import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import ReportNavbar from './ReportNavbar';
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import {  DatePicker, Select } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DischargeReportReport, ReportDonationList } from '../../ApiFile';
const { RangePicker } = DatePicker;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

const DonationReport = () => {

const [members, setMembers] = useState([]);
const [dateRange, setDateRange] = useState([]);

const handleDateChange = (dates) => {
  setDateRange(dates);
};

const handleRegularWiseShow = () => {
  const [dateFrom, dateTo] = dateRange;

  const objData = {
    fromdate: dateFrom ? dateFrom.format('DD-MM-YYYY') : null,
    todate: dateTo ? dateTo.format('DD-MM-YYYY') : null,
  }

  axios.post(ReportDonationList,objData)
  .then(response => {    
    setMembers(response.data);
})
  .catch(error => console.error('Error fetching member list:', error));
};

const rows = members.map((member, index) => ({
    serial: index + 1,
    name: member.name,
    address: member.address,
    mob_no: member.mob_no,  
    amount: member.amount,  
    donation_for: member.donation_for,  
    donation_date: member.donation_date,  
}));

const data = {
  columns: [
    { label: 'Serial No', field: 'serial', sort: 'asc' },
    { label: 'Name', field: 'name', sort: 'asc' },
    { label: 'Address', field: 'address', sort: 'asc' },
    { label: 'Mob.No', field: 'mob_no', sort: 'asc' },
    { label: 'Amt', field: 'amount', sort: 'asc' },
    { label: 'Donation For', field: 'donation_for', sort: 'asc' },
    { label: 'Date of Donation', field: 'donation_date', sort: 'asc' },
  ],
  rows: rows
};

// Calculate totals====
const totalCount = members.length;


const handleExcelData = () => {
  const ws = XLSX.utils.json_to_sheet(rows);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Donation_Data.xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handlePdfData = () => {
  const doc = new jsPDF();
  const columns = data.columns.map((col) => col.label);
  const rowsData = rows.map((row) => [ row.serial,row.name,row.address,row.mob_no,row.amount,row.donation_for,row.donation_date]);

  const title = 'Donation Data';

  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);

  doc.autoTable({
    head: [columns],
    body: rowsData,
    startY: 20,
  });

  const blob = doc.output('blob');
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Donation_Data.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};




  return (
    <>
    <Sidebar />
    <section className="home-section pb-5">
      <NavLogo />
      <ReportNavbar />

      <Row className="m-0 p-0 pt-4 px-3 ">
            <Col
                className="rounded text-center py-2"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    border: "none",
                    alignItems: "center",
                    alignContent: "center",
                    color: "maroon",
                }}
            >    
            <div><span style={{color:"maroon",fontSize:"18px"}}>Donation Report</span></div>
                
                
            </Col>
        </Row>

      <div className="row m-0 p-2 p-md-3 pb-5">
                  <div className="row m-0 py-3 border" style={{backgroundColor:"white",borderBottom:"1px solid grey"}}>

                  <div className="col-12 col-lg-3"></div>
                    <div className="col-12 col-lg-5 pt-2 pt-md-0 d-flex">
                        <div className="row" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <div className="col-12 col-md-3">
                                <label style={{fontWeight:"normal",fontSize:"13px"}}>Select Date :</label>
                            </div>
                            <div className="col-12 col-md-9">
                                <RangePicker format={dateFormatList}  onChange={handleDateChange} />
                            </div>
                        </div>                                 
                    </div>

                    <div className="col-12 col-lg-2 pt-2 pt-lg-0 text-center">
                      <Button variant="contained" color="primary" style={{backgroundColor:"#1685f3",padding:"5px 10px"}} onClick={handleRegularWiseShow}>Show</Button>
                    </div>

                    <div className="col-12 col-lg-2"></div>
                  </div>

                  <div className="row m-0 p-2 mb-5"  style={{backgroundColor:"white"}}>

                  <div className='col-12 text-end py-2'>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#3b9cfd",color:"white"}}  onClick={() => handleExcelData()}>Excel</Button>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#136fcb",color:"white"}} onClick={() => handlePdfData()}>Pdf</Button>
                  </div>
                    <div className='col-12' style={{overflowX:"auto"}}>
                      <MDBDataTable
                        striped
                        bordered
                        className="custom-datatable"
                        small
                        data={data}
                      />
                      </div>

                      <div className="col-12 text-end p-1" style={{backgroundColor:"black",color:"white"}}>
                          <div className="row border m-0">
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Donor: {totalCount}</span>
                            </div>
                          </div>                        
                      </div>


                  </div>
        </div>

      </section>
      </>
  )
}

export default DonationReport