import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextareaAutosize } from '@mui/material';
import { AdminBreakfastAddApi, AdminBreakfastDeleteApi, AdminBreakfastFindAllApi, AdminBreakfastUpdateApi } from '../../ApiFile';
import Sidebar from '../../AluminiAdminDashboardDesign/sidebar';
import NavLogo from '../../AluminiAdminDashboardDesign/NavLogo';

const BreakfastmenuMaster = () => {
  const [showModal, setShowModal] = useState(false);
  const [rules, setRules] = useState([]);
  const [MenuName, setMenuName] = useState('');
  const [price, setPrice] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [editId, setEditId] = useState(null);
  
  // State for delete confirmation modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    fetchRuleData();
  }, []);

  const fetchRuleData = async () => {
    try {
      const response = await fetch(AdminBreakfastFindAllApi);
      if (!response.ok) {
        throw new Error('Failed to fetch block data');
      }
      const data = await response.json();

      if (data === "NOTFOUND") {
        setRules([]);
      } else {
        setRules(data);
      }
    } catch (error) {
      console.error('Error fetching block data:', error);
    }
  };

  const handleOpen = (id = null) => {
    setOpen(true);
    setIsEditing(id !== null);
    if (id !== null) {
      const item = rules.find(rule => rule.id === id);
      if (item) {
        setEditId(id);
        setMenuName(item.menu_name);
        setPrice(item.price);
      }
    } else {
      setMenuName('');
      setPrice('');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    setMenuName('');
    setPrice('');
  };

  const savedata = async (e) => {
    e.preventDefault();
    
    if (!MenuName || !price) {
      toast.error('MenuName and Price are mandatory fields.');
      return;
    }
  
    try {
      if (isEditing) {
        const bodyData = { menu_name: MenuName, price: price };
        const response = await fetch(`${AdminBreakfastUpdateApi}/${editId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        });
        if (!response.ok) {
          throw new Error('Failed to update');
        }
        toast.success('Data updated successfully');
      } else {
        const bodyData = { menu_name: MenuName, price: price };
        const response = await fetch(AdminBreakfastAddApi, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        });
        if (!response.ok) {
          throw new Error('Failed to add');
        }
        toast.success('Data saved successfully');
      }
      fetchRuleData();
      handleClose();
    } catch (error) {
      console.error('Error adding/updating:', error);
    }
  };

  const confirmDelete = (id) => {
    setDeleteModalOpen(true);
    setDeleteId(id);
  };

  const deleteData = async () => {
    try {
      const response = await fetch(`${AdminBreakfastDeleteApi}/${deleteId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete');
      }
      toast.success('Data deleted successfully');
      fetchRuleData();
      setDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting:', error);
      setError('Failed to delete ');
      setDeleteModalOpen(false);
    }
  };

  const columns = [
    { label: 'Serial No', field: 'serial_no', sort: 'asc' },
    { label: 'Menu Name', field: 'menu_name', sort: 'asc' },
    { label: 'Price', field: 'price', sort: 'asc' },
    { label: 'Edit', field: 'edit', sort: 'asc' },
    { label: 'Delete', field: 'delete', sort: 'asc' },
  ];

  const rows = rules.map((item, index) => ({
    serial_no: index+1,
    menu_name: item.menu_name,
    price: item.price,
    edit: (
      <button
        className="m-1"
        style={{ color: "rgb(255, 93, 0)", border: "0px", backgroundColor: "transparent" }}
        onClick={() => handleOpen(item.id)}
      >
        <UpdateIcon />
      </button>
    ),
    delete: (
      <button
        className="m-1"
        style={{ color: "rgb(255, 0, 0)", border: "0px", backgroundColor: "transparent" }}
        onClick={() => confirmDelete(item.id)}
      >
        <DeleteIcon />
      </button>
    )
  }));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />

        <div>
          <div className="block-header">
            <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
              Breakfast Menu Master
            </h2>
            <Button
              className="ms-md-5 m-0  ms-3  mb-2 mb-md-4 mt-2 mt-md-4"
              variant="contained"
              color="primary"
              onClick={() => handleOpen()}
              startIcon={<AddIcon />}
            >
              Add Menu
            </Button>
          </div>

          <Row className="m-0 p-0  px-md-3  py-1 pb-5  w-100" style={{ display: 'flex' }}>
            <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
              <Col className="m-0 p-0  px-3 py-3 rounded" style={{ background: 'white' }}>
                <div style={{ overflowY: 'auto' }}>
                  <div style={{ overflowX: 'auto' }}>
                    <MDBDataTable striped className="custom-datatable" bordered hover data={{ columns, rows }} />
                  </div>
                </div>
              </Col>
            </Row>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Typography variant="h6" className="mt-2" component="h2">
                Breakfast Menu Master
                </Typography>
                <form>
                  <TextField
                    label="Menu Name"
                    value={MenuName}
                    onChange={(e) => setMenuName(e.target.value)}
                    fullWidth
                    style={{ marginBottom: '20px' }}
                  />
                  <TextField
                    type='number'
                    label="Price Rs"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    fullWidth
                    style={{ marginBottom: '20px' }}
                  />
                </form>
                <Row>
                  <Col className='text-end'>
                    <Button
                      className="mt-4"
                      style={{ color: 'black', backgroundColor: 'white', border: '2px solid rgb(135, 178, 178)', borderRadius: '8' }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                      className="py-2 px-3 mt-4 shadow text-dark"
                      type="submit"
                      onClick={savedata}
                    >
                      {isEditing ? 'Update' : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
              <Box sx={style}>
                <Typography variant="h6" component="h2">
                  Confirm Delete
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  Are you sure you want to delete this item?
                </Typography>
                <Row className="mt-4">
                  <Col className='text-end'>
                    <Button
                      style={{ color: 'black', backgroundColor: 'white', border: '2px solid rgb(135, 178, 178)', borderRadius: '8' }}
                      onClick={() => setDeleteModalOpen(false)}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ border: '0px', backgroundColor: 'rgb(255, 0, 0)' }}
                      className="py-2 px-3 shadow text-light"
                      onClick={deleteData}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Modal>
          </Row>
        </div>
      </section>
    </>
  );
};

export default BreakfastmenuMaster;
