import React, { useEffect, useState } from 'react';
import Sidebar from './sidebarstudent';
import Header from './Headerstudent';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { Col, Row } from 'react-bootstrap';
import { Button, TextareaAutosize, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { MDBDataTable } from 'mdbreact';
import { toast } from 'react-toastify';
import { RegularLeaveAdd, RegularLeaveDelete, RegularLeaveFindall, RegularLeaveUpdate } from '../../ApiFile';

dayjs.locale('en-gb');

const RegularLeaveApplication = () => {
  const [selectedDates, setSelectedDates] = useState([null, null]);
  const [reason, setReason] = useState('');
  const [leaveData, setLeaveData] = useState([]);
  const [memberId, setMemberId] = useState(null);
  const [editingLeave, setEditingLeave] = useState(null);
  const [confirmCancelModal, setConfirmCancelModal] = useState(false);
  const [leaveIdToCancel, setLeaveIdToCancel] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedMemberId = sessionStorage.getItem('member_id');
    if (storedMemberId) {
      setMemberId(storedMemberId);
    } else {
      console.error('No member ID found in session storage');
    }
  }, []);

  useEffect(() => {
    if (memberId) {
      fetch(`${RegularLeaveFindall}/${memberId}`)
        .then((response) => response.json())
        .then((data) => setLeaveData(data))
        .catch((error) => console.error('Error fetching leave data:', error));
    }
  }, [memberId]);

  const handleDateChange = (newValue) => {
    setSelectedDates(newValue);
  };

  const handleReasonChange = (event) => {
    const { value } = event.target;
    if (value.length <= 200) {
      setReason(value);
    }
  };

  const handleSubmit = async () => {
    if (!selectedDates[0] || !selectedDates[1] || !reason) {
      toast.warn('Please select dates and provide a reason.');
      return;
    }

    const data = {
      member_id: memberId,
      reason,
      fromdate: selectedDates[0].format('DD/MM/YYYY'),
      todate: selectedDates[1].format('DD/MM/YYYY'),
    };

    try {
      const response = await fetch(RegularLeaveAdd, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success('Regular leave application submitted successfully!');
        setSelectedDates([null, null]);
        setReason('');
        fetch(`${RegularLeaveFindall}/${memberId}`)
          .then((response) => response.json())
          .then((data) => setLeaveData(data))
          .catch((error) => console.error('Error fetching leave data:', error));
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Error submitting leave application');
      }
    } catch (error) {
      console.error('Network error:', error);
      toast.error('Network error');
    }
  };

  const handleUpdate = async (leaveId) => {
    if (!selectedDates[0] || !selectedDates[1] || !reason) {
      toast.warn('Please select dates and provide a reason.');
      return;
    }

    const data = {
      member_id: memberId,
      reason,
      fromdate: selectedDates[0].format('DD/MM/YYYY'),
      todate: selectedDates[1].format('DD/MM/YYYY'),
    };

    try {
      const response = await fetch(`${RegularLeaveUpdate}/${leaveId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success('Regular leave updated successfully!');
        setEditingLeave(null);
        setSelectedDates([null, null]);
        setReason('');
        fetch(`${RegularLeaveFindall}/${memberId}`)
          .then((response) => response.json())
          .then((data) => setLeaveData(data))
          .catch((error) => console.error('Error fetching leave data:', error));
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Error updating leave');
      }
    } catch (error) {
      console.error('Network error:', error);
      toast.error('Network error');
    }
  };

  const handleCancelConfirm = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${RegularLeaveDelete}/${leaveIdToCancel}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        toast.success('Regular leave canceled successfully!');
        fetch(`${RegularLeaveFindall}/${memberId}`)
          .then((response) => response.json())
          .then((data) => setLeaveData(data))
          .catch((error) => console.error('Error fetching leave data:', error));
        setConfirmCancelModal(false);
        setLeaveIdToCancel(null);
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Error canceling leave');
      }
    } catch (error) {
      console.error('Network error:', error);
      toast.error('Network error');
    } finally {
      setLoading(false);
    }
  };

  const openCancelDialog = (leaveId) => {
    setLeaveIdToCancel(leaveId);
    setConfirmCancelModal(true);
  };

  const tableData = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'From Date',
        field: 'fromdate',
        sort: 'asc',
        width: 270
      },
      {
        label: 'To Date',
        field: 'todate',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Reason',
        field: 'reason_for_leave',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Update',
        field: 'update',
        width: 100
      },
      {
        label: 'Cancel',
        field: 'cancel',
        width: 100
      }
    ],
    rows: leaveData.map((leave) => ({
      id: leave.id,
      fromdate: leave.fromdate,
      todate: leave.todate,
      reason_for_leave: leave.reason_for_leave,
      status: leave.leave_status === "0" ? (
        <Button style={{ backgroundColor: 'orange', color: 'white' }}>
          Pending
        </Button>
      ) : leave.leave_status === "1" ? (
        <Button style={{ backgroundColor: 'green', color: 'white' }}>
          Approved
        </Button>
      ) : leave.leave_status === "2" ? (
        <Button style={{ backgroundColor: 'red', color: 'white' }}>
          Rejected
        </Button>
      ) : '',
      update: leave.leave_status === "0" ? (
        <Button
          onClick={() => {
            setEditingLeave(leave.id);
            setSelectedDates([dayjs(leave.fromdate, 'DD/MM/YYYY'), dayjs(leave.todate, 'DD/MM/YYYY')]);
            setReason(leave.reason_for_leave);
          }}
          variant="contained"
          color="info"
        >
          Update
        </Button>
      ) : null,
      cancel: leave.leave_status === "0" ? (
        <Button
          onClick={() => openCancelDialog(leave.id)}
          variant="contained"
          color="error"
        >
          Cancel
        </Button>
      ) : null,
    })),
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <Header />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Regular Leave Application
          </h2>
        </div>
        <div className="mt-4">
          <Row className="m-0 p-0 py-1 px-md-5 px-2 mt-4 w-100" style={{ display: 'flex' }}>
            <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
              <Row >
                <Col sm={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DemoContainer components={['DateRangePicker']}>
                      <label style={{ color: 'maroon' }}>Select Dates:</label>
                      <DateRangePicker
                        startText="From"
                        endText="To"
                        format="DD/MM/YYYY"
                        value={selectedDates}
                        onChange={handleDateChange}
                        slotProps={{
                          textField: { size: 'small' },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Col>
                <Col sm={12} md={6}>
                  <label className='mt-3' style={{ color: 'maroon' }}>Reason:</label>
                  <TextareaAutosize
                    value={reason}
                    onChange={handleReasonChange}
                    maxLength={200}
                    className='mt-3'
                    placeholder="Enter Reason"
                    style={{ width: '100%', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ccc' }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className='mt-2' style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    style={{ backgroundColor: "rgb(0, 120, 215)", color: "white" }}
                    onClick={editingLeave ? () => handleUpdate(editingLeave) : handleSubmit}
                  >
                    {editingLeave ? 'UPDATE' : 'APPLY'}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="m-0 p-0 py-1 pb-5 px-md-5 px-2 w-100" style={{ display: 'flex' }}>
            <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
              <MDBDataTable
                striped
                bordered
                className="custom-datatable"
                hover
                data={tableData}
              />
            </Col>
          </Row>
        </div>
      </section>

      {/* Confirmation Dialog for Cancel */}
      <Dialog open={confirmCancelModal} onClose={() => setConfirmCancelModal(false)} maxWidth="sm" fullWidth>
        <DialogTitle style={{ color: "maroon" }}>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to cancel this leave?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmCancelModal(false)} color="primary">
            No
          </Button>
          <Button onClick={handleCancelConfirm} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Yes, Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegularLeaveApplication;
