import React, { useState } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { Col, Row, Form } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminRoomChangeAddApi, AdminRoomChangeRefundApi } from '../../ApiFile';
import { Box, Button } from '@mui/material';

const AdminRefundPage = () => {
  const [refundAmount, setRefundAmount] = useState('');
  const [checkNo, setCheckNo] = useState('');
  const [file, setFile] = useState(null);
  const [payMode, setPayMode] = useState('');
  const [refundFlag, setRefundFlag] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const bedFinal = location.state && location.state.bedFinal;
  
  console.log("lojhj", bedFinal);
  
  let substringAmount = '';
  if (bedFinal && bedFinal.amount) {
    // Convert bedFinal.amount to a string if it's not already
    const amountString = typeof bedFinal.amount === 'string' ? bedFinal.amount : bedFinal.amount.toString();
    console.log("amountString", amountString);
    
    // Create a substring from the second character to the end
    substringAmount = amountString.slice(1);
    console.log("substringAmount", substringAmount);
  } else {
    console.error('bedFinal or bedFinal.amount is undefined');
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('receipt_id', bedFinal.receipt_id);
    formData.append('refundAmount', substringAmount);
    formData.append('member_id', bedFinal.member_id);
    formData.append('check_no', checkNo);
    formData.append('pay_mode', payMode);
    formData.append('file', file);

    try {
      const refundResponse = await axios.post(AdminRoomChangeRefundApi , formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (refundResponse.data === 'SAVED') {
        const roomChangeData = {
          block_id: bedFinal.block_id,
          room_id: bedFinal.room_id,
          bed_id: bedFinal.bed_id,
          member_id: bedFinal.member_id,
        };

        const roomChangeResponse = await axios.post(AdminRoomChangeAddApi, roomChangeData);

        if (roomChangeResponse.data === 'SAVED') {
          toast.success('Refund Done And Room Changed Successfully!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
         
        } else {
          toast.error('Error saving room change, please try again', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        navigate('/RoomChange')
        setRefundAmount('');
        setCheckNo('');
        setFile(null);
      } else {
        toast.error('Error saving refund, please try again', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      // console.error('Error submitting refund:', error);
      toast.error('Error submitting refund, please try again', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div className="mt-3">
          <h3 className="text-center">
            <p style={{ color: 'maroon' }}>Refund Amount</p>
          </h3>
          <Row className="m-0 p-0 px-md-3 py-1 pb-5 mt-4 w-100" style={{ display: 'flex' }}>
            <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
              <Row>
                <Col md={3} className="mt-3"></Col>
                <Col md={6} className="mt-3">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={4}>
                        Refund Amount Rs :
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="number"
                          value={substringAmount}
                          readOnly
                          required
                        />
                      </Col>
                    </Form.Group>
                   

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={4}>
                        Payment Mode:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          as="select"
                          value={payMode}
                          onChange={(e) => setPayMode(e.target.value)}
                          required
                        >
                          <option value="">Select Payment Mode</option>
                          <option value="2">Cheque / Voucher</option>
                          {/* <option value="2">Online</option> */}
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={4}>
                        Cheque / Voucher No:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          value={checkNo}
                          onChange={(e) => setCheckNo(e.target.value)}
                          maxLength={30}
                          required
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={4}>
                      Cheque / Voucher Image:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="file"
                          onChange={handleFileChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                    
                  
                    <div className="text-center">
                        <Box sx={{ p: 2,}}>
                           <Row className='mt-1' >
                           <Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                          Submit
                          </Button>
                          </Button>
                          </Row>
                       </Box>
                    </div>
                  </Form>
                </Col>
                <Col md={3} className="mt-3"></Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default AdminRefundPage;
