import React, { useEffect, useState } from 'react'
import { Nav, Navbar, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './ReportCSS.css';
import Sidebar from '../sidebar'
import NavLogo from '../NavLogo'
import { AiFillContainer } from "react-icons/ai";
import { Link } from 'react-router-dom'
import { Button } from '@mui/material';

const ReportNavbar = () => {

  const selectedLanguage = sessionStorage.getItem('selectedLanguage');
  const [showStock, setShowStock] = useState(false);
  const [showBilling, setShowBilling] = useState(false);
  const [showPayment, setShowPayment] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
          const navbar = document.querySelector(".navbar");
          if (window.scrollY > 100) {
            navbar.classList.add("fixed-top", "fixed-background");
          } else {
            navbar.classList.remove("fixed-top", "fixed-background");
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const navDropdownStyle = {
        color: "rgb(47, 79, 79)"
      };

  return (
    <>
    
    <Row className='shadow m-0' style={{backgroundColor: "rgb(128, 178, 178)" }} >            

            <Navbar expand="lg ps-5 ps-lg-0 text-end" style={{ backgroundColor: "rgb(128, 178, 178)",display:"flex", }}>
                <Container>
                  <Navbar.Brand style={{ color: "black", borderBottom: "1px solid black" }}>
                    <b>Report</b>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" style={{color:"white",border:"none"}}>
                  <i class="fa-solid fa-bars"></i>
                  </Navbar.Toggle>
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">

                    <Nav.Link href="/#/AdminDashboardReport" id="basic-nav-dropdown" className='mx-1'> Dashboard </Nav.Link>

                    <NavDropdown title="App.Fee" id="basic-nav-dropdown" className='mx-1'>
                        <NavDropdown.Item href="/#/TodaysApplicationFormFee" className='dropdownItems'>Todays Application Form Fee Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/TotalApplicationFormFee" className='dropdownItems'>Total Application Form Fee Report</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Hostel Fee" id="basic-nav-dropdown" className='mx-1'>
                        <NavDropdown.Item href="/#/TodayFeeReport" className='dropdownItems'>Today's Fee Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/TotalFeeReport" className='dropdownItems'>Total Fee Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/PendingFeeReport" className='dropdownItems'>Installment Pending Fee Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/FeeStatusHostelFee" className='dropdownItems'>Fee Status Report</NavDropdown.Item>
                    </NavDropdown>                    

                      <NavDropdown title="Deposit" id="basic-nav-dropdown" className='mx-1'>
                        <NavDropdown.Item href="/#/TodayDepositReport" className='dropdownItems'>Todays Deposit Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/TotalDepositReport" className='dropdownItems'>Total Deposit Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/TodayDepositReturnReport" className='dropdownItems'>Todays Deposit Return Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/TotalDepositReturnReport" className='dropdownItems'>Total Deposit Return Report</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Refund" id="basic-nav-dropdown" className='mx-1'>
                        <NavDropdown.Item href="/#/TodaysRefundHostel" className='dropdownItems'>Todays Refund Hostel Fee Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/TotalRefundHostel" className='dropdownItems'>Total Refund Hostel Fee Report</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Room-Bed" id="basic-nav-dropdown" className='mx-1'>
                        <NavDropdown.Item href="/#/VacantRoomBed" className='dropdownItems'>Vacant Bed Report (Room Wise)</NavDropdown.Item>
                        <NavDropdown.Item href="/#/AllBookedRoomBed" className='dropdownItems'>Booked Bed Report (Room Wise)</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Admit" id="basic-nav-dropdown" className='mx-1'>
                        <NavDropdown.Item href="/#/NewAdmit" className='dropdownItems'>New Admit Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/ReAdmit" className='dropdownItems'>Re-Admit Report</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Other" id="basic-nav-dropdown" className='mx-1'>
                        <NavDropdown.Item href="/#/AlphabeticalWiseStudent" className='dropdownItems'>Student List  (Alpha Wise)</NavDropdown.Item>
                        <NavDropdown.Item href="/#/BlockWiseStudent" className='dropdownItems'>Student List (Block Wise)</NavDropdown.Item>
                        <NavDropdown.Item href="/#/AlumniReport" className='dropdownItems'>Alumni Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/StudentLeaveReport" className='dropdownItems'>Student Leave Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/PaymentReceipt" className='dropdownItems'>Payment Receipt Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/DonorPage" className='dropdownItems'>Donor List Report</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="Attendance" id="basic-nav-dropdown" className='mx-1'>
                        <NavDropdown.Item href="/#/MessWiseAttendance" className='dropdownItems'>Mess Attendance Report</NavDropdown.Item>
                        <NavDropdown.Item href="/#/EventWiseAttendance" className='dropdownItems'>Event Attendance Report</NavDropdown.Item>
                      </NavDropdown>

                      <Nav.Link href="/#/DischargeReport" id="basic-nav-dropdown" className='mx-1'> Discharge </Nav.Link>
                     
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>

    </Row>
    </>
  )
}

export default ReportNavbar


