import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios'; // Add axios for API calls
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { backdropClasses } from '@mui/material';
import { Edit, Delete, Visibility, Build, Create, ModeEdit } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { AluminiViewApi } from '../../ApiFile';



export default function AluminiView() {
  const matches = useMediaQuery('(max-width:600px)');
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [],
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get(AluminiViewApi)
      .then(response => {
        // Map the response data to add the fullName and year fields
        const mappedRows = response.data.map(row => {
          return {
            ...row,
            fullName: getFullName(row),
            year: getYear(row),
          };
        });
        setRows(mappedRows);
      })
      .catch(error => {
        // console.error(error);
      });
  };

 



  // Add a getter for the fullName field
  const getFullName = (row) => {
    const { f_name, m_name, l_name } = row;
    const fullNameParts = [f_name, m_name, l_name].filter(part => part !== null && part !== undefined && part !== '');
    return fullNameParts.join(' ');
  };

  // Add a getter for the year field
  const getYear = (row) => {
    // console.log(row);
    const { fromyearof_boarding, toyearof_boarding } = row;
    if (fromyearof_boarding !== undefined && toyearof_boarding !== undefined) {
      return `${fromyearof_boarding} - ${toyearof_boarding}`;
    } else if (fromyearof_boarding !== undefined) {
      return fromyearof_boarding;
    } else if (toyearof_boarding !== undefined) {
      return toyearof_boarding;
    } else {
      return '';
    }
  };

  const handleViewClick = (row) => {
    setSelectedRow(row || {});
    setOpen(true);
  };

 



  const columns = [
    {
      field: 'serialNo',
      headerName: 'S.No.',
      width: 50,
      renderCell: (params) => {
        return params.rowIndex + 1;
      },
    },
    { field: 'fullName', headerName: 'Name', width: 200 },
    { field: 'mob_no', headerName: 'Contact No', width: 120 },
    { field: 'email_id', headerName: 'Email', width: 180 },
    { field: 'professional_category', headerName: 'Profession', width: 230 },
    { field: 'year', headerName: 'Year', width: 150 },
    
   
    {
      field: 'view',
      headerName: 'View',
      width: 73,
      renderCell: (params) => (
        <Button variant="contained" color="primary" onClick={() => handleViewClick(params.row)} style={{ background: 'blue', borderRadius: '10px' }} >
          <Visibility fontSize="small" />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '86vh',
          margin: '0 auto',
          padding: '20px',
          maxWidth: '1200px',
          width: '60%',
          backgroundColor: 'light grey',
          width: matches ? '80%' : '100%', // Add this line
        }}
      >
         <DataGrid
           style={{ backgroundColor: 'white' }} // Add this line
          columns={columns}
          rows={rows}
          
          disableColumnResize
          disableDensitySelector
          disableColumnHeaderSorting
          disableColumnFilter
          slots={{ toolbar: GridToolbar }}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => setFilterModel(newModel)}
          slotProps={{ toolbar: { showQuickFilter: true } }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          className="custom-data-grid"
        />
      </Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '500px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            p: 4,
          }}
        >
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  {Object.entries(selectedRow || {}).map(([key, value]) => (
    <div key={key}>
      <strong>{key.replace(/_/g, ' ').replace(/\w\w/g, (c) => c.toUpperCase())}:</strong> {value}
      <br />
    </div>
  ))}
</Typography>

          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2, position: 'absolute', bottom: '16px', right: '16px' }}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}