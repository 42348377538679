import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './index.css'
import App from './App'
// import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals'

import 'react-toastify/dist/ReactToastify.css'
import { HashRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <HashRouter>

    <React.StrictMode>
      <App />
      <ToastContainer />
    </React.StrictMode>

  </HashRouter>
)

reportWebVitals()