// import React, { useState, useEffect } from 'react';
// import Sidebar from './sidebarstudent';
// import Header from './Headerstudent';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import dayjs from 'dayjs';
// import 'dayjs/locale/en-gb';
// import { Col, Row, Spinner } from 'react-bootstrap';
// import { Button, TextareaAutosize, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, TextField, Box, FormControlLabel, Checkbox } from '@mui/material';
// import { MDBDataTable } from 'mdbreact';
// import { MessLeaveAdd, MessLeaveCancel, MessLeaveFindall, MessLeaveUpdate, MessLeaveUpdateFindone } from '../../ApiFile';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// dayjs.locale('en-gb');

// const MessLeaveApplication = () => {
//   const [selectedDates, setSelectedDates] = useState([null, null]);
//   const [checkboxes, setCheckboxes] = useState([]);
//   const [reason, setReason] = useState('');
//   const [leaveData, setLeaveData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [confirmCancelModal, setConfirmCancelModal] = useState(false);
//   const [cancelLeaveId, setCancelLeaveId] = useState(null);
//   const [cancelDateflag, setCancelDateflag] = useState(null); // Track the dateflag for cancellation
//   const [isUpdating, setIsUpdating] = useState(false);
//   const [updateLeaveId, setUpdateLeaveId] = useState(null);

//   const memberId = sessionStorage.getItem('member_id');

//   const handleDateChange = (newValue) => {
//     setSelectedDates(newValue);
//     if (newValue[0] && newValue[1]) {
//       const days = [];
//       let currentDay = dayjs(newValue[0]);
//       const endDay = dayjs(newValue[1]);

//       while (currentDay.isBefore(endDay) || currentDay.isSame(endDay, 'day')) {
//         days.push({
//           date: currentDay.format('DD/MM/YYYY'),
//           lunch: false,
//           dinner: false
//         });
//         currentDay = currentDay.add(1, 'day');
//       }

//       setCheckboxes(days);
//     }
//   };

//   const handleCheckboxChange = (date, mealType, checked) => {
//     setCheckboxes(checkboxes.map((item) =>
//       item.date === date ? { ...item, [mealType]: checked } : item
//     ));
//   };

//   const handleReasonChange = (event) => {
//     const { value } = event.target;
//     if (value.length <= 200) {
//       setReason(value);
//     }
//   };

//   const handleSubmit = async () => {
//     if (!reason) {
//       toast.warn("Please provide the reason before proceeding.");
//       return;
//     }
//     setLoading(true);
//     setError(null);

//     const leaves = checkboxes.map((day) => ({
//       id: day.id,
//       leave_date: day.date,
//       flag: {
//         lunch: day.lunch ? 1 : 0,
//         dinner: day.dinner ? 1 : 0,
//       }
//     }));
//     console.log("leaves..",leaves);
    

//     const data = {
//       dateflag: isUpdating ? updateLeaveId : '', // Use `updateLeaveId` for updating
//       member_id: memberId,
//       reason,
//       leaves
//     };

//     try {
//       const response = await fetch(isUpdating ? MessLeaveUpdate : MessLeaveAdd, {
//         method: isUpdating ? 'PUT' : 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(data)
//       });

//       if (response.ok) {
//         toast.success(isUpdating ? 'Leave application updated successfully!' : 'Leave application submitted successfully!');
//         fetchLeaveApplications(); // Fetch updated leave data
//         setSelectedDates([null, null]);
//         setCheckboxes([]);
//         setReason('');
//         setIsUpdating(false);
//       } else {
//         const errorData = await response.json();
//         setError(errorData.message || 'Error saving leave data');
//         toast.error(errorData.message || 'Error saving leave data');
//       }
//     } catch (error) {
//       setError('Network error');
//       toast.error('Network error');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchLeaveApplications = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${MessLeaveFindall}/${memberId}`);
//       if (response.ok) {
//         const data = await response.json();
//         setLeaveData(data);
//       } else {
//         const errorData = await response.json();
//         setError(errorData.message || 'Error fetching leave data');
//         toast.error(errorData.message || 'Error fetching leave data');
//       }
//     } catch (error) {
//       setError('Network error');
//       toast.error('Network error');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleUpdate = async (leave) => {
//     setIsUpdating(true);
//     setUpdateLeaveId(leave.dateflag); // Track the leave dateflag for updates
//     setSelectedDates([dayjs(leave.fromdate, 'DD/MM/YYYY'), dayjs(leave.todate, 'DD/MM/YYYY')]);
//     setReason(leave.reason);

//     const days = [];
//     let currentDay = dayjs(leave.fromdate, 'DD/MM/YYYY');
//     const endDay = dayjs(leave.todate, 'DD/MM/YYYY');

//     while (currentDay.isBefore(endDay) || currentDay.isSame(endDay, 'day')) {
//       days.push({
        
//         date: currentDay.format('DD/MM/YYYY'),
//         lunch: false,
//         dinner: false
//       });
//       currentDay = currentDay.add(1, 'day');
//     }

//     setCheckboxes(days);

//     try {
//       const response = await fetch(`${MessLeaveUpdateFindone}/${memberId}/${leave.dateflag}`);
//       if (response.ok) {
//         const data = await response.json();
//         setReason(data.reason);
//         setCheckboxes(
//           data.leaves.map((leaveDay) => ({
//             id:leaveDay.id,
//             date: dayjs(leaveDay.leave_date, 'DD/MM/YYYY').format('DD/MM/YYYY'),
//             lunch: leaveDay.flag.lunch === 1,
//             dinner: leaveDay.flag.dinner === 1,
//           }))
//         );
//       } else {
//         const errorData = await response.json();
//         setError(errorData.message || 'Error fetching leave data');
//         toast.error(errorData.message || 'Error fetching leave data');
//       }
//     } catch (error) {
//       setError('Network error');
//       toast.error('Network error');
//     }
//   };

//   const handleCancel = async (leaveId) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const leaveToCancel = leaveData.find(leave => leave.id === leaveId);
//       if (leaveToCancel) {
//         setCancelDateflag(leaveToCancel.dateflag); 
//         setConfirmCancelModal(true);
//       } else {
//         toast.error('Leave not found');
//       }
//     } catch (error) {
//       setError('Error finding leave data');
//       toast.error('Error finding leave data');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleCancelConfirm = async () => {
//     if (!cancelDateflag) return;

//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${MessLeaveCancel}/${cancelDateflag}`, {
//         method: 'GET',
//       }
 
//     );

//       if (response.ok) {
//         toast.success('Leave application canceled successfully!');
//         fetchLeaveApplications();
//       } else {
//         const errorData = await response.json();
//         setError(errorData.message || 'Error canceling leave');
//         toast.error(errorData.message || 'Error canceling leave');
//       }
//     } catch (error) {
//       setError('Network error');
//       toast.error('Network error');
//     } finally {
//       setLoading(false);
//       setConfirmCancelModal(false);
//       setCancelDateflag(null);
//     }
//   };

//   useEffect(() => {
//     fetchLeaveApplications();
//   }, []);

//   const tableData = {
//     columns: [
//       {
//         label: 'Serial No',
//         field: 'serialNo',
//         sort: 'asc',
//         width: 150,
//       },
//       {
//         label: 'From Date',
//         field: 'fromdate',
//         sort: 'asc',
//         width: 270,
//       },
//       {
//         label: 'To Date',
//         field: 'todate',
//         sort: 'asc',
//         width: 270,
//       },
//       {
//         label: 'Reason',
//         field: 'reason',
//         sort: 'asc',
//         width: 200,
//       },
//       {
//         label: 'Status',
//         field: 'status',
//         sort: 'asc',
//         width: 150,
//       },
//       {
//         label: 'Update',
//         field: 'update',
//         sort: 'asc',
//         width: 150,
//       },
//       {
//         label: 'Cancel',
//         field: 'cancel',
//         sort: 'asc',
//         width: 150,
//       },
//     ],
//     rows: leaveData.map((leave, index) => ({
//       serialNo: index + 1,
//       fromdate: leave.fromdate,
//       todate: leave.todate,
//       reason: leave.reason || '',
//       status:
//         leave.leave_status === "0"
//           ? (<Button style={{ backgroundColor: 'orange', color: "white" }}>Pending</Button>)
//           : leave.leave_status === "1"
//             ? (<Button style={{ backgroundColor: 'green', color: "white" }}>Approved</Button>)
//             : leave.leave_status === "2"
//               ? (<Button style={{ backgroundColor: 'red', color: "white" }}>Rejected</Button>)
//               : '',
//       update:
//         leave.leave_status === "0" ? (
//           <Button onClick={() => handleUpdate(leave)} variant="contained" color="info">
//             Update
//           </Button>
//         ) : null,
//       cancel:
//         leave.leave_status === "0" ? (
//           <Button onClick={() => handleCancel(leave.id)} variant="contained" color="error">
//             Cancel
//           </Button>
//         ) : null,
//     }))
//   };

//   return (
//     <>
//       <Sidebar />
//       <section className="home-section">
//         <Header />
//         <div className="block-header">
//           <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
//             Meals Leave Application
//           </h2>
//         </div>
//         <div className="mt-4">
//           <Row className="m-0 p-0 py-1 px-md-5 px-2 mt-4 w-100" style={{ display: 'flex' }}>
//             <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
//               <Row>
//                 <Col sm={12} md={6}>
//                   <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
//                     <DemoContainer components={['DateRangePicker']}>
//                       <label style={{ color: 'maroon' }}>Select Dates:</label>
//                       <DateRangePicker
//                         startText="Departure"
//                         endText="Return"
//                         format="DD/MM/YYYY"
//                         value={selectedDates}
//                         onChange={handleDateChange}
//                         slotProps={{
//                           textField: { size: 'small' },
//                         }}
//                       />
//                     </DemoContainer>
//                   </LocalizationProvider>
//                 </Col>
//                 <Col sm={12} md={6}>
//                   <label className='mt-2' style={{ color: 'maroon' }}>Reason:</label>
//                   <TextareaAutosize
//                     value={reason}
//                     onChange={handleReasonChange}
//                     maxLength={200}
//                     className='mt-3'
//                     placeholder="Enter Reason"
//                     style={{ width: '100%', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ccc' }}
//                   />
//                 </Col>
//               </Row>
//             </Col>
//           </Row>
//           {checkboxes.length > 0 && (
//             <div>
//               <Row className="m-0 p-0 py-1 px-md-5 px-2 w-100" style={{ display: 'flex' }}>
//                 <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
//                   <label style={{ color: 'maroon' }}>Select Meals Leaves:</label>
//                   <Row>
//                     {checkboxes.map((day, index) => (
//                       <Col sm={12} md={3} key={index}>
//                         <div className="shadow-sm text-center" style={{ marginBottom: '1rem', backgroundColor: 'rgb(235, 244, 244)' }}>
//                           <p className="pt-2" style={{ margin: 0 }}>{day.date}</p>
//                           <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
//                             <FormControlLabel
//                               control={
//                                 <Checkbox
//                                   checked={day.lunch}
//                                   onChange={(e) => handleCheckboxChange(day.date, 'lunch', e.target.checked)}
//                                 />
//                               }
//                               label="Lunch"
//                             />
//                             <FormControlLabel
//                               control={
//                                 <Checkbox
//                                   checked={day.dinner}
//                                   onChange={(e) => handleCheckboxChange(day.date, 'dinner', e.target.checked)}
//                                 />
//                               }
//                               label="Dinner"
//                             />
//                           </div>
//                         </div>
//                       </Col>
//                     ))}
//                   </Row>
//                   <Row>
//                     <Col className='mt-1' style={{ display: "flex", justifyContent: "center" }}>
//                       <Button style={{ backgroundColor: "rgb(0, 120, 215)", color: "white" }} onClick={handleSubmit} disabled={loading}>
//                         {loading ? <Spinner animation="border" size="sm" /> : isUpdating ? 'UPDATE' : 'APPLY'}
//                       </Button>
//                     </Col>
//                   </Row>
//                 </Col>
//               </Row>
//             </div>
//           )}
//         </div>
//         <Row className="m-0 p-0 py-1 pb-5 px-md-5 px-2 w-100" style={{ display: 'flex' }}>
//           <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
//             <MDBDataTable
//               striped
//               bordered
//               className="custom-datatable"
//               hover
//               data={tableData}
//             />
//           </Col>
//         </Row>

//         {/* Confirmation Dialog for Cancel */}
//         <Dialog open={confirmCancelModal} onClose={() => setConfirmCancelModal(false)} maxWidth="sm" fullWidth>
//           <DialogTitle style={{ color: "maroon" }}>Confirm Cancellation</DialogTitle>
//           <DialogContent>
//             <p>Are you sure you want to cancel this leave?</p>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setConfirmCancelModal(false)} color="primary">
//               No
//             </Button>
//             <Button onClick={handleCancelConfirm} color="primary" disabled={loading}>
//               {loading ? <CircularProgress size={24} /> : 'Yes, Cancel'}
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </section>
//     </>
//   );
// };

// export default MessLeaveApplication;











import React, { useState, useEffect } from 'react';
import Sidebar from './sidebarstudent';
import Header from './Headerstudent';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { Col, Row, Spinner } from 'react-bootstrap';
import { Button, TextareaAutosize, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, TextField, Box, FormControlLabel, Checkbox } from '@mui/material';
import { MDBDataTable } from 'mdbreact';
import { MessLeaveAdd, MessLeaveCancel, MessLeaveFindall, MessLeaveUpdate, MessLeaveUpdateFindone } from '../../ApiFile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

dayjs.locale('en-gb');

const MessLeaveApplication = () => {
  const [selectedDates, setSelectedDates] = useState([null, null]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [reason, setReason] = useState('');
  const [leaveData, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confirmCancelModal, setConfirmCancelModal] = useState(false);
  const [cancelLeaveId, setCancelLeaveId] = useState(null);
  const [cancelDateflag, setCancelDateflag] = useState(null); // Track the dateflag for cancellation
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateLeaveId, setUpdateLeaveId] = useState(null);

  const memberId = sessionStorage.getItem('member_id');

  const handleDateChange = (newValue) => {
    setSelectedDates(newValue);
    if (newValue[0] && newValue[1]) {
      const days = [];
      let currentDay = dayjs(newValue[0]);
      const endDay = dayjs(newValue[1]);

      while (currentDay.isBefore(endDay) || currentDay.isSame(endDay, 'day')) {
        days.push({
          date: currentDay.format('DD/MM/YYYY'),
          lunch: false,
          dinner: false
        });
        currentDay = currentDay.add(1, 'day');
      }

      setCheckboxes(days);
    }
  };

  const handleCheckboxChange = (date, mealType, checked) => {
    const isSunday = dayjs(date, 'DD/MM/YYYY').day() == 0; // Day of week (0 = Sunday)
    if (isSunday && mealType == 'dinner') {
      return; // Prevent changing dinner on Sundays
    }
  
    setCheckboxes(checkboxes.map((item) =>
      item.date === date ? { ...item, [mealType]: checked } : item
    ));
  };
  

  const handleReasonChange = (event) => {
    const { value } = event.target;
    if (value.length <= 200) {
      setReason(value);
    }
  };

  // const handleSubmit = async () => {
  //   if (!reason) {
  //     toast.warn("Please provide the reason before proceeding.");
  //     return;
  //   }
  //   setLoading(true);
  //   setError(null);
  
  //   const leaves = checkboxes.map((day) => ({
  //     id: day.id,
  //     leave_date: day.date,
  //     flag: {
  //       lunch: day.lunch ? 1 : 0,
  //       dinner: day.dinner ? 1 : 0,
  //       // dinner: isSunday ? undefined : (day.dinner ? 1 : 0),
  //     }
  //   }));
    
  //   console.log("leaves..", leaves);
  
  //   const data = {
  //     dateflag: isUpdating ? updateLeaveId : '', // Use `updateLeaveId` for updating
  //     member_id: memberId,
  //     reason,
  //     leaves
  //   };
  
  //   try {
  //     const response = await fetch(isUpdating ? MessLeaveUpdate : MessLeaveAdd, {
  //       method: isUpdating ? 'PUT' : 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(data)
  //     });
  
  //     if (response.ok) {
  //       toast.success(isUpdating ? 'Leave application updated successfully!' : 'Leave application submitted successfully!');
  //       fetchLeaveApplications(); // Fetch updated leave data
  //       setSelectedDates([null, null]);
  //       setCheckboxes([]);
  //       setReason('');
  //       setIsUpdating(false);
  //     } else {
  //       const errorData = await response.json();
  //       setError(errorData.message || 'Error saving leave data');
  //       toast.error(errorData.message || 'Error saving leave data');
  //     }
  //   } catch (error) {
  //     setError('Network error');
  //     toast.error('Network error');
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  
const handleSubmit = async () => {
  if (!reason) {
    toast.warn("Please provide the reason before proceeding.");
    return;
  }
  setLoading(true);
  setError(null);

  // Prepare leaves data, excluding dinner flag for Sundays
  const leaves = checkboxes.map((day) => {
    const isSunday = dayjs(day.date, 'DD/MM/YYYY').day() === 0; // Check if it's Sunday
    return {
      id: day.id,
      leave_date: day.date,
      flag: {
        lunch: day.lunch ? 1 : 0,
        // Only include dinner flag if it's not Sunday
        dinner: isSunday ? 1 : (day.dinner ? 1 : 0),
      }
    };
  }).filter(leave => leave.flag.dinner !== undefined); // Filter out leaves where dinner flag is undefined

  const data = {
    dateflag: isUpdating ? updateLeaveId : '', // Use `updateLeaveId` for updating
    member_id: memberId,
    reason,
    leaves
  };

  try {
    const response = await fetch(isUpdating ? MessLeaveUpdate : MessLeaveAdd, {
      method: isUpdating ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      toast.success(isUpdating ? 'Leave application updated successfully!' : 'Leave application submitted successfully!');
      fetchLeaveApplications(); // Fetch updated leave data
      setSelectedDates([null, null]);
      setCheckboxes([]);
      setReason('');
      setIsUpdating(false);
    } else {
      const errorData = await response.json();
      setError(errorData.message || 'Error saving leave data');
      toast.error(errorData.message || 'Error saving leave data');
    }
  } catch (error) {
    setError('Network error');
    toast.error('Network error');
  } finally {
    setLoading(false);
  }
};


  const fetchLeaveApplications = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${MessLeaveFindall}/${memberId}`);
      if (response.ok) {
        const data = await response.json();
        setLeaveData(data);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Error fetching leave data');
        toast.error(errorData.message || 'Error fetching leave data');
      }
    } catch (error) {
      setError('Network error');
      toast.error('Network error');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (leave) => {
    setIsUpdating(true);
    setUpdateLeaveId(leave.dateflag); // Track the leave dateflag for updates
    setSelectedDates([dayjs(leave.fromdate, 'DD/MM/YYYY'), dayjs(leave.todate, 'DD/MM/YYYY')]);
    setReason(leave.reason);

    const days = [];
    let currentDay = dayjs(leave.fromdate, 'DD/MM/YYYY');
    const endDay = dayjs(leave.todate, 'DD/MM/YYYY');

    while (currentDay.isBefore(endDay) || currentDay.isSame(endDay, 'day')) {
      days.push({
        
        date: currentDay.format('DD/MM/YYYY'),
        lunch: false,
        dinner: false
      });
      currentDay = currentDay.add(1, 'day');
    }

    setCheckboxes(days);

    try {
      const response = await fetch(`${MessLeaveUpdateFindone}/${memberId}/${leave.dateflag}`);
      if (response.ok) {
        const data = await response.json();
        setReason(data.reason);
        setCheckboxes(
          data.leaves.map((leaveDay) => ({
            id:leaveDay.id,
            date: dayjs(leaveDay.leave_date, 'DD/MM/YYYY').format('DD/MM/YYYY'),
            lunch: leaveDay.flag.lunch === 1,
            dinner: leaveDay.flag.dinner === 1,
          }))
        );
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Error fetching leave data');
        toast.error(errorData.message || 'Error fetching leave data');
      }
    } catch (error) {
      setError('Network error');
      toast.error('Network error');
    }
  };

  const handleCancel = async (leaveId) => {
    setLoading(true);
    setError(null);

    try {
      const leaveToCancel = leaveData.find(leave => leave.id === leaveId);
      if (leaveToCancel) {
        setCancelDateflag(leaveToCancel.dateflag); 
        setConfirmCancelModal(true);
      } else {
        toast.error('Leave not found');
      }
    } catch (error) {
      setError('Error finding leave data');
      toast.error('Error finding leave data');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelConfirm = async () => {
    if (!cancelDateflag) return;

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${MessLeaveCancel}/${cancelDateflag}`, {
        method: 'GET',
      }
 
    );

      if (response.ok) {
        toast.success('Leave application canceled successfully!');
        fetchLeaveApplications();
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Error canceling leave');
        toast.error(errorData.message || 'Error canceling leave');
      }
    } catch (error) {
      setError('Network error');
      toast.error('Network error');
    } finally {
      setLoading(false);
      setConfirmCancelModal(false);
      setCancelDateflag(null);
    }
  };

  useEffect(() => {
    fetchLeaveApplications();
  }, []);

  const tableData = {
    columns: [
      {
        label: 'Serial No',
        field: 'serialNo',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'From Date',
        field: 'fromdate',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'To Date',
        field: 'todate',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Leaved Meals',
        field: 'leave_count',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Reason',
        field: 'reason',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Update',
        field: 'update',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Cancel',
        field: 'cancel',
        sort: 'asc',
        width: 150,
      },
    ],
    rows: leaveData.map((leave, index) => ({
      serialNo: index + 1,
      fromdate: leave.fromdate,
      todate: leave.todate,
      leave_count: leave.leave_count,
      reason: leave.reason || '',
      status:
        leave.leave_status === "0"
          ? (<Button style={{ backgroundColor: 'orange', color: "white" }}>Pending</Button>)
          : leave.leave_status === "1"
            ? (<Button style={{ backgroundColor: 'green', color: "white" }}>Approved</Button>)
            : leave.leave_status === "2"
              ? (<Button style={{ backgroundColor: 'red', color: "white" }}>Rejected</Button>)
              : '',
      update:
        leave.leave_status === "0" ? (
          <Button onClick={() => handleUpdate(leave)} variant="contained" color="info">
            Update
          </Button>
        ) : null,
      cancel:
        leave.leave_status === "0" ? (
          <Button onClick={() => handleCancel(leave.id)} variant="contained" color="error">
            Cancel
          </Button>
        ) : null,
    }))
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <Header />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Meals Leave Application
          </h2>
        </div>
        <div className="mt-4">
          <Row className="m-0 p-0 py-1 px-md-5 px-2 mt-4 w-100" style={{ display: 'flex' }}>
            <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
              <Row>
                <Col sm={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DemoContainer components={['DateRangePicker']}>
                      <label style={{ color: 'maroon' }}>Select Dates:</label>
                      <DateRangePicker
                        startText="Departure"
                        endText="Return"
                        format="DD/MM/YYYY"
                        value={selectedDates}
                        onChange={handleDateChange}
                        slotProps={{
                          textField: { size: 'small' },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Col>
                <Col sm={12} md={6}>
                  <label className='mt-3' style={{ color: 'maroon' }}>Reason:</label>
                  <TextareaAutosize
                    value={reason}
                    onChange={handleReasonChange}
                    maxLength={200}
                    className='mt-3'
                    placeholder="Enter Reason"
                    style={{ width: '100%', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ccc' }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {checkboxes.length > 0 && (
  <div>
    <Row className="m-0 p-0 py-1 px-md-5 px-2 w-100" style={{ display: 'flex' }}>
      <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
        <label style={{ color: 'maroon' }}>Select Meals Leaves:</label>
        <Row>
          {checkboxes.map((day, index) => {
            const isSunday = dayjs(day.date, 'DD/MM/YYYY').day() === 0;
            return (
              <Col sm={12} md={3} key={index}>
                <div className="shadow-sm text-center" style={{ marginBottom: '1rem', backgroundColor: 'rgb(235, 244, 244)' }}>
                  <p className="pt-2" style={{ margin: 0 }}>{day.date}</p>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={day.lunch}
                          onChange={(e) => handleCheckboxChange(day.date, 'lunch', e.target.checked)}
                        />
                      }
                      label="Lunch"
                    />
                    {!isSunday && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={day.dinner}
                            onChange={(e) => handleCheckboxChange(day.date, 'dinner', e.target.checked)}
                          />
                        }
                        label="Dinner"
                      />
                    )}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col className='mt-1' style={{ display: "flex", justifyContent: "center" }}>
            <Button style={{ backgroundColor: "rgb(0, 120, 215)", color: "white" }} onClick={handleSubmit} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : isUpdating ? 'UPDATE' : 'APPLY'}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
)}

        </div>
        <Row className="m-0 p-0 py-1 pb-5 px-md-5 px-2 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
            <MDBDataTable
              striped
              bordered
              className="custom-datatable"
              hover
              data={tableData}
            />
          </Col>
        </Row>

        {/* Confirmation Dialog for Cancel */}
        <Dialog open={confirmCancelModal} onClose={() => setConfirmCancelModal(false)} maxWidth="sm" fullWidth>
          <DialogTitle style={{ color: "maroon" }}>Confirm Cancellation</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to cancel this leave?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmCancelModal(false)} color="primary">
              No
            </Button>
            <Button onClick={handleCancelConfirm} color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Yes, Cancel'}
            </Button>
          </DialogActions>
        </Dialog>
      </section>
    </>
  );
};

export default MessLeaveApplication;
