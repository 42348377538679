import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import ReportNavbar from './ReportNavbar';
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { DatePicker, Input, Select, Space } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import moment from 'moment';
import 'jspdf-autotable';
import { ReporMessWiseAttendance, ReporMessWiseAttendanceSingleStudent, ReportStudentList } from '../../ApiFile';
const { RangePicker } = DatePicker;


const MessWiseAttendance = () => {

//========Code for All Students ============

const [members, setMembers] = useState([]);
const [date, setDate] = useState(null);
const [mealType, setMealType] = useState(null);
const [allStudents, setAllStudents] = useState(true);
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
const [flag, setFlag] = useState(1);

const handleDateChange = (date,dateString) => {
  setDate(dateString);
};

const handleMealTypeChange = (value) => {
  setMealType(value);
};

const handleAllShow = () => {
  const requestData = {
    leave_date: date,
    flag: parseInt(mealType),
  };

  setFlag(requestData.flag);

  axios.post(ReporMessWiseAttendance, requestData)
    .then(response => setMembers(response.data),
  )
    .catch(error => console.error('Error fetching member list:', error));
};


const rows = members.map((member, index) => ({
  serial: index + 1,
  leave_date: member.leave_date,
  name: member.name,
  block_id: member.block_id,
  room_id: member.room_id,
  lunch: member.lunch,
  dinner: member.dinner,
}));

const data = {
  columns: [
    { label: 'Serial No', field: 'serial', sort: 'asc' },
    { label: 'Date', field: 'leave_date', sort: 'asc' },
    { label: 'Student Name', field: 'name', sort: 'asc' },
    { label: 'Block No', field: 'block_id', sort: 'asc' },
    { label: 'Room No', field: 'room_id', sort: 'asc' },
    ...(flag === 1 ? [{ label: 'Lunch', field: 'lunch', sort: 'asc' }] : []),
    ...(flag === 2 ? [{ label: 'Dinner', field: 'dinner', sort: 'asc' }] : []),
  ],
  rows: rows
};

// Calculate totals
const totalStudentCount = members.length;
const totalPresentStudent = members.filter(member => (flag === 1 ? member.lunch : member.dinner) === 'Present').length;
const totalAbsentStudent = members.filter(member => (flag === 1 ? member.lunch : member.dinner) === 'Absent').length;

const handleAllStudents =()=>{
  setSingleStudent(false);
  setAllStudents(true);
}

const handleExcelData = () => {
  const ws = XLSX.utils.json_to_sheet(rows);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Student_Attendance_(Mess_Wise).xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handlePdfData = () => {
  const doc = new jsPDF();
  const columns = data.columns.map((col) => col.label);
  const rowsData = rows.map((row) => [row.serial,row.leave_date,row.name,row.block_id,row.room_id,row.lunch,row.dinner]);

  const title = 'Student Attendance (Mess Wise)';

  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);

  doc.autoTable({
    head: [columns],
    body: rowsData,
    startY: 20,
  });

  const blob = doc.output('blob');
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Student_Attendance_(Mess_Wise).pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};



//========Code for Single Students ============

const [members1, setMembers1] = useState([]);
const [month, setMonth] = useState('');
const [year, setYear] = useState('');
const [selectedStudentId, setSelectedStudentId] = useState('');
const [students, setStudents] = useState([]);
const [singleStudent, setSingleStudent] = useState(false);

  // Fetch students data from GET API
useEffect(() => {
  axios.get(ReportStudentList)
    .then(response => setStudents(response.data))
    .catch(error => console.error('Error fetching students list:', error));
}, []);


const handleMonthChange = (date,dateString) => {

  if (dateString) {
    const [selectedMonth, selectedYear] = dateString.split('/');
    console.log(`Selected Month: ${selectedMonth}, Selected Year: ${selectedYear}`);
    
    setMonth(selectedMonth);
    setYear(selectedYear);
    
  } else {
    console.log('No date selected');
  }
};


const handleSingleShow = () => {
  const requestData = {
    member_id: selectedStudentId,
    month: month,
    year: year,     
  };
console.log(requestData);


  axios.post(ReporMessWiseAttendanceSingleStudent, requestData)
    .then(response => setMembers1(response.data))
    .catch(error => console.error('Error fetching member list:', error));
};

const rows1 = members1.map((member, index) => ({
  serial: index + 1,
  leave_date: member.leave_date,
  name: member.name,
  block_id: member.block_id,
  room_id: member.room_id,
  lunch: member.lunch,
  dinner: member.dinner,
}));

const data1 = {
  columns: [
    { label: 'Serial No', field: 'serial', sort: 'asc' },
    { label: 'Date', field: 'leave_date', sort: 'asc' },
    { label: 'Student Name', field: 'name', sort: 'asc' },
    { label: 'Block No', field: 'block_id', sort: 'asc' },
    { label: 'Room No', field: 'room_id', sort: 'asc' },
    { label: 'Lunch', field: 'lunch', sort: 'asc' },
    { label: 'Dinner', field: 'dinner', sort: 'asc' },
  ],
  rows: rows1
};

// Calculate totals
const totalLunchPresentCount = members1.filter(member => member.lunch == 'Present').length;
const totalDinnerPresentCount = members1.filter(member => member.dinner == 'Present').length;
const totalLunchAbsentCount = members1.filter(member => member.lunch == 'Absent').length;
const totalDinnerAbsentCount = members1.filter(member => member.dinner == 'Absent').length;

const handleSingleStudent =()=>{
  setSingleStudent(true);
  setAllStudents(false);
}

const handleExcelData1 = () => {
  const ws = XLSX.utils.json_to_sheet(rows1);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Student_Attendance_(Mess_Wise).xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handlePdfData1 = () => {
  const doc = new jsPDF();
  const columns = data1.columns.map((col) => col.label);
  const rowsData = rows1.map((row) => [row.serial,row.leave_date,row.name,row.block_id,row.room_id,row.lunch,row.dinner]);

  const title = 'Student Attendance (Mess Wise)';

  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);

  doc.autoTable({
    head: [columns],
    body: rowsData,
    startY: 20,
  });

  const blob = doc.output('blob');
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Student_Attendance_(Mess_Wise).pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

//===================================================================================


  return (
  
      <>
    <Sidebar />
    <section className="home-section pb-5">
      <NavLogo />
      <ReportNavbar />
      
      <Row className="m-0 p-0 pt-4 px-3 ">
            <Col
                className="rounded text-center py-2"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    border: "none",
                    alignItems: "center",
                    alignContent: "center",
                    color: "maroon",
                }}
            >    
                <div><span style={{color:"maroon",fontSize:"18px"}}>Mess Wise Student Attendance</span></div>
                <div className='d-flex'>
                  <button className='mx-1 px-2 py-1' style={{color:"white",backgroundColor:"grey",fontSize:"13px",}} onClick={handleAllStudents}>All Students</button>
                  <button className='mx-1 px-2 py-1' style={{color:"white",backgroundColor:"grey",fontSize:"13px",}} onClick={handleSingleStudent}>Single Student</button>
                </div>
                
            </Col>
        </Row>

        {allStudents && (

              <div className="row m-0 p-2 p-md-3 ">
                  <div className="row m-0  pb-5" style={{backgroundColor:"white",borderBottom:"1px solid grey"}}>

                  <div className="col-12 text-start my-2"> <span style={{backgroundColor:"#808080",color:"white",padding:"0px 25px",borderRadius:"5px"}}>All Students</span> </div>

                  <div className="col-12 col-lg-1"></div>
                    <div className="col-12 col-md-6 col-lg-4 pt-2 pt-md-0">
                        <label style={{fontWeight:"normal",fontSize:"13px"}}>Select Date :</label>
                        <DatePicker className='w-100' format={dateFormatList}  onChange={handleDateChange} />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 pt-2 pt-md-0">
                        <label style={{fontWeight:"normal",fontSize:"13px"}}>Select Lunch/Dinner :</label>
                        <Select
                            className="w-100"
                            placeholder="Select lunch/dinner"
                            onChange={handleMealTypeChange}
                            options={[
                              { value: '1', label: 'Lunch' },
                              { value: '2', label: 'Dinner' },
                            ]}
                          />
                    </div>
                    <div className="col-12 col-lg-2 pt-3">
                      <Button variant="contained" color="primary" style={{backgroundColor:"#1685f3"}} onClick={handleAllShow}>Show</Button>
                    </div>
                    <div className="col-12 col-lg-1"></div>
                  </div>

                  <div className="row m-0 p-2 mb-5"  style={{backgroundColor:"white"}}>

                  <div className='col-12 text-end py-2'>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#3b9cfd",color:"white"}}  onClick={() => handleExcelData()}>Excel</Button>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#136fcb",color:"white"}} onClick={() => handlePdfData()}>Pdf</Button>
                  </div>

                      <MDBDataTable
                        striped
                        bordered
                        className="custom-datatable"
                        small
                        data={data}
                      />

                      <div className="col-12 text-end p-1" style={{backgroundColor:"black",color:"white"}}>
                          <div className="row border m-0">
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Students: {totalStudentCount}</span>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Present: {totalPresentStudent}</span>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Absent: {totalAbsentStudent}</span>
                            </div>
                          </div>                        
                      </div>

                  </div>
              </div>
        )}

        {singleStudent && (

              <div className="row m-0 p-2 p-md-3 ">
                  <div className="row m-0 pb-5" style={{backgroundColor:"white",borderBottom:"1px solid grey"}}>

                    <div className="col-12 text-start my-2"> <span style={{backgroundColor:"#808080",color:"white",padding:"0px 25px",borderRadius:"5px"}}>Single Student</span> </div>

                    <div className="col-12 col-lg-2"></div>
                    <div className="col-12 col-md-6 col-lg-4 pt-2 pt-md-0">
                        <label style={{fontWeight:"normal",fontSize:"13px"}}>Select Student :</label>
                        <Select
                                    showSearch className='w-100'
                                    placeholder="Select Student"
                                    optionFilterProp="label"
                                    value={selectedStudentId}
                                    onChange={(value) => setSelectedStudentId(value)}
                                    options={students.map(student => ({
                                      value: student.member_id,
                                      label: student.name,
                                    }))}
                                  />
                    </div>

                    <div className="col-12 col-md-6 col-lg-3 pt-2 pt-md-0">
                        <label style={{fontWeight:"normal",fontSize:"13px"}}>Select Month :</label>
                        <DatePicker className='w-100' onChange={handleMonthChange} picker="month" format="MM/YYYY" />
                    </div>

                    <div className="col-12 col-lg-2 pt-3">
                      <Button variant="contained" color="primary" style={{backgroundColor:"#1685f3"}} onClick={handleSingleShow}>Show</Button>
                    </div>
                    <div className="col-12 col-lg-1"></div>


                  </div>

                  <div className="row m-0 p-2 mb-5"  style={{backgroundColor:"white"}}>

                  <div className='col-12 text-end py-2'>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#3b9cfd",color:"white"}}  onClick={() => handleExcelData1()}>Excel</Button>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#136fcb",color:"white"}} onClick={() => handlePdfData1()}>Pdf</Button>
                  </div>

                      <MDBDataTable
                        striped
                        bordered
                        className="custom-datatable"
                        small
                        data={data1}
                      />

                      <div className="col-12 text-end p-1" style={{backgroundColor:"black",color:"white"}}>
                          <div className="row border m-0">
                            <div className="col-12 col-md-6 col-lg-3 text-center">
                                <span>Total Lunch Present: {totalLunchPresentCount}</span>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 text-center">
                                <span>Total Dinner Present: {totalDinnerPresentCount}</span>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 text-center">
                                <span>Total Lunch Absent: {totalLunchAbsentCount}</span>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 text-center">
                                <span>Total Dinner Absent: {totalDinnerAbsentCount}</span>
                            </div>
                          </div>                        
                      </div>


                  </div>
              </div>
        )}

    </section>
    </>
  )
}

export default MessWiseAttendance