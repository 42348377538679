import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Container,Row} from "react-bootstrap";
import { EventFindApi } from "../../ApiFile";

const Newscard = () => {
  const [news, setNews] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    // Fetch data from API
    const fetchNews = async () => {
      try {
        const response = await axios.get(EventFindApi);
        const fetchedNews = response.data.map((event) => ({
          id: event.event_id,
          title: `Event ${event.event_id}`,
          content: event.discription,
          date: event.event_date,
        }));
        setNews(fetchedNews);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  // Automatically scroll the news content vertically
  useEffect(() => {
    const scrollHeight = scrollRef.current.scrollHeight;
    let scrollInterval;

    if (!isPaused) {
      scrollInterval = setInterval(() => {
        scrollRef.current.scrollTop += 1;
        if (scrollRef.current.scrollTop >= scrollHeight) {
          scrollRef.current.scrollTop = 0;
        }
      }, 50);
    }

    return () => clearInterval(scrollInterval);
  }, [isPaused]);

  return (
    <>
    <Container style={{boxShadow:' grey 2px 1px 4px 1px',marginLeft:'2px',backgroundColor:'white'}}>
      <Row><h2 style={{backgroundColor:'blanchedalmond',padding:'5px',boxShadow:' grey 2px 1px 1px 1px',color:'tomato'}}>EVENTS</h2></Row>
    <div 
      className="news-container" 
      ref={scrollRef} 
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)} 
    > 
      {news.map((item) => (
        <div key={item.id} className="news-item" style={{border:'none'}}>
              <span style={{ animation: "blinking 0.4s infinite", marginRight: "5px", color: "red",fontSize:'20px' }}><b>💥</b></span>
 <span style={{color:'black',fontWeight:'500'}}>📅{item.date}</span> <span style={{color:'red'}}><b>{item.content}</b></span> 
         
        </div>
      ))}
    </div>
    <style jsx>{`
        @keyframes blinking {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .news-container {
          height: 500px;
          overflow: hidden;
        }
        
        .news-wrapper {
          animation: scroll 10s linear infinite;
          animation-play-state: running;
        }
        
        .news-wrapper:hover {
          animation-play-state: paused;
        }
        
        .news-item {
          border: 1px solid #ccc;
          padding: 10px;
          margin: 10px 0;
        }
        
        @keyframes scroll {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(calc(-100% * 5));
          }
        }
      `}</style></Container></>
  );
};

export default Newscard;
