import React, { useEffect, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineForm, AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./sidebar.css";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  let userdata = sessionStorage.getItem("userdata");

  const logoutFun = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    if (userdata == null) {
      sessionStorage.clear();
      navigate("/");
    }
  }, []);
  return (
    <div
      className={`sidebar ${isOpen ? "open" : ""}`}
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1",
        overflow: "scroll",
      }}
    >
      <div className="logo-details mt-4">
        <i className="bx bxs-landscape"></i>
        <div
          className="logo_name"
          style={{
            marginLeft: "-32px",
            color: "maroon",
            textShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
            fontFamily: "Arial, sans-serif",
          }}
        >
          WEL- COME
        </div>
        <i
          className={`fa ${isOpen ? "fa-solid fa-bars" : "fa-solid fa-bars"}`}
          id="btn"
          onClick={toggleSidebar}
        ></i>
      </div>
      <div className="sidebar-content">
        <ul className="nav-list mb-5">
          <li>
            <a href="/#/AluminiDashboard" className="active ">
              <i className="fa fa-line-chart"></i>
              <span className="links_name">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="/#/Jobpost">
              <i class="fa-solid fa-folder-plus"></i>
              <span className="links_name">Job Post</span>
            </a>
          </li>
          <li>
            <a href="/#/AluminiAllJob">
            <i className="fa-solid fa-list"></i>
              <span className="links_name">Jobs</span>
            </a>
          </li>

          <li>
            <a href="/#/GalleryAlumini">
              <i class="fa-solid fa-image"></i>
              <span className="links_name">Gallery</span>
            </a>
          </li>

          <li>
            <a href="/#/Suggestion">
              <i class="fa-solid fa-file-circle-plus"></i>
              <span className="links_name">Suggestion Form</span>
            </a>
          </li>
          <li>
            {/* Use <button> instead of < className=''a> for better accessibility */}
            <a onClick={logoutFun}>
              <i class="fa-solid fa-circle-arrow-left"></i>
              <span className="links_name">Log Out</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
