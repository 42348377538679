import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReportNavbar from './ReportNavbar';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { AiFillContainer } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { ReportDashboard } from '../../ApiFile';

const AdminDashboardReport = () => {

  const navigate = useNavigate();
  const [eventCompleteCount,setEventCompleteCount]=useState('')
  const [data, setData] = useState({
    newadmit: 0,
    readmit: 0,
    regformpay: 0,
    todayfeesum: 0,
    totalfeesum: 0,
    todaydeposit: 0,
    totaldeposit: 0,
    carryforward: 0,
    totalrefundhostelsum: 0,
    totalrefundhostelcount: 0,
    todayrefundhostelfeesum: 0,
    todayrefundhostelcount: 0,
    vaccantbedcount: 0,
    bookedbedcount: 0,
    // allbook_pendingcount: []
  });
  // const pendingCountObj = data.allbook_pendingcount.find(item => item.flag == '2');
  // const pendingCountObj1 = data.allbook_pendingcount.find(item => item.flag == '0');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const year = sessionStorage.getItem('selectedYear');
        const response = await axios.post(ReportDashboard, { year });
        setData(response.data);
        const eventCount = response.data.eventcompletecount?.[0]?.eventcompletecount ?? null;
        setEventCompleteCount(eventCount);
      } 
       catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

//=======CSS=================================
const divLabel={
  fontSize:"14px",color:"black"
}
//========================================
  return (
    <>
      <Sidebar />
      <section className="home-section pb-5">
        <NavLogo />
        <ReportNavbar />

        <Row className="m-0 pt-4 px-3 px-md-5">
          <Col
            className="rounded py-2"
            style={{
              display: "flex",
              justifyContent: "start",
              backgroundColor: "white",
              border: "0px",
              alignItems: "center",
              alignContent: "center",
              color: "maroon",
            }}
          >
            <div><span style={{ color: "maroon", fontSize: "18px" }}> Report Dashboard</span></div>
          </Col>
        </Row>

        <div className='row m-0 px-md-5 pb-5'>

            {/* //=======Admission Count=======// */}
            <div className="col-12 col-md-4 p-2">

              <div className='rounded' style={{ backgroundColor: "white", }}>

                <div className="row m-0 p-2" style={{ borderBottom: "5px solid #f87808",height:"120px" }}>

                  <div className="col-12 pb-2 text-center" style={{fontSize:"16px",fontWeight:"500px"}}>
                    <span><u>Admission Count</u></span>
                  </div>

                  <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                      <div className="col-9 fs-5"><span style={divLabel}>New Admit:</span> {data.newadmit !== null ? data.newadmit : 'NA'}</div>
                      <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/NewAdmit')} >View</button></div>
                  </div>

                  <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                      <div className="col-9 fs-5"><span style={divLabel}>Re Admit:</span>{data.readmit !== null ? data.readmit : 'NA'}</div>
                      <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/ReAdmit')} >View</button></div>
                  </div>

                </div>

              </div>

            </div>

             {/* //=======Bed Count=======// */}
             <div className="col-12 col-md-4 p-2">

              <div className='rounded' style={{ backgroundColor: "white" }}>

                <div className="row m-0 p-2" style={{ borderBottom: "5px solid #f87808",height:"120px" }}>

                  <div className="col-12 pb-2 text-center" style={{fontSize:"16px",fontWeight:"500px"}}>
                    <span><u>Bed Count</u></span>
                  </div>

                  <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                      <div className="col-9 fs-5"><span style={divLabel}>Vacant:</span> {data.vaccantbedcount !== null ? data.vaccantbedcount : 'NA'}</div>
                      <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/VacantRoomBed')} >View</button></div>
                  </div>

                  <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                      <div className="col-9 fs-5"><span style={divLabel}>Booked:</span>{data.bookedbedcount !== null ? data.bookedbedcount : 'NA'}</div>
                      <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/AllBookedRoomBed')} >View</button></div>
                  </div>

                </div>

              </div>

              </div>

              {/* //=======Hostel Fee=======// */}
              <div className="col-12 col-md-4 p-2">

                <div className='rounded' style={{ backgroundColor: "white" }}>

                  <div className="row m-0 p-2" style={{ borderBottom: "5px solid #f87808",height:"120px" }}>

                    <div className="col-12 pb-2 text-center" style={{fontSize:"16px",fontWeight:"500px"}}>
                      <span><u>Hostel Fee</u></span>
                    </div>

                    <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                        <div className="col-9 fs-5"><span style={divLabel}>Today's:</span> {data.todayfeesum !== null ? data.todayfeesum : 'NA'}₹</div>
                        <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/TodayFeeReport')} >View</button></div>
                    </div>

                    <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                        <div className="col-9 fs-5"><span style={divLabel}>Total:</span>{data.totalfeesum !== null ? data.totalfeesum : 'NA'}₹</div>
                        <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/TotalFeeReport')} >View</button></div>
                    </div>

                  </div>

                </div>

             </div>

              {/* //=======Registration Form Payment=======// */}
              <div className="col-12 col-md-4 p-2">

                <div className='rounded' style={{ backgroundColor: "white" }}>

                  <div className="row m-0 p-2" style={{ borderBottom: "5px solid #f87808",height:"120px" }}>

                    <div className="col-12 pb-2 text-center" style={{fontSize:"16px",fontWeight:"500px"}}>
                      <span><u>Reg.Form Payment</u></span>
                    </div>

                    <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                        <div className="col-9 fs-5"><span style={divLabel}>Reg.Form:</span> {data.regformpay !== null ? data.regformpay : 'NA'}₹</div>
                        <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/TotalApplicationFormFee')} >View</button></div>
                    </div>

                  </div>

                </div>

              </div>

                 {/* //======= Event Completed Count=======// */}
                 <div className="col-12 col-md-4 p-2">

                  <div className='rounded' style={{ backgroundColor: "white" }}>

                    <div className="row m-0 p-2" style={{ borderBottom: "5px solid red",height:"120px" }}>

                      <div className="col-12 pb-2 text-center" style={{fontSize:"16px",fontWeight:"500px"}}>
                        <span><u>Event Completed</u></span>
                      </div>

                      <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                          <div className="col-9 fs-5"><span style={divLabel}>Event Completed No:</span> {eventCompleteCount !== null ? eventCompleteCount : 'NA'}</div>
                          <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/EventWiseAttendance')} >View</button></div>
                      </div>

                    </div>

                  </div>

                </div>

                  {/* //=======Mess Attendance Count=======// */}
               <div className="col-12 col-md-4 p-2">

                <div className='rounded' style={{ backgroundColor: "white" }}>

                  <div className="row m-0 p-2" style={{ borderBottom: "5px solid #f87808",height:"120px" }}>

                    <div className="col-12 pb-2 text-center" style={{fontSize:"16px",fontWeight:"500px"}}>
                      <span><u>Mess Attendance</u></span>
                    </div>

                    <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                        <div className="col-9 fs-5"><span style={divLabel}>Yesterday's Dinner:</span> {data.messLeaveLunch !== null ? data.messLeaveLunch : 'NA'}</div>
                        <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/MessWiseAttendance')} >View</button></div>
                    </div>

                    <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                        <div className="col-9 fs-5"><span style={divLabel}>Today's Lunch:</span>{data.mess_leavedinner !== null ? data.mess_leavedinner : 'NA'}</div>
                        <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/MessWiseAttendance')} >View</button></div>
                    </div>

                  </div>

                </div>

              </div>

               {/* //=======Hostel Fee Refund=======// */}
              <div className="col-12 col-md-4 p-2">

                <div className='rounded' style={{ backgroundColor: "white" }}>

                  <div className="row m-0 p-2" style={{ borderBottom: "5px solid red",height:"120px" }}>

                    <div className="col-12 pb-2 text-center" style={{fontSize:"16px",fontWeight:"500px"}}>
                      <span><u>Hostel Fee Refund</u></span>
                    </div>

                    <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                        <div className="col-7 fs-5"><span style={divLabel}>Today's:</span> {data.todayrefundhostelfeesum !== null ? data.todayrefundhostelfeesum : 'NA'}₹</div>

                        <div className="col-2 p-0"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/TodaysRefundHostel')} >View</button></div>

                        <div className="col-3 text-end p-0">
                          <span style={{fontSize:"13px"}}>{data.todayrefundhostelcount !== null ? data.todayrefundhostelcount : 'NA'}No.</span>
                        </div>

                    </div>

                    <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>

                        <div className="col-7 fs-5"><span style={divLabel}>Total:</span>{data.totalrefundhostelsum !== null ? data.totalrefundhostelsum : 'NA'}₹</div>

                        <div className="col-2 p-0"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/TotalRefundHostel')} >View</button></div>

                        <div className="col-3 text-end p-0">
                          <span style={{fontSize:"13px"}}>{data.totalrefundhostelcount !== null ? data.totalrefundhostelcount : 'NA'}No.</span>
                        </div>

                    </div>

                  </div>

                </div>

              </div>

               {/* //=======Deposit Return Count=======// */}
               <div className="col-12 col-md-4 p-2">

                <div className='rounded' style={{ backgroundColor: "white" }}>

                  <div className="row m-0 p-2" style={{ borderBottom: "5px solid red",height:"120px" }}>

                    <div className="col-12 pb-2 text-center" style={{fontSize:"16px",fontWeight:"500px"}}>
                      <span><u>Deposit Return Count</u></span>
                    </div>

                    <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                        <div className="col-9 fs-5"><span style={divLabel}>Today's:</span> {data.return_deposittoday !== null ? data.return_deposittoday : 'NA'}</div>
                        <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/TodayDepositReturnReport')} >View</button></div>
                    </div>

                    <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                        <div className="col-9 fs-5"><span style={divLabel}>Total:</span>{data.return_deposittotal !== null ? data.return_deposittotal : 'NA'}</div>
                        <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/TotalDepositReturnReport')} >View</button></div>
                    </div>

                  </div>

                </div>

              </div>

            {/* //=======Discharge Count=======// */}
            <div className="col-12 col-md-4 p-2">

              <div className='rounded' style={{ backgroundColor: "white" }}>

                <div className="row m-0 p-2" style={{ borderBottom: "5px solid red",height:"120px" }}>

                  <div className="col-12 pb-2 text-center" style={{fontSize:"16px",fontWeight:"500px"}}>
                    <span><u>Discharge Count</u></span>
                  </div>

                  <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                      <div className="col-9 fs-5"><span style={divLabel}>Today's:</span> {data.discharge_today !== null ? data.discharge_today : 'NA'}</div>
                      <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/DischargeReport')} >View</button></div>
                  </div>

                  <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                      <div className="col-9 fs-5"><span style={divLabel}>Total:</span>{data.discharge_total !== null ? data.discharge_total : 'NA'}</div>
                      <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/DischargeReport')} >View</button></div>
                  </div>

                </div>

              </div>

            </div>


              {/* //======= Total Deposit Payment=======// */}
              <div className="col-12 col-md-4 p-2">

                  <div className='rounded' style={{ backgroundColor: "white" }}>

                    <div className="row m-0 p-2" style={{ borderBottom: "5px solid red",height:"140px" }}>

                      <div className="col-12 pb-2 text-center" style={{fontSize:"16px",fontWeight:"500px"}}>
                        <span><u>Total Student Deposit</u></span>
                      </div>

                      <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>
                          <div className="col-9 fs-5"><span style={divLabel}>Total:</span> {data.totaldeposit !== null ? data.totaldeposit : 'NA'}₹</div>
                          <div className="col-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/TotalDepositReport')} >View</button></div>
                      </div>

                    </div>

                  </div>

              </div>

               {/* //======= Student Leave Count=======// */}
               <div className="col-12 col-md-4 p-2">  

                  <div className='rounded' style={{ backgroundColor: "white" }}>

                    <div className="row m-0 p-2" style={{ borderBottom: "5px solid #f87808",height:"140px" }}>

                      <div className="col-12 pb-2 text-center" style={{fontSize:"16px",fontWeight:"500px"}}>
                        <span><u>Student Today's Leave</u></span>
                      </div>

                      <div className="row m-0 p-0" style={{display:"flex",alignItems:"center"}}>

                          <div className="col-12">

                              <div className="row px-2">
                              <div className="col-2 p-0">
                                      <span style={divLabel}>Mess -</span>                            
                                  </div>
                                  <div className="col-5">
                                      <span style={divLabel}>Lunch:</span> {data.messLeaveLunch !== null ? data.messLeaveLunch : 'NA'}                            
                                  </div>
                                  <div className="col-5">
                                      <span style={divLabel}>Dinner:</span> {data.mess_leavedinner !== null ? data.mess_leavedinner : 'NA'}
                                  </div>
                              </div>

                              <div className="row px-2">
                                  <div className="col-6 p-0">
                                    <span style={divLabel}>Event:</span> {data.event_leave !== null ? data.event_leave : 'NA'}
                                  </div>
                                  <div className="col-6 p-0">
                                      <span style={divLabel}>Regular:</span> {data.regular_leave !== null ? data.regular_leave : 'NA'}
                                  </div>
                              </div>

                              <div className="row px-2">
                                  <div className="col-12 d-flex justify-content-center pt-1"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/StudentLeaveReport')} >View</button></div>
                              </div>

                          </div>

                          
                      </div>

                    </div>

                  </div>

               </div>



          {/* <div className="col-12 col-md-3 p-2">
            <div className='rounded' style={{ backgroundColor: "white" }}>
              <div className="row m-0 p-2" style={{ borderBottom: "5px solid red" }}>
                <div className="col-12" style={{fontSize:"16px"}}>
                  <AiFillContainer style={{ color: "gray" }} />&nbsp; Today Deposit Return
                </div>
                <div className="col-6 pt-2 fs-5"> {pendingCountObj ? pendingCountObj.flag_count : 'NA'}</div>
                <div className="col-6 pt-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/TodayDepositReturnReport')} >View</button></div>
              </div>  
            </div>
          </div>

          <div className="col-12 col-md-3 p-2">
            <div className='rounded' style={{ backgroundColor: "white" }}>
              <div className="row m-0 p-2" style={{ borderBottom: "5px solid red" }}>
                <div className="col-12" style={{fontSize:"16px"}}>
                  <AiFillContainer style={{ color: "gray" }} />&nbsp; Total Deposit Return
                </div>
                <div className="col-6 pt-2 fs-5"> {pendingCountObj1 ? pendingCountObj1.flag_count : 'NA'}</div>
                <div className="col-6 pt-3"> <button style={{backgroundColor:"#09adf9",padding:"2px 10px",fontSize:"14px"}} onClick={() => navigate('/TotalDepositReturnReport')} >View</button></div>
              </div>  
            </div>
          </div> */}

          
        </div>

      </section>
    </>
  );
};

export default AdminDashboardReport;
