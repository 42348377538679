import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Sidebar from '../sidebar';
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import NavLogo from '../NavLogo';
import { ImagePath, JobApprovalFindallApi, JobPostApprovalReject, JobpostApprovalView} from '../../ApiFile';



export default function JobApproval () {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedPayType, setSelectedPayType] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [statusRow, setStatusRow] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);

  const handleCheckBoxChange = () => {
    setCheckBoxChecked(!checkBoxChecked);
  };

  function capitalizeWords(str) {
    return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  }

  const fetchData = () => {
    axios.get(JobApprovalFindallApi)
      .then(response => {
        const mappedRows = response.data.map((row, index) => ({
          ...row,
          Name: row.name,
        }));
        setRows(mappedRows);
        // console.log("mapped rows: ", mappedRows);
      })
      .catch(error => {
        // console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleApproval = () => {
    if (!checkBoxChecked) {
      alert("Please select the checkbox before proceeding.");
      return;
    }

    const approvalData = {
      id: statusRow?.id,
      status: 1, // Flag for approva
    };
    console.log("approval data: ", approvalData);

    axios.put(JobPostApprovalReject, approvalData)
      .then(res => {
        // console.log(res);
        if (res.data == 'APPROVAL') {
   
          toast.success("Approved Successfully..!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        
          setCheckBoxChecked('')
          fetchData(); 
          setStatusModalOpen(false);
        }
      })
      .catch((err) => {
        // console.error("Failed to save data:", err);
        toast.error("Failed...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  const handleDelete = () => {
    if (!checkBoxChecked) {
      // Display toast or alert when checkbox is not checked
      alert("Please select the checkbox before proceeding.");
      return;
    }

    const rejectionData = {
      id: statusRow?.id,
      status: 2, 
    };
    console.log("rejection data: ", rejectionData);

    axios.put(JobPostApprovalReject, rejectionData)
      .then(res => {
        // console.log(res);
        setDialogOpen(false);
        if (res.data === "REJECT") {
          // console.log("Rejected successfully:", res.data);
          toast.success("Rejected Successfully..!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setStatusModalOpen(false)
          setCheckBoxChecked('')
          fetchData(); // Refresh data after rejection
        }
      })
      .catch((err) => {
        // console.error("Failed to save data:", err);
        toast.error("Failed...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  const handleViewClick = (row) => {
    setSelectedRow(row);
    setOpen(true);

    axios.get(`${JobpostApprovalView}/${row.id}`)
      .then(response => {
        // console.log("findOne API response: ", response);
        setSelectedRow(response.data[0]);
        // console.log("selected row: ", response.data);
      })
      .catch(error => {
        // console.error(error);
      });
  };

  const handleDeleteConfirmation = (id) => {
    if (!checkBoxChecked) {
      alert("Please select the checkbox before proceeding.");
      return;
    }
    setRowToDelete(id);
    setDialogOpen(true);
  };



  const handleStatusModalOpen = (row) => {
    setStatusRow(row);
    handleViewClick(row);
    setStatusModalOpen(true);
  };

  const data = {
    columns: [
      { label: 'Sr No', field: 'srn' },
      { label: 'Category Name', field: 'categoryname', width: 150 },
      { label: 'Job Posted Date', field: 'jobpost_date', width: 150 },
      { label: 'Job Posted By', field: 'jobpost_name', width: 150 },
      {
        label: 'View',
        field: 'View',
        render: (row) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleViewClick(row)}
            style={{ fontSize: '12px' }}
          >
          <span className='me-1'>View </span>    <i class="fa-regular fa-eye"></i>
          </Button>
        )
      },
      {
        label: 'Status',
        field: 'Status',
        render: (row) => {
          if (row.admin_approval == 0) {
            return (
              <Button
                variant="contained"
        style={{ backgroundColor: 'orange', color: 'white', fontSize: '12px' }}
                onClick={() => handleStatusModalOpen(row)}
    
              >
               <span className='me-1'>Pending</span>   <i class="fas fa-spinner"></i>
              </Button>
            );
          } else if (row.admin_approval == 1) {
            return (
              <Button
                variant="contained"
                style={{ backgroundColor: 'green', color: 'white', fontSize: '12px' }}
                disabled
              >
               <span className='me-1'>Approved</span>   <i class="fas fa-check"></i>
              </Button>
            );
          } else {
            return (
              <Button
                variant="contained"
                style={{ backgroundColor: 'red', color: 'white', fontSize: '12px' }}
                disabled
              >
              <span className='me-1'>Rejected</span>    <i class="fas fa-times"></i>
              </Button>
            );
          }
        }
      },
     
    ],
    rows: rows.map((row,index) => ({
      srn: index + 1,
      ...row,
      View: (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleViewClick(row)}
          style={{ fontSize: '12px' }}
        >
           <span className='me-1'>View</span> <i class="fa-regular fa-eye"></i>
        </Button>
      ),
      Status: row.admin_approval == 0 ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleStatusModalOpen(row)}
        style={{ backgroundColor: 'orange', color: 'white', fontSize: '12px' }}
        >
       <span className='me-1'>Pending</span> <i class="fas fa-spinner"></i>
        </Button>
      ) : row.admin_approval == 1 ? (
        <Button
          variant="contained"
          style={{ backgroundColor: 'green', color: 'white', fontSize: '12px' }}
          disabled
        >
          <span className='me-1'>Approved</span>  <i class="fas fa-check"></i>
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{ backgroundColor: 'red', color: 'white', fontSize: '12px' }}
          disabled
        >
          <span className='me-1'>Rejected</span>  <i class="fas fa-times"></i>
        </Button>
      )
      
    }))
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Job Approval
          </h2>
        </div>

        <Row className="m-0 p-0 px-md-3 py-1 pb-5 mb-5 mt-4 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable striped className="custom-datatable text-center" bordered hover small data={data} />
              </div>
            </div>
          </Col>
        </Row>

        <Modal
  open={open}
  onClose={() => setOpen(false)}
  aria-labelledby="status-modal-title"
          aria-describedby="status-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '700px',
              maxHeight: '90vh',
              overflow:"scroll",
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
            }}
  >
    <Box
      sx={{
        overflowY: 'auto',
        overflowY:"scroll",
        p: 4,
        flex: 1,
      }}
    >
      <Typography id="modal-modal-description" style={{lineHeight:"18px"}}>
        <div className="block-header">
        <h2 className="mb-3" style={{ color: 'maroon' ,borderBottom:"2px solid maroon"}}>
        Job Approval
          </h2>
        </div>

        <Row>
          <Col ><b>Job Posted By</b>:</Col>
          <Col >{selectedRow.jobpost_name }</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Contact Number</b>:</Col>
          <Col >{selectedRow.jobpost_mobno}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Employer</b>:</Col>
          <Col >{selectedRow.industry_name}</Col>
        </Row>
        <br/>
        <Row>
          <Col> <b>Job Title</b>:</Col>
          <Col> {selectedRow.job_title} </Col>
        </Row>
        <br/>
        <Row>
          <Col> <b>Job Category</b>:</Col>
          <Col> {selectedRow.categoryname} </Col>
        </Row>
        <br/>
        <Row>
          <Col> <b>Job Type</b>:</Col>
          <Col> {selectedRow.job_type === 1 ? 'Full-Time' : selectedRow.job_type === 2 ? 'Part-Time' : selectedRow.job_type === 3 ? 'Internship' : selectedRow.job_type === 4 ? 'Contract' : '-'} </Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Discription</b>:</Col>
          <Col >{selectedRow.discription}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Qualification</b>:</Col>
          <Col >{selectedRow.qualification}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b> Other Qualification</b>:</Col>
          <Col >{selectedRow.other_qualification}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Experience</b>:</Col>
          <Col >{selectedRow.experience}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Vaccancy</b>:</Col>
          <Col >{selectedRow.vaccancy}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Location</b>:</Col>
          <Col >{selectedRow.address}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Email ID.</b>:</Col>
          <Col >{selectedRow.email_id}  </Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Contact Number</b>: </Col>
          <Col >{selectedRow.contact} </Col>
        </Row>
        <br/>
        <Row style={{display:"flex"}}>
        <Col ><b>Image</b>: </Col>
        <Row>
        <Col md={3}></Col>
        <Col className=" mt-2 text-center"  md={6}>
          <img src={`${ImagePath}/jobpost_image/${selectedRow.jobpost_image}`} className='acceptable rounded shadow border-0' alt='' style={{ height: "16rem",width:"15rem", border: "1px solid grey" }} />
        </Col>
        <Col md={3}></Col>
        </Row>
        </Row> 
        </Typography>

  
 
    </Box>
    <Box
      sx={{
        p: 2,
        borderTop: '1px solid #ccc',
        textAlign: 'right',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(false)}
      >
        Close
      </Button>
    </Box>
  </Box>
</Modal>



        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        >
          <DialogTitle>{"Reject Job Post"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you really want to reject the job posted ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDelete(rowToDelete)} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
          open={statusModalOpen}
          onClose={() => setStatusModalOpen(false)}
          aria-labelledby="status-modal-title"
          aria-describedby="status-modal-description"
          sx={{
            display: 'flex',
            overflow:'scroll',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '700px',
              maxHeight: '90vh',
              overflow:"scroll",
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                p: 3,
                borderTop: '1px solid #ccc',
                // textAlign: 'right',
              }}
            >
            <Row>
            <div className="block-header">
          <h2 className="mb-2  mt-1 " style={{ color: 'maroon' ,borderBottom:"2px solid maroon"}}>
       Job Approval
          </h2>
        </div>

        <Box
      sx={{
        overflowY: 'auto',
        p: 4,
        flex: 1,
      }}
    >
      <Typography id="modal-modal-description" style={{lineHeight:"18px"}}>
   
      <Row>
          <Col ><b>Job Posted By</b>:</Col>
          <Col >{selectedRow.jobpost_name }</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Contact Number</b>:</Col>
          <Col >{selectedRow.jobpost_mobno}</Col>
        </Row>
        <br/>
      <Row>
          <Col ><b>Employer</b>:</Col>
          <Col >{selectedRow.industry_name}</Col>
        </Row>
        <br/>
        <Row>
          <Col> <b>Job Title</b>:</Col>
          <Col> {selectedRow.job_title} </Col>
        </Row>
        <br/>
        <Row>
          <Col> <b>Job Category</b>:</Col>
          <Col> {selectedRow.categoryname} </Col>
        </Row>
        <br/>   
        <Row>
          <Col> <b>Job Type</b>:</Col>
          <Col> {selectedRow.job_type === 1 ? 'Full-Time' : selectedRow.job_type === 2 ? 'Part-Time' : selectedRow.job_type === 3 ? 'Internship' : selectedRow.job_type === 4 ? 'Contract' : '-'} </Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Discription</b>:</Col>
          <Col >{selectedRow.discription}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Qualification</b>:</Col>
          <Col >{selectedRow.qualification}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b> Other Qualification</b>:</Col>
          <Col >{selectedRow.other_qualification}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Experience</b>:</Col>
          <Col >{selectedRow.experience}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Vaccancy</b>:</Col>
          <Col >{selectedRow.vaccancy}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Location</b>:</Col>
          <Col >{selectedRow.address}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Email ID.</b>:</Col>
          <Col >{selectedRow.email_id}  </Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Contact Number</b>: </Col>
          <Col >{selectedRow.contact} </Col>
        </Row>
        <br/>
        <Row style={{display:"flex"}}>
        <Col ><b>Image</b>: </Col>
        <Row>
        <Col md={3}></Col>
        <Col className=" mt-2 text-center"  md={6}>
          <img src={`${ImagePath}/jobpost_image/${selectedRow.jobpost_image}`} className='acceptable rounded shadow border-0' alt='' style={{ height: "16rem",width:"15rem", border: "1px solid grey" }} />
        </Col>
        <Col md={3}></Col>
        </Row>
        </Row> 
        </Typography>
</Box>
   

        
      <Box   sx={{
                p: 2,
              }}>
        <Checkbox className='ps-0 m-0' style={{position:"relative"}} checked={checkBoxChecked} onChange={handleCheckBoxChange}/> <b>Check And Proceed..</b>
     <Row className='mt-1' style={{display:"flex"}}>

              <Col>
              <Button
                variant="contained"
                color="primary"
                className='w-100'
                onClick={() => handleApproval(statusRow?.reg_id)}
                style={{ marginRight: '10px' }}
              >
                Approve
              </Button>
              </Col>
              <Col>
              <Button
                variant="contained"
                className='w-100'
                color="secondary"
                onClick={() => handleDeleteConfirmation(statusRow?.reg_id)}
              >
                Reject
              </Button>
              </Col>
              </Row>
              </Box>
            </Row>
            </Box>
            <Box
              sx={{
                p: 2,
                borderTop: '1px solid #ccc',
                textAlign: 'right',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setStatusModalOpen(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </section>
    </>
  );
}
 

