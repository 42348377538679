import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { Col, Row } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import { BlockFindallApi, RoomAddApi, RoomFindAllApi } from '../../ApiFile';

const Room = () => {
  const [showModal, setShowModal] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [blockId, setBlockId] = useState('');
  const [blocks, setBlocks] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBlocks();
    fetchRoomData();
  }, []);

  const fetchBlocks = async () => {
    try {
      const response = await fetch(BlockFindallApi);
      if (!response.ok) {
        throw new Error('Failed to fetch block data');
      }
      const data = await response.json();
      if (data === "NOTFOUND") {
        console.log("if ");
        setBlocks([]); // Set rules to an empty array if "NOTFOUND"
    } else {
      console.log("else ");
      setBlocks(data);
    }
      
    } catch (error) {
      // console.error('Error fetching block data:', error);
      setError('Failed to fetch block data');
    }
  };

  const fetchRoomData = async () => {
    try {
      const response = await fetch(RoomFindAllApi);
      if (!response.ok) {
        throw new Error('Failed to fetch room data');
      }
      const data = await response.json();
      if (data === "NOTFOUND") {
        console.log("if ");
        setRooms([]); // Set rules to an empty array if "NOTFOUND"
    } else {
      console.log("else ");
      const roomsWithId = data.map((room, index) => ({ ...room, id: index + 1 }));
      setRooms(roomsWithId);
    }
      
    
    } catch (error) {
      // console.error('Error fetching room data:', error);
      setError('Failed to fetch room data');
    }
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const savedata = async (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
        const response = await fetch(RoomAddApi, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ room_id: roomName, block_id: blockId }),
        });
        if (!response.ok) {
            throw new Error('Failed to add room');
        }
        fetchRoomData();
        handleCloseModal();
        handleClose();
        toast.success('Room saved successfully');
    } catch (error) {
        // console.error('Error adding room:', error);
        setError('Failed to add room');
    }
};

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    setRoomName('');
    setBlockId('');
  };
  const columns = [
    { label: 'Sr.No', field: 'srNo', sort: 'asc' },
    { label: 'Room Number', field: 'room_id', sort: 'asc' },
    { label: 'Block Number', field: 'block_id', sort: 'asc' },
    // { label: 'Edit', field: 'edit', sort: 'asc' },
  ];

  const rows = rooms.map((item, index) => ({
    srNo: index + 1,
    room_id: item.room_id,
    block_id: item.block_id,
    // edit: (
    //   <button
    //     className="m-1"
    //     style={{ color: "rgb(255, 93, 0)", border: "0px", backgroundColor: "transparent" }}
    //     onClick={() => handleEditShow(item)}
    //   >
    //     <i className="fa-solid fa-pen-to-square"></i>
    //   </button>
    // ),
  }));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (                      

     <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />

        <div>
          <div className="block-header">
            <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Room Master
            </h2>
            <Button
              className="ms-md-5 m-0  ms-3  mb-2 mb-md-4 mt-2 mt-md-4"
              variant="contained"
              color="primary"
              onClick={handleOpen}
              startIcon={<AddIcon />}
            >Add Room
            </Button>
          </div>

          <Row className="m-0 p-0  px-md-3  py-1 pb-5  w-100" style={{ display: 'flex' }}>
            <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
              <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
                <div style={{ overflowY: 'auto' }}>
                  <div style={{ overflowX: 'auto' }}>
                    <MDBDataTable striped className="custom-datatable" bordered hover data={{ columns, rows }} />
                  </div>
                </div>
              </Col>
            </Row>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Typography variant="h6" className="mt-2" component="h2">
              Room Master
                </Typography>
                <form>
                <TextField
              select
              label="Block No"
              value={blockId}
              onChange={(e) => setBlockId(e.target.value)}
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              {blocks.map((block) => (
                <MenuItem key={block.block_id} value={block.block_id}>
                  {block.block_id}
                </MenuItem>
              ))}
            </TextField>
            <TextField label="Room No" value={roomName} onChange={(e) => setRoomName(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
                
                </form>
                <Row>
                  <Col className='text-end'>
                    <Button
                      className="mt-4"
                      style={{ color: 'black', backgroundColor: 'white', border: '2px solid rgb(135, 178, 178)', borderRadius: '8' }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col >
                    {isEditing ? (
                      <Button
                        style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                        className="py-2 px-3 mt-4 shadow text-dark"
                        type="submit"
                        onClick={savedata}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                        className="py-2 px-3 mt-4 shadow text-dark"
                        type="submit"
                        onClick={savedata}
                      >
                        Save
                      </Button>
                    )}
                  </Col>
                </Row>
              </Box>
            </Modal>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Room;
