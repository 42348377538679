// import React, { useState, useEffect } from "react";
// import { Dropdown, Input, Modal, notification, Table } from "antd";
// import { Button } from "@mui/material";
// import {
//   Admin2SelectedStudentData,
//     Admin2SelectedStudentList,
//   Admin2SelectedSubmit,
//   AdminDischargeGetData,
//   AdminDischargeStudentList,
//   AdminDischargeStudentPersonalDetails,
//   AdminSideDischargeAttendance,
//   AdminSideDischargeAttendanceEventDetail,
//   AdminSideDischargeFeeDetails,
//   AdminSideDischargeStudent,
//   ImagePath,
// } from "../ApiFile";
// import { Select } from "antd";
// import { MDBDataTable } from "mdbreact";
// import { Col, Row } from "react-bootstrap";
// import axios from "axios";
// import Sidebar from "../AluminiAdminDashboardDesign/sidebar";
// import NavLogo from "../AluminiAdminDashboardDesign/NavLogo";

// const { Option } = Select;

// const Admin2Discharge = () => {
//   const [students, setStudents] = useState([]);
//   const [studentsList, setStudentsList] = useState([]);
//   const [data, setData] = useState(null);
//   const [feeDetails, setFeeDetails] = useState(null);
//   const [refund_amount, setrefund_amount] = useState('');
//   const [fine_amount, setfine_amount] = useState('');
//   const [remark, setremark] = useState('');
//   const [selectedStudent, setSelectedStudent] = useState(null);
//   const [studentData, setStudentData] = useState(null);
//   const [studentData2, setStudentData2] = useState([])
//   const [modalData, setModalData] = useState([]);
//   const [modalTitle, setModalTitle] = useState("");
//   const [ReturnDepno, setReturnDepno] = useState("");
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const sessionYears = sessionStorage.getItem("selectedYear");
//   const [paymentMethod, setPaymentMethod] = useState(null);

//   const handlePaymentMethodChange = (value) => {
//     setPaymentMethod(value);
//   };

//   useEffect(() => {
//     fetch(`${Admin2SelectedStudentList}/${sessionYears}`)
//       .then((response) => response.json())
//       .then((data) => {
//         setStudentsList(data);
//       })
//       .catch((error) => console.error("Error fetching student list:", error));
//   }, [sessionYears]);

//   useEffect(() => {
//     if (selectedStudent) {
//       fetch(
//         `${AdminDischargeStudentPersonalDetails}/${selectedStudent}/${sessionYears}`
//       )
//         .then((response) => response.json())
//         .then((data) => {
//           setStudentData(data);
//         })
//         .catch((error) =>
//           console.error("Error fetching student personal details:", error)
//         );
//     }
//   }, [selectedStudent, sessionYears]);
//   useEffect(() => {
//     if (selectedStudent) {
//       fetch(
//         `${Admin2SelectedStudentData}/${sessionYears}/${selectedStudent}`
//       )
//         .then((response) => response.json())
//         .then((data) => {
//           setStudentData2(data[0]);
//         })
//         .catch((error) =>
//           console.error("Error fetching student personal details:", error)
//         );
//     }
//   }, [selectedStudent, sessionYears]);

//   useEffect(() => {
//     if (selectedStudent) {
//       axios
//         .get(
//           `${AdminSideDischargeFeeDetails}/${selectedStudent}/${sessionYears}`
//         )
//         .then((response) => {
//           setFeeDetails(response.data[0]);
//         })
//         .catch((error) => {
//           console.error("There was an error fetching the fee details!", error);
//         });
//     }
//   }, [selectedStudent, sessionYears]);

//   useEffect(() => {
//     fetch(`${Admin2SelectedStudentList}/${sessionYears}`)
//       .then((response) => response.json())
//       .then((data) => {
//         // Add serial number to each student object
//         const studentsWithSrNo = data.map((student, index) => ({
//           ...student,
//           srno: index + 1, // Adding serial number starting from 1
//         }));
//         setStudents(studentsWithSrNo);
//       })
//       .catch((error) => console.error("Error fetching discharge data:", error));
//   }, [sessionYears]);

//   useEffect(() => {
//     if (selectedStudent) {
//       axios
//         .get(
//           `${AdminSideDischargeAttendance}/${selectedStudent}/${sessionYears}`
//         )
//         .then((response) => {
//           if (response.data === "NOTFOUND") {
//             throw new Error("Attendance data not found");
//           }
//           setData(response.data[0]);
//         })
//         .catch((error) => {
//           console.error(
//             "There was an error fetching the attendance data!",
//             error.message
//           );
//           // You can set a state here to show a message in the UI
//           setData(null); // or handle it according to your needs
//         });
//     }
//   }, [selectedStudent, sessionYears]);


//   const handleSubmit = () => {
//     if (!selectedStudent || !ReturnDepno) {
//       notification.warning({ message: 'All Fields Required' });
//       return;
//     }
    
//     const dischargeData = {

//       member_id: selectedStudent,
//       payment_mode :paymentMethod,
//        payment_details: ReturnDepno,
//        refund_amount:studentData2.refund_amount,
//        fine_amount:studentData2.fine_amount,
//        deposit_flag:2 
  
//     };

//     axios.post(Admin2SelectedSubmit, dischargeData)
//       .then(response => {
//         notification.success({ message: 'Discharge processed successfully.' });
//         setrefund_amount('');
//         setfine_amount('');
//         setremark('');
//         setPaymentMethod('');
//         setReturnDepno('');
//         setSelectedStudent(null);

//       })
//       .catch(error => {
//         console.error('Error processing discharge:', error);
//         notification.error({ message: 'Failed to process discharge.' });
//       });
//   };

//   const showModal = (title, attendanceFlag) => {
//     axios
//       .get(`${AdminSideDischargeAttendanceEventDetail}/${selectedStudent}`)
//       .then((response) => {
//         const eventsData = response.data.events;
//         let eventDetails = [];

//         if (attendanceFlag == 1) {
//           // For present events
//           eventDetails = eventsData.present;
//         } else if (attendanceFlag == 2) {
//           // For absent events
//           eventDetails = eventsData.absent;
//         }

//         setModalData(eventDetails);
//         setModalTitle(title);
//         setIsModalVisible(true);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the event details!", error);
//       });
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//     setModalData([]);
//   };

//   const handleRefundAmount = (fineAmount) => {
//     if (feeDetails) {
//       const hostelDeposit = parseFloat(feeDetails.Hostel_deposit) || 0;
//       setrefund_amount(hostelDeposit - (parseFloat(fineAmount) || 0));
//     }
//   };

//   const handleFineAmountChange = (e) => {
//     setfine_amount(e.target.value);
//     handleRefundAmount(e.target.value);
//   };

//   const columns = [
//     {
//       title: "Serial No.",
//       dataIndex: "serial_no",
//       key: "serial_no",
//       render: (text, record, index) => index + 1,
//     },
//     {
//       title: "Event Name",
//       dataIndex: "discription",
//       key: "discription",
//     },
//     {
//       title: "Event Date",
//       dataIndex: "event_date",
//       key: "event_date",
//     },
//   ];

//   const handleSelectChange = (value) => {
//     setSelectedStudent(value);
//   };

//   const studentOptions = studentsList.map((student) => ({
//     value: student.member_id,
//     label: student.name,
//   }));

//   const data1 = {
//     columns: [
//       {
//         label: "Sr.No",
//         field: "srno",
//         sort: "asc",
//         width: 50,
//       },
//       {
//         label: "Name",
//         field: "name",
//         sort: "asc",
//         width: 150,
//       },
//       {
//         label: "Mobile Number",
//         field: "mob_no",
//         sort: "asc",
//         width: 100,
//       },
//       {
//         label: "Block No",
//         field: "block_id",
//         sort: "asc",
//         width: 50,
//       },
//       {
//         label: "Room No",
//         field: "room_id",
//         sort: "asc",
//         width: 50,
//       },
//       {
//         label: "Request Date",
//         field: "request_dischargedate",
//         sort: "asc",
//         width: 100,
//       },
//       {
//         label: "Discharge Reason",
//         field: "discharge_reason",
//         sort: "asc",
//         width: 200,
//       },
//       {
//         label: "Fine Amount",
//         field: "fine_amount",
//         sort: "asc",
//         width: 200,
//       },
//       {
//         label: "Refund Amount",
//         field: "refund_amount",
//         sort: "asc",
//         width: 200,
//       },
//     ],
//     rows: students,
//   };

//   return (
//     <>
//       <Sidebar />
//       <section className="home-section pb-5">
//         <NavLogo />

//         <div className="member-list pb-5">
//           <div className="row m-0 p-0 py-2">
//             <div
//               className="col-12 py-1 mb-3 shadow"
//               style={{
//                 backgroundColor: "white",
//                 color: "maroon",
//                 borderRadius: "5px",
//                 display: "flex",
//                 alignItems: "center",
//               }}
//             >
//               <div>
//                 <span style={{ color: "maroon", fontSize: "18px" }}>
//                   Discharge Student Data (admin-2)
//                 </span>
//               </div>
//             </div>

//             <div className="col-12 pt-2 pt-md-0">
//               <div className="row mb-3">
//                 <div className="col-md-4"></div>
//                 <div className="col-12 col-md-4">
//                   <label style={{ fontWeight: "normal", fontSize: "13px" }}>
//                     Select Student :
//                   </label>
//                   <Select
//                     showSearch
//                     className="w-100"
//                     placeholder="Select Student"
//                     optionFilterProp="label"
//                     options={studentOptions}
//                     onChange={handleSelectChange}
//                     value={selectedStudent}
//                   />
//                 </div>
//                 <div className="col-md-4"></div>
//               </div>

//               <Row className="m-0 p-0 pt-1 mb-2 mt-3 rounded">
//                 <Col
//                   className="m-0 p-0 px-2 py-3 rounded"
//                   style={{ background: "white" }}
//                 >
//                   <div style={{ overflowY: "auto" }}>
//                     <div style={{ overflowX: "auto" }}>
//               <h5 style={{color:"maroon"}}>Discharge Request Student List Approved By Superitendent</h5>
//                       <MDBDataTable
//                         style={{ backgroundColor: "white" }}
//                         striped
//                         className="custom-datatable"
//                         bordered
//                         responsive
//                         small
//                         data={data1}
//                       />
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//             </div>
//           </div>

//           {studentData && (
//             <div
//               className="row m-0 p-2 mx-md-3 mb-2 rounded"
//               style={{ backgroundColor: "whitesmoke" }}
//             >
//               <div
//                 className="col-12 py-1"
//                 style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
//               >
//                 <span>Personal Details -</span>
//               </div>
//               <div className="col-12 col-md-5  pt-2">
//                 <label
//                   style={{
//                     fontWeight: "normal",
//                     fontSize: "13px",
//                     color: "grey",
//                   }}
//                 >
//                   Name:
//                 </label>
//                 <span>{studentData.name}</span>
//               </div>
//               <div className="col-12 col-md-2  pt-2">
//                 <label
//                   style={{
//                     fontWeight: "normal",
//                     fontSize: "13px",
//                     color: "grey",
//                   }}
//                 >
//                   Block No :
//                 </label>
//                 <span>{studentData.block_id}</span>
//               </div>
//               <div className="col-12 col-md-2  pt-2">
//                 <label
//                   style={{
//                     fontWeight: "normal",
//                     fontSize: "13px",
//                     color: "grey",
//                   }}
//                 >
//                   Room No :
//                 </label>
//                 <span>{studentData.room_id}</span>
//               </div>

//               <div className="col-12 col-md-2 text-center  pt-2">
//                 <label
//                   style={{
//                     fontWeight: "normal",
//                     fontSize: "13px",
//                     color: "grey",
//                   }}
//                 >
//                   Photo:
//                 </label>
//                 <span>
//                   <img
//                     src={`${ImagePath}/${studentData.image}`}
//                     alt="img"
//                     style={{ width: "100px", height: "100px" }}
//                   />
//                 </span>
//               </div>

//               <div className="col-12 pt-2">
//                 <label
//                   style={{
//                     fontWeight: "normal",
//                     fontSize: "13px",
//                     color: "grey",
//                   }}
//                 >
//                   Address:
//                 </label>
//                 <span>{studentData.address}</span>
//               </div>

//               <div className="col-12 col-md-6  pt-2">
//                 <label
//                   style={{
//                     fontWeight: "normal",
//                     fontSize: "13px",
//                     color: "grey",
//                   }}
//                 >
//                   College Name:
//                 </label>
//                 <span>{studentData.collage_name}</span>
//               </div>

//               <div className="col-12 col-md-6  pt-2">
//                 <label
//                   style={{
//                     fontWeight: "normal",
//                     fontSize: "13px",
//                     color: "grey",
//                   }}
//                 >
//                   Standard :
//                 </label>
//                 <span>{studentData.std}</span>
//               </div>

//               <div className="col-12 col-md-6  pt-2">
//                 <label
//                   style={{
//                     fontWeight: "normal",
//                     fontSize: "13px",
//                     color: "grey",
//                   }}
//                 >
//                   Student's Contact No:
//                 </label>
//                 <span>{studentData.mob_no}</span>
//               </div>
//               <div className="col-12 col-md-6  pt-2">
//                 <label
//                   style={{
//                     fontWeight: "normal",
//                     fontSize: "13px",
//                     color: "grey",
//                   }}
//                 >
//                   Parent's Contact No:
//                 </label>
//                 <span>{studentData.parents_mobno}</span>
//               </div>
//             </div>
//           )}

//           {feeDetails && (
//             <div
//               className="row m-0 p-2 mx-md-3 mx-1 mb-1"
//               style={{ backgroundColor: "whitesmoke" }}
//             >
//               <div
//                 className="col-12 py-1"
//                 style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
//               >
//                 <span>Fee Details - </span>
//               </div>
//               <div className="col-12 col-md-6 col-lg-6 pt-2 d-flex">
//                 <label style={{fontWeight: "normal",fontSize: "13px",color: "grey", }}>
//                   Total Fee:
//                 </label>
//                 &nbsp;&nbsp;&nbsp;
//                 <span>{feeDetails.total_fee}</span>
//               </div>

//               <div className="col-12 col-md-6 col-lg-6 pt-2 d-flex">
//                 <label
//                   style={{
//                     fontWeight: "normal",
//                     fontSize: "13px",
//                     color: "grey",
//                   }}
//                 >
//                   Pending Fee:
//                 </label>
//                 &nbsp;&nbsp;&nbsp;
//                 <span>{feeDetails.pending_amount}</span>
//               </div>

//               {feeDetails.first_installment.length > 0 && (
//                 <div className="col-12 col-md-4 col-lg-4 pt-2 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     First Installment:{" "}
//                   </label>
//                   &nbsp;&nbsp;&nbsp;&nbsp;
//                   <span>
//                     {feeDetails.first_installment.map((installment) => (
//                       <div key={installment.payment_date}>
//                         {installment.total_amount} - {installment.payment_date}
//                       </div>
//                     ))}
//                   </span>
//                 </div>
//               )}
//               {feeDetails.second_installment.length > 0 && (
//                 <div className="col-12 col-md-4 col-lg-4 pt-2 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Second Installment:{" "}
//                   </label>
//                   &nbsp;&nbsp;&nbsp;&nbsp;
//                   <span>
//                     {feeDetails.second_installment.map((installment) => (
//                       <div key={installment.payment_date}>
//                         {installment.total_amount} - {installment.payment_date}
//                       </div>
//                     ))}
//                   </span>
//                 </div>
//               )}

//               {feeDetails.without_installment.length > 0 && (
//                 <div className="col-12 col-md-4 col-lg-4 pt-2 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Onetime Payment :{" "}
//                   </label>
//                   &nbsp;&nbsp;&nbsp;&nbsp;
//                   <span>
//                     {feeDetails.without_installment.map((installment) => (
//                       <div key={installment.payment_date}>
//                         {installment.total_amount} - {installment.payment_date}
//                       </div>
//                     ))}
//                   </span>
//                 </div>
//               )}
//             </div>
//           )}

//           {data && (
//             <>
//               <div
//                 className="row m-0 p-2 mx-md-3 rounded mx-1 mb-1"
//                 style={{ backgroundColor: "whitesmoke" }}
//               >
//                 <div
//                   className="col-12 py-1"
//                   style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
//                 >
//                   <span>Regular Attendance - </span>
//                 </div>
//                 <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Absent:
//                   </label>
//                   &nbsp;&nbsp;&nbsp;
//                   <span>{data.regularCount}</span>
//                 </div>
//               </div>

//               <div
//                 className="row m-0 mx-md-3 rounded p-2 mx-1 mb-1"
//                 style={{ backgroundColor: "whitesmoke" }}
//               >
//                 <div
//                   className="col-12 py-1"
//                   style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
//                 >
//                   <span>Event Attendance - </span>
//                 </div>

//                 <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Total Events:
//                   </label>
//                   &nbsp;&nbsp;&nbsp;
//                   <span>
//                   {data.eventCount.total_event}
//                   </span>
//                 </div>

//                 <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Present:
//                   </label>
//                   &nbsp;&nbsp;&nbsp;
//                   <span>{data.eventCount.present_eventCount}</span>{" "}
//                   &nbsp;&nbsp;&nbsp;
//                   <span>
//                     {" "}
//                     <Button
//                       style={{ backgroundColor: "#9acac9", color: "black" }}
//                       className="p-0 m-0"
//                       onClick={() => showModal("Present Event Details", 1)} // Passing 1 for present events
//                     >
//                       View
//                     </Button>
//                   </span>
//                 </div>

//                 <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Absent:
//                   </label>
//                   &nbsp;&nbsp;&nbsp;
//                   <span>{data.eventCount.absent_eventCount}</span>{" "}
//                   &nbsp;&nbsp;&nbsp;
//                   <span>
//                     <Button
//                       style={{ backgroundColor: "#9acac9", color: "black" }}
//                       className="p-0 m-0"
//                       onClick={() => showModal("Absent Event Details", 2)} // Passing 2 for absent events
//                     >
//                       View
//                     </Button>
//                   </span>
//                 </div>
//               </div>

//               <div
//                 className="row m-0 p-2 mx-md-3 rounded mx-1 mb-1"
//                 style={{ backgroundColor: "whitesmoke" }}
//               >
//                 <div
//                   className="col-12 py-1"
//                   style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
//                 >
//                   <span>Mess Attendance - </span>
//                 </div>
//                 <li>Lunch - </li>

//                 <div className="col-12 col-md-6 col-lg-4 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Total Lunchs :
//                   </label>
//                   &nbsp;&nbsp;&nbsp;
//                   <span>
//                     {data.lunchCount.lunch_present +
//                       data.lunchCount.lunch_absent}
//                   </span>
//                 </div>

//                 <div className="col-12 col-md-6 col-lg-4 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Present:
//                   </label>
//                   &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_present}</span>
//                 </div>

//                 <div className="col-12 col-md-6 col-lg-4 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Absent:
//                   </label>
//                   &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_absent}</span>
//                 </div>

//                 <li>Dinner - </li>

//                 <div className="col-12 col-md-6 col-lg-4 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Total Dinners :
//                   </label>
//                   &nbsp;&nbsp;&nbsp;
//                   <span>
//                     {data.dinnerCount.dinner_present +
//                       data.dinnerCount.dinner_absent}
//                   </span>
//                 </div>

//                 <div className="col-12 col-md-6 col-lg-4 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Present:
//                   </label>
//                   &nbsp;&nbsp;&nbsp;
//                   <span>{data.dinnerCount.dinner_present}</span>
//                 </div>

//                 <div className="col-12 col-md-6 col-lg-4 d-flex">
//                   <label
//                     style={{
//                       fontWeight: "normal",
//                       fontSize: "13px",
//                       color: "grey",
//                     }}
//                   >
//                     Absent:
//                   </label>
//                   &nbsp;&nbsp;&nbsp;
//                   <span>{data.dinnerCount.dinner_absent}</span>
//                 </div>
//               </div>
//             </>
//           )}

//           {studentData2 && (<>
              
//           <div className="row m-0 mx-md-3 rounded p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
//                <div className="col-12 col-md-4 pt-2 d-flex">
//                 <label style={{fontWeight: "normal",fontSize: "13px",color: "grey", }}>
//                   fine Amount :
//                 </label>
//                 &nbsp;&nbsp;&nbsp;
//                 <span>{studentData2.fine_amount}</span>
//               </div>
//                <div className="col-12 col-md-4 pt-2 d-flex">
//                 <label style={{fontWeight: "normal",fontSize: "13px",color: "grey", }}>
//                   Refund Amount :
//                 </label>
//                 &nbsp;&nbsp;&nbsp;
//                 <span>{studentData2.refund_amount}</span>
//               </div>
//                <div className="col-12 col-md-4 pt-2 d-flex">
//                 <label style={{fontWeight: "normal",fontSize: "13px",color: "grey", }}>
//                   Discharge Date :
//                 </label>
//                 &nbsp;&nbsp;&nbsp;
//                 <span>{studentData2.request_dischargedate}</span>
//               </div>
            

//           <div className="row mt-2 ">
//           <div className="col-12 col-md-4 ">
//           <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
//           Select Payment Method :
//           </span>
//         <Select
//         className="w-100"
//           placeholder="Select Payment Method"
//           onChange={handlePaymentMethodChange}
//         >
//           <Option value="1">CASH</Option>
//           <Option value="2">CHEQUE</Option>
//           <Option value="3">ONLINE</Option>
//         </Select>
//         </div>
//       </div>

//       <div className="row mt-2">
//         {paymentMethod === "1" && (<>
//           <style>
//                 {`
//           .custom-input::placeholder {
//             color: black;
//             font-size:14px;
//           }
//         `}
//               </style>
//           <div className="col-12 col-md-4">
//             <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
//               Voucher No :
//             </span>
//             <Input className="text-end" value={ReturnDepno}
//                 onChange={(e) => setReturnDepno(e.target.value)} maxLength={20} />
//           </div>
//           <div className="col-12 col-md-4">
//           <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
//             Amount:
//           </span>
//           <Input className="text-end custom-input" readOnly placeholder={studentData2.refund_amount} />
//         </div>

//         <div className="col-12 col-md-4 mt-4">
//           <Button style={{ backgroundColor: "#9acac9", color: "black", height: "27px" }}    onClick={handleSubmit}>
//             Done
//           </Button>
//         </div>
//         </> )}

//         {paymentMethod === "2" && (<>
//           <style>
//                 {`
//           .custom-input::placeholder {
//             color: black;
//             font-size:14px;
//           }
//         `}
//               </style>
//           <div className="col-12 col-md-4">
//             <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
//               Cheque No :
//             </span>
//             <Input className="text-end" maxLength={20} />
//           </div>

//           <div className="col-12 col-md-4">
//           <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
//             Amount:
//           </span>
//           <Input className="text-end custom-input" readOnly placeholder={studentData2.refund_amount} />
//         </div>

//         <div className="col-12 col-md-4 mt-4">
//           <Button style={{ backgroundColor: "#9acac9", color: "black", height: "27px" }}  onClick={handleSubmit}>
//             Done
//           </Button>
//         </div>
//         </>)}

//         {paymentMethod === "3" && (<>
//           <style>
//                 {`
//           .custom-input::placeholder {
//             color: black;
//             font-size:14px;
//           }
//         `}
//               </style>
//           <div className="col-12 col-md-4">
//             <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
//               Transaction ID :
//             </span>
//             <Input className="text-end" maxLength={20} />
//           </div>

//           <div className="col-12 col-md-4">
//           <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
//             Amount:
//           </span>
//           <Input className="text-end custom-input"placeholder={studentData2.refund_amount} readOnly />
//         </div>

//         <div className="col-12 col-md-4 mt-4">
//           <Button style={{ backgroundColor: "#9acac9", color: "black", height: "27px" }}  onClick={handleSubmit}>
//             Done
//           </Button>
//         </div>
//         </>)}
        
        
//       </div>
    
//           </div>
//        </> )}

//           <Modal
//             title={modalTitle}
//             visible={isModalVisible}
//             onCancel={handleCancel}
//             footer={null}
//           >
//             <Table
//               dataSource={modalData}
//               columns={columns}
//               rowKey="event_date"
//               pagination={false}
//             />
//           </Modal>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Admin2Discharge;





import React, { useState, useEffect } from "react";
import { Dropdown, Input, Modal, notification, Table } from "antd";
import { Button } from "@mui/material";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from '../studentDashboard/img/logo2.png';
import logo2 from '../studentDashboard/img/logonew.jpg';
import {
  Admin2Generatereceipt,
  Admin2GetReceiptId,
  Admin2SelectedStudentData,
    Admin2SelectedStudentList,
  Admin2SelectedSubmit,
  AdminDischargeGetData,
  AdminDischargeStudentList,
  AdminDischargeStudentPersonalDetails,
  AdminSideDischargeAttendance,
  AdminSideDischargeAttendanceEventDetail,
  AdminSideDischargeFeeDetails,
  AdminSideDischargeStudent,
  ImagePath,
} from "../ApiFile";
import { Select } from "antd";
import { MDBDataTable } from "mdbreact";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import Sidebar from "../AluminiAdminDashboardDesign/sidebar";
import NavLogo from "../AluminiAdminDashboardDesign/NavLogo";

const { Option } = Select;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Utility function to convert image to base64
const toDataURL = url =>
  fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }));

const Admin2Discharge = () => {
  const [students, setStudents] = useState([]);
  const [studentsList, setStudentsList] = useState([]);
  const [receiptid, setReceiptid] = useState([]);
  const [loading, setLoading] = useState(false);
  const [receiptData, setReceiptData] = useState([]);
  const [data, setData] = useState(null);
  const [feeDetails, setFeeDetails] = useState(null);
  const [refund_amount, setrefund_amount] = useState('');
  const [fine_amount, setfine_amount] = useState('');
  const [remark, setremark] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentData, setStudentData] = useState(null);
  const [studentData2, setStudentData2] = useState([])
  const [modalData, setModalData] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [ReturnDepno, setReturnDepno] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const sessionYears = sessionStorage.getItem("selectedYear");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [logoBase64, setLogoBase64] = useState(null);
  const [logo2Base64, setLogo2Base64] = useState(null);

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
  };

  useEffect(() => {
    // Convert images to base64
    Promise.all([toDataURL(logo), toDataURL(logo2)])
      .then(([logoBase64, logo2Base64]) => {
        setLogoBase64(logoBase64);
        setLogo2Base64(logo2Base64);
      });
  }, []);

  useEffect(() => {
    fetch(`${Admin2SelectedStudentList}/${sessionYears}`)
      .then((response) => response.json())
      .then((data) => {
        setStudentsList(data);
      })
      .catch((error) => console.error("Error fetching student list:", error));
  }, [sessionYears]);

  useEffect(() => {
    if (selectedStudent) {
      fetch(
        `${AdminDischargeStudentPersonalDetails}/${selectedStudent}/${sessionYears}`
      )
        .then((response) => response.json())
        .then((data) => {
          setStudentData(data);
        })
        .catch((error) =>
          console.error("Error fetching student personal details:", error)
        );
    }
  }, [selectedStudent, sessionYears]);
  useEffect(() => {
    if (selectedStudent) {
      fetch(
        `${Admin2SelectedStudentData}/${sessionYears}/${selectedStudent}`
      )
        .then((response) => response.json())
        .then((data) => {
          setStudentData2(data[0]);
        })
        .catch((error) =>
          console.error("Error fetching student personal details:", error)
        );
    }
  }, [selectedStudent, sessionYears]);

  useEffect(() => {
    if (selectedStudent) {
      axios
        .get(
          `${AdminSideDischargeFeeDetails}/${selectedStudent}/${sessionYears}`
        )
        .then((response) => {
          setFeeDetails(response.data[0]);
        })
        .catch((error) => {
          console.error("There was an error fetching the fee details!", error);
        });
    }
  }, [selectedStudent, sessionYears]);

  useEffect(() => {
    fetch(`${Admin2SelectedStudentList}/${sessionYears}`)
      .then((response) => response.json())
      .then((data) => {
        // Add serial number to each student object
        const studentsWithSrNo = data.map((student, index) => ({
          ...student,
          srno: index + 1, // Adding serial number starting from 1
        }));
        setStudents(studentsWithSrNo);
      })
      .catch((error) => console.error("Error fetching discharge data:", error));
  }, [sessionYears]);

  useEffect(() => {
    if (selectedStudent) {
      axios
        .get(
          `${AdminSideDischargeAttendance}/${selectedStudent}/${sessionYears}`
        )
        .then((response) => {
          if (response.data === "NOTFOUND") {
            throw new Error("Attendance data not found");
          }
          setData(response.data[0]);
        })
        .catch((error) => {
          console.error(
            "There was an error fetching the attendance data!",
            error.message
          );
          // You can set a state here to show a message in the UI
          setData(null); // or handle it according to your needs
        });
    }
  }, [selectedStudent, sessionYears]);

  const showModal = (title, attendanceFlag) => {
    axios
      .get(`${AdminSideDischargeAttendanceEventDetail}/${selectedStudent}`)
      .then((response) => {
        const eventsData = response.data.events;
        let eventDetails = [];

        if (attendanceFlag == 1) {
          // For present events
          eventDetails = eventsData.present;
        } else if (attendanceFlag == 2) {
          // For absent events
          eventDetails = eventsData.absent;
        }

        setModalData(eventDetails);
        setModalTitle(title);
        setIsModalVisible(true);
      })
      .catch((error) => {
        console.error("There was an error fetching the event details!", error);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalData([]);
  };

  const handleRefundAmount = (fineAmount) => {
    if (feeDetails) {
      const hostelDeposit = parseFloat(feeDetails.Hostel_deposit) || 0;
      setrefund_amount(hostelDeposit - (parseFloat(fineAmount) || 0));
    }
  };

  const handleFineAmountChange = (e) => {
    setfine_amount(e.target.value);
    handleRefundAmount(e.target.value);
  };

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "serial_no",
      key: "serial_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Event Name",
      dataIndex: "discription",
      key: "discription",
    },
    {
      title: "Event Date",
      dataIndex: "event_date",
      key: "event_date",
    },
  ];

  const handleSelectChange = (value) => {
    setSelectedStudent(value);
  };

  const studentOptions = studentsList.map((student) => ({
    value: student.member_id,
    label: student.name,
  }));

  const data1 = {
    columns: [
      {
        label: "Sr.No",
        field: "srno",
        sort: "asc",
        width: 50,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile Number",
        field: "mob_no",
        sort: "asc",
        width: 100,
      },
      {
        label: "Block No",
        field: "block_id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Room No",
        field: "room_id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Request Date",
        field: "request_dischargedate",
        sort: "asc",
        width: 100,
      },
      {
        label: "Discharge Reason",
        field: "discharge_reason",
        sort: "asc",
        width: 200,
      },
      {
        label: "Fine Amount",
        field: "fine_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Refund Amount",
        field: "refund_amount",
        sort: "asc",
        width: 200,
      },
    ],
    rows: students,
  };


  const handleSubmit = () => {
    if (!selectedStudent || !ReturnDepno || !paymentMethod) {
      notification.warning({ message: "All Fields Required" });
      return;
    }
  
    Modal.confirm({
      title: "Confirm Discharge",
      content: "Are you sure you want to proceed with the discharge?",
      onOk() {
        setLoading(true); // Start the loader
  
        const dischargeData = {
          member_id: selectedStudent,
          payment_mode: paymentMethod,
          payment_details: ReturnDepno,
          refund_amount: studentData2.refund_amount,
          fine_amount: studentData2.fine_amount,
          deposit_flag: 2,
        };
  
        axios
          .post(Admin2SelectedSubmit, dischargeData)
          .then((response) => {
            notification.success({ message: "Discharge processed successfully." });
            resetForm();
  
            // Fetch receipt ID
            return axios.get(`${Admin2GetReceiptId}/${selectedStudent}/${sessionYears}`);
          })
          .then((response) => {
            const receiptId = response.data[0]?.id;
            if (!receiptId) {
              throw new Error("Receipt ID not found");
            }
  
            // Generate receipt
            return axios.get(
              `${Admin2Generatereceipt}/${selectedStudent}/${receiptId}/${sessionYears}/${response.data[0].receipt_id}`
            );
          })
          .then((response) => {
            const receiptData = response.data[0];
            setReceiptData(receiptData);
  
            console.log("receipt data set1...", receiptData);
  
            notification.success({ message: "Receipt generated successfully." });
  
            // setTimeout(() => {
            //   generatePDF(receiptData);
            // }, 10000); 
            generatePDF(receiptData);
  
          })
          .catch((error) => {
            console.error("Error:", error);
            notification.error({ message: "Operation failed." });
          })
          .finally(() => {
            setLoading(false); // Stop the loader
          });
      },
    });
  };
  

  const generatePDF = (receiptData) => {
    console.log("generate pdf res...",receiptData);
    
    if (!receiptData  || !logoBase64 || !logo2Base64) return;
    setLoading(true);

    console.log("generate pdf res...1",receiptData);
    const dd = {
      content: [
        {
          columns: [
            { style: "logo", width: "15%", image: logoBase64, fit: [50, 50] },
            {
              width: "70%",
              text: [
                { text: "Dakshin Bharat Jain Sabha's \n", style: "header1" },
                { text: "Sheth R D Dawada Digambar Jain Boarding,\n", style: "header" },
                { text: "37, Mahavir Nagar, Sangli 416416, Phone: 0233-262306\n", style: "header2" },
                { text: "Web : www.jainboardingsangli.com  Email : jainboardingsangli@gmail.com \n", style: "header3" },
              ],
            },
            { style: "logo", width: "15%", image: logo2Base64, fit: [50, 50] },
          ],
        },
        {
          margin: [0, 15, 0, 0],
          canvas: [
            {
              type: 'line',
              x1: 0, y1: 0,
              x2: 515, y2: 0,
              lineWidth: 1
            }
          ],
        },
        {
          style: "receiptmargin",
          columns: [
            {
              style: "receipt",
              width: "33%",
              text: [
                { text: 'Receipt No: ', bold: true },
                { text: `${receiptData.receiptNo}\n` }
              ],
            },
            {
              style: "receipt",
              width: "33%",
              text: [
                { text: 'Academic Year: ', bold: true },
                { text: sessionYears }
              ],
            },
            {
              style: "recdate",
              width: "34%",
              text: [
                { text: 'Receipt Date: ', bold: true },
                { text: `${receiptData.receipt_date}\n` }
              ],
            },
          ],
        },
        {
          columns: [
            {
              width: "66%",
              text: [
                { text: 'Name: ', bold: true },
                { text: `${receiptData.memberName}\n` }
              ],
              style: "NameMargin"
            },
            {
              width: "34%",
              text: [{ text: 'Payment Date: ', bold: true }, { text: `${receiptData.payment_date}\n` }],
              style: "NameMargin"
            },
          ],
        },
        {
          text: [
            { text: 'Address: ', bold: true },
            { text: `${receiptData.address} , ${receiptData.village} ,` },
            { text: ' Taluka: ', bold: true },
            { text: `${receiptData.Taluka} , ` },
            { text: ' District: ', bold: true },
            { text: `${receiptData.District} , ` },
            { text: ' State: ', bold: true },
            { text: `${receiptData.State} , ` },
            { text: ' Pin-Code: ', bold: true },
            { text: `${receiptData.pincode} . ` }
          ],
          style: "NameMargin"
        },
        {
          style: "NameMargin",
          columns: [
            { width: "33%", text: [{ text: 'Term: ', bold: true }, { text: `${receiptData.term}\n` }] },
            { width: "33%", text: [{ text: 'Mobile No: ', bold: true }, { text: `${receiptData.mobileno}\n` }] },
            { width: "34%", text: [{ text: 'Parent\'s Mob No: ', bold: true }, { text: `${receiptData.parents_mobno}\n` }] },
          ],
        },
        {
          style: "NameMargin",
          columns: [
            { width: "33%", text: [{ text: 'Payment Mode: ', bold: true }, { text: `${receiptData.paymode}\n` }] },
            { width: "67%", text: [{ text: 'Transaction Id: ', bold: true }, { text: `${receiptData.taxid}\n` }] },
          ],
        },
        {
          style: "NameMargin",
          columns: [
            { width: "33%", text: [{ text: 'Block No: ', bold: true }, { text: `${receiptData.block_id}\n` }] },
            { width: "33%", text: [{ text: 'Room No: ', bold: true }, { text: `${receiptData.room_id}\n` }] },
            { width: "34%", text: [{ text: 'Bed No: ', bold: true }, { text: `${receiptData.bed_id}\n` }] },
          ],
        },
        {
          text: "",
        },
        {
          style: "NameMargin1",
          columns: [
            {
              width: "30%",
              text: '',  // Empty to keep layout structure
            },
            {
              width: "40%",
              text: [
                { text: 'Deposit: ', bold: true },
                { text: `${receiptData.Deposit}\n`, alignment: 'right' }
              ],
            },
            {
              width: "30%",
              text: '',  // Empty to keep layout structure
            },
          ],
        },
        {
          style: "NameMargin",
          columns: [
            {
              width: "30%",
              text: '',  // Empty to keep layout structure
            },
            {
              width: "40%",
              text: [
                { text: 'Fine Amount: ', bold: true },
                { text: `${receiptData.fine_amount}\n`, alignment: 'right' }
              ],
            },
            {
              width: "30%",
              text: '',  // Empty to keep layout structure
            },
          ],
        },
        {
          style: "NameMargin",
          columns: [
            {
              width: "30%",
              text: '',  // Empty to keep layout structure
            },
            {
              width: "40%",
              text: [
                { text: 'Refund Amount: ', bold: true },
                { text: `${receiptData.refund_amount}\n`, alignment: 'right' }
              ],
            },
            {
              width: "30%",
              text: '',  // Empty to keep layout structure
            },
          ],
        },
       
      ],
      footer: function(currentPage, pageCount) {
        return {
          text: `Design & Developed By TechnoAarv Solution`,
          alignment: 'center',
          margin: [0, 0, 0, 10]
        };
      },
      styles: {
        lineheight: {
          lineHeight: 1.5,
          fontSize: 12,
        },
        header1: {
          fontSize: 15,
          alignment: "center",
        },
        header: {
          fontSize: 18,
          alignment: "center",
          bold: true,
        },
        header2: {
          fontSize: 13,
          alignment: "center",
        },
        header3: {
          fontSize: 9,
          alignment: "center",
        },
        logo: {
          alignment: "center",
        },
        left: {
          alignment: "right",
          lineHeight: 1.5,
          fontSize: 12,
        },
        subheader: {
          fontSize: 15,
          lineHeight: 1.5,
          bold: true,
        },
        start1: {
          alignment: "left",
          bold: true,
          color: 'blue'
        },
        receiptmargin: {
          margin: [0, 25, 0, 0],
        },
        NameMargin: {
          margin: [0, 7, 0, 0],
        },
        NameMargin1: {
          margin: [0, 13, 0, 0],
        },
        description: {
          margin: [19, 0, 0, 0],
        },
        amount: {
          margin: [0, 0, 5, 0],
        },
        tableExample: {
          lineHeight: 1.5,
          alignment: 'center',
          margin: [0, 40, 0, 0],
        }
      },
    };

  
    // pdfMake.createPdf(dd).download("receipt.pdf");
    // setLoading(false);
    pdfMake.createPdf(dd).open(); // Open the PDF in a new tab
    setLoading(false);
  }
  

  const resetForm = () => {
    setReturnDepno("");
    setSelectedStudent(null);
    setPaymentMethod(null);
  };


  return (
    <>
      <Sidebar />
      <section className="home-section pb-5">
        <NavLogo />

        <div className="member-list pb-5">
          <div className="row m-0 p-0 py-2">
            <div
              className="col-12 py-1 mb-3 shadow"
              style={{
                backgroundColor: "white",
                color: "maroon",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <span style={{ color: "maroon", fontSize: "18px" }}>
                  Discharge Student Data (admin-2)
                </span>
              </div>
            </div>

            <div className="col-12 pt-2 pt-md-0">
              <div className="row mb-3">
                <div className="col-md-4"></div>
                <div className="col-12 col-md-4">
                  <label style={{ fontWeight: "normal", fontSize: "13px" }}>
                    Select Student :
                  </label>
                  <Select
                    showSearch
                    className="w-100"
                    placeholder="Select Student"
                    optionFilterProp="label"
                    options={studentOptions}
                    onChange={handleSelectChange}
                    value={selectedStudent}
                  />
                </div>
                <div className="col-md-4"></div>
              </div>

              <Row className="m-0 p-0 pt-1 mb-2 mt-3 rounded">
                <Col
                  className="m-0 p-0 px-2 py-3 rounded"
                  style={{ background: "white" }}
                >
                  <div style={{ overflowY: "auto" }}>
                    <div style={{ overflowX: "auto" }}>
              <h5 style={{color:"maroon"}}>Discharge Request Student List Approved By Superitendent</h5>
                      <MDBDataTable
                        style={{ backgroundColor: "white" }}
                        striped
                        className="custom-datatable"
                        bordered
                        responsive
                        small
                        data={data1}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          {studentData && (
            <div
              className="row m-0 p-2 mx-md-3 mb-2 rounded"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div
                className="col-12 py-1"
                style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
              >
                <span>Personal Details -</span>
              </div>
              <div className="col-12 col-md-5  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Name:
                </label>
                <span>{studentData.name}</span>
              </div>
              <div className="col-12 col-md-2  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Block No :
                </label>
                <span>{studentData.block_id}</span>
              </div>
              <div className="col-12 col-md-2  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Room No :
                </label>
                <span>{studentData.room_id}</span>
              </div>

              <div className="col-12 col-md-2 text-center  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Photo:
                </label>
                <span>
                  <img
                    src={`${ImagePath}/${studentData.image}`}
                    alt="img"
                    style={{ width: "100px", height: "100px" }}
                  />
                </span>
              </div>

              <div className="col-12 pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Address:
                </label>
                <span>{studentData.address}</span>
              </div>

              <div className="col-12 col-md-6  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  College Name:
                </label>
                <span>{studentData.collage_name}</span>
              </div>

              <div className="col-12 col-md-6  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Standard :
                </label>
                <span>{studentData.std}</span>
              </div>

              <div className="col-12 col-md-6  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Student's Contact No:
                </label>
                <span>{studentData.mob_no}</span>
              </div>
              <div className="col-12 col-md-6  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Parent's Contact No:
                </label>
                <span>{studentData.parents_mobno}</span>
              </div>
            </div>
          )}

          {feeDetails && (
            <div
              className="row m-0 p-2 mx-md-3 mx-1 mb-1"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div
                className="col-12 py-1"
                style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
              >
                <span>Fee Details - </span>
              </div>
              <div className="col-12 col-md-6 col-lg-6 pt-2 d-flex">
                <label style={{fontWeight: "normal",fontSize: "13px",color: "grey", }}>
                  Total Fee:
                </label>
                &nbsp;&nbsp;&nbsp;
                <span>{feeDetails.total_fee}</span>
              </div>

              <div className="col-12 col-md-6 col-lg-6 pt-2 d-flex">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Pending Fee:
                </label>
                &nbsp;&nbsp;&nbsp;
                <span>{feeDetails.pending_amount}</span>
              </div>

              {feeDetails.first_installment.length > 0 && (
                <div className="col-12 col-md-4 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    First Installment:{" "}
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    {feeDetails.first_installment.map((installment) => (
                      <div key={installment.payment_date}>
                        {installment.total_amount} - {installment.payment_date}
                      </div>
                    ))}
                  </span>
                </div>
              )}
              {feeDetails.second_installment.length > 0 && (
                <div className="col-12 col-md-4 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Second Installment:{" "}
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    {feeDetails.second_installment.map((installment) => (
                      <div key={installment.payment_date}>
                        {installment.total_amount} - {installment.payment_date}
                      </div>
                    ))}
                  </span>
                </div>
              )}

              {feeDetails.without_installment.length > 0 && (
                <div className="col-12 col-md-4 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Onetime Payment :{" "}
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    {feeDetails.without_installment.map((installment) => (
                      <div key={installment.payment_date}>
                        {installment.total_amount} - {installment.payment_date}
                      </div>
                    ))}
                  </span>
                </div>
              )}
            </div>
          )}

          {data && (
            <>
              <div
                className="row m-0 p-2 mx-md-3 rounded mx-1 mb-1"
                style={{ backgroundColor: "whitesmoke" }}
              >
                <div
                  className="col-12 py-1"
                  style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
                >
                  <span>Regular Attendance - </span>
                </div>
                <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Absent:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>{data.regularCount}</span>
                </div>
              </div>

              <div
                className="row m-0 mx-md-3 rounded p-2 mx-1 mb-1"
                style={{ backgroundColor: "whitesmoke" }}
              >
                <div
                  className="col-12 py-1"
                  style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
                >
                  <span>Event Attendance - </span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Total Events:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>
                  {data.eventCount.total_event}
                  </span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Present:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>{data.eventCount.present_eventCount}</span>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    {" "}
                    <Button
                      style={{ backgroundColor: "#9acac9", color: "black" }}
                      className="p-0 m-0"
                      onClick={() => showModal("Present Event Details", 1)} // Passing 1 for present events
                    >
                      View
                    </Button>
                  </span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Absent:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>{data.eventCount.absent_eventCount}</span>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    <Button
                      style={{ backgroundColor: "#9acac9", color: "black" }}
                      className="p-0 m-0"
                      onClick={() => showModal("Absent Event Details", 2)} // Passing 2 for absent events
                    >
                      View
                    </Button>
                  </span>
                </div>
              </div>

              <div
                className="row m-0 p-2 mx-md-3 rounded mx-1 mb-1"
                style={{ backgroundColor: "whitesmoke" }}
              >
                <div
                  className="col-12 py-1"
                  style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
                >
                  <span>Mess Attendance - </span>
                </div>
                <li>Lunch - </li>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Total Lunchs :
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    {data.lunchCount.lunch_present +
                      data.lunchCount.lunch_absent}
                  </span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Present:
                  </label>
                  &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_present}</span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Absent:
                  </label>
                  &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_absent}</span>
                </div>

                <li>Dinner - </li>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Total Dinners :
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    {data.dinnerCount.dinner_present +
                      data.dinnerCount.dinner_absent}
                  </span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Present:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>{data.dinnerCount.dinner_present}</span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Absent:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>{data.dinnerCount.dinner_absent}</span>
                </div>
              </div>
            </>
          )}

          {studentData2 && (<>
              
          <div className="row m-0 mx-md-3 rounded p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
               <div className="col-12 col-md-4 pt-2 d-flex">
                <label style={{fontWeight: "normal",fontSize: "13px",color: "grey", }}>
                  fine Amount :
                </label>
                &nbsp;&nbsp;&nbsp;
                <span>{studentData2.fine_amount}</span>
              </div>
               <div className="col-12 col-md-4 pt-2 d-flex">
                <label style={{fontWeight: "normal",fontSize: "13px",color: "grey", }}>
                  Refund Amount :
                </label>
                &nbsp;&nbsp;&nbsp;
                <span>{studentData2.refund_amount}</span>
              </div>
               <div className="col-12 col-md-4 pt-2 d-flex">
                <label style={{fontWeight: "normal",fontSize: "13px",color: "grey", }}>
                  Discharge Date :
                </label>
                &nbsp;&nbsp;&nbsp;
                <span>{studentData2.request_dischargedate}</span>
              </div>
            

          <div className="row mt-2 ">
          <div className="col-12 col-md-4 ">
          <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
          Select Payment Method :
          </span>
        <Select
        className="w-100"
          placeholder="Select Payment Method"
          onChange={handlePaymentMethodChange}
        >
          <Option value="1">CASH</Option>
          <Option value="2">CHEQUE</Option>
          <Option value="3">ONLINE</Option>
        </Select>
        </div>
      </div>

      <div className="row mt-2">
        {paymentMethod == "1" && (<>
          <style>
                {`
          .custom-input::placeholder {
            color: black;
            font-size:14px;
          }
        `}
              </style>
          <div className="col-12 col-md-4">
            <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
              Voucher No :
            </span>
            <Input className="text-end" value={ReturnDepno}
                onChange={(e) => setReturnDepno(e.target.value)} maxLength={20} />
          </div>
          <div className="col-12 col-md-4">
          <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
            Amount:
          </span>
          <Input className="text-end custom-input" readOnly placeholder={studentData2.refund_amount} />
        </div>

        <div className="col-12 col-md-4 mt-4">
          <Button style={{ backgroundColor: "#9acac9", color: "black", height: "27px" }}    onClick={handleSubmit}>
            Done
          </Button>
        </div>
        </> )}

        {paymentMethod == "2" && (<>
          <style>
                {`
          .custom-input::placeholder {
            color: black;
            font-size:14px;
          }
        `}
              </style>
          <div className="col-12 col-md-4">
            <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
              Cheque No :
            </span>
            <Input className="text-end" maxLength={20} />
          </div>

          <div className="col-12 col-md-4">
          <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
            Amount:
          </span>
          <Input className="text-end custom-input" readOnly placeholder={studentData2.refund_amount} />
        </div>

        <div className="col-12 col-md-4 mt-4">
          <Button style={{ backgroundColor: "#9acac9", color: "black", height: "27px" }}  onClick={handleSubmit}>
            Done
          </Button>
        </div>
        </>)}

        {paymentMethod == "3" && (<>
          <style>
                {`
          .custom-input::placeholder {
            color: black;
            font-size:14px;
          }
        `}
              </style>
          <div className="col-12 col-md-4">
            <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
              Transaction ID :
            </span>
            <Input className="text-end" maxLength={20} />
          </div>

          <div className="col-12 col-md-4">
          <span style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>
            Amount:
          </span>
          <Input className="text-end custom-input"placeholder={studentData2.refund_amount} readOnly />
        </div>

        <div className="col-12 col-md-4 mt-4">
          <Button style={{ backgroundColor: "#9acac9", color: "black", height: "27px" }}  onClick={handleSubmit}>
            Done
          </Button>
        </div>
        </>)}
        
        
      </div>
    
          </div>
       </> )}


       <Modal
        title={modalTitle}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Table dataSource={modalData} columns={columns} rowKey="event_date" pagination={false} />
      </Modal>


       <Modal
        title={modalTitle}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Table dataSource={modalData} columns={columns} rowKey="event_date" pagination={false} />
      </Modal>


          <Modal
            title={modalTitle}
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <Table
              dataSource={modalData}
              columns={columns}
              rowKey="event_date"
              pagination={false}
            />
          </Modal>
        </div>
      </section>
    </>
  );
};

export default Admin2Discharge;



