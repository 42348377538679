// import React, { useState, useEffect } from 'react';

// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Card from 'react-bootstrap/Card';
// import { Grid } from '@mui/material';
// import Button from 'react-bootstrap/Button';
// import axios from 'axios';
// import Header from './Header';
// import Sidebar from './sidebar';
// import Newscard from './Newscard';

// import '../../App.css';
// import {
//   CCard,
//   CCardHeader,
//   CCardBody,
//   CCardTitle,
//   CCardText,
//   CCardFooter,
//   CImage
// } from '@coreui/react';

// import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaBirthdayCake, FaTint, FaGraduationCap, FaBriefcase, FaCalendarAlt, FaAward } from 'react-icons/fa';
// import { EventAluminiFindOne, EventAluminiHomeUpdate, ImagePath } from '../../ApiFile';


// function HomeAlumini() {
//   const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
//   const [alumniData, setAlumniData] = useState({});
//   const [editableFields, setEditableFields] = useState({}); // State to manage editable fields
//   const [isEditMode, setIsEditMode] = useState(false); // State to manage edit mode
//   const userdata = JSON.parse(sessionStorage.getItem("userdata"));
//   const id = userdata.id;
//   console.log(id);
  
//   const OpenSidebar = () => {
//     setOpenSidebarToggle(!openSidebarToggle);
//   };



//   useEffect(() => {
//     const fetchAlumniData = async () => {
//       try {
//         const response = await axios.get(`${EventAluminiFindOne}/${id}`);
//         setAlumniData(response.data[0]);
//         // Initialize editable fields with alumni data
//         setEditableFields(response.data[0]);
//         console.log("API Response:", response.data); // Log API response
//       } catch (error) {
//         console.error('Error fetching alumni data:', error);
//       }
//     };

//     fetchAlumniData();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     // Update the editable fields state with the new value
//     setEditableFields({ ...editableFields, [name]: value });
//   };

//   const toggleEditMode = () => {
//     setIsEditMode(!isEditMode);
//   };

//   const updateAlumniData = async () => {
//     try {
//       const response = await axios.put(`${EventAluminiHomeUpdate}/${alumniData.id}`, editableFields);
//       alert('Update successful!');
//       setIsEditMode(false); // Exit edit mode after successful update
//       setAlumniData(editableFields); // Update alumniData state with edited values
//     } catch (error) {
//       console.error('Error updating alumni data:', error);
//       alert('Update failed!');
//     }
//   };

//   return (
//     <>
//     <Sidebar/>
//      <section className="home-section">
//        <Header/> 

//         <div className="scrollable-content" style={{ overflow: 'scroll', marginTop: '20px' }}>
//           <Container>
//           <Row className="m-0 p-0 pt-2 pb-3 ">
//               <Col
//                 className=" rounded pt-2"
//                 style={{
//                   backgroundColor: "white",
//                   border: "0px",
//                   alignItems: "center",
//                   alignContent: "center",
//                   color: " rgb(47, 79, 79)",
//                 }}
//               >
//                 <h3 style={{ color: "maroon" }}>DASHBOARD</h3>
//               </Col>
//             </Row>
//           </Container>
//           <div className="container">
//             <Grid container spacing={0.8}>
//               <Grid item xs={12} md={8} lg={8} className='mb-5'>
//                 <CCard style={{ border: 'none', boxShadow: 'grey 2px 1px 4px 1px'}}>
//                   <CCardHeader style={{ backgroundColor: 'rgb(224,228,255)' }}>
//                     <CImage
//                       src={`${ImagePath}/${alumniData.profile_img}`} 
//                       className="me-3"
//                       width={70}
//                       height={70}
//                       alt={`${ImagePath}/${alumniData.profile_img}`} 
//                     />
//                     <div>
//                     <CCardTitle style={{ color: 'blueviolet' }}>
//   {isEditMode ? (
//     <>
//       <Row> <Col sm={12} md={4}><input
//         type="text"
//         name="f_name"
//         value={editableFields.f_name}
//         onChange={handleInputChange}
//       /></Col>
//       <Col sm={12} md={4}>
//       <input
//         type="text"
//         name="m_name"
//         value={editableFields.m_name}
//         onChange={handleInputChange}
//       /></Col>
//       <Col sm={12} md={4}>
//       <input
//         type="text"
//         name="l_name"
//         value={editableFields.l_name}
//         onChange={handleInputChange}
//       /></Col></Row>
//     </>
//   ) : (
//     `${alumniData.f_name} ${alumniData.m_name} ${alumniData.l_name}`
//   )}
// </CCardTitle>
//                       <CCardText><FaEnvelope style={{ color: 'blue' }} className='mt-2' /> <b>Email:{alumniData.email_id} </b>
//                      {/*  {isEditMode ? (
//                           <input
//                             type="text"
//                             name="email_id"
//                             value={editableFields.email_id}
//                             onChange={handleInputChange}
//                           />
//                         ) : (
//                           alumniData.email_id
//                         )}*/}
//                       </CCardText>
//                       <Row>
//                         <Col md={6}>
//                           <CCardText><FaPhone style={{ color: 'blue' }} /> <b>Contact No: </b>
//                             {isEditMode ? (
//                               <input
//                                 type="text"
//                                 name="mob_no"
//                                 value={editableFields.mob_no}
//                                 onChange={handleInputChange}
//                               />
//                             ) : (
//                               alumniData.mob_no
//                             )}
//                           </CCardText>
//                         </Col>
//                         <Col md={6}>
//                           <CCardText><FaPhone style={{ color: 'blue' }} /> <b>Alternate Contact No: </b>
//                             {isEditMode ? (
//                               <input
//                                 type="text"
//                                 name="alternate_mobno"
//                                 value={editableFields.alternate_mobno}
//                                 onChange={handleInputChange}
//                               />
//                             ) : (
//                               alumniData.alternate_mobno
//                             )}
//                           </CCardText>
//                         </Col>
//                       </Row>
//                     </div>
//                   </CCardHeader>
//                   <CCardBody>
//                     <CCardText>
//                       <FaMapMarkerAlt style={{ color: 'blue' }} /> <b>Current Address: </b>
//                       {isEditMode ? (
//                         <input
//                           type="text"
//                           name="current_address"
//                           value={editableFields.current_address}
//                           onChange={handleInputChange}
//                         />
//                       ) : (
//                         `${alumniData.current_address}, ${alumniData.taluka}, ${alumniData.district}, ${alumniData.state}, ${alumniData.pincode}`
//                       )}
//                     </CCardText>
//                     <CCardText>
//                       <FaMapMarkerAlt style={{ color: 'blue' }} /> <b>Permanent Address: </b>
//                       {isEditMode ? (
//                         <input
//                           type="text"
//                           name="alternate_address"
//                           value={editableFields.alternate_address}
//                           onChange={handleInputChange}
//                         />
//                       ) : (
//                         `${alumniData.alternate_address}, ${alumniData.alternate_taluka}, ${alumniData.alternate_district}, ${alumniData.alternate_state}, ${alumniData.alternate_pincode}`
//                       )}
//                     </CCardText>
//                     <Row>
//                       <Col>
//                         <CCardText>
//                           <FaBirthdayCake style={{ color: 'blue' }} /> <b>Date of Birth: </b>
//                           {isEditMode ? (
//                             <input
//                               type="text"
//                               name="dateofbirth"
//                               value={editableFields.dateofbirth}
//                               onChange={handleInputChange}
//                             />
//                           ) : (
//                             alumniData.dateofbirth
//                           )}
//                         </CCardText>
//                       </Col>
//                       <Col>
//                         <CCardText>
//                           <FaTint style={{ color: 'blue' }} /> <b>Blood Group: </b>
//                           {isEditMode ? (
//                             <input
//                               type="text"
//                               name="blood_group"
//                               value={editableFields.blood_group}
//                               onChange={handleInputChange}
//                             />
//                           ) : (
//                             alumniData.blood_group
//                           )}
//                         </CCardText>
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col>
//                         <CCardText>
//                           <FaGraduationCap style={{ color: 'blue' }} /> <b>Qualification: </b>
//                           {isEditMode ? (
//                             <input
//                               type="text"
//                               name="qualification"
//                               value={editableFields.qualification}
//                               onChange={handleInputChange}
//                             />
//                           ) : (
//                             alumniData.qualification
//                           )}
//                         </CCardText>
//                       </Col>
//                       <Col>
//                         <CCardText>
//                           <FaBriefcase style={{ color: 'blue' }} /> <b>Professional Category: </b>
//                           {isEditMode ? (
//                             <input
//                               type="text"
//                               name="professional_category"
//                               value={editableFields.professional_category}
//                               onChange={handleInputChange}
//                             />
//                           ) : (
//                             alumniData.professional_category
//                           )}
//                         </CCardText>
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col>
//                         <CCardText>
//                           <FaBriefcase style={{ color: 'blue' }} /> <b>Service/Business Name: </b>
//                           {isEditMode ? (
//                             <input
//                               type="text"
//                               name="business_service_name"
//                               value={editableFields.business_service_name}
//                               onChange={handleInputChange}
//                             />
//                           ) : (
//                             alumniData.business_service_name
//                           )}
//                         </CCardText>
//                       </Col>
//                       <Col>
//                         <CCardText>
//                           <FaCalendarAlt style={{ color: 'blue' }} /> <b>Year Of Residence: </b>
//                           {isEditMode ? (
//                             <input
//                               type="text"
//                               name="fromyearof_boarding"
//                               value={editableFields.fromyearof_boarding}
//                               onChange={handleInputChange}
//                             />
//                           ) : (
//                             `${alumniData.fromyearof_boarding}-${alumniData.toyearof_boarding}`
//                           )}
//                         </CCardText>
//                       </Col>
//                     </Row>
//                     <CCardText>
//                       <FaAward style={{ color: 'blue' }} /> <b>Achievements if Any: </b> 
//                       {isEditMode ? (
//                         <input
//                           type="text"
//                           name="achievements"
//                           value={editableFields.achievements}
//                           onChange={handleInputChange}
//                         />
//                       ) : (
//                         alumniData.achievements
//                       )}
//                     </CCardText>
//                   </CCardBody>
//                   <CCardFooter style={{display:"flex",justifyContent:"start", border:"0px"}}>
//                     {isEditMode ? (
//                       <Button className='border-0 fs-5 ' style={{ width: '80px',backgroundColor:"rgb(23, 160, 160)",color:"black" }} onClick={updateAlumniData}>Save</Button>
//                     ) : (
//                       <Button className='border-0 fs-5 ' style={{ width: '80px',backgroundColor:"rgb(23, 160, 160)",color:"black" }} onClick={toggleEditMode}>Edit</Button>
//                     )}
//                   </CCardFooter>
//                 </CCard>
//               </Grid>
//               <Grid item xs={12} md={4} lg={4}>
//                 <Newscard />
//               </Grid>
//             </Grid>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }
// export default HomeAlumini;





import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Card, Button, Input, Grid } from 'antd';
import axios from 'axios';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaBirthdayCake, FaTint, FaGraduationCap, FaBriefcase, FaCalendarAlt, FaAward } from 'react-icons/fa';
import Newscard from './Newscard';
import { EventAluminiFindOne, EventAluminiHomeUpdate, ImagePath } from '../../ApiFile';
import Sidebar from './sidebar';
import Header from './Header';

const { Content } = Layout;
const { useBreakpoint } = Grid;

function HomeAlumini() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [alumniData, setAlumniData] = useState({});
  const [editableFields, setEditableFields] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const id = userdata.id;

  useEffect(() => {
    const fetchAlumniData = async () => {
      try {
        const response = await axios.get(`${EventAluminiFindOne}/${id}`);
        setAlumniData(response.data[0]);
        setEditableFields(response.data[0]);
      } catch (error) {
        console.error('Error fetching alumni data:', error);
      }
    };

    fetchAlumniData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableFields({ ...editableFields, [name]: value });
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const updateAlumniData = async () => {
    try {
      await axios.put(`${EventAluminiHomeUpdate}/${alumniData.id}`, editableFields);
      alert('Update successful!');
      setIsEditMode(false);
      setAlumniData(editableFields);
    } catch (error) {
      console.error('Error updating alumni data:', error);
      alert('Update failed!');
    }
  };


  return (
    <Layout>
    <Sidebar />
      <Layout className="home-section">
        <Header />
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3 style={{ color: 'maroon' }}>DASHBOARD</h3>
              </Col>
              <Col xs={24} md={16}>
                <Card
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={`${ImagePath}/${alumniData.profile_img}`}
                        alt="Profile"
                        className='me-5'
                        style={{ width: 70, height: 70 }}
                      />
                      {isEditMode ? (
                        <Row gutter={8} style={{ width: '100%' }}>
                          <Col span={8}>
                            <Input
                              type="text"
                              name="f_name"
                              value={editableFields.f_name}
                              onChange={handleInputChange}
                            />
                          </Col>
                          <Col span={8}>
                            <Input
                              type="text"
                              name="m_name"
                              value={editableFields.m_name}
                              onChange={handleInputChange}
                            />
                          </Col>
                          <Col span={8}>
                            <Input
                              type="text"
                              name="l_name"
                              value={editableFields.l_name}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                      ) : (
                        `${alumniData.f_name} ${alumniData.m_name} ${alumniData.l_name}`
                      )}
                    </div>
                  }
                  // extra={
                  //   <Button type="primary" onClick={toggleEditMode}>
                  //     {isEditMode ? 'Save' :'Edit'}
                  //   </Button>
                  // }
                  bordered={false}
                >
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                      <p>
                        <FaEnvelope style={{ color: 'blue' }} /> <b>Email:</b> {alumniData.email_id}
                      </p>
                      <p>
                        <FaPhone style={{ color: 'blue' }} /> <b>Contact No:</b>{' '}
                        {isEditMode ? (
                          <Input
                            type="text"
                            name="mob_no"
                            value={editableFields.mob_no}
                            onChange={handleInputChange}
                          />
                        ) : (
                          alumniData.mob_no
                        )}
                      </p>
                      <p>
                        <FaMapMarkerAlt style={{ color: 'blue' }} /> <b>Current Address:</b>{' '}
                        {isEditMode ? (
                          <Input
                            type="text"
                            name="current_address"
                            value={editableFields.current_address}
                            onChange={handleInputChange}
                          />
                        ) : (
                          `${alumniData.current_address}, ${alumniData.taluka}, ${alumniData.district}, ${alumniData.state}, ${alumniData.pincode}`
                        )}
                      </p>
                    </Col>
                    <Col xs={24} md={12}>
                      <p>
                        <FaPhone style={{ color: 'blue' }} /> <b>Alternate Contact No:</b>{' '}
                        {isEditMode ? (
                          <Input
                            type="text"
                            name="alternate_mobno"
                            value={editableFields.alternate_mobno}
                            onChange={handleInputChange}
                          />
                        ) : (
                          alumniData.alternate_mobno
                        )}
                      </p>
                      <p>
                        <FaMapMarkerAlt style={{ color: 'blue' }} /> <b>Permanent Address:</b>{' '}
                        {isEditMode ? (
                          <Input
                            type="text"
                            name="alternate_address"
                            value={editableFields.alternate_address}
                            onChange={handleInputChange}
                          />
                        ) : (
                          `${alumniData.alternate_address}, ${alumniData.alternate_taluka}, ${alumniData.alternate_district}, ${alumniData.alternate_state}, ${alumniData.alternate_pincode}`
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                      <p>
                        <FaBirthdayCake style={{ color: 'blue' }} /> <b>Date of Birth:</b>{' '}
                        {isEditMode ? (
                          <Input
                            type="text"
                            name="dateofbirth"
                            value={editableFields.dateofbirth}
                            onChange={handleInputChange}
                          />
                        ) : (
                          alumniData.dateofbirth
                        )}
                      </p>
                      <p>
                        <FaGraduationCap style={{ color: 'blue' }} /> <b>Qualification:</b>{' '}
                        {isEditMode ? (
                          <Input
                            type="text"
                            name="qualification"
                            value={editableFields.qualification}
                            onChange={handleInputChange}
                          />
                        ) : (
                          alumniData.qualification
                        )}
                      </p>
                    </Col>
                    <Col xs={24} md={12}>
                      <p>
                        <FaTint style={{ color: 'blue' }} /> <b>Blood Group:</b>{' '}
                        {isEditMode ? (
                          <Input
                            type="text"
                            name="blood_group"
                            value={editableFields.blood_group}
                            onChange={handleInputChange}
                          />
                        ) : (
                          alumniData.blood_group
                        )}
                      </p>
                      <p>
                        <FaBriefcase style={{ color: 'blue' }} /> <b>Professional Category:</b>{' '}
                        {isEditMode ? (
                          <Input
                            type="text"
                            name="professional_category"
                            value={editableFields.professional_category}
                            onChange={handleInputChange}
                          />
                        ) : (
                          alumniData.professional_category
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                      <p>
                        <FaBriefcase style={{ color: 'blue' }} /> <b>Service/Business Name:</b>{' '}
                        {isEditMode ? (
                          <Input
                            type="text"
                            name="business_service_name"
                            value={editableFields.business_service_name}
                            onChange={handleInputChange}
                          />
                        ) : (
                          alumniData.business_service_name
                        )}
                      </p>
                    </Col>
                    <Col xs={24} md={12}>
                      <p>
                        <FaCalendarAlt style={{ color: 'blue' }} /> <b>Year Of Residence:</b>{' '}
                        {isEditMode ? (
                          <Input
                            type="text"
                            name="fromyearof_boarding"
                            value={editableFields.fromyearof_boarding}
                            onChange={handleInputChange}
                          />
                        ) : (
                          `${alumniData.fromyearof_boarding}-${alumniData.toyearof_boarding}`
                        )}
                      </p>
                    </Col>
                  </Row>
                  <p>
                    <FaAward style={{ color: 'blue' }} /> <b>Achievements if Any:</b>{' '}
                    {isEditMode ? (
                      <Input
                        type="text"
                        name="achievements"
                        value={editableFields.achievements}
                        onChange={handleInputChange}
                      />
                    ) : (
                      alumniData.achievements
                    )}
                  </p>
                </Card>
              </Col>

                <Col xs={24} md={8}>
                  <Newscard />
                </Col>
          
            </Row>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default HomeAlumini;
