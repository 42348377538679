
import './alumini.css';
import React, { useState, useEffect, Fragment } from 'react';
import axios from "axios";
import { IoIosArrowForward } from "react-icons/io";

import Box from '@mui/material/Box';

import JobList from './Jobpost/JobList';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { CategoryFindAllApi, JobPostFindAll } from '../../ApiFile';



function Blog() {
  const [jobs, setJobs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");

  useEffect(() => {
    fetchCategories();
    fetchJobs();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(CategoryFindAllApi);
      const categoryNames = response.data.map((category) => category.categoryname);
      setCategories(["All", ...categoryNames]);
    } catch (error) {
      console.log("Error fetching categories: ", error);
    }
  };

  const fetchJobs = async () => {
    try {
      const response = await axios.get(JobPostFindAll);
      setJobs(response.data);
    } catch (error) {
      console.log("Error fetching jobs: ", error);
    }
  };

  const filterJobs = (category) => {
    setSelectedCategory(category);
  };

  const filteredJobs =
    selectedCategory === "All" ? jobs : jobs.filter((job) => job.job_category === selectedCategory);


    const bull = (
      <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
      >
        •
      </Box>
    );
    
  return (
    <div>


      <Navbar></Navbar>

      <div className="container-fluid bg3-breadcrumb">
        <div className="container text-center py-5" style={{ maxWidth: '900px', color: 'black' }}>
          <h3 className=" display-3 mb-4" style={{ color: 'white', textShadow: '3px 3px darkblue' }}> Jobs</h3>
        </div>
       
      </div>
      {/* Header End */}
      <div className="page">
        <div className="containerr">
          <div className="container-fluid" style={{backgroundColor:'#F2F2F2'}}>
          <div className="row">
        <div className="col-md-3 categories-container">
          <div className='category-box' style={{padding:'20px'}}>
          <ul className="category-list" style={{listStyleType:'none',paddingLeft:'0',border:'1px solid grey',backgroundColor:'white',borderRadius:'7px',height:'400px',overflow:'auto',boxShadow:' grey 1px 1px 1px 1px'}} >
            {categories.map((category, index) => (
              <li key={index} style={{fontSize:'18px',padding:'10px'}}>
                <button onClick={() => filterJobs(category)} style={{border:'none',background:'none'}}>
                  <IoIosArrowForward className="arrow-icon"  style={{marginRight:'5px'}}/> {category}
                </button>
              </li>
            ))}
          </ul></div>
        </div>

        <div className="col-md-9 job-cards-container" style={{padding:'10px',overflow:'auto',height:'600px'}}>
       
   <h3 style={{fontSize:'25px',marginTop:'10px'}}>Jobs</h3>
   <JobList jobs={filteredJobs} />
    
    
     
        </div>
      </div>
          </div>
        </div>
      </div>
      <Footer/>
      {/* Copyright End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up" /></a>
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
    </div>

  )
}
export default Blog;