import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import { ClipLoader } from 'react-spinners';
import { AdminPaymentApprovalFindOneApi, ImagePath, PaymentApprovalApi, PaymentFindAllApi } from '../../ApiFile';
import { DialogContent, TextField } from '@mui/material';

export default function Payment() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [memberIdForRejection, setMemberIdForRejection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(null);
  const [rejectLoading, setRejectLoading] = useState(null);
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [rejectiontext, setRejectiontext] = useState('');

  const handleCheckBoxChange = () => {
    setCheckBoxChecked(!checkBoxChecked);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios.get(PaymentFindAllApi)
      .then(response => {
        const mappedRows = response.data.map((row, index) => ({
          ...row,
          sNo: index + 1,
          View: (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleViewClick(row)}
              style={{ fontSize: '12px' }}
            >
              <span className='me-1'>View</span> <i className="fa-regular fa-eye"></i>
            </Button>
          ),
          pay_approval: getStatusButton(row)
        }));
        setRows(mappedRows);
        setLoading(false);
      })
      .catch(error => {
        // console.error(error);
        setLoading(false);
      });
  };

  const handleViewClick = (row) => {
    setLoading(true);
    console.log("dcwerg",(`${AdminPaymentApprovalFindOneApi}/${row.payment_id}/${row.member_id}`));
    axios.get(`${AdminPaymentApprovalFindOneApi}/${row.payment_id}/${row.member_id}`)
      .then(response => {
        console.log("response", response.data);
        setSelectedRow(response.data[0]);
        setOpen(true);
        setLoading(false);
      })
      .catch(error => {
        // console.error("Error fetching payment details:", error);
        toast.error("Failed to fetch payment details.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
      });
  };

  const handleApproval = (memberId) => {
    if (!checkBoxChecked) {
      alert("Please select the checkbox before proceeding.");
      return;
    }
    setApproveLoading(memberId);

const obj={
  status: 2, // 2: APPROVAL
      member_id: memberId,
      payment_id: selectedRow.payment_id,
}
// console.log("asd..",obj);

    axios.put(PaymentApprovalApi, obj)
      .then(response => {
        // console.log("response", response.data);
        if (response.data.message === "APPROVAL") {
          toast.success("Approved Successfully..!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setRows(prevRows => prevRows.map(row => row.member_id === memberId ? { ...row, pay_approval: getStatusButton({ ...row, pay_approval: 'APPROVAL' }) } : row));
          handleViewClick(selectedRow);
        }
        fetchData();
        setApproveLoading(null);
        setCheckBoxChecked(false);
        setOpen(false);
      })
      .catch((err) => {
        // console.error("Failed to save data:", err);
        toast.error("Failed...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setApproveLoading(null);
      });
  };

  const handleDeleteConfirmation = (memberId) => {
    if (!checkBoxChecked) {
      alert("Please select the checkbox before proceeding.");
      return;
    }
    setRejectLoading(memberId);
    axios.put(PaymentApprovalApi, {
      status: 3, // 3: REJECT
      member_id: memberId,
      payment_id: selectedRow.payment_id,
      reject_reason: rejectiontext,
    })
   
      .then(response => {
        console.log("response", response.data);
        if (response.data.message === "REJECT") {
          toast.success("Rejected ...!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setRows(prevRows => prevRows.map(row => row.member_id === memberId ? { ...row, pay_approval: getStatusButton({ ...row, pay_approval: 'REJECT' }) } : row));
          handleViewClick(selectedRow);
        }
        fetchData();
        setCheckBoxChecked(false);
        setRejectiontext('');
        setRejectLoading(null);
        setOpen(false);
      })
      .catch((err) => {
        // console.error("Failed to save data:", err);
        toast.error("Failed...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setRejectLoading(null);
      });
  };

  const handleRejectClick = (memberId) => {
    if (!checkBoxChecked) {
      alert("Please select the checkbox before proceeding.");
      return;
    }
    setMemberIdForRejection(memberId);
    setConfirmOpen(true);
  };

  const handleConfirmReject = () => {
    handleDeleteConfirmation(memberIdForRejection);
    setConfirmOpen(false);
  };

  const getStatusButton = (row) => {
    return row.pay_approval === 'PENDING' ? (
      <Button
        variant="contained"
        style={{ backgroundColor: 'orange', color: 'white', fontSize: '12px' }}
        onClick={() => handleViewClick(row)}
        disabled={approveLoading === row.member_id || rejectLoading === row.member_id}
      >
        {approveLoading === row.member_id || rejectLoading === row.member_id ? <ClipLoader size={15} color="#fff" /> : 'Pending'} &nbsp;
        <i className="fas fa-spinner"></i>
      </Button>
    ) : row.pay_approval === 'APPROVAL' ? (
      <Button
        variant="contained"
        style={{ backgroundColor: 'green', color: 'white', fontSize: '12px' }}
        disabled
      >
        <span className='me-1'>Approved</span>  <i className="fas fa-check"></i>
      </Button>
    ) : (
      <Button
        variant="contained"
        style={{ backgroundColor: 'red', color: 'white', fontSize: '12px' }}
        disabled
      >
        <span className='me-1'>Rejected</span>  <i className="fas fa-times"></i>
      </Button>
    );
  };

  const data = {
    columns: [
      { label: 'S.No', field: 'sNo', sort: 'asc' },
      { label: 'Name', field: 'name' },
      {
        label: 'View Details',
        field: 'View',
        sort: 'disabled',
      },
      {
        label: 'Status',
        field: 'pay_approval',
        sort: 'disabled',
      }
    ],
    rows: rows
  };
  return (
    <>
    <Sidebar />
    <section className="home-section">
      <NavLogo />
      <div className="block-header">
        <h2 className="text-center mt-md-4 mt-2 mb-3 m-0 p-0" style={{ color: 'maroon' }}>
          Payment Approval
        </h2>
      </div>
      <Row className="m-0 p-0 px-md-3 py-1 pb-5 w-100" style={{ display: 'flex' }}>
        <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
          {loading ? (
            <div className="text-center">
              <ClipLoader color="#123abc" loading={loading} size={50} />
            </div>
          ) : (
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable striped className="custom-datatable" bordered hover data={data} />
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '700px',
            maxHeight: '90vh',
            overflow: "scroll",
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            p: 4,
          }}
        >
          <Typography id="modal-modal-description" style={{ lineHeight: "30px" }}>
            <div className="block-header">
              <h2 className="mb-3" style={{ color: 'maroon', borderBottom: "2px solid maroon" }}>
                Payment Approval
              </h2>
            </div>
            <Row>
              <Col> <b>Student Name</b>:</Col>
              <Col> {selectedRow?.name} </Col>
            </Row>
            <Row>
              <Col> <b>Mobile No.</b>:</Col>
              <Col> {selectedRow?.mobileno} </Col>
            </Row>
            <Row>
              <Col> <b>Block No.</b>:</Col>
              <Col> {selectedRow?.block_id} </Col>
            </Row>
            <Row>
              <Col> <b>Room No.</b>:</Col>
              <Col> {selectedRow?.room_id} </Col>
            </Row>
            <Row>
              <Col> <b>Bed No.</b>:</Col>
              <Col> {selectedRow?.bed_id} </Col>
            </Row>
            <Row>
              <Col> <b>Term</b>:</Col>
              <Col> {selectedRow?.term} </Col>
            </Row>
            <Row>
              <Col> <b>Paid Amount</b>:</Col>
              <Col> {selectedRow?.hostelFee_payAmount}  ({selectedRow?.installment}) </Col>
            </Row>
            {selectedRow?.pay_approval !== 'PENDING' && (
              <>
                <Row>
                  <Col> <b>Payment Mode</b>:</Col>
                  <Col> {selectedRow?.pay_mode} </Col>
                </Row>
              </>)}
            <Row>
              <Col> <b>Transaction Id</b>:</Col>
              <Col> {selectedRow?.taxid} </Col>
            </Row>
            <Row>
              <Col> <b>Payment Date</b>:</Col>
              <Col> {selectedRow?.pay_date} </Col>
            </Row>
            {selectedRow?.pay_approval !== 'PENDING' && (
              <>
                <Row>
                  <Col> <b>Approval Date</b>:</Col>
                  <Col> {selectedRow?.approval_date} </Col>
                </Row>
              </>)}
            <Row className='mb-5' style={{ display: "flex" }}>
              <Col ><b>Transaction Image</b>: </Col>
              <Col className="mt-2 text-center" md={6} xs={12}>
                <img src={`${ImagePath}/${selectedRow?.tax_image}`}  className='acceptable rounded shadow border-0 ' alt='' style={{ height: "16rem", width: "15rem", border: "1px solid grey" }} />
              </Col>
            </Row>
          </Typography>
          {selectedRow?.pay_approval == 'PENDING' && (
            <>
              <Box>
                <Checkbox className='ps-0 m-0' style={{ position: "relative" }} checked={checkBoxChecked} onChange={handleCheckBoxChange} /> <b>Check And Proceed..</b>
                <Row className='mb-5'>
                  <Col md={6}>
                    <Button
                      variant="contained"
                      className='w-100'
                      color="primary"
                      sx={{ mt: 2, mr: 2, backgroundColor: selectedRow.pay_approval === 'APPROVAL' ? 'green' : null }}
                      onClick={() => handleApproval(selectedRow.member_id)}
                      disabled={approveLoading === selectedRow.member_id}
                    >
                      {approveLoading === selectedRow.member_id ? <ClipLoader size={15} color="#fff" /> : 'Approve'}
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button
                      className='w-100'
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 2, mr: 2, backgroundColor: selectedRow.pay_approval === 'REJECT' ? 'red' : null }}
                      onClick={() => handleRejectClick(selectedRow.member_id)}
                      disabled={rejectLoading === selectedRow.member_id}
                    >
                      {rejectLoading === selectedRow.member_id ? <ClipLoader size={15} color="#fff" /> : 'Reject'}
                    </Button>
                  </Col>
                </Row>
              </Box>
            </>
          )}
          <Box
            sx={{
              p: 2,
              borderTop: '1px solid #ccc',
              textAlign: 'right',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="confirmation-modal-title"
        aria-describedby="confirmation-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '400px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            p: 4,
          }}
        >
          <Typography id="confirmation-modal-title" variant="h6" component="div" >
            <b>Confirm Rejection</b>
          </Typography>
          {/* <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
            Are you sure, want to reject this payment?
          </Typography> */}
          <DialogContent>
      <TextField
        label="Reason for Rejection"
        placeholder="Enter reason for rejection"
        value={rejectiontext}
        onChange={(e) => setRejectiontext(e.target.value)}
        fullWidth
        margin="normal"
      />
    </DialogContent>
          <Row style={{ display: "flex" }}>
            <Col> 
              <Button
                variant="contained"
                color="secondary"
                className='w-100'
                sx={{ mt: 2, mr: 2 }}
                onClick={handleConfirmReject}
              >
                Confirm
              </Button>
            </Col>
            <Col>
              <Button
                variant="contained"
                className='w-100'
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => setConfirmOpen(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Box>
      </Modal>
    </section>
  </>
);
}
