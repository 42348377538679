import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import './Student.css';
import NavLogo1 from '../../StudentRegristrationDesign/studentimg.jpg'
import NavLogo2 from '../../StudentRegristrationDesign/aadharcard.png'
import NavLogo3 from '../../StudentRegristrationDesign/taximg.png'
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'react-toastify';
import { ImagePath, StudentAFindOneApi, StudentApprovalApi, StudentApprovalListApi } from '../../ApiFile';
import { CircularProgress } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';


export default function StudentView() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedPayType, setSelectedPayType] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [statusRow, setStatusRow] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [date, setDate] = useState(null);
  const tomorrow = dayjs().add(1, 'day');
  const sessionYears = sessionStorage.getItem('selectedYear');

  const handleCheckBoxChange = () => {
    setCheckBoxChecked(!checkBoxChecked);
  };

  function capitalizeWords(str) {
    return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  }


  const fetchData = () => {
    axios.get(StudentApprovalListApi)
      .then(response => {
        const mappedRows = response.data.map((row, index) => ({
          ...row,
          Name: row.name,
        }));
        setRows(mappedRows);
        // console.log("mapped rows: ", mappedRows);
      })
      .catch(error => {
        // console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleApproval = (id) => {

    if (!selectedPayType) {
      alert("Please select a term first..");
      return;
    }
  //   if (selectedRowId === statusRow?.reg_id && selectedPayType === 1) {
  //   if (date && date.isBefore(tomorrow, 'day')) {
  //     toast.error('Please select a date from tomorrow onwards.');
  //     return;
  //   }
  // }
    if (!checkBoxChecked) {
      alert("Please select the checkbox before proceeding.");
      return;
    }
    const formattedDate = date ? dayjs(date).format('DD/MM/YYYY') : "0";
   
    setLoading(true);
    const approvalData = {
      reg_id: id,
      status: 1, // Flag for approval 
      pay_type: selectedPayType,
      installment_date: formattedDate,
      boarding_year: sessionYears
   
    };
    // console.log("approval data: ", approvalData);

    axios.put(StudentApprovalApi, approvalData)
      .then(res => {
        // console.log(res);
        if (res.data === "APPROVAL") {
          // console.log("Approved successfully:", res.data);
          toast.success("Approved Successfull", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setSelectedPayType('');
          setDate('');
          setCheckBoxChecked(false);
          setStatusModalOpen(false);
          fetchData(); // Refresh data after approval
          setLoading(false);
        }
        else if (res.data === "ERROR") {
          toast.error("Error !!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        // console.error("Failed to save data:", err);
        toast.error("Failed...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    
    if (!checkBoxChecked) {
      alert("Please select the checkbox before proceeding.");
      return;
    }
    const formattedDate = date ? dayjs(date).format('DD/MM/YYYY') : "0";
    setLoading1(true)
    const rejectionData = {
      reg_id: id,
      status: 2, // Flag for rejection
      pay_type: selectedPayType,
      installment_date: formattedDate,
      boarding_year: sessionYears
    };
    // console.log("rejection data: ", rejectionData);

    axios.put(StudentApprovalApi, rejectionData)
      .then(res => {
        // console.log(res);
        setDialogOpen(false);
        if (res.data === "REJECT") {
          // console.log("Rejected successfully:", res.data);
          toast.success("Rejected Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setSelectedPayType('');
          setStatusModalOpen(false);
          fetchData(); // Refresh data after rejection
          setLoading1(false);
        }
      })
      .catch((err) => {
        // console.error("Failed to save data:", err);
        toast.error("Failed...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading1(false);
      });
  };

  const handleViewClick = (row) => {
    setSelectedRow(row || {});
    setOpen(true);

    axios.get(`${StudentAFindOneApi}/${row.reg_id}`)
      .then(response => {
        // console.log("findOne API response: ", response);
        setSelectedRow(response.data);
        // console.log("selected row: ", response.data);
      })
      .catch(error => {
        // console.error(error);
      });
  };

  const handleDeleteConfirmation = (id) => {
    if (!checkBoxChecked) {
      alert("Please select the checkbox before proceeding.");
      return;
    }
    setRowToDelete(id);
    setDialogOpen(true);
  };

  const handleCheckboxChange = (rowId, payType) => {
    if (selectedRowId === rowId && selectedPayType === payType) {
      setSelectedPayType(null);
      setSelectedRowId(null);
    } else {
      setSelectedRowId(rowId);
      setSelectedPayType(payType);
    }
  };

  const handleStatusModalOpen = (row) => {
    setStatusRow(row);
    handleViewClick(row)
    setStatusModalOpen(true);
  };

  const data = {
    columns: [
      { label: 'Reg_ID', field: 'reg_id' },
      { label: 'Name', field: 'Name', width: 150 },
      {
        label: 'View',
        field: 'View',
        render: (row) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleViewClick(row)}
            style={{ fontSize: '12px' }}
          >
          <span className='me-1'>View</span> <i class="fa-regular fa-eye"></i>
          </Button>
        )
      },
      {
        label: 'Status',
        field: 'Status',
        render: (row) => {
          if (row.admin_approval === "0") {
            return (
              <Button
                variant="contained"
        style={{ backgroundColor: 'orange', color: 'white', fontSize: '12px' }}
                onClick={() => handleStatusModalOpen(row)}
    
              >
                  <span className='me-1'>Pending</span> <i class="fas fa-spinner"></i>
              </Button>
            );
          } else if (row.admin_approval === "1") {
            return (
              <Button
                variant="contained"
                style={{ backgroundColor: 'green', color: 'white', fontSize: '12px' }}
                disabled
              >
               <span className='me-1'>Approved</span>  <i class="fas fa-check"></i>
              </Button>
            );
          } else {
            return (
              <Button
                variant="contained"
                style={{ backgroundColor: 'red', color: 'white', fontSize: '12px' }}
                disabled
              >
              <span className='me-1'>Rejected</span>  <i class="fas fa-times"></i>
              </Button>
            );
          }
        }
      },
     
    ],
    rows: rows.map(row => ({
      ...row,
      View: (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleViewClick(row)}
          style={{ fontSize: '12px' }}
        >
       <span className='me-1'>View</span> <i class="fa-regular fa-eye"></i>
        </Button>
      ),
      Status: row.admin_approval ===
      "0" ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleStatusModalOpen(row)}
        style={{ backgroundColor: 'orange', color: 'white', fontSize: '12px' }}
        >
             <span className='me-1'>Pending</span> <i class="fas fa-spinner"></i>
        </Button>
      ) : row.admin_approval === "1" ? (
        <Button
          variant="contained"
          style={{ backgroundColor: 'green', color: 'white', fontSize: '12px' }}
          disabled
        >
           <span className='me-1'>Approved</span>  <i class="fas fa-check"></i>
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{ backgroundColor: 'red', color: 'white', fontSize: '12px' }}
          disabled
        >
        <span className='me-1'>Rejected</span>  <i class="fas fa-times"></i>
        </Button>
      )
      
    }))
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Member Approval
          </h2>
        </div>

        <Row className="m-0 p-0 px-md-3 py-1 pb-5 mb-5 mt-4 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable striped className="custom-datatable text-center" bordered hover small data={data} />
              </div>
            </div>
          </Col>
        </Row>
        <div>
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={() => setAlertOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert
              onClose={() => setAlertOpen(false)}
              severity="warning"
              sx={{ width: '100%' }}
            >
              Please select a term first.
            </Alert>
          </Snackbar>
        </div>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '700px',
              maxHeight: '90vh',
              overflow:"scroll",
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                overflowY: 'auto',
                p: 4,
                flex: 1,
              }}
            >
              <Typography id="modal-modal-description" style={{lineHeight:"30px"}}>
               

              <div className="block-header">
        <h2 className="mb-3" style={{ color: 'maroon' ,borderBottom:"2px solid maroon"}}>
          Member Approve
          </h2>
        </div>
        <Row>
          <Col> <b>Student's Name</b>:</Col>
          <Col> {selectedRow.name} </Col>
        </Row>
        <Row>
          <Col> <b>Father's Name</b>:</Col>
          <Col> {selectedRow.fatherName} </Col>
        </Row>
        <Row>
          <Col ><b>Address</b>:</Col>
          <Col >{selectedRow.address}</Col>
        </Row>
        <Row>
          <Col ><b>Mobile No.</b>:</Col>
          <Col >{selectedRow.mob_no}  </Col>
        </Row>
        <Row>
          <Col ><b>Parent's Mob. No.</b>:</Col>
          <Col >{selectedRow.parents_mobno}  </Col>
        </Row>
        <Row>
          <Col ><b>Email</b>:</Col>
          <Col >{selectedRow.email}</Col>
        </Row>
        <Row>
          <Col ><b>Date Of Birth</b>:</Col>
          <Col >{selectedRow.dateofbirth}</Col>
        </Row>
        <Row>
          <Col ><b>Father's Occupation</b>:</Col>
          <Col >{selectedRow.father_occupation}</Col>
        </Row>
        <Row>
          <Col ><b>Sub-Category</b>:</Col>
          <Col >{selectedRow.sub_category}</Col>
        </Row>
        <Row>
          <Col ><b>College Name</b>:</Col>
          <Col >{selectedRow.collage_name}</Col>
        </Row>
        <Row>
          <Col ><b>Last College Name</b>:</Col>
          <Col >{selectedRow.l_collagename}</Col>
        </Row>
        <Row>
          <Col ><b>Last Year Student</b>:</Col>
          <Col >{selectedRow.profeciency}</Col>
        </Row>
        <Row>
          <Col ><b>Scholarship</b>:</Col>
          <Col >{selectedRow.fellowship}</Col>
        </Row>
      
            <Row style={{display:"flex",justifyContent:"center"}}>
              <Col className='mt-2' xs={12} md={4} >
              <b>Photo</b>: <br/><div className='text-center mt-2'>
              <img src={`${ImagePath}/${selectedRow.image}`}  className='acceptable rounded shadow border-0 text-center' alt='' style={{ height: "13rem",width:"12rem", border: "1px solid grey" }} /></div>
              </Col>
              <Col className='mt-2' xs={12} md={4}>
              <b>Aadhar Card</b>: <br/>
              <div className='text-center mt-2'>
              <img src={`${ImagePath}/${selectedRow.adharcard}`}  className='acceptable rounded shadow border-0 text-center' alt='' style={{ height: "13rem",width:"12rem", border: "1px solid grey" }} /></div>
              </Col>
            </Row>

            <Row style={{display:"flex",justifyContent:"center"}}>
              <Col className='mt-2' xs={12} md={4} >
              <b>MarkList</b>: <br/><div className='text-center mt-2'>
              <img src={`${ImagePath}/${selectedRow.marklist}`}  className='acceptable rounded shadow border-0 text-center' alt='' style={{ height: "13rem",width:"12rem", border: "1px solid grey" }} /></div>
              </Col>
              <Col className='mt-2' xs={12} md={4}>
              <b>School / College Admission Receipt</b>: <br/>
              <div className='text-center mt-2'>
              <img src={`${ImagePath}/${selectedRow.profeciency_document}`}  className='acceptable rounded shadow border-0 text-center' alt='' style={{ height: "13rem",width:"12rem", border: "1px solid grey" }} /></div>
              </Col>
            </Row>

            <Box
              sx={{
                textAlign: 'right',
                marginTop:'50px'
              }}
            >
              <Button
                variant="contained"
              
                color="primary"
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            </Box>
          </Typography>
            </Box>
            
          </Box>
        </Modal>

        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        >
          <DialogTitle>{"Reject Student"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you really want to reject the student?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDelete(rowToDelete)} color="primary" autoFocus  disabled={loading1}  // Disable button while loading
      >
        {loading1 ?  <p>Loading...</p> : 'Confirm'}
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
          open={statusModalOpen}
          onClose={() => setStatusModalOpen(false)}
          aria-labelledby="status-modal-title"
          aria-describedby="status-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '700px',
              maxHeight: '90vh',
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                overflowY: 'auto',
                p: 4,
                flex: 1,
              }}
            >
             <Typography id="modal-modal-description" style={{lineHeight:"30px"}}>
               

               <div className="block-header">
         <h2 className="mb-3" style={{ color: 'maroon' ,borderBottom:"2px solid maroon"}}>
           Member Approve
           </h2>
         </div>
         <Row>
           <Col> <b>Student's Name</b>:</Col>
           <Col> {selectedRow.name} </Col>
         </Row>
         <Row>
           <Col> <b>Father's Name</b>:</Col>
           <Col> {selectedRow.fatherName} </Col>
         </Row>
         <Row>
           <Col ><b>Address</b>:</Col>
           <Col >{selectedRow.address}</Col>
         </Row>
         <Row>
           <Col ><b>Mobile No.</b>:</Col>
           <Col >{selectedRow.mob_no}  </Col>
         </Row>
         <Row>
           <Col ><b>Parent's Mob. No.</b>:</Col>
           <Col >{selectedRow.parents_mobno}  </Col>
         </Row>
         <Row>
           <Col ><b>Email</b>:</Col>
           <Col >{selectedRow.email}</Col>
         </Row>
         <Row>
           <Col ><b>Date Of Birth</b>:</Col>
           <Col >{selectedRow.dateofbirth}</Col>
         </Row>
         <Row>
           <Col ><b>Father's Occupation</b>:</Col>
           <Col >{selectedRow.father_occupation}</Col>
         </Row>
         <Row>
           <Col ><b>Sub-Category</b>:</Col>
           <Col >{selectedRow.sub_category}</Col>
         </Row>
         <Row>
           <Col ><b>College Name</b>:</Col>
           <Col >{selectedRow.collage_name}</Col>
         </Row>
         <Row>
           <Col ><b>Last College Name</b>:</Col>
           <Col >{selectedRow.l_collagename}</Col>
         </Row>
         <Row>
           <Col ><b>Last Year Student:</b>:</Col>
           <Col >{selectedRow.profeciency}</Col>
         </Row>
         <Row>
           <Col ><b>Scholarship</b>:</Col>
           <Col >{selectedRow.fellowship}</Col>
         </Row>
       
         <Row style={{display:"flex",justifyContent:"center"}}>
              <Col className='mt-2' xs={12} md={4} >
              <b>Photo</b>: <br/><div className='text-center mt-2'>
              <img src={`${ImagePath}/${selectedRow.image}`}  className='acceptable rounded shadow border-0 text-center' alt='' style={{ height: "13rem",width:"12rem", border: "1px solid grey" }} /></div>
              </Col>
              <Col className='mt-2' xs={12} md={4}>
              <b>Aadhar Card</b>: <br/>
              <div className='text-center mt-2'>
              <img src={`${ImagePath}/${selectedRow.adharcard}`}  className='acceptable rounded shadow border-0 text-center' alt='' style={{ height: "13rem",width:"12rem", border: "1px solid grey" }} /></div>
              </Col>
            </Row>

            <Row style={{display:"flex",justifyContent:"center"}}>
              <Col className='mt-2' xs={12} md={4} >
              <b>MarkList</b>: <br/><div className='text-center mt-2'>
              <img src={`${ImagePath}/${selectedRow.marklist}`}  className='acceptable rounded shadow border-0 text-center' alt='' style={{ height: "13rem",width:"12rem", border: "1px solid grey" }} /></div>
              </Col>
              <Col className='mt-2' xs={12} md={4}>
              <b>School / College Admission Receipt</b>: <br/>
              <div className='text-center mt-2'>
              <img src={`${ImagePath}/${selectedRow.profeciency_document}`}  className='acceptable rounded shadow border-0 text-center' alt='' style={{ height: "13rem",width:"12rem", border: "1px solid grey" }} /></div>
              </Col>
            </Row>

           </Typography>
            <div className="block-header mt-4">
            <h5 className="mb-3" style={{ color: 'maroon' ,borderBottom:"2px solid maroon"}}>
          Select Term
          </h5>
        </div>
              <Typography id="status-modal-description" sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRowId === statusRow?.reg_id && selectedPayType === 1}
                      onChange={() => handleCheckboxChange(statusRow?.reg_id, 1)}
                    />
                  }
                  label="Full Term"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRowId === statusRow?.reg_id && selectedPayType === 2}
                      onChange={() => handleCheckboxChange(statusRow?.reg_id, 2)}
                    />
                  }
                  label="Half Term"
                />
                {/* {selectedRowId === statusRow?.reg_id && selectedPayType === 1 && ( */}
                  <Row className='mt-2 mb-3'>
                  <div className="block-header">
            <h5 className="mb-3" style={{ color: 'maroon' ,borderBottom:"2px solid maroon"}}>
           Due Date For 2nd Installement
          </h5>
        </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        label="Select Date"
                        value={date}
                        minDate={tomorrow}
                        onChange={(newDate) => {
                          console.log("date res", newDate);
                          setDate(newDate);
                        }}
                        slotProps={{ textField: { size: 'small' } }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  </Row>
                {/* )} */}

   <Box >
   <Checkbox className='ps-0 m-0' style={{position:"relative"}} checked={checkBoxChecked} onChange={handleCheckBoxChange}/> <b>Check And Proceed..</b>
           <Row className='mt-3'>
              <Col>
              <Button
                variant="contained"
                color="primary"
                className='w-100'
                onClick={() => handleApproval(statusRow?.reg_id)}
                style={{ marginRight: '10px' }}
                disabled={loading}  // Disable button while loading
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Approve'}
      </Button>
              </Col>
              <Col>
              <Button
                variant="contained"
                className='w-100'
                color="secondary"
                onClick={() => handleDeleteConfirmation(statusRow?.reg_id)}
              >
                Reject
              </Button>
              </Col>
            </Row>
            </Box>

            <Box
              sx={{
                borderTop: '1px solid #ccc',
                textAlign: 'right',
               
              }}
            >
              <Button
                variant="contained"
             style={{ marginTop:'50px'}}
                color="primary"
                onClick={() => setStatusModalOpen(false)}
              >
                Close
              </Button>
            </Box>
              </Typography>
            </Box>
            
          </Box>
        </Modal>
      </section>
    </>
  );
}
