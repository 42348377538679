import React from "react";
import { FaBriefcase, FaInfoCircle, FaUser, FaEnvelope, FaMapMarker, FaUserTie, FaRegCalendarCheck } from "react-icons/fa";
import './Job.css';

const Job = ({ job }) => {
  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card h-100 job-card" style={{width:'300px',backgroundColor:'#B9D9EB'}}>
        <div className="card-body">
          <h5 className="card-title" style={{color:'darkblue'}}>{job.job_title}</h5>
          <p className="card-text"><FaBriefcase /> <strong>Job Type:</strong> {job.job_type}</p>
          <p className="card-text"><FaInfoCircle /> <strong>Description:</strong> {job.discription}</p>
          <p className="card-text"><FaRegCalendarCheck /> <strong>Experience:</strong> {job.experience}</p>
          <p className="card-text"><FaUserTie /> <strong>Industry:</strong> {job.industry_name}</p>
          <p className="card-text"><FaUser /> <strong>Contact:</strong> {job.contact}</p>
          <p className="card-text"><FaEnvelope /> <strong>Email:</strong> {job.email_id}</p>
          <p className="card-text"><FaMapMarker /> <strong>Location:</strong> {job.address}</p>
          <p className="card-text"><FaUser /> <strong>Vacancy:</strong> {job.vaccancy}</p>
        </div> 
      </div>
    </div>
  );
};

export default Job;
