import React, { useEffect, useState } from 'react';
import { Col, Container, Form, FormControl, Nav, Row, Spinner } from 'react-bootstrap';
import changePass from '../../RoomBookingDesign/img/changepass.svg'
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ChangePasswordApi } from '../../ApiFile';
import Sidebar from './sidebarstudent';
import Header from './Headerstudent';

const ChangePasswordStudent1 = () => {
    const [loading, setLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const navigate = useNavigate();

    const memberId = sessionStorage.getItem('member_id');
    const memberName = sessionStorage.getItem('memberName');
    const email = sessionStorage.getItem('email');


    const input = {
      border: '1px solid',
      margin: '10px 10px 10px 0px',
    };
    
  
  
    const handlePasswordChange = (value) => {
      setPassword(value);
      setPasswordsMatch(value === confirmPassword);
    };
  
    const handleConfirmPasswordChange = (value) => {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    };
  


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!oldPassword || !password || !confirmPassword) {
            toast.error('Please fill in all the password fields.');
            return;
        }
    
        if (!passwordsMatch) {
            toast.error('Passwords do not match');
            return;
        }
    
        try {
            setLoading(true);
    
            const Obj = {
               

 email : email ,
 oldpassword: oldPassword,
 createpassword: password,
confirmpassword : confirmPassword

            };
    
            console.log("Request Object:", Obj);
    
            // Make API call
            const response = await axios.put(ChangePasswordApi, Obj);
            console.log("res../...",response);
            if(response.data === "NOTFOUND"){
                toast.error('Old password does not match.');
 
            }else if (response.data === 'UPDATE') {
                // Customize the response for password updated successfully
                toast.success('Password changed successfully');
                setOldPassword('');
                setPassword('');
                setConfirmPassword('');
            } else {
                // Handle unknown response
                toast.error('Unknown response');
            }
        } catch (error) {
            // Handle errors during the API call
            toast.error('An error occurred while changing the password');
        } finally {
            setLoading(false);
        }
    };
    
    
    
      
    return (<>
        <Sidebar/>
        <section className="home-section">
          <Header/>

            <Container className='mb-5 mt-md-5 mt-3'>
            <Row className='pt-md-3 mt-0'>
            <Col sm={12} md={2} ></Col>
                <Col sm={12} md={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={changePass} className='w-100' />
                </Col>
                <Col md={7} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className='login-container mb-4 mb-md-0'>
                        <div className='login-form shadow mb-5 mb-md-3 py-3 px-2 px-md-4' style={{ border: "3px solid rgb(127, 183, 181)", borderRadius: "15px" }}>
                            <form
                                onSubmit={handleSubmit}
                            >
                                <div className='input'>

                                    <h2 className='text-center mb-4' style={{color:"black"}}>Change Password </h2>

                                    <div className="col-md-12 ">
                      <Form.Group controlId="validationCustom06">
                        <label htmlFor="password" style={{color:"black"}}>Old Password :</label>
                        <FormControl
                          style={input}
                          type="password"
                          id="password"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>


                                    <div className="col-md-12 ">
                      <Form.Group controlId="validationCustom06">
                        <label htmlFor="password" style={{color:"black"}}>Create Password :</label>
                        <FormControl
                          style={input}
                          type="password"
                          id="password"
                          value={password}
                          isInvalid={!passwordsMatch}
                          onChange={(e) => handlePasswordChange(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>


                    <div className="col-md-12">
                      <Form.Group controlId="validationCustom06">
                        <label htmlFor="confirmPassword" style={{color:"black"}}>Confirm Password :</label>
                        <FormControl
                          style={input}
                          type="password"
                          id="confirmPassword"
                          value={confirmPassword}
                          isInvalid={!passwordsMatch}
                          onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                          required
                        />
                        {/* <Form.Control.Feedback type="invalid">
                          Password do not match
                        </Form.Control.Feedback> */}
                      </Form.Group>
                    </div>

                                    <div className=' text-center mt-3 mb-3' style={{display:"flex",justifyContent:"center"}}>
                                        <button type="submit" className='rounded  text-center' style={{backgroundColor:"rgb(127, 183, 181)",border:"1px solid rgb(127, 183, 181)"}}>{loading ? <Spinner animation='border' size='sm' variant="primary" /> : <b>Confirm</b>}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
            </Container>

      </section>
      </>
    )
}

export default ChangePasswordStudent1;
