import react, { Fragment } from 'react';
import './about.css';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';


function About(){
    return(
      <div>
        <Navbar></Navbar>
      <div className="container-fluid bg-breadcrumbabout">
        <div className="container text-center py-5" style={{maxWidth: '900px', color: 'black'}}>
          <h3 className=" display-3 mb-4" style={{color: 'white', textShadow: '3px 3px darkblue'}}>About Us</h3>
        </div>
      </div>
      {/* Header End */}
      {/* About Start */}
      <div className="container-fluid about py-5" style={{marginTop: '-100px'}}>
        <div className>
          <div className="row m-0 p-0 g-5 align-items-center">
            <div className="col-lg-6 about" style={{marginTop: '-10px'}}>
              <h5 className="section-title px-3">About Us</h5>        
              <p className="mb-4">At present, Boarding is providing various facility to the hostel students at
                district place in very reasonable fees. Boarding has total 4 buildings providing student’s
                accommodation, commercial spaces &amp; offices of “Dakshin Bharat Jain Sabha, Veer Seva Dal, Central
                Library and Padwidhar Sanghatana are also functioning in the same premises.</p>
            </div>
            <div className="col-lg-6" style={{marginTop: '15px'}}>
              <h5 className="section-title his px-3">History</h5>
              <p className="mb-4">Sheth R.D. Dawada Digambar Jain Boarding, Sangli is one of the important part of
                Dakshin Bharat Jain Sabha. It was established on 8th June, 1911. It started its journey at the
                ‘Panjar Pol’ building. In 1918, it purchased its own land &amp; built its own building through
                valuable donation of Sheth Ramchand Dhanaji Dawada (Natepute) on 28th Nov, 1922.</p>
              <p className="mb-4">Historically, importance of Jain Boarding has been increased as a center of Jain
                religion not only in Sangli but also in the Southern region. In the pre-independence period it
                was centre of freedom struggle and hiding place for freedom fighters. Since independence up to
                90’s period of ‘Adv. Viracharya Babasaheb Kuchnure, Jain Boarding had kept its treasure of
                social, economic, religions developments. Till today boarding carrying social responsibilities
                with same sanctity.</p>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Travel Guide Start */}
      {/* Travel Guide End */}
      {/* Subscribe Start */}
      {/* Subscribe End */}
      <Footer />
      {/* Footer End */}
      {/* Copyright Start */}
     
      {/* Copyright End */}
      {/* Back to Top */}
      {/* <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up" /></a> */}
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
    </div>

    )
}
export default About;