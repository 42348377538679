import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import { Visibility, Delete } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { GalleryAddApi, GalleryDeleteApi, GalleryFindAllApi, GalleryUpdateApi, ImagePath } from '../../ApiFile';

const GalleryMaster = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [viewImg, setViewImg] = useState(null);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(GalleryFindAllApi);
      const images = response.data.map((img, index) => ({
        serial_no: index + 1,
        id: img.id,
        upload_img: <img src={`${ImagePath}/${img.upload_img}`} alt={`img-${img.id}`} style={{ width: '100px' }} />,
        view: <Button variant="contained" onClick={() => handleView(img.upload_img)}>  <Visibility fontSize="small" /></Button>,
        edit: <Button variant="contained" style={{color:"white",backgroundColor:"orange"}} onClick={() => handleEdit(img.id)}><UpdateIcon /></Button>,
        delete: <Button variant="contained" color="secondary" onClick={() => openDeleteDialog(img.id)}>    <DeleteIcon /></Button>,
      }));
      setData(images);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleAddRule = () => {
    setSelectedFile(null);
    setEditMode(false);
    setOpen(true);
  };

  const handleEdit = id => {
    setCurrentId(id);
    setEditMode(true);
    setOpen(true);
  };

  const openDeleteDialog = id => {
    setDeleteId(id);
    setDeleteOpen(true);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${GalleryDeleteApi}/${deleteId}`);
      if (response.data === "DELETE") {
        toast.success('Image deleted successfully');
      } else if (response.data === "NOTFOUND") {
        toast.error('Image not found');
      } else {
        toast.error('Error deleting image');
      }
      fetchImages();
      setDeleteOpen(false);
    } catch (error) {
      console.error('Error deleting image:', error);
      toast.error('Error deleting image');
    }
  };

  const handleView = imgPath => {
    setViewImg(`${ImagePath}/${imgPath}`);
    setViewOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setViewOpen(false);
    setDeleteOpen(false);
  };

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async () => {

    if (!selectedFile) {
      toast.error('Please select a file');
      return;
    }
    
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      if (editMode) {
        const response = await axios.put(`${GalleryUpdateApi}/${currentId}`, formData);
        if (response.data === "UPDATE") {
          toast.success('Image updated successfully');
        } else {
          toast.error('Error updating image');
        }
      } else {
        const response = await axios.post(GalleryAddApi, formData);
        if (response.data === "SAVED") {
          toast.success('Image saved successfully');
        } else {
          toast.error('Error saving image');
        }
      }
      fetchImages();
      handleClose();
    } catch (error) {
      console.error('Error saving image:', error);
      toast.error('Error saving image');
    }
  };

  const columns = [
    { label: 'Serial No', field: 'serial_no', sort: 'asc', width: 50 },
    { label: 'View', field: 'view', sort: 'asc', width: 150 },
    { label: 'Edit', field: 'edit', sort: 'asc', width: 150 },
    { label: 'Delete', field: 'delete', sort: 'asc', width: 150 },
  ];

  const tableData = {
    columns: columns,
    rows: data
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div>
          <div className="block-header">
            <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
              Gallery Master
            </h2>
            <Button
              className="ms-md-5 m-0 ms-3 mb-2 mb-md-4 mt-2 mt-md-4"
              variant="contained"
              color="primary"
              onClick={handleAddRule}
            >
              Add Image
            </Button>
          </div>
          <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
            <Col className="m-0 p-0  px-3 py-3 rounded" style={{ background: 'white' }}>
              <div style={{ overflowY: 'auto' }}>
                <div style={{ overflowX: 'auto' }}>
                  <MDBDataTable striped className="custom-datatable" bordered hover data={tableData} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <Dialog open={open} onClose={handleClose} style={{border:"1px solid maroon"}}>
        <DialogTitle style={{color:"maroon",borderBottom:"1px solid maroon"}} variant='h5' className='p-0 py-2 px-2 bold'>{editMode ? 'Edit Image' : 'Add Image'}</DialogTitle>
        <DialogContent>
          <DialogContentText className='mb-3  pt-3 text-dark' variant='h6'>
            {editMode ? 'Edit the selected image.' : 'Upload a new image.'}
          </DialogContentText>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="span">
              {selectedFile ? selectedFile.name : 'Choose File'}
            </Button>
          </label>
        </DialogContent>
        <DialogActions style={{borderTop:"1px solid maroon"}}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {editMode ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={viewOpen} onClose={handleClose}>
        <DialogTitle style={{color:"maroon",borderBottom:"1px solid maroon"}} variant='h5' className='p-0 py-2 px-2 bold'>View Image</DialogTitle>
        <DialogContent>
          <img src={viewImg} alt="view" style={{ width: '100%' }} />
        </DialogContent>
        <DialogActions style={{borderTop:"1px solid maroon"}}>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteOpen} onClose={handleClose}>
        <DialogTitle style={{color:"maroon",borderBottom:"1px solid maroon"}} variant='h5' className='p-0 py-2 px-2 bold'>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText className='pt-3 text-dark'>Are you sure you want to delete this image?</DialogContentText>
        </DialogContent>
        <DialogActions style={{borderTop:"1px solid maroon"}}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default GalleryMaster;

