import react, { Fragment } from 'react';
import Navbar from '../Components/Navbar';
import { Box, Select, MenuItem, Button, Grid, Typography, Dialog, DialogTitle, DialogContent, DialogActions, FilledInput } from '@mui/material';
import './job.css';
import { useState, hover } from 'react';
import { Modal, TextField } from "@mui/material";
import { FormControl, InputLabel } from "@mui/material";
import Footer from '../Components/Footer';


const skills = ["Javascript", "Node.js", "Java"];





function Job() {
  const [showModal, setShowModal] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [jobCategory, setJobCategory] = useState("");
  const [jobType, setJobType] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [jobDescription, setJobDescription] = useState("");

  const handlePostJob = () => {
    // Handle posting job logic here
    console.log("Posting job:", jobTitle, jobCategory, jobType, industryName, jobDescription);

    // Close the modal after posting the job
    setShowModal(false);
  };



  const [hover, setHover] = useState(false);
  return (
    <div>

      <Navbar></Navbar>

      <div className="container-fluid bg4-breadcrumb">
        <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h3 className="text-white display-3 mb-4" style={{ textShadow: '3px 3px #ee1515' }}>JOBS</h3>
          <Button variant="contained" style={{ backgroundColor: " #55c2da", color: "black", borderRadius: "20px", padding: "10px 20px", fontSize: "20px" }} onClick={() => setShowModal(true)}>Post a Job </Button>
        </div>
      </div>

      <Box className="wrapper center" style={{ backgroundColor: '#fff', display: 'flex', boxShadow: "0px 1px 5px rgba(0,0,0,0.1)", borderRadius: "5px", marginLeft: "100px", width: "1100px", marginTop: "-35px" }} component="section" sx={{ p: 2 }}>
        <Select disableUnderline variant='filled' defaultValue="Full Time" style={{ flex: "1", height: "45px", margin: "8px" }}>
          <MenuItem value="Full Time" mt={1}>Full time</MenuItem>
          <MenuItem value="Part Time">Part Time</MenuItem>
          <MenuItem value="Contract">Contract</MenuItem>
        </Select>
        <Select disableUnderline variant='filled' defaultValue="Remote" style={{ flex: "1", height: "45px", margin: "8px" }}>
          <MenuItem value="Remote">Remote</MenuItem>
          <MenuItem value="In-Office">In-Office</MenuItem>

        </Select>
        <Button variant="contained" style={{ flex: "1", height: "45px", borderRadius: "0px", margin: "8px" }}>Search</Button>
      </Box>

      <Box p={2} mt={2} style={{  border: "1px solid #e8e8e8",
    backgroundColor: "#F0F8FF",
    marginLeft: "auto",
    marginRight: "auto",
    width: "90%", // Adjust width for smaller screens
    maxWidth: "1100px", // Maximum width for larger screens
    transition: ".3s",
    boxShadow: hover ? "0px 5px 25px rgba(0,0,0,0.1)" : "none",
    borderLeft: hover ? "9px solid #4d64e4" : "1px solid #e8e8e8"  }} className="jobcard" onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <Grid container alignItems="center">
          <Grid item xs >
            <Typography variant="subtitle1">FrontEnd Dev</Typography>
            <Typography variant="subtitle1" style={{ fontSize: "16.5px", backgroundColor: "#89CFF0", wordSpacing: "0.75px", padding: "7px", borderRadius: "5px", fontWeight: "600", display: "inline-block" }}>Google</Typography>
          </Grid>
          <Grid item container xs>
            {skills.map((skill) => (
              <Grid key={skill} item style={{ margin: "5px", padding: "7px", fontSize: "14.5px", borderRadius: "5px", transition: ".3s", cursor: "pointer", fontWeight: "600", backgroundColor: "Grey", color: "#fff" }}>
                {skill}
              </Grid>
            ))}
          </Grid>
          <Grid item container direction="column" alignItems="flex-end" xs>
            <Grid item>
              <Typography variant="caption">
                Full time | Remote
              </Typography>
            </Grid>
            <Grid item>
              <Box mt={2}>
                <Button variant="outlined" style={{ borderRadius: "20px" }}>Check</Button></Box>
            </Grid></Grid>
        </Grid>






      </Box>

      {/*<Dialog open={true} fullWidth>
        <DialogTitle>Post Job</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FilledInput placeholder="job title *" disableUnderline fullWidth />
            </Grid>
            <Grid item xs={6}>
            <Select fullWidth disableUnderline variant='filled' defaultValue="Full Time">
          <MenuItem value="Full Time">Full time</MenuItem>
          <MenuItem value="Part Time">Part Time</MenuItem>
          <MenuItem value="Contract">Contract</MenuItem>
        </Select>

            </Grid>
            <Grid item xs={6}>
              <FilledInput placeholder="job title *" disableUnderline fullWidth />
            </Grid>
          </Grid>
        </DialogContent>
          </Dialog>*/}

      <Modal open={showModal} onClose={() => setShowModal(false)} fullWidth>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: '90%', // Adjust width as per your requirement
            maxWidth: '500px', // Maximum width for larger screens
          }}

        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Job Title"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Job Category"
                value={jobCategory}
                onChange={(e) => setJobCategory(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal" required>
                <InputLabel>Job Type</InputLabel>
                <Select
                  value={jobType}
                  onChange={(e) => setJobType(e.target.value)}
                  label="Job Type"
                >
                  <MenuItem value="full-time">Full Time</MenuItem>
                  <MenuItem value="part-time">Part Time</MenuItem>
                  <MenuItem value="contract">Contract</MenuItem>
                  <MenuItem value="internship">Internship</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Industry Name"
                value={industryName}
                onChange={(e) => setIndustryName(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Job Description"
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePostJob}
            style={{ marginTop: "20px" }}
          >
            Post Job
          </Button>
        </Box>
      </Modal>


      <Footer/>

      <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up" /></a>

    </div>

  )
}
export default Job;