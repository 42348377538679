import React, { useEffect, useState } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { MDBDataTable } from 'mdbreact';
import { RoomChangeListApi } from '../../ApiFile';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const RoomChange = () => {

  const navigate = useNavigate();

  const [data, setData] = useState({
    columns: [
      {
        label: 'Serial No',
        field: 'serial_no',
        sort: 'asc'
      },
      {
        label: 'Member ID',
        field: 'member_id',
        sort: 'asc'
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc'
      },
      {
        label: 'Block ID',
        field: 'block_id',
        sort: 'asc'
      },
      {
        label: 'Room ID',
        field: 'room_id',
        sort: 'asc'
      },
      {
        label: 'Bed ID',
        field: 'bed_id',
        sort: 'asc'
      },
      {
        label: 'Receive Amount',
        field: 'receive_amount',
        sort: 'asc'
      },
      {
        label: 'Action',
        field: 'action'
      }
    ],
    rows: []
  });

  useEffect(() => {
    fetch(RoomChangeListApi)
      .then(response => response.json())
      .then(data => {
        const rows = data.map((item, index) => ({
          serial_no: index + 1,
          member_id: item.member_id,
          name: item.name,
          block_id: item.block_id,
          room_id: item.room_id,
          bed_id: item.bed_id,
          receive_amount: item.receive_amount,
          action: <Button style={{backgroundColor:"Highlight",color:"whitesmoke"}} onClick={() => handleChange(item.member_id)}>Change</Button>
        }));
        setData(prevData => ({ ...prevData, rows }));
      });
  }, []);

  const handleChange = (member_id) => {
    navigate('/AdminBlock',{ state : { MemberId : member_id}});
    console.log(`Change button clicked for member ID: ${member_id}`);
    // Add your change logic here
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Room Change
          </h2>
        </div>
        <Row className="m-0 p-0 px-md-3 py-1 pb-5 mt-4 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
          <MDBDataTable
            striped
             className="custom-datatable text-center"
            bordered
            small
            data={data}
          />
        </div>
        </div>
        </Col>
        </Row>
      </section>
    </>
  );
}

export default RoomChange;
