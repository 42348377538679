import React from 'react'
import SideNavbarStudentReg from './SideNavbarStudentReg'
import NavBarStudentReg from './NavBarStudentReg'

const TermCondition = () => {
  return (
    <>
     <SideNavbarStudentReg/>
      <section className="home-section">
        <NavBarStudentReg/> 
    <div className="my-3 px-md-4 px-3 mx-md-4 mx-2" style={{ textAlign: 'left',marginLeft:'-20px', borderRadius: '20px', padding: '30px', backgroundColor: '#dafddc' }}>
    <h3 style={{ marginTop: '-10px', color: '#45a049' }} className='mb-3'>Terms & Conditions</h3>
    <p>These terms and conditions (“Terms and Conditions”) control your use of this website: www.jainboardingsangli.com (Website).</p>
    <p>You refers to a user or a paying customer. If You are a company or another person who gives access to Company products, you agree to take responsibility in full in case of damages or indemnification that could properly lie against the customer.</p>
    <p>By using or accessing the information on this website, including, but not limited to downloading or accessing courses through this Website or through designated Software of the Company; You agree to abide by the terms and conditions set forth in these “Terms and Conditions”:</p>
    {/* General Terms of Use */}
    <h3 style={{ color: 'black',marginLeft:'-10px',fontSize:'17px'}}>GENERAL TERMS OF USE</h3>
    <ul className="bullet-list">
      <li>Registering at this Website does not entitle You to the right of admission/selection for any course. The Company Selection Committee reserves the right to select or reject a candidate without any notice or assigning any reason whatsoever.</li>
      <li>Selected candidates are required to comply with the rules laid down under the various Policies and jainboardingsangli, reserves the right to expel a student or terminate the candidature at any time if found otherwise.</li>
      <li>As a user, You agree to maintain (i) all content in the account including but not limited to academic content, assessments, email/SMS content, profile information and current billing and contact information, (ii) Email/mobile phone number list and Address Book maintenance including opt-out contact information and immediate removal of opt-outs and bounces from email/mobile phone number lists and the address book, (iii) Payment of all agreed course fees (iv) full responsibility for all activity associated with your use of this Website and services and (v) compliance with all other obligations set forth in these Terms and Conditions.</li>
      <li>You will provide current, complete, accurate information in the registration section of this Website. You will keep Your account information current and accurate and promptly update your registration information to keep it current and accurate.</li>
      <li>You acknowledge and agree that by using the Website You will not disrupt the functioning of this Website, solicit another user's password, or otherwise act in a way that interferes with the normal operation of this Website.</li>
      <li>To the extent that you violate any part of these Terms and Conditions, Company reserves the right to restrict or prevent You from accessing the Website and the services.</li>
    </ul>
    {/* Consent to Receive Electronic Communication */}
    <h3 style={{ color: 'black',marginLeft:'-10px',fontSize:'20px' }}>CONSENT TO RECEIVE ELECTRONIC COMMUNICATION</h3>
    <p>By registering on this Website and/or the Company’s mobile application, you consent to receive electronic communications including but not limited to newsletters, account reminders and updates, customer support, transactional notices, other promotional content, and other information relating to the Website and its services.</p>
    <h3 style={{ color: 'black',marginLeft:'-10px',fontSize:'20px' }}>RIGHT TO USE OF LIKENESS</h3>
    <p>By registering on the Website and providing your personal information such as photos, and videos, you consent to the use of your likeness by the Company for promotional purposes.</p>
    {/* Additional clauses and sections can be added here */}
    <div>
      <p>These Terms and Conditions may change from time to time. Please review these periodically for updates. By continuing to use this Website, you agree to abide by these Terms and Conditions.</p>
    </div>
  </div>

  <footer className="fixed-footer" >
          <div className="container footerr">
            <div className="row">
              <div className="col-12 text-center">
                {/* Footer content goes here */}
                <p>
                  &copy;2023 Sheth R. D. Davada Digambar Jain Boarding, Sangli.
                  All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </footer>
  </section>
</>)
}

export default TermCondition