import react, { Fragment } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';


function Contact(){

    return(
        <div>
        <Navbar></Navbar>
          {/* Header Start */}
          <div className="container-fluid bg1-breadcrumb">
            <div className="container text-center py-5" style={{maxWidth: '900px'}}>
              <h3 className="text-white display-3 mb-4" style={{textShadow: '3px 3px darkblue'}}>Contact Us</h3>
            </div>
          </div>
          {/* Header End */}
          {/* Contact Start */}
          <div className="container-fluid contact bg-light py-5">
            <div className="container py-5">
              <div className="mx-auto text-center mb-5" style={{maxWidth: '900px'}}>
                <h5 className="section-title px-2">Contact Us</h5>
              </div>
              <div className="row">
                {/* Option 1: Drop On In */}
                <div className="col-md-4 mb-3">
                  <div className="tile">
                    <div className="tile-icon">
                      <i className="fa fa-map-marker fa-3x" aria-hidden="true" />
                      <h4>Drop On In</h4>
                    </div>
                    <div className="tile-content">
                      <p>37, Mahaveer Nagar,Vakhar Bhag,<br /> Sangli,Sangli Miraj Kupwad,<br /> Maharashtra
                        416416</p>
                    </div>
                  </div>
                </div>
                {/* Option 2: Give us a call */}
                <div className="col-md-4 mb-3">
                  <div className="tile">
                    <div className="tile-icon">
                      <i className="fa fa-phone fa-3x" aria-hidden="true" />
                      <h4>Give us a call</h4>
                    </div>
                    <div className="tile-content">
                      <p>Office: (0233) 2623206</p>
                      <br /><br />
                    </div>
                  </div>
                </div>
                {/* Option 3: Connect online */}
                <div className="col-md-4 mb-4">
                  <div className="tile">
                    <div className="tile-icon">
                      <i className="fa fa-envelope fa-3x" aria-hidden="true" />
                      <h4>Connect online</h4>
                    </div>
                    <div className="tile-content">
                      <p>Email: jainboardingsangli@.com</p>
                      <br /><br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="rounded map-container">
                  <iframe className="map-responsive" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} id="gmap_canvas" src="https://maps.google.com/maps?width=520&height=400&hl=en&q=37,%20Mahaveer%20Nagar,%20Vakhar%20Bhag%20sangli+(digambar%20jain%20boarding)&t=&z=12&ie=UTF8&iwloc=B&output=embed">
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        
        {/* Contact End */}
        {/* Footer Start */}
        <Footer />
      </div>

    )
}
export default Contact;