import React, { useEffect, useState } from 'react';
import Sidebar from './sidebarstudent';
import Header from './Headerstudent';
import { Col, Row, Form, Modal } from 'react-bootstrap';
import { Button, MenuItem, TextareaAutosize, TextField, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { MDBDataTable } from 'mdbreact';
import { EventLeaveAdd, EventLeaveDelete, EventLeaveFindAll, EventLeaveList, EventLeaveUpdate } from '../../ApiFile';
import { toast } from 'react-toastify';

const EventLeaveApplication = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [reason, setReason] = useState('');
  const [leaveData, setLeaveData] = useState([]);
  const [editingLeave, setEditingLeave] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [leaveIdToDelete, setLeaveIdToDelete] = useState(null);
  const [loading, setLoading] = useState(false); // Added loading state

  useEffect(() => {
    const storedMemberId = sessionStorage.getItem('member_id');
    if (storedMemberId) {
      setMemberId(storedMemberId);
    } else {
      console.error('No member ID found in session storage');
    }
  }, []);

  useEffect(() => {
    if (memberId) {
      fetch(EventLeaveList)
        .then((response) => response.json())
        .then((data) => setEvents(data))
        .catch((error) => console.error('Error fetching events:', error));
    }
  }, [memberId]);

  useEffect(() => {
    if (memberId) {
      fetch(`${EventLeaveFindAll}/${memberId}`)
        .then((response) => response.json())
        .then((data) => setLeaveData(data))
        .catch((error) => console.error('Error fetching leave data:', error));
    }
  }, [memberId]);

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedEvent || !reason) {
      toast.warn('Please select an event and provide a reason.');
      return;
    }

    const data = {
      event_id: selectedEvent,
      member_id: memberId,
      reason,
    };

    try {
      const response = await fetch(EventLeaveAdd, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success('Event leave application submitted successfully!');
        setSelectedEvent('');
        setReason('');
        fetch(`${EventLeaveFindAll}/${memberId}`)
          .then((response) => response.json())
          .then((data) => setLeaveData(data))
          .catch((error) => console.error('Error fetching leave data:', error));
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Error submitting leave application');
      }
    } catch (error) {
      console.error('Network error:', error);
      toast.error('Network error');
    }
  };

  const handleUpdate = async (leaveId) => {
    const data = {
      event_id: selectedEvent,
      reason,
    };

    try {
      const response = await fetch(`${EventLeaveUpdate}/${leaveId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success('Leave updated successfully!');
        setEditingLeave(null);
        setSelectedEvent('');
        setReason('');
        fetch(`${EventLeaveFindAll}/${memberId}`)
          .then((response) => response.json())
          .then((data) => setLeaveData(data))
          .catch((error) => console.error('Error fetching leave data:', error));
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Error updating leave');
      }
    } catch (error) {
      console.error('Network error:', error);
      toast.error('Network error');
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${EventLeaveDelete}/${leaveIdToDelete}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        toast.success('Leave deleted successfully!');
        fetch(`${EventLeaveFindAll}/${memberId}`)
          .then((response) => response.json())
          .then((data) => setLeaveData(data))
          .catch((error) => console.error('Error fetching leave data:', error));
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Error deleting leave');
      }
    } catch (error) {
      console.error('Network error:', error);
      toast.error('Network error');
    } finally {
      setShowModal(false);
      setLoading(false); // Reset loading state
    }
  };

  const handleCancelConfirm = async () => {
    setLoading(true); // Set loading state
    await handleDelete();
  };

  const tableData = {
    columns: [
      { label: 'Sr.No', field: 'SrNo', sort: 'asc', width: 150 },
      { label: 'Event', field: 'discription', sort: 'asc', width: 270 },
      { label: 'Event Date', field: 'event_date', sort: 'asc', width: 270 },
      { label: 'Reason', field: 'reason_for_leave', sort: 'asc', width: 270 },
      { label: 'Status', field: 'status', sort: 'asc', width: 200 },
      {
        label: 'Update',
        field: 'update',
        width: 100,
      },
      {
        label: 'Cancel',
        field: 'cancel',
        width: 100,
      }
    ],
    rows: leaveData.map((leave,index) => ({
      SrNo: index+1,
      discription: leave.discription,
      event_date: leave.event_date,
      reason_for_leave: leave.reason_for_leave,
      status:
      leave.leave_status === "0"
        ? (  <Button style={{ backgroundColor: 'orange',color:"white" }}>
        Pending
      </Button>)
        : leave.leave_status === "1"
        ? (  <Button style={{ backgroundColor: 'green',color:"white" }}>
          Approved
        </Button>)
        : leave.leave_status === "2"
        ? (  <Button style={{ backgroundColor: 'red' ,color:"white"}}>
         Reject
        </Button>)
        : '',
      update:
      leave.leave_status === "0" ? (
        <Button   onClick={() => {
          setEditingLeave(leave.id);
          setSelectedEvent(leave.event_id);
          setReason(leave.reason_for_leave);
        }} variant="contained" color="info">
          Update
        </Button>
      ) : null,
    cancel:
      leave.leave_status === "0" ? (
        <Button
          onClick={() => {
            setLeaveIdToDelete(leave.id);
            setShowModal(true);
          }}
          variant="contained"
          color="error"
        >
          Cancel
        </Button>
      ) : null,
    })),
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <Header />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Event Leave Application
          </h2>
        </div>

        <Row className="m-0 p-0 py-1 px-md-5 px-2 mt-4 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
            <Row>
              <Col sm={12} md={5} className="mt-2 mt-md-0">
                <Form.Group controlId="eventSelect">
                  <Form.Label style={{ color: 'maroon' }}>Select Event:</Form.Label>
                  <TextField
                    id="eventSelect"
                    select
                    size="small"
                    label="Select Event"
                    fullWidth
                    value={selectedEvent}
                    onChange={handleEventChange}
                    variant="outlined"
                    margin="normal"
                  >
                    {events.map((event) => (
                      <MenuItem key={event.event_id} value={event.event_id}>
                        {event.event_date} - {event.discription} (Start Time: {event.starttime})
                      </MenuItem>
                    ))}
                  </TextField>
                </Form.Group>
              </Col>
              <Col sm={12} md={5} className="mt-3 mt-md-0">
                <label style={{ color: 'maroon' }}>Reason:</label>
                <TextareaAutosize
                  value={reason}
                  onChange={handleReasonChange}
                  maxLength={200}
                  className='mt-4'
                  placeholder="ENTER REASON"
                  style={{
                    width: '100%',
                    padding: '0.5rem',
                    borderRadius: '0.25rem',
                    border: '1px solid #ccc'
                  }}
                />
              </Col>
              <Col sm={12} md={2} className="mt-3 mt-md-0">
                <label style={{ color: 'white' }}>.</label>
                <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                  className='mt-4'
                    style={{ backgroundColor: 'rgb(0, 120, 215)', color: 'white' }}
                    onClick={editingLeave ? () => handleUpdate(editingLeave) : handleSubmit}
                  >
                    {editingLeave ? 'UPDATE' : 'APPLY'}
                  </Button>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="m-0 p-0 py-1 pb-5 px-md-5 px-2 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
            <MDBDataTable
              striped
              bordered
              className="custom-datatable"
              hover
              data={tableData}
            />
          </Col>
        </Row>

        {/* Confirmation Dialog for Cancel */}
        <Dialog open={showModal} onClose={() => setShowModal(false)} maxWidth="sm" fullWidth>
          <DialogTitle style={{ color: 'maroon' }}>Confirm Cancellation</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to cancel this leave?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} color="primary">
              No
            </Button>
            <Button onClick={handleCancelConfirm} color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Yes, Cancel'}
            </Button>
          </DialogActions>
        </Dialog>
      </section>
    </>
  );
};

export default EventLeaveApplication;
