import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Dropdown, Nav, Navbar, Row } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { MDBDataTable } from 'mdbreact';
import AddIcon from '@mui/icons-material/Add';
import { FeeMasterAdd, FeeMasterFindAll, FeeTypeFindAllApi1, FeemasterUpdate } from '../../ApiFile';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { MenuItem, TextField } from '@mui/material';

const FeeMaster1 = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [fee, setFee] = useState('');
  const [feeId, setFeeId] = useState([]);
  const [isEditing, setisEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [term, setTerm] = useState('');
  const [payAmount, setPayAmount] = useState('');
  const [open, setOpen] = useState(false);
  const [installment_flag, setinstallment_flag] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setisEditing(false);
    setTerm('');
    setPayAmount('');
    setinstallment_flag(false);
    setFee('');
  };

  const sessionYears = sessionStorage.getItem('selectedYear');

  useEffect(() => {
    fetchFeeId();
    Getdata();
  }, []);

  const fetchFeeId = async () => {
    try {
      const response = await fetch(FeeTypeFindAllApi1);
      if (!response.ok) {
        throw new Error('Failed to fetch fee data');
      }
      const data = await response.json();
      if (data === "NOTFOUND") {
        console.log("if ");
        setFeeId([]); // Set rules to an empty array if "NOTFOUND"
    } else {
      console.log("else ");
      setFeeId(data);
    }
     
    } catch (error) {
      // console.error('Error fetching fee data:', error);
    }
  };

  const savedata = (e) => {
    e.preventDefault();

    if (
      (selectedFee && selectedFee.term_applicable === 1 && !term) ||
      !payAmount ||
      !fee
    ) {
      toast.error("All fields are required", { position: "top-right", autoClose: 3000 });
      return;
    }
  

    const obj = {
      term: term,
      pay_amount: payAmount,
      installment_flag: installment_flag ? 1 : 0,
      fee_id: fee,
      boarding_year:sessionYears,
    };
    // console.log("obj fee master..", obj);

    if (isEditing) {
      axios
        .put(`${FeemasterUpdate}/${currentId}`, obj)
        .then((res) => {
          if (res.data === "UPDATE") {
            toast.success("Data Updated Successfully!", { position: "top-right", autoClose: 3000 });
            Getdata();
            handleClose();
          }
        })
        .catch((err) => {
          toast.error("Failed to update, please try again", { position: "top-right", autoClose: 3000 });
          // console.error(err);
        });
    } else {
      axios
        .post(FeeMasterAdd, obj)
        .then((res) => {
          // console.log("response.data.....", res)
          if (res.data === "ALREADYEXIST") {
            toast.warn("Data already exist..!", { position: "top-right", autoClose: 3000 });
          }
          else if (res.data === "SAVED") {
            toast.success("Data Saved Successfully!", { position: "top-right", autoClose: 3000 });
            Getdata();
            handleClose();
          }
        })
        .catch((err) => {
          toast.error("Failed to save, please try again", { position: "top-right", autoClose: 3000 });
          // console.error(err);
        });
    }
  };

  const Getdata = () => {
    axios
      .get(`${FeeMasterFindAll}/${sessionYears}`)
      .then((res) => {
        if (res.data !== null) {
          setData(res.data);
          console.log("res..", res.data);
        }
      })
      .catch((err) => {
        toast.error("Failed to upload, try again", { position: "top-right", autoClose: 3000 });
        // console.log(err);
      });
  };

  const handleEditShow = (item) => {
    setTerm(item.term);
    setPayAmount(item.pay_amount);
    setPayAmount(item.installment_Fee);
    setFee(item.fee_id);
    setinstallment_flag(item.installment_flag === 1);
    setCurrentId(item.id);
    setisEditing(true);
    handleOpen();
  };

  const columns = [
    { label: 'Sr.No', field: 'srNo', sort: 'asc' },
    { label: 'Fee Name', field: 'fee_name', sort: 'asc' },
    { label: 'Term', field: 'term', sort: 'asc' },
    { label: 'Pay Amount', field: 'pay_amount', sort: 'asc' },
    { label: 'Installment Fee', field: 'installment_Fee', sort: 'asc' },
    { label: 'Edit', field: 'edit', sort: 'asc' },
  ];

  const rows = data.map((item, index) => ({
    srNo: index + 1,
    fee_name: item.fee_name,
    term: item.term === 1 ? 'Full Term' : item.term === 2 ? 'Half Term' : '-',
    pay_amount: item.pay_amount,
    installment_Fee: item.installment_Fee,
    edit: (
      <button
        style={{ color: "rgb(255, 93, 0)", border: "0px", backgroundColor: "transparent" }}
        onClick={() => handleEditShow(item)}
      >
        <i className="fa-solid fa-pen-to-square"></i>
      </button>
    ),
  }));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const selectedFee = feeId.find(feeItem => feeItem.fee_id === fee);

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />

        <div>
          <div className="block-header">
            <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
              Fee Amount Entry
            </h2>
            <Button
              className="ms-md-5 m-0  ms-3  mb-2 mb-md-4 mt-2 mt-md-4"
              variant="contained"
              color="primary"
              onClick={handleOpen}
              startIcon={<AddIcon />}
            >
              Add Fee
            </Button>
          </div>

          <Row className="m-0 p-0  px-md-3  py-1 pb-5  w-100" style={{ display: 'flex' }}>
            <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
              <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
                <div style={{ overflowY: 'auto' }}>
                  <div style={{ overflowX: 'auto' }}>
                    <MDBDataTable striped className="custom-datatable" bordered hover data={{ columns, rows }} />
                  </div>
                </div>
              </Col>
            </Row>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Typography variant="h6" className="mt-2" component="h2">
                  Fee Amount Entry
                </Typography>
                <form>
                  <TextField
                    className="mt-3"
                    size="small"
                    select
                    label="Select Fee Name"
                    value={fee}
                    onChange={(e) => setFee(e.target.value)}
                    fullWidth
                    required
                  >
                    {feeId.map((fee) => (
                      <MenuItem key={fee.fee_id} value={fee.fee_id}>
                        {fee.fee_name}
                      </MenuItem>
                    ))}
                  </TextField>

                  {selectedFee && selectedFee.term_applicable === 1 && (
                    <>
                      <TextField
                        className="mt-3"
                        select
                        size="small"
                        label="Term"
                        value={term}
                        onChange={(e) => setTerm(e.target.value)}
                        fullWidth
                        required
                      >
                        <MenuItem value="1">Full Term</MenuItem>
                        <MenuItem value="2">Half Term</MenuItem>
                      </TextField>
                    </>
                  )}

                  <TextField
                    className="mt-3"
                    size="small"
                    label="Pay Amount"
                    value={payAmount}
                    onChange={(e) => setPayAmount(e.target.value)}
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                    required
                  />

<div style={{display:"flex",height:"40px", borderRadius:"5px"}} className=" mt-3 border p-2 ">
                    <input
                      type="checkbox"
                      style={{width: "20px",height: "20px"}}
                      id="installment_flag"
                      checked={installment_flag}
                      onChange={(e) => setinstallment_flag(e.target.checked)}
                    />
                   <p className="ms-2" >Installment Applicable</p>
                  </div>
                </form>
                <Row>
                  <Col md={6} xs={6} className='text-end'>
                    <Button
                      className="mt-4"
                      style={{ color: 'black', backgroundColor: 'white', border: '2px solid rgb(135, 178, 178)', borderRadius: '8' }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col md={6} xs={6}>
                    {isEditing ? (
                      <Button
                        style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                        className="py-2 px-3 mt-4 shadow text-dark"
                        type="submit"
                        onClick={savedata}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                        className="py-2 px-3 mt-4 shadow text-dark"
                        type="submit"
                        onClick={savedata}
                      >
                        Save
                      </Button>
                    )}
                  </Col>
                </Row>
              </Box>
            </Modal>
          </Row>
        </div>
      </section>
    </>
  );
};

export default FeeMaster1;
