import react, { Fragment, useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import './boarding.css';
import Footer from '../Components/Footer';
import { GalleryApi, ImagePath } from '../../ApiFile';
import axios from 'axios';


function Gallery(){

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    axios.get(GalleryApi)
      .then(response => {
        setPhotos(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the photos!', error);
      });
  }, []);
  
    return(
      
        <div>
        <Navbar></Navbar>
          {/* Header Start */}
          <div className="container-fluid bgg-breadcrumb">
            <div className="container text-center py-5" style={{maxWidth: '900px'}}>
              <h3 className="text-white display-3 mb-4" style={{textShadow: '3px 3px darkblue'}}>Gallery</h3>
            </div>
          </div>
          {/* Header End */}
          {/* Gallery Start */}
          <div className="container-fluid gallery py-5 my-5">
            <div className="mx-auto text-center mb-5" style={{maxWidth: '900px'}}>
              <h5 className="section-title pxd-3" style={{marginLeft:'30px'}}>Our Gallery</h5>
            </div>
            <div className="tab-class text-center">
              <div className="tab-content">
                <div id="GalleryTab-1" className="tab-pane fade show p-0 active">
                  {/* <div className="row g-2">
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                      <div className="gallery-item h-100">
                        <img src="assets/img/g1.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                        <div className="gallery-content">
                        </div>
                        <div className="gallery-plus-icon">
                          <a href="assets/img/g1.jpg" data-lightbox="gallery-1" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                      <div className="gallery-item h-100">
                        <img src="assets/img/post1.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                        <div className="gallery-plus-icon">
                          <a href="assets/img/post1.jpg" data-lightbox="gallery-2" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                      <div className="gallery-item h-100">
                        <img src="assets/img/post2.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                        <div className="gallery-content">
                        </div>
                        <div className="gallery-plus-icon">
                          <a href="assets/img/post2.jpg" data-lightbox="gallery-3" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                      <div className="gallery-item h-100">
                        <img src="assets/img/post3.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                        <div className="gallery-content">
                        </div>
                        <div className="gallery-plus-icon">
                          <a href="assets/img/post3.jpg" data-lightbox="gallery-4" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                      <div className="gallery-item h-100">
                        <img src="assets/img/post4.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                        <div className="gallery-content">
                        </div>
                        <div className="gallery-plus-icon">
                          <a href="assets/img/post4.jpg" data-lightbox="gallery-5" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                      <div className="gallery-item h-100">
                        <img src="assets/img/post5.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                        <div className="gallery-content">
                        </div>
                        <div className="gallery-plus-icon">
                          <a href="assets/img/post5.jpg" data-lightbox="gallery-6" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <div className="gallery-item h-100">
                        <img src="assets/img/post6.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                        <div className="gallery-content">
                        </div>
                        <div className="gallery-plus-icon">
                          <a href="assets/img/post6.jpg" data-lightbox="gallery-7" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <div className="gallery-item h-100">
                        <img src="assets/img/post7.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                        <div className="gallery-content">
                        </div>
                        <div className="gallery-plus-icon">
                          <a href="assets/img/post7.jpg" data-lightbox="gallery-8" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="row g-2">
      {photos.map((photo, index) => (
        <div key={photo.id} className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
          <div className="gallery-item h-100">
            <img 
              src={`${ImagePath}/${photo.upload_img}`}
              className="img-fluid w-100 h-100 rounded" 
              alt={`Gallery Image ${photo.id}`} 
            />
            <div className="gallery-plus-icon">
              <a href={`${ImagePath}/${photo.upload_img}`} data-lightbox={`gallery-${index + 1}`} className="my-auto">
                <i className="fas fa-plus fa-2x text-white" />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
                </div>
              </div>
            </div>
          </div>
        
        {/* Gallery End */}
        {/* Footer Start */}
        <Footer/>
        {/* Copyright End */}
        {/* Back to Top */}
        {/* <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up" /></a> */}
        {/* JavaScript Libraries */}
        {/* Template Javascript */}
      </div>

    )
}
export default Gallery;