import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import { DonationFindAll, ImagePath } from '../../ApiFile';
import { Button, Modal, Box, Typography } from '@mui/material'; // Added missing imports
import { Visibility } from '@mui/icons-material'; // Added missing icon

const DonationList = () => {
  const [donations, setDonations] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedDonation, setSelectedDonation] = useState(null);

  useEffect(() => {
    // Fetch donation data from API
    const fetchDonations = async () => {
      try {
        const response = await axios.get(DonationFindAll);
        const formattedDonations = response.data.map((donation, index) => ({
          name: donation.name || '-',
          address: donation.address || '-',
          mob_no: donation.mob_no || '-',
          amount: donation.amount,
          tax_id: donation.tax_id,
          donation_for: donation.donation_for || '-',
          view: (
            <Button variant="contained" color="primary" onClick={() => handleViewClick(donation)}>
              <Visibility fontSize="small" />
            </Button>
          ),
        }));
        setDonations(formattedDonations);
      } catch (error) {
        console.error('Error fetching donations:', error);
      }
    };

    fetchDonations();
  }, []);

  const handleViewClick = (donation) => {
    setSelectedDonation(donation); // Set the selected donation for viewing
    setOpen(true); // Open the modal
  };

  const data = {
    columns: [
      { label: 'Name', field: 'name', sort: 'asc', width: 150 },
      { label: 'Address', field: 'address', sort: 'asc', width: 200 },
      { label: 'Mobile No', field: 'mob_no', sort: 'asc', width: 150 },
      { label: 'Amount', field: 'amount', sort: 'asc', width: 100 },
      { label: 'Tax ID', field: 'tax_id', sort: 'asc', width: 100 },
      { label: 'Donation For', field: 'donation_for', sort: 'asc', width: 200 },
      { label: 'Image', field: 'view', sort: 'asc', width: 100 }, // Added 'View' column for the button
    ],
    rows: donations,
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <Row>
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Donations
          </h2>
        </Row>
        <Row className="m-0 pt-1 px-2 px-md-3 mb-5 mt-3 rounded">
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable
                  style={{ backgroundColor: 'white' }}
                  striped
                  className="custom-datatable"
                  bordered
                  small
                  data={data}
                />
              </div>
            </div>
          </Col>
        </Row>

        {selectedDonation && (
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="status-modal-title"
            aria-describedby="status-modal-description"
            sx={{
              display: 'flex',
              overflow: 'scroll',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '700px',
                maxHeight: '90vh',
                overflow: 'scroll',
                backgroundColor: '#fff',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ p: 3, borderTop: '1px solid #ccc' }}>
                <Row>
                  <div className="block-header">
                    <h2 className="mb-2 mt-1" style={{ color: 'maroon', borderBottom: "2px solid maroon" }}>
                      Donation Details
                    </h2>
                  </div>
                  <Box sx={{ overflowY: 'auto', p: 4, flex: 1 }}>
                    <Typography id="modal-modal-description" style={{ lineHeight: "18px" }}>
                      <Row style={{ display: "flex" }}>
                        <Col><b>Uploaded Image</b>: </Col>
                        <Col className="mt-3 text-center" md={6} xs={12}>
                          <img
                            src={`${ImagePath}/${selectedDonation.payImg}`} // Correctly displaying the selected donation's image
                            className='acceptable rounded shadow border-0'
                            alt='Payment'
                            style={{ height: "16rem", width: "15rem", border: "1px solid grey" }}
                          />
                        </Col>
                      </Row>
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 4, position: 'absolute', bottom: '16px', right: '16px' }}
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </Button>
                  </Box>
                </Row>
              </Box>
            </Box>
          </Modal>
        )}
      </section>
    </>
  );
};

export default DonationList;
