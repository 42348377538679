// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
// import { Col, Row } from 'react-bootstrap';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { AdminRoomChangePaymentApi, RoomBookingAddApi, RoomBookingApi } from '../../ApiFile';
// import Sidebar from '../sidebar';
// import NavLogo from '../NavLogo';

// const AdminPaymentChange = () => {
//   const navigate = useNavigate();
//   const [showModal, setShowModal] = useState(false);
//   const [inputValue, setInputValue] = useState('');
//   const [paymentMode, setPaymentMode] = useState('');
//   const [file, setFile] = useState(null);
//   const [amount, setAmount] = useState(null); // State to store the amount
//   const [waitingForApproval, setWaitingForApproval] = useState(false); // State for admin approval status
//   const [errors, setErrors] = useState({}); // Define errors state variable


//   const handleShowModal = () => {
//     setShowModal(true);
//   };

//   const location = useLocation();
//   const bedFinal = location.state && location.state.bedFinal;
//   console.log("lojhj",bedFinal);

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setInputValue();
//   }

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const [blockId, setBlockId] = useState(null);
//   const [roomId, setRoomId] = useState(null);
//   const [bedId, setBedId] = useState(null);

//   useEffect(() => {
//     const fetchPaymentData = async () => {
//       try {

//        const requestBody = { member_id: bedFinal.member_id, block_id: bedFinal.block_id, room_id: bedFinal.room_id, bed_id: bedFinal.bed_id };
        

//         const response = await axios.post(RoomBookingApi, requestBody);

//         console.log("Response data:", response.data);

//         if (response.data === 'Admin Not Room Approval') {
//           setWaitingForApproval(true);
//           setAmount(null);
//           console.log("Waiting for admin room approval");
//         } else if (Array.isArray(response.data) && response.data.length > 0) {
//           const paymentData = response.data[0];
//           setAmount(paymentData.amount);
//           setBlockId(paymentData.block_id);
//           setRoomId(paymentData.room_id);
//           setBedId(paymentData.bed_id);
//           setWaitingForApproval(false);
//           console.log("amount...", paymentData.amount);
//           console.log("block_id...", paymentData.block_id);
//           console.log("room_id...", paymentData.room_id);
//           console.log("bed_id...", paymentData.bed_id);
//         } else {
//           setAmount(null); // Reset amount if no data received
//           setBlockId(null);
//           setRoomId(null);
//           setBedId(null);
//           setWaitingForApproval(false);
//           console.log("No data received");
//         }
//       } catch (error) {
//         console.error("Error fetching payment data:", error);
//         setAmount(null);
//         setBlockId(null);
//         setRoomId(null);
//         setBedId(null);
//         setWaitingForApproval(false);
//       }
//     };

//     if (bedFinal.member_id) {
//       fetchPaymentData();
//     }
//   }, [bedFinal.member_id]);

//   const handleSubmit = async () => {

//     const formData = new FormData();
//     formData.append('member_id', bedFinal.member_id);
//     formData.append('file', file); 
//     formData.append('taxid', inputValue); 
//     formData.append('pay_mode', paymentMode); 
//     formData.append('pay_amount', bedFinal.amount); 

//     // Log form data entries for debugging
//     for (const entry of formData.entries()) {
//       console.log(`${entry[0]}: ${entry[1]}`);
//     }

//     try {
//       const res = await axios.post(AdminRoomChangePaymentApi, formData);

//       console.log("res console....", res.data);
//       if (res.data === 'SAVED') {
//         toast.success('Payment Saved Successfully !!!!!', {
//           position: 'top-right',
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//         });
//         setInputValue('');
//         setPaymentMode('');
//         setFile(null);
//         navigate('/HomeAdmin')
//       } else if (res.data === "Alredy Payment done") {
//         toast.warn('Already payment done !!!!!', {
//           position: 'top-right',
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//         });
//       } else {
//         // Handle errors or other responses
//         toast.error('Please try again', {
//           position: 'top-right',
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//         });
//       }
//     } catch (error) {
//       console.error('Error submitting payment:', error);
//       toast.error('Error submitting payment, please try again', {
//         position: 'top-right',
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//     }
//   };

//   const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

//   const OpenSidebar = () => {
//     setOpenSidebarToggle(!openSidebarToggle);
//   };
 
//   return (
//     <>
//        <Sidebar />
//       <section className="home-section">
//         <NavLogo />
//         <div className="bed-booking px-md-5 pb-5 px-2 mt-md-5 mt-3">
//           <h3 className="main-title1"><p style={{borderBottom:"1px solid maroon" ,color:"maroon"}}>Room Payment</p></h3>
//           {waitingForApproval ? (
//             <p style={{ fontSize: '25px', color: 'maroon', textShadow: '1px 1px black' }}>
//               Wait for admin room approval
//             </p>
//           ) : (
//             <>
//               {/* {amount !== null && (
//                 <p style={{ fontSize: '25px', color: 'maroon', textShadow: '1px 1px black' }}>
//                   Fee: {amount}
//                 </p>
//               )} */}
//               <Row style={{display:"flex"}}>
//               <Col md={2}></Col>
//               <Col md={8}>
//               <Row style={{display:"flex"}}>
//               <Col sm={12} md={3}><p><b style={{color:"gray",borderBottom:"1px solid maroon"}}>Amount</b>: {amount}</p></Col>
//               <Col sm={12} md={3}><p><b style={{color:"gray",borderBottom:"1px solid maroon"}}>Block No</b>: {blockId}</p></Col>
//               <Col sm={12} md={3}><p><b style={{color:"gray",borderBottom:"1px solid maroon"}}>Room No</b>: {roomId}</p></Col>
//               <Col sm={12} md={3}><p><b style={{color:"gray",borderBottom:"1px solid maroon"}}>Bed No</b>: {bedId}</p></Col>
//               </Row>
//               </Col>
//               <Col md={2}></Col>
//         </Row>
//               <Row className="mt-3 ">
//               <Col md={6} >
//                     <div >
//                   {/* <img src={qrcode} style={{height:"130px",width:"130px"}} /> */}
//                   <Row className="mt-md-2 mt-2 ">
//                     <Col md={4}>
//                    <label >
//                         Account Name :
//                       </label>
//                       </Col>
//                       <Col md={8}>
//                       <p>MS.SHETH R D DAVADA DIGAMBER JAIN BORDING.SANGLI</p>
//                       </Col>
//                       </Row>

//                       <Row className="mt-md-2 mt-2 ">
//                     <Col md={4}>
//                    <label >
//                         Account No :
//                       </label>
//                       </Col>
//                       <Col md={8}>
//                       <p>1000312010001901</p>
//                       </Col>
//                       </Row>

//                       <Row className="mt-md-2 mt-2 ">
//                     <Col md={4}>
//                    <label >
//                         Bank Name/Branch :
//                       </label>
//                       </Col>
//                       <Col md={8}>
//                       <p>RBL BANK / Sangli Main</p>
//                       </Col>
//                       </Row>

//                       <Row className="mt-md-2 mt-2 ">
//                     <Col md={4}>
//                    <label >
//                         IFSC Code :
//                       </label>
//                       </Col>
//                       <Col md={8}>
//                       <p>RATN0000003</p>
//                       </Col>
//                       </Row>
//                 </div>
//                     </Col>
//                 <Col md={6}>
//                   <Row style={{ display: "flex" }}>
                  
//                       <Row className="mt-md-3 mt-2" style={{ display: "flex" }}>
//                         <Col md={5}>
//                           <label className=" mt-2">Payment Mode :</label>
//                         </Col>
//                         <Col md={7}>
//                           <select
//                             id="paymentMode"
//                             style={{height:"39px"}}
//                             required="required"
//                             className={`w-100 rounded ${errors.paymentMode ? "error-input" : ""}`}
//                             value={paymentMode}
//                             onChange={(e) => setPaymentMode(e.target.value)}
//                           >
//                             <option value="">Select Payment Mode</option>
//                             <option value="1">Online</option>
//                             <option value="2">Cash</option>
//                           </select>
//                           {errors.paymentMode && (
//                             <div className="error-message">{errors.paymentMode}</div>
//                           )}
//                         </Col>
//                       </Row>
//                     <Row className="mt-md-3 mt-2" style={{ display: "flex" }}>
//                     <Col md={5}>
//                       <label className=' mt-2' htmlFor="transactionId">Transaction ID:</label>
//                       </Col>
//                       <Col md={7}>
//                       <input
//                         type="text"
//                         className='w-100'
//                         id="transactionId"
//                         placeholder="Enter transaction ID..."
//                         value={inputValue}
//                         name="taxid"
//                         onChange={(e) => setInputValue(e.target.value)}
//                       />
//                       </Col>
//                     </Row>
//                     <Row className="mt-md-3 mt-2" style={{ display: "flex" }}>
//                     <Col md={5}>
//                       <label className=' mt-2' htmlFor="fileUpload">Upload File:</label>
//                     </Col>
//                     <Col md={7}>
//                       <input
//                         type="file"
//                         className='w-100'
//                         id="fileUpload"
//                         onChange={handleFileChange}
//                       />
//                     </Col>
//                     </Row>
//                   </Row>
//                 </Col>
//               </Row>
//               <div className="mb-5 mt-3 mt-md-5" style={{ display: "flex", justifyContent: "center" }}>
//                 <Button
//                   type="button"
//                   className="fs-5 ms-2 text-center btn btn-primary nextBtn"
//                   style={{ backgroundColor: "rgb(23, 160, 160)", color: "black", width: "120px", border: "0px" }}
//                   disabled={!inputValue || !file || !paymentMode }
//                   onClick={handleSubmit}
//                 >
//                   Submit
//                 </Button>
//               </div>
//             </>
//           )}
//         </div>
//       </section>
//     </>
//   );
// };

// export default AdminPaymentChange;
 



import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AdminRoomChangeAddApi, AdminRoomChangePaymentApi, RoomBookingAddApi, RoomBookingApi, RoomChangeAddApi } from '../../ApiFile'; // Add RoomChangeAddApi
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';

const AdminPaymentChange = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [file, setFile] = useState(null);
  const [amount, setAmount] = useState(null); // State to store the amount
  const [waitingForApproval, setWaitingForApproval] = useState(false); // State for admin approval status
  const [errors, setErrors] = useState({}); // Define errors state variable

  const handleShowModal = () => {
    setShowModal(true);
  };

  const location = useLocation();
  const bedFinal = location.state && location.state.bedFinal;
  console.log("lojhj", bedFinal);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const [blockId, setBlockId] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [bedId, setBedId] = useState(null);

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const requestBody = { member_id: bedFinal.member_id, block_id: bedFinal.block_id, room_id: bedFinal.room_id, bed_id: bedFinal.bed_id };
        const response = await axios.post(RoomBookingApi, requestBody);

        console.log("Response data:", response.data);

        if (response.data === 'Admin Not Room Approval') {
          setWaitingForApproval(true);
          setAmount(null);
          console.log("Waiting for admin room approval");
        } else if (Array.isArray(response.data) && response.data.length > 0) {
          const paymentData = response.data[0];
          setAmount(paymentData.amount);
          setBlockId(paymentData.block_id);
          setRoomId(paymentData.room_id);
          setBedId(paymentData.bed_id);
          setWaitingForApproval(false);
          console.log("amount...", paymentData.amount);
          console.log("block_id...", paymentData.block_id);
          console.log("room_id...", paymentData.room_id);
          console.log("bed_id...", paymentData.bed_id);
        } else {
          setAmount(null); // Reset amount if no data received
          setBlockId(null);
          setRoomId(null);
          setBedId(null);
          setWaitingForApproval(false);
          console.log("No data received");
        }
      } catch (error) {
        console.error("Error fetching payment data:", error);
        setAmount(null);
        setBlockId(null);
        setRoomId(null);
        setBedId(null);
        setWaitingForApproval(false);
      }
    };

    if (bedFinal.member_id) {
      fetchPaymentData();
    }
  }, [bedFinal.member_id]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('member_id', bedFinal.member_id);
    formData.append('file', file);
    formData.append('taxid', inputValue);
    formData.append('pay_mode', paymentMode);
    formData.append('pay_amount', amount);

    // Log form data entries for debugging
    for (const entry of formData.entries()) {
      console.log(`${entry[0]}: ${entry[1]}`);
    }

    const roomChangeData = {
      block_id: blockId,
      room_id: roomId,
      bed_id: bedId,
      member_id: bedFinal.member_id,
    };

    try {
      const [paymentResponse, roomChangeResponse] = await axios.all([
        axios.post(AdminRoomChangePaymentApi, formData),
        axios.post(AdminRoomChangeAddApi, roomChangeData),
      ]);

      console.log("Payment response:", paymentResponse.data);
      console.log("Room change response:", roomChangeResponse.data);

      if (paymentResponse.data == 'SAVED' && roomChangeResponse.data == 'SAVED') {
        toast.success('Payment Done And Room Changed Successfully!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setInputValue('');
        setPaymentMode('');
        setFile(null);
        navigate('/RoomChange');
      } else if (paymentResponse.data === "Alredy Payment done") {
        toast.warn('Already payment done!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Handle errors or other responses
        toast.error('Please try again', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error('Error submitting payment or room change:', error);
      toast.error('Error submitting payment or room change, please try again', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div className="bed-booking px-md-5 pb-5 px-2 mt-md-5 mt-3">
          <h3 className="main-title1"><p style={{ borderBottom: "1px solid maroon", color: "maroon" }}>Room Payment</p></h3>
          {waitingForApproval ? (
            <p style={{ fontSize: '25px', color: 'maroon', textShadow: '1px 1px black' }}>
              Wait for admin room approval
            </p>
          ) : (
            <>
              <Row style={{ display: "flex" }}>
                <Col md={2}></Col>
                <Col md={8}>
                  <Row style={{ display: "flex" }}>
                    <Col sm={12} md={3}><p><b style={{ color: "gray", borderBottom: "1px solid maroon" }}>Amount</b>: {amount}</p></Col>
                    <Col sm={12} md={3}><p><b style={{ color: "gray", borderBottom: "1px solid maroon" }}>Block No</b>: {blockId}</p></Col>
                    <Col sm={12} md={3}><p><b style={{ color: "gray", borderBottom: "1px solid maroon" }}>Room No</b>: {roomId}</p></Col>
                    <Col sm={12} md={3}><p><b style={{ color: "gray", borderBottom: "1px solid maroon" }}>Bed No</b>: {bedId}</p></Col>
                  </Row>
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                <div >
                  
                  
                  <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Account Name :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SHETH RAMDHANAJI DAVADA JAIN BOARDING SANGLI</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Account No :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>120503130002085</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Bank Name/Branch :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SVC CO-OPERATIVE BANK LTD / SANGLI</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        IFSC Code :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SVCB0000205</p>
                      </Col>
                      </Row>
                </div>
                </Col>
                <Col md={6}>
                  <Row style={{ display: "flex" }}>
                    <Row className="mt-md-3 mt-2" style={{ display: "flex" }}>
                      <Col md={5}>
                        <label className="mt-2">Payment Mode:</label>
                      </Col>
                      <Col md={7}>
                        <select
                          id="paymentMode"
                          style={{ height: "39px" }}
                          required="required"
                          className={`w-100 rounded ${errors.paymentMode ? "error-input" : ""}`}
                          value={paymentMode}
                          onChange={(e) => setPaymentMode(e.target.value)}
                        >
                          <option value="">Select Payment Mode</option>
                          {/* <option value="1">Online</option> */}
                          <option value="2">Cash / Online</option>
                        </select>
                        {errors.paymentMode && (
                          <div className="error-message">{errors.paymentMode}</div>
                        )}
                      </Col>
                    </Row>
                    <Row className="mt-md-3 mt-2" style={{ display: "flex" }}>
                      <Col md={5}>
                        <label className='mt-2' htmlFor="transactionId">Payment Ref. No:</label>
                      </Col>
                      <Col md={7}>
                        <input
                          type="text"
                          className='w-100'
                          id="transactionId"
                          placeholder="Cheque No / Transaction Id"
                          value={inputValue}
                          name="taxid"
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-md-3 mt-2" style={{ display: "flex" }}>
                      <Col md={5}>
                        <label className='mt-2' htmlFor="fileUpload">Upload File:</label>
                      </Col>
                      <Col md={7}>
                        <input
                          type="file"
                          className='w-100'
                          id="fileUpload"
                          onChange={handleFileChange}
                        />
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
              <div className="mb-5 mt-3 mt-md-5" style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="button"
                  className="fs-5 ms-2 text-center btn btn-primary nextBtn"
                  style={{ backgroundColor: "rgb(23, 160, 160)", color: "black", width: "120px", border: "0px" }}
                  disabled={!inputValue || !file || !paymentMode}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default AdminPaymentChange;
