import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; 
import qrcode from '../../StudentRegristrationDesign/JainBoardingQr.jpeg';
import '../Room Master/room.css';
import Header from './Header';
import Sidebar from './sidebar';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RoomBookingAddApi, RoomBookingApi } from '../../ApiFile';
import { Button } from '@mui/material';

const PaymentRoomBooking = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [pay_amount, setpay_amount] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [file, setFile] = useState(null);
  const [amount, setAmount] = useState(null); // State to store the amount
  const [waitingForApproval, setWaitingForApproval] = useState(false); // State for admin approval status
  const [errors, setErrors] = useState({}); // Define errors state variable

  const block_id = sessionStorage.getItem('block_id');
  const room_id = sessionStorage.getItem('room_id');
  const bed_id = sessionStorage.getItem('bed_id');
  const memberId = sessionStorage.getItem('member_id');
  const status = sessionStorage.getItem('status');
  // console.log("status....",status)

  const handleShowModal = () => {
    setShowModal(true);
  };

  const downloadQRCode = () => {
    const link = document.createElement('a');
    link.href = qrcode;
    link.download = <img src={qrcode} className='w-80 text-center px-md-3 px-1 mb-3 mb-md-0' height={250} />;
    link.click();
  };

  const location = useLocation();
  const bedFinal = location.state && location.state.bedFinal;
  // console.log("lojhj",bedFinal);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue();
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const [blockId, setBlockId] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [bedId, setBedId] = useState(null);

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {

        let requestBody;
        if (status === '8' || status === '5') {
          requestBody = { member_id: memberId, block_id: block_id, room_id: room_id, bed_id: bed_id };
        } else {
          requestBody = { member_id: memberId, block_id: bedFinal.block_id, room_id: bedFinal.room_id, bed_id: bedFinal.bed_id };
        }

        const response = await axios.post(RoomBookingApi, requestBody);

        // console.log("Response status:", response.status);
        // console.log("Response data:", response.data);

        if (response.data === 'Admin Not Room Approval') {
          setWaitingForApproval(true);
          setAmount(null);
          // console.log("Waiting for admin room approval");
        } else if (Array.isArray(response.data) && response.data.length > 0) {
          const paymentData = response.data[0];
          setAmount(paymentData.amount);
          setBlockId(paymentData.block_id);
          setRoomId(paymentData.room_id);
          setBedId(paymentData.bed_id);
          setWaitingForApproval(false);
          // console.log("amount...", paymentData.amount);
          // console.log("block_id...", paymentData.block_id);
          // console.log("room_id...", paymentData.room_id);
          // console.log("bed_id...", paymentData.bed_id);
        } else {
          setAmount(null); // Reset amount if no data received
          setBlockId(null);
          setRoomId(null);
          setBedId(null);
          setWaitingForApproval(false);
          // console.log("No data received");
        }
      } catch (error) {
        // console.error("Error fetching payment data:", error);
        setAmount(null);
        setBlockId(null);
        setRoomId(null);
        setBedId(null);
        setWaitingForApproval(false);
      }
    };

    if (memberId) {
      fetchPaymentData();
    }
  }, [memberId]);

  const handleSubmit = async () => {

    const formData = new FormData();
    formData.append('member_id', memberId);
    formData.append('file', file); 
    formData.append('taxid', inputValue); 
    formData.append('pay_amount', amount); 
    formData.append('pay_mode', paymentMode); 
    formData.append('block_id', (status === '8' || status === '5') ? blockId : bedFinal.block_id);
formData.append('room_id', (status === '8' || status === '5') ? roomId : bedFinal.room_id);
formData.append('bed_id', (status === '8' || status === '5') ? bedId : bedFinal.bed_id);


    // Log form data entries for debugging
    // for (const entry of formData.entries()) {
    //   console.log(`${entry[0]}: ${entry[1]}`);
    // }

    try {
      const res = await axios.post(RoomBookingAddApi, formData);

      // console.log("res console....", res.data);
      if (res.data === 'SAVED') {
        alert('Payment Done, Wait For Admin Approval !!!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setInputValue('');
        setpay_amount('');
        setPaymentMode('');
        setFile(null);
        navigate('/')
      } else if (res.data === "Alredy Payment done") {
        toast.warn('Already payment done !!!!!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Handle errors or other responses
        toast.error('Please try again', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      // console.error('Error submitting payment:', error);
      toast.error('Error submitting payment, please try again', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
 
  return (
    <>
      <Sidebar />
      <section className="home-section">
        <Header />
        <div className="bed-booking px-md-5 pb-5 px-2 mt-md-5 mt-3">
          <h3 className="main-title1"><p style={{borderBottom:"1px solid maroon" ,color:"maroon"}}>Room Payment</p></h3>
          {waitingForApproval ? (
            <p style={{ fontSize: '25px', color: 'maroon', textShadow: '1px 1px black' }}>
              Wait for admin room approval
            </p>
          ) : (
            <>
              {/* {amount !== null && (
                <p style={{ fontSize: '25px', color: 'maroon', textShadow: '1px 1px black' }}>
                  Fee: {amount}
                </p>
              )} */}
              <Row style={{display:"flex"}}>
              <Col md={2}></Col>
              <Col md={8}>
              <Row style={{display:"flex"}}>
              <Col sm={12} md={3}><p><b style={{color:"gray",borderBottom:"1px solid maroon"}}>Amount</b>: {amount}</p></Col>
              <Col sm={12} md={3}><p><b style={{color:"gray",borderBottom:"1px solid maroon"}}>Block No</b>: {blockId}</p></Col>
              <Col sm={12} md={3}><p><b style={{color:"gray",borderBottom:"1px solid maroon"}}>Room No</b>: {roomId}</p></Col>
              <Col sm={12} md={3}><p><b style={{color:"gray",borderBottom:"1px solid maroon"}}>Bed No</b>: {bedId}</p></Col>
              </Row>
              </Col>
              <Col md={2}></Col>
        </Row>
        <div className="text-center mt-3"><img src={qrcode} style={{height:"130px",width:"130px"}} /></div>
        <div className="text-center">
                        <Button  className='mt-3 mt-md-2' style={{background:"rgb(57, 158, 161)",color:"white",border:"0px"}} onClick= {downloadQRCode}>
                    Download QR Code
                  </Button></div>
              <Row className="mt-3 ">
              <Col md={6} >
              <div >
                  
              <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        UPI ID :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>getepay.svccblqr432819@icici</p>
                      </Col>
                      </Row>
                  
                  <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Account Name :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SHETH RAMDHANAJI DAVADA JAIN BOARDING SANGLI</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Account No :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>120503130002085</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Bank Name/Branch :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SVC CO-OPERATIVE BANK LTD / SANGLI</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        IFSC Code :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SVCB0000205</p>
                      </Col>
                      </Row>
                </div>
                    </Col>
                <Col md={6}>
                  <Row style={{ display: "flex" }}>

                  <Row className="mt-md-3 mt-2" style={{ display: "flex" }}>
                    <Col md={5}>
                      <label className=' mt-2' htmlFor="transactionId">Pay Amount:</label>
                      </Col>
                      <Col md={7}>
                      <input
                        value={amount}
                        readOnly
                        // type="text"
                        className='w-100'
                        // name="pay_amount"
                        // placeholder="Enter Amount"
                        // value={pay_amount}
                        // onChange={(e) => setpay_amount(e.target.value)}
                      />
                      </Col>
                    </Row>
                  
                      <Row className="mt-md-3 mt-2" style={{ display: "flex" }}>
                        <Col md={5}>
                          <label className=" mt-2">Payment Mode :</label>
                        </Col>
                        <Col md={7}>
                          <select
                            id="paymentMode"
                            style={{height:"39px"}}
                            required="required"
                            className={`w-100 rounded ${errors.paymentMode ? "error-input" : ""}`}
                            value={paymentMode}
                            onChange={(e) => setPaymentMode(e.target.value)}
                          >
                            <option value="">Select Payment Mode</option>
                            <option value="1">Online</option>
                            <option value="2">Cash</option>
                          </select>
                          {errors.paymentMode && (
                            <div className="error-message">{errors.paymentMode}</div>
                          )}
                        </Col>
                      </Row>
                    <Row className="mt-md-3 mt-2" style={{ display: "flex" }}>
                    <Col md={5}>
                    {paymentMode === '1'?
                      <label className=' mt-2' htmlFor="transactionId">Transaction ID:</label> :
                      <label className=' mt-2' htmlFor="transactionId">Cash Receipt No:</label>
                    }
                      </Col>
                      <Col md={7}>
                      <input
                        type="text"
                        className='w-100'
                        id="transactionId"
                        placeholder={paymentMode === '1'?
                        "Enter transaction ID...":
                        "Enter Cash Receipt No..."}
                        value={inputValue}
                        name="taxid"
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                      </Col>
                    </Row>
                   
                    <Row className="mt-md-3 mt-2" style={{ display: "flex" }}>
                    <Col md={5}>
                      <label className=' mt-2' htmlFor="fileUpload">Upload Paid Image:</label>
                    </Col>
                    <Col md={7}>
                      <input
                        type="file"
                        className='w-100'
                        id="fileUpload"
                        onChange={handleFileChange}
                      />
                    </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
              <div className="mb-5 mt-3 mt-md-5" style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="button"
                  className="fs-5 ms-2 text-center btn btn-primary nextBtn"
                  style={{ backgroundColor: "rgb(23, 160, 160)", color: "black", width: "120px", border: "0px" }}
                  disabled={!inputValue || !amount || !file || !paymentMode }
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default PaymentRoomBooking;
