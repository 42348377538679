import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import ReportNavbar from './ReportNavbar';
import { MDBDataTable } from 'mdbreact';
import { ReporFeeStatus, ReportMemberListApi } from '../../ApiFile';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const FeeStatusHostelFee = () => {

    const [members, setMembers] = useState([]);

    useEffect(() => {
      const year = sessionStorage.getItem('selectedYear');
      fetch(`${ReporFeeStatus}/${year}`)
        .then(response => response.json())
        .then(data => setMembers(data))
        .catch(error => console.error('Error fetching member list:', error));
    }, []);
  
    const rows = members.map((member, index) => ({
      serial: index + 1,
      name: member.name,
      block_id: member.block_id,
      room_id: member.room_id,
      bed_id: member.bed_id,
      mob_no: member.mob_no,
      total_amount: member.total_amount,
      first_installment: member.first_installment,
      second_installment: member.second_installment,
      without_installment: member.without_installment,
      pay_mode: member.pay_mode,
      pending_amount: member.pending_amount,
    }));
  
    const data = {
      columns: [
        {
          label: 'Serial No',
          field: 'serial',
          sort: 'asc',
        },
        {
          label: 'Name',
          field: 'name',
          sort: 'asc',
        },
        {
          label: 'Block No',
          field: 'block_id',
          sort: 'asc',
        },
        {
          label: 'Room No',
          field: 'room_id',
          sort: 'asc',
        },
        {
          label: 'Mob. No',
          field: 'mob_no',
          sort: 'asc',
        },
        {
          label: 'Total Fee',
          field: 'total_amount',
          sort: 'asc',
        },
        {
          label: '1st Installment',
          field: 'first_installment',
          sort: 'asc',
        },
        {
            label: '2nd Installment',
            field: 'second_installment',
            sort: 'asc',
          },
        {
            label: 'Without Installment',
            field: 'without_installment',
            sort: 'asc',
          },
          {
            label: 'Pay Mode',
            field: 'pay_mode',
            sort: 'asc',
          },
          {
            label: 'Pending Amt',
            field: 'pending_amount',
            sort: 'asc',
          },
        
      ],
      rows: rows
    };
  
    const handleExcelData = () => {
      const ws = XLSX.utils.json_to_sheet(rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
  
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Fee_Status_Data.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
  
      toast.success("Excel Sheet Downloaded !!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    };
  
    const handlePdfData = () => {
      const doc = new jsPDF();
      const columns = data.columns.map((col) => col.label);

      const rowsData = rows.map((row) => [row.serial, row.name,row.block_id,row.room_id,row.mob_no,row.total_amount,row.first_installment,row.second_installment,row.without_installment,row.pay_mode,row.pending_amount]);
  
      const title = 'Fee Status Data';
  
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const x = (pageWidth - textWidth) / 2;
      doc.text(title, x, 10);
  
      doc.autoTable({
        head: [columns],
        body: rowsData,
        startY: 20,
      });
  
      const blob = doc.output('blob');
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Fee_Status_Data.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
  
      toast.success("PDF Downloaded !!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    };
  
    return (
      <>
        <Sidebar />
        <section className="home-section">
          <NavLogo />
          <ReportNavbar />
  
          <div className="member-list border">

            <Row className="m-0 p-0 pt-4 px-3">
              <Col
                  className="rounded text-center py-2"
                  style={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: "white",
                      border: "0px",
                      alignItems: "center",
                      alignContent: "center",
                      color: "maroon",
                  }}
              >    
                  <div><span style={{color:"maroon",fontSize:"18px"}}>Fee Status Report</span></div>
                  <div>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#3b9cfd",color:"white"}}  onClick={() => handleExcelData()}>Excel</Button>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#136fcb",color:"white"}} onClick={() => handlePdfData()}>Pdf</Button>
                  </div>
              </Col>
          </Row>
  
            <Row className="m-0 p-0 px-md-3 py-1 pb-5  w-100 mb-5" style={{ display: 'flex' }}>
            <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <MDBDataTable
              striped
              bordered
              className="custom-datatable"
              small
              data={data}
            />
            </Col>
            </Row>
          </div>
  
        </section>
      </>
    );
  };

export default FeeStatusHostelFee