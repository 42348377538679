import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import ReportNavbar from './ReportNavbar';
import { MDBDataTable } from 'mdbreact';
import { ReportAluminiReport, ReportMemberListApi } from '../../ApiFile';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const AlumniReport = () => {

    const [members, setMembers] = useState([]);

    useEffect(() => {
      fetch(ReportAluminiReport)
        .then(response => response.json())
        .then(data => setMembers(data))
        .catch(error => console.error('Error fetching member list:', error));
    }, []);
  
    const rows = members.map((member, index) => ({
      serial: index + 1,
      name: member.name,
      address: member.address,
      alternate_address: member.alternate_address,
      dateofbirth: member.dateofbirth,
      qualification: member.qualification,
      email_id: member.email_id,
      mob_no: member.mob_no,
      alternate_mobno: member.alternate_mobno,
      business_service_name: member.business_service_name,
      yearofboarding: member.yearofboarding,
    }));
  
  
    const data = {
      columns: [
        {
          label: 'Serial No',
          field: 'serial',
          sort: 'asc',
        },
        {
          label: 'Student Name',
          field: 'name',
          sort: 'asc',
        },
        {
          label: 'Mob.No-1',
          field: 'mob_no',
          sort: 'asc',
        },
        {
          label: 'Mob.No-2',
          field: 'alternate_mobno',
          sort: 'asc',
        },
        {
          label: 'Email Id',
          field: 'email_id',
          sort: 'asc',
        },
        {
          label: 'Current Add.',
          field: 'address',
          sort: 'asc',
        },
        {
          label: 'Alternate Add.',
          field: 'alternate_address',
          sort: 'asc',
        },
        {
          label: 'Date Of Birth',
          field: 'dateofbirth',
          sort: 'asc',
        },
        {
          label: 'Qualification',
          field: 'qualification',
          sort: 'asc',
        },
        {
          label: 'Service/Business Name',
          field: 'business_service_name',
          sort: 'asc',
        },      
        {
          label: 'Year Of Boarding',
          field: 'yearofboarding',
          sort: 'asc',
        },
       
      ],
      rows: rows
    };
  
    const handleExcelData = () => {
      const ws = XLSX.utils.json_to_sheet(rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
  
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Alumni_Report_Data.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
  
      toast.success("Excel Sheet Downloaded !!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    };
  
    const handlePdfData = () => {
      const doc = new jsPDF();
      const columns = data.columns.map((col) => col.label);
      const rowsData = rows.map((row) => [row.serial,row.name,row.mob_no,row.alternate_mobno,row.email_id,row.address,row.alternate_address,row.dateofbirth,row.qualification,row.business_service_name,row.yearofboarding]);
  
      const title = 'Alumni Report Data';
  
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const x = (pageWidth - textWidth) / 2;
      doc.text(title, x, 10);
  
      doc.autoTable({
        head: [columns],
        body: rowsData,
        startY: 20,
      });
  
      const blob = doc.output('blob');
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Alumni_Report_Data.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
  
      toast.success("PDF Downloaded !!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    };
  
    return (
      <>
        <Sidebar />
        <section className="home-section">
          <NavLogo />
          <ReportNavbar />
  
          <div className="member-list border">
            <Row className="m-0 p-0 pt-4 px-3 ">
              <Col
                  className="rounded text-center py-2"
                  style={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: "white",
                      border: "0px",
                      alignItems: "center",
                      alignContent: "center",
                      color: "maroon",
                  }}
              >    
                  <div><span style={{color:"maroon",fontSize:"18px"}}>Alumni Report</span></div>
                  <div>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#3b9cfd",color:"white"}}  onClick={() => handleExcelData()}>Excel</Button>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#136fcb",color:"white"}} onClick={() => handlePdfData()}>Pdf</Button>
                  </div>
              </Col>
          </Row>
  
            <Row className="m-0 p-0 px-md-3 py-1 pb-5  w-100 mb-5" style={{ display: 'flex' }}>
            <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white',overflowX:"auto", }}>
            <MDBDataTable
              striped
              bordered
              className="custom-datatable"
              small
              data={data}
            />
            </Col>
            </Row>
          </div>
  
        </section>
      </>
    );
  };
export default AlumniReport