import React, { useState } from 'react';
import Popup from './reg'; // Import the Popup component

function Forgot() {
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [email, setEmail] = useState(''); // State to store email input value

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform any necessary actions, e.g., submit email
    togglePopup(); // Show the popup
    // Redirect to /reg after a delay (if needed)
    setTimeout(() => {
      window.location.href = '/reg';
    }, 0); // Redirect after 1 seconds (adjust as needed)
  };

  const fieldStyle = {
    position: 'relative',
    height: '45px',
    width: '100%',
    display: 'flex',
    background: 'rgba(255,255,255,0.94)',
    marginTop: '14px',
  };

  const inputStyle = {
    height: '100%',
    width: '100%',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    color: '#222',
    fontSize: '16px',
    fontFamily: "'Poppins', sans-serif",
  };

  return (
    <div
      style={{
        backgroundImage: "url('assets/img/about.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        height: '100vh',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          zIndex: 999,
          textAlign: 'center',
          padding: '60px 32px',
          width: '370px',
          transform: 'translate(-50%,-50%)',
          boxShadow: '3px 4px 28px 0px rgba(0,0,0,0.75)',
          backgroundColor: 'rgba(234, 225, 218,0.6)',
        }}
      >
        <div
          style={{
            height: '80px',
            width: '300px',
            marginTop: '-30px',
            borderRadius: '2px',
          }}
        >
          <img
            src="assets/img/jain logo.png"
            alt="Logo"
            style={{ height: '70%', width: '100%' }}
          />
        </div>
        <header
          style={{
            color: 'black',
            fontSize: '33px',
            fontWeight: 600,
            margin: '9px 0 35px 0',
            fontFamily: "'Montserrat', sans-serif",
          }}
        >
         Reset Password
        </header>
        <header
          style={{
            color: 'black',
            fontSize: '13px',
            fontWeight: 600,
            margin: '9px 0 35px 0',
            fontFamily: "'Montserrat', sans-serif",
          }}
        >
          Please enter the email address that you used to register, and we will send you a link to reset your password via Email.
        </header>
        <form onSubmit={handleSubmit}>
          
          <div style={fieldStyle}>
            <span style={{ color: '#222', width: '40px', lineHeight: '45px' }}>
              <i className="fa fa-envelope"></i>
            </span>
            <input
              type="email"
              required
              placeholder="Enter your registered Email"
              style={inputStyle}
              value={email}
              onChange={handleEmailChange}
            />
          </div>
         
          <a href="/reg" style={{ textDecoration: 'none', color: '#fff' }}>
            <div style={{ marginTop: '14px' }} className="field">
              <input
                type="submit"
                value="Send Mail"
                style={{
                  background: '#3498db',
                  border: '1px solid #2691d9',
                  color: 'white',
                  fontSize: '18px',
                  letterSpacing: '1px',
                  fontWeight: 600,
                  cursor: 'pointer',
                  fontFamily: "'Montserrat', sans-serif",
                  padding: '10px 0',
                  width: '100%',
                }}
              />
            </div>
          </a>

          <div style={{ marginTop: '14px', fontSize: '18px', color: '#222', fontstyle:'Bold' }}>
            Have an account? <a href="/signin">Sign In</a>
          </div>
        
        </form>
      </div>
      
      {/* Popup component */}
      {showPopup && <Popup />}
    </div>
  );
}

export default Forgot;