import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import loginImage from './6878430.png'; // Import the image file
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './alumini.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AluminiLoginpApi} from './../../ApiFile';


const AluminiLogin = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const onFinish = values => {
        setLoading(true);
        axios.post(AluminiLoginpApi , {
            email_id: values.username,
            password: values.password,
        })
            .then(response => {
                // console.log(response);
                setLoading(false);
                if(response.data ==="NOTFOUND"){
                    // alert("Invalid login details")
                    alert('Invalid login details', {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                    });
                }
                else if(response.data.message === "ALREADYEXIST"){
                    sessionStorage.setItem("userdata", JSON.stringify(response.data.userdata));
                    navigate('/AluminiDashboard');
                }
               
               else if (response.data.message === 'login successful') {
                    // notification.success({
                    //   message: 'Login Successful',
                    //   description: 'Welcome back, alumni!',
                    // });
                    sessionStorage.setItem("userdata", JSON.stringify(response.data.userdata));
                    navigate('/Aluminiform');
                  } else {
                    notification.error({
                      message: 'Login Failed',
                      description: 'Invalid email or password.',
                    });
                  }
            })
            .catch(error => {
                setLoading(false);
                notification.error({
                    message: 'Login Failed',
                    description: 'Error logging in. Please try again.',
                });
            });
    };

    const onFinishFailed = errorInfo => {
        // console.log('Failed:', errorInfo);
    };

    return (
        <div className="login-page">
            <div className="login-box" style={{overflow:"scroll"}}>
                <div className="illustration-wrapper">
                    <img src={loginImage} alt="Login" /> {/* Use the imported image */}
                </div>
                <Form
                    name="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <p className="form-title">Alumini Login</p>
                    <p>Login to your alumni account</p>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your Email ID!' }]}
                    >
                        <Input
                            placeholder="Email ID"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password
                            placeholder="Password"
                        />
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                            LOGIN
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        {/* Ensure the to prop points to the correct path for the signup page */}
                        <p>Not a user? <Link to="/AluminiSignup">Sign up</Link></p>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default AluminiLogin;
