import react, { Fragment } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';


function Post(){
    return(
        <div>
        <Navbar></Navbar>
        {/* Header Start */}
        <div className="container-fluid bg3-breadcrumb">
          <div className="container text-center py-4" style={{maxWidth: '300px', maxHeight: '50px'}}>
            <h3 className="text-white display-4 mb-4">Post Job</h3>
            <div className="banner-job" />
          </div>
        </div>
        <br />
        <div className="info" style={{}}>
          <form className="form-body" action="/action_page.php" method="post" validate>
            <section className=" job-bg ad-details-page" style={{marginLeft: '20%'}}>
              <div className="container">
                <div className="breadcrumb-section">
                  <ol className="breadcrumb">
                    <h2 className="title">Post Your Job</h2>
                  </ol></div>
                <div className="job-postdetails">
                  <div className="row">
                    <div className="col-lg-8">
                      <fieldset>
                        <div className="section postdetails">
                          <h4><span className="pull-right" style={{}}>* Mandatory Fields</span></h4>
                          <div className="row form-group add-title">
                            <label className="col-sm-3 label-title">Job Category</label>
                            <div className="col-sm-9">
                              <div className="dropdown category-dropdown">
                                <a data-toggle="dropdown" href="#" aria-expanded="false"><span className="change-text">Select a category</span> <i className="fa fa-angle-down " /></a>
                                <ul className="dropdown-menu category-change">
                                  <li><a href="#">Select a category</a></li>
                                  <li><a href="#">Software Engineer</a></li>
                                  <li><a href="#">Program Development</a></li>
                                  <li><a href="#">Project Manager</a></li>
                                  <li><a href="#">Graphics Designer</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-3">Job Type<span className="required">*</span></label>
                            <div className="col-sm-9 user-type">
                              <input type="radio" name="sellType" defaultValue="full-time" id="full-time" /> <label htmlFor="full-time">Full Time</label>
                              <input type="radio" name="sellType" defaultValue="part-time" id="part-time" /> <label htmlFor="part-time">Part Time</label>
                              <input type="radio" name="sellType" defaultValue="freelance" id="freelance" /> <label htmlFor="freelance">Freelance</label>
                              <input type="radio" name="sellType" defaultValue="contract" id="contract" />
                              <label htmlFor="contract">Contract</label>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-3 label-title">Title for your job<span className="required">*</span></label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" placeholder="ex, Human Resource Manager" />
                            </div>
                          </div>
                          <div className="row form-group item-description">
                            <label className="col-sm-3 label-title">Description<span className="required">*</span></label>
                            <div className="col-sm-9">
                              <textarea className="form-control" id="textarea" placeholder="Write few lines about your jobs" rows={8} defaultValue={""} />
                            </div>
                          </div>
                          <div className="row characters">
                            <div className="col-sm-9 col-sm-offset-3">
                              <p>5000 characters left</p>
                            </div>
                          </div>
                          <div className="row form-group add-title location">
                            <label className="col-sm-3 label-title">Location<span className="required">*</span></label>
                            <div className="col-sm-9 loc">
                              <div className="dropdown category-dropdown pull-left">
                                <a data-toggle="dropdown" href="#" aria-expanded="false"><span className="change-text">Country</span> <i className="fa fa-angle-down " /></a>
                                <ul className="dropdown-menu category-change">
                                  <li><a href="#">Argentina</a></li>
                                  <li><a href="#">Australia</a></li>
                                  <li><a href="#">Belgium</a></li>
                                  <li><a href="#">Brazil</a></li>
                                  <li><a href="#">Cambodia</a></li>
                                </ul>
                              </div>
                              <div className="dropdown category-dropdown state">
                                <a data-toggle="dropdown" href="#" aria-expanded="false"><span className="change-text">State</span> <i className="fa fa-angle-down pull-right" /></a>
                                <ul className="dropdown-menu category-change">
                                  <li><a href="#">State 1</a></li>
                                  <li><a href="#">State 2</a></li>
                                  <li><a href="#">State 3</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group select-price">
                            <label className="col-sm-3 label-title">Salary<span className="required">*</span></label>
                            <div className="col-sm-9 maxmin">
                              <label>$USD</label>
                              <input type="text" className="form-control" placeholder="Min" />
                              <span>-</span>
                              <input type="text" className="form-control" placeholder="Max" />
                              <input type="radio" name="price" defaultValue="negotiable" id="negotiable" />
                              <label htmlFor="negotiable">Negotiable </label>
                            </div>
                          </div>
                          <div className="row form-group add-title">
                            <label className="col-sm-3 label-title">Salary Type<span className="required">*</span></label>
                            <div className="col-sm-9">
                              <div className="dropdown category-dropdown">
                                <a data-toggle="dropdown" href="#" aria-expanded="false"><span className="change-text">Per Hour</span> <i className="fa fa-angle-down pull-right" /></a>
                                <ul className="dropdown-menu category-change">
                                  <li><a href="#">Per Hour</a></li>
                                  <li><a href="#">Daily</a></li>
                                  <li><a href="#">Monthly</a></li>
                                  <li><a href="#">Yearly</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group add-title">
                            <label className="col-sm-3 label-title">Exprience<span className="required">*</span></label>
                            <div className="col-sm-9">
                              <div className="dropdown category-dropdown">
                                <a data-toggle="dropdown" href="#" aria-expanded="false"><span className="change-text">Mid Level</span> <i className="fa fa-angle-down pull-right" /></a>
                                <ul className="dropdown-menu category-change">
                                  <li><a href="#">Entry Level</a></li>
                                  <li><a href="#">Mid Level</a></li>
                                  <li><a href="#">Mid-Senior Level</a></li>
                                  <li><a href="#">Top Level</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="section company-information">
                          <h4>Company Information</h4>
                          <div className="row form-group">
                            <label className="col-sm-3 label-title">Industry<span className="required">*</span></label>
                            <div className="col-sm-9">
                              <input type="text" name="name" className="form-control" placeholder="Marketing and Advertising" />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-3 label-title">Company Name<span className="required">*</span></label>
                            <div className="col-sm-9">
                              <input type="text" name="name" className="form-control" placeholder="ex, Jhon Doe" />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-3 label-title">Email ID</label>
                            <div className="col-sm-9">
                              <input type="email" name="email" className="form-control" placeholder="ex, jhondoe@mail.com" />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-3 label-title">Mobile Number<span className="required">*</span></label>
                            <div className="col-sm-9">
                              <input type="text" name="mobileNumber" className="form-control" placeholder="ex, +912457895" />
                            </div>
                          </div>
                          <div className="row form-group address">
                            <label className="col-sm-3 label-title">Address<span className="required">*</span></label>
                            <div className="col-sm-9">
                              <input type="text" name="address" className="form-control" placeholder="ex, alekdera House, coprotec, usa" />
                            </div>
                          </div>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" defaultValue id="invalidCheck" required />
                          <label className="form-check-label" id="confirm">I confirm that all data are
                            correct</label>
                        </div>
                        <button type="submit" className="btn btn-primary">Post Your Job</button>
                      </fieldset></div>
                  </div>
                </div>
              </div>
            </section></form></div>
        <br />
        <Footer/>
        {/* Copyright End */}
        {/* Back to Top */}
        <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top up"><i className="fa fa-arrow-up" /></a>
        {/* Footer End */}
      </div>

    )
}
export default Post;