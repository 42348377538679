import React, { useState } from "react";
import { Form, Input, Button, Checkbox, notification } from "antd";
import loginImage from "./6878430.png"; // Import the image file
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./alumini.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MemberLoginApi } from "../../ApiFile";

const StudentLogin = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    setLoading(true);
    axios
      .post(MemberLoginApi, {
        email: values.username,
        password: values.password,
      })
      .then((response) => {
        // console.log(response); // Add this line
        setLoading(false);
      
      if (response.data.status == 5 ) {
          notification.warning({
            message: "Student is discharged!",
          });
          navigate("/StudentLogin");
          return;
        }
       else if (response.data.message === "Invalid Email_id") {
          notification.error({
            message: "Invalid Email",
          });
          return;
        }
       else if (response.data.message === "Invalid Password") {
        notification.error({
          message: "Invalid Password",
        });
        return;
        } if (response.data.message === "Login Successful") {
          
          sessionStorage.setItem("member_id", response.data.member_id);
          sessionStorage.setItem("memberName", response.data.memberName);
          sessionStorage.setItem("email", response.data.email);
          sessionStorage.setItem("status", response.data.status);
          sessionStorage.setItem("year", response.data.year);
         
          if (response.data.status === 3 ) {
            notification.warning({
              message: "Admin Approval Pending",
              description: "Wait for Admin Approval....",
            });
            navigate("/StudentLogin");
          }
          else if (response.data.status === 4 ) {
            notification.success({
              message: "Login Successfully !!!!!",
            });
            navigate("/BlockRoomBooking");
          }
         
          else if (response.data.status === 7 ) {
            notification.info({
              message: "Payment Approval Pending",
              description: "Admin payment approval is pending.",
            });
            navigate("/StudentLogin");
          }
          else if (response.data.status === 8 ) {
            sessionStorage.setItem("block_id", response.data.block_id);
            sessionStorage.setItem("room_id", response.data.room_id);
            sessionStorage.setItem("bed_id", response.data.bed_id);
            notification.error({
              message: "Payment Rejected",
              description: "Your payment has been rejected. Please pay again.",
            });
            navigate("/PaymentRoomBooking");
          }
          else if (response.data.status === 9) {
            // notification.success({
            //   message: "Payment Successful",
            //   description: "Your payment was successful.",
            // });
            navigate("/Homestudent");
          }
        }
        else {
          notification.error({
            message: "Login Failed",
            description: "Invalid email or password.",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: "Login Failed",
          description: "Error logging in. Please try again.",
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <div className="login-page">
      <div className="login-box"  style={{overflow:"scroll"}}>
        <div className="illustration-wrapper">
          <img src={loginImage} alt="Login" /> {/* Use the imported image */}
        </div>
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className="form-title">Student Login</p>
          <p>Login to your student account</p>
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Email ID!" }]}
          >
            <Input placeholder="Email ID" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>


          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loading}
              style={{backgroundColor:"rgb(77, 81, 168)"}}
            >
              <b>Existing User Login</b>
            </Button>
          </Form.Item>
          <Form.Item>
           <Link to="/RegForm"><Button className="signup-link w-100" style={{backgroundColor:"rgb(49, 141, 106)"}}>
            <b>Room Book / Apply</b>
            </Button></Link>
           
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default StudentLogin;
