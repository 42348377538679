import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import './category.css';
import AddIcon from '@mui/icons-material/Add';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { Button, Modal, Box, Typography, TextField, IconButton } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CategoryAddApi, CategoryFindAllApi, CategoryJobDeleteApi, CategoryJobUpdateApi } from './../../ApiFile';

const JobCategoryMaster = () => {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);


  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    setCategoryName('');
  };

  const handleConfirmOpen = () => setConfirmOpen(true);
  const handleConfirmClose = () => setConfirmOpen(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(CategoryFindAllApi);
      setCategories(response.data);
    } catch (error) {
      toast.error('Failed to fetch categories', { position: 'top-right', autoClose: 3000 });
    }
  };

  const handleSave = async () => {
    if (!categoryName) {
      toast.error('Category name is required', { position: 'top-right', autoClose: 3000 });
      return;
    }

    try {
      let response;
      if (isEditing) {
        response = await axios.put(`${CategoryJobUpdateApi}/${currentId}`, { categoryname: categoryName });
        console.log("update...",response);
        if (response.data == 'UPDATE') {
          toast.success('Category updated successfully', { position: 'top-right', autoClose: 3000 });
        } else {
          toast.error('Failed to update category', { position: 'top-right', autoClose: 3000 });
        }
      } else {
        response = await axios.post(CategoryAddApi, { categoryname: categoryName });
        if (response.data == 'SAVED') {
          toast.success('Category added successfully', { position: 'top-right', autoClose: 3000 });
        } else {
          toast.error('Failed to add category', { position: 'top-right', autoClose: 3000 });
        }
      }
      fetchCategories();
      handleClose();
    } catch (error) {
      toast.error('Failed to save category', { position: 'top-right', autoClose: 3000 });
    }
  };

  const handleEdit = (category) => {
    setCategoryName(category.categoryname);
    setCurrentId(category.id);
    setIsEditing(true);
    handleOpen();
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${CategoryJobDeleteApi}/${deleteId}`);
      if (response.data == 'DELETE') {
        toast.success('Category deleted successfully', { position: 'top-right', autoClose: 3000 });
      } else if (response.data == 'NOTFOUND') {
        toast.error('Category not found', { position: 'top-right', autoClose: 3000 });
      } else {
        toast.error('Error deleting category', { position: 'top-right', autoClose: 3000 });
      }
      fetchCategories();
      handleConfirmClose();
    } catch (error) {
      console.error('Failed to delete category:', error.response ? error.response.data : error.message);
      toast.error('Failed to delete category', { position: 'top-right', autoClose: 3000 });
    }
  };

  const columns = [
    { label: 'Sr.No', field: 'sno', sort: 'asc' },
    { label: 'Category Name', field: 'categoryname', sort: 'asc' },
    {
      label: 'Edit',
      field: 'edit',
      sort: 'asc',
      width: 100,
    },
    {
      label: 'Delete',
      field: 'delete',
      sort: 'asc',
      width: 100,
    },
  ];

  const rows = categories.map((category, index) => ({
    sno: index + 1,
    categoryname: category.categoryname,
    edit: (
      <IconButton onClick={() => handleEdit(category)}>
        <EditIcon color="primary" />
      </IconButton>
    ),
    delete: (
      <IconButton
        onClick={() => {
          setDeleteId(category.id);
          handleConfirmOpen();
        }}
      >
        <DeleteIcon color="secondary" />
      </IconButton>
    ),
  }));

  const data = { columns, rows };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const confirmStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />

        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Job Category
          </h2>
          <Button
            className="ms-md-5 m-0 ms-3 mb-2 mb-md-4 mt-2 mt-md-4"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            Add Job Category
          </Button>
        </div>

        <div className="m-4 p-4 mb-5" style={{ backgroundColor: 'white', borderRadius: '8px' }}>
          <MDBDataTable striped bordered className="custom-datatable text-center" hover data={data} />  

        </div>

        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Typography variant="h6" component="h2">
              {isEditing ? 'Edit Job Category' : 'Add Job Category'}
            </Typography>
            <TextField
              className="mt-3"
              size="small"
              label="Category Name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              fullWidth
              required
            />
            <div className="mt-3 text-end">
              <Button variant="contained" color="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" className="ms-2" onClick={handleSave}>
                {isEditing ? 'Update' : 'Save'}
              </Button>
            </div>
          </Box>
        </Modal>

        <Modal open={confirmOpen} onClose={handleConfirmClose}>
          <Box sx={confirmStyle}>
            <Typography variant="h6" component="h2">
              Confirm Delete
            </Typography>
            <Typography className="mt-2">
              Are you sure you want to delete this category?
            </Typography>
            <div className="mt-3">
              <Button variant="contained" color="secondary" onClick={handleConfirmClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" className="ms-2" onClick={handleDelete}>
                Confirm
              </Button>
            </div>
          </Box>
        </Modal>
      </section>
    </>
  );
};

export default JobCategoryMaster;
