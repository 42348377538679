import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

function Rules() {
  return (
    <div style={{ minHeight: '100vh', position: 'relative' }}>
      <Navbar />
      {/* Header Start */}
      <div className="container-fluid bg-breadcrumbR">
        <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h3 className="text-white display-3 mb-4" style={{ textShadow: '2px 2px #ee1515' }}>प्रवेश व निवास यासंबंधी चे नियम </h3><br />
        </div>
      </div>
      {/* Header End */}
      {/* Packages Start */}
      {/* Packages End */}
      {/* Tour Booking Start */}
      <div className="main">
      </div>

      <div className="content" style={{ textAlign: 'center', margin: '0px 60px', borderRadius: '20px', marginTop: '30px', padding: '30px', backgroundColor: '#ebf2fc', paddingBottom: '100px' }}>
        <h6 style={{ lineHeight: '40px', textAlign: 'justify' }}>
          {/* Your rules content */}
          (१) दिगंबर जैन पालन करण्याची प्रतिज्ञा घेऊनच प्रवेश दिला
            जाईल. प्रवेश घेतेवेळी बोर्डिंग कमिटी वेळोवेळी ठरवेल त्या टर्मस् व डिपॉझिटची रक्कम विद्याथ्यनि भरली पाहिजे,
            <br /> (२) बोर्डिंगमध्ये प्रवेश मिळविण्यासाठी बोर्डिंगच्या छापील फॉर्मवर विद्याथ्यनि फॉर्म भरतेवेळी विचारलेली
            माहिती पूर्णपणे खरी दिली पाहिजे. पूर्णपणे न भरलेल्या अगर चुकीची माहिती भरलेल्या फॉर्मचा विचार केला जाणार
            नाही व तो रद्द करण्यात येईल.
            <br />(३) एखाद्या विद्यार्थ्याने चुकीची माहिती देऊन बोर्डिंगमध्ये प्रवेश मिळवलेले आढळून आल्यास त्यास
            बोर्डिंगमधून ताबडतोब काढून टाकण्यात येईल.
            <br />(4) बोर्डिंगचे वर्षे १ जून ते ३० एप्रिल पर्यंत समजणेत येईल. यामध्ये पहिली टर्म १ जून ते ३० ऑक्टोबर व
            दुसरी टर्म १ नोव्हेंबर ते ३० एप्रिल अशी समजणेत येईल. ज्या विद्यार्थ्यांची टर्म शैक्षणिक वर्षापासून सुरु होईल
            त्याची परिक्षा संपेपर्यंत टर्म समजणेची आहे. त्यापुढे
            समजणेचे नाही. पण सदरची टर्म जास्तीत जास्त ३० जून अखेर पर्यंतची समजणेची आहे. जादा कालावधीसाठी वेगळी फी भरावी
            लागेल.
            <br />(५) प्रवेश अर्जासोबत विद्यार्थ्याने आपल्या शाळेचे अगर महाविद्यालयाच शैक्षणिक
            संस्थेचे बोनाफाईड सर्टिफिकेट व गुणपत्रक हजर केले पाहिजे.
            <br /> (६) बोर्डिंगच्या प्रत्येक विद्यार्थ्याकडे आपले ओळखपत्र स्वतः जवळ असले पाहिजे.
            <br />(७) विद्यार्थ्यास बोर्डिंगमध्ये प्रवेश देणे अगर नाकारणे हे सुपरिटेंडेंट, सेक्रेटरी अगर त्यांच्या
            कमिटीच्या अधिकारात राहील.
            <br />(८) जैन धर्मरुढी, परंपरा, सवयी, पंथ इत्यादी अनुसार पुर्णतः शाकाहारी व निरव्यसनी विद्यार्थ्यांनाच प्रवेश
            दिला जाईल.
            <br />(९) बोर्डिंगमध्ये होणाऱ्या धार्मिक कार्यक्रमास, गुरुवारच्या आरतीस आणि धर्मसंस्कार शिबीरास गैरहजर
            राहणा-या विद्यार्थ्यास वसतिगृहातून काढून टाकणेस पात्र होईल, अशा विद्यार्थ्यास पुन्हा प्रवेश मिळणार नाही
            प्रत्येक विद्यार्थ्यांचे सर्व धार्मिक शिक्षणास व कार्यक्रमास नियमितपणे हजर राहिले पाहिजे व धार्मिक परीक्षा
            दिली पाहिजे.
            <br />(१०) बोर्डिंगमध्ये हौशाका केशवराव चौगुले अन्नपुर्णा भोजनगृह चालवले जाते.
            प्रत्येक विद्याध्यनि बोर्डिंगच्या भोजनालयात जेवण घेतले पाहिजे. जर एखाद्या। या बाबत काही जास्त अडचण असेल
            त्याने मॅनेजिंग कमिटीची पूर्व परवानगी घेऊनच त्यास काही अटींवर प्रवेश दिला जाईल. विद्याथ्यांन
            <br /> (११) विद्यार्थ्यास त्याच्या गैरवर्तनाबद्दल कोणतेही कारण न देता वसतिगृहातून काढून टाकणेचा अथवा अन्य
            शिक्षा करणेचा अधिकार सुपरिटेंडेंट व सेक्रेटरी यांना राहील.
            <br />(१२) बोर्डिंगच्या नियमामध्ये आवश्यक ते सर्व बदल करणेचा अथवा दुरुस्ती करणेचा अधिकार बोर्डिंग कमिटीस आहे.
            <br />(१३) विद्यार्थ्यांस बाहेर गावी जायचे असेल तर त्याने सुपरिटेंडेंट यांची पूर्व परवानगी घेऊनच जाणे आवश्यक
            आहे. विद्याथ्यनि त्याच्या ताब्यात असणाऱ्या साहित्याची योग्य काळजी घेतली पाहिजे. (उदा. काचा, इलेक्ट्रिक
            फिटींग, तावदाने, फर्निचर, बोर्डिंगच्या साहित्याचे नुकसान करणे) भिंतीत खिळे ठोकणे इ. गोष्टी करु नयेत तसे
            केलेचे अढळून आलेस त्याची नुकसान भरपाई विद्यार्थ्याकडून भरुन घेतली जाईल.
            <br />(१४) बोर्डिंगमध्ये कोणत्याही विद्यार्थ्यांच्या आप्तास अथवा पाहुण्यास रात्री १० चे नंतर राहाता येणार
            नाही. त्याचेसाठी निराळी गेस्टरुमची व्यवस्था केली जाईल. तथापि या गेस्टरुमध्ये राहणेसाठी सुपरिटेंडेंट यांची
            पूर्व परवानगी घेणे आवश्यक आहे.
            <br />(१५) बोर्डिंगमध्ये ग्रंथालयाची सोय आहे. विद्यार्थास त्याच्या बोर्डिंगच्या ओळखपत्रावर एका वेळी एकच पुस्तक
            मिळेल. पुस्तक घेतल्यापासून १० दिवसांच्या आत ग्रंथालयात पुस्तक जमा करायला पाहिजे. अन्यथा प्रत्येक दिवसाला १०
            रु. दंड आकारला जाईल. बोर्डिंगच्या आवारात फटाके वाजविण्यावर सक्त मनाई आहे.
            <br />(१६) वसतिगृहातील विद्यार्थी आजारी असल्यास अगर एखादा विद्यार्थी बराच काळ खोलीत राहत नसल्यास त्याची माहिती
            सुपरिटेंडेंट यांना त्या खोलीतील अन्य विद्यार्थ्यांनी लेखी कळविणे आवश्यक आहे.
            <br />(१७) विद्यार्थ्यांची खोली कोणत्याही वेळी तपासण्याचा अधिकार बोर्डिंगचे सुपरिटेंडेंट व सेक्रेटरी यांना
            आहे.
            <br />(१८) वसतिगृहात व आवारात मद्यपान व धुम्रपान करणेस विद्यार्थ्यांना सक्त मनाई आहे.
            <br />(१९) रात्री १० नंतर बोर्डिंगचे मेनगेट बंद कले जाईल. त्यानंतर विद्यार्थ्यांना बोर्डिंगबाहेर
            जाता येणार नाही व आतही प्रवेश मिळणार नाही.
            <br />(२०) प्रत्येक विद्याथ्यनि आपण बोर्डिंगमध्ये शिक्षण घेण्यासाठी व अभ्यास करण्यासाठी आलो आहोत याची जाणीव
            नेहमी ठेवली पाहिजे.
            <br />(२१) विद्याभ्यनि मोकळ्या वेळेत बोर्डिंगमधील इतर खोल्यामधून अगर बोर्डिंगच्या आवारात गप्पा मारत इतरत्र न
            फिरता त्याचा अभ्यासासाठी उपयोग करून घ्यावा.
            <br />(२२) प्रायेक दिवशी बोर्डिंग मार्फत सकाळी ६ वाजता प्रार्थना होईल त्यास सर्व विद्याथ्यांनी
            हजर राहिले पाहिजे.
            <br />(२३) विद्याथ्याने कोणत्याही राजकिय पक्षापासून अलीमपणे बोर्डिंगमध्ये राहिले पाहिजे.
            <br />(२४) विद्याव्यनि पाण्याचा वापर योग्य तितकाच करावा, अनाठाई पाणी वापरून बाया घालवू नये. पाण्याचा नळ व चावी
            यांची मोडतोड करु नये,
            <br />(२५) प्रत्येक विद्याथ्यनि बोर्डिंगमध्ये संडासचा योग्य वापर केला पाहिजे, बापर करण्यापूर्वी व वापर
            केल्यानंतर न चुकता पाणी टाकावे म्हणजे संडास साफ राहणेस मदत होईल. चाथरूमध्ये लघवी करु नये, त्यास मनाई आहे.
            <br />(२६) आपान्या खोलीतील केरकचरा कोंडाळ्यातच टाकावा च आपली खोली व बोर्डिंग
            स्वच्छ ठेवावे.
            <br />(२७) बोर्डिंगच्या आवारात रंगपंचमी खेळणेस तसेच एखाद्या विद्यार्थ्याला त्याच्या
            इच्छेविरुध्द जबरदस्तीने रंग लावणेस सक्त मनाई आहे.
            <br />(२८) बोर्डिंगच्या नोटीस बोर्डावर सुपरिटेंडेंट बांच्या पूर्वपरवानगीशिवाय विद्यार्थ्यांना
            कोणतीही सूचना अगर माहिती लिहिणेस सक्त मनाई आहे.
            <br />(२९) बोर्डिंगतर्फे विद्यार्थ्याचे गॅदरिंग कोणत्याही परिस्थितीत घेतले जाणार नाही. याची
            विद्यार्थानी नोंद घ्यावी. त्याबाबत तक्रारही चालणार नाही.
            <br />(३०) विद्यार्थ्याने आपल्या खोलीत कोणत्याही प्रकारचे पोस्टर्स, चित्रे चिकटवणेचे नाहीत.
            तसेच खोलीच्या भिंतीना मोठे खिळे ठोकणेचे नाहीत.
            <br />(३१) बोर्डिंगच्या मालकीच्या कोणत्याही वस्तूची नासधूस अगर नुकसानी करु नये, केलेस
            किंमत संबंधित विद्यार्थ्यांकडून जमा करुन घेतली जाईल.
            <br />(३२) बाथरुममध्ये कागद, कापडी बोळे व दगड विटा वगैरे काहीही टाकू नये. तसेच जेवणाचे डबे बाथरुममध्ये धुवू
            नयेत.
            <br />(३३) प्रत्येक विद्याथ्यनि जेवणाच्या डब्यातील शिल्लक राहिलेले अन्न बोडींगने पुरविलेल्या डब्यातच टाकावे.
            <br />(३४) आरोग्याच्या दुहीने विद्याथ्यनि बोर्डिंगच्या आवारात व इतरत्र कोठेही
            तंबाखू खाजून अगर पान खावून धुंकू नये, (१५) विद्याथ्यांनी बोर्डिंगच्या मेन गेट वरील नियमांचे पालन केले
            पाहिजे.
            <br />(३६) बोर्डिंगच्या पदाधिका-याविरुध्द विद्यार्थ्यांस संप, चेराव घालेजेस अगर उपोषण करण्यास सक्त मनाई असून
            मने भडकवण्याच्या विरुध्द कडक कारवाई केली जाईल
            प्रसंगी वसतिगृहातून काढून टाकले जाईल.
            <br />(३७) विद्याध्यनि आपले बाहन (सायकल, मोटर सायकल वगैरे) बोर्डिंगमध्ये सोय केलेल्या ठिकाणी व्यवस्थित ठेवले
            पाहिजे. शिस्तीच्या दृष्टीने इतरत्र वाहन ठेवू नये.
            <br />(३८) बोर्डिंगच्या आवारात विद्याथ्यनि क्रिकेटचा खेळ खेळू नये, तसेच पते, जुगार बर्गर बोर्डिंगमध्ये
            खेळण्यास सक्त मनाई आहे.
            <br />(३९) बोर्डिंगमध्ये विद्याथ्यांच्या सोईसाठी आप्पासाहेब गणपती कागवाडे दवाखाना चालविला जातो.
            विद्यार्थ्यांनी याचा लाभ घ्यावा.
            <br />(४०) वसतीगृहात राहणा-या विद्यार्थ्यांच्या वडिलांनी अगर पालकांनी विद्यार्थ्यांच्या
            शैक्षणिक काळात त्याच्या अभ्यासाबद्दल व वर्तणुकीबद्दल बोर्डिंगचे सुपरिटेंडेंट यांना किमान महिन्यातून एक वेळ
            भेटून चौकशी करणे व सूचना देणे आवश्यक आहे. तसे बडिलांनी अगर पालकांनी न केल्यास त्याची जबाबदार संस्थेवर राहणार
            नाही. ४१) गैरवर्तणूक, गैरभाषा, गैरहालचाली अथवा बोर्डिंग नियमभंग करणाऱ्या विद्याथ्यांची
            भरलेली टर्म फी त्यास परत दिली जाणार नाही.
            <br />(४२) विद्यार्थ्यांच्या कोणत्याही वस्तूच्या सुरक्षिततेची जबाबदारी संस्थेवर नाही. विद्याथ्यांनी
            आपल्या वस्तू काळजीपूर्वक आपणच सांभळणेच्या आहेत. व विषयाच्या सर्व परिक्षांना बसले पाहिजे व त्याने
            <br />(४३) प्रत्येक विद्याथ्यनि ज्या त्या वर्गाच्या गुणपत्रक बोर्डिंगच्या कार्यालयात सादर कले पाहिजे. तसे न
            केलेस पुढील वेळेस प्रवेश देताना विचार केला जाईल.
            <br />(४४) बोर्डिंगचे लिखित नियम, उपनियम यामध्ये वेळोवेळी होणारे बदल याची विद्याच्यानि नोंद घेऊन त्याप्रमाणे
            वागले पाहिजे.
            <br />(४५) ज्यांना सुट्टीत रहावयाचे असेल त्यांनी खालील गोष्टींची पूर्तता करणे जरुरीचे आहे. (अ) टर्म
            संपण्यापूर्वी ह्याबाबत परवानगीसाठी अर्ज करणे व परवानगी घेणे,
            (ब) त्यासाठी मॅनेजिंग कमिटीने ठरविलेली रक्कम आगाऊ भरणे (क) टर्म संपलेनंतर विद्याथ्यांने वसतिगृह सोडले
            पाहिजे.
            (ड) सुट्टीत राहणे हा विद्यार्थ्यांचा हक नसून त्यासाठी परवानगी देणे अथवा न देणे से चेअरमन, सेक्रेटरी,
            सुपरिटेंडेंट यांचे अधिकारात राहील.
            (इ) उन्हाळ्याचे सुट्टीत १ मे पासून विद्याथ्याँनी वसतिगृहात राहू नये. अभ्यासाची अगर परिक्षेची अडचण असेल तर
            तीची आवश्यकता व खात्री पटवून दिलेस त्या विद्यार्थ्यांस बोर्डिंगमध्ये राहणेबद्दल जरुर ती परवानगी दिली जाईल व
            त्याचे योग्य भाडे आकारले जाईल.
            <br />(४६) प्रत्येक खोलीमध्ये बोर्डिंगने दिलेल्या बल्ब (ट्युब वापरणेच्या आहेत, ज्यादा कनेक्शन व बल्ब वापरणेचे
            नाहीत.)
            <br />(४७) प्रत्येक विद्याथ्यनि दक्षिण भारत जैन सभेचे कमीत कमी ५ मेंबर्स मिळवून द्यावेत.
            <br />(४८) विद्यार्थ्यास बोर्डिंगमध्ये प्रवेश नाकारणेत आला तर त्याने भरलेल्या रकमेपैकी प्रवेश
            रक्कम वजा करून त्यास बाकीची रक्कम आदा केली जाईल.
            <br />(४९) बोर्डिंगमध्ये प्रवेश घेतल्यानंतर विद्यार्थ्यांस काही कारणामुळे प्रवेश नको असेल आणि
            प्रवेश घेतलेपासून १५ दिवसाचे आत त्याने तसे लेखी कळविलेस त्या विद्यार्थ्यांस प्रवेश फी व द. भा. जैन सभेची
            मेंबर वर्गणीची रक्कम वजा करुन बाकीची रक्कम आदा
            केली जाईल.
            <br />(५०) बोर्डिंगमध्ये प्रामुख्याने जे विद्यार्थी दोन्हीही टर्म करीता राहणार असतील त्या
            विद्यार्थ्यांना प्रथम दिला जाईल. त्यानंतर जागा शिल्लक असतील तरच एका टर्मच्या विद्याथ्यर्थ्यांना प्रवेश दिला
            जाईल.
            <br />(५१) ज्या विद्यार्थ्यांस बोर्डिंगमध्ये प्रवेश दिला असेल त्या विद्यार्थ्यांना ज्या खोल्या दिल्या असतील
            त्याच खोल्यामध्ये विद्यार्थ्यांने राहण्याचे आहे. अन्यथा सुपरिटेंडेंट यांच्या
            परवानगीशिवाय परस्पर जे विद्यार्थी खोल्या बदलत असतील त्या विद्यार्थ्यांचा प्रवेश रह केला जाईल.
            <br />(५२) प्रवेश अर्जाप्रमाणे ज्या विद्याथ्यर्थ्यांला बोर्डिंगमध्ये प्रवेश दिला असेल त्याच विद्यार्थ्यांला
            बोर्डिंगमध्ये राहता येईल. त्याच्या नांवावर इतरांना राहता येणार नाही. जर असा एखादा विद्यार्थी रहात असेल तर
            त्या खोलीतील विद्यार्थ्यांवर योग्य ती कडक कारवाई केली जाईल.
            <br />(५३) वसतिगृहातील विद्यार्थ्याने स्वतःची खोली स्वच्छ व टापटीप ठेवावी. त्याचप्रमाणे
            खोलीतील लाईट कनेमान्स, अनावश्यक चालू ठेवू नये, झोपतेवेळी व बाहेर जातेवेळ लाईट बंद करणे आवश्यक आहे.
            विद्याव्योंने पंखा, इखी, रेडिओ, हिटर अगर तत्सम विज्ञ उपकरणे वापर नयेत, तसे आढळून आल्यास ते साहित्य जम करून
            कारवाई केली जाईल,
            <br />(५४) वसतिगृहातील राहणान्या विद्याथ्यनि जर एखादी स्वतःची वस्तू हरविली असल्यास अगर चोरीस गेली असल्यास
            त्याबात सत्वर बोर्डिंगच्या कार्यालयाकडे लेखी तक्रार अर्ज देणेचा आहे जर एखाद्या विद्याथ्यनि बराच काळ तक्रार
            केली नसल्यास व पुढे हरवलेल्या अगर चोरीस गेलेल्या वस्तूबाबत वाद उपस्थित झालेस व निर्णय देता येणे अशक्य झालेस
            ती वस्तू बोर्डिंगच्या मालकीची समजली जाईल.
            <br />(५५) बोर्डिंगच्या संस्थेमार्फत वसतिगृहात रहात असलेल्या गरीब, होतकर व हुशार विद्याथ्यनि जर मेडिकल अगर
            इंजिनिअरिंगला प्रवेश मिळविला असेल तर त्या विद्याथ्यांना कर्जाऊ शिष्यवृत्ती देण्याची व्यवस्था करण्यात आलेली
            आहे.
            <br />(५६) विद्याथ्यांनी आपली परिक्षा संपल्यानंतर ४ दिवसांच्या आत आपले खोलीतील सर्व साहित्य काढून घेऊन खोली
            सोडल्याचा फॉर्म भरुन खोली रितसर बोर्डिंगच्या ताव्यात द्यावी. या नियमांचे पालन करणान्या विद्याथ्यांलाच
            डिपॉझिट रक्कम मिळेल.
            अन्यथा सदर डिपॉझीटची रक्कम बोर्डिंगच्या अनामत खातेस जमा केली जाईल.
            <br />(५७) ज्या विद्यार्थ्यांनी बोर्डिंगमध्ये दोन्हीही टर्मकरिता जर प्रवेश घेतला असेल त्या
            विद्यार्थ्यांला १५ जुलै नंतर अगर दुसऱ्या टर्ममध्ये जर रहाक्याचे नसल्यास त्या विद्याथ्याँस मध्यंतरी अगर
            दुस-या टर्ममध्ये बोर्डिंग सोडल्याबद्दल टर्म फी ची रक्कम परत दिली जाणार नाही.
            <br />(५८) बोर्डिंगमध्ये विद्यार्थ्यांला प्रवेश दिल्यानंतर त्या खोलीतील प्रत्येक विद्याथ्यांनी शाळा अगर
            कॉलेजमधील अल्प सुट्टीत अगर दिर्घ सुट्टीत परस्पर बोडींग व्यतिरिक्त इतरांना खोलीत जर राहण्यास दिल्याचे
            आढळल्यास त्या खोलीतील सर्व विद्यार्थ्यांवर
            नियमबाह्य वर्तन केले म्हणून योग्य ते इलाज केले जातील.
            <br />(५९) विद्याथ्याने आपले वर्तन इतर विद्याथ्यांशी, सुपरिटेंडेंट, सेक्रेटरी, चेअरमन व मॅनेजिग
            कमिटीतील पदाधिकाऱ्यांशी सौजन्याने, शिस्तपूर्ण आणि सहकार्याचे ठेवावे.
            <br />(६०) वसतिगृहातील विद्यार्थ्यांना त्याचप्रमाणे शेजारील खोलीतील विद्यार्थ्यांना आपल्या वर्तनाचा त्रास होऊ
            नये याची खबरदारी घेतली पाहिजे.
            <br />(६१) ज्या विद्यार्थ्यांच्या परिक्षा झाल्या आहेत. त्यांनी सत्वर खोलीचा ताबा वसतिगृह व्यवस्थापनाकडे
            देण्याचा आहे. तसा जर दिला गेला नाही तर व्यवस्थापनाकडून सदर खोलीचे कुलुप तोडून ताबा घेतला जाईल. त्याबाचत
            कोणतीही तक्रार ऐकली जाणार नाही.
            <br />(६२) विद्याथ्यांनी मौल्यवान वस्तू बोर्डिंगवर वापरु नयेत, अशा वस्तू हरविलेस अगर चोरीस गेल्यास बोर्डिंग
            जबाबदार राहणार नाही.
            <br />(६३) विद्यार्थ्यांनी जिमचा वापर योग पद्धतीने करावा, गैरवर्तणूक अढळेस सदर विद्यार्थ्यांची जिमची सुविधा
            बंद करण्यात येईल.
            <br />(६४) मोबाईल वर गेम खेळताना, निरर्थक व्हिडीओ बघताना तसेच टाईमपास करत असल्यास अशा विद्याथ्यांवर कडक कारवाई
            करण्यात येईल.
            <br />(६५) गरम पाण्याची सोय पहाटे ५.०० वाजलेपासून सकाळी ८.०० वाजेपर्यंतच उपलब्ध राहील. वरील प्रमुख नियम व
            वेळोवेळी करण्यात येणारे नियम विद्यार्थ्यांनी अत्यंत काटेकोरपणे पाहिले पाहिजेत. नियमबाह्य वर्तन करणाऱ्या
            विद्याथ्र्यांविरुध्द इलाज केले जातील. प्रसंगी बोर्डिंगमधून काढले जाईल.
            <br />
        </h6>
        <form id="redirectForm" action="/Rform1" method="get">
          <button type="submit" style={{ backgroundColor: 'darkblue', border: 'none', padding: '8px 18px', borderRadius: '10px', color: 'white', fontSize: '18px' }}>Next</button>
        </form>
      </div>
      {/* Tour Booking End */}
      {/* Subscribe Start */}
      {/* Subscribe End */}
      <Footer style={{ position: 'absolute', bottom: '0', width: '100%' }} />
    </div>
  )
}

export default Rules;
