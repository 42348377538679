import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import ReportNavbar from './ReportNavbar';
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import {  DatePicker, Select } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ReportEventLeaveReport, ReportEventlist, ReportLeaveRegularReport, ReportMessLeaveReport } from '../../ApiFile';
const { RangePicker } = DatePicker;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

const StudentLeaveReport = () => {

//========Code for Regular wise leave =================================================

const [members, setMembers] = useState([]);
const [dateRange, setDateRange] = useState([]);
const [regularWise, setRegularWise] = useState(true);

const handleDateChange = (dates) => {
  setDateRange(dates);
};

const handleRegularWiseShow = () => {
  const [dateFrom, dateTo] = dateRange;

  const objData={
    fromdate: dateFrom ? dateFrom.format('DD-MM-YYYY') : null,
    todate: dateTo ? dateTo.format('DD-MM-YYYY') : null
  }

  axios.post(ReportLeaveRegularReport, objData)
  .then(response => {
    setMembers(response.data);
  })
  .catch(error => console.error('Error fetching member list:', error));
};

const rows = members.map((member, index) => ({
    serial: index + 1,
    name: member.name,
    block_id: member.block_id,
    room_id: member.room_id,  
    fromdate: member.fromdate,
    todate: member.todate,
    reason: member.reason,
    mob_no: member.mob_no,
}));

const data = {
  columns: [
    { label: 'Serial No', field: 'serial', sort: 'asc' },
    { label: 'Student Name', field: 'name', sort: 'asc' },
    { label: 'Block No', field: 'block_id', sort: 'asc' },
    { label: 'Room No', field: 'room_id', sort: 'asc' },
    { label: 'Date From', field: 'fromdate', sort: 'asc' },
    { label: 'Date To', field: 'todate', sort: 'asc' },
    { label: 'Reason', field: 'reason', sort: 'asc' },
    { label: 'Contact No', field: 'mob_no', sort: 'asc' },
  ],
  rows: rows
};

// Calculate totals====
const totalCount = members.length;

const handleRegularWise =()=>{
    setRegularWise(true);
    setEventWise(false);
    setMessWise(false);
}

const handleExcelData = () => {
  const ws = XLSX.utils.json_to_sheet(rows);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Regular_Student_Leave_Data.xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handlePdfData = () => {
  const doc = new jsPDF();
  const columns = data.columns.map((col) => col.label);
  const rowsData = rows.map((row) => [ row.serial,row.name,row.block_id,row.room_id,row.fromdate,row.todate,row.reason,row.mob_no]);

  const title = 'Regular Student Leave Data';

  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);

  doc.autoTable({
    head: [columns],
    body: rowsData,
    startY: 20,
  });

  const blob = doc.output('blob');
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Regular_Student_Leave_Data.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};



//========Code for Event Wise leave=======================================================

const [members1, setMembers1] = useState([]);
const [events, setEvents] = useState([]);
const [event, setEvent] = useState(null);
const [eventWise, setEventWise] = useState(false);

// Fetch the events data
useEffect(() => {
  axios.get(ReportEventlist)
    .then(response => setEvents(response.data))
    .catch(error => console.error('Error fetching events:', error));
}, []);

const handleEventChange = (selectedOption) => {
  setEvent(selectedOption);
};

const handleEventWiseShow = () => {

  axios.get(`${ReportEventLeaveReport}/${event}`)
    .then(response => setMembers1(response.data))
    .catch(error => console.error('Error fetching member list:', error));
};

const rows1 = members1.map((member, index) => ({
  serial: index + 1,
  event_date: member.event_date,
  discription: member.discription,
  name: member.name,
  block_id: member.block_id,
  room_id: member.room_id, 
}));

const data1 = {
  columns: [
    { label: 'Serial No', field: 'serial', sort: 'asc' },
    { label: 'Event Date', field: 'event_date', sort: 'asc' },
    { label: 'Discription', field: 'discription', sort: 'asc' },
    { label: 'Student Name', field: 'name', sort: 'asc' },
    { label: 'Block No', field: 'block_id', sort: 'asc' },
    { label: 'Room No', field: 'room_id', sort: 'asc' },
  ],
  rows: rows1
};

// Calculate totals====
const totalCount1 = members1.length;


const handleEventWise =()=>{
  setRegularWise(false);
  setEventWise(true);
  setMessWise(false);
}

const handleExcelData1 = () => {
  const ws = XLSX.utils.json_to_sheet(rows1);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Event_Student_Leave_Data.xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handlePdfData1 = () => {
  const doc = new jsPDF();
  const columns = data1.columns.map((col) => col.label);
  const rowsData = rows1.map((row) => [row.serial,row.event_date,row.discription,row.name,row.room_id,row.block_id]);

  const title = 'Event Student Leave Data';

  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);

  doc.autoTable({
    head: [columns],
    body: rowsData,
    startY: 20,
  });

  const blob = doc.output('blob');
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Event_Student_Leave_Data.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};


//========Code for Mess Wise leave=================================================

const [members2, setMembers2] = useState([]);
const [dateRange2, setDateRange2] = useState([]);
const [messWise, setMessWise] = useState(false);

const handleDateChange2 = (dates) => {
  setDateRange2(dates);
};

const handleMessWiseShow = () => {
  const [dateFrom, dateTo] = dateRange;

  const objData={
    fromdate: dateFrom ? dateFrom.format('DD-MM-YYYY') : null,
    todate: dateTo ? dateTo.format('DD-MM-YYYY') : null
  }

  axios.post(ReportMessLeaveReport, objData)
  .then(response => setMembers2(response.data))
  .catch(error => console.error('Error fetching member list:', error));
};

const rows2 = members2.map((member, index) => ({
    serial: index + 1,
    name: member.name,
    block_id: member.block_id,
    room_id: member.room_id,  
    fromdate: member.fromdate,
    todate: member.todate,
    reason: member.reason,
}));

const data2 = {
  columns: [
    { label: 'Serial No', field: 'serial', sort: 'asc' },
    { label: 'Student Name', field: 'name', sort: 'asc' },
    { label: 'Block No', field: 'block_id', sort: 'asc' },
    { label: 'Room No', field: 'room_id', sort: 'asc' },
    { label: 'Date From', field: 'fromdate', sort: 'asc' },
    { label: 'Date To', field: 'todate', sort: 'asc' },
    { label: 'Reason', field: 'reason', sort: 'asc' },
  ],
  rows: rows2
};

// Calculate totals====
const totalCount2 = members2.length;

const handleMessWise =()=>{
    setRegularWise(false);
    setEventWise(false);
    setMessWise(true);
}

const handleExcelData2 = () => {
  const ws = XLSX.utils.json_to_sheet(rows2);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Mess_Student_Leave_Data.xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handlePdfData2 = () => {
  const doc = new jsPDF();
  const columns = data2.columns.map((col) => col.label);
  const rowsData = rows2.map((row) => [ row.serial,row.name,row.block_id,row.room_id,row.fromdate,row.todate,row.reason]);

  const title = 'Mess Student Leave Data';

  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);

  doc.autoTable({
    head: [columns],
    body: rowsData,
    startY: 20,
  });

  const blob = doc.output('blob');
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Mess_Student_Leave_Data.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};


//===================================================================================


  return (
  
      <>
    <Sidebar />
    <section className="home-section pb-5">
      <NavLogo />
      <ReportNavbar />
      
      <Row className="m-0 p-0 pt-4 px-3 ">
            <Col
                className="rounded text-center py-2"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    border: "none",
                    alignItems: "center",
                    alignContent: "center",
                    color: "maroon",
                }}
            >    
                <div><span style={{color:"maroon",fontSize:"18px"}}>Students Leave Report</span></div>
                <div className='d-flex'>
                  <button className='mx-1 px-2 py-1' style={{color:"white",backgroundColor:"grey",fontSize:"13px",}} onClick={handleRegularWise}>Regular</button>
                  <button className='mx-1 px-2 py-1' style={{color:"white",backgroundColor:"grey",fontSize:"13px",}} onClick={handleEventWise}>Event</button>
                  <button className='mx-1 px-2 py-1' style={{color:"white",backgroundColor:"grey",fontSize:"13px",}} onClick={handleMessWise}>Mess</button>
                </div>
                
            </Col>
        </Row>

        {regularWise && (

              <div className="row m-0 p-2 p-md-3 pb-5">
                  <div className="row m-0  pb-5" style={{backgroundColor:"white",borderBottom:"1px solid grey"}}>

                  <div className="col-12 text-start my-2"> <span style={{backgroundColor:"#808080",color:"white",padding:"0px 25px",borderRadius:"5px"}}>Regular Report</span> </div>

                  <div className="col-12 col-lg-3"></div>
                    <div className="col-12 col-md-8 col-lg-4 pt-2 pt-md-0">
                        <label style={{fontWeight:"normal",fontSize:"13px"}}>Select Date :</label>
                        <RangePicker className='w-100' format={dateFormatList}  onChange={handleDateChange} />
                    </div>
                    <div className="col-12 col-md-4 col-lg-2 pt-4">
                      <Button variant="contained" color="primary" style={{backgroundColor:"#1685f3",padding:"5px 10px"}} onClick={handleRegularWiseShow}>Show</Button>
                    </div>
                    <div className="col-12 col-lg-3"></div>
                  </div>

                  <div className="row m-0 p-2 mb-5"  style={{backgroundColor:"white"}}>

                  <div className='col-12 text-end py-2'>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#3b9cfd",color:"white"}}  onClick={() => handleExcelData()}>Excel</Button>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#136fcb",color:"white"}} onClick={() => handlePdfData()}>Pdf</Button>
                  </div>

                      <MDBDataTable
                        striped
                        bordered
                        className="custom-datatable"
                        small
                        data={data}
                      />

                      <div className="col-12 text-end p-1" style={{backgroundColor:"black",color:"white"}}>
                          <div className="row border m-0">
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Students: {totalCount}</span>
                            </div>
                          </div>                        
                      </div>


                  </div>
              </div>
        )}

        {eventWise && (

              <div className="row m-0 p-2 p-md-3 pb-5">
                  <div className="row m-0 pb-5" style={{backgroundColor:"white",borderBottom:"1px solid grey"}}>

                    <div className="col-12 text-start my-2"> <span style={{backgroundColor:"#808080",color:"white",padding:"0px 25px",borderRadius:"5px"}}>Event Report</span> </div>

                    <div className="col-12 col-lg-3"></div>
                    <div className="col-12 col-md-6 col-lg-4 pt-2 pt-md-0">
                        <label style={{fontWeight:"normal",fontSize:"13px"}}>Select Event :</label>
                        <Select
                            className="w-100"
                            placeholder="Select Event"
                            options={events.map(event => ({ value: event.event_id, label: `${event.event_date} - ${event.discription}` }))}
                            onChange={handleEventChange}
                          />
                    </div>

                    <div className="col-12 col-lg-2 pt-4">
                      <Button variant="contained" color="primary" style={{backgroundColor:"#1685f3",padding:"5px 10px"}} onClick={handleEventWiseShow}>Show</Button>
                    </div>
                    <div className="col-12 col-lg-3"></div>


                  </div>

                  <div className="row m-0 p-2 mb-5"  style={{backgroundColor:"white"}}>

                  <div className='col-12 text-end py-2'>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#3b9cfd",color:"white"}}  onClick={() => handleExcelData1()}>Excel</Button>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#136fcb",color:"white"}} onClick={() => handlePdfData1()}>Pdf</Button>
                  </div>

                      <MDBDataTable
                        striped
                        bordered
                        className="custom-datatable"
                        small
                        data={data1}
                      />

                      <div className="col-12 text-end p-1" style={{backgroundColor:"black",color:"white"}}>
                          <div className="row border m-0">
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Events: {totalCount1}</span>
                            </div>
                          </div>                        
                      </div>


                  </div>
              </div>
        )}

        {messWise && (

          <div className="row m-0 p-2 p-md-3 pb-5">
                  <div className="row m-0  pb-5" style={{backgroundColor:"white",borderBottom:"1px solid grey"}}>

                  <div className="col-12 text-start my-2"> <span style={{backgroundColor:"#808080",color:"white",padding:"0px 25px",borderRadius:"5px"}}>Mess Report</span> </div>

                  <div className="col-12 col-lg-3"></div>
                    <div className="col-12 col-md-8 col-lg-4 pt-2 pt-md-0">
                        <label style={{fontWeight:"normal",fontSize:"13px"}}>Select Date :</label>
                        <RangePicker className='w-100' format={dateFormatList}  onChange={handleDateChange2} />
                    </div>
                    <div className="col-12 col-md-4 col-lg-2 pt-4">
                      <Button variant="contained" color="primary" style={{backgroundColor:"#1685f3",padding:"5px 10px"}} onClick={handleMessWiseShow}>Show</Button>
                    </div>
                    <div className="col-12 col-lg-3"></div>
                  </div>

                  <div className="row m-0 p-2 mb-5"  style={{backgroundColor:"white"}}>

                  <div className='col-12 text-end py-2'>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#3b9cfd",color:"white"}}  onClick={() => handleExcelData2()}>Excel</Button>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#136fcb",color:"white"}} onClick={() => handlePdfData2()}>Pdf</Button>
                  </div>

                      <MDBDataTable
                        striped
                        bordered
                        className="custom-datatable"
                        small
                        data={data2}
                      />

                      <div className="col-12 text-end p-1" style={{backgroundColor:"black",color:"white"}}>
                          <div className="row border m-0">
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Students: {totalCount2}</span>
                            </div>
                          </div>                        
                      </div>


                  </div>
              </div>
            )}

    </section>
    </>
  )
}


export default StudentLeaveReport