import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Pagination } from '@mui/material';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Box, Select, MenuItem, Grid, Typography, Dialog, DialogTitle, DialogContent, DialogActions, FilledInput } from '@mui/material';
import { TextField } from "@mui/material";
import { FormControl, InputLabel } from "@mui/material";
import { Modal } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { CategoryFindAllApi, JobPostFindAll } from '../../ApiFile';


const API_URL = 'http://192.168.29.112:8081/api';

const Jontable = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobTitle, setJobTitle] = useState('');
  const [industryName, setIndustryName] = useState('');
  const [jobType, setJobType] = useState('');
  const [location, setLocation] = useState('');
  const [companyContact, setCompanyContact] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [vacancy, setVacancy] = useState('');
  const [experience, setExperience] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [jobCategoryId, setJobCategoryId] = useState('');

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      setLoading(true);
      const response = await axios.get(JobPostFindAll);
      setJobs(response.data);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = (job) => {
    setSelectedJob(job);
    setJobTitle(job.job_title);
    setIndustryName(job.industry_name);
    setJobType(job.job_type);
    setJobCategoryId(job.job_category);
    setLocation(job.address);
    setCompanyContact(job.contact);
    setCompanyEmail(job.email_id);
    setJobDescription(job.discription);
    setExperience(job.experience);
    setVacancy(job.vaccancy);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUpdateJob = async () => {
    try {
      setLoading(true);
      const response = await axios.put(`${API_URL}/jobpost/update/${selectedJob.id}`, {
        job_category: selectedJob.job_category,
        job_type: jobType,
        job_title: jobTitle,
        industry_name: industryName
        // Add other fields as needed
      });
      console.log(response.data);
      fetchJobs();
      handleCloseModal();
    } catch (error) {
      console.error('Error updating job:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (jobId) => {
    // Delete API call
    try {
      setLoading(true);
      const response = await axios.delete(`${API_URL}/jobpost/delete/${jobId}`);
      console.log(response.data);
      fetchJobs();
    } catch (error) {
      console.error('Error deleting job:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredJobs = jobs.filter((job) => {
    return Object.values(job).some((value) => value.toString().toLowerCase().includes(searchQuery.toLowerCase()));
  });

  const paginatedJobs = filteredJobs.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const totalPages = Math.ceil(filteredJobs.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(CategoryFindAllApi);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
      <Row className="m-0 p-0 pt-3 px-2 ">
              <Col
                className=" rounded text-center py-3"
                style={{
                  backgroundColor: "white",
                  border: "0px",
                  alignItems: "center",
                  alignContent: "center",
                  color: " rgb(47, 79, 79)",
                }}
              >
      <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto', border: '1px solid lightgray' }}>
        <Table sx={{ minWidth: 640 }} aria-label="simple table" stickyHeader>
          <TableHead style={{ backgroundColor: '#B0E0E6' }}>
            <TableRow>
              <TableCell>Job Title</TableCell>
              <TableCell align="left">Industry Name</TableCell>
              <TableCell align="left">Job Type</TableCell>
              <TableCell align="left">Job Category</TableCell>
              <TableCell align="left">Location</TableCell>
              <TableCell align="left">Company Contact</TableCell>
              <TableCell align="left">Company Email</TableCell>
              <TableCell align="left">Vacancies</TableCell>
              <TableCell align="left">Experience</TableCell>
              <TableCell align="left">Job Description</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: '#E1EBEE' }}>
            {paginatedJobs.map((job) => (
              <TableRow key={job.id}>
                <TableCell component="th" scope="row">
                  {job.job_title}
                </TableCell>
                <TableCell align="left">{job.industry_name}</TableCell>
                <TableCell align="left">{job.job_type}</TableCell>
                <TableCell align="left">{job.categoryname}</TableCell>
                <TableCell align="left">{job.address}</TableCell>
                <TableCell align="left">{job.contact}</TableCell>
                <TableCell align="left">{job.email_id}</TableCell>
                <TableCell align="left">{job.vaccancy}</TableCell>
                <TableCell align="left">{job.experience}</TableCell>
                <TableCell align="left">{job.discription}</TableCell>
                <TableCell align="left">
                  <Button onClick={() => handleOpenModal(job)} style={{ color: 'green', fontSize: '20px', padding: '5px' }}>
                    <FaEdit />
                  </Button>
                  <Button onClick={() => handleDelete(job.id)} style={{ color: 'red', fontSize: '20px', padding: '4px' }}>
                    <FaTrash />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} style={{ margin: '10px', float: 'right', padding: '5px', boxShadow: 'grey 2px 1px 4px 3px', borderRadius: '25px' }} />
      <style jsx>{`
        ::-webkit-scrollbar {
          width: 4px;
          height: 10px;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #ccc;
          border-radius: 10px;
        }

        ::-webkit-scrollbar-track {
          background-color: #f0f0f0;
        }
      `}</style>

      <Modal open={showModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: '5px',
            width: '90%', // Adjust width as per your requirement
            maxWidth: '500px', // Maximum width for larger screens
            marginTop: '30px',
          }}
        >
          <Grid container spacing={0.2}>
            <Grid item xs={12} sm={12} style={{ backgroundColor: "#5F9EA0" }}>
              <h2 style={{ textAlign: 'center', color: 'black' }}>Job Details</h2>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Job Title"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Industry Name"
                value={industryName}
                onChange={(e) => setIndustryName(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal" required>
                <InputLabel>Job Type</InputLabel>
                <Select
                  value={jobType}
                  onChange={(e) => setJobType(e.target.value)}
                  label="Job Type"
                >
                  <MenuItem value="1">Full Time</MenuItem>
                  <MenuItem value="2">Part Time</MenuItem>
                  <MenuItem value="3">Internship</MenuItem>
                  <MenuItem value="4">Contract</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal" required>
                <InputLabel>Job Category</InputLabel>
                <Select
                  value={jobCategoryId}
                  onChange={(e) => setJobCategoryId(e.target.value)}
                  label="Job Category"
                >

                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>{category.categoryname}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Company Contact"
                value={companyContact}
                onChange={(e) => setCompanyContact(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Company Email"
                value={companyEmail}
                onChange={(e) => setCompanyEmail(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Vacancies"
                value={vacancy}
                onChange={(e) => setVacancy(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Experience"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Job Description"
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCloseModal}
              style={{ backgroundColor: 'grey' }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateJob} style={{ backgroundColor: 'blue' }}
            >
              Update Job
            </Button>
          </DialogActions>
        </Box>
      </Modal>
      </Col>
      </Row>
  );
};

export default Jontable;