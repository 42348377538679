import React from 'react'
import SideNavbarStudentReg from './SideNavbarStudentReg'
import NavBarStudentReg from './NavBarStudentReg'
import { Link } from 'react-router-dom'

const PrivacyPolacyReg = () => {
  return (
    <>
     <SideNavbarStudentReg/>
      <section className="home-section">
        <NavBarStudentReg/> 

        <div className="my-4 px-md-4 px-3  mx-0" style={{ textAlign: 'center'}}>
          <div className="content" style={{ textAlign: 'left', margin: '10px', borderRadius: '15px', padding: '30px', backgroundColor: '#dafddc' }}>
              <h3 style={{color: '#45a049'}} className='mb-3'>Privacy Policy</h3>
              <p>We respect the privacy of those who visit our website. In accordance with this, we have created this policy in order to demonstrate our privacy commitment to our users.</p>
              <h3 style={{ color: 'black' }}>What this Privacy Policy Covers?</h3>
              <p>This Privacy Policy covers our treatment of personally identifiable information that we collect when you are on our site, and when you use our services. This policy also covers our treatment of any personally identifiable information that third parties share with us.</p>
              <p>This policy does not apply to the practices of organizations that we do not own or control, or to people that we do not employ or manage.</p>
              <h3 style={{ color: '#black' }}>Information Collection and Use</h3>
              <p>We collect personally identifiable information when you register on our website, when you use our services, and when you visit our pages. We may also receive personally identifiable information from third parties.</p>
              <p>When you register with us, we ask for your name, email address, zip code, occupation, industry, and personal interests. Once you register with us and sign in to our services, you are not anonymous to us.</p>
              <p>The email or contact number address you submit to receive information from bduedu.in will never be disclosed to any third party. All email that you receive will be from the domain names bduedu.in.</p>
              <p>Bharathidasan University has adopted and adheres to stringent security standards designed to protect non-public personal information at this Website against accidental or unauthorized access or disclosure. Among the safeguards that Bharathidasan University has developed for this Website are administrative, physical, and technical barriers that together form a protective firewall around the information stored at this site.</p>
              <p>We use this information for three general purposes: to customize the content you see, to fulfill your requests for certain services, and to contact you about services.</p>
              <p>We also automatically receive and record information on our server logs from your browser including your IP address, cookie information, and the page you requested. This information is not linked to your account and will not be used to identify you.</p>
              <h3 style={{ color: '#black' }}>Information Sharing and Disclosure</h3>
              <p>We do not sell or rent your personally identifiable information to anyone. We will send personally identifiable information about you to other companies or people when:</p>
              <ul>
                <li>We have your consent to share the information;</li>
                <li>We need to share your information to provide the product or service you have requested;</li>
                <li>We need to send the information to companies who work on behalf of the University to provide a product or service to you (unless we tell you differently, these companies do not have any right to use the personally identifiable information we provide to them beyond what is necessary to assist us);</li>
                <li>We respond to subpoenas, court orders, or legal process; or</li>
                <li>We find that your actions on our web sites violate the <Link to="/#/termandcondition">Terms of Service</Link> or any of our usage guidelines.</li>
              </ul>
              <p>We will send personally identifiable information about you to other companies or people when:</p>
              <h3 style={{ color: '#black' }}>Cookies</h3>
              <p>We may set and access cookies on your computer.</p>
              <p>We use cookies to remember your session so that you don’t have to sign in again every time you visit our site.</p>
              <h3 style={{ color: '#black' }}>Your Ability to Edit and Delete Your Account Information and Preferences</h3>
              <p>You can edit your account information, including your preferences, at any time.</p>
              <p>You can request deletion of your account by contacting the IT Helpdesk at Bharathidasan University.</p>
              <h3 style={{ color: '#black' }}>Confidentiality and Security</h3>
              <p>We limit access to personal information about you to employees who we believe reasonably need to come into contact with that information to provide products or services to you.</p>
              <p>We have physical, electronic, and procedural safeguards to protect personal information about you.</p>
              <p>In certain areas, we use industry-standard SSL encryption to protect data transmissions.</p>
              <h3 style={{ color: '#black' }}>Changes to this Privacy Policy</h3>
              <p>We may update this policy. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site.</p>
              <h3 style={{ color: '#black' }}>Questions and Suggestions</h3>
              <p>If you have questions or suggestions, please contact us.</p>
            </div>
          </div>

          
          <footer className="fixed-footer" >
          <div className="container footerr">
            <div className="row">
              <div className="col-12 text-center">
                {/* Footer content goes here */}
                <p>
                  &copy;2023 Sheth R. D. Davada Digambar Jain Boarding, Sangli.
                  All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </footer>

    </section></>
  )
}

export default PrivacyPolacyReg