import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import loginImage from '../pages/6310507.jpg'; // Import the image file
import './AluminiLogin.css';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import useHistory
import { useNavigate } from 'react-router-dom';
import { AluminiSignUpApi} from './../../ApiFile';


const AluminiSignup = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();




  const onFinish =async (values) => {
    setLoading(true);
    try {
        const response = await axios.post(AluminiSignUpApi, values);
        // console.log('response:', response.data);
        navigate('/AluminiLogin');
        
      } catch (error) {
        // console.error('Error occurred:', error);
        // console.log('Signup failed!');
      }
    
    
    setTimeout(() => {
      setLoading(false);
      // Validate password and confirm password
      if (values.password !== values.confirm_password) {
        notification.error({
          message: 'Signup Failed',
          description: 'Password and confirm password do not match.',
        });
      } else {
        // Simulate successful signup
        notification.success({
          message: 'Signup Successful',
          description: 'Welcome, your account has been created!',
        });
      }
    }, 1000);
  };

  const onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  return (
    
    <div className="login-page" >
      <div className="login-box my-4 py-0" style={{overflow:"scroll"}}>
        <div className="illustration-wrapper">
          <img src={loginImage} alt="Login" /> {/* Use the imported image */}
        </div>

        <Form 
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className="form-title" style={{marginTop:'50px'}}>Alumini Sign Up</p>
          <p>Sign up for account</p>
          <Form.Item
            name="fname"
            rules={[{ required: true, message: 'Please input your First Name!' }]}
          >
            <Input
              placeholder="First Name"
            />
          </Form.Item>

          <Form.Item
            name="mname"
            rules={[{ required: true, message: 'Please input your Middle Name!' }]}
          >
            <Input
              placeholder="Middle Name"
              
            />
          </Form.Item>

          <Form.Item
            name="lname"
            rules={[{ required: true, message: 'Please input your Last Name!' }]}
          >
            <Input
              placeholder="Last Name"
            />
          </Form.Item>

          <Form.Item
            name="email_id"
            rules={[
              { required: true, message: 'Please input your Email ID!' },
              { type: 'email', message: 'Please enter a valid email address!' }
            ]}
          >
            <Input
              placeholder="Email ID"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>


          <Form.Item>
            <Button type="primary" htmlType="submit" className="signup-form-button" loading={loading}>
              SIGN UP
            </Button>
          </Form.Item>
          <Form.Item >
            <p>Already have an account? <Link to="/AluminiLogin">Log in</Link></p>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AluminiSignup;