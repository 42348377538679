import React, { useState, useEffect } from 'react';
import { EventFindApi, StudentEventAttendance } from '../../ApiFile';
import Sidebar from './sidebarstudent';
import Header from './Headerstudent';
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';

function MyAttendance() {
    const [events, setEvents] = useState([]);
    const memberId = sessionStorage.getItem('member_id');
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);
    const [totalEvents, setTotalEvents] = useState(0); // State for total events

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await fetch(`${StudentEventAttendance}/${memberId}`);
                if (response.ok) {
                    const data = await response.json();
                    setEvents(data);
                    calculateAttendanceCounts(data);
                } else {
                    console.error('Error fetching events: Response not ok');
                }
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    const calculateAttendanceCounts = (events) => {
        let presentCount = 0;
        let absentCount = 0;

        events.forEach(event => {
            if (event.attendance_flag == 1) {
                presentCount += 1;
            } else if (event.attendance_flag == 2) {
                absentCount += 1;
            }
        });

        setTotalPresent(presentCount);
        setTotalAbsent(absentCount);
        setTotalEvents(events.length); // Update total events count
    };

    const data = {
        columns: [
            {
                label: 'Serial No',
                field: 'serialNo',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Event Name',
                field: 'name',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date',
                field: 'event_date',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Attendance',
                field: 'attendance_flag',
                sort: 'asc',
                width: 100
            },
        ],
        rows: events.map((event, index) => ({
            serialNo: index + 1,
            name: event.discription,
            event_date: event.event_date,
            attendance_flag: event.attendance_flag == 1 ? (
                <Button style={{ backgroundColor: 'green', color: "white" }}>
                    Present
                </Button>) : event.attendance_flag == 2 ? (
                    <Button style={{ backgroundColor: 'red', color: "white" }}>
                        Absent
                    </Button>) : ""
        }))
    };

    return (
        <>
            <Sidebar />
            <section className="home-section">
                <Header />
                <div className="container mt-5 mb-5">
                <Row className="m-0 pt-1 px-2 px-md-3 mb-2 rounded">
                        <Col className="m-0 p-0 px-3 py-1 rounded" style={{ background: 'white' }}>
<h4 style={{color:"maroon"}}>My Event Attendance</h4>
                        </Col>
                    </Row>
                    <Row className="m-0 px-2 px-md-3 mb-5 rounded">
                        <Col className="m-0 p-0 px-3 py-2 rounded" style={{ background: 'white' }}>
                            <div style={{ overflowY: 'auto' }}>
                                <div style={{ overflowX: 'auto' }}>
                                    <MDBDataTable
                                        striped
                                        bordered
                                        className='custom-datatable text-center'
                                        small
                                        data={data}
                                    />
                                </div>
                            </div>
                            <div className="text-center rounded py-2" style={{display:"flex",alignItems:"center",justifyContent:"space-around",border:"1px solid rgb(214, 222, 219)"}}>
                                <div style={{color:"maroon"}}>Total Events Conducted: {totalEvents}</div>
                                <div style={{color:"green"}}>Total Present: {totalPresent}</div>
                                <div style={{color:"red"}}>Total Absent: {totalAbsent}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
}

export default MyAttendance;
