import React, { useEffect, useState } from 'react';
import './SideNavbarStudentReg.css'
import { display } from '@mui/system';
import logo  from '../AluminiAdminDashboardDesign/adminlogin/jain logo.png'
import ReportIcon from '@mui/icons-material/Report';

const SideNavbarStudentReg = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  
  const handleReturnStockClick = () => {
    setShowDropdown(!showDropdown); // Toggle the dropdown
  };

  const selectedLanguage = sessionStorage.getItem('selectedLanguage');
  
  
  return (
    
    <div className={`sidebar ${isOpen ? 'open' : ''}`}  style={{display:"flex",flexDirection:"column",flex:"1",overflow:"scroll"}}>
    <div className="logo-details mt-4" >
      <i className='bx bxs-landscape'></i>
      <div className="logo_name" style={{marginLeft: "-32px",color: "maroon", textShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",fontSize: "24px", fontWeight: "bold", letterSpacing: "1px", fontFamily: "Arial, sans-serif"}}>WEL- COME
</div>
      <i className={`fa ${isOpen ? 'fa-solid fa-bars' : 'fa-solid fa-bars'}`} id="btn" onClick={toggleSidebar}></i>
    </div>
    <div className="sidebar-content">
    <ul className="nav-list mb-5" >
      <li >
        <a href="/#/RegForm"   className="active ">
        <i class="fa-solid fa-file-lines"></i>
          <span className="links_name">Admission Form</span>
        </a>
      </li>
      {/* <li >
        <a href="/#/privacypolacy"   className="active ">
        <i class="fa-solid fa-users"></i>
          <span className="links_name">Privacy Polacy</span>
        </a>
      </li>
      <li >
        <a href="/#/termandcondition"   className="active ">
        <i class="fa-regular fa-comments"></i>
          <span className="links_name">Term & Condition</span>
        </a>
      </li> */}

    


    </ul>
    </div>
  </div>
  
);
};


export default SideNavbarStudentReg;