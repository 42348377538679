import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import loginImage from './4707071.jpg'; // Import the image file
import './Login.css';
import { Navigate, useNavigate } from 'react-router-dom';
import { AdminLoginApi } from '../../ApiFile';
import axios from 'axios';

const Supertendent = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(AdminLoginApi, {
        email_id: values.username,
        password: values.password,
        flag:2
      });
  
      const data = response.data;
  
      if (data.message === "Invalid Email") {
        notification.error({
          message: 'Invalid Email',
          description: data.message,
        });
      } else if (data.message === "Invalid Password") {
        notification.error({
          message: 'Invalid Password',
          description: data.message,
        });
      } else if (data.message === "Login successful") {
        sessionStorage.setItem("email", data.email);
        sessionStorage.setItem("flag", data.flag);
        notification.success({
          message: 'Login Successful',
          description: data.message,
        });
        navigate('/AdminLoginYear');
      } else {
        notification.error({
          message: 'Login Failed',
          description: data.message,
        });
      }
    } catch (error) {
      // console.error('Error:', error);
      notification.error({
        message: 'Error',
        description: 'An error occurred. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <div className="login-page" >
      <div className="login-box" style={{overflow:"scroll"}}>
        <div className="illustration-wrapper">
          <img src={loginImage} alt="Login" /> {/* Use the imported image */}
        </div>
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className="form-title">Welcome Admin</p>
          <p>Superintendent</p>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Email ID!' }]}
          >
            <Input
              placeholder="Email ID"
            />
          </Form.Item>

          <Form.Item  
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              placeholder="Password"
            />
          </Form.Item>


          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Supertendent;
