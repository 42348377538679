import React, { useState, useEffect } from 'react';
import './fee.css'; // Import CSS file
import MenuItem from '@mui/material/MenuItem'; // Import MenuItem from Material-UI
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add'; // Import Add icon
import Button from '@mui/material/Button'; // Import Button from Material-UI
import Modal from '@mui/material/Modal'; // Import Modal from Material-UI
import TextField from '@mui/material/TextField'; // Import TextField from Material-UI
import Typography from '@mui/material/Typography'; // Import Typography from Material-UI
import Box from '@mui/material/Box'; // Import Box from Material-UI
import { DataGrid, GridToolbar } from '@mui/x-data-grid'; // Import DataGrid from Material-UI
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { FeeMasterAddApi, FeeMasterFindAllApi, FeeMasterUpdateApi } from '../../ApiFile';

const BoardingFeeMaster = () => {
  const [feeMaster, setFeeMaster] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [blockId, setBlockId] = useState('');
  const [floor, setFloor] = useState('');
  const [term, setTerm] = useState('1'); // Default value set to '1'
  const [payAmount, setPayAmount] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    fetchFeeMaster();
  }, []);

  const fetchFeeMaster = async () => {
    try {
      const response = await fetch(FeeMasterFindAllApi, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch fee master');
      }
      const data = await response.json();
      if (data === "NOTFOUND") {
        console.log("if ");
        setFeeMaster([]); // Set rules to an empty array if "NOTFOUND"
    } else {
      console.log("else ");
      setFeeMaster(data);
    }
    
    } catch (error) {
      // console.error('Error fetching fee master:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setBlockId('');
    setFloor('');
    setTerm('1'); // Reset term to default value '1'
    setPayAmount('');
    setSelectedRow(null);
  };

  const handleShowModal = () => setShowModal(true);

  const handleAddFeeMaster = async () => {
    try {
      const response = await fetch(FeeMasterAddApi, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          block_id: blockId,
          floor: floor,
          term: term,
          pay_amount: payAmount,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to add fee master');
      }
      const data = await response.text();
      if (data === 'SAVED') {
        fetchFeeMaster();
        handleCloseModal();
      }
    } catch (error) {
      // console.error('Error adding fee master:', error);
    }
  };

  const handleUpdateFeeMaster = async () => {
    try {
      const response = await fetch(`${FeeMasterUpdateApi}/${selectedRow.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          block: blockId,
          term: term,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update fee master');
      }
      const data = await response.text();
      if (data === 'UPDATE') {
        fetchFeeMaster();
        handleCloseModal();
      }
    } catch (error) {
      // console.error('Error updating fee master:', error);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'block', headerName: 'Block ID', width: 150 },
    { field: 'floor', headerName: 'Floor', width: 150 },
    { field: 'term', headerName: 'Term', width: 150 },
    { field: 'paymentAmount', headerName: 'Payment Amount', width: 200 },
    {
      field: 'update',
      headerName: 'Update',
      width: 100,
      renderCell: (params) => (
        <Button variant="contained" color="primary" onClick={() => handleEdit(params.row)}>
          <UpdateIcon />
        </Button>
      ),
    },
  ];

  const handleEdit = (row) => {
    setSelectedRow(row);
    setBlockId(row.block);
    setFloor(row.floor);
    setTerm(row.term);
    setPayAmount(row.paymentAmount);
    setShowModal(true);
  };

  return (
    <>

  <Sidebar/>
  <section className="home-section">
    <NavLogo/> 
    <Box className="fee-master-container w-100" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '86vh', width: '1000px', margin: '0 auto', padding: '20px', backgroundColor: '#d7eeed' }}>
      <Typography variant="h2" gutterBottom>
      Boarding Fee Master
      </Typography>
      <Button variant="contained" color="primary" onClick={handleShowModal} style={{ marginBottom: '20px', width: '700px' }}>
        <AddIcon /> Add Boarding Fee
      </Button>
      <DataGrid
        style={{ width: '900px' }}
        columns={columns}
        rows={feeMaster}
        disableColumnResize
        disableDensitySelector
        disableColumnHeaderSorting
        disableColumnFilter
        slots={{ toolbar: GridToolbar }}
        className="custom-data-grid"
      />

      <Modal open={showModal} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 4, width: 400, boxShadow: 24 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            {selectedRow ? 'Update Boarding Fee' : 'Add Boarding Fee'}
          </Typography>
          <TextField label="Block ID" value={blockId} onChange={(e) => setBlockId(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
          <TextField label="Floor" value={floor} onChange={(e) => setFloor(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
          <TextField
            select
            label="Term"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            fullWidth
            style={{ marginBottom: '20px' }}
          >
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="Both">Both</MenuItem>
          </TextField>
          <TextField label="Payment Amount" value={payAmount} onChange={(e) => setPayAmount(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
          <Button variant="contained" color="primary" onClick={selectedRow ? handleUpdateFeeMaster : handleAddFeeMaster} style={{ marginRight: '10px' }}>
            {selectedRow ? 'Update' : 'Add'}
          </Button>
          <Button variant="contained" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Box>
      </Modal>
    </Box>
    </section>
    </>
  );
};

export default BoardingFeeMaster;
