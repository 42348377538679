import { useEffect, useState } from 'react';
import React from 'react';
import Header from './Header';
import Sidebar from './sidebar';
import { toast } from 'react-toastify';
import { AluminiSuggestionFindOne, EventAluminiSuggestionAdd } from '../../ApiFile';
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
const Suggestion = () => {

    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const userdata = JSON.parse(sessionStorage.getItem("userdata")) || {};
    const { f_name, m_name, l_name, id } = userdata;
    console.log(userdata);
  
    const [formData, setFormData] = useState({
      fname: f_name || '',
      mname: m_name || '',
      lname: l_name || '',
      id: id || '',
      discription: ''
    });

    const [suggestions, setSuggestions] = useState([]); // State to hold suggestions data

    useEffect(() => {
  
      fetchSuggestions();
    }, [id]);
  
    const fetchSuggestions = async () => {
      try {
        const response = await fetch(`${AluminiSuggestionFindOne}/${id}`);
        const data = await response.json();
        setSuggestions(data); // Update the suggestions state with API response
      } catch (error) {
        toast.error('Error fetching suggestions');
      }
    };
  
    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle);
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await fetch(EventAluminiSuggestionAdd, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        if (response.ok) {
          fetchSuggestions();
          toast.success('Suggestion Submitted Successfully!');
          // Reset form data after successful submission if needed
          setFormData({
            fname: f_name || '',
            mname: m_name || '',
            lname: l_name || '',
            id: id || '',
            discription: ''
          });
        } else {
          toast.warn('Error Submitting Suggestion. Please Try Again Later.');
        }
      } catch (error) {
        toast.error('Error Submitting Suggestion. Please Try Again Later.');
      }
    };

    const tableData = {
      columns: [
        {
          label: 'S.No.',
          field: 'serialNo',
          sort: 'asc',
          width: 50
        },
        {
          label: 'Description',
          field: 'suggestion_discription',
          sort: 'asc',
          width: 300
        },
        {
          label: 'Date',
          field: 'suggestion_date',
          sort: 'asc',
          width: 150
        }
      ],
      rows: suggestions.map((suggestion, index) => ({
        serialNo: index + 1,
        suggestion_discription: suggestion.suggestion_discription,
        suggestion_date: suggestion.suggestion_date
      }))
    };

    return (
      <>
      <Sidebar/>
       <section className="home-section">
         <Header/> 
  


            <div className="scrollable-content " style={{ overflow: 'scroll', marginTop: '20px' }}>
            <div className="form-container shadow mt-3" style={{border:"0px"}}>
      <h2 className='text-center' style={{color:"maroon"}}>Suggestion</h2>
      <form onSubmit={handleSubmit}>
        
       
        <div className="form-group">
          <label>Full Name:</label>
          <input
            type="text"
            name="fname"
            value={formData.fname + ' ' + formData.mname + ' ' + formData.lname}
            required
          />
        </div>
        
        <div className="form-group">
          <label>Description:</label>
          <textarea
            name="discription"
            value={formData.discription}
            onChange={handleChange}
            placeholder='Enter Description'
            rows={4}
            required
          ></textarea>
        </div>
        <div className='text-center' style={{display:"flex",justifyContent:"center"}}>
        <button className='text-center w-50' type="submit" style={{backgroundColor:"rgb(23, 160, 160)",color:"white"}}>Submit</button>
        </div>
        
      </form></div>

      <Row className="m-0 p-0 px-md-3 py-1 pb-5 mb-5 mt-4 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable striped className="custom-datatable text-center" bordered hover small data={tableData} />
              </div>
            </div>
          </Col>
        </Row>
        
            </div>
            <style jsx>{`
            
.form-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #F0F8FF;
    box-shadow:grey 1px 2px 2px 1px;
  }
  
  h1, h3 {
    color: #DD3333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="date"],
  input[type="number"],
  textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    background-color: blue;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  

            
            `}</style>
        </section>
        </>
        )
}

export default Suggestion;