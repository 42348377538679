import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { Col, Row } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextareaAutosize } from '@mui/material';
import { RuleMasterAddApi, RuleMasterDeleteApi, RuleMasterFindallApi, RuleMasterUpdateApi } from '../../ApiFile';

const AdmissionRuleMaster = () => {
  const [showModal, setShowModal] = useState(false);
  const [rules, setRules] = useState([]);
  const [serialNo, setSerialNo] = useState('');
  const [description, setDescription] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [editId, setEditId] = useState(null); // New state to track the ID of the item being edited

  useEffect(() => {
    fetchRuleData();
  }, []);

  const fetchRuleData = async () => {
    try {
      const response = await fetch(RuleMasterFindallApi);
      console.log("api..",RuleMasterFindallApi);
      if (!response.ok) {
        throw new Error('Failed to fetch block data');
      }
      console.log("in rules")
      const data = await response.json();

      if (data === "NOTFOUND") {
        console.log("if ");
      setRules([]); // Set rules to an empty array if "NOTFOUND"
    } else {
      console.log("else ");
      setRules(data);
    }
    } catch (error) {
      // console.error('Error fetching block data:', error);
    }
  };

  const handleOpen = (id = null) => { // Modified to accept an optional parameter for editing
    setOpen(true);
    setIsEditing(id !== null);
    if (id !== null) { // If editing, set the initial state with the data of the row being edited
      const item = rules.find(rule => rule.id === id);
      if (item) {
        setEditId(id);
        setSerialNo(item.serial_no);
        setDescription(item.description);
      }
    } else {
      setSerialNo('');
      setDescription('');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    setSerialNo('');
    setDescription('');
  };

  const savedata = async (e) => {
    e.preventDefault();
    
    const trimmedSerialNo = String(serialNo).trim();
    const trimmedDescription = String(description).trim();
  
    // Validation for mandatory fields
    if (!trimmedSerialNo || !trimmedDescription) {
      toast.error('Serial No and Description are mandatory fields.');
      return;
    }
  
    try {
      if (isEditing) {
        const bodyData = { serial_no: trimmedSerialNo, description: trimmedDescription };
        console.log('Update Body Data:', bodyData); // Log the data being sent in the update request
  
        const response = await fetch(`${RuleMasterUpdateApi}/${editId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        });
        if (!response.ok) {
          throw new Error('Failed to update');
        }
        toast.success('Data updated successfully');
      } else {
        const bodyData = { serial_no: trimmedSerialNo, description: trimmedDescription };
        console.log('Add Body Data:', bodyData); // Log the data being sent in the add request
  
        const response = await fetch(RuleMasterAddApi, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        });
        if (!response.ok) {
          throw new Error('Failed to add');
        }
        toast.success('Data saved successfully');
      }
      fetchRuleData();
      handleClose();
    } catch (error) {
      console.error('Error adding/updating:', error);
    }
  };
  

  const deleteData = async (id) => {
    try {
      const response = await fetch(`${RuleMasterDeleteApi}/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete');
      }
      toast.success('Data deleted successfully');
      fetchRuleData();
    } catch (error) {
      // console.error('Error deleting:', error);
      setError('Failed to delete ');
    }
  };

  const columns = [
    { label: 'Serial No', field: 'serial_no', sort: 'asc' },
    { label: 'Description', field: 'description', sort: 'asc' },
    { label: 'Edit', field: 'edit', sort: 'asc' },
    { label: 'Delete', field: 'delete', sort: 'asc' },
  ];

  const rows = rules.map((item, index) => ({
    id: item.id, // 
    serial_no: item.serial_no,
    description: item.description,
    edit: (
      <>
        <button
          className="m-1"
          style={{ color: "rgb(255, 93, 0)", border: "0px", backgroundColor: "transparent" }}
          onClick={() => handleOpen(item.id)}
        >
          <UpdateIcon />
        </button>
      </>
    ),
    delete:(
      <>
      <button
          className="m-1"
          style={{ color: "rgb(255, 0, 0)", border: "0px", backgroundColor: "transparent" }}
          onClick={() => deleteData(item.id)}
        >
          <DeleteIcon />
        </button>
      </>
    )
  }));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />

        <div>
          <div className="block-header">
            <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
              Admission Rule
            </h2>
            <Button
              className="ms-md-5 m-0  ms-3  mb-2 mb-md-4 mt-2 mt-md-4"
              variant="contained"
              color="primary"
              onClick={() => handleOpen()} // Modified to call handleOpen directly
              startIcon={<AddIcon />}
            >
              Add Rule
            </Button>
          </div>

          <Row className="m-0 p-0  px-md-3  py-1 pb-5  w-100" style={{ display: 'flex' }}>
            <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
              <Col className="m-0 p-0  px-3 py-3 rounded" style={{ background: 'white' }}>
                <div style={{ overflowY: 'auto' }}>
                  <div style={{ overflowX: 'auto' }}>
                    <MDBDataTable striped className="custom-datatable" bordered hover data={{ columns, rows }} />
                  </div>
                </div>
              </Col>
            </Row>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Typography variant="h6" className="mt-2" component="h2">
                  Admission Rule Master
                </Typography>
                <form>
                  <TextField
                  type='number'
                    label="Serial No"
                    value={serialNo}
                    onChange={(e) => setSerialNo(e.target.value)}
                    fullWidth
                    style={{ marginBottom: '20px' }}
                  />
                 
                  <TextareaAutosize
                      minRows={4}
                      label="Description"
                      value={description} onChange={(e) => setDescription(e.target.value)}
                      style={{ width: '100%', marginBottom: '20px' }}
                    />

                </form>
                <Row>
                  <Col className='text-end'>
                    <Button
                      className="mt-4"
                      style={{ color: 'black', backgroundColor: 'white', border: '2px solid rgb(135, 178, 178)', borderRadius: '8' }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col >
                    <Button
                      style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                      className="py-2 px-3 mt-4 shadow text-dark"
                      type="submit"
                      onClick={savedata}
                    >
                      {isEditing ? 'Update' : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Modal>
          </Row>
        </div>
      </section>
    </>
  );
};

export default AdmissionRuleMaster;
