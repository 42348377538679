import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdminMenuMasterAdd, AdminMenuMasterDaysFindall, AdminMenuMasterDelete, AdminMenuMasterFindAll, AdminMenuMasterUpdate } from '../../ApiFile';
import Sidebar from '../../AluminiAdminDashboardDesign/sidebar';
import NavLogo from '../../AluminiAdminDashboardDesign/NavLogo';

const MessMenuMaster = () => {
  const [rules, setRules] = useState([]);
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState('');
  const [menu, setMenu] = useState('');
  const [flag, setFlag] = useState(''); // Default to Lunch (1)
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [editId, setEditId] = useState(null);
  
  // States for delete confirmation
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    fetchRuleData();
    fetchDays();
  }, []);

  const fetchRuleData = async () => {
    try {
      const response = await fetch(AdminMenuMasterFindAll);
      if (!response.ok) {
        throw new Error('Failed to fetch menu data');
      }
      const data = await response.json();
      setRules(data === "NOTFOUND" ? [] : data);
    } catch (error) {
      console.error('Error fetching menu data:', error);
    }
  };

  const fetchDays = async () => {
    try {
      const response = await fetch(AdminMenuMasterDaysFindall);
      if (!response.ok) {
        throw new Error('Failed to fetch days');
      }
      const data = await response.json();
      setDays(data);
    } catch (error) {
      console.error('Error fetching days:', error);
    }
  };

  const handleOpen = (id = null) => {
    setOpen(true);
    setIsEditing(id !== null);
    if (id !== null) {
      const item = rules.find(rule => rule.id === id);
      if (item) {
        setEditId(id);
        setMenu(item.menu_name);
        setSelectedDay(item.day_id);
        setFlag(item.flag); // Set flag if editing
      }
    } else {
      setMenu('');
      setSelectedDay('');
      setFlag(''); // Default to Lunch
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    setMenu('');
    setSelectedDay('');
    setFlag(''); // Reset to Lunch
  };

  const savedata = async (e) => {
    e.preventDefault();

    if (!menu || !selectedDay || !flag) {
      toast.error('Menu Name , Flag and Day are mandatory fields.');
      return;
    }

    try {
      const bodyData = { menu_name: menu, day: selectedDay, flag: flag }; // Include flag in request
      const response = await fetch(isEditing ? `${AdminMenuMasterUpdate}/${editId}` : AdminMenuMasterAdd, {
        method: isEditing ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyData),
      });
      if (!response.ok) {
        throw new Error(isEditing ? 'Failed to update' : 'Failed to add');
      }
      toast.success(isEditing ? 'Data updated successfully' : 'Data saved successfully');
      fetchRuleData();
      handleClose();
    } catch (error) {
      console.error('Error adding/updating:', error);
    }
  };

  const deleteData = (id) => {
    setDeleteId(id);
    setOpenConfirmDelete(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(`${AdminMenuMasterDelete}/${deleteId}`, { method: 'DELETE' });
      if (!response.ok) {
        throw new Error('Failed to delete');
      }
      toast.success('Data deleted successfully');
      fetchRuleData();
      setOpenConfirmDelete(false);
    } catch (error) {
      setError('Failed to delete');
    }
  };

  const columns = [
    { label: 'Serial No', field: 'serial_no', sort: 'asc' },
    { label: 'Menu Name', field: 'menu_name', sort: 'asc' },
    { label: 'Day', field: 'day', sort: 'asc' },
    { label: 'Flag', field: 'flag', sort: 'asc' },
    { label: 'Edit', field: 'edit', sort: 'asc' },
    { label: 'Delete', field: 'delete', sort: 'asc' },
  ];

  const rows = rules.map((item, index) => ({
    serial_no: index + 1,
    menu_name: item.menu_name,
    day: item.day,
    flag: item.flag === 1 ? 'Lunch' : 'Dinner',
    edit: (
      <button
        className="m-1"
        style={{ color: "rgb(255, 93, 0)", border: "0px", backgroundColor: "transparent" }}
        onClick={() => handleOpen(item.id)}
      >
        <UpdateIcon />
      </button>
    ),
    delete: (
      <button
        className="m-1"
        style={{ color: "rgb(255, 0, 0)", border: "0px", backgroundColor: "transparent" }}
        onClick={() => deleteData(item.id)}
      >
        <DeleteIcon />
      </button>
    )
  }));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div>
          <div className="block-header">
            <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
              Mess Menu Master
            </h2>
            <Button
              className="ms-md-5 m-0 ms-3 mb-2 mb-md-4 mt-2 mt-md-4"
              variant="contained"
              color="primary"
              onClick={() => handleOpen()}
              startIcon={<AddIcon />}
            >
              Add Menu
            </Button>
          </div>

          <Row className="m-0 p-0 px-md-3 py-1 pb-5 w-100" style={{ display: 'flex' }}>
            <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
              <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
                <div style={{ overflowY: 'auto' }}>
                  <div style={{ overflowX: 'auto' }}>
                    <MDBDataTable striped className="custom-datatable" bordered hover data={{ columns, rows }} />
                  </div>
                </div>
              </Col>
            </Row>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Typography variant="h6" className="mt-2" component="h2">
                  Mess Menu Master
                </Typography>
                <form>
                  <TextField
                    select
                    label="Day"
                    value={selectedDay}
                    onChange={(e) => setSelectedDay(e.target.value)}
                    fullWidth
                    style={{ marginBottom: '20px' }}
                  >
                    {days.map((day) => (
                      <MenuItem key={day.id} value={day.id}>
                        {day.day}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    label="Enter Menu"
                    value={menu}
                    onChange={(e) => setMenu(e.target.value)}
                    fullWidth
                    style={{ marginBottom: '20px' }}
                  />

                  <TextField
                    select
                    label="Select Flag"
                    value={flag}
                    onChange={(e) => setFlag(e.target.value)}
                    fullWidth
                    style={{ marginBottom: '20px' }}
                  >
                    <MenuItem value={1}>Lunch</MenuItem>
                    <MenuItem value={2}>Dinner</MenuItem>
                  </TextField>
                </form>
                <Row>
                  <Col className='text-end'>
                    <Button
                      className="mt-4"
                      style={{ color: 'black', backgroundColor: 'white', border: '2px solid rgb(135, 178, 178)', borderRadius: '8' }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                      className="py-2 px-3 mt-4 shadow text-dark"
                      type="submit"
                      onClick={savedata}
                    >
                      {isEditing ? 'Update' : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Modal>

            <Modal
              open={openConfirmDelete}
              onClose={() => setOpenConfirmDelete(false)}
              aria-labelledby="confirm-delete-title"
              aria-describedby="confirm-delete-description"
            >
              <Box sx={style}>
                <Typography variant="h6" id="confirm-delete-title">
                  Confirm Deletion
                </Typography>
                <Typography id="confirm-delete-description" sx={{ mt: 2 }}>
                  Are you sure you want to delete this item?
                </Typography>
                <Row>
                  <Col className='text-end'>
                    <Button
                      className="mt-4"
                      style={{ color: 'black', backgroundColor: 'white', border: '2px solid rgb(135, 178, 178)', borderRadius: '8' }}
                      onClick={() => setOpenConfirmDelete(false)}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                      className="py-2 px-3 mt-4 shadow text-dark"
                      onClick={confirmDelete}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Modal>
          </Row>
        </div>
      </section>
    </>
  );
};

export default MessMenuMaster;
