import React, { useState, useEffect } from 'react';
import './fee.css';
import MenuItem from '@mui/material/MenuItem';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import { FeeTypeAddApi, FeeTypeFindAllApi1, FeeTypeUpdateApi } from '../../ApiFile';

const FeeType = () => {
  const [feeMaster, setFeeMaster] = useState([]);
  const [fee_name, setFeeName] = useState('');
  const [fee_type, setFeeType] = useState('');
  const [term_applicable, setTermApplicable] = useState(false); // New state for term_applicable
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    fetchFeeMaster();
  }, []);

  const fetchFeeMaster = () => {
    axios
      .get(FeeTypeFindAllApi1)
      .then((res) => {
        if (res.data === "NOTFOUND") {
          console.log("if ");
          setFeeMaster([]); // Set rules to an empty array if "NOTFOUND"
      } else {
        console.log("else ");
        setFeeMaster(res.data);
      }
        // console.log("Data fetched successfully:", res.data);
        
      })
      .catch((err) => {
        // console.error("Failed to fetch data:", err);
        toast.error("Failed to fetch data. Please try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  const savedata = (e) => {
    e.preventDefault();
    if (!fee_name || !fee_type) {
      alert("All fields are required");
      return;
    }

    const obj = { 
      fee_name, 
      fee_type, 
      term_applicable: term_applicable ? 1 : 2 // Include term_applicable in the payload
    };
    // console.log("obj cob..",obj)

    const apiUrl = isEditing
      ? `${FeeTypeUpdateApi}/${currentId}`
      : FeeTypeAddApi;

    axios
      .request({
        method: isEditing ? 'put' : 'post',
        url: apiUrl,
        data: obj
      })
      .then((res) => {
        const message = res.data === "UPDATE" ? `Data Updated Successfully!` : `Data Saved Successfully!`;
        toast.success(message, { position: "top-right", autoClose: 3000 });
        fetchFeeMaster();
        handleClose();
      })
      
      .catch((err) => {
        const errorMessage = isEditing ? "Failed to update, please try again" : "Failed to save, please try again";
        toast.error(errorMessage, { position: "top-right", autoClose: 3000 });
        // console.error(err);
      });
  };

  const handleEditShow = (item) => {
    setFeeName(item.fee_name);
    setFeeType(item.fee_type);
    setTermApplicable(item.term_applicable === 1); // Set term_applicable for editing
    setCurrentId(item.fee_id);
    setIsEditing(true);
    handleOpen();
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    setFeeName('');
    setFeeType('');
    setTermApplicable(false); // Reset term_applicable
  };

  const columns = [
    { label: 'Sr.No', field: 'srNo', sort: 'asc' },
    { label: 'Fee Name', field: 'fee_name', sort: 'asc' },
    { label: 'Fee Type', field: 'fee_type', sort: 'asc' },
    { label: 'Term Applicable', field: 'term_applicable', sort: 'asc' }, // New column for term_applicable
    { label: 'Edit', field: 'edit', sort: 'asc' },
  ];

  const rows = feeMaster.map((item, index) => ({
    srNo: index + 1,
    fee_name: item.fee_name,
    fee_type: item.fee_type === 1 ? 'Compulsory' : item.fee_type === 2 ? 'Optional' : item.fee_type === 3 ? 'Deposit' : 'Unknown',
    term_applicable: item.term_applicable === 1 ? 'Applicable' : 'Not Applicable', // Displaying term_applicable
    edit: (
      <button
        className="m-1"
        style={{ color: "rgb(255, 93, 0)", border: "0px", backgroundColor: "transparent" }}
        onClick={() => handleEditShow(item)}
      >
        <i className="fa-solid fa-pen-to-square"></i>
      </button>
    ),
  }));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div>
          <div className="block-header">
            <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
              Fee Type Master
            </h2>
            <Button
              className="ms-md-5 m-0 ms-3 mb-2 mb-md-4 mt-2 mt-md-4"
              variant="contained"
              color="primary"
              onClick={handleOpen}
              startIcon={<AddIcon />}
            >
              Add Fee Type
            </Button>
          </div>

          <Row className="m-0 p-0 px-md-3 py-1 pb-5 w-100" style={{ display: 'flex' }}>
            <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
              <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
                <div style={{ overflowY: 'auto' }}>
                  <div style={{ overflowX: 'auto' }}>
                    <MDBDataTable striped className="custom-datatable" bordered hover data={{ columns, rows }} />
                  </div>
                </div>
              </Col>
            </Row>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Typography variant="h6" component="h2">
                  <h2 className="text-center mt-2 m-0 p-0" style={{ color: 'maroon' }}>
                    Fee Type Master
                  </h2>
                </Typography>
                <form>
                  <TextField
                    className="mt-3"
                    size="small"
                    label="Fee Name"
                    value={fee_name}
                    onChange={(e) => setFeeName(e.target.value)}
                    fullWidth
                    required
                  />
                  <TextField
                    className="mt-3"
                    size="small"
                    label="Select Fee Type"
                    value={fee_type}
                    onChange={(e) => setFeeType(e.target.value)}
                    fullWidth
                    select
                  >
                    <MenuItem value={1}>Compulsory</MenuItem>
                    <MenuItem value={2}>Optional</MenuItem>
                    <MenuItem value={3}>Deposit</MenuItem>
                  </TextField>
                  <div style={{display:"flex",height:"40px", borderRadius:"5px"}} className=" mt-3 border p-2 ">
                    <input
                      type="checkbox"
                      style={{width: "20px",height: "20px"}}
                      id="term_applicable"
                      checked={term_applicable}
                      onChange={(e) => setTermApplicable(e.target.checked)}
                    />
                   <p className="ms-2" >Term Applicable</p>
                  </div>
                </form>
                <Row>
                  <Col md={6} xs={6} className='text-end'>
                    <Button
                      className="mt-4 "
                      style={{ color: 'black', backgroundColor: 'white', border: '2px solid rgb(135, 178, 178)', borderRadius: '8' }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col md={6} xs={6}>
                    {isEditing ? (
                      <Button
                        style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                        className="py-2 px-3 mt-4 shadow text-dark"
                        type="submit"
                        onClick={savedata}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                        className="py-2 px-3 mt-4 shadow text-dark"
                        type="submit"
                        onClick={savedata}
                      >
                        Save
                      </Button>
                    )}
                  </Col>
                </Row>
              </Box>
            </Modal>
          </Row>
        </div>
      </section>
    </>
  );
};

export default FeeType;
