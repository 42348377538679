import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Gallery.css';
import Header from './Header';
import { Col, Row } from 'react-bootstrap';
import { EventAluminiGalleryFindAll, ImagePath } from '../../ApiFile';
import Sidebar from './sidebar';


const GalleryAlumini = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(EventAluminiGalleryFindAll);
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <>
    <Sidebar/>
     <section className="home-section">
       <Header/> 

      
      <div className="scrollable-content" style={{ overflow: 'scroll' }}>
      <Row className="m-0 p-0 pt-5 px-md-4 px-2 ">
              <Col
                className=" rounded pt-2"
                style={{
                  backgroundColor: "white",
                  border: "0px",
                  alignItems: "center",
                  alignContent: "center",
                  color: " rgb(47, 79, 79)",
                }}
              >
                <h3 style={{ color: "maroon" }}>GALLERY</h3>
              </Col>
            </Row>
        <div className="tab-class text-center" style={{ padding: '10px' }}>
          <div className="tab-content">
            <div id="GalleryTab-1" className="tab-pane fade show p-0 active">
              <div className="row g-2">
                {images.map((image) => (
                  <div key={image.id} className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="gallery-item h-100">
                      <img
                        src={`${ImagePath}/${image.upload_img}`}
                        className="img-fluid w-100 h-100 rounded"
                        alt={`${ImagePath}/${image.upload_img}`}
                      />
                      {/* <div className="gallery-plus-icon">
                        <a href={`${API_URL}/uploads/${image.upload_img}`} data-lightbox={`gallery-${image.id}`} className="my-auto">
                          <i className="fas fa-plus fa-2x text-white" />
                        </a>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default GalleryAlumini;
