import react, { Fragment, useEffect, useState } from 'react';
import './boarding.css';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { DirectorPhotoApi, ImagePath } from '../../ApiFile';

function Boardofdir() {

  const [guideItems, setGuideItems] = useState([]);

  useEffect(() => {
    const fetchGuideItems = async () => {
      try {
        const response = await fetch(DirectorPhotoApi);
        const guideItemsData = await response.json();
        setGuideItems(guideItemsData);
      } catch (error) {
        console.error('Error fetching guide items:', error);
      }
    };

    fetchGuideItems();
  }, []); 

  return (
    <div>
      <Navbar></Navbar>
      {/* Header Start */}
      <div className="container-fluid bgg-breadcrumb">
        <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h3 className="text-white display-3" style={{ textShadow: '3px 3px darkblue' }}>Board of Directors</h3>
        </div>
      </div>

      {/* Header End */}
      {/* Services Start */}
      <section className="services">
        <div className="container">
          <div className="container-fluid guide py-5">
            <div className="container py-5">
              <div className="mx-auto text-center mb-5" style={{ maxWidth: '900px' }}>
                <h5 className="section-title px-2">Board of Directors</h5>
              </div>
              {/* <div className="row g-4">
                <div className="col-md-6 col-lg-3">
                  <div className="guide-item">
                    <div className="guide-img">
                      <div className="guide-img-efects">
                        <img src="img/guide-1.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                      </div>
                   
                    </div>
                    <div className="guide-title text-center rounded-bottom p-4">
                      <div className="guide-title-inner">
                        <h4 className="mt-2" style={{fontSize:'19px'}}>Full Name</h4>
                        <p className="mb-0">Designation</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="guide-item">
                    <div className="guide-img">
                      <div className="guide-img-efects">
                        <img src="img/guide-2.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                      </div>
                     
                    </div>
                    <div className="guide-title text-center rounded-bottom p-4">
                      <div className="guide-title-inner">
                        <h4 className="mt-3">Full Name</h4>
                        <p className="mb-0">Designation</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="guide-item">
                    <div className="guide-img">
                      <div className="guide-img-efects">
                        <img src="img/guide-3.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                      </div>
                     
                    </div>
                    <div className="guide-title text-center rounded-bottom p-4">
                      <div className="guide-title-inner">
                        <h4 className="mt-3">Full Name</h4>
                        <p className="mb-0">Designation</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="guide-item">
                    <div className="guide-img">
                      <div className="guide-img-efects">
                        <img src="img/guide-4.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                      </div>
                      
                    </div>
                    <div className="guide-title text-center rounded-bottom p-4">
                      <div className="guide-title-inner">
                        <h4 className="mt-3">Full Name</h4>
                        <p className="mb-0">Designation</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row g-4" id="guide-container">
      {guideItems.map(item => (
        <div key={item.id} className="col-md-6 col-lg-3">
          <div className="guide-item">
            <div className="guide-img">
              <div className="guide-img-efects">
                <img src={`${ImagePath}/${item.upload_img}`} className="img-fluid w-100 rounded-top" alt={`${item.fname} ${item.mname} ${item.lname}`} />
              </div> 
            </div>
            <div className="guide-title text-center rounded-bottom p-4">
              <div className="guide-title-inner">
                <h4 className="mt-2" style={{ fontSize: '19px' }}>{`${item.fname} ${item.mname} ${item.lname}`}</h4>
                <p className="mb-0">{item.designation}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
            </div>
          </div></div></section>
      {/* Footer Start */}
      <Footer/>
      {/* Copyright End */}
      {/* Back to Top */}
      {/* <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up" /></a> */}
      {/* JavaScript Libraries */}
      {/* Template Javascript */}

    </div>

  )
}
export default Boardofdir;