import react, { Fragment } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

function Services(){
    return(
        <div>
        <Navbar></Navbar>
        <div className="container-fluid bg2-breadcrumb">
        <div className="container text-center py-5" style={{maxWidth: '900px'}}>
          <h3 className="text-white display-3 mb-4" style={{textShadow: '3px 3px #ee1515'}}>Services</h3>
        </div>
      </div>
        {/* Services Start */}
      <div className="container-fluid bg-light service py-5" data-aos="fade-up" data-aos-delay={400} data-aos-duration={400}>
        <div className="container py-5">
          <div className="mx-auto text-center mb-5" style={{maxWidth: '900px'}}>
            <h1 className="mb-0">Our Services</h1>
          </div>
          <div className="row g-4" data-aos-delay={800} data-aos-duration={400}>
            <div className="col-lg-6">
              <div className="row g-4">
                <div className="col-12" data-aos="fade-up" data-aos-delay={1000} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 pe-0">
                    <div className="service-icon p-4">
                      <i className="fa fa-utensil-spoon fa-3x text-primary" />
                    </div>
                    <div className="service-content text-end">
                      <h5 className="mb-4">Annapurna Mess</h5>
                    </div>
                  </div>
                </div>
                <div className="col-12" data-aos="fade-up" data-aos-delay={1300} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center  bg-white border border-primary rounded p-4 pe-0">
                    <div className="service-icon p-4">
                      <i className="fa fa-hotel fa-3x text-primary" />
                    </div>
                    <div className="service-content text-end">
                      <p className="mb-0">
                      </p><h5 className="mb-4">Library</h5>
                      <p />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-4">
                <div className="col-12" data-aos="fade-up" data-aos-delay={1500} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 ps-0">
                    <div className="service-icon p-4">
                      <i className="fa fa-dumbbell fa-3x text-primary" />
                    </div>
                    <div className="service-content">
                      <p className="mb-0">
                      </p><h5 className="mb-4">Gym</h5>
                      <p />
                    </div>
                  </div>
                </div>
                <div className="col-12" data-aos="fade-up" data-aos-delay={1700} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 ps-0">
                    <div className="service-icon p-4">
                      <i className="fas fa-door-open fa-3x text-primary" />
                    </div>
                    <div className="service-content">
                      <p className="mb-0">
                      </p><h5 className="mb-4">Rooms</h5>
                      <p />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Start */}
      <Footer/>
          {/* Copyright End */}
      {/* Back to Top */}
      {/* <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up" /></a> */}
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
       </div>

    )
}
export default Services;