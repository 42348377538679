import React, { useState, useCallback, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../pages/Calender.css';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { EventAddApi, EventFindApi, EventUpdateApi, EventDeleteApi } from '../../ApiFile';
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';

const localizer = momentLocalizer(moment);

function Event() {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    let notificationTimer = null;

    useEffect(() => {
        let flag =  sessionStorage.getItem('flag');
        fetchEvents();
    }, []);

    let flag = Number(sessionStorage.getItem("flag"));

    const fetchEvents = async () => {
        try {
            const response = await fetch(EventFindApi);
            if (!response.ok) {
                throw new Error('Failed to fetch events');
            }
            const data = await response.json();
            const formattedEvents = data.map((event, index) => ({
                sn: index + 1,
                id: event.event_id,
                start: new Date(moment(event.event_date + ' ' + event.starttime, 'DD/MM/YYYY HH:mm:ss').toISOString()),
                end: new Date(moment(event.event_date + ' ' + event.starttime, 'DD/MM/YYYY HH:mm:ss').add(1, 'hour').toISOString()),
                title: event.discription,
                date: moment(event.event_date, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                description: event.discription,
                startTime: moment(event.starttime, 'HH:mm:ss').format('HH:mm')
            }));
            setEvents(formattedEvents);
        } catch (error) {
            console.error('Error fetching events:', error);
            toast.error('Failed to fetch events.');
        }
    };

    const handleSelectSlot = useCallback(({ start }) => {
        setSelectedEvent({ start, description: '', date: moment(start).format('DD/MM/YYYY'), startTime: '' });
        setOpenDialog(true);
    }, []);

    const handleSelectEvent = useCallback((event) => {
        setSelectedEvent(event);
        setOpenDialog(true);
    }, []);

    const handleEventChange = (e) => {
        const { name, value } = e.target;
        setSelectedEvent((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // const handleSave = async () => {
    //     try {
    //         const response = await fetch(EventAddApi, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 event_date: moment(selectedEvent.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    //                 discription: selectedEvent.description,
    //                 starttime: selectedEvent.startTime
    //             }),
    //         });

    //         if (!response.ok) {
    //             throw new Error('Failed to save event');
    //         }

    //         const data = await response.json();
    //         console.log(data); // Response message
    //         console.log("res..",response); 

    //         if (data == 'SAVED') {
    //             const newEvent = {
    //                 ...selectedEvent,
    //                 id: data.event_id, // Get the new event ID from the response
    //                 title: selectedEvent.description,
    //                 end: new Date(moment(selectedEvent.start).add(1, 'hour').toISOString())
    //             };
    //             setEvents([...events, newEvent]);
    //             fetchEvents();
    //             setOpenDialog(false);
    //             scheduleNotification(selectedEvent);
    //             toast.success('Event saved successfully');
    //         } else {
    //             toast.error('Failed to save event.');
    //         }
    //     } catch (error) {
    //         console.error('Error saving event:', error);
    //         toast.error('Failed to save event.');
    //     }
    // };
  
    const handleSave = async () => {
        try {
            const response = await axios.post(EventAddApi, {
                event_date: moment(selectedEvent.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                description: selectedEvent.description,
                starttime: selectedEvent.startTime
            });
    
            const data = response.data;
            console.log(data); // Response message
            console.log("res..", response); 
    
            if (data === 'SAVED') {
                const newEvent = {
                    ...selectedEvent,
                    id: data.event_id, // Assuming response contains event_id
                    title: selectedEvent.description,
                    end: new Date(moment(selectedEvent.start).add(1, 'hour').toISOString())
                };
                setEvents([...events, newEvent]);
                fetchEvents();
                setOpenDialog(false);
                scheduleNotification(selectedEvent);
                toast.success('Event saved successfully');
            } else {
                toast.error('Failed to save event.');
            }
        } catch (error) {
            console.error('Error saving event:', error);
            toast.error('Failed to save event.');
        }
    };
    

    const handleUpdate = async () => {
        try {
            const response = await fetch(`${EventUpdateApi}/${selectedEvent.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    event_date: moment(selectedEvent.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    discription: selectedEvent.description,
                    starttime: selectedEvent.startTime
                }),
            });

            if (response.ok) {
                const data = await response.text(); // Change to response.text() to handle plain text response
                console.log(data); // Response message
                if (data === 'UPDATE') {
                    setEvents(events.map((event) => 
                        (event.id === selectedEvent.id ? 
                            { ...selectedEvent, title: selectedEvent.description, end: new Date(moment(selectedEvent.start).add(1, 'hour').toISOString()) } 
                            : event
                        )
                    ));
                    setOpenDialog(false);
                    fetchEvents();
                    scheduleNotification(selectedEvent);
                    toast.success('Event updated successfully');
                } else {
                    toast.error('Failed to update event.');
                }
            } else {
                toast.error('Failed to update event.');
            }
        } catch (error) {
            console.error('Error updating event:', error);
            toast.error('Failed to update event.');
        }
    };

    const handleDelete = async () => {
        try {
            const response = await fetch(`${EventDeleteApi}/${selectedEvent.id}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Failed to delete event');
            }

            // DELETE operations typically do not return a body, just check status
            setEvents(events.filter((event) => event.id !== selectedEvent.id));
            setOpenDialog(false);
            clearNotification(selectedEvent);
            toast.success('Event deleted successfully');
        } catch (error) {
            console.error('Error deleting event:', error);
            toast.error('Failed to delete event.');
        }
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedEvent(null);
    };

    // Schedule notification 15 minutes before the event starts
    const scheduleNotification = (event) => {
        if (event.startTime) {
            const eventStartTime = moment(event.start);
            const notifyTime = eventStartTime.subtract(15, 'minutes').toDate();

            // Clear any existing notification timer
            clearNotification(event);

            // Schedule a new timer
            notificationTimer = setTimeout(() => {
                // toast.info(`The event "${event.description}" is about to start in 15 minutes!`);
            }, notifyTime.getTime() - Date.now());
        }
    };

    // Clear the notification timer for the given event
    const clearNotification = (event) => {
        if (notificationTimer) {
            clearTimeout(notificationTimer);
        }
    };

    const tableData = {
        columns: [
            {
                label: 'Serial No',
                field: 'sn',
                sort: 'asc'
            },
            {
                label: 'Event Description',
                field: 'description',
                sort: 'asc'
            },
            {
                label: 'Event Date',
                field: 'date',
                sort: 'asc'
            },
            {
                label: 'Start Time',
                field: 'startTime',
                sort: 'asc'
            }
        ],
        rows: events
    };

    return (
        <>
            <Sidebar />
            <section className="home-section">
                <NavLogo />
                <Row>
                    <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
                        Events
                    </h2>
                </Row>
                <div className='w-100 px-md-5 py-md-3'>

                {(flag == 1 ) && (
                    <Calendar
                        className='w-100 p-md-5'
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        selectable
                        onSelectSlot={handleSelectSlot}
                        onSelectEvent={handleSelectEvent}
                    />
                )}

                    <Row className="m-0 px-md-1 py-1 pb-5 mt-3  w-100" style={{ display: 'flex' }}>
                        <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
                            <h3 className="text-center" style={{color:"maroon"}}>Events List</h3>
                            <MDBDataTable
                                striped
                                bordered
                                className="custom-datatable"
                                small
                                data={tableData}
                            />
                        </Col>
                    </Row>

                    <Dialog open={openDialog} onClose={handleDialogClose}>
                        <DialogTitle>{selectedEvent?.id ? 'Edit Event' : 'Add Event'}</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                name="description"
                                size='small'
                                label="Event Description"
                                type="text"
                                fullWidth
                                value={selectedEvent?.description || ''}
                                onChange={handleEventChange}
                            />
                            <TextField
                                margin="dense"
                                name="date"
                                size='small'
                                label="Event Date"
                                type="text"
                                fullWidth
                                value={selectedEvent ? moment(selectedEvent.date, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''}
                                onChange={handleEventChange}
                            />
                            <TextField
                                margin="dense"
                                name="startTime"
                                size='small'
                                label="Start Time"
                                type="time"
                                fullWidth
                                value={selectedEvent?.startTime || ''}
                                onChange={handleEventChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose} color="primary">
                                Cancel
                            </Button>
                            {selectedEvent?.id && (
                                <Button onClick={handleDelete} color="secondary">
                                    Delete
                                </Button>
                            )}
                            {selectedEvent?.id ? (
                                <Button onClick={handleUpdate} color="primary">
                                    Update
                                </Button>
                            ) : (
                                <Button onClick={handleSave} color="primary">
                                    Save
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                </div>
            </section>
        </>
    );
}

export default Event;














