import React, { useState, useEffect } from 'react';
import logo1 from '../../StudentRegristrationDesign/jain logo.png';
import { Popover, TextField, Typography } from '@mui/material';
import { FaUserCircle } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";
import { Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FooterAll from '../../FooterAll';

const Header = () => {
    const memberId = sessionStorage.getItem('member_id');
    const memberName = sessionStorage.getItem('memberName');
    const email = sessionStorage.getItem('email');
    
 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClickPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
    };
    const open2 = Boolean(anchorEl);
    const id = open2 ? 'simple-popover' : undefined;
  
    const navigate = useNavigate();
    const logoutFun = () => {
        sessionStorage.clear();
        navigate('/');
    }
    const handlePasswordChange = () => {
        navigate('/passwordChangeStudent1');
    };


    return (<>
        <Row className=' py-1 m-0 p-0 ' style={{ display: "flex", backgroundColor: "#9acac9"}}>
            <Col sm={8} md={7} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={logo1} alt="Logo" style={{ mixBlendMode: "darken" }} className='w-100'/>
            </Col>
            <Col sm={2} md={4} className='pe-md-5 pe-0' style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                <FaUserCircle style={{ fontSize: 30, color: "rgb(47, 79, 79)" }} title='Profile' aria-describedby={id} variant="contained" onClick={handleClickPopover} />
                <Popover
                    id={id}
                    open={open2}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography className='w-100'> <button style={{backgroundColor:"rgb(179, 240, 240)",color:"black",borderRadius:"0px"}} className='text-end w-100'>{memberName} &nbsp; <FaUserCircle /></button> </Typography>
                    <Typography className='w-100'> <button style={{backgroundColor:"rgb(179, 240, 240)",color:"black",borderRadius:"0px"}}  className='text-end w-100' onClick={handlePasswordChange}>Change Password &nbsp; <RiLockPasswordFill /></button> </Typography>
                    <Typography className='w-100'> <button style={{backgroundColor:"rgb(179, 240, 240)",color:"black",borderRadius:"0px"}} className='text-end w-100' onClick={logoutFun}>Logout &nbsp;<AiOutlineLogout /></button> </Typography>
                </Popover>
                &nbsp; &nbsp;{memberName}
            </Col>
        </Row>
        <FooterAll />
        </>);
}

export default Header;
 

