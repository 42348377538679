import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import Sidebar from '../../studentDashboard/pages/sidebarstudent';
import Header from '../../studentDashboard/pages/Headerstudent';
import { DatePicker } from 'antd';
import { StudentMessAttendanceFindAll } from '../../ApiFile';

const { RangePicker } = DatePicker;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

function MessAttendance() {
    const [events, setEvents] = useState([]);
    const memberId = sessionStorage.getItem('member_id');
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);
    const [totalEvents, setTotalEvents] = useState(0); 
    const [dateRange, setDateRange] = useState([]);

    const handleDateChange = (dates) => {
        setDateRange(dates);
    };

    useEffect(() => {
        if (dateRange.length === 2) {
            const fetchEvents = async () => {
                try {
                    const body = {
                        fromdate: dateRange[0].format('DD/MM/YYYY'),
                        todate: dateRange[1].format('DD/MM/YYYY'),
                        member_id: memberId
                    };
                    const response = await fetch(StudentMessAttendanceFindAll, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setEvents(data);
                        calculateAttendanceCounts(data);
                    } else {
                        console.error('Error fetching events: Response not ok');
                    }
                } catch (error) {
                    console.error('Error fetching events:', error);
                }
            };

            fetchEvents();
        }
    }, [dateRange]);

    const calculateAttendanceCounts = (events) => {
        let presentCount = 0;
        let absentCount = 0;
        let totalMealCount = 0;

        events.forEach(event => {
            if (event.lunch_flag === 1) {
                presentCount += 1;
            } else if (event.lunch_flag === 2) {
                absentCount += 1;
            }

            if (event.dinner_flag === 1) {
                presentCount += 1;
            } else if (event.dinner_flag === 2) {
                absentCount += 1;
            }

            // Increment total meal count for both lunch and dinner
            if (event.lunch_flag !== 0) totalMealCount += 1;
            if (event.dinner_flag !== 0) totalMealCount += 1;
        });

        setTotalPresent(presentCount);
        setTotalAbsent(absentCount);
        setTotalEvents(totalMealCount); // Set total meal count (lunch + dinner)
    };

    const renderAttendanceStatus = (flag) => {
        if (flag === 1) {
            return <Button style={{ backgroundColor: 'green', color: "white" }}>Present</Button>;
        } else if (flag === 2) {
            return <Button style={{ backgroundColor: 'red', color: "white" }}>Absent</Button>;
        } else {
            return <Button style={{ backgroundColor: 'grey', color: "white" }}>Pending</Button>;
        }
    };

    const data = {
        columns: [
            {
                label: 'Serial No',
                field: 'serialNo',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Date',
                field: 'attendance_date',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Name',
                field: 'name',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Lunch',
                field: 'lunch_flag',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Dinner',
                field: 'dinner_flag',
                sort: 'asc',
                width: 100
            },
        ],
        rows: events.map((event, index) => ({
            serialNo: index + 1,
            attendance_date: event.attendance_date,
            name: event.name,
            lunch_flag: renderAttendanceStatus(event.lunch_flag),
            dinner_flag: renderAttendanceStatus(event.dinner_flag),
        }))
    };

    return (
        <>
            <Sidebar />
            <section className="home-section">
                <Header />
                <div className="mt-2 mb-5">
                    <Row className="m-0 pt-1 px-2 px-md-3 mb-2 rounded">
                        <Col className="m-0 p-0 px-3 py-1 rounded" style={{ background: 'white' }}>
                            <h4 style={{ color: "maroon" }}>My Mess Attendance</h4>
                        </Col>
                    </Row>
                    <Row className="m-0 pt-1 px-2 px-md-3 mb-2 rounded">
                        <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white', display: "flex" }}>
                            <label style={{ color: "maroon" }}>Select Date :</label> &nbsp;&nbsp;&nbsp;&nbsp;
                            <RangePicker format={dateFormatList} onChange={handleDateChange} />
                        </Col>
                    </Row>
                    <Row className="m-0 px-2 px-md-3 mb-5 rounded">
                        <Col className="m-0 p-0 px-3 py-2 rounded" style={{ background: 'white' }}>
                            <div style={{ overflowY: 'auto' }}>
                                <div style={{ overflowX: 'auto' }}>
                                    <MDBDataTable
                                        striped
                                        bordered
                                        className='custom-datatable text-center'
                                        small
                                        data={data}
                                    />
                                </div>
                            </div>
                            {/* <div className="text-center rounded py-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-around", border: "1px solid rgb(214, 222, 219)" }}>
                                <div style={{ color: "maroon" }}>Total Mess Conducted: {totalEvents}</div>
                                <div style={{ color: "green" }}>Total Present: {totalPresent}</div>
                                <div style={{ color: "red" }}>Total Absent: {totalAbsent}</div>
                            </div> */}
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
}

export default MessAttendance;
