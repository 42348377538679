import "./App.css";
import TemplateFileImport from "./TemplateDesign/Pages/TemplateFileImport";

function App() {
  return (
    <div className="App">
      {/* <Home/> */}
      <TemplateFileImport />
    </div>
  );
}

export default App;
