import React from 'react';

function Popup() {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        background: '#3c5077',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: '400px',
          background: '#fff',
          borderRadius: '6px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          padding: '0 30px 30px',
          color: '#333',
          transition: 'transform 0.4s, top 0.4s',
        }}
      >
        <img
          src="assets/img/t2.png"
          alt="Popup"
          style={{
            width: '100px',
            marginTop: '-50px',
            borderRadius: '50%',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          }}
        />
        <h2 style={{ fontSize: '38px', fontWeight: '500', margin: '30px 0 10px' }}>Submitted Successfully</h2>
        <p>Your details have been submitted successfully. Thanks!</p>
        <button
          type="button"
          style={{
            width: '100%',
            marginTop: '50px',
            padding: '10px 0',
            background: '#6fd649',
            color: '#fff',
            border: 0,
            outline: 'none',
            fontSize: '18px',
            borderRadius: '4px',
            cursor: 'pointer',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          <a href="/newpass" style={{ textDecoration: 'none' }}>OK</a>
        </button>
      </div>
    </div>
  );
}

export default Popup;