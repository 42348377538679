import React, { useState, useEffect } from 'react';
import './complain.css'; 
import Header from './Headerstudent';
import AddIcon from '@mui/icons-material/Add';
import Sidebar from './sidebarstudent';
import { Box, Button, Select, MenuItem, Modal, Typography, TextareaAutosize } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom';
import { ImagePath, StudentComplaintAddApi, StudentComplaintFindOneApi, StudentComplaintUpdateApi } from '../../ApiFile';
import { Visibility } from '@mui/icons-material';
import { toast } from 'react-toastify';

function StudentComplaintForm() {
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState('');
  const [file, setFile] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [complaints, setComplaints] = useState([]);
  const [open1, setOpen1] = useState(false); // State to control image modal
  const [selectedComplaint, setSelectedComplaint] = useState(null); // State to store selected complaint
  const [errors, setErrors] = useState({});
  const [updateOpen, setUpdateOpen] = useState(false); // State to control update modal
  const [updateReason, setUpdateReason] = useState('');
  const [selectedComplaintId, setSelectedComplaintId] = useState(null); // State to store selected complaint id for update
  const navigate = useNavigate();
  const memberId = sessionStorage.getItem('member_id');

  useEffect(() => {
    fetchComplaints();
  }, []);

  const fetchComplaints = async () => {
    try {
      const response = await axios.get(`${StudentComplaintFindOneApi}/${memberId}`);
      setComplaints(response.data);
      console.log("resd..",response.data);
    } catch (error) {
      console.error("Error fetching complaints:", error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDetails('');
    setFile(null);
    setSelectedValue('');
    setErrors({});
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!details) newErrors.details = "Details are required";
    if (!selectedValue) newErrors.selectedValue = "You must select an issue";
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formData = new FormData();
    formData.append('details', details);
    formData.append('about', selectedValue);
    formData.append('member_id', memberId);
    formData.append('file', file);

    try {
      await axios.post(StudentComplaintAddApi, formData);
      toast.success('Complaint Registered Successfully');
      handleClose();
      fetchComplaints();
    } catch (error) {
      console.error("Error saving complaint:", error);
    }
  };

  const handleModal = (complaint) => {
    setSelectedComplaint(complaint);
    setOpen1(true);
  };

  const handleUpdateModal = (complaint) => {
    setSelectedComplaintId(complaint.id); // Assuming complaint has an 'id' field
    setUpdateOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setUpdateOpen(false);
    setUpdateReason('');
  };

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.put(`${StudentComplaintUpdateApi}/${selectedComplaintId}`, {
        status: 9, // Example status update
        reason: updateReason,
      });

      toast.success('Complaint Updated Successfully');
      handleCloseUpdateModal();
      fetchComplaints(); // Refresh complaints after update
    } catch (error) {
      console.error("Error updating complaint:", error);
    }
  };

  const getStatusButton = (status) => {
    const statusMap = {
      0: { text: 'Pending', color: 'orange' },
      1: { text: 'Within 1 Month', color: 'orange' },
      2: { text: 'Within 1 day', color: 'orange' },
      3: { text: 'Within 2 days', color: 'orange' },
      4: { text: 'Within 4 days', color: 'orange' },
      5: { text: 'Within 1 week', color: 'orange' },
      6: { text: 'Completed', color: 'green' },
      7: { text: 'InProcess', color: 'purple' },
      8: { text: 'Rejected', color: 'red' },
      9: { text: 'Recomplaint', color: 'orange' }
    };
    const statusInfo = statusMap[status] || { text: 'Unknown', color: 'default' };
    return (
      <Button variant="contained" style={{ backgroundColor: statusInfo.color }}>
        {statusInfo.text}
      </Button>
    );
  };

  const data = {
    columns: [
      { label: 'Serial No', field: 'serial' },
      { label: 'Date', field: 'comapliant_date', sort: 'asc' }, // Assuming complaint_date is the correct field name
      { label: 'Time', field: 'comapliant_time', sort: 'asc' }, // Assuming complaint_time is the correct field name
      { label: 'About', field: 'about', sort: 'asc' },
      { label: 'Details', field: 'details', sort: 'asc' },
      { label: 'Image', field: 'upload_img', sort: 'asc' },
      { label: 'Status', field: 'status', sort: 'asc' },
      // { label: 'Update', field: 'update', sort: 'asc' },
      complaints.some(complaint => complaint.status === 6) ? { label: 'Update', field: 'update', sort: 'asc' } : null,
    ].filter(column => column !== null),
    rows: complaints.map((complaint, index) => ({
      serial: index + 1,
      ...complaint,
      upload_img: (
        <Button variant="contained" color="primary" onClick={() => handleModal(complaint)}>
          <Visibility fontSize="small" />
        </Button>
      ),
      status: getStatusButton(complaint.status),
      update: complaint.status === 6 ? (
        <Button variant="contained" color="info" onClick={() => handleUpdateModal(complaint)}>
          Update
        </Button>
      ) : null,
    })),
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <Header />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Complaint
          </h2>
          <Button
            className="ms-md-4 m-0  ms-2  mb-2 mb-md-2 mt-2 mt-md-4"
            variant="contained"
            color="primary"
            onClick={handleOpen} 
            startIcon={<AddIcon />}
          >
            Register Complaint
          </Button>
        </div>

        <Row className="m-0 p-0 px-md-3 py-1 pb-5 mb-5 mt-2 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable striped className="custom-datatable text-center" bordered hover small data={data} />
              </div>
            </div>
          </Col>
        </Row>

        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Typography className="mb-4 text-center">
              <h3 style={{ color: "maroon" }}>Register Complaint</h3>
            </Typography>
            <form onSubmit={handleSubmit}>
              <Select
                value={selectedValue}
                onChange={handleSelectChange}
                fullWidth
                size='small'
                displayEmpty
                error={Boolean(errors.selectedValue)}
                style={{ marginBottom: '15px' }}
              >
                <MenuItem value="" disabled>
                  Select Issue Related 
                </MenuItem>
                <MenuItem value='Electrical Related'>Electrical Related</MenuItem>
                <MenuItem value='Room Related'>Room Related</MenuItem>
                <MenuItem value='Water Related'>Water Related</MenuItem>
                <MenuItem value='Washroom Related'>Washroom Related</MenuItem>
                <MenuItem value='Cleaning Related'>Cleaning Related</MenuItem>
                <MenuItem value='Building Related'>Building Related</MenuItem>
                <MenuItem value='Safety Related'>Safety Related</MenuItem>
                <MenuItem value='Mess Related'>Mess Related</MenuItem>
                <MenuItem value='Ragging Related'>Ragging Related</MenuItem>
                <MenuItem value='Staff Related'>Staff Related</MenuItem>
                <MenuItem value='Other'>Other</MenuItem>
              </Select>
              {errors.selectedValue && <p style={{ color: 'red' }}>{errors.selectedValue}</p>}

              <TextareaAutosize
                minRows={2}
                placeholder="Enter Details"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                style={{ width: '100%', marginBottom: '15px' }}
              />
              {errors.details && <p style={{ color: 'red' }}>{errors.details}</p>}

              <input
                className='w-100'
                type="file"
                onChange={handleFileChange}
                style={{ marginBottom: '10px', borderRadius:"5px"}}
              />
              {errors.file && <p style={{ color: 'red' }}>{errors.file}</p>}

              <Row>
                <Col className='text-end'>
                  <Button
                    className="mt-4"
                    style={{ color: 'black', backgroundColor: 'white', border: '2px solid rgb(135, 178, 178)', borderRadius: '8' }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                    className="py-2 px-3 mt-4 shadow text-dark"
                    type="submit"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </form>
          </Box>
        </Modal>

        <Modal
          open={open1}
          onClose={() => setOpen1(false)}
          aria-labelledby="status-modal-title"
          aria-describedby="status-modal-description"
          sx={{
            display: 'flex',
            overflow: 'scroll',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '700px',
              maxHeight: '90vh',
              overflow: "scroll",
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ p: 3, borderTop: '1px solid #ccc' }}>
              <Row>
                <div className="block-header">
                  <h2 className="mb-2 mt-1" style={{ color: 'maroon', borderBottom: "2px solid maroon" }}>
                    Complaint Image
                  </h2>
                </div>

                <Box sx={{ overflowY: 'auto', p: 4}}>
                  <Typography id="modal-modal-description" style={{ lineHeight: "18px" }}>
                    <Row style={{ display: "flex" }}>
                      <Col><b>Uploaded Image</b>:</Col>
                      <Col className="mt-3 text-center" >
                        <img 
                          src={`${ImagePath}/${selectedComplaint && selectedComplaint.upload_img}`}
                          className='acceptable rounded shadow border-0'
                          alt='Image Not Found'
                          style={{ height: "16rem", width: "15rem", border: "1px solid grey" }}
                        />
                      </Col>
                    </Row>
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 4, position: 'absolute', bottom: '16px', right: '16px' }}
                    onClick={() => setOpen1(false)}
                  >
                    Close
                  </Button>
                </Box>
              </Row>
            </Box>
          </Box>
        </Modal>

        <Modal open={updateOpen} onClose={handleCloseUpdateModal} aria-labelledby="update-modal-title" aria-describedby="update-modal-description">
          <Box sx={style}>
            <Typography className="mb-4 text-center">
              <h3 style={{ color: "maroon" }}>Update Complaint</h3>
            </Typography>
            <form onSubmit={handleUpdateSubmit}>
              <TextareaAutosize
                minRows={2}
                placeholder="Enter Reason"
                value={updateReason}
                onChange={(e) => setUpdateReason(e.target.value)}
                style={{ width: '100%', marginBottom: '15px' }}
              />
              <Row>
                <Col className='text-end'>
                  <Button
                    className="mt-4"
                    style={{ color: 'black', backgroundColor: 'white', border: '2px solid rgb(135, 178, 178)', borderRadius: '8' }}
                    onClick={handleCloseUpdateModal}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ border: '0px', backgroundColor: 'rgb(135, 178, 178)' }}
                    className="py-2 px-3 mt-4 shadow text-dark"
                    type="submit"
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </form>
          </Box>
        </Modal>
      </section>
    </>
  );
}

export default StudentComplaintForm;
