import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AdminLoginApi } from '../../ApiFile';

function Slogin() {

  const navigate=useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
     if (!formData.email || !formData.password) {
      toast.error('Please fill in all fields.');
      return;
    }
    try {
      const response = await axios.post(AdminLoginApi, {
        email_id: formData.email,
        password: formData.password
      });
      console.log("res obj...",response);

      if (response.data === 'login successful') {
        toast.success("Login successful!");
        navigate('/Signup'); 
      } else if(response.data === 'NOTFOUND'){
        toast.error("Invalid login details..");
      }
       else {
        window.alert('Invalid Login Details...');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      // handle error, maybe show a message to the user
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const fieldStyle = {
    position: 'relative',
    height: '45px',
    width: '100%',
    display: 'flex',
    background: 'rgba(255,255,255,0.94)',
    marginTop: '14px',
  };

  const inputStyle = {
    height: '100%',
    width: '100%',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    color: '#222',
    fontSize: '16px',
    fontFamily: "'Poppins', sans-serif",
  };

  return (
    <div
    style={{
      backgroundImage: "url('assets/img/about.jpg')",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      height: '100vh',
    }}
  >
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: 999,
        textAlign: 'center',
        padding: '60px 32px',
        width: '370px',
        transform: 'translate(-50%,-50%)',
        boxShadow: '3px 4px 28px 0px rgba(0,0,0,0.75)',
        backgroundColor: 'rgba(234, 225, 218,0.6)',
      }}
    >
      <div
        style={{
          height: '80px',
          width: '300px',
          marginTop: '-30px',
          borderRadius: '2px',
        }}
      >
        <img
          src="assets/img/jain logo.png"
          alt="Logo"
          style={{ height: '70%', width: '100%' }}
        />
      </div>
      <header
        style={{
          color: 'black',
          fontSize: '33px',
          fontWeight: 600,
          margin: '9px 0 35px 0',
          fontFamily: "'Montserrat', sans-serif",
        }}
      >
        Login
      </header>
      <form onSubmit={handleSubmit}>
        <div style={fieldStyle}>
          <span style={{ color: '#222', width: '40px', lineHeight: '45px' }}>
            <i className="fa fa-envelope"></i>
          </span>
          <input
            type="email"
            required
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            style={inputStyle}
          />
        </div>
        <div style={fieldStyle}>
          <span style={{ color: '#222', width: '40px', lineHeight: '45px' }}>
            <i className="fa fa-lock"></i>
          </span>
          <input
            type={showPassword ? 'text' : 'password'}
            className="pass-key"
            required
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            style={inputStyle}
          />
          <span
            className="show"
            onClick={togglePasswordVisibility}
            style={{
              color: '#222',
              fontSize: '13px',
              fontWeight: 700,
              cursor: 'pointer',
              display: 'inline-block',
              fontFamily: "'Montserrat', sans-serif",
              padding: '3px',
              marginRight:'10px',
              marginTop:'7px'
            }}
          >
            <i className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} aria-hidden="true"></i>
          </span>
        </div>
        <div style={{ marginTop: '14px' }} className="field">
          <input
            type="submit"
            value="Login"
            style={{
              background: '#3498db',
              border: '1px solid #2691d9',
              color: 'white',
              fontSize: '18px',
              letterSpacing: '1px',
              fontWeight: 600,
              cursor: 'pointer',
              fontFamily: "'Montserrat', sans-serif",
              padding: '10px 0',
              width: '100%',
            }}
          />
        </div>
        <div style={{ marginTop: '14px', fontSize: '18px', color: '#222', fontstyle:'Bold' }}>
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </div>
        <div style={{ marginTop: '14px', fontSize: '18px', color: '#222', fontstyle:'Bold' }}>
          <a href="/Forgotpass" style={{color:'blue'}}>forgot password?</a>
        </div>
      </form>
    </div>
  </div>
  );
}

export default Slogin;
