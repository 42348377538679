import React, { useEffect, useState } from 'react'
import Sidebar from '../../AluminiAdminDashboardDesign/sidebar'
import NavLogo from '../../AluminiAdminDashboardDesign/NavLogo'
import { Col, Row } from 'react-bootstrap'
import { MDBDataTable } from 'mdbreact'
import axios from 'axios'
import { AdminMessFeedbackApi } from '../../ApiFile'

const FeedBack = () => {

  const [rows, setRows] = useState([]);

  const fetchData = () => {
    axios.get(AdminMessFeedbackApi)
      .then(response => {
        const mappedRows = response.data.map((row, index) => ({
          ...row,
          Name: row.name,
        }));
        setRows(mappedRows);
        // console.log("mapped rows: ", mappedRows);
      })
      .catch(error => {
        // console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);


  const data = {
    columns: [
      { label: 'Sr.No', field: 'srNo' },
      { label: 'Student Name', field: 'name', width: 150 },
      { label: 'Feedback Date', field: 'feedback_date', width: 150 },
      { label: 'Block No', field: 'block_id', width: 150 },
      { label: 'Room No', field: 'room_id', width: 150 },
      {
        label: 'Feedback',
        field: 'feedback',
        sort: 'asc',
        width: 270,
      },
   
     
    ],
    rows: rows.map((row,index) => ({
      srNo:index+1,
      ...row,
      
    }))
  };

  return (<>
    <Sidebar />
    <section className="home-section">
      <NavLogo />
      <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
           Mess Feedback
          </h2>
        </div>

        <Row className="m-0 p-0 px-md-3 py-1 pb-5 mb-5 mt-4 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable striped className="custom-datatable text-center" bordered hover small data={data} />
              </div>
            </div>
          </Col>
        </Row>

    
    </section>
    </>
  )
}

export default FeedBack
