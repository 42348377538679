import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Button, MenuItem, TextareaAutosize, TextField, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { MDBDataTable } from 'mdbreact';
import { toast } from 'react-toastify';
import Sidebar from '../../studentDashboard/pages/sidebarstudent';
import Header from '../../studentDashboard/pages/Headerstudent';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { AdminBreakfastFindAllApi,StudentBreakfastReqAdd,StudentBreakfastReqDelete,StudentBreakfastReqFindAll, StudentBreakfastReqUpdate } from '../../ApiFile';

const BreakFastReq = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [reason, setReason] = useState('');
  const [leaveData, setLeaveData] = useState([]);
  const [editingLeave, setEditingLeave] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [leaveIdToDelete, setLeaveIdToDelete] = useState(null);
  const [loading, setLoading] = useState(false); // Added loading state
  const [date, setDate] = useState();

  const memberId = sessionStorage.getItem('member_id');

  useEffect(() => {
    if (memberId) {
      fetch(AdminBreakfastFindAllApi)
        .then((response) => response.json())
        .then((data) => setEvents(data))
        .catch((error) => console.error('Error fetching events:', error));
    }
  }, [memberId]);

  useEffect(() => {
    if (memberId) {
      fetch(`${StudentBreakfastReqFindAll}/${memberId}`)
        .then((response) => response.json())
        .then((data) => setLeaveData(data))
        .catch((error) => console.error('Error fetching leave data:', error));
    }
  }, [memberId]);

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedEvent || !date) {
      toast.warn('Please select a menu and date.');
      return;
    }

    const data = {
      member_id: memberId,
      breakfast_menu: selectedEvent,
      request_date: dayjs(date).format('DD/MM/YYYY')
    };

    try {
      const response = await fetch(StudentBreakfastReqAdd, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success('Breakfast request submitted successfully!');
        setSelectedEvent('');
        setDate(null);
          fetch(`${StudentBreakfastReqFindAll}/${memberId}`)
          .then((response) => response.json())
          .then((data) => setLeaveData(data))
          .catch((error) => console.error('Error fetching leave data:', error));
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Error submitting breakfast request');
      }
    } catch (error) {
      console.error('Network error:', error);
      toast.error('Network error');
    }
  };

  const handleUpdate = async (leaveId) => {
    const data = {
      member_id: memberId,
      breakfast_menu: selectedEvent,
      request_date: dayjs(date).format('DD/MM/YYYY')
    };

    try {
      const response = await fetch(`${StudentBreakfastReqUpdate}/${leaveId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success('Breakfast request updated successfully!');
        setEditingLeave(null);
        setSelectedEvent('');
        setDate(null);
          fetch(`${StudentBreakfastReqFindAll}/${memberId}`)
          .then((response) => response.json())
          .then((data) => setLeaveData(data))
          .catch((error) => console.error('Error fetching leave data:', error));
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Error updating breakfast request');
      }
    } catch (error) {
      console.error('Network error:', error);
      toast.error('Network error');
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${StudentBreakfastReqDelete}/${leaveIdToDelete}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        toast.success('Breakfast request canceled successfully!');
          fetch(`${StudentBreakfastReqFindAll}/${memberId}`)
          .then((response) => response.json())
          .then((data) => setLeaveData(data))
          .catch((error) => console.error('Error fetching leave data:', error));
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Error canceling breakfast request');
      }
    } catch (error) {
      console.error('Network error:', error);
      toast.error('Network error');
    } finally {
      setShowModal(false);
      setLoading(false); // Reset loading state
    }
  };

  const handleCancelConfirm = async () => {
    setLoading(true); // Set loading state
    await handleDelete();
  };

  const tableData = {
    columns: [
      { label: 'Sr.No', field: 'SrNo', sort: 'asc', width: 150 },
      { label: 'Date', field: 'request_date', sort: 'asc', width: 270 },
      { label: 'Menu', field: 'menu_name', sort: 'asc', width: 270 },
      { label: 'Status', field: 'approval_status', sort: 'asc', width: 200 },
      {
        label: 'Update',
        field: 'update',
        width: 100,
      },
      {
        label: 'Cancel',
        field: 'cancel',
        width: 100,
      }
    ],
    rows: leaveData.map((leave, index) => ({
      SrNo: index + 1,
      request_date: leave.request_date,
      menu_name: leave.menu_name,
      approval_status:
        leave.approval_status === 0
          ? <Button style={{ backgroundColor: 'orange', color: "white" }}>Pending</Button>
          : leave.approval_status === 1
            ? <Button style={{ backgroundColor: 'green', color: "white" }}>Approved</Button>
            : leave.approval_status === 2
              ? <Button style={{ backgroundColor: 'red', color: "white" }}>Rejected</Button>
              : '',
      update:
        leave.approval_status === 0 ? (
          <Button onClick={() => {
            setEditingLeave(leave.id);
            setSelectedEvent(leave.menuid);
            setDate(dayjs(leave.request_date, 'DD/MM/YYYY'));
          }} variant="contained" color="info">
            Update
          </Button>
        ) : null,
      cancel:
        leave.approval_status === 0 ? (
          <Button
            onClick={() => {
              setLeaveIdToDelete(leave.id);
              setShowModal(true);
            }}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
        ) : null,
    })),
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <Header />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Breakfast Request
          </h2>
        </div>

        <Row className="m-0 p-0 py-1 px-md-5 px-2 mt-4 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
            <Row>
              <Col sm={12} md={5} className="mt-3 mt-md-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      label='Select Date'
                      className='w-100'
                      value={date}
                      style={{ width: "100%" }}
                      onChange={(newDate) => {
                        console.log("date res", newDate);
                        setDate(newDate);
                      }}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Col>
              <Col sm={12} md={5} className="mt-3 mt-md-0 pt-md-0">
                <Form.Group controlId="eventSelect">
                  <TextField
                    id="eventSelect"
                    select
                    size="small"
                    label="Select Menu"
                    fullWidth
                    value={selectedEvent}
                    onChange={handleEventChange}
                    variant="outlined"
                    margin="normal"
                  >
                    {events.map((event) => (
                      <MenuItem key={event.id} value={event.id}>
                        {event.menu_name} - ({event.price})
                      </MenuItem>
                    ))}
                  </TextField>
                </Form.Group>
              </Col>
              <Col sm={12} md={2} className="mt-3 mt-md-3 pt-md-0">
                <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    style={{ backgroundColor: 'rgb(0, 120, 215)', color: 'white' }}
                    onClick={editingLeave ? () => handleUpdate(editingLeave) : handleSubmit}
                  >
                    {editingLeave ? 'UPDATE' : 'APPLY'}
                  </Button>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="m-0 p-0 py-1 pb-5 px-md-5 px-2 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
            <MDBDataTable
              striped
              bordered
              className="custom-datatable"
              hover
              data={tableData}
            />
          </Col>
        </Row>

        {/* Confirmation Dialog for Cancel */}
        <Dialog open={showModal} onClose={() => setShowModal(false)} maxWidth="sm" fullWidth>
          <DialogTitle style={{ color: 'maroon' }}>Confirm Cancellation</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to cancel this request?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} color="primary">
              No
            </Button>
            <Button onClick={handleCancelConfirm} color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Yes, Cancel'}
            </Button>
          </DialogActions>
        </Dialog>
      </section>
    </>
  );
};

export default BreakFastReq;





// import React, { useEffect, useState } from 'react';
// import { Col, Row, Form } from 'react-bootstrap';
// import { Button, MenuItem, TextField, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, TextareaAutosize } from '@mui/material';
// import { toast } from 'react-toastify';
// import Sidebar from '../../studentDashboard/pages/sidebarstudent';
// import Header from '../../studentDashboard/pages/Headerstudent';
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { Table, Input } from 'antd';
// import dayjs from "dayjs";
// import { AdminBreakfastFindAllApi, StudentBreakfastReqAdd, StudentBreakfastReqDelete, StudentBreakfastReqFindAll, StudentBreakfastReqUpdate } from '../../ApiFile';

// const BreakFastReq = () => {
//   const [events, setEvents] = useState([]);
//   const [selectedEvent, setSelectedEvent] = useState('');
//   const [reason, setReason] = useState('');
//   const [leaveData, setLeaveData] = useState([]);
//   const [editingLeave, setEditingLeave] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [leaveIdToDelete, setLeaveIdToDelete] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [date, setDate] = useState();

//   const memberId = sessionStorage.getItem('member_id');

//   useEffect(() => {
//     if (memberId) {
//       fetch(AdminBreakfastFindAllApi)
//         .then((response) => response.json())
//         .then((data) => setEvents(data))
//         .catch((error) => console.error('Error fetching events:', error));
//     }
//   }, [memberId]);

//   useEffect(() => {
//     if (memberId) {
//       fetch(`${StudentBreakfastReqFindAll}/${memberId}`)
//         .then((response) => response.json())
//         .then((data) => setLeaveData(data))
//         .catch((error) => console.error('Error fetching leave data:', error));
//     }
//   }, [memberId]);

//   const handleEventChange = (event) => {
//     setSelectedEvent(event.target.value);
//   };

//   const handleReasonChange = (event) => {
//     setReason(event.target.value);
//   };

//   const handleSubmit = async () => {
//     if (!selectedEvent || !date) {
//       toast.warn('Please select a menu and date.');
//       return;
//     }

//     const data = {
//       member_id: memberId,
//       breakfast_menu: selectedEvent,
//       request_date: dayjs(date).format('DD/MM/YYYY')
//     };

//     try {
//       const response = await fetch(StudentBreakfastReqAdd, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(data),
//       });

//       if (response.ok) {
//         toast.success('Breakfast request submitted successfully!');
//         setSelectedEvent('');
//         setDate(null);
//         fetchLeaveData(); // Refetch data after submit
//       } else {
//         const errorData = await response.json();
//         toast.error(errorData.message || 'Error submitting breakfast request');
//       }
//     } catch (error) {
//       console.error('Network error:', error);
//       toast.error('Network error');
//     }
//   };

//   const handleUpdate = async (leaveId) => {
//     const data = {
//       member_id: memberId,
//       breakfast_menu: selectedEvent,
//       request_date: dayjs(date).format('DD/MM/YYYY')
//     };

//     try {
//       const response = await fetch(`${StudentBreakfastReqUpdate}/${leaveId}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(data),
//       });

//       if (response.ok) {
//         toast.success('Breakfast request updated successfully!');
//         setEditingLeave(null);
//         setSelectedEvent('');
//         setDate(null);
//         fetchLeaveData(); // Refetch data after update
//       } else {
//         const errorData = await response.json();
//         toast.error(errorData.message || 'Error updating breakfast request');
//       }
//     } catch (error) {
//       console.error('Network error:', error);
//       toast.error('Network error');
//     }
//   };

//   const fetchLeaveData = () => {
//     fetch(`${StudentBreakfastReqFindAll}/${memberId}`)
//       .then((response) => response.json())
//       .then((data) => setLeaveData(data))
//       .catch((error) => console.error('Error fetching leave data:', error));
//   };

//   const handleDelete = async () => {
//     try {
//       const response = await fetch(`${StudentBreakfastReqDelete}/${leaveIdToDelete}`, {
//         method: 'DELETE',
//       });

//       if (response.ok) {
//         toast.success('Breakfast request canceled successfully!');
//         fetchLeaveData(); // Refetch data after delete
//       } else {
//         const errorData = await response.json();
//         toast.error(errorData.message || 'Error canceling breakfast request');
//       }
//     } catch (error) {
//       console.error('Network error:', error);
//       toast.error('Network error');
//     } finally {
//       setShowModal(false);
//       setLoading(false);
//     }
//   };

//   const handleCancelConfirm = async () => {
//     setLoading(true);
//     await handleDelete();
//   };

//   const getColumnSearchProps = (dataIndex) => ({
//     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
//       <div style={{ padding: 8 }}>
//         <Input
//           placeholder={`Search ${dataIndex}`}
//           value={selectedKeys[0]}
//           onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
//           onPressEnter={confirm}
//           style={{ marginBottom: 8, display: 'block' }}
//         />
//         <Button
//           type="primary"
//           onClick={confirm}
//           size="small"
//           style={{ width: 90, marginRight: 8 }}
//         >
//           Search
//         </Button>
//         <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
//           Reset
//         </Button>
//       </div>
//     ),
//     filterIcon: (filtered) => (
//       <i className="fas fa-search" style={{ color: filtered ? '#1890ff' : undefined }} />
//     ),
//     onFilter: (value, record) =>
//       record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
//   });

//   const columns = [
//     {
//       title: 'Sr.No',
//       dataIndex: 'SrNo',
//       key: 'SrNo',
//       ...getColumnSearchProps('SrNo'),
//     },
//     {
//       title: 'Date',
//       dataIndex: 'request_date',
//       key: 'request_date',
//       ...getColumnSearchProps('request_date'),
//     },
//     {
//       title: 'Menu',
//       dataIndex: 'menu_name',
//       key: 'menu_name',
//       ...getColumnSearchProps('menu_name'),
//     },
//     {
//       title: 'Status',
//       dataIndex: 'approval_status',
//       key: 'approval_status',
//       render: (approval_status) => {
//         if (approval_status === 0) {
//           return <Button style={{ backgroundColor: 'orange', color: 'white' }}>Pending</Button>;
//         } else if (approval_status === 1) {
//           return <Button style={{ backgroundColor: 'green', color: 'white' }}>Approved</Button>;
//         } else if (approval_status === 2) {
//           return <Button style={{ backgroundColor: 'red', color: 'white' }}>Rejected</Button>;
//         }
//         return '';
//       },
//     },
//     {
//       title: 'Update',
//       key: 'update',
//       render: (text, record) =>
//         record.approval_status === 0 ? (
//           <Button
//             onClick={() => {
//               setEditingLeave(record.id);
//               setSelectedEvent(record.menuid);
//               setDate(dayjs(record.request_date, 'DD/MM/YYYY'));
//             }}
//             variant="contained"
//             color="info"
//           >
//             Update
//           </Button>
//         ) : null,
//     },
//     {
//       title: 'Cancel',
//       key: 'cancel',
//       render: (text, record) =>
//         record.approval_status === 0 ? (
//           <Button
//             onClick={() => {
//               setLeaveIdToDelete(record.id);
//               setShowModal(true);
//             }}
//             variant="contained"
//             color="error"
//           >
//             Cancel
//           </Button>
//         ) : null,
//     },
//   ];

//   const dataSource = leaveData.map((leave, index) => ({
//     key: index + 1,
//     SrNo: index + 1,
//     request_date: leave.request_date,
//     menu_name: leave.menu_name,
//     approval_status: leave.approval_status,
//     id: leave.id,
//     menuid: leave.menuid,
//   }));

//   return (
//     <>
//       <Sidebar />
//       <section className="home-section">
//         <Header />
//         <div className="block-header">
//           <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
//             Breakfast Request
//           </h2>
//         </div>

//         <Row className="m-0 p-0 py-1 px-md-5 px-2 mt-4 w-100" style={{ display: 'flex' }}>
//           <Col className="col-12">
//             <Form>
//               <Row className="my-3">
//                 <Col md="4" className="mb-3">
//                   <TextField
//                     select
//                     fullWidth
//                     label="Select Menu"
//                     value={selectedEvent}
//                     onChange={handleEventChange}
//                     variant="outlined"
//                   >
//                     {events.map((event) => (
//                       <MenuItem key={event.id} value={event.id}>
//                         {event.menu_name}
//                       </MenuItem>
//                     ))}
//                   </TextField>
//                 </Col>
//                 <Col md="4" className="mb-3">
//                   <LocalizationProvider dateAdapter={AdapterDayjs}>
//                     <DatePicker
//                       label="Select Date"
//                       value={date}
//                       onChange={(newValue) => setDate(newValue)}
//                       renderInput={(params) => <TextField {...params} fullWidth />}
//                     />
//                   </LocalizationProvider>
//                 </Col>
//                 <Col md="4" className="mb-3">
//                   <TextareaAutosize
//                     aria-label="Reason"
//                     placeholder="Reason"
//                     minRows={3}
//                     value={reason}
//                     onChange={handleReasonChange}
//                     style={{ width: '100%', padding: '10px' }}
//                   />
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md="4" className="mb-3">
//                   {editingLeave ? (
//                     <Button
//                       variant="contained"
//                       color="info"
//                       fullWidth
//                       onClick={() => handleUpdate(editingLeave)}
//                     >
//                       Update Request
//                     </Button>
//                   ) : (
//                     <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
//                       Submit Request
//                     </Button>
//                   )}
//                 </Col>
//               </Row>
//             </Form>
//           </Col>
//         </Row>

//         <div className="p-3">
//           <Table
//             columns={columns}
//             dataSource={dataSource}
//             pagination={{ pageSize: 5 }}
//             bordered
//           />
//         </div>

//         <Dialog
//           open={showModal}
//           onClose={() => setShowModal(false)}
//           aria-labelledby="alert-dialog-title"
//           aria-describedby="alert-dialog-description"
//         >
//           <DialogTitle id="alert-dialog-title">Cancel Confirmation</DialogTitle>
//           <DialogContent>
//             Are you sure you want to cancel this breakfast request?
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setShowModal(false)} color="primary">
//               No
//             </Button>
//             <Button
//               onClick={handleCancelConfirm}
//               color="secondary"
//               autoFocus
//               disabled={loading}
//             >
//               {loading ? <CircularProgress size={24} /> : 'Yes'}
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </section>
//     </>
//   );
// };

// export default BreakFastReq;


