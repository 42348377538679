import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import ReportNavbar from './ReportNavbar';
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import {  Select } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ReporStudentWiseEventList, ReportDateWiseEventList, ReportEvent, ReportEventList } from '../../ApiFile';

const EventWiseAttendance = () => {
 
  //========Code for Date wise event ============

const [members, setMembers] = useState([]);
const [events, setEvents] = useState([]);
const [event, setEvent] = useState(null);
const [eventWise, setEventWise] = useState(true);

// Fetch the events data
useEffect(() => {
  axios.get(ReportEventList)
    .then(response => setEvents(response.data))
    .catch(error => console.error('Error fetching events:', error));
}, []);

const handleEventChange = (selectedOption) => {
  setEvent(selectedOption);
};


const handleEventWiseShow = () => {
  axios.get(`${ReportDateWiseEventList}/${event}`)
    .then(response => setMembers(response.data))
    .catch(error => console.error('Error fetching member list:', error));
};

const rows = members.map((member, index) => ({
  serial: index + 1,
  event_date: member.event_date,
  discription: member.discription,
  name: member.name,
  block_id: member.block_id,
  room_id: member.room_id,
  attendance_flag: member.attendance_flag,
  manually_biometric: member.manually_biometric,
}));

const data = {
  columns: [
    { label: 'Serial No', field: 'serial', sort: 'asc' },
    { label: 'Date', field: 'event_date', sort: 'asc' },
    { label: 'Student Name', field: 'name', sort: 'asc' },
    { label: 'Block No', field: 'block_id', sort: 'asc' },
    { label: 'Room No', field: 'room_id', sort: 'asc' },
    { label: 'Event Description', field: 'discription', sort: 'asc' },
    { label: 'Attendance', field: 'attendance_flag', sort: 'asc' },
    { label: 'Attendance Type', field: 'manually_biometric', sort: 'asc' },
  ],
  rows: rows
};

// Calculate totals
const totalCount = members.length;
const totalPresent = members.filter(member => member.attendance_flag === 'Present').length;
const totalAbsent = members.filter(member => member.attendance_flag === 'Absent').length;

const handleEventWise =()=>{
  setStudentWise(false);
  setEventWise(true);
}


const handleExcelData = () => {
  const ws = XLSX.utils.json_to_sheet(rows);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Student_Attendance_(Event_Wise).xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handlePdfData = () => {
  const doc = new jsPDF();
  const columns = data.columns.map((col) => col.label);
  const rowsData = rows.map((row) => [row.serial,row.event_date,row.name,row.block_id,row.room_id,row.discription,row.attendance_flag,row.manually_biometric]);

  const title = 'Student Attendance (Event Wise)';

  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);

  doc.autoTable({
    head: [columns],
    body: rowsData,
    startY: 20,
  });

  const blob = doc.output('blob');
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Student_Attendance_(Event_Wise).pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};



//========Code for Student Wise============

const [members1, setMembers1] = useState([]);
const [studentData, setStudentData] = useState([]);
const [selectedStudentId, setSelectedStudentId] = useState('');
const [studentWise, setStudentWise] = useState(false);

 // Fetch the student data
useEffect(() => {
 
  axios.get(ReportEvent)
    .then(response => setStudentData(response.data))
    .catch(error => console.error('Error fetching events:', error));
}, []);


const handleStudentWiseShow = () => {

  axios.get(`${ReporStudentWiseEventList}/${selectedStudentId}`)
    .then(response => setMembers1(response.data))
    .catch(error => console.error('Error fetching member list:', error));
};

const rows1 = members1.map((member, index) => ({
  serial: index + 1,
  event_date: member.event_date,
  discription: member.discription,
  name: member.name,
  block_id: member.block_id,
  room_id: member.room_id,
  attendance_flag: member.attendance_flag,
  manually_biometric: member.manually_biometric,
}));

const data1 = {
  columns: [
    { label: 'Serial No', field: 'serial', sort: 'asc' },
    { label: 'Date', field: 'event_date', sort: 'asc' },
    { label: 'Student Name', field: 'name', sort: 'asc' },
    { label: 'Block No', field: 'block_id', sort: 'asc' },
    { label: 'Room No', field: 'room_id', sort: 'asc' },
    { label: 'Event Description', field: 'discription', sort: 'asc' },
    { label: 'Attendance', field: 'attendance_flag', sort: 'asc' },
    { label: 'Attendance Type', field: 'manually_biometric', sort: 'asc' },
  ],
  rows: rows1
};

// Calculate totals
const totalEventCount = members1.length;
const totalPresentEvent = members1.filter(member => member.attendance_flag === 'Present').length;
const totalAbsentEvent = members1.filter(member => member.attendance_flag === 'Absent').length;


const handleStudentWise =()=>{
  setStudentWise(true);
  setEventWise(false);
}

const handleExcelData1 = () => {
  const ws = XLSX.utils.json_to_sheet(rows1);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Student_Attendance_(Event_Wise).xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("Excel Sheet Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const handlePdfData1 = () => {
  const doc = new jsPDF();
  const columns = data1.columns.map((col) => col.label);
  const rowsData = rows1.map((row) => [row.serial,row.event_date,row.name,row.block_id,row.room_id,row.discription,row.attendance_flag,row.manually_biometric]);

  const title = 'Student Attendance (Event Wise)';

  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const x = (pageWidth - textWidth) / 2;
  doc.text(title, x, 10);

  doc.autoTable({
    head: [columns],
    body: rowsData,
    startY: 20,
  });

  const blob = doc.output('blob');
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Student_Attendance_(Event_Wise).pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  toast.success("PDF Downloaded !!!", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

//===================================================================================


  return (
  
      <>
    <Sidebar />
    <section className="home-section pb-5">
      <NavLogo />
      <ReportNavbar />
      
      <Row className="m-0 p-0 pt-4 px-3 ">
            <Col
                className="rounded text-center py-2"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    border: "none",
                    alignItems: "center",
                    alignContent: "center",
                    color: "maroon",
                }}
            >    
                <div><span style={{color:"maroon",fontSize:"18px"}}>Event Wise Student Attendance</span></div>
                <div className='d-flex'>
                  <button className='mx-1 px-2 py-1' style={{color:"white",backgroundColor:"grey",fontSize:"13px",}} onClick={handleEventWise}>Event Wise</button>
                  <button className='mx-1 px-2 py-1' style={{color:"white",backgroundColor:"grey",fontSize:"13px",}} onClick={handleStudentWise}>Student Wise</button>
                </div>
                
            </Col>
        </Row>

        {eventWise && (

              <div className="row m-0 p-2 p-md-3 pb-5">
                  <div className="row m-0  pb-5" style={{backgroundColor:"white",borderBottom:"1px solid grey"}}>

                  <div className="col-12 text-start my-2"> <span style={{backgroundColor:"#808080",color:"white",padding:"0px 25px",borderRadius:"5px"}}>Event Wise Report</span> </div>

                  <div className="col-12 col-lg-3"></div>
                    <div className="col-12 col-md-8 col-lg-4 pt-2 pt-md-0">
                        <label style={{fontWeight:"normal",fontSize:"13px"}}>Select Event :</label>
                        <Select
                            className="w-100"
                            placeholder="Select Event"
                            options={events.map(event => ({ value: event.event_id, label: `${event.event_date} - ${event.discription}` }))}
                            onChange={handleEventChange}
                          />
                    </div>
                    <div className="col-12 col-md-4 col-lg-2 pt-4">
                      <Button variant="contained" color="primary" style={{backgroundColor:"#1685f3",padding:"5px 10px"}} onClick={handleEventWiseShow}>Show</Button>
                    </div>
                    <div className="col-12 col-lg-3"></div>
                  </div>

                  <div className="row m-0 p-2 mb-5"  style={{backgroundColor:"white"}}>

                  <div className='col-12 text-end py-2'>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#3b9cfd",color:"white"}}  onClick={() => handleExcelData()}>Excel</Button>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#136fcb",color:"white"}} onClick={() => handlePdfData()}>Pdf</Button>
                  </div>

                      <MDBDataTable
                        striped
                        bordered
                        className="custom-datatable"
                        small
                        data={data}
                      />

                      <div className="col-12 text-end p-1" style={{backgroundColor:"black",color:"white"}}>
                          <div className="row border m-0">
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Students: {totalCount}</span>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Present: {totalPresent}</span>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Absent: {totalAbsent}</span>
                            </div>
                          </div>                        
                      </div>


                  </div>
              </div>
        )}

        {studentWise && (

              <div className="row m-0 p-2 p-md-3 pb-5">
                  <div className="row m-0 pb-5" style={{backgroundColor:"white",borderBottom:"1px solid grey"}}>

                    <div className="col-12 text-start my-2"> <span style={{backgroundColor:"#808080",color:"white",padding:"0px 25px",borderRadius:"5px"}}>Student Wise Event Report</span> </div>

                    <div className="col-12 col-lg-3"></div>
                    <div className="col-12 col-md-6 col-lg-4 pt-2 pt-md-0">
                        <label style={{fontWeight:"normal",fontSize:"13px"}}>Select Student :</label>

                          <Select
                                    showSearch className='w-100'
                                    placeholder="Select Student"
                                    optionFilterProp="label"
                                    value={selectedStudentId}
                                    onChange={(value) => setSelectedStudentId(value)}
                                    options={studentData.map(student => ({
                                      value: student.member_id,
                                      label: student.name,
                                    }))}
                                  />

                    </div>

                    <div className="col-12 col-lg-2 pt-4">
                      <Button variant="contained" color="primary" style={{backgroundColor:"#1685f3",padding:"5px 10px"}} onClick={handleStudentWiseShow}>Show</Button>
                    </div>
                    <div className="col-12 col-lg-3"></div>


                  </div>

                  <div className="row m-0 p-2 mb-5"  style={{backgroundColor:"white"}}>

                  <div className='col-12 text-end py-2'>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#3b9cfd",color:"white"}}  onClick={() => handleExcelData1()}>Excel</Button>
                    <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#136fcb",color:"white"}} onClick={() => handlePdfData1()}>Pdf</Button>
                  </div>

                      <MDBDataTable
                        striped
                        bordered
                        className="custom-datatable"
                        small
                        data={data1}
                      />

                      <div className="col-12 text-end p-1" style={{backgroundColor:"black",color:"white"}}>
                          <div className="row border m-0">
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Events: {totalEventCount}</span>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Present: {totalPresentEvent}</span>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 text-center">
                                <span>Total Absent: {totalAbsentEvent}</span>
                            </div>
                          </div>                        
                      </div>


                  </div>
              </div>
        )}

    </section>
    </>
  )
}

export default EventWiseAttendance