// import React, { useEffect, useState } from 'react';
// import Sidebar from './sidebarstudent';
// import Header from './Headerstudent';
// import { Button } from '@mui/material';
// import axios from 'axios';
// import { StudentDischargegetData, StudentDischargeFeeDetails, StudentREquestDischarge } from '../../ApiFile';
// import { Input } from 'antd';
// import { toast } from 'react-toastify';

// const StudentDischarge = () => {
//   const [data, setData] = useState(null);
//   const [feeDetails, setFeeDetails] = useState(null);
//   const [dischargeReason, setDischargeReason] = useState('');
//   const year = sessionStorage.getItem("year");
//   const memberId = sessionStorage.getItem('member_id');

//   useEffect(() => {
//     axios.get(`${StudentDischargegetData}/${memberId}/${year}`)
//       .then(response => {
//         setData(response.data[0]); // Assuming the response is an array with one object
//       })
//       .catch(error => {
//         console.error('There was an error fetching the attendance data!', error);
//       });

//     axios.get(`${StudentDischargeFeeDetails}/${memberId}/${year}`)
//       .then(response => {
//         setFeeDetails(response.data[0]); // Assuming the response is an array with one object
//       })
//       .catch(error => {
//         console.error('There was an error fetching the fee details!', error);
//       });
//   }, [memberId, year]);

//   const handleDischargeRequest = () => {
//     if (!dischargeReason) {
//       toast.warning("Please enter reason before proceeding.");
//       return;
//     }

//     axios.post(StudentREquestDischarge, {
//       member_id: memberId,
//       discharge_reason: dischargeReason
//     })
//     .then(response => {
//       toast.success('Discharge request submitted successfully!');
//       setDischargeReason('');
//     })
//     .catch(error => {
//       console.error('There was an error submitting the discharge request!', error);
//     });
//   };

//   return (
//     <>
//       <Sidebar />
//       <section className="home-section">
//         <Header />
//         <div className="block-header">
//           <h4 className="text-center mt-md-4 mt-2 mb-2 m-0 p-0" style={{ color: 'maroon' }}>
//             Request Discharge
//           </h4>
//         </div>

//         {data && (
//           <>
//             <div className="row m-0 p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
//               <div className="col-12 py-1" style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}>
//                 <span>Regular Attendance - </span>
//               </div>
//               <div className='col-12 col-md-6 col-lg-4 pt-2 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Regular Absent Total:</label>&nbsp;&nbsp;&nbsp;
//                 <span>{data.regularCount}</span>
//               </div>
//             </div>

//             <div className="row m-0 p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
//               <div className="col-12 py-1" style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}>
//                 <span>Event Attendance - </span>
//               </div>

//               <div className='col-12 col-md-6 col-lg-4 pt-2 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Event Present:</label>&nbsp;&nbsp;&nbsp;
//                 <span>{data.eventCount.present_eventCount}</span> &nbsp;&nbsp;&nbsp;
//                 <span><Button style={{ backgroundColor: "#9acac9", color: "black" }} className='p-0 m-0'>View</Button></span>
//               </div>

//               <div className='col-12 col-md-6 col-lg-4 pt-2 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Event Absent:</label>&nbsp;&nbsp;&nbsp;
//                 <span>{data.eventCount.absent_eventCount}</span> &nbsp;&nbsp;&nbsp;
//                 <span><Button style={{ backgroundColor: "#9acac9", color: "black" }} className='p-0 m-0'>View</Button></span>
//               </div>

//               <div className='col-12 col-md-6 col-lg-4 pt-2 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Event Total:</label>&nbsp;&nbsp;&nbsp;
//                 <span>{data.eventCount.present_eventCount + data.eventCount.absent_eventCount}</span> 
//               </div>
//             </div>

//             <div className="row m-0 p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
//               <div className="col-12 py-1" style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}>
//                 <span>Mess Attendance - </span>
//               </div>
//               <li>Lunch - </li>
//               <div className='col-12 col-md-6 col-lg-4 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Lunch Present:</label>
//                 &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_present}</span> 
//               </div>

//               <div className='col-12 col-md-6 col-lg-4 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Lunch Absent:</label>
//                 &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_absent}</span> 
//               </div>

//               <div className='col-12 col-md-6 col-lg-4 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Lunch Total:</label>
//                 &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_present + data.lunchCount.lunch_absent}</span>
//               </div>

//               <li>Dinner - </li>
//               <div className='col-12 col-md-6 col-lg-4 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Dinner Present:</label>
//                 &nbsp;&nbsp;&nbsp;<span>{data.dinnerCount.dinner_present}</span> 
//               </div>

//               <div className='col-12 col-md-6 col-lg-4 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Dinner Absent:</label>
//                 &nbsp;&nbsp;&nbsp;<span>{data.dinnerCount.dinner_absent}</span> 
//               </div>

//               <div className='col-12 col-md-6 col-lg-4 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Dinner Total:</label>
//                 &nbsp;&nbsp;&nbsp;<span>{data.dinnerCount.dinner_present + data.dinnerCount.dinner_absent}</span> 
//               </div>
//             </div>
//           </>
//         )}

//         {feeDetails && (
//           <div className="row m-0 p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
//             <div className="col-12 py-1" style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}>
//               <span>Fee Details - </span>
//             </div>
//             <div className='col-12 col-md-6 col-lg-6 pt-2 d-flex'>
//               <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Total Fee:</label>&nbsp;&nbsp;&nbsp;
//               <span>{feeDetails.total_fee}</span> 
//             </div>

//             <div className='col-12 col-md-6 col-lg-6 pt-2 d-flex'>
//               <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Pending Fee:</label>&nbsp;&nbsp;&nbsp;
//               <span>{feeDetails.pending_amount}</span> 
//             </div>

//             {feeDetails.first_installment.length > 0 && (
//               <div className='col-12 col-md-4 col-lg-4 pt-2 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>First Installment: </label>&nbsp;&nbsp;&nbsp;&nbsp;
//                 <span>{feeDetails.first_installment.map(installment => (
//                   <div key={installment.payment_date}>
//                     {installment.total_amount} - {installment.payment_date}
//                   </div>
//                 ))}</span>
//               </div>
//             )}
//             {feeDetails.second_installment.length > 0 && (
//               <div className='col-12 col-md-4 col-lg-4 pt-2 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Second Installment: </label>&nbsp;&nbsp;&nbsp;&nbsp;
//                 <span>{feeDetails.second_installment.map(installment => (
//                   <div key={installment.payment_date}>
//                     {installment.total_amount} - {installment.payment_date}
//                   </div>
//                 ))}</span>
//               </div>
//             )}

//             {feeDetails.without_installment.length > 0 && (
//               <div className='col-12 col-md-4 col-lg-4 pt-2 d-flex'>
//                 <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Without Installment: </label>&nbsp;&nbsp;&nbsp;&nbsp;
//                 <span>{feeDetails.without_installment.map(installment => (
//                   <div key={installment.payment_date}>
//                     {installment.total_amount} - {installment.payment_date}
//                   </div>
//                 ))}</span>
//               </div>
//             )}
//           </div>
//         )}

//         <div className="row m-0 p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
//           <div className="col-12 py-1" style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}>
//             <span>Discharge Reason - </span>
//           </div>
//           <div style={{display:"flex"}}>
//           <div className='col-12 col-md-6 col-lg-4 pt-2 d-flex'>
//             <Input 
//               placeholder="Enter Reason" 
//               value={dischargeReason} 
//               onChange={(e) => setDischargeReason(e.target.value)} 
//             />
//           </div>

//           <div className='ps-5 mt-2'>
//             <Button 
//               style={{ backgroundColor: "#9acac9", color: "black" }} 
//               onClick={handleDischargeRequest}
//             >
//               Submit
//             </Button>
//           </div>
//           </div>

//         </div>
//       </section>
//     </>
//   );
// }

// export default StudentDischarge;




import React, { useEffect, useState } from 'react';
import { Input, Modal, Table } from 'antd';
import Sidebar from './sidebarstudent';
import Header from './Headerstudent';
import { Button} from '@mui/material';
import axios from 'axios';
import { StudentDischargegetData, StudentDischargeFeeDetails, StudentREquestDischarge, StudentDischargeReqEventDetails } from '../../ApiFile';
import { toast } from 'react-toastify';

const StudentDischarge = () => {
  const [data, setData] = useState(null);
  const [feeDetails, setFeeDetails] = useState(null);
  const [dischargeReason, setDischargeReason] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  
  const year = sessionStorage.getItem("year");
  const memberId = sessionStorage.getItem('member_id');

  useEffect(() => {
    axios.get(`${StudentDischargegetData}/${memberId}/${year}`)
      .then(response => {
        setData(response.data[0]);
      })
      .catch(error => {
        console.error('There was an error fetching the attendance data!', error);
      });

    axios.get(`${StudentDischargeFeeDetails}/${memberId}/${year}`)
      .then(response => {
        setFeeDetails(response.data[0]);
      })
      .catch(error => {
        console.error('There was an error fetching the fee details!', error);
      });
  }, [memberId, year]);

  const handleDischargeRequest = () => {
    if (!dischargeReason) {
      toast.warning("Please enter a reason before proceeding.");
      return;
    }

    axios.post(StudentREquestDischarge, {
      member_id: memberId,
      discharge_reason: dischargeReason
    })
    .then(response => {
      toast.success('Discharge request submitted successfully!');
      setDischargeReason('');
    })
    .catch(error => {
      console.error('There was an error submitting the discharge request!', error);
    });
  };

  const showModal = (flag, title) => {
    axios.get(`${StudentDischargeReqEventDetails}/${memberId}/${flag}`)
      .then(response => {
        setModalData(response.data);
        setModalTitle(title);
        setIsModalVisible(true);
      })
      .catch(error => {
        console.error('There was an error fetching the event details!', error);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalData([]);
  };

  const columns = [
    {
      title: 'Serial No.',
      dataIndex: 'serial_no',
      key: 'serial_no',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Event Name',
      dataIndex: 'discription',
      key: 'discription',
    },
    {
      title: 'Event Date',
      dataIndex: 'event_date',
      key: 'event_date',
    },
  ];

  return (
    <>
      <Sidebar />
      <section className="home-section pb-5">
        <Header />
        <div className="block-header">
          <h4 className="text-center mt-md-4 mt-2 mb-2 m-0 p-0" style={{ color: 'maroon' }}>
            Request Discharge
          </h4>
        </div>

        {data && (
          <>
            <div className="row m-0 p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
              <div className="col-12 py-1" style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}>
                <span>Regular Attendance - </span>
              </div>
              <div className='col-12 col-md-6 col-lg-4 pt-2 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Absent:</label>&nbsp;&nbsp;&nbsp;
                <span>{data.regularCount}</span>
              </div>
            </div>

            <div className="row m-0 p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
              <div className="col-12 py-1" style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}>
                <span>Event Attendance - </span>
              </div>

              <div className='col-12 col-md-6 col-lg-4 pt-2 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Total Events:</label>&nbsp;&nbsp;&nbsp;
                <span>{data.eventCount.present_eventCount + data.eventCount.absent_eventCount}</span> 
              </div>

              <div className='col-12 col-md-6 col-lg-4 pt-2 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Present:</label>&nbsp;&nbsp;&nbsp;
                <span>{data.eventCount.present_eventCount}</span> &nbsp;&nbsp;&nbsp;
                <span><Button style={{ backgroundColor: "#9acac9", color: "black" }} className='p-0 m-0' onClick={() => showModal(1, 'Present Event Details')}>View</Button></span>
              </div>

              <div className='col-12 col-md-6 col-lg-4 pt-2 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Absent:</label>&nbsp;&nbsp;&nbsp;
                <span>{data.eventCount.absent_eventCount}</span> &nbsp;&nbsp;&nbsp;
                <span><Button style={{ backgroundColor: "#9acac9", color: "black" }} className='p-0 m-0' onClick={() => showModal(2, 'Absent Event Details')}>View</Button></span>
              </div>

            
            </div>

            <div className="row m-0 p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
              <div className="col-12 py-1" style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}>
                <span>Mess Attendance - </span>
              </div>
              <li>Lunch - </li>

              <div className='col-12 col-md-6 col-lg-4 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Total Lunchs :</label>
                &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_present + data.lunchCount.lunch_absent}</span>
              </div>

              <div className='col-12 col-md-6 col-lg-4 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Present:</label>
                &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_present}</span> 
              </div>

              <div className='col-12 col-md-6 col-lg-4 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Absent:</label>
                &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_absent}</span> 
              </div>

              <li>Dinner - </li>

              <div className='col-12 col-md-6 col-lg-4 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Total Dinners :</label>
                &nbsp;&nbsp;&nbsp;<span>{data.dinnerCount.dinner_present + data.dinnerCount.dinner_absent}</span> 
              </div>

              <div className='col-12 col-md-6 col-lg-4 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Present:</label>
                &nbsp;&nbsp;&nbsp;<span>{data.dinnerCount.dinner_present}</span> 
              </div>

              <div className='col-12 col-md-6 col-lg-4 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Absent:</label>
                &nbsp;&nbsp;&nbsp;<span>{data.dinnerCount.dinner_absent}</span> 
              </div>

            </div>
          </>
        )}

        {feeDetails && (
          <div className="row m-0 p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
            <div className="col-12 py-1" style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}>
              <span>Fee Details - </span>
            </div>
            <div className='col-12 col-md-6 col-lg-6 pt-2 d-flex'>
              <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Total Fee:</label>&nbsp;&nbsp;&nbsp;
              <span>{feeDetails.total_fee}</span> 
            </div>

            <div className='col-12 col-md-6 col-lg-6 pt-2 d-flex'>
              <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Pending Fee:</label>&nbsp;&nbsp;&nbsp;
              <span>{feeDetails.pending_amount}</span> 
            </div>

            {feeDetails.first_installment.length > 0 && (
              <div className='col-12 col-md-4 col-lg-4 pt-2 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>First Installment: </label>&nbsp;&nbsp;&nbsp;&nbsp;
                <span>{feeDetails.first_installment.map(installment => (
                  <div key={installment.payment_date}>
                    {installment.total_amount} - {installment.payment_date}
                  </div>
                ))}</span>
              </div>
            )}
            {feeDetails.second_installment.length > 0 && (
              <div className='col-12 col-md-4 col-lg-4 pt-2 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Second Installment: </label>&nbsp;&nbsp;&nbsp;&nbsp;
                <span>{feeDetails.second_installment.map(installment => (
                  <div key={installment.payment_date}>
                    {installment.total_amount} - {installment.payment_date}
                  </div>
                ))}</span>
              </div>
            )}

            {feeDetails.without_installment.length > 0 && (
              <div className='col-12 col-md-4 col-lg-4 pt-2 d-flex'>
                <label style={{ fontWeight: "normal", fontSize: "13px", color: "grey" }}>Onetime Payment : </label>&nbsp;&nbsp;&nbsp;&nbsp;
                <span>{feeDetails.without_installment.map(installment => (
                  <div key={installment.payment_date}>
                    {installment.total_amount} - {installment.payment_date}
                  </div>
                ))}</span>
              </div>
            )}
          </div>
        )}

        <div className="row m-0 p-2 mx-1 mb-1" style={{ backgroundColor: "whitesmoke" }}>
          <div className="col-12 py-1" style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}>
            <span>Discharge Reason - </span>
          </div>
          <div style={{display:"flex"}}>
          <div className='col-12 col-md-6 col-lg-4 pt-2 d-flex'>
            <Input 
              placeholder="Enter Reason" 
              value={dischargeReason} 
              onChange={(e) => setDischargeReason(e.target.value)} 
            />
          </div>

          <div className='ps-5 mt-2'>
            <Button 
              style={{ backgroundColor: "#9acac9", color: "black" }} 
              onClick={handleDischargeRequest}
            >
              Submit
            </Button>
          </div>
          </div>

        </div>

        <Modal title={modalTitle} visible={isModalVisible} onCancel={handleCancel} footer={null}>
          <Table
            dataSource={modalData}
            columns={columns}
            rowKey="event_date"
            pagination={false}
          />
        </Modal>
      </section>
    </>
  );
};

export default StudentDischarge;
