// import React, { useState, useEffect } from 'react';
// import 'react-toastify/dist/ReactToastify.css';
// import { Col, Row } from 'react-bootstrap';
// import { MDBDataTable } from 'mdbreact';
// import Sidebar from '../sidebar';
// import NavLogo from '../NavLogo';
// import axios from 'axios';
// import { EventDatelistAdd, EventDateSelectAttendance, EventMemberList, EventAttendanceAdd } from '../../ApiFile';
// import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// import { toast } from 'react-toastify';

// const EventAttendance = () => {
//   const [events, setEvents] = useState([]);
//   const [selectedEventId, setSelectedEventId] = useState('');
//   const [eventDescriptions, setEventDescriptions] = useState([]);
//   const [selectedDescription, setSelectedDescription] = useState('');
//   const [studentDataType, setStudentDataType] = useState('');
//   const [attendanceType, setAttendanceType] = useState(1);
//   const [memberList, setMemberList] = useState([]);
//   const [totalStudents, setTotalStudents] = useState(0);
//   const [totalPresent, setTotalPresent] = useState(0);
//   const [totalAbsent, setTotalAbsent] = useState(0);

//   useEffect(() => {
//     axios.get(EventDateSelectAttendance)
//       .then(response => {
//         setEvents(response.data);
//       })
//       .catch(error => {
//         console.error("There was an error fetching the events!", error);
//       });
//   }, []);

//   const handleEventChange = (event) => {
//     console.log("value.....",event.target.value);
    
//     const selectedEvent = events.find(e => e.event_id == event.target.value);
//     setSelectedEventId(event.target.value);
//     if (selectedEvent) {
//       axios.post(EventDatelistAdd, { event_date: selectedEvent.event_date })
//         .then(response => {
//           setEventDescriptions(response.data);
//         })
//         .catch(error => {
//           console.error("There was an error fetching the event descriptions!", error);
//         });
//     }
//   };

//   const handleDescriptionChange = (event) => {
//     setSelectedEventId(event.target.value);
//     console.log("value.....dis",event.target.value);
//     setSelectedDescription(event.target.value);
//   };

//   const handleStudentDataTypeChange = (event) => {
//     setStudentDataType(event.target.value);
//   };

//   const handleAttendanceTypeChange = (event) => {
//     setAttendanceType(event.target.value);
//   };

//   const fetchMemberList = () => {
//     const selectedEvent = events.find(e => e.event_id == selectedEventId);
//     if (selectedEvent) {
//       axios.post(EventMemberList, {
//         list_flag: studentDataType,
//         event_date: selectedEvent.event_date,
//         event_id: selectedEvent.event_id,
//       })
//         .then(response => {
//           const updatedMemberList = response.data.map(member => ({
//             ...member,
//             attendance_flag: member.attendanceList.attendance_flag || "0" // Default to "Pending"
//           }));
//           setMemberList(updatedMemberList);
//           updateTotals(updatedMemberList);
//         })
//         .catch(error => {
//           console.error("There was an error fetching the event member list!", error);
//         });
//     }
//   };

//   const updateTotals = (members) => {
//     const total = members.length;
//     const present = members.filter(member => member.attendance_flag == "1").length;
//     const absent = members.filter(member => member.attendance_flag == "2").length;
//     setTotalStudents(total);
//     setTotalPresent(present);
//     setTotalAbsent(absent);
//   };

//   const handleAttendanceToggle = (memberId, flag) => {
//     setMemberList(prevList => {
//       const updatedList = prevList.map(member =>
//         member.member_id === memberId ? { ...member, attendance_flag: flag } : member
//       );
//       updateTotals(updatedList);
//       return updatedList;
//     });
//   };

//   useEffect(() => {
//     if (selectedEventId && studentDataType) {
//       fetchMemberList();
//     }
//   }, [selectedEventId, studentDataType]);

//   const handleSaveAttendance = () => {
//     const hasPendingAttendance = memberList.some(member =>  member.attendance_flag === "0");

    
//     if (hasPendingAttendance) {
//       toast.error('Please check all attendance records before saving!');
//     } else {
//       const attendanceData = {
//         flag: attendanceType,
//         event_id: selectedEventId,
//         attendanceList: memberList.map(member => ({
//           member_id: member.member_id,
//           attendance_flag: member.attendance_flag
//         }))
//       };
  
//       axios.post(EventAttendanceAdd, attendanceData)
//         .then(response => {
//           if (response.data === 'SAVED') {
//             fetchMemberList();
//             toast.success('Attendance data saved successfully!');
//           }
//           else if(response.data === 'ALREADYEXIST'){
//             fetchMemberList();
//             toast.warn('Attendance data already exists!');
//           }
//         })
//         .catch(error => {
//           console.error("There was an error saving the attendance data!", error);
//         });
//     }
//   };
  

//   const getButtonStyle = (attendance_flag, buttonType) => {
//     if (attendance_flag == "0") {
//       return { backgroundColor: "white", color: "black" };
//     }
//     if (attendance_flag == "1" && buttonType === "P") {
//       return { backgroundColor: "green", color: "white" };
//     }
//     if (attendance_flag == "2" && buttonType === "A") {
//       return { backgroundColor: "red", color: "white" };
//     }
//     return { backgroundColor: "white", color: "black" };
//   };

//   const data = {
//     columns: [
//       { label: 'Sr.No', field: 'sno', sort: 'asc', attributes: { className: 'ssss' } },
//       { label: 'Block No', field: 'block_id', sort: 'asc', attributes: { className: 'ssss' } },
//       { label: 'Room No', field: 'room_id', sort: 'asc', attributes: { className: 'ssss' } },
//       { label: 'Name', field: 'name', sort: 'asc', attributes: { className: 'name-column' } },
//       { label: 'Attendance', field: 'attendance_flag', sort: 'asc', attributes: { style: { textAlign: 'center' } } }
//     ],
//     rows: memberList.map((member, index) => ({
//       sno: index + 1,
//       block_id: member.attendanceList.block_id,
//       room_id: member.attendanceList.room_id,
//       name: (
//         <span className="text-start">
//           {member.attendanceList.name}
//         </span>
//       ),
//       attendance_flag: (
//         <>
//           <Button
//             variant="contained"
//             className='me-1 '
//             style={getButtonStyle(member.attendance_flag, "P")}
//             onClick={() => handleAttendanceToggle(member.member_id, "1")}
//           >
//             <b>P</b>
//           </Button>
//           <Button
//             variant="contained"
//             className='ms-1'
//             style={getButtonStyle(member.attendance_flag, "A")}
//             onClick={() => handleAttendanceToggle(member.member_id, "2")}
//           >
//             <b>A</b>
//           </Button>
//         </>
//       )
//     }))
//   };

//   return (
//     <>
//       <Sidebar />
//       <section className="home-section">
//         <NavLogo />

//         <div className='pb-5'>
//           <div className="block-header">
//             <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
//               Event Attendance
//             </h2>
//           </div>

//           <Row className="m-0 pt-1 px-2 px-md-3 rounded">
//             <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
//               <Row>
//                 <Col md={3} className="mt-3 mt-md-0">
//                   <FormControl fullWidth margin="dense" size="small">
//                     <InputLabel id="event-select-label">Event Date</InputLabel>
//                     <Select
//                       labelId="event-select-label"
//                       id="event-select"
//                       value={selectedEventId}
//                       label="Event Date"
//                       onChange={handleEventChange}
//                     >
//                       {events.map(event => (
//                         <MenuItem key={event.event_id} value={event.event_id}>
//                           {event.event_date}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Col>
//                 <Col md={3} className="mt-3 mt-md-0">
//                   <FormControl fullWidth margin="dense" size="small">
//                     <InputLabel id="description-select-label">Description</InputLabel>
//                     <Select
//                       labelId="description-select-label"
//                       id="description-select"
//                       value={selectedDescription}
//                       label="Description"
//                       onChange={handleDescriptionChange}
//                       disabled={eventDescriptions.length === 0}
//                     >
//                       {eventDescriptions.map(desc => (
//                         <MenuItem key={desc.event_id} value={desc.event_id}>
//                           {desc.discription}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Col>
//                 <Col md={3} className="mt-3 mt-md-0">
//                   <FormControl fullWidth margin="dense" size="small">
//                     <InputLabel id="student-data-type-select-label">Student Data Type</InputLabel>
//                     <Select
//                       labelId="student-data-type-select-label"
//                       id="student-data-type-select"
//                       value={studentDataType}
//                       label="Student Data Type"
//                       onChange={handleStudentDataTypeChange}
//                     >
//                       <MenuItem value={1}>Block Wise</MenuItem>
//                       <MenuItem value={2}>Alphabetical Order</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Col>
//                 <Col md={3} className="mt-3 mt-md-0">
//                   <FormControl fullWidth margin="dense" size="small">
//                     <InputLabel id="attendance-type-select-label">Attendance Type</InputLabel>
//                     <Select
//                       labelId="attendance-type-select-label"
//                       id="attendance-type-select"
//                       value={attendanceType}
//                       label="Attendance Type"
//                       onChange={handleAttendanceTypeChange}
//                     >
//                       <MenuItem value={1}>Manual</MenuItem>
//                       <MenuItem value={2}>Biometric</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Col>
//               </Row>
//             </Col>
//           </Row>

//           <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
//             <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
//               <div style={{ overflowY: 'auto' }}>
//                 <div style={{ overflowX: 'auto' }}>
//                   <MDBDataTable striped className="custom-datatable text-center" bordered hover data={data} />
//                 </div>
//               </div>
//               <div className="text-center pt-2 rounded" style={{display:"flex",alignItems:"center",justifyContent:"space-around",border:"1px solid rgb(214, 222, 219)"}}>
//                 <p><b style={{color:"maroon"}}>Total Students</b> : <b>{totalStudents}</b></p>
//                 <p><b style={{color:"green"}}>Total Present</b> : <b>{totalPresent}</b></p>
//                 <p><b style={{color:"red"}}>Total Absent</b> : <b>{totalAbsent}</b></p>
//               </div>
//               <div className="text-right text-center mt-3 mb-4">
//                 <Button style={{ backgroundColor: "rgb(0, 120, 215)" }} onClick={handleSaveAttendance}>
//                   <b style={{ color: "white" }}>Save Attendance</b>
//                 </Button>
//               </div>
//             </Col>
//           </Row>
//         </div>
//       </section>
//     </>
//   );
// };

// export default EventAttendance;





// import React, { useState, useEffect } from 'react';
// import 'react-toastify/dist/ReactToastify.css';
// import { Col, Row } from 'react-bootstrap';
// import { MDBDataTable } from 'mdbreact';
// import Sidebar from '../sidebar';
// import NavLogo from '../NavLogo';
// import axios from 'axios';
// import { EventDatelistAdd, EventDateSelectAttendance, EventMemberList, EventAttendanceAdd, EventAttendanceUpdate } from '../../ApiFile';
// import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// import { toast } from 'react-toastify';

// const EventAttendance = () => {
//   const [events, setEvents] = useState([]);
//   const [selectedEventId, setSelectedEventId] = useState('');
//   const [eventDescriptions, setEventDescriptions] = useState([]);
//   const [selectedDescription, setSelectedDescription] = useState('');
//   const [studentDataType, setStudentDataType] = useState('');
//   const [attendanceType, setAttendanceType] = useState(1);
//   const [memberList, setMemberList] = useState([]);
//   const [totalStudents, setTotalStudents] = useState(0);
//   const [totalPresent, setTotalPresent] = useState(0);
//   const [totalAbsent, setTotalAbsent] = useState(0);
//   const [isAttendanceSaved, setIsAttendanceSaved] = useState(false);

//   useEffect(() => {
//     axios.get(EventDateSelectAttendance)
//       .then(response => {
//         setEvents(response.data);
//       })
//       .catch(error => {
//         console.error("There was an error fetching the events!", error);
//       });
//   }, []);

//   const handleEventChange = (event) => {
//     const selectedEvent = events.find(e => e.event_id === event.target.value);
//     setSelectedEventId(event.target.value);
//     if (selectedEvent) {
//       axios.post(EventDatelistAdd, { event_date: selectedEvent.event_date })
//         .then(response => {
//           setEventDescriptions(response.data);
//         })
//         .catch(error => {
//           console.error("There was an error fetching the event descriptions!", error);
//         });
//     }
//   };

//   const handleDescriptionChange = (event) => {
//     setSelectedDescription(event.target.value);
//   };

//   const handleStudentDataTypeChange = (event) => {
//     setStudentDataType(event.target.value);
//   };

//   const handleAttendanceTypeChange = (event) => {
//     setAttendanceType(event.target.value);
//   };

//   const fetchMemberList = () => {
//     const selectedEvent = events.find(e => e.event_id === selectedEventId);
//     if (selectedEvent) {
//       axios.post(EventMemberList, {
//         list_flag: studentDataType,
//         event_date: selectedEvent.event_date,
//         event_id: selectedEvent.event_id,
//       })
//         .then(response => {
//           const updatedMemberList = response.data.map(member => ({
//             ...member,
//             attendance_flag: member.attendanceList.attendance_flag || "0" // Default to "Pending"
//           }));
//           setMemberList(updatedMemberList);
//           updateTotals(updatedMemberList);
//         })
//         .catch(error => {
//           console.error("There was an error fetching the event member list!", error);
//         });
//     }
//   };

//   const updateTotals = (members) => {
//     const total = members.length;
//     const present = members.filter(member => member.attendanceList.attendance_flag == 1).length;
//     const absent = members.filter(member => member.attendanceList.attendance_flag == 2).length;
//     setTotalStudents(total);
//     setTotalPresent(present);
//     setTotalAbsent(absent);
//   };

//   const handleAttendanceToggle = (memberId, flag) => {
//     setMemberList(prevList => {
//       const updatedList = prevList.map(member =>
//         member.member_id === memberId ? { ...member, attendance_flag: flag } : member
//       );
//       updateTotals(updatedList);
//       return updatedList;
//     });
//   };

//   useEffect(() => {
//     if (selectedEventId && studentDataType) {
//       fetchMemberList();
//     }
//   }, [selectedEventId, studentDataType]);

// //  const handleSaveAttendance = () => {
// //   const hasPendingAttendance = memberList.some(member => member.attendance_flag === "0");
// //   const shouldCallUpdateAPI = memberList.some(member => member.attendance_flag === "1" || member.attendance_flag === "2");

// //   // Check if there are pending records
// //   if (hasPendingAttendance) {
// //     toast.error('Please check all attendance records before saving!');
// //     return; // Exit the function if there are pending records
// //   }

// //   const selectedEvent = events.find(e => e.event_id === selectedEventId);
// //   const attendanceData = {
// //     event_date: selectedEvent ? selectedEvent.event_date : null,
// //     flag: attendanceType,
// //     event_id: selectedEventId,
// //     attendanceList: memberList.map(member => ({
// //       id: member.attendanceList.id,
// //       member_id: member.member_id,
// //       attendance_flag: member.attendance_flag
// //     }))
// //   };

// //   // Determine whether to call the add or update API
// //   if (!isAttendanceSaved && shouldCallUpdateAPI) {
// //     // First-time save
// //     axios.post(EventAttendanceAdd, attendanceData)
// //       .then(response => {
// //         if (response.data === 'SAVED') {
// //           fetchMemberList();
// //           toast.success('Attendance data saved successfully!');
// //           setIsAttendanceSaved(true);
// //         }
// //       })
// //       .catch(error => {
// //         console.error("There was an error saving the attendance data!", error);
// //       });
// //   } else if (isAttendanceSaved && shouldCallUpdateAPI) {
// //     // Subsequent update
// //     axios.put(EventAttendanceUpdate, attendanceData)
// //       .then(response => {
// //         if (response.data === 'UPDATE') {
// //           fetchMemberList();
// //           toast.success('Attendance data updated successfully!');
// //         } else if (response.data === 'ALREADYEXIST') {
// //           fetchMemberList();
// //           toast.warn('Attendance data already exists!');
// //         }
// //       })
// //       .catch(error => {
// //         console.error("There was an error updating the attendance data!", error);
// //       });
// //   }
// // };


// const handleSaveAttendance = () => {
//   const hasPendingAttendance = memberList.some(member => member.attendance_flag == "0");
//   const shouldCallUpdateAPI = memberList.some(member => member.attendance_flag == "1" || member.attendance_flag == "2");


//   if (hasPendingAttendance) {
//     toast.error('Please check all attendance records before saving!');
//     return;
//   }

//   const selectedEvent = events.find(e => e.event_id === selectedEventId);
//   const attendanceData = {
//     event_date: selectedEvent ? selectedEvent.event_date : null,
//     flag: attendanceType,
//     event_id: selectedEventId,
//     attendanceList: memberList.map(member => ({
//       id: member.attendanceList.id,
//       member_id: member.member_id,
//       attendance_flag: member.attendance_flag
//     }))
//   };

//   if (!isAttendanceSaved && shouldCallUpdateAPI) {
//     axios.post(EventAttendanceAdd, attendanceData)
//       .then(response => {
//         if (response.data === 'SAVED') {
//           fetchMemberList();
//           toast.success('Attendance data saved successfully!');
//           setIsAttendanceSaved(true);
//         }
//       })
//       .catch(error => {
//         console.error("There was an error saving the attendance data!", error);
//       });
//   } else if (isAttendanceSaved && shouldCallUpdateAPI) {
//     console.log('Calling update API for attendance flags 1 or 2...');
//     axios.put(EventAttendanceUpdate, attendanceData)
//       .then(response => {
//         if (response.data === 'UPDATE') {
//           fetchMemberList();
//           toast.success('Attendance data updated successfully!');
//         } else if (response.data === 'ALREADYEXIST') {
//           fetchMemberList();
//           toast.warn('Attendance data already exists!');
//         }
//       })
//       .catch(error => {
//         console.error("There was an error updating the attendance data!", error);
//       });
//   }
  
// };


  



//   const getButtonStyle = (attendance_flag, buttonType) => {
//     if (attendance_flag == "0") {
//       return { backgroundColor: "white", color: "black" };
//     }
//     if (attendance_flag == "1" && buttonType === "P") {
//       return { backgroundColor: "green", color: "white" };
//     }
//     if (attendance_flag == "2" && buttonType === "A") {
//       return { backgroundColor: "red", color: "white" };
//     }
//     return { backgroundColor: "white", color: "black" };
//   };

//   const data = {
//     columns: [
//       { label: 'Sr.No', field: 'sno', sort: 'asc', attributes: { className: 'ssss' } },
//       { label: 'Block No', field: 'block_id', sort: 'asc', attributes: { className: 'ssss' } },
//       { label: 'Room No', field: 'room_id', sort: 'asc', attributes: { className: 'ssss' } },
//       { label: 'Name', field: 'name', sort: 'asc', attributes: { className: 'name-column' } },
//       { label: 'Attendance', field: 'attendance_flag', sort: 'asc', attributes: { style: { textAlign: 'center' } } }
//     ],
//     rows: memberList.map((member, index) => ({
//       sno: index + 1,
//       block_id: member.attendanceList.block_id,
//       room_id: member.attendanceList.room_id,
//       name: (
//         <span className="text-start">
//           {member.attendanceList.name}
//         </span>
//       ),
//       attendance_flag: (
//         <>
//           <Button
//             variant="contained"
//             className='me-1 '
//             style={getButtonStyle(member.attendance_flag, "P")}
//             onClick={() => handleAttendanceToggle(member.member_id, "1")}
//           >
//             <b>P</b>
//           </Button>
//           <Button
//             variant="contained"
//             className='ms-1'
//             style={getButtonStyle(member.attendance_flag, "A")}
//             onClick={() => handleAttendanceToggle(member.member_id, "2")}
//           >
//             <b>A</b>
//           </Button>
//         </>
//       )
//     }))
//   };

//   return (
//     <>
//       <Sidebar />
//       <section className="home-section">
//         <NavLogo />

//         <div className='pb-5'>
//           <div className="block-header">
//             <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
//               Event Attendance
//             </h2>
//           </div>

//           <Row className="m-0 pt-1 px-2 px-md-3 rounded">
//             <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
//               <Row>
//                 <Col md={3} className="mt-3 mt-md-0">
//                   <FormControl fullWidth margin="dense" size="small">
//                     <InputLabel id="event-select-label">Event Date</InputLabel>
//                     <Select
//                       labelId="event-select-label"
//                       id="event-select"
//                       value={selectedEventId}
//                       label="Event Date"
//                       onChange={handleEventChange}
//                     >
//                       {events.map(event => (
//                         <MenuItem key={event.event_id} value={event.event_id}>
//                           {event.event_date}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Col>
//                 <Col md={3} className="mt-3 mt-md-0">
//                   <FormControl fullWidth margin="dense" size="small">
//                     <InputLabel id="description-select-label">Description</InputLabel>
//                     <Select
//                       labelId="description-select-label"
//                       id="description-select"
//                       value={selectedDescription}
//                       label="Description"
//                       onChange={handleDescriptionChange}
//                       disabled={eventDescriptions.length === 0}
//                     >
//                       {eventDescriptions.map(desc => (
//                         <MenuItem key={desc.event_id} value={desc.event_id}>
//                           {desc.discription}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Col>
//                 <Col md={3} className="mt-3 mt-md-0">
//                   <FormControl fullWidth margin="dense" size="small">
//                     <InputLabel id="student-data-type-select-label">Student Data Type</InputLabel>
//                     <Select
//                       labelId="student-data-type-select-label"
//                       id="student-data-type-select"
//                       value={studentDataType}
//                       label="Student Data Type"
//                       onChange={handleStudentDataTypeChange}
//                     >
//                       <MenuItem value={1}>Block Wise</MenuItem>
//                       <MenuItem value={2}>Alphabetical Order</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Col>
//                 <Col md={3} className="mt-3 mt-md-0">
//                   <FormControl fullWidth margin="dense" size="small">
//                     <InputLabel id="attendance-type-select-label">Attendance Type</InputLabel>
//                     <Select
//                       labelId="attendance-type-select-label"
//                       id="attendance-type-select"
//                       value={attendanceType}
//                       label="Attendance Type"
//                       onChange={handleAttendanceTypeChange}
//                     >
//                       <MenuItem value={1}>Manual</MenuItem>
//                       <MenuItem value={2}>Biometric</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Col>
//               </Row>
//             </Col>
//           </Row>

//           <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
//             <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
//               <div style={{ overflowY: 'auto' }}>
//                 <div style={{ overflowX: 'auto' }}>
//                   <MDBDataTable striped className="custom-datatable text-center" bordered hover data={data} />
//                 </div>
//               </div>
//               <div className="text-center pt-2 rounded" style={{display:"flex",alignItems:"center",justifyContent:"space-around",border:"1px solid rgb(214, 222, 219)"}}>
//                 <p><b style={{color:"maroon"}}>Total Students</b> : <b>{totalStudents}</b></p>
//                 <p><b style={{color:"green"}}>Total Present</b> : <b>{totalPresent}</b></p>
//                 <p><b style={{color:"red"}}>Total Absent</b> : <b>{totalAbsent}</b></p>
//               </div>
//               <div className="text-right text-center mt-3 mb-4">
              
//                 {!isAttendanceSaved ? (
//                   <Button
//                   style={{ backgroundColor: "rgb(0, 120, 215)" }}
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSaveAttendance}
//                   >
//                     Save Attendance
//                   </Button>
//                 ) : (
//                   <Button
//                     variant="contained"
//                     style={{ backgroundColor: "rgb(0, 120, 215)" }}
//                     color="secondary"
//                     onClick={handleSaveAttendance}
//                   >
//                     Update Attendance
//                   </Button>
//                 )}
//               </div>
//             </Col>
//           </Row>
//         </div>
//       </section>
//     </>
//   );
// };

// export default EventAttendance;







import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import axios from 'axios';
import { EventDatelistAdd, EventDateSelectAttendance, EventMemberList, EventAttendanceAdd, EventDateList } from '../../ApiFile';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';

const EventAttendance = () => {
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState('');
  const [selectedEventnum, setSelectedEventnum] = useState('');
  const [eventDescriptions, setEventDescriptions] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState('');
  const [studentDataType, setStudentDataType] = useState('');
  const [attendanceType, setAttendanceType] = useState(1);
  const [memberList, setMemberList] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalPresent, setTotalPresent] = useState(0);
  const [totalAbsent, setTotalAbsent] = useState(0);

  useEffect(() => {
    axios.get(EventDateSelectAttendance)
      .then(response => {
        setEvents(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the events!", error);
      });
  }, []);

  const handleEventChange = (event) => {
    console.log("value.....",event.target.value);
    
    const selectedEvent = events.find(e => e.event_id == event.target.value);
    setSelectedEventId(event.target.value);
    if (selectedEvent) {
      axios.post(EventDateList, { event_date: selectedEvent.event_date })
        .then(response => {
          setEventDescriptions(response.data);
        })
        .catch(error => {
          console.error("There was an error fetching the event descriptions!", error);
        });
    }
  };

  const handleDescriptionChange = (event) => {
    setSelectedEventnum(event.target.value);
    console.log("value.....dis",event.target.value);
    setSelectedDescription(event.target.value);
  };

  const handleStudentDataTypeChange = (event) => {
    setStudentDataType(event.target.value);
  };

  const handleAttendanceTypeChange = (event) => {
    setAttendanceType(event.target.value);
  };

  const fetchMemberList = () => {
    const selectedEvent = eventDescriptions.find(e => e.event_id == selectedEventnum);
    console.log("numididfj...",selectedEvent);
    console.log("numididfj123...",selectedEventnum);
    
    if (selectedEvent) {
      axios.post(EventMemberList, {
        list_flag: studentDataType,
        event_date: selectedEvent.event_date,
        event_id: selectedEventnum,
      })
        .then(response => {
          const updatedMemberList = response.data.map(member => ({
            ...member,
            attendance_flag: member.attendanceList.attendance_flag || "0" // Default to "Pending"
          }));
          setMemberList(updatedMemberList);
          updateTotals(updatedMemberList);
        })
        .catch(error => {
          console.error("There was an error fetching the event member list!", error);
        });
    }
  };

  const updateTotals = (members) => {
    const total = members.length;
    const present = members.filter(member => member.attendance_flag == "1").length;
    const absent = members.filter(member => member.attendance_flag == "2").length;
    setTotalStudents(total);
    setTotalPresent(present);
    setTotalAbsent(absent);
  };

  const handleAttendanceToggle = (memberId, flag) => {
    setMemberList(prevList => {
      const updatedList = prevList.map(member =>
        member.member_id === memberId ? { ...member, attendance_flag: flag } : member
      );
      updateTotals(updatedList);
      return updatedList;
    });
  };

  useEffect(() => {
    if (selectedEventnum && studentDataType) {
      fetchMemberList();
    }
  }, [selectedEventnum, studentDataType]);

  const handleSaveAttendance = () => {
    const hasPendingAttendance = memberList.some(member =>  member.attendance_flag === "0");

    
    if (hasPendingAttendance) {
      toast.error('Please check all attendance records before saving!');
    } else {
      const attendanceData = {
        flag: attendanceType,
        event_id: selectedEventnum,
        attendanceList: memberList.map(member => ({
          member_id: member.member_id,
          attendance_flag: member.attendance_flag
        }))
      };
  
      axios.post(EventAttendanceAdd, attendanceData)
        .then(response => {
          if (response.data == 'SAVED') {
            
            toast.success('Attendance data saved successfully!');
            fetchMemberList();
          }
          else if(response.data == 'ALREADYEXIST'){
        
            toast.warn('Attendance data already exists!');
            fetchMemberList();
          }
        })
        .catch(error => {
          console.error("There was an error saving the attendance data!", error);
        });
    }
  };
  

  const getButtonStyle = (attendance_flag, buttonType) => {
    if (attendance_flag == "0") {
      return { backgroundColor: "white", color: "black" };
    }
    if (attendance_flag == "1" && buttonType === "P") {
      return { backgroundColor: "green", color: "white" };
    }
    if (attendance_flag == "2" && buttonType === "A") {
      return { backgroundColor: "red", color: "white" };
    }
    return { backgroundColor: "white", color: "black" };
  };

  const data = {
    columns: [
      { label: 'Sr.No', field: 'sno', sort: 'asc', attributes: { className: 'ssss' } },
      { label: 'Block No', field: 'block_id', sort: 'asc', attributes: { className: 'ssss' } },
      { label: 'Room No', field: 'room_id', sort: 'asc', attributes: { className: 'ssss' } },
      { label: 'Name', field: 'name', sort: 'asc', attributes: { className: 'name-column' } },
      { label: 'Attendance', field: 'attendance_flag', sort: 'asc', attributes: { style: { textAlign: 'center' } } }
    ],
    rows: memberList.map((member, index) => ({
      sno: index + 1,
      block_id: member.attendanceList.block_id,
      room_id: member.attendanceList.room_id,
      name: (
        <span className="text-start">
          {member.attendanceList.name}
        </span>
      ),
      attendance_flag: (
        <>
          <Button
            variant="contained"
            className='me-1 '
            style={getButtonStyle(member.attendance_flag, "P")}
            onClick={() => handleAttendanceToggle(member.member_id, "1")}
          >
            <b>P</b>
          </Button>
          <Button
            variant="contained"
            className='ms-1'
            style={getButtonStyle(member.attendance_flag, "A")}
            onClick={() => handleAttendanceToggle(member.member_id, "2")}
          >
            <b>A</b>
          </Button>
        </>
      )
    }))
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />

        <div className='pb-5'>
          <div className="block-header">
            <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
              Event Attendance
            </h2>
          </div>

          <Row className="m-0 pt-1 px-2 px-md-3 rounded">
            <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
              <Row>
                <Col md={3} className="mt-3 mt-md-0">
                  <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="event-select-label">Event Date</InputLabel>
                    <Select
                      labelId="event-select-label"
                      id="event-select"
                      value={selectedEventId}
                      label="Event Date"
                      onChange={handleEventChange}
                    >
                      {events.map(event => (
                        <MenuItem key={event.event_id} value={event.event_id}>
                          {event.event_date}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col md={3} className="mt-3 mt-md-0">
                  <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="description-select-label">Description</InputLabel>
                    <Select
                      labelId="description-select-label"
                      id="description-select"
                      value={selectedDescription}
                      label="Description"
                      onChange={handleDescriptionChange}
                      disabled={eventDescriptions.length === 0}
                    >
                      {eventDescriptions.map(desc => (
                        <MenuItem key={desc.event_id} value={desc.event_id}>
                          {desc.discription}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col md={3} className="mt-3 mt-md-0">
                  <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="student-data-type-select-label">Student Data Type</InputLabel>
                    <Select
                      labelId="student-data-type-select-label"
                      id="student-data-type-select"
                      value={studentDataType}
                      label="Student Data Type"
                      onChange={handleStudentDataTypeChange}
                    >
                      <MenuItem value={1}>Block Wise</MenuItem>
                      <MenuItem value={2}>Alphabetical Order</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col md={3} className="mt-3 mt-md-0">
                  <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="attendance-type-select-label">Attendance Type</InputLabel>
                    <Select
                      labelId="attendance-type-select-label"
                      id="attendance-type-select"
                      value={attendanceType}
                      label="Attendance Type"
                      onChange={handleAttendanceTypeChange}
                    >
                      <MenuItem value={1}>Manual</MenuItem>
                      <MenuItem value={2}>Biometric</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
            <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
              <div style={{ overflowY: 'auto' }}>
                <div style={{ overflowX: 'auto' }}>
                  <MDBDataTable striped className="custom-datatable text-center" bordered hover data={data} />
                </div>
              </div>
              <div className="text-center pt-2 rounded" style={{display:"flex",alignItems:"center",justifyContent:"space-around",border:"1px solid rgb(214, 222, 219)"}}>
                <p><b style={{color:"maroon"}}>Total Students</b> : <b>{totalStudents}</b></p>
                <p><b style={{color:"green"}}>Total Present</b> : <b>{totalPresent}</b></p>
                <p><b style={{color:"red"}}>Total Absent</b> : <b>{totalAbsent}</b></p>
              </div>
              <div className="text-right text-center mt-3 mb-4">
                <Button style={{ backgroundColor: "rgb(0, 120, 215)" }} onClick={handleSaveAttendance}>
                  <b style={{ color: "white" }}>Save Attendance</b>
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default EventAttendance;
