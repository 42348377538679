import react, { Fragment, useState, useEffect } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { Form } from "react-bootstrap";
import {} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Image from "./logo (2).png";
import { AiOutlineLogout } from "react-icons/ai";
import logo1 from "../../StudentRegristrationDesign/jain logo.png";
import { Button, DatePicker} from "antd";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import {
  AluminiAdd,
  AluminiDistrictFind,
  AluminiStateFindAll,
  AluminiTalukaFind,
} from "../../ApiFile";

function Aluminiform() {
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();
  const [yearFrom, setYearFrom] = useState(currentYear.toString());
  const [yearTo, setYearTo] = useState(currentYear.toString());

  const handleChangeFrom = (e) => {
    setYearFrom(e.target.value);
  };
  const handleChangeTo = (e) => {
    setYearTo(e.target.value);
  };

  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const email_id = userdata.email_id;
  const f_name = userdata.f_name;
  const m_name = userdata.m_name;
  const l_name = userdata.l_name;

  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);
  const [pDistrictList, setPDistrictList] = useState([]); // new state for permanent address district list
  const [pTalukaList, setPTalukaList] = useState([]); // new state for permanent address taluka list

  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedTaluka, setSelectedTaluka] = useState("");
  const [pSelectedState, setPSelectedState] = useState("");
  const [pSelectedDistrict, setPSelectedDistrict] = useState("");
  const [pSelectedTaluka, setPSelectedTaluka] = useState("");
  const [fileName, setFileName] = useState("");

  const [currentAddress, setCurrentAddress] = useState("");
  const [currentPincode, setCurrentPincode] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [permanentPincode, setPermanentPincode] = useState("");

  const [blood_group, setblood_group] = useState("");
  const [qualification, setqualification] = useState("");
  const [dateofbirth, setdateofbirth] = useState("");
  const [date, setDate] = useState(null);
  const [mob_no, setmob_no] = useState("");
  const [alternate_mobno, setalternate_mobno] = useState("");
  const [professional_category, setprofessional_category] = useState("");
  const [service_name, setservice_name] = useState("");
  const [reference_fname, setreference_fname] = useState("");
  const [reference_mname, setreference_mname] = useState("");
  const [reference_lname, setreference_lname] = useState("");
  const [contactdetails, setcontactdetails] = useState("");
  const [achievements, setachievements] = useState("");
  const [comment, setcomment] = useState("");
  const [file, setfile] = useState(null);

  useEffect(() => {
    axios
      .get(AluminiStateFindAll)
      .then((response) => {
        setStateList(response.data);
      })
      .catch((error) => {
        // console.error('Error fetching states:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedState !== "") {
      axios
        .get(`${AluminiDistrictFind}/${selectedState}`)
        .then((response) => {
          setDistrictList(response.data);
        })
        .catch((error) => {
          // console.error('Error fetching districts:', error);
        });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedState !== "" && selectedDistrict !== "") {
      axios
        .get(`${AluminiTalukaFind}/${selectedState}/${selectedDistrict}`)
        .then((response) => {
          setTalukaList(response.data);
        })
        .catch((error) => {
          // console.error('Error fetching talukas:', error);
        });
    }
  }, [selectedState, selectedDistrict]);

  useEffect(() => {
    if (pSelectedState !== "") {
      axios
        .get(`${AluminiDistrictFind}/${pSelectedState}`)
        .then((response) => {
          setPDistrictList(response.data);
        })
        .catch((error) => {
          // console.error('Error fetching permanent address districts:', error);
        });
    }
  }, [pSelectedState]);

  useEffect(() => {
    if (pSelectedState !== "" && pSelectedDistrict !== "") {
      axios
        .get(`${AluminiTalukaFind}/${pSelectedState}/${pSelectedDistrict}`)
        .then((response) => {
          setPTalukaList(response.data);
        })
        .catch((error) => {
          // console.error('Error fetching permanent address talukas:', error);
        });
    }
  }, [pSelectedState, pSelectedDistrict]);

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setSelectedDistrict("");
    setSelectedTaluka("");
  };

  const handleDistrictChange = (e) => {
    setSelectedDistrict(e.target.value);
    setSelectedTaluka("");
  };

  const handleTalukaChange = (e) => {
    setSelectedTaluka(e.target.value);
  };

  const handlePStateChange = (e) => {
    setPSelectedState(e.target.value);
    setPSelectedDistrict("");
    setPSelectedTaluka("");
  };

  const handlePDistrictChange = (e) => {
    setPSelectedDistrict(e.target.value);
    setPSelectedTaluka("");
  };

  const handlePTalukaChange = (e) => {
    setPSelectedTaluka(e.target.value);
  };
  const [responseMessage, setResponseMessage] = useState("");

  //checkbox
  const [usePermanentAddress, setUsePermanentAddress] = useState(false);
  const handleUsePermanentAddressChange = (e) => {
    setUsePermanentAddress(e.target.checked);
    if (e.target.checked) {
      setPSelectedState(selectedState);
      setPSelectedDistrict(selectedDistrict);
      setPSelectedTaluka(selectedTaluka);
      setPermanentAddress(currentAddress);
      setPermanentPincode(currentPincode);
    } else {
      setPSelectedState("");
      setPSelectedDistrict("");
      setPSelectedTaluka("");
      setPermanentAddress("");
      setPermanentPincode("");
    }
  };

  const handleCurrentAddressChange = (event) => {
    setCurrentAddress(event.target.value);
  };

  const handleCurrentPincodeChange = (e) => {
    // setCurrentPincode(event.target.value);
    const input = e.target.value;
    if (/^\d*$/.test(input) && input.length <= 6) {
      // Update state only if the input is a number and within the length limit
      setCurrentPincode(input);
    }
  };

  const [errors, setErrors] = useState({});

  // Validation functions
  const validateMobileNumber = (number) => /^[0-9]{10}$/.test(number);
  const validatePincode = (pincode) => /^[0-9]{6}$/.test(pincode);

  //add api
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    const formattedDate = date ? dayjs(date).format('DD/MM/YYYY') : null;

    try {
      formData.append("file", file);
      formData.append("fname", f_name);
      formData.append("mname", m_name);
      formData.append("lname", l_name);
      formData.append("qualification", qualification);
      formData.append("address", currentAddress);
      formData.append("pincode", currentPincode);
      formData.append("state", selectedState);
      formData.append("district", selectedDistrict);
      formData.append("taluka", selectedTaluka);
      formData.append("alternate_address", permanentAddress);
      formData.append("alternate_pincode", permanentPincode);
      formData.append("alternate_state", pSelectedState);
      formData.append("alternate_district", pSelectedDistrict);
      formData.append("alternate_taluka", pSelectedTaluka);
      formData.append("dateofbirth", formattedDate);
      formData.append("blood_group", blood_group);
      formData.append("email_id", email_id);
      formData.append("mob_no", mob_no);
      formData.append("alternate_mobno", alternate_mobno);
      formData.append("professional_category", professional_category);
      formData.append("service_name", service_name);
      formData.append("fromyear", yearFrom);
      formData.append("toyear", yearTo);
      formData.append("reference_fname", reference_fname);
      formData.append("reference_mname", reference_mname);
      formData.append("reference_lname", reference_lname);
      formData.append("contactdetails", contactdetails);
      formData.append("achievements", achievements);
      formData.append("comment", comment);

      console.log([...formData]);

      // Validation code here...

      const response = await axios.post(AluminiAdd, formData);
      toast.success("Alumini data added successfully!");
      navigate("/AluminiDashboard");
    } catch (error) {
      console.error("Error occurred while submitting the form:", error);
    }
  };

 

  const logoutFun = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    if (userdata == null) {
      sessionStorage.clear();
      navigate("/AluminiLogin");
    }
  }, []);

  const inputStyle = {
    marginTop:"-9px",
  height:"37px"
  };

  return (
    <>
      <Container className="pb-5">
        <div
          className="shadow"
          style={{
            backgroundColor: "#B9D9EB",
            borderRadius: "10px",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <Row
            className=" py-1 shadow m-0 p-0 "
            style={{
              display: "flex",
              backgroundColor: "#9acac9",
            }}
          >
            <Col sm={12} md={8}>
              <img
                src={logo1}
                alt="Logo"
                style={{ mixBlendMode: "darken" }}
                className="w-100"
              />
            </Col>
            <Col sm={12} md={4} className="text-end">
              <p
                style={{ textAlign: "end", textShadow: "1px 1px 1px" }}
                className="pt-4 pe-4"
              >
                <a className="sidebar-list-link" onClick={logoutFun}>
                  <AiOutlineLogout className="icon" />
                </a>
              </p>
            </Col>
          </Row>

          <Row>
            <h3
              style={{ textAlign: "center", color: "maroon" }}
              className="pb-3 pt-2"
            >
              Alumini Registration Form
            </h3>
          </Row>
          <Row>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload Photo</Form.Label>
              <Form.Control
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={(e) => setfile(e.target.files[0])}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Label>Full Name</Form.Label>
            <Form.Group as={Col} xs={12} sm={4} controlId="formGridFname">
              <Form.Control
                type="text"
                placeholder="First name"
                required
                value={f_name}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={4} controlId="formGridMname">
              <Form.Control
                type="text"
                placeholder="Middle name"
                required
                value={m_name}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={4} controlId="formGridLname">
              <Form.Control
                type="text"
                placeholder="Last Name"
                required
                value={l_name}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
         
          <Form.Group as={Col} xs={12} sm={3} controlId="formFile">
          <Form.Label>Date of Birth</Form.Label>
          <LocalizationProvider  dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                format="DD-MM-YYYY"
                
                className="w-100 rounded"
                style={inputStyle}
                required
                value={date}
                onChange={(newDate) => setDate(newDate)}
                slotProps={{ textField: { size: "small" } }}
             
              />
            </DemoContainer>
          </LocalizationProvider>
        </Form.Group>
                 

        
            <Form.Group as={Col} xs={12} sm={3} controlId="formGridPassword">
              <Form.Label>Blood Group</Form.Label>
              <Form.Select
                defaultValue="Choose..."
                value={blood_group}
                onChange={(e) => setblood_group(e.target.value)}
                required
              >
                <option>Choose...</option>
                <option value='A +ve'>A +ve</option>
                <option value='A -ve'>A -ve</option>
                <option value='B +ve'>B +ve</option>
                <option value='B -ve'>B -ve</option>
                <option value='AB +ve'>AB +ve</option>
                <option value='AB -ve'>AB -ve</option>
                <option value='O +ve'>O +ve</option>
                <option value='O -ve'>O -ve</option>
                <option value='Other'>Other</option>
           
              </Form.Select>
            </Form.Group>




            <Form.Group as={Col} xs={12} sm={6} controlId="formGridEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                maxLength={25}
                placeholder=""
                value={email_id}
                required
              />
            </Form.Group>
          </Row>
          <hr style={{ height: "3px" }} />


          <Row className="mb-3">
              <Form.Group as={Col} xs={12} sm={3} controlId="formGridState">
                <Form.Label>State</Form.Label>
                <Form.Select
                  defaultValue="Choose..."
                  name="state"
                  value={selectedState}
                  onChange={handleStateChange}
                  required
                >
                 <option value="">Choose...</option>
                  {stateList.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.state_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} xs={12} sm={3} controlId="formGridDistrict">
                <Form.Label>District</Form.Label>
                <Form.Select
                  defaultValue="Choose..."
                  name="district"
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  required
                >
                   <option value="">Choose...</option>
                  {districtList.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.districtenname}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} xs={12} sm={3} controlId="formGridTaluka">
                <Form.Label>Taluka</Form.Label>
                <Form.Select
                  defaultValue="Choose..."
                  name="taluka"
                  value={selectedTaluka}
                  onChange={handleTalukaChange}
                  required
                >
                <option value="">Choose...</option>
                  {talukaList.map((taluka) => (
                    <option key={taluka.id} value={taluka.id}>
                      {taluka.talukaenname}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} xs={12} sm={3} controlId="formGridPassword">
                <Form.Label>Pin code</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="pincode"
                  maxLength={6}
                  value={currentPincode}
                  onChange={handleCurrentPincodeChange}
                  required
                />
                {errors.currentPincode && (
                  <p className="text-danger">{errors.currentPincode}</p>
                )}
              </Form.Group>
            </Row>

          <div className="Caddress">
            <Form.Group as={Col} xs={12} controlId="formGridEmail">
              <Form.Label>Current Detail Address</Form.Label>
              <Form.Control
                type="text"
                maxLength={200}
                value={currentAddress}
                onChange={handleCurrentAddressChange}
                placeholder="House Name/No/Road/Chowk/Colony/Landmark/P.O/Village/City"
                required
              />
            </Form.Group>

            
          </div>
          <hr style={{ height: "3px" }} />
          <Form.Group
            as={Col}
            xs={12}
            className="mb-4"
            sm={12}
            controlId="formGridUsePermanentAddress"
          >
            <Form.Check
              type="checkbox"
              label="Use current address as permanent address"
              checked={usePermanentAddress}
              onChange={handleUsePermanentAddressChange}
            />
          </Form.Group>

          <Row className="mb-3">
              <Form.Group as={Col} xs={12} sm={3} controlId="formGridPState">
                <Form.Label>State</Form.Label>
                <Form.Select
                  defaultValue="Choose..."
                  name="alternate_state"
                  value={pSelectedState}
                  onChange={handlePStateChange}
                  required
                >
             <option value="">Choose...</option>
                  {stateList.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.state_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} xs={12} sm={3} controlId="formGridPDistrict">
                <Form.Label>District</Form.Label>
                <Form.Select
                  defaultValue="Choose..."
                  name="alternate_district"
                  value={pSelectedDistrict}
                  onChange={handlePDistrictChange}
                  required
                >
                <option value="">Choose...</option>
                  {pDistrictList.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.districtenname}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} xs={12} sm={3} controlId="formGridPTaluka">
                <Form.Label>Taluka</Form.Label>
                <Form.Select
                  defaultValue="Choose..."
                  name="alternate_taluka"
                  value={pSelectedTaluka}
                  onChange={handlePTalukaChange}
                  required
                >
               <option value="">Choose...</option>
                  {pTalukaList.map((taluka) => (
                    <option key={taluka.id} value={taluka.id}>
                      {taluka.talukaenname}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} xs={12} sm={3} controlId="formGridPassword">
                <Form.Label>Pin code</Form.Label>
                <Form.Control
                  type="number"
                  maxLength={6}
                  placeholder=""
                  value={permanentPincode}
                  //  onChange={(e) => setPermanentPincode(e.target.value)}
                  onChange={(e) => {
                    const input = e.target.value;
                    // Check if the input consists only of numbers and has a maximum length of 6
                    if (/^\d*$/.test(input) && input.length <= 6) {
                      // Update state only if the input is a number and within the length limit
                      setPermanentPincode(input);
                    }
                  }}
                  required
                />
                {errors.permanentPincode && (
                  <p className="text-danger">{errors.permanentPincode}</p>
                )}
              </Form.Group>
            </Row>
                      
          <div className="Paddress">
            <Form.Group as={Col} xs={12} controlId="formGridEmail">
              <Form.Label className="pt-3">Permanent Detail Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="House Name/No/Road/Chowk/Colony/Landmark/P.O/Village/City"
                value={permanentAddress}
                onChange={(e) => setPermanentAddress(e.target.value)}
                required
              />
            </Form.Group>

            
          </div>
          <hr style={{ height: "3px" }} />

          <Row className="mb-3">
            <Form.Group as={Col} xs={12} sm={6}>
              <Form.Label>Mobile(WhatsApp) Number</Form.Label>
              <Form.Control
                id="mobileNumber"
                placeholder=""
                value={mob_no}
                onChange={(e) => setmob_no(e.target.value)}
                required
              />
              {errors.mob_no && <p className="text-danger">{errors.mob_no}</p>}
            </Form.Group>

            <Form.Group as={Col} xs={12} sm={6}>
              <Form.Label>Alternate Number</Form.Label>
              <Form.Control
                id="mobileNumber"
                placeholder=""
                value={alternate_mobno}
                onChange={(e) => setalternate_mobno(e.target.value)}
              />
              {errors.alternate_mobno && (
                <p className="text-danger">{errors.alternate_mobno}</p>
              )}
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} xs={12} sm={6} controlId="formGridEmail">
              <Form.Label>Qualification</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                maxLength={200}
                value={qualification}
                onChange={(e) => setqualification(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} xs={12} sm={6} controlId="formGridState">
              <Form.Label>Professional Category</Form.Label>
              <Form.Select
                defaultValue="Choose..."
                value={professional_category}
                onChange={(e) => setprofessional_category(e.target.value)}
                required
              >
                <option>Choose...</option>
                <option value='Teacher/Professor'>Teacher/Professor</option>
                <option value='Chef'>Chef</option>
                <option value='Engineer'>Engineer</option>
                <option value='Software IT'>Software IT</option>
                <option value='Lawyer'>Lawyer</option>
                <option value='Chartered Accountant'>Chartered Accountant</option>
                <option value="Aviator">Aviator</option>
                <option value="Health professional">Health professional</option>
                <option value="Architect">Architect</option>
                <option value="Analyst">Analyst</option>
                <option value="Data Engineer">Data Engineer</option>
                <option value="Makeup Artist">Makeup Artist</option>
                <option value="Medical Representative">
                  Medical Representative
                </option>
                <option value="Flight Attendant">Flight Attendant</option>
                <option value="Customer Service Representative">
                  Customer Service Representative
                </option>
                <option value="Network Administrator">
                  Network Administrator
                </option>
                <option value="Accountant">Accountant</option>
                <option value="Physician">Physician</option>
                <option value="Secretary">Secretary</option>
                <option value="Dentist">Dentist</option>
                <option value="Designer">Designer</option>
                <option value="Economist">Economist</option>
                <option value="Dietitians And Nutritionists">
                  Dietitians And Nutritionists
                </option>
                <option value="Air Traffic Controller">
                  Air Traffic Controller
                </option>
                <option value="Photographer">Photographer</option>
                <option value="Mechanic">Mechanic</option>
                <option value="Driver">Driver</option>
                <option value="Personal Stylist">Personal Stylist</option>
                <option value="Aeronautical Engineer">
                  Aeronautical Engineer
                </option>
                <option value="Business Consultant">Business Consultant</option>
                <option value="Software Engineer">Software Engineer</option>
                <option value="Data Scientist">Data Scientist</option>
                <option value="Advocate">Advocate</option>
                <option value="Veterinarian">Veterinarian</option>
                <option value="Businessperson">Businessperson</option>
                <option value="Web Designer">Web Designer</option>
                <option value="Scientist">Scientist</option>
                <option value="Firefighter">Firefighter</option>
                <option value="Journalist">Journalist</option>
                <option value="Politician">Politician</option>
                <option value="Farmer">Farmer</option>
                <option value="Judge">Judge</option>
                <option value="Civil Engineer">Civil Engineer</option>
                <option value="Technician">Technician</option>
                <option value="Design Engineer">Design Engineer</option>
                <option value="Private Investigator">
                  Private Investigator
                </option>
                <option value="Geologist">Geologist</option>
                <option value="Merchant">Merchant</option>
                <option value="Chemist">Chemist</option>
                <option value="Pharmasist">Pharmasist</option>
                <option value="Civil Contractor">Civil Contractor</option>
                <option value="Tax Consultant">Tax Consultant</option>
                <option value="Policeman">Policeman</option>
                <option value="Civil Administration">
                  Civil Administration
                </option>
                <option value="Govt. Service man">Govt. Service man</option>
                <option value="Student">Student</option>
                <option value="Defence Services">Defence Services</option>
                <option value="Electrician">Electrician</option>
                <option value="Other">Other</option>
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} xs={12} sm={6} controlId="formGridEmail">
              <Form.Label>Business/Service Name</Form.Label>
              <Form.Control
                type="text"
                value={service_name}
                onChange={(e) => setservice_name(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12} sm={6} controlId="formGridYearRange">
              <Form.Label>Year of Boarding Residence</Form.Label>
              <div className="d-flex">
                <Form.Select
                  value={yearFrom}
                  defaultValue="Choose..."
                  name="fromyear"
                  onChange={handleChangeFrom}
                  className="mr-5"
                  required
                >
                  {[...Array(50)].map((_, index) => (
                    <option key={index} value={currentYear - index}>
                      From ({currentYear - index})
                    </option>
                  ))}
                </Form.Select>
                <Form.Select
                  value={yearTo}
                  name="toyear"
                  defaultValue="Choose..."
                  onChange={handleChangeTo}
                  required
                >
                  {[...Array(50)].map((_, index) => (
                    <option key={index} value={currentYear - index}>
                      To ({currentYear - index})
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Form.Group>
          </Row>
          <hr style={{ height: "3px" }} />

          <Row className="mb-3">
            <Form.Label style={{ color: "Blue" }}>
              Reference of other Partners with Contact details, If Any
            </Form.Label>
            <Form.Label>Partner's Full Name</Form.Label>
            <Form.Group as={Col} xs={12} sm={4} controlId="formGridEmail">
              <Form.Control
                type="text"
                placeholder="First Name"
                value={reference_fname}
                onChange={(e) => setreference_fname(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={4} controlId="formGridEmail">
              <Form.Control
                type="text"
                placeholder="Middle Name"
                value={reference_mname}
                onChange={(e) => setreference_mname(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={4} controlId="formGridEmail">
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={reference_lname}
                onChange={(e) => setreference_lname(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Form.Group as={Col} xs={12} sm={4} controlId="formGridEmail">
            <Form.Label>Partner's Contact </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={contactdetails}
              onChange={(e) => setcontactdetails(e.target.value)}
            />
          </Form.Group>
          <hr style={{ height: "3px" }} />

          <Form.Group as={Col} xs={12} controlId="formGridEmail">
            <Form.Label>Special Achievements</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={achievements}
              onChange={(e) => setachievements(e.target.value)}
            />
          </Form.Group>

          <Row className="mb-3">
            <Form.Group as={Col} xs={12} controlId="formGridPassword">
              <Form.Label className="pt-2">Suggestions/Comments</Form.Label>
              <Form.Control
                type="textarea"
                placeholder="Leave a comment here"
                value={comment}
                onChange={(e) => setcomment(e.target.value)}
                className="w-100"
              />
            </Form.Group>
          </Row>

          <div className="text-center">
            <Button
              variant="primary"
              // type="submit"
              // onSubmit={handleSubmit}
              onClick={handleSubmit}
              className="regbtn p-3"
              style={{ backgroundColor: "rgb(0, 120, 215)", color: "white" }}
            >
              Register
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
}
export default Aluminiform;
