import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import '../Room Master/room.css';
import Header from './Header';
import Sidebar from './sidebar';
import { Col, Row } from 'react-bootstrap';
import { BlockFindall, RoomDetailsApi } from '../../ApiFile';
import axios from 'axios';
const BlockRoomBooking = () => {
    const navigate = useNavigate();
    const [blocks, setBlocks] = useState([]);
    const [blocks1, setBlocks1] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const year = sessionStorage.getItem("year");
    const memberId = sessionStorage.getItem('member_id');

    useEffect(() => {

      //---------------------------------Disabled back button-------------------------------
      window.history.pushState(null, null, window.location.href);
  
      const handleBackButton = (event) => {
        event.preventDefault();
        window.history.forward();
      };
  
      window.addEventListener('popstate', handleBackButton);
  
      return () => {
        window.removeEventListener('popstate', handleBackButton);
      };
    }, [ ]);

    useEffect(() => {
        const fetchBlocks = async () => {
          try {
            const response = await axios.get(RoomDetailsApi);
            console.log("console.log..",response.data);
            setBlocks1(response.data);
            setLoading(false);
          } catch (error) {
            setError(error);
            setLoading(false);
          }
        };
    
        fetchBlocks();
      }, []);

    useEffect(() => {
    //   console.log("api......", BlockFindall);
      fetch(BlockFindall, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              year: year, // "2024-25" if you want a fixed year
              member_id: memberId, // 5 if you want a fixed member_id
          }),
      })
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              return response.json();
          })
          .then(data => {
              setBlocks(data);
            //   console.log("res block findall//....", data);
              setLoading(false);
          })
          .catch(error => {
              setError(error);
              setLoading(false);
          });
  }, [year, memberId]);

    const handleBlockSelect = (block_id) => {
        navigate(`/room/${block_id}`);
        // console.log("block id././",block_id)
    };
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };
    return (
<>
    <Sidebar/>
     <section className="home-section">
       <Header/> 
       

        <main className="main-container">

        <Row className="m-0 p-0 pt-2 mb-1">
              <Col
                className=" rounded pt-2"
                style={{
                  backgroundColor: "white",
                  border: "0px",
                  alignItems: "center",
                  alignContent: "center",
                  color: " rgb(47, 79, 79)",
                }}
              >
                <h4 style={{ color: "maroon" }}>BLOCKS</h4>
              </Col>
            </Row>

        <Row className='px-md-4 px-2'>
        {blocks1.map(block => (
      <Col sm={12} md={3} className='p-4' key={block.id}>
      <div className='rounded text-center' style={{border:"1px solid #18528b"}}>
      <div className='ms-3 '  style={{zIndex:"-1",marginTop:"-15px",backgroundColor:"rgb(24, 82, 139)",width:"90%",borderRadius:"3px"}}>
      <h3 className='text-center text-light' style={{fontFamily:"cursive"}}>Block  {block.block_id}</h3></div>
     
      <div className='mt-3 text-dark'>
      <b style={{ color: 'black' }} >Deposit: </b>  {block.deposit}
      </div>
      <div className='mt-3 text-dark'>
      <b style={{ color: 'green' }} >Fees:  </b>{block.total}
      </div>
     
</div>
      </Col>
    ))}
    </Row>

    <Row className="m-0 p-0 pt-2 ">
              <Col
                className=" rounded pt-1"
                style={{
                  backgroundColor: "white",
                  border: "0px",
                  alignItems: "center",
                  alignContent: "center",
                  color: " rgb(47, 79, 79)",
                }}
              >
                <h4 style={{ color: "maroon" }}>Your Booking</h4>
              </Col>
            </Row>
       

            <div className="main-cards1">
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>Error fetching blocks: {error.message}</p>
                ) : blocks.length === 0 ? (
                    <p>No blocks available.</p>
                ) : (
                    blocks.map((block, index) => (
                        <div key={index} className='card5 mt-3' style={{backgroundColor:"pink"}}>
                            <h5>Block {block.block_id}</h5>
                            <h5>Rooms: {block.room_range}</h5>
                            <h5>Bed/Room :{block.bed_count}</h5>
                            <h5>Amount : {block.amount}</h5>
                            {/* <h5>Full Term Fee: {block.yearly_amount}</h5> */}
                            <h5>+Refundable Deposit: {block.deposit_amount}</h5>
                             <h5>Facilities: {block.discription}</h5>
                             <div style={{display:"flex",justifyContent:"center"}}>
                            <button 
                             className="fs-5 nextBtn shadow"
                        style={{backgroundColor:"maroon",color:"white",borderRadius:"10px",width:"100px",border:"0px"}} 
                             onClick={() => handleBlockSelect(block.block_id)}>Select</button>
                             </div>
                        </div>
                    ))
                )}
            </div>
           
        </main>
        </section>
        </>
    );
};

export default BlockRoomBooking;
