// import React, { useState, useEffect } from "react";
// import Sidebar from "./sidebarstudent";
// import Header from "./Headerstudent";
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// import { Col, Row } from "react-bootstrap";
// import { ReceiptFeeDetailsApi, ReceiptIdApi, StudentDownloadReceiptApi, StudentInstallmentPayApi } from "../../ApiFile";
// import logo from '../img/logo2.png';
// import logo2 from '../img/logonew.jpg';
// import { Box, Button, CircularProgress } from "@mui/material";
// import { useNavigate } from "react-router-dom";

// // Assign the fonts to pdfMake
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

// // Utility function to convert image to base64
// const toDataURL = url =>
//   fetch(url)
//     .then(response => response.blob())
//     .then(blob => new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onloadend = () => resolve(reader.result);
//       reader.onerror = reject;
//       reader.readAsDataURL(blob);
//     }));

// const DownloadReceipt = () => {
//   const [receiptData, setReceiptData] = useState(null);
//   const [feeDetails, setFeeDetails] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [logoBase64, setLogoBase64] = useState(null);
//   const [logo2Base64, setLogo2Base64] = useState(null);
//   const year = sessionStorage.getItem("year");
//   const memberId = sessionStorage.getItem('member_id');
//   const [data, setData] = useState([]);
//   const [receiptDetails, setReceiptDetails] = useState([]);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

  
//     const fetchReceiptDetails = async () => {
//       try {
//         const response = await fetch(`${ReceiptIdApi}/${memberId}`);
//         const data = await response.json();
//         setReceiptDetails(data[0]);
//         console.log("jhgach..",data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching the receipt details:", error);
//         setLoading(false);
//       }
//     };
//     useEffect(() => {
//     fetchReceiptDetails();
//   }, []);


//     // Fetch receipt data
//     const fetchReceiptData = async () => {
//       const requestBody = { id: receiptDetails.id, member_id: memberId };
//       console.log("Fetch Receipt Data Request Body:", requestBody); // Log the request body

//       try {
//         const response = await fetch(StudentDownloadReceiptApi, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(requestBody),
//         });

//         const data = await response.json();
//         console.log("Receipt Data Response:", data); // Log the response data

//         if (data.includes("Admin Payment Approval Pending") || data.includes("Admin Payment Approval Reject")) {
//           toast.error("Admin approval status: " + data);
//         } else {
//           setReceiptData(data[0]); // Assuming the response is an array with one item
//         }
//       } catch (error) {
//         console.error("Error fetching the receipt data:", error);
//       }
//     };

//     const fetchFeeDetails = async () => {
//       const requestBody = { id: receiptDetails.id, member_id: memberId };
//       console.log("Fetch Fee Details Request Body:", requestBody); // Log the request body

//       try {
//         const response = await fetch(ReceiptFeeDetailsApi, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(requestBody),
//         });

//         const data = await response.json();
//         console.log("Fee Details Response:", data); // Log the response data
//         console.log("Fee  Response:", response); // Log the response data

//         if (data == "Admin Payment Approval Pending" ){
//           toast.error("Admin approval status");
//           }
//          else if(data == "Admin Payment Approval Reject"){
//           toast.error("Admin Payment Approval Reject");
//         }
//         else{
//           setFeeDetails(data.feeDetails);
//         }
//       } catch (error) {
//         console.error("Error fetching the fee details:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     useEffect(() => {
//       // Convert images to base64
//       Promise.all([toDataURL(logo), toDataURL(logo2)])
//         .then(([logoBase64, logo2Base64]) => {
//           setLogoBase64(logoBase64);
//           setLogo2Base64(logo2Base64);
//         });
  
//     fetchReceiptData();
//     fetchFeeDetails();
//   }, [memberId, receiptDetails]);


//   useEffect(() => {
//     const fetchInstallmentData = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(StudentInstallmentPayApi, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify({ member_id: memberId })
//         });
// console.log("memberid...",memberId);
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }

//         const jsonData = await response.json();
//         console.log("ffff",jsonData);
//         if (jsonData === 'Already payment done') {
//           setData(null); // Reset data if NOTFOUND
//         } else {
//           setData(jsonData);
//           console.log("hsagdf..",jsonData)
//         }
//         setError(null);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchInstallmentData();
//   }, [memberId]);

//   const handleDownload = () => {
//     if (!receiptData || !feeDetails || !logoBase64 || !logo2Base64) return;
//     setLoading(true);
  
//     // Calculate the total amount
//     const totalAmount = Object.values(feeDetails).reduce((sum, fee) => sum + fee.amount, 0);
  
    
//   const tableBody = [
//     [
//       { text: "S.No", bold: true, alignment: 'center' },
//       { text: "Description", bold: true, alignment: 'center' },
//       { text: "Amount", bold: true, alignment: 'center' }
//     ]
//   ];

//   // Add fee details to the table
//   Object.entries(feeDetails).forEach(([key, value], index) => {
//     tableBody.push([
//       { text: index + 1, alignment: 'center' },
//       { text: key, alignment: 'left', style:'description' },
//       { text: value.amount, alignment: 'right',style:'amount'}
//     ]);
//   });

//   // Add the total amount row
//   tableBody.push([
//     {},
//     { text: "Total =", bold: true, alignment: 'center' },
//     { text: totalAmount, bold: true, alignment: 'right' ,style:'amount'}
//   ]);

//     var dd = {
//       content: [
//         {
//           columns: [
//             { style: "logo", width: "15%", image: logoBase64, fit: [50, 50] },
//             {
//               width: "70%",
//               text: [
//                 { text: "Dakshin Bharat Jain Sabha's \n", style: "header1" },
//                 { text: "Sheth R D Dawada Digambar Jain Boarding,\n", style: "header" },
//                 { text: "37, Mahavir Nagar, Sangli 416416, Phone: 0233-262306\n", style: "header2" },
//                 { text: "Web : www.jainboardingsangli.com  Email : jainboardingsangli@gmail.com \n", style: "header3" },
//               ],
//             },
//             { style: "logo", width: "15%", image: logo2Base64, fit: [50, 50] },
//           ],
//         },
//         {
//           margin: [0, 15, 0, 0],
//           canvas: [
//             {
//               type: 'line',
//               x1: 0, y1: 0,
//               x2: 515, y2: 0,
//               lineWidth: 1
//             }
//           ],
//         },
//         {
//           style: "receiptmargin",
//           columns: [
//             {
//               style: "receipt",
//               width: "33%",
//               text: [
//                 { text: 'Receipt No: ', bold: true },
//                 { text: `${receiptData.receiptNo}\n` }
//               ],
//             },
//             {
//               style: "receipt",
//               width: "33%",
//               text: [
//                 { text: 'Academic Year: ', bold: true },
//                 { text: year }
//               ],
//             },
//             {
//               style: "recdate",
//               width: "34%",
//               text: [
//                 { text: 'Receipt Date: ', bold: true },
//                 { text: `${receiptData.receipt_date}\n` }
//               ],
//             },
//           ],
//         },
//         {
//           columns: [
//             {
//               width: "66%",
//               text: [
//                 { text: 'Name: ', bold: true },
//                 { text: `${receiptData.memberName}\n` }
//               ],
//               style: "NameMargin"
//             },
//             {
//               width: "34%",
//               text: [{ text: 'Payment Date: ', bold: true }, { text: `${receiptData.payment_date}\n` }],
//               style: "NameMargin"
//             },
//           ],
//         },
//         {
//           text: [
//             { text: 'Address: ', bold: true },
//             { text: `${receiptData.address} , ${receiptData.village} ,` },
//             { text: ' Taluka: ', bold: true },
//             { text: `${receiptData.Taluka} , ` },
//             { text: ' District: ', bold: true },
//             { text: `${receiptData.District} , ` },
//             { text: ' State: ', bold: true },
//             { text: `${receiptData.State} , ` },
//             { text: ' Pin-Code: ', bold: true },
//             { text: `${receiptData.pincode} . ` }
//           ],
//           style: "NameMargin"
//         },
//         {
//           style: "NameMargin",
//           columns: [
//             { width: "33%", text: [{ text: 'Term: ', bold: true }, { text: `${receiptData.term}\n` }] },
//             { width: "33%", text: [{ text: 'Mobile No: ', bold: true }, { text: `${receiptData.mobileno}\n` }] },
//             { width: "34%", text: [{ text: 'Parent\'s Mob No: ', bold: true }, { text: `${receiptData.parents_mobno}\n` }] },
//           ],
//         },
//         {
//           style: "NameMargin",
//           columns: [
//             { width: "33%", text: [{ text: 'Payment Mode: ', bold: true }, { text: `${receiptData.paymode}\n` }] },
//             { width: "67%", text: [{ text: 'Transaction Id: ', bold: true }, { text: `${receiptData.taxid}\n` }] },
//           ],
//         },
//         {
//           style: "NameMargin",
//           columns: [
//             { width: "33%", text: [{ text: 'Block No: ', bold: true }, { text: `${receiptData.block_id}\n` }] },
//             { width: "33%", text: [{ text: 'Room No: ', bold: true }, { text: `${receiptData.room_id}\n` }] },
//             { width: "34%", text: [{ text: 'Bed No: ', bold: true }, { text: `${receiptData.bed_id}\n` }] },
//           ],
//         },
//         {
//           text: "",
//         },
//         // Add fee details to the PDF
      

//         {
//           style: "NameMargin",
//           columns: [
//             { width: "15%", text: [{ text: ''}] },
//             { width: "70%",
//               style: "tableExample",
//               table: {
//                 widths: ["12%", "70%", "18%"],
//                 body: tableBody
//               }
//             },
//             { width: "15%", text: [{ text: ''}] },
//           ],
//         },
//       ],
//       footer: function(currentPage, pageCount) {
//         return {
//           text: `Design & Developed By TechnoAarv Solution`,
//           alignment: 'center',
//           margin: [0, 0, 0, 10]
//         };
//       },
//       styles: {
//         lineheight: {
//           lineHeight: 1.5,
//           fontSize: 12,
//         },
//         header1: {
//           fontSize: 15,
//           alignment: "center",
//         },
//         header: {
//           fontSize: 18,
//           alignment: "center",
//           bold: true,
//         },
//         header2: {
//           fontSize: 13,
//           alignment: "center",
//         },
//         header3: {
//           fontSize: 9,
//           alignment: "center",
//         },
//         logo: {
//           alignment: "center",
//         },
//         left: {
//           alignment: "right",
//           lineHeight: 1.5,
//           fontSize: 12,
//         },
//         subheader: {
//           fontSize: 15,
//           lineHeight: 1.5,
//           bold: true,
//         },
//         start1: {
//           alignment: "left",
//           bold: true,
//           color: 'blue'
//         },
//         receiptmargin: {
//           margin: [0, 25, 0, 0],
//         },
//         NameMargin: {
//           margin: [0, 7, 0, 0],
//         },
//         description: {
//           margin: [19, 0, 0, 0],
//         },
//         amount : {
//           margin: [0, 0, 5, 0],
//         },
//         tableExample: {
//           lineHeight: 1.5,
//           alignment:'center',
//           margin: [0, 40, 0, 0],
//         }
//       },
//     };
  
//     // pdfMake.createPdf(dd).download("receipt.pdf");
//     // setLoading(false);
//     pdfMake.createPdf(dd).open(); // Open the PDF in a new tab
//     setLoading(false); 
//   };

//   const handlePay = () =>{
//     navigate('/InstallmentPay')
//   }
//   return (
//     <>
//       <Sidebar />
//       <section className="home-section">
//         <Header />
     

//         <div className="bed-booking px-md-5 px-2 mt-md-5 mt-3 pb-5">
        
//           <Row style={{display:"flex"}}>
//           <Col style={{display:"flex",justifyContent:"center",borderInlineEnd:"2px solid gray"}}>
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             receiptData && feeDetails && (
//               <>
//               <div>
//               <h2 className="main-title1">Amount Paid</h2>
//                 <Box
//                   sx={{
//                     p: 2,
//                     textAlign: 'center',
//                   }}
//                 >
             
//                   <Button
//                     variant="contained"
//                     onClick={handleDownload}
//                     color="primary"
//                     disabled={loading}
//       >
//         {loading ? <CircularProgress size={24} /> : 'Download Receipt'}
//                   </Button>
//                 </Box>
//                 </div>
//               </>
//             )
//           )}

// </Col>
// <Col  style={{display:"flex",justifyContent:"center"}}>
//         {data && data !== 'Already payment done' && (<>
//         <div>
//         {data.map((payment) => (<>
//         <h2 className="main-title1">Amount Pending</h2>
        
           
//               <p className="ps-3 " key={payment.block_id}>
//                 <strong>Rs:</strong> {payment.receive_amount} <br />
//                 <strong>Due Date:</strong> {payment.installment_date}
//               </p>
         
//           <div className="text-center">
//        <Button   variant="contained"
//                     color="primary" onClick={handlePay}>Pay</Button>
//         </div>
//         </>))}
//         </div>

//         </> )}
//       </Col>
 
//       </Row>
//       </div>
//       </section>
//     </>
//   );
// };

// export default DownloadReceipt;








import React, { useState, useEffect } from "react";
import Sidebar from "./sidebarstudent";
import Header from "./Headerstudent";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Col, Row } from "react-bootstrap";
import { ReceiptFeeDetailsApi, ReceiptIdApi, StudentDownloadReceiptApi, StudentInstallmentPayApi } from "../../ApiFile";
import logo from '../img/logo2.png';
import logo2 from '../img/logonew.jpg';
import { Box, Button, CircularProgress, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Assign the fonts to pdfMake
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Utility function to convert image to base64
const toDataURL = url =>
  fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }));

const DownloadReceipt = () => {
  const [receiptData, setReceiptData] = useState(null);
  const [feeDetails, setFeeDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [logoBase64, setLogoBase64] = useState(null);
  const [logo2Base64, setLogo2Base64] = useState(null);
  const [receiptOptions, setReceiptOptions] = useState([]);
  const [selectedReceiptId, setSelectedReceiptId] = useState(null);
  const year = sessionStorage.getItem("year");
  const memberId = sessionStorage.getItem('member_id');
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchReceiptOptions = async () => {
    try {
      const response = await fetch(`${ReceiptIdApi}/${memberId}`);
      const data = await response.json();
      setReceiptOptions(data);
      console.log("id...",data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching the receipt options:", error);
      setLoading(false);
    }
  };

  const fetchReceiptData = async () => {
    if (!selectedReceiptId) return;

    const requestBody = { id: selectedReceiptId, member_id: memberId };
    console.log("Fetch Receipt Data Request Body:", requestBody); // Log the request body

    try {
      const response = await fetch(StudentDownloadReceiptApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      console.log("Receipt Data Response:", data); // Log the response data

      if (data.includes("Admin Payment Approval Pending") || data.includes("Admin Payment Approval Reject")) {
        toast.error("Admin approval status: " + data);
      } else {
        setReceiptData(data[0]); // Assuming the response is an array with one item
      }
    } catch (error) {
      console.error("Error fetching the receipt data:", error);
    }
  };

  const fetchFeeDetails = async () => {
    if (!selectedReceiptId) return;

    const requestBody = { id: selectedReceiptId, member_id: memberId };
    // console.log("Fetch Fee Details Request Body:", requestBody); // Log the request body

    try {
      const response = await fetch(ReceiptFeeDetailsApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      // console.log("Fee Details Response:", data); 

      if (data === "Admin Payment Approval Pending") {
        toast.error("Admin approval status");
      } else if (data === "Admin Payment Approval Reject") {
        toast.error("Admin Payment Approval Reject");
      } else {
        setFeeDetails(data.feeDetails);
      }
    } catch (error) {
      // console.error("Error fetching the fee details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReceiptOptions();
  }, [memberId]);

  useEffect(() => {
    if (selectedReceiptId) {
      fetchReceiptData();
      fetchFeeDetails();
    }
  }, [selectedReceiptId]);

  useEffect(() => {
    // Convert images to base64
    Promise.all([toDataURL(logo), toDataURL(logo2)])
      .then(([logoBase64, logo2Base64]) => {
        setLogoBase64(logoBase64);
        setLogo2Base64(logo2Base64);
      });
  }, []);

  useEffect(() => {
    const fetchInstallmentData = async () => {
      setLoading(true);
      try {
        const response = await fetch(StudentInstallmentPayApi, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ member_id: memberId })
        });
        // console.log("memberid...", memberId);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();
        // console.log("ffff", jsonData);
        if (jsonData === 'Already payment done') {
          setData(null); // Reset data if NOTFOUND
        } else {
          setData(jsonData);
          // console.log("hsagdf..", jsonData)
        }
        setError(null);
      } catch (error) {
        // console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInstallmentData();
  }, [memberId]);

  const handleDownload = () => {
    if (!receiptData || !feeDetails || !logoBase64 || !logo2Base64) return;
    setLoading(true);

    // Calculate the total amount
    const totalAmount = Object.values(feeDetails).reduce((sum, fee) => sum + fee.amount, 0);

    const tableBody = [
      [
        { text: "S.No", bold: true, alignment: 'center' },
        { text: "Description", bold: true, alignment: 'center' },
        { text: "Amount", bold: true, alignment: 'center' }
      ]
    ];

    // Add fee details to the table
    Object.entries(feeDetails).forEach(([key, value], index) => {
      tableBody.push([
        { text: index + 1, alignment: 'center' },
        { text: key, alignment: 'left', style: 'description' },
        { text: value.amount, alignment: 'right', style: 'amount' }
      ]);
    });

    // Add the total amount row
    tableBody.push([
      {},
      { text: "Total =", bold: true, alignment: 'center' },
      { text: totalAmount, bold: true, alignment: 'right', style: 'amount' }
    ]);

    const dd = {
      content: [
        {
          columns: [
            { style: "logo", width: "15%", image: logoBase64, fit: [50, 50] },
            {
              width: "70%",
              text: [
                { text: "Dakshin Bharat Jain Sabha's \n", style: "header1" },
                { text: "Sheth R D Dawada Digambar Jain Boarding,\n", style: "header" },
                { text: "37, Mahavir Nagar, Sangli 416416, Phone: 0233-262306\n", style: "header2" },
                { text: "Web : www.jainboardingsangli.com  Email : jainboardingsangli@gmail.com \n", style: "header3" },
              ],
            },
            { style: "logo", width: "15%", image: logo2Base64, fit: [50, 50] },
          ],
        },
        {
          margin: [0, 15, 0, 0],
          canvas: [
            {
              type: 'line',
              x1: 0, y1: 0,
              x2: 515, y2: 0,
              lineWidth: 1
            }
          ],
        },
        {
          margin: [0, 10, 0, 0],
          columns: [
            {
              style: "paymetnReceipt",
              width: "100%",
              text: [
                { text: 'Payment Receipt ', bold: true },
              ],
            },
          ],
        },
        {
          style: "receiptmargin",
          columns: [
            {
              style: "receipt",
              width: "33%",
              text: [
                { text: 'Receipt No: ', bold: true },
                { text: `${receiptData.receiptNo}\n` }
              ],
            },
            {
              style: "receipt",
              width: "33%",
              text: [
                { text: 'Academic Year: ', bold: true },
                { text: year }
              ],
            },
            {
              style: "recdate",
              width: "34%",
              text: [
                { text: 'Receipt Date: ', bold: true },
                { text: `${receiptData.receipt_date}\n` }
              ],
            },
          ],
        },
        {
          columns: [
            {
              width: "66%",
              text: [
                { text: 'Name: ', bold: true },
                { text: `${receiptData.memberName}\n` }
              ],
              style: "NameMargin"
            },
            {
              width: "34%",
              text: [{ text: 'Payment Date: ', bold: true }, { text: `${receiptData.payment_date}\n` }],
              style: "NameMargin"
            },
          ],
        },
        {
          text: [
            { text: 'Address: ', bold: true },
            { text: `${receiptData.address} , ${receiptData.village} ,` },
            { text: ' Taluka: ', bold: true },
            { text: `${receiptData.Taluka} , ` },
            { text: ' District: ', bold: true },
            { text: `${receiptData.District} , ` },
            { text: ' State: ', bold: true },
            { text: `${receiptData.State} , ` },
            { text: ' Pin-Code: ', bold: true },
            { text: `${receiptData.pincode} . ` }
          ],
          style: "NameMargin"
        },
        {
          style: "NameMargin",
          columns: [
            { width: "33%", text: [{ text: 'Term: ', bold: true }, { text: `${receiptData.term}\n` }] },
            { width: "33%", text: [{ text: 'Mobile No: ', bold: true }, { text: `${receiptData.mobileno}\n` }] },
            { width: "34%", text: [{ text: 'Parent\'s Mob No: ', bold: true }, { text: `${receiptData.parents_mobno}\n` }] },
          ],
        },
        {
          style: "NameMargin",
          columns: [
            { width: "33%", text: [{ text: 'Payment Mode: ', bold: true }, { text: `${receiptData.paymode}\n` }] },
            { width: "67%", text: [{ text: 'Transaction Id: ', bold: true }, { text: `${receiptData.taxid}\n` }] },
          ],
        },
        {
          style: "NameMargin",
          columns: [
            { width: "33%", text: [{ text: 'Block No: ', bold: true }, { text: `${receiptData.block_id}\n` }] },
            { width: "33%", text: [{ text: 'Room No: ', bold: true }, { text: `${receiptData.room_id}\n` }] },
            { width: "34%", text: [{ text: 'Bed No: ', bold: true }, { text: `${receiptData.bed_id}\n` }] },
          ],
        },
        {
          text: "",
        },
        // Add fee details to the PDF
        {
          style: "NameMargin",
          columns: [
            { width: "15%", text: [{ text: ''}] },
            { width: "70%",
              style: "tableExample",
              table: {
                widths: ["12%", "70%", "18%"],
                body: tableBody
              }
            },
            { width: "15%", text: [{ text: ''}] },
          ],
        },
      ],
      footer: function(currentPage, pageCount) {
        return {
          text: `Design & Developed By TechnoAarv Solution`,
          alignment: 'center',
          margin: [0, 0, 0, 10]
        };
      },
      styles: {
        lineheight: {
          lineHeight: 1.5,
          fontSize: 12,
        },
        header1: {
          fontSize: 15,
          alignment: "center",
        },
        header: {
          fontSize: 18,
          alignment: "center",
          bold: true,
        },
        header2: {
          fontSize: 13,
          alignment: "center",
        },
        header3: {
          fontSize: 9,
          alignment: "center",
        },
        logo: {
          alignment: "center",
        },
        left: {
          alignment: "right",
          lineHeight: 1.5,
          fontSize: 12,
        },
        paymetnReceipt: {
          fontSize: 18,
          paddingTop:10,
          alignment: "center",
          bold: true,
        },
        subheader: {
          fontSize: 15,
          lineHeight: 1.5,
          bold: true,
        },
        start1: {
          alignment: "left",
          bold: true,
          color: 'blue'
        },
        receiptmargin: {
          margin: [0, 25, 0, 0],
        },
        NameMargin: {
          margin: [0, 7, 0, 0],
        },
        description: {
          margin: [19, 0, 0, 0],
        },
        amount: {
          margin: [0, 0, 5, 0],
        },
        tableExample: {
          lineHeight: 1.5,
          alignment: 'center',
          margin: [0, 40, 0, 0],
        }
      },
    };

    // pdfMake.createPdf(dd).download("receipt.pdf");
    // setLoading(false);
    pdfMake.createPdf(dd).open(); // Open the PDF in a new tab
    setLoading(false);
  };

  const handlePay = () => {
    navigate('/InstallmentPay')
  }

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <Header />

        <div className="bed-booking px-md-5 px-2 mt-md-5 mt-3 pb-5">
          <Row style={{ display: "flex" }}>
            <Col style={{ display: "flex", justifyContent: "center", borderInlineEnd: "2px solid gray" }}>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <>
                  <div>
                    <h2 className="main-title1 text-start">Select Receipt</h2>
                    <FormControl fullWidth>
                      <InputLabel>Select Receipt</InputLabel>
                      <Select
                        value={selectedReceiptId || ''}
                        onChange={(e) => setSelectedReceiptId(e.target.value)}
                        label="Select Receipt"
                        // size="small"
                      >
                        {receiptOptions.map((option, index) => (
                          <MenuItem key={option.id} value={option.id}>
                          Receipt No: {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* <h2 className="main-title1">Amount Paid</h2> */}
                    <Box
                      sx={{
                        pt: 3,
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleDownload}
                        color="primary"
                        disabled={loading || !selectedReceiptId}
                      >
                        {loading ? <CircularProgress size={24} /> : 'Download Receipt'}
                      </Button>
                    </Box>
                  </div>
                </>
              )}
            </Col>
            <Col style={{ display: "flex", justifyContent: "center" }}>
  {data && data !== 'Already payment done' && (
    <>
      <div>
        {data.map((payment) => (
          <div key={payment.block_id}>
            <h2 className="main-title1">Amount Pending</h2>
            <p className="ps-3">
              <strong>Rs:</strong> {payment.receive_amount} <br />
              <strong>Due Date:</strong> {payment.installment_date}
            </p>

            {/* Log the receive_amount value for debugging */}
            {console.log("Receive Amount: ", payment.receive_amount)}

            {/* Only show the button if receive_amount is greater than 0 */}
            {Number(payment.receive_amount) === 0 ? (
              <p>Payment is fully done.</p> // If receive_amount is 0, show this message
            ) : (
              <div className="text-center">
                <Button variant="contained" color="primary" onClick={handlePay}>
                  Pay
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )}
</Col>

          </Row>
        </div>
      </section>
    </>
  );
};

export default DownloadReceipt;
