import React, { useEffect, useState } from 'react';
import './SidebarStudent.css'
import {  useLocation, useNavigate } from 'react-router-dom';
import { Accordion, useAccordionButton } from "react-bootstrap";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(null);
  const location = useLocation();

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setActiveKey(activeKey === eventKey ? null : eventKey);
    });

    return (
      <span
        onClick={decoratedOnClick}
        style={{
          color: "#495494",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
      </span>
    );
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  
  const handleReturnStockClick = () => {
    setShowDropdown(!showDropdown); // Toggle the dropdown
  };

  const selectedLanguage = sessionStorage.getItem('selectedLanguage');
  
  useEffect(() => {
        let sessionData =  sessionStorage.getItem('member_id');
    if(!sessionData){
      navigate('/')
    }
  }, [])
   
  useEffect(() => {

    //---------------------------------Disabled back button-------------------------------
    window.history.pushState(null, null, window.location.href);

    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.forward();
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [ ]);

  useEffect(() => {
     if(location.pathname.startsWith("/RegularLeaveApplication") || location.pathname.startsWith("/MessLeaveApplication") || location.pathname.startsWith("/EventLeaveApplication")){
      setActiveKey("0");
    }
     if(location.pathname.startsWith("/Eventstudent") || location.pathname.startsWith("/MyAttendance")){
      setActiveKey("1");
    }
     if(location.pathname.startsWith("/StudentTodaysMenu") || location.pathname.startsWith("/BreakFastReq") || location.pathname.startsWith("/MessAttendance") || location.pathname.startsWith("/MessFeedback")){
      setActiveKey("2");
    }
  }, [location]);
  
  return (
    
    <div className={`sidebar ${isOpen ? 'open' : ''}`}  style={{display:"flex",flexDirection:"column",flex:"1",overflow:"scroll"}}>
    <div className="logo-details mt-4" >
      <i className='bx bxs-landscape'></i>
      <div className="logo_name" style={{marginLeft: "-32px",color: "maroon", textShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",fontSize: "24px", fontWeight: "bold", letterSpacing: "1px", fontFamily: "Arial, sans-serif"}}>WEL- COME
</div>
      <i className={`fa ${isOpen ? 'fa-solid fa-bars' : 'fa-solid fa-bars'}`} id="btn" onClick={toggleSidebar}></i>
    </div>
    <div className="sidebar-content">
    <ul className="nav-list mb-5" >
      <li >
      <a 
       href="/#/Homestudent" 
       className="active ">
              <i className="fa fa-line-chart"></i>
              <span className="links_name">Dashboard</span>
            </a>
      </li>
      <li >
      <a href="/#/DownloadReceipt" className="active ">
          <i class="fa-solid fa-receipt"></i>
              <span className="links_name">Payments</span>
            </a>
      </li>
      
      <li >
      <a href="/#/complainstudent" className="active ">
      <i class="fa-solid fa-file-lines"></i>
              <span className="links_name">Complaints</span>
            </a>
      </li>
  

      <Accordion activeKey={activeKey}>
            <li>
              <CustomToggle eventKey="0">
              <a>
              <i class="fa-solid fa-file"></i>
             
                <span className="links_name" style={{ color: "black" }}>
               Leave Application
                </span>

                <span
                  className="arrow-icon"
                  style={{ marginLeft: "auto", color: "black" }}
                >
                  <>&#9660;</>
                </span>
                </a>
              </CustomToggle>
              <Accordion.Collapse eventKey="0">
                <ul className="dropdown-list " style={{ listStyle: "none" }}>
                  <li>
                    <a
                    className="ms-2 me-2 text-start"
                      href="/#/RegularLeaveApplication"
                      // style={{
                      //   textDecoration: "none",
                      //   backgroundColor: "transparent",
                      // }}
                    >
                    <i class="fa-solid fa-newspaper"></i>
                      <span className="links_name">Regular Leave</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ms-2 me-2 text-start"
                      href="/#/MessLeaveApplication"
                      // style={{
                      //   textDecoration: "none",
                      //   backgroundColor: "transparent",
                      // }}
                    >
            <i class="fa-solid fa-bowl-food"></i>
                      <span className="links_name">Meals Leave</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ms-2 me-2 text-start"
                      href="/#/EventLeaveApplication"
                      // style={{
                      //   textDecoration: "none",
                      //   backgroundColor: "transparent",
                      // }}
                    >
             <i class="fa-solid fa-pager"></i>
                      <span className="links_name">Event Leave</span>
                    </a>
                  </li>
                </ul>
              </Accordion.Collapse>
            </li>
          </Accordion>


      <Accordion activeKey={activeKey}>
            <li>
              <CustomToggle eventKey="1">
              <a>
              <i class="fa-solid fa-calendar-days"></i>
             
                <span className="links_name" style={{ color: "black" }}>
               Event
                </span>

                <span
                  className="arrow-icon"
                  style={{ marginLeft: "auto", color: "black" }}
                >
                  <>&#9660;</>
                </span>
                </a>
              </CustomToggle>
              <Accordion.Collapse eventKey="1">
                <ul className="dropdown-list " style={{ listStyle: "none" }}>
                  <li>
                    <a
                    className="ms-2 me-2 text-start"
                      href="/#/Eventstudent"
                      // style={{
                      //   textDecoration: "none",
                      //   backgroundColor: "transparent",
                      // }}
                    >
                 <i class="fa-solid fa-calendar"></i>
                      <span className="links_name">Event Calender</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ms-2 me-2 text-start"
                      href="/#/MyAttendance"
                      // style={{
                      //   textDecoration: "none",
                      //   backgroundColor: "transparent",
                      // }}
                    >
        <i class="fa-solid fa-clipboard-user"></i>
                      <span className="links_name">My Attendance</span>
                    </a>
                  </li>
                </ul>
              </Accordion.Collapse>
            </li>
          </Accordion>

          <Accordion activeKey={activeKey}>
            <li>
              <CustomToggle eventKey="2">
              <a>
              <i class="fa-solid fa-bowl-rice"></i>
             
                <span className="links_name" style={{ color: "black" }}>
             Mess
                </span>

                <span
                  className="arrow-icon"
                  style={{ marginLeft: "auto", color: "black" }}
                >
                  <>&#9660;</>
                </span>
                </a>
              </CustomToggle>
              <Accordion.Collapse eventKey="2">
                <ul className="dropdown-list " style={{ listStyle: "none" }}>
                  <li>
                    <a
                    className="ms-2 me-2 text-start"
                      href="/#/StudentTodaysMenu"
                      // style={{
                      //   textDecoration: "none",
                      //   backgroundColor: "transparent",
                      // }}
                    >
                   <i class="fa-solid fa-plate-wheat"></i>
                      <span className="links_name">Todays Menu</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ms-2 me-2 text-start"
                      href="/#/BreakFastReq"
                      // style={{
                      //   textDecoration: "none",
                      //   backgroundColor: "transparent",
                      // }}
                    >
         <i class="fa-solid fa-hot-tub-person"></i>
                      <span className="links_name">Breakfast Request</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ms-2 me-2 text-start"
                      href="/#/MessAttendance"
                      // style={{
                      //   textDecoration: "none",
                      //   backgroundColor: "transparent",
                      // }}
                    >
            <i class="fa-solid fa-bowl-food"></i>
                      <span className="links_name">Mess Attendance</span>
                    </a>
                  </li>
                
                  <li>
                    <a
                     className="ms-2 me-2 text-start"
                      href="/#/MessFeedback"
                      // style={{
                      //   textDecoration: "none",
                      //   backgroundColor: "transparent",
                      // }}
                    >
             <i class="fa-solid fa-pager"></i>
                      <span className="links_name">Feedback</span>
                    </a>
                  </li>
                </ul>
              </Accordion.Collapse>
            </li>
          </Accordion>



      <li >
      <a 
      href="/#/Jobsstudent"
      >
              <i class="fa-solid fa-folder-plus"></i>
              <span className="links_name">Job</span>
            </a>
      </li>

      <li>
            <a href="/#/SuggestionStudent">
              <i class="fa-solid fa-file-circle-plus"></i>
              <span className="links_name">Suggestion Form</span>
            </a>
          </li>

      <li >
      <a 
      href="/#/StudentDischarge"
      >
           <i class="fa-solid fa-eject"></i>
              <span className="links_name">Discharge</span>
            </a>
      </li>


    


    </ul>
    </div>
  </div>
  
);
};


export default Sidebar;
