
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Visibility, Delete } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { MDBDataTable } from 'mdbreact';

import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { ComplaintDeleteApi, ComplaintFindAllApi, ComplaintFindOneApi, ComplaintUpdateApi, ImagePath } from '../../ApiFile';
import { Col, Row } from 'react-bootstrap';

const statusFlags = {
  'Pending': 0,
 
  'Within 1 day': 2,
  'Within 2 days': 3,
  'Within 4 days': 4,
  'Within 1 week': 5,
  'Within 1 Month': 1,
  'Completed': 6,
  // 'InProcess': 7,
  'Rejected': 8,
 'Recomplaint': 9,

};

const flagStatuses = {
  0: 'Pending',
  1: 'Within 1 Month',
  2: 'Within 1 day',
  3: 'Within 2 days',
  4: 'Within 4 days',
  5: 'Within 1 week',
  6: 'Completed',
  7: 'InProcess',
  8: 'Rejected',
  9: 'Recomplaint'
};


function Complaint() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    axios.get(ComplaintFindAllApi)
      .then(response => {
        const mappedRows = response.data.map(row => ({
          ...row,
          status: flagStatuses[row.status], // Map the status flag to the status text
        }));
        setRows(mappedRows);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleViewClick = (row) => {
    axios.get(`${ComplaintFindOneApi}/${row.id}`)
      .then(response => {
        console.log("findone res..",response.data);
        setSelectedRow(response.data[0] || {});
        setOpen(true);
      })
      .catch(error => {
        console.error(error);
        toast.error('Failed to fetch data');
      });
  };

  const handleUpdateStatus = (id, newStatus) => {
    const flag = statusFlags[newStatus];
    axios.put(`${ComplaintUpdateApi}/${id}`, { status: flag })
      .then(response => {
        setRows(rows.map(row => row.id === id ? { ...row, status: newStatus } : row));
        toast.success('Status updated successfully');
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleDeleteClick = (id) => {
    axios.delete(`${ComplaintDeleteApi}/${id}`)
      .then(response => {
        if (response.data === 'DELETED') {
          setRows(rows.filter(row => row.id !== id));
          toast.success('Complaint deleted successfully');
        } else {
          toast.error('Complaint not found');
        }
      })
      .catch(error => {
        console.error(error);
        toast.error('Failed to delete complaint');
      });
  };

  const data = {
    columns: [
      { label: 'Serial No', field: 'serial' },
      { label: 'Student Name', field: 'name' },
      { label: 'Compliant Date', field: 'compliant_date' },
      { label: 'About', field: 'about' },
      {
        label: 'View',
        field: 'view',
        sort: 'disabled',
        renderCell: (row) => (
          <Button variant="contained" color="primary" onClick={() => handleViewClick(row)}>
            <Visibility fontSize="small" />
          </Button>
        ),
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        renderCell: (row) => (
          <Select
            value={row.status}
            onChange={(e) => handleUpdateStatus(row.id, e.target.value)}
            displayEmpty
            size='small'
            className='w-100'
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {/* <MenuItem value="">
              <em>Select Status</em>
            </MenuItem> */}
            {Object.entries(statusFlags).map(([key, value]) => (
              <MenuItem key={value} value={key}>{key}</MenuItem>
            ))}
          </Select>
        ),
      },
      // {
      //   label: 'Delete',
      //   field: 'delete',
      //   sort: 'disabled',
      //   renderCell: (row) => (
      //     <Button variant="contained" color="secondary" onClick={() => handleDeleteClick(row.id)}>
      //       <Delete fontSize="small" />
      //     </Button>
      //   ),
      // },
    ],
    rows: rows.map((row, index) => ({
      serial: index + 1,
      ...row,
      view: (
        <Button variant="contained" color="primary" onClick={() => handleViewClick(row)}>
          <Visibility fontSize="small" />
        </Button>
      ),
      status: (
        <Select
          value={row.status}
          onChange={(e) => handleUpdateStatus(row.id, e.target.value)}
          displayEmpty
          className='w-100'
          size='small'
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">
            <em>Select Status</em>
          </MenuItem>
          {Object.entries(statusFlags).map(([key, value]) => (
            <MenuItem key={value} value={key}>{key}</MenuItem>
          ))}
        </Select>
      ),
      // delete: (
      //   <Button variant="contained" color="secondary" onClick={() => handleDeleteClick(row.id)}>
      //     <Delete fontSize="small" />
      //   </Button>
      // ),
    })),
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />

        <Row>
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Complaints
          </h2>
        </Row>

        <Row className="m-0 pt-1 px-2 px-md-3 mb-5 mt-3 rounded">
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable
                  style={{ backgroundColor: 'white' }}
                  striped
                  className="custom-datatable"
                  bordered
                  small
                  data={data}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="status-modal-title"
          aria-describedby="status-modal-description"
          sx={{
            display: 'flex',
            overflow: 'scroll',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '700px',
              maxHeight: '90vh',
              overflow: "scroll",
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ p: 3, borderTop: '1px solid #ccc' }}>
              <Row>
                <div className="block-header">
                  <h2 className="mb-2  mt-1 " style={{ color: 'maroon', borderBottom: "2px solid maroon" }}>
                    Complaint Details
                  </h2>
                </div>

                <Box sx={{ overflowY: 'auto', p: 4, flex: 1 }}>
                  <Typography id="modal-modal-description" style={{ lineHeight: "18px" }}>
                    <Row>
                      <Col> <b>Name</b>:</Col>
                      <Col>  {selectedRow?.name} </Col>
                    </Row>  <br />
                    <Row>
              <Col> <b>Mobile No.</b>:</Col>
              <Col> {selectedRow?.mob_no} </Col>
            </Row>  <br />
            <Row>
              <Col> <b>Block No.</b>:</Col>
              <Col> {selectedRow?.block_id} </Col>
            </Row>  <br />
            <Row>
              <Col> <b>Room No.</b>:</Col>
              <Col> {selectedRow?.room_id} </Col>
            </Row>
                    <br />
                    <Row>
                      <Col ><b>Complaint Date</b>:</Col>
                      <Col > {selectedRow?.comapliant_date}</Col>
                    </Row>
                    <br />
                    <Row>
                      <Col ><b>Complaint Time</b>:</Col>
                      <Col > {selectedRow?.comapliant_time}</Col>
                    </Row>
                    <br />
                    <Row>
                      <Col ><b>About</b>:</Col>
                      <Col > {selectedRow?.about}</Col>
                    </Row>
                    <br />
                    <Row>
                      <Col ><b>Details</b>:</Col>
                      <Col > {selectedRow?.details}</Col>
                    </Row>
                    <br />
                        <Row>
                          <Col><b>Update Reason</b>:</Col>
                          <Col>{selectedRow?.update_reason}</Col>
                        </Row>
                        <br />
                    <Row style={{ display: "flex" }}>
                      <Col ><b>Uploaded Image</b>: </Col>
                      <Col className="mt-3 text-center" md={6} xs={12}>
                        <img src={`${ImagePath}/${selectedRow?.upload_img}`} className='acceptable rounded shadow border-0 ' alt='' style={{ height: "16rem", width: "15rem", border: "1px solid grey" }} />
                      </Col>
                    </Row>
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 4, position: 'absolute', bottom: '16px', right: '16px' }}
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </Button>
                </Box>
              </Row>
            </Box>
          </Box>
        </Modal>

        <ToastContainer />
      </section>
    </>
  );
}

export default Complaint;

