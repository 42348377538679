import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { Col, Row } from 'react-bootstrap';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import { AdminToDoAdd, AdminToDoFindAll } from '../../ApiFile';
import { toast } from 'react-toastify';

const ToDoMainAdmin = () => {
  const [date, setDate] = useState(null);
  const [description, setDescription] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [todoData, setTodoData] = useState([]);  // State to store fetched data


    // Fetch data from the API when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(AdminToDoFindAll);
        setTodoData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    useEffect(() => {

    fetchData();
  }, []);

  const handleCheckboxChange = (index) => {
    if (index === 1) {
      setChecked1(!checked1);
    } else if (index === 2) {
      setChecked2(!checked2);
    } else if (index === 3) {
      setChecked3(!checked3);
    }
  };

  const handleSend = async () => {
    const sendToArray = [];
    if (checked1) sendToArray.push({ sendto: 2 });  // Superintendent
    if (checked2) sendToArray.push({ sendto: 3 });  // Office Attendant
    if (checked3) sendToArray.push({ sendto: 4 });  // Mess Attendant

    const formattedDate = date ? dayjs(date).format('DD/MM/YYYY') : null;

    const requestBody = {
      description,
      completed_by_date: formattedDate,
      createdby: createdBy,
      sendto: sendToArray,
    };

    try {
      const response = await axios.post(AdminToDoAdd, requestBody);
      setCreatedBy('');
      setChecked1('');
      setChecked2('');
      setChecked3('');
      setDescription('');
      setDate(null);
      toast.success('Task Sent Successfully');
      fetchData();
      console.log('Task added successfully:', response.data);
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  // Define the columns for MDBDataTable
  const columns = [
    { label: 'Sr. No', field: 'sr_no', sort: 'asc' },
  
    { label: 'Completed By Date', field: 'completed_by_date', sort: 'asc' },
    { label: 'Created By', field: 'createdby', sort: 'asc' },
    { label: 'Send To', field: 'sendto', sort: 'asc' },
    { label: 'Description', field: 'description', sort: 'asc' },
  ];

  // Prepare data for MDBDataTable
  const data = {
    columns,
    rows: todoData.map((item,index) => ({
      sr_no: index+1,

      completed_by_date: item.completed_by_date,
      createdby: item.createdby,
      sendto: item.sendto,
      description: item.description,
    })),
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />

        <Row className="m-0 pt-2 rounded">
          <Col className="m-0 p-0 px-3 py-1 text-center">
            <h4 style={{ color: "maroon" }}>To Do</h4>
          </Col>
        </Row>

        <Row className="m-0 p-0 py-1 px-md-5 px-2 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
            <Row>
              <Col sm={12} md={12} className="mt-3 mt-md-0 pt-md-0">
                <label>Description</label>
                <TextArea
                  label="Text Here"
                  variant="outlined"
                  rows={3}
                  fullWidth
                  size="small"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>

              <Col sm={12} md={6} className="mt-3 mt-md-2 pt-md-0">
                <label>Completed By</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      label="Select Date"
                      className="w-100"
                      value={date}
                      style={{ width: "100%" }}
                      onChange={(newDate) => setDate(newDate)}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Col>

              <Col sm={12} md={6} className="mt-3 mt-md-3 pt-md-0">
                <label>Created By</label>
                <TextField
                  label="Name Surname"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={createdBy}
                  onChange={(e) => setCreatedBy(e.target.value)}
                />
              </Col>

              <Col sm={12} className="mt-3">
                <label>Send To</label><br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked1}
                      onChange={() => handleCheckboxChange(1)}
                    />
                  }
                  label="Superintendent"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked2}
                      onChange={() => handleCheckboxChange(2)}
                    />
                  }
                  label="Office Attendant"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked3}
                      onChange={() => handleCheckboxChange(3)}
                    />
                  }
                  label="Mess Attendant"
                />
              </Col>

              <Col sm={12} md={12} className="mt-2 mt-md-3 pt-md-0">
                <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    style={{ backgroundColor: 'rgb(0, 120, 215)', color: 'white' }}
                    onClick={handleSend}
                  >
                    SEND
                  </Button>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* MDBDataTable to display fetched ToDo data */}
        <Row className="m-0 p-0 py-1 px-md-5 px-2 w-100">
          <Col className="m-0 p-0 px-2 py-3 rounded" style={{ background: 'white' }}>
            <MDBDataTable
              striped
              bordered
              className="custom-datatable"
              hover
              data={data}
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default ToDoMainAdmin;
