import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios'; // Add axios for API calls
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AllocateFindAllApi } from '../../ApiFile';


const RoomBookingTable = () => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [],
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get(AllocateFindAllApi)
     .then(response => {
        setRows(response.data);
      })
     .catch(error => {
        // console.error(error);
      });
  };

  const handleView = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'block_id', headerName: 'Block', width: 100 },
    { field: 'room_id', headerName: 'Room no', width: 100 },
    { field: 'bed_id', headerName: 'Bed no', width: 100 },
    { field: 'date', headerName: 'Date', width: 150 },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" color="primary" onClick={() => handleView(params.row)}>View</Button>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '86vh',
          margin: '0 auto',
          padding: '20px',
          maxWidth: '1200px',
          width: '100%',
          backgroundColor: 'light grey',
        }}
      >
        <DataGrid
          columns={columns}
          rows={rows}
          disableColumnResize
          disableDensitySelector
          disableColumnHeaderSorting
          disableColumnFilter
          slots={{ toolbar: GridToolbar }}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => setFilterModel(newModel)}
          slotProps={{ toolbar: { showQuickFilter: true } }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          className="custom-data-grid"
        />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '500px',
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="div" sx={{ mb: 2 }}>
            {selectedRow?.name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {Object.entries(selectedRow || {}).map(([key, value]) => (
              <div key={key}>
                <strong>{key.replace(/_/g, ' ').replace(/\w\w/g, (c) => c.toUpperCase())}:</strong> {value}
                <br />
              </div>
            ))}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2, position: 'absolute', bottom: '16px', right: '16px' }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default RoomBookingTable;
