import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const FooterAll = () => {
  return (
//     <div style={{ position: 'absolute', bottom: 0, width: '100%', backgroundColor: '#f8f9fa' }}>
//     <Row className='py-1 m-0 p-0 w-100' style={{ display: "flex", justifyContent:"space-between"}}>
//       <Col sm={12} md={6} className="text-center">
//         2024 Sheth R. D. Davada Digambar Jain Boarding, Sangli. All Rights Reserved.
//       </Col>
//       <Col sm={12} md={6} className="text-center">
//         2024 Jain Boarding | Managed By : TechnoAarv Solution
//       </Col>
//     </Row>
//   </div>


<div className="footer9">
<Row className='py-1 m-0 p-0 w-100' style={{ display: "flex", justifyContent:"center"}}>
  <Col className="text-center">
    2024 Jain Boarding | Managed By: TechnoAarv Solution
  </Col>
</Row>
</div>
  )
}

export default FooterAll
