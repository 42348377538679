// import studentImage from "../img/immigration_3125856.png";
// import calenderImage from "../img/calendar_8922329.png";
// import profile from "../img/profile_9479047.png";
// import check from "../img/check_3468503.png";
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { Container } from "react-bootstrap";
// import { Pie } from "react-chartjs-2";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import Sidebar from "./sidebarstudent";
// import Header from "./Headerstudent";

// function Home() {
//   const [events, setEvents] = useState([]);
//   const [news, setNews] = useState([]);
//   const [isPaused, setIsPaused] = useState(false);
//   const scrollRef = useRef(null);
//   const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
//   const [activeCard, setActiveCard] = useState("card1"); // Set card1 as the default active card
//   ChartJS.register(ArcElement, Tooltip, Legend);

//   const data = {
//     labels: ["Present", "Absent", "Late"],
//     datasets: [
//       {
//         data: [65, 25, 10],
//         backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
//         hoverBackgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//   };
//   const OpenSidebar = () => {
//     setOpenSidebarToggle(!openSidebarToggle);
//   };

//   useEffect(() => {
//     const fetchNews = async () => {
//       try {
//         const response = await axios.get(
//           "http://192.168.29.112:8081/api/event/find"
//         );
//         const fetchedNews = response.data.map((event) => ({
//           id: event.event_id,
//           title: `Event ${event.event_id}`,
//           content: event.discription,
//           date: event.event_date,
//         }));
//         setNews(fetchedNews);
//       } catch (error) {
//         console.error("Error fetching news:", error);
//       }
//     };

//     fetchNews();
//   }, []);

//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//         const response = await fetch(
//           "http://192.168.29.112:8081/api/event/find"
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch events");
//         }
//         const eventData = await response.json();
//         setEvents(eventData);
//       } catch (error) {
//         console.error("Error fetching events:", error);
//       }
//     };

//     fetchEvents();
//   }, []);

//   const handleCardClick = (card) => {
//     setActiveCard(card);
//   };

//   return (<>
//        <Sidebar />
//       <section className="home-section">
//         <Header />
//       <div className="main1-container">
//         <div
//           className="main1-cards-row"
//           style={{ display: "flex", marginLeft: "-20px", marginBottom: "20px" }}
//         >
//           {/* New Cards */}
//           <div
//             className="card1"
//             style={{
//               backgroundColor: "#2962ff",
//               width: "210px",
//               height: "100px",
//               marginRight: "20px",
//             }}
//             onClick={() => handleCardClick("card1")}
//           >
//             <div className="card1-inner">
//               <h2>Profile </h2>
//               <img
//                 src={profile}
//                 alt="Student"
//                 className="card1-icon"
//                 style={{ width: "50px", height: "50px", marginLeft: "50px" }}
//               />
//             </div>
//           </div>

//           <div
//             className="card1"
//             style={{
//               backgroundColor: "#ff6d00",
//               width: "210px",
//               height: "100px",
//               marginRight: "20px",
//             }}
//             onClick={() => handleCardClick("card2")}
//           >
//             <div className="card1-inner">
//               <h2>Attendance</h2>{" "}
//               <img
//                 src={studentImage}
//                 alt="Student"
//                 className="card1-icon"
//                 style={{ width: "50px", height: "50px", marginLeft: "30px" }}
//               />
//             </div>
//           </div>

//           <div
//             className="card1"
//             style={{
//               backgroundColor: "#2e7d32",
//               width: "210px",
//               height: "100px",
//               marginRight: "20px",
//             }}
//             onClick={() => handleCardClick("card3")}
//           >
//             <div className="card1-inner">
//               <h2>Complaint Status</h2>
//               <img
//                 src={check}
//                 alt="Student"
//                 className="card1-icon"
//                 style={{ width: "50px", height: "50px" }}
//               />
//             </div>
//           </div>

//           <div
//             className="card1"
//             style={{
//               backgroundColor: "#d50000",
//               width: "210px",
//               height: "100px",
//             }}
//             onClick={() => handleCardClick("card4")}
//           >
//             <div className="card1-inner">
//               <h2>Events</h2>{" "}
//               <img
//                 src={calenderImage}
//                 alt="Student"
//                 className="card1-icon"
//                 style={{ width: "50px", height: "50px", marginLeft: "30px" }}
//               />
//             </div>
//           </div>
//         </div>

//         <div className="card-content-row" style={{ marginBottom: "20px" }}>
//           {/* Conditional content based on the active card */}
//           {activeCard === "card1" && (
//             <div
//               className="card-content"
//               style={{
//                 color: "black",
//                 backgroundColor: "",
//                 width: "auto",
//                 padding: "20px",
             
//               }}
//             >
//               <h3
//                 style={{
//                   color: "#ee1515",
//                   textAlign: "center",
//                   fontSize: "30px",
//                 }}
//               >
//                 Student Information
//               </h3>
//               <div
//                 style={{
//                   maxHeight: "290px",
//                   overflowY: "auto",
//                   fontSize: "18px",
//                   padding: "20px",
//                   width: "950px",
//                   marginLeft: "-40px",
               
//                   boxShadow:
//                     "#9bcfd4 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.35) 0px 18px 36px -18px inset",
//                 }}
//               >
//                 <br />
//                 <br />

//                 <h4 style={{ marginTop: "-30px" }}>Name:</h4>
//                 <h4 style={{ marginTop: "15px" }}>Member Id:</h4>
//                 <h4 style={{ marginTop: "15px" }}>DOB:</h4>
//                 <h4 style={{ marginTop: "15px" }}>Block no.</h4>
//                 <h4 style={{ marginTop: "15px" }}>Room no:</h4>
//                 <h4 style={{ marginTop: "15px" }}>Email Id:</h4>
//               </div>
//             </div>
//           )}
//           {activeCard === "card2" && (
//             <div
//               className="card-content"
//               style={{
//                 color: "black",
//                 backgroundColor: "#",
//                 width: "auto",
//                 padding: "20px",
//               }}
//             >
//               <h3>Attendance Content</h3>
//               <p></p>
//               <div style={{ height: "300px" }}>
//                 <Pie data={data} options={options} />
//               </div>
//             </div>
//           )}
//           {activeCard === "card3" && (
//             <div
//               className="card-content"
//               style={{
//                 color: "black",
//                 backgroundColor: "#dfedf1",
//                 width: "auto",
//                 padding: "20px",
//               }}
//             >
//               <h3>Complaint Status Content</h3>
//               <p>This is the complaint status content.</p>
//             </div>
//           )}
//           {activeCard === "card4" && (
//             <div className="events-card" style={{ color: "black", backgroundColor: "", width: "auto", padding: "20px" }}>
//             <div className="card1-inner" style={{ marginTop: "-15px" }}>
//               <Container style={{}}>
//                 <h2 style={{ color: "#ee1515", textAlign: "center", fontSize: "30px" }}>Events</h2>
//                 <div style={{ marginTop: "10px", height: "auto", width: "800px", marginLeft: '50px', overflowY: "scroll", overflowX: "hidden" }} className="news-container" ref={scrollRef} onMouseEnter={() => setIsPaused(true)}>
//   <div className="news-wrapper" style={{ animation: !isPaused ? "scroll 100s linear infinite" : "none", columnCount: 2 }}>
//     {news.map((item) => (
//       <div key={item.id} className="news-item" style={{ border: "none" }}>
//         <span style={{ animation: "blinking 0.8s infinite", marginRight: "5px", color: "red", fontSize: "20px" }}><b>💥</b></span>
//         <span style={{ color: "black", fontWeight: "500" }}>📅{item.date}</span>{" "}
//         <span style={{ color: "#0059FF" }}><b>{item.content}</b></span>
//       </div>
//     ))}
//    </div>
//  </div>

          
//                 <style jsx>{`
//                   @keyframes blinking {
//                     0% {
//                       opacity: 1;
//                     }
//                     50% {
//                       opacity: 0;
//                     }
//                     100% {
//                       opacity: 1;
//                     }
//                   }
          
//                   .news-wrapper {
//                     animation: scroll 10s linear infinite;
//                     animation-play-state: running;
//                   }
          
//                   .news-wrapper:hover {
//                     animation-play-state: paused;
//                   }
          
//                   .news-item {
//                     border: 1px solid #ccc;
//                     padding: 10px;
//                     margin: 10px 0;
//                   }
          
//                   @keyframes scroll {
//                     0% {
//                       transform: translateY(0);
//                     }
//                     100% {
//                       transform: translateY(calc(-100% * ${news.length}));
//                     }
//                   }
          
//                   /* Style the scrollbar */
//                   .news-container::-webkit-scrollbar {
//                     width: 0px; /* Width of the scrollbar */
//                   }
          
//                   /* Track */
//                   .news-container::-webkit-scrollbar-track {
//                     background: #F89880; /* Color of the track */
//                   }
          
//                   /* Handle */
//                   .news-container::-webkit-scrollbar-thumb {
//                     background: #888; /* Color of the scroll thumb */
//                   }
          
//                   /* Handle on hover */
//                   .news-container::-webkit-scrollbar-thumb:hover {
//                     background: #555; /* Color of the scroll thumb on hover */
//                   }
//                 `}</style>
//               </Container>
//             </div>
//           </div>
          
//           )}
//         </div>
//       </div><footer
//                     className="fixed-footer"
//                     style={{
//                       marginTop: "600px",
//                       backgroundColor: "#5AB1BB",
//                       position: "fixed",
//                       width: "1060px",
//                       marginLeft: "220px",
//                       height: "50px",
//                     }}
//                   >
//                     <div className="container footerr">
//                       <div className="row">
//                         <div className="col-12 text-center">
//                           {/* Footer content goes here */}
//                           <p style={{ color: "white" }}>
//                             &copy;2024 Sheth R. D. Davada Digambar Jain
//                             Boarding, Sangli. All Rights Reserved.
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </footer>
//                   <style jsx>{`.main-cards {
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr 1fr;
//   gap: 20px;
//   margin: 15px 0;
// }

// .card {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   padding: 8px 15px;
//   border-radius: 5px;
// }


// .card:first-child {
//   background: linear-gradient(90deg, rgba(255,191,150,1) 3%, rgba(254,112,150,1) 99%);
// }

// .card:nth-child(2) {
//   background: linear-gradient(90deg, rgba(144,202,249,1) 3%, rgba(4,126,223,1) 99%);
// }

// .card:nth-child(3) {
//   background: linear-gradient(90deg, rgba(132,217,210,1) 3%, rgba(7,205,174,1) 99%);
// }

// .card:nth-child(4) {
//   background: linear-gradient(90deg, rgba(216,255,150,1) 3%, rgba(53,243,42,1) 99%);
// }

// .profile-icon.white {
//   color: rgb(0, 0, 0); /* Set color to white */
// }

// .card-inner {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

// .card-inner > .card_icon {
//   font-size: 25px;
// }
// .card:hover{
//   background-color: #e0e4ff; /* Change background color on hover */
// }
// .card1 h2{
//   color:rgb(255, 255, 255);
//   text-shadow: 1px 1px  black;
// }
// .card1 h3{
//   color:#f36709;
//   font-size: 15px;

// }
// .event-detail{
//   margin-left: -400px;
// }
// .event-detail h4{
//   color: #f36709;
// }
// .card:hover h1,
// .card:hover h3,
// .card:hover h4{
//   color: black; /* Change text color on hover */
// }
// .card:hover .card-inner  {
//   color: black; /* Change icon color on hover */
// }
// .card:hover >.card-icon {
//   color:black;
// }


// .card1 {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
 
//   padding: 8px 15px;
//   width: 310px;
//   height: 150px;
//   border-radius: 5px;
// }
// .card1-inner {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

// .card1-inner > .card_icon {
//   font-size: 25px;
// }
// .card1:hover{
//   background-color: #505fc0; /* Change background color on hover */
// }

// .card1:hover h1,
// .card1:hover h3,
// .card1:hover h4{
//   color: black; /* Change text color on hover */
// }
// .card1:hover .card-inner  {
//   color: black; /* Change icon color on hover */
// }
// .card1:hover >.card-icon {
//   color:black;
// }
// .main1-container {

//   padding: 20px 20px;
//   color: rgba(255, 255, 255, 0.95);
// }

// .main1-title {
//   display: flex;
//   justify-content: space-between;
//   color:#495494;
// }

// .main1-cards {
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr 1fr;
//   gap: 20px;
//   margin: 15px 0;
// }





// `}</style>
// </section>
//     </>
//   );
// }

// export default Home;



import React, { useEffect, useState } from 'react'
import Sidebar from './sidebarstudent'
import Header from './Headerstudent'
import { FaUserLarge } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { MdEvent } from "react-icons/md";
import { Button } from '@mui/material';
import { EventDashboardApi } from '../../ApiFile';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StudentMessTodaysMenuFindall } from '../../ApiFile';
import { Col, Row } from 'react-bootstrap';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const Homestudent = () => {

  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);
  const [lunchData, setLunchData] = useState(null);
  const [dinnerData, setDinnerData] = useState(null);

  useEffect(() => {

    //----------------------------Disabled back button-------------------------------
    window.history.pushState(null, null, window.location.href);

    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.forward();
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [ ]);

  useEffect(() => {
    fetch(EventDashboardApi)
      .then(response => {
        if (!response.ok) {
          throw new Error('NOTFOUND');
        }
        return response.json();
      })
      .then(data => {
        setEvents(data);
      })
      .catch(error => {
        if (error.message === 'NOTFOUND') {
          setError('Resource not found');
        } else {
          setError('Add Event');
        }
      });
  }, [EventDashboardApi]);



  useEffect(() => {
    // Fetch the today's menu data from the API
    fetch(StudentMessTodaysMenuFindall)
      .then((response) => response.json())
      .then((data) => {
        // Update the lunch data
        setLunchData({
          labels: data.lunch.map(item => item.menu),
          datasets: [
            {
              data: data.lunch.map(() => 15), // Example data values, modify as needed
              backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#4BC0C0',
                '#9966FF',
                '#FF9F40',
                '#FFCD56',
              ]
            }
          ]
        });

        // Update the dinner data
        setDinnerData({
          labels: data.dinner.map(item => item.menu),
          datasets: [
            {
              data: data.dinner.map(() => 20), // Example data values, modify as needed
              backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#4BC0C0',
                '#9966FF',
                '#FF9F40'
              ]
            }
          ]
        });
      })
      .catch((error) => {
        console.error('Error fetching the menu data:', error);
      });
  }, []);

  // Chart options with datalabels plugin
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows chart to resize better
    plugins: {
      legend: {
        display: false // Hide the legend since labels will be on the chart
      },
      datalabels: {
        color: '#fff', // Text color for the labels
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        },
        font: {
          weight: 'bold'
        }
      }
    }
  };


  return (
    <>
    <Sidebar />
    <section className="home-section pb-5">
      <Header />
      <div> 
      <h2 className="text-center mt-md-4 mt-2 mb-3 m-0 p-0" style={{ color: 'maroon' }}>
              Dashboard
            </h2>
        </div>
        <div className='row mx-1'>
          <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid rgb(247, 140, 177)' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <FaUserLarge style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Profile</h5>
                </div>
                <div className="col-12 ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <div className="fs-5" style={{color:"gray"}}>
  
                </div>
                <div>
                <Button >
                   <Link to="/StudentProfile" className='navigateLink'><Button style={{backgroundColor:"rgb(247, 140, 177)" ,color:"black"}}>View</Button></Link>
                </Button>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid #2bdbd1' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <FaUserLarge style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Attendance Mess</h5>
                </div>
                <div className="col-12 ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <div className="fs-5" style={{color:"gray"}}>
                </div>
                <div>
                <Button >
                   <Link to="/MessAttendance" className='navigateLink'><Button style={{backgroundColor:"#2bdbd1" ,color:"black"}}>View</Button></Link>
                </Button>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid orange' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <FaUserLarge style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Attendance Event</h5>
                </div>
                <div className="col-12 ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <div className="fs-5" style={{color:"gray"}}>
  
                </div>
                <div>
                <Button >
                   <Link to="/MyAttendance" className='navigateLink'><Button style={{backgroundColor:"orange" ,color:"black"}}>View</Button></Link>
                </Button>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid rgb(57, 158, 161' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <FaUserLarge style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Jobs</h5>
                </div>
                <div className="col-12 ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <div className="fs-5" style={{color:"gray"}}>
  
                </div>
                <div>
                <Button >
                   <Link to="/Jobsstudent" className='navigateLink'><Button style={{backgroundColor:"rgb(57, 158, 161)" ,color:"black"}}>View</Button></Link>
                </Button>
                </div>
                </div>
              </div>
            </div>
          </div>
          </div> 

          <div className='row mx-1 d-flex'>
        <div className="col-12 col-md-4 p-2 mb-5 border pb-5 mb-md-1 pb-md-1">
      <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', height: "200px", color: '#2bdbd1', borderBottom: '5px solid rgb(146, 209, 83)' }}>
        <div className="row p-0 m-0 " >
          <div className="col-12 d-flex fs-5 pt-2" style={{ borderBottom: "1px solid gray" }}>
            <MdEvent style={{ color: "gray" }} />&nbsp;&nbsp;&nbsp;
            <h5>Events</h5>
          </div>
          <div className="col-12" style={{ overflowY: 'scroll', maxHeight: '148px' }}>
            {error ? (
              <div>{error}</div>
            ) : (
             <table  className="table">
                <thead>
                  <tr className='p-0 m-0'  style={{ height: '10px' , fontSize:"13px"  }}>
                    <th scope="col">Sr No</th>
                    <th scope="col">Event Date</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((event, index) => (
                    <tr key={index}  style={{ height: '15px' ,fontSize:"13px"  }}>
                      <td>{index + 1}</td>
                      <td>{event.event_date}</td>
                      <td>{event.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          </div>
          </div>
          </div>
        



          <div className="col-12 col-md-4 p-2 mb-5 pb-5 mb-md-1 pb-md-1">
      <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', height: "200px", color: '#2bdbd1', borderBottom: '5px solid rgb(146, 209, 83)' }}>
        <div className="row p-0 m-0 " >
          <div className="col-12 d-flex fs-5 pt-2" style={{ borderBottom: "1px solid gray" }}>
            <MdEvent style={{ color: "gray" }} />&nbsp;&nbsp;&nbsp;
            <h5>Todays Menu</h5>
          </div>
          <div className="col-12" style={{ overflowY: 'scroll', maxHeight: '148px' }}>
          <Row style={{display:"flex",justifyContent:"space-evenly"}}>
            <Col md={5} className="text-center">
            <h6>Lunch</h6>
              <div>
                {lunchData && <Pie data={lunchData} options={options} />}
              </div>
            </Col>
            <Col md={5} className="text-center">
              <h6>Dinner</h6>
              <div >
                {dinnerData && <Pie data={dinnerData} options={options} />}
              </div>
            </Col>
          </Row>
        </div>
        </div>
          </div>
          </div>


          </div>


       


    </section>
    </>
  )
}

export default Homestudent
