import React, { useState, useEffect } from 'react';

const AdminProfile = () => {
    // Define state for the admin's profile data
    const [profileData, setProfileData] = useState({
        name: '',
        email: '',
        role: '',
        // Add other fields as needed
    });

    // Fetch admin profile data when the component mounts
    useEffect(() => {
        // Fetch profile data from an API endpoint
        fetch('/api/admin-profile')
            .then(response => response.json())
            .then(data => setProfileData(data))
            .catch(error => console.error('Error fetching profile data:', error));
    }, []);

    // Handle form submissions
    const handleFormSubmit = (event) => {
        event.preventDefault();

        // Send updated profile data to an API endpoint
        fetch('/api/admin-profile', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(profileData),
        })
        .then(response => response.json())
        .then(data => {
            // Handle success (e.g., display a success message)
            // console.log('Profile updated:', data);
        })
        .catch(error => {
            // Handle error (e.g., display an error message)
            // console.error('Error updating profile:', error);
        });
    };

    // Handle input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };

    return (
        <div className="admin-profile">
            <h1 style={{textAlign: 'center' }}> Change Password</h1>
            <form onSubmit={handleFormSubmit}>     
            </form>
        </div>
    );
};

export default AdminProfile;
