import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideNavbarStudentReg from './SideNavbarStudentReg';
import NavBarStudentReg from './NavBarStudentReg';
import { TermFindAllApi } from '../ApiFile';

const RegRulePage = () => {
  const [rules, setRules] = useState([]);
  const [isChecked, setIsChecked] = useState(false); // State variable for checkbox
  const [isValid, setIsValid] = useState(false); // State variable for validation status

  const navigate = useNavigate();

  useEffect(() => {
    fetchRuleData();
  }, []);

  const fetchRuleData = async () => {
    try {
      const response = await fetch(TermFindAllApi);
      if (!response.ok) {
        throw new Error('Failed to fetch block data');
      }
      const data = await response.json();
      setRules(data);
      // console.log('rule api res...', data);
    } catch (error) {
      // console.error('Error fetching block data:', error);
    }
  };

  // Handler for checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle checkbox state
    setIsValid(!isChecked); // Update validation status based on checkbox state
  };

  // Handler for next button click
  const handleNextButtonClick = () => {
    if (isValid) {
      navigate('/RegInformationPage'); // Call the function to go to the next page if validation is successful
    } else {
      toast.error('Please select the checkbox to proceed.'); // Show toast message if checkbox is not selected
    }
  };

  return (
    <>
      <ToastContainer />
      <SideNavbarStudentReg />
      <section className="home-section">
        <NavBarStudentReg />
        <div>
          <div
            className="my-3 px-md-4 px-3 mx-md-4 mx-2 py-4"
            style={{
              textAlign: 'left',
              borderRadius: '15px',
              backgroundColor: '#dafddc',
            }}
            id="step-1"
          >
            <h3 style={{ color: '#45a049' }}>Rules</h3>
            <div>
              <div style={{ lineHeight: '40px' }}>
                <div>
                  <ul>
                    {rules.map((rule) => (
                      <li key={rule.id}>
                        {rule.serial_no}. &nbsp; {rule.description}
                        <br />
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Next button */}
                <Row>
                  <Col sm={1} md={1}>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                  <Col sm={11} md={11}></Col>
                </Row>
                <label style={{ color: 'black', marginLeft: '10px' }}>
                  वरील सर्व नियम व अटी मान्य आहेत
                </label>
                <div className="button-container text-center">
                  <button
                    type="button"
                    className={`text-center fs-4 btn3 btn-primary nextBtn ${
                      !isValid && 'disabled'
                    }`}
                    style={{
                      backgroundColor: 'rgb(23, 160, 160)',
                      color: 'black',
                      width: '100px',
                      border: '0px',
                      marginTop: '-10px',
                    }}
                    onClick={handleNextButtonClick}
                  >
                    Next
                  </button>
                </div>

                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegRulePage;
