// import React, { useState, useEffect } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { IoBed } from "react-icons/io5";
// import Button from "react-bootstrap/Button";
// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import "../Room Master/room.css";
// import Header from "./Header";
// import logo1 from '../../RoomBookingDesign/img/bedroom.jpg';
// import Sidebar from "./sidebar";
// import { Col, Row } from "react-bootstrap";
// import axios from "axios";
// import { BedAllocateAddApi, BedAllocateAddApi2, BedBookListApi, BlockFindallApi, ImagePath } from "../../ApiFile";
// import Footer from "./Footer";
// import { toast } from "react-toastify";

// const BedBookingPage = () => {
//   const [beds, setBeds] = useState([]);
//   const [blocks, setBlocks] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const { block_id, room_id } = useParams();
//   // console.log("block id.,.,",block_id)
//   const navigate = useNavigate();
//   const memberId = sessionStorage.getItem("member_id");

//   const [showModal, setShowModal] = useState(false);
//   const [modalData, setModalData] = useState([]);

//   const handleClose = () => {
//     navigate('/BlockRoomBooking')
//   }
//   const handleNextPage = () => {
//     // console.log("moalkh",modalData)
//     navigate('/PaymentRoomBooking',{ state : { bedFinal : modalData}});
//   }

//   useEffect(() => {
//     fetchBlocks();
//   }, []);

//   const fetchBlocks = async () => {
//     try {
//       const response = await fetch(BlockFindallApi);
//       if (!response.ok) {
//         throw new Error('Failed to fetch blocks');
//       }
//       const data = await response.json();
//       // console.log("Fetched data:", data);
//       setBlocks(data);
//     } catch (error) {
//       // console.error('Error fetching blocks:', error);
//     }
//   };

//   const fetchBeds = async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(BedBookListApi, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ block_id, room_id }),
       
//       });
//       // console.log("kkkk",block_id)
//       if (!response.ok) {
//         throw new Error("Failed to fetch beds");
//       }
//       const responseData = await response.json();
//       setBeds(responseData);
//       // console.log("hkj", responseData);
//     } catch (error) {
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };



//   const handleBedSelect = async (bedId) => {
//     const selectedBed = beds.find((bed) => bed.bed_id === bedId);
//     if (selectedBed) {
//       if (selectedBed.flag === "2") {
//         alert("Booked");
//         // console.log(`Bed ${selectedBed.bed_id} is booked.`);
//       } else if (selectedBed.flag === "1") {
//         alert("In Approval Process.");
//       } else if (selectedBed.flag === "0") {
//         const obj = {
//           block_id: block_id,
//           room_id: parseInt(room_id),
//           bed_id: parseInt(bedId),
//           member_id: parseInt(memberId),
//         };

//         // console.log("obj consolll...", obj);
//         try {
//           const response = await axios.post(BedAllocateAddApi2, obj);
//           if (response.data !== null) {
//             console.log("obj consolll...", response.data);
//             setModalData(response.data[0]);
//             setShowModal(true);
//           }
//         } catch (err) {
//           console.error("Failed to save data:", err);
//           toast.error("Failed...", {
//             position: "top-right",
//             autoClose: 3000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//           });
//         }
//       }
//     }
//   };

//   useEffect(() => {
//     fetchBeds();
//   }, [block_id, room_id]);

//   const handlePreviousClick = () => {
//     navigate(`/room/${block_id}/${room_id}`);
//   };

//   const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

//   const OpenSidebar = () => {
//     setOpenSidebarToggle(!openSidebarToggle);
//   };

//   return (
//     <>
//       <Sidebar />
//       <section className="home-section pb-5">
//         <Header />

//         <div className="bed-booking px-md-5 px-2 mt-md-5 mt-3 pb-5">
//           <h2 className="main-title1">Select the Bed</h2>

//           <Row className="ms-3 ms-md-5 mt-3">
//             <label>
//               <input
//                 style={{
//                   backgroundColor: "red",
//                   height: "17px",
//                   width: "17px",
//                   borderRadius: "0px",
//                 }}
//               />{" "}
//               {" "} &nbsp;&nbsp;
//               Booked.
//             </label>
//             <label>
//               <input
//                 style={{
//                   backgroundColor: "orange",
//                   height: "17px",
//                   width: "17px",
//                   borderRadius: "0px",
//                 }}
//               />{" "}
//               {" "}&nbsp;&nbsp;
//               In Approval Process.
//             </label>
//             <label>
//               <input
//                 style={{
//                   backgroundColor: "rgb(83, 219, 83)",
//                   height: "17px",
//                   width: "17px",
//                   borderRadius: "0px",
//                 }}
//               />{" "}
//               {" "}&nbsp;&nbsp;
//               Available
//             </label>
//           </Row>
//           {loading ? (
//             <p>Loading...</p>
//           ) : error ? (
//             <p className="error-message">Error fetching beds: {error}</p>
//           ) : (
//             <div className="bed-container-wrapper ms-3 ms-md-5 d-flex row">
//               <div className="bed-container1 main-cards1 text-center col-md-7">
//                 {beds.length === 0 ? (
//                   <p>No beds available</p>
//                 ) : (
//                   beds.map((bed) => (
//                     <div
//                       key={bed.bed_id}
//                       className={`mx-3 mt-4 rounded bed-card ${
//                         bed.flag === "2"
//                           ? "booked"
//                           : bed.flag === "1"
//                           ? "pending-approval"
//                           : "pending"
//                       }`}
//                       onClick={() => handleBedSelect(bed.bed_id)}
//                     >
//                       <div
//                         style={{
//                           backgroundColor:
//                             bed.flag === "2"
//                               ? "red"
//                               : bed.flag === "1"
//                               ? "orange"
//                               : "rgb(83, 219, 83)",
//                         }}
//                         className="text-center p-3 shadow"
//                       >
//                         <IoBed size={28} className="bed-icon" />
//                         <br />
//                         <span
//                           style={{ fontSize: "20px" }}
//                           className="bed-label"
//                         >
//                           Bed {bed.bed_id}
//                         </span>
//                       </div>
//                     </div>
//                   ))
//                 )}
//               </div>

//               <div className="col-md-5 " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//                 <img src={`${ImagePath}/${beds.bed_img}`} alt="Logo" className="rounded shadow" style={{ mixBlendMode: "darken", height: "200px", width: "250px" }} />
//               </div>
//             </div>
//           )}
//         </div>

//       </section>

//       <Modal open={showModal} onClose={handleClose}>
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: 400,
//             bgcolor: "background.paper",
//             border: "2px solid #000",
//             boxShadow: 24,
//             p: 4,
//           }}
//         >
//           <div className="block-header text-center">
//           <h4 className="mb-2  mt-1 " style={{ color: 'maroon', borderBottom:"1px solid maroon"}}>
//         Your Bed Booking Details
//           </h4>
//         </div>
         
//             <div>
//               <Typography sx={{ mt: 2 }}><strong>Block No:</strong> {modalData.block_id}</Typography>
//               <Typography sx={{ mt: 2 }}><strong>Room No:</strong> {modalData.room_id}</Typography>
//               <Typography sx={{ mt: 2 }}><strong>Bed No:</strong> {modalData.bed_id}</Typography>
//               <Typography sx={{ mt: 2 }}><strong>Amount:</strong> {modalData.amount}</Typography>
//               {/* Include other details you want to show */}
//             </div>
        


//           <Box   sx={{
//                 p: 2,
//               }}>
       
//      <Row className='mt-3' style={{display:"flex"}}>

//            <Col>
//               <Button
//                 variant="contained"
//                 className='w-100 text-light'
//                 style={{backgroundColor:"rgb(77, 81, 168)"}}
//                 onClick={handleClose}
//               >
//             Back
//               </Button>
//               </Col>

//               <Col>
//               <Button
//                 variant="contained"
//                 onClick={handleNextPage}
//                 style={{backgroundColor:"rgb(49, 141, 106)", marginRight: '10px' }}
//                 className='w-100 text-light'
//               >
//           Proceed
//               </Button>
//               </Col>
              
//               </Row>
//               </Box>
//         </Box>
//       </Modal>
//     </>
//   );
// };

// const previousButtonStyle = {
//   backgroundColor: "#153084",
//   color: "white",
//   marginTop: "20px",
//   padding: "10px 60px",
//   marginLeft: "20px",
// };

// export default BedBookingPage;



import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoBed } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "../Room Master/room.css";
import axios from "axios";
import { BedAllocateAddApi2, BedBookListApi, BlockFindallApi, ImagePath } from "../../ApiFile";
import Footer from "./Footer";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import Sidebar from "./sidebar";
import Header from "./Header";

const BedBookingPage = () => {
  const [beds, setBeds] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { block_id, room_id } = useParams();
  const navigate = useNavigate();
  const memberId = sessionStorage.getItem("member_id");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  const handleClose = () => {
    navigate('/BlockRoomBooking');
  };

  const handleNextPage = () => {
    navigate('/PaymentRoomBooking', { state: { bedFinal: modalData } });
  };

  useEffect(() => {
    fetchBlocks();
  }, []);

  const fetchBlocks = async () => {
    try {
      const response = await fetch(BlockFindallApi);
      if (!response.ok) {
        throw new Error('Failed to fetch blocks');
      }
      const data = await response.json();
      setBlocks(data);
    } catch (error) {
      console.error('Error fetching blocks:', error);
    }
  };

  const fetchBeds = async () => {
    setLoading(true);
    try {
      const response = await fetch(BedBookListApi, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ block_id, room_id }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch beds");
      }
      const responseData = await response.json();
      setBeds(responseData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBedSelect = async (bedId) => {
    const selectedBed = beds.find((bed) => bed.bed_id === bedId);
    if (selectedBed) {
      if (selectedBed.flag === "2") {
        alert("Booked");
      } else if (selectedBed.flag === "1") {
        alert("In Approval Process.");
      } else if (selectedBed.flag === "0") {
        const obj = {
          block_id: block_id,
          room_id: parseInt(room_id),
          bed_id: parseInt(bedId),
          member_id: parseInt(memberId),
        };

        try {
          const response = await axios.post(BedAllocateAddApi2, obj);
          if (response.data !== null) {
            setModalData(response.data[0]);
            setShowModal(true);
          }
        } catch (err) {
          console.error("Failed to save data:", err);
          toast.error("Failed...", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    }
  };

  useEffect(() => {
    fetchBeds();
  }, [block_id, room_id]);

  const handlePreviousClick = () => {
    navigate(`/room/${block_id}/${room_id}`);
  };

  return (
    <>
      <Sidebar />
      <section className="home-section pb-5">
        <Header />
        <div className="bed-booking px-md-5 px-2 mt-md-5 mt-3 pb-5">
          <h2 className="main-title1">Select the Bed</h2>
          <Row className="ms-3 ms-md-5 mt-3">
            <label>
              <input
                style={{ backgroundColor: "red", height: "17px", width: "17px", borderRadius: "0px" }}
              /> &nbsp;&nbsp;
              Booked.
            </label>
            <label>
              <input
                style={{ backgroundColor: "orange", height: "17px", width: "17px", borderRadius: "0px" }}
              /> &nbsp;&nbsp;
              In Approval Process.
            </label>
            <label>
              <input
                style={{ backgroundColor: "rgb(83, 219, 83)", height: "17px", width: "17px", borderRadius: "0px" }}
              /> &nbsp;&nbsp;
              Available
            </label>
          </Row>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="error-message">Error fetching beds: {error}</p>
          ) : (
            <div className="bed-container-wrapper ms-3 ms-md-5 d-flex row">
              <div className="bed-container1 main-cards1 text-center col-md-7">
                {beds.length === 0 ? (
                  <p>No beds available</p>
                ) : (
                  beds.map((bed) => (
                    <div
                      key={bed.bed_id}
                      className={`mx-3 mt-4 rounded bed-card ${
                        bed.flag === "2" ? "booked" : bed.flag === "1" ? "pending-approval" : "pending"
                      }`}
                      onClick={() => handleBedSelect(bed.bed_id)}
                    >
                      <div
                        style={{
                          backgroundColor: bed.flag === "2" ? "red" : bed.flag === "1" ? "orange" : "rgb(83, 219, 83)",
                        }}
                        className="text-center p-3 shadow"
                      >
                        <IoBed size={28} className="bed-icon" />
                        <br />
                        <span style={{ fontSize: "20px" }} className="bed-label">
                          Bed {bed.bed_id}
                        </span>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="col-md-5 d-flex justify-content-center align-items-center">
                {beds.length > 0 && (
                  <img
                    src={`${ImagePath}/${beds[0].room_img}`} // Access the first room_img in the beds array
                    alt={beds[0].room_img}
                    className="rounded shadow"
                    style={{ mixBlendMode: "darken", height: "200px", width: "250px" }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      <Modal open={showModal} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="block-header text-center">
            <h4 className="mb-2 mt-1" style={{ color: 'maroon', borderBottom: "1px solid maroon" }}>
              Your Bed Booking Details
            </h4>
          </div>
          <div>
            <Typography sx={{ mt: 2 }}><strong>Name:</strong> {modalData.name}</Typography>
            <Typography sx={{ mt: 2 }}><strong>Block No:</strong> {modalData.block_id}</Typography>
            <Typography sx={{ mt: 2 }}><strong>Room No:</strong> {modalData.room_id}</Typography>
            <Typography sx={{ mt: 2 }}><strong>Bed No:</strong> {modalData.bed_id}</Typography>
            <Typography sx={{ mt: 2 }}><strong>Amount:</strong> {modalData.amount}</Typography>
          </div>
          <Box sx={{ p: 2 }}>
            <Row className='mt-3' style={{ display: "flex" }}>
              <Col>
                <Button
                  variant="contained"
                  className='w-100 text-light'
                  style={{ backgroundColor: "rgb(77, 81, 168)" }}
                  onClick={handleClose}
                >
                  Back
                </Button>
              </Col>
              <Col>
                <Button
                  variant="contained"
                  onClick={handleNextPage}
                  style={{ backgroundColor: "rgb(49, 141, 106)", marginRight: '10px' }}
                  className='w-100 text-light'
                >
                  Proceed
                </Button>
              </Col>
            </Row>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BedBookingPage;
