import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import Home from "./Home";
import Job from "./Job";
import About from "./About";
import Alumini from "./Alumini";
import Boardofdir from "./Boardofdir";
import Gallery from "./Gallery";
import Post from "./Post";
import Blog from "./Blog";
import Contact from "./Contact";
import Services from "./Services";
import Boarding from "./Boarding";
import Privacy from "./Privacy";
import TandC from "./TandC";
import Slogin from "./Slogin";
import Signup from "./sign up";
import Rules from "./Rules";
import Forgotpass from "./Forgotpass";
import Forms from "./Forms";

import AluminiLogin from './../../AluminiLoginDesign/pages/AluminiLogin';
import AluminiSignup from './../../AluminiLoginDesign/pages/AluminiSignup';
import HomeAlumini from '../../AluminiLoginDesign/pages/HomeAlumini';
import Jobpost from './../../AluminiLoginDesign/pages/Jobpost';
import GalleryAlumini from '../../AluminiLoginDesign/pages/GalleryAlumini';
import Aluminiform from './../../AluminiLoginDesign/pages/Aluminiform';
import Suggestion from './../../AluminiLoginDesign/pages/Suggestion';

import AdminProfile from './../../AluminiAdminDashboardDesign/pages/Profile';
import Event from './../../AluminiAdminDashboardDesign/pages/event';
import RoomBookingTable from './../../AluminiAdminDashboardDesign/pages/roombooking';
import Payment from './../../AluminiAdminDashboardDesign/pages/payment';
import AluminiView from './../../AluminiAdminDashboardDesign/Alumini/AluminiView';
import StudentView from './../../AluminiAdminDashboardDesign/Student/StudentView';
// import JobCategoryMaster from './../../AluminiLoginDesign/pages/JobCategoryMaster';
import Block from './../../AluminiAdminDashboardDesign/Booking Master/Block';
import Room from './../../AluminiAdminDashboardDesign/Booking Master/room';
import Bed from './../../AluminiAdminDashboardDesign/Booking Master/Bed';
import FeeMaster from './../../AluminiAdminDashboardDesign/pages/FeeMaster';
import AlumniGallery from './../../AluminiAdminDashboardDesign/pages/Agallery';
import BoardingFeeMaster from './../../AluminiAdminDashboardDesign/pages/BoardingFeeMaster';
import JobpostAdmin from "../../AluminiAdminDashboardDesign/pages/JobpostAdmin";
import Alljob from './../../AluminiAdminDashboardDesign/pages/Alljob';
import HomeAdmin from "../../AluminiAdminDashboardDesign/HomeAdmin";

import Header from "../../AluminiLoginDesign/pages/Header";
import NavLogo from "../../AluminiAdminDashboardDesign/NavLogo";
import Complaint from "../../AluminiAdminDashboardDesign/pages/Complaint";


import StudentLogin from './../../RoomBookingDesign/pages/StudentLogin';
import BlockRoomBooking from './../../RoomBookingDesign/Room Master/BlockRoomBooking';
import RoomBooking from './../../RoomBookingDesign/Room Master/Room';
import BedBookingPage from './../../RoomBookingDesign/Room Master/Bed';
import PaymentRoomBooking from './../../RoomBookingDesign/Room Master/PaymentRoomBooking';


import Homestudent from './../../studentDashboard/pages/Homestudent';
import StudentComplaintForm from './../../studentDashboard/pages/complainstudent';
import Jobs from './../../studentDashboard/pages/Jobsstudent';
import Eventstudent from "../../studentDashboard/pages/Eventstudent";
import SideNavbarStudentReg from "../../StudentRegristrationDesign/SideNavbarStudentReg";
import NavBarStudentReg from "../../StudentRegristrationDesign/NavBarStudentReg";
import TermCondition from "../../StudentRegristrationDesign/TermCondition";
import PrivacyPolacyReg from "../../StudentRegristrationDesign/PrivacyPolacyReg";
import RegForm from "../../StudentRegristrationDesign/RegForm";
import Login from "../../AluminiAdminDashboardDesign/adminlogin/Login";
import ChangePasswordStudent from "../../RoomBookingDesign/ChangePasswordStudent";
import ChangePasswordAdmin from "../../AluminiAdminDashboardDesign/adminlogin/ChangePasswordAdmin";
import FeeType from "../../AluminiAdminDashboardDesign/pages/FeeType";
import FeeMaster1 from "../../AluminiAdminDashboardDesign/pages/FeeMaster1";
import AdmissionRuleMaster from "../../AluminiAdminDashboardDesign/pages/AdmissionRuleMaster";
import Reg50Approval from "../../AluminiAdminDashboardDesign/pages/Reg50Approval";
import RoomApproval from "../../AluminiAdminDashboardDesign/pages/RoomApproval";
import AdminLoginYear from "../../AluminiAdminDashboardDesign/adminlogin/AdminLoginYear";
import DownloadReceipt from "../../studentDashboard/pages/DownloadReceipt";


// import Form from './../../StudentRegristrationFormDesign/Pages/Form';
// import Form1 from './../../StudentRegristrationFormDesign/Pages/Form1';
// import Form2 from './../../StudentRegristrationFormDesign/Pages/Form2';
import ChangePasswordStudent1 from './../../studentDashboard/pages/ChangePasswordStudent1';
import Footer from "../Components/Footer";
import RegRulePage from "../../StudentRegristrationDesign/RegRulePage";
import RegInformationPage from "../../StudentRegristrationDesign/RegInformationPage";
import RoomChange from "../../AluminiAdminDashboardDesign/pages/RoomChange";
import AdminBlock from "../../AluminiAdminDashboardDesign/pages/AdminBlock";
import AdminRoomChange from "../../AluminiAdminDashboardDesign/pages/AdminRoomChange";
import AdminBedChange from "../../AluminiAdminDashboardDesign/pages/AdminBedChange";
import AdminRefundPage from "../../AluminiAdminDashboardDesign/pages/AdminRefundPage";
import AdminPaymentChange from "../../AluminiAdminDashboardDesign/pages/AdminPaymentChange";
import FooterAll from "../../FooterAll";
import InstallmentPay from "../../studentDashboard/pages/InstallmentPay";
import JobCategoryMaster from './../../AluminiAdminDashboardDesign/pages/JobCategoryMaster';
import WebsiteDashboardImgMaster from "../../AluminiAdminDashboardDesign/GalleryMaster/WebsiteDashboardImgMaster";
import GalleryMaster from './../../AluminiAdminDashboardDesign/GalleryMaster/GalleryMaster';
import BoardingDirectorsImgMaster from "../../AluminiAdminDashboardDesign/GalleryMaster/BoardingDirectorsImgMaster";

import EventAttendance from "../../AluminiAdminDashboardDesign/pages/EventAttendance";
import JobApproval from "../../AluminiAdminDashboardDesign/pages/JobApproval";
import EventLeaveApplication from "../../studentDashboard/pages/EventLeaveApplication";
import MessLeaveApplication from "../../studentDashboard/pages/MessLeaveApplication";
import RegularLeaveApplication from "../../studentDashboard/pages/RegularLeaveApplication";

import AdminDashboardReport from "../../AluminiAdminDashboardDesign/Report/AdminDashboardReport";
import ReportNavbar from "../../AluminiAdminDashboardDesign/Report/ReportNavbar";
import TodayFeeReport from "../../AluminiAdminDashboardDesign/Report/TodayFeeReport";
import TotalFeeReport from "../../AluminiAdminDashboardDesign/Report/TotalFeeReport";
import PendingFeeReport from "../../AluminiAdminDashboardDesign/Report/PendingFeeReport";
import FeeStatusHostelFee from "../../AluminiAdminDashboardDesign/Report/FeeStatusHostelFee";
import TodaysApplicationFormFee from "../../AluminiAdminDashboardDesign/Report/TodaysApplicationFormFee";
import TotalApplicationFormFee from "../../AluminiAdminDashboardDesign/Report/TotalApplicationFormFee";

import TodayDepositReport from "../../AluminiAdminDashboardDesign/Report/TodayDepositReport";
import TotalDepositReport from "../../AluminiAdminDashboardDesign/Report/TotalDepositReport";
import TodayDepositReturnReport from "../../AluminiAdminDashboardDesign/Report/TodayDepositReturnReport";
import TotalDepositReturnReport from "../../AluminiAdminDashboardDesign/Report/TotalDepositReturnReport";

import CarryForwardDeposit from "../../AluminiAdminDashboardDesign/Report/CarryForwardDeposit";
import TotalRefundHostel from "../../AluminiAdminDashboardDesign/Report/TotalRefundHostel";
import TodaysRefundHostel from "../../AluminiAdminDashboardDesign/Report/TodaysRefundHostel";
import VacantRoomBed from "../../AluminiAdminDashboardDesign/Report/VacantRoomBed";
import AllBookedRoomBed from "../../AluminiAdminDashboardDesign/Report/AllBookedRoomBed";
import NewAdmit from "../../AluminiAdminDashboardDesign/Report/NewAdmit";
import ReAdmit from "../../AluminiAdminDashboardDesign/Report/ReAdmit";
import MessWiseAttendance from "../../AluminiAdminDashboardDesign/Report/MessWiseAttendance";
import EventWiseAttendance from "../../AluminiAdminDashboardDesign/Report/EventWiseAttendance";
import AlphabeticalWiseStudent from "../../AluminiAdminDashboardDesign/Report/AlphabeticalWiseStudent";
import BlockWiseStudent from "../../AluminiAdminDashboardDesign/Report/BlockWiseStudent";
import AlumniReport from "../../AluminiAdminDashboardDesign/Report/AlumniReport";
import StudentLeaveReport from "../../AluminiAdminDashboardDesign/Report/StudentLeaveReport";
import DischargeReport from "../../AluminiAdminDashboardDesign/Report/DischargeReport";
import PaymentReceipt from "../../AluminiAdminDashboardDesign/Report/PaymentReceipt";
import DonationReport from "../../AluminiAdminDashboardDesign/Report/DonationReport";


import MessLeaveApprove from "../../AluminiAdminDashboardDesign/pages/MessLeaveApprove";
import RegularAndEventLeaveApprove from "../../AluminiAdminDashboardDesign/pages/RegularAndEventLeaveApprove";
import EventLeaveApprove from "../../AluminiAdminDashboardDesign/pages/EventLeaveApprove";
import MyAttendance from "../../studentDashboard/pages/MyAttendance";
import StudentProfile from "../../studentDashboard/pages/StudentProfile";
import BreakfastmenuMaster from "../../Mess/MessAdmin/BreakfastmenuMaster";
import FeedBack from "../../Mess/MessAdmin/FeedBack";
import MessLeaveApproval from "../../Mess/MessAdmin/MessLeaveApproval";
import MessMenuMaster from "../../Mess/MessAdmin/MessMenuMaster";
import RequestRec from "../../Mess/MessAdmin/RequestRec";
import BreakFastReq from "../../Mess/MessStudent/BreakFastReq";
import MessAttendanceAdmin from "../../Mess/MessAdmin/MessAttendanceAdmin";
import MessAttendance from "../../Mess/MessStudent/MessAttendance";
import MessFeedback from "../../Mess/MessStudent/MessFeedback";
import StudentTodaysMenu from "../../Mess/MessStudent/StudentTodaysMenu";
import StudentDischarge from "../../studentDashboard/pages/StudentDischarge";
import Discharge from "../../AluminiAdminDashboardDesign/AdminDischarge/Discharge";
import Admin2Discharge from "../../Admin2/Admin2Discharge";
import AluminiAllJob from "../../AluminiLoginDesign/pages/AluminiAllJob";
import SuggestionStudent from "../../studentDashboard/pages/SuggestionStudent";
import SuggestionAll from "../../AluminiAdminDashboardDesign/pages/SuggestionAll";
import Donation from "./Donation";
import ToDoMainAdmin from "../../AluminiAdminDashboardDesign/pages/ToDoMainAdmin";
import DonationList from "../../AluminiAdminDashboardDesign/pages/DonationList";
import OfficeAttendant from "../../AluminiAdminDashboardDesign/adminlogin/OfficeAttendant";
import MessAttendant from "../../AluminiAdminDashboardDesign/adminlogin/MessAttendant";
import Supertendent from './../../AluminiAdminDashboardDesign/adminlogin/Supertendent';


function TemplateFileImport() {


  return (
    <>
      <Routes>
        {/* ===================== Template Design ==================== */}
        <Route path="/Home" element={<Home />} />
        <Route path="/Job" element={<Job />} />
        <Route path="/About" element={<About />} />
        <Route path="/" element={<Home />} />
        <Route path="/Alumini" element={<Alumini />} />
        <Route path="/Boardofdir" element={<Boardofdir />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/Post" element={<Post />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Boarding" element={<Boarding />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/TandC" element={<TandC />} />
        <Route path="/Slogin" element={<Slogin />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/Forgotpass" element={<Forgotpass />} />
        <Route path="/Rules" element={<Rules />} />
        <Route path="/Forms" element={<Forms />} />
        <Route path="/OfficeAttendant" element={<OfficeAttendant />} />
        <Route path="/Supertendent" element={<Supertendent/>} />
        <Route path="/MessAttendant" element={<MessAttendant />} />
        <Route path="/Donation" element={<Donation />} />

        {/* ===================== Alumini Login Design ==================== */}
        {/* <Route path="/" element={<AluminiLogin />} /> */}
        <Route path="/AluminiLogin" element={<AluminiLogin />} />
        <Route path="/AluminiSignup" element={<AluminiSignup />} />
        <Route path="/AluminiDashboard" element={<HomeAlumini />} />
        <Route path="/Jobpost" element={<Jobpost />} />
        <Route path="/header" element={<Header />} />
        <Route path="/GalleryAlumini" element={<GalleryAlumini />} />
        <Route path="/Aluminiform" element={<Aluminiform />} />
        <Route path="/Suggestion" element={<Suggestion />} />
        <Route path="/AluminiAllJob" element={<AluminiAllJob />} />
        <Route path="/SuggestionStudent" element={<SuggestionStudent />} />

        {/* ===================== Admin Design==================== */}
       
          <Route path="/AdminLogin" element={<Login />} />
          <Route path="/ChangePasswordAdmin" element={<ChangePasswordAdmin />} />
          <Route path="/FeeType" element={<FeeType />} />
          <Route path="/FeeMaster1" element={<FeeMaster1 />} />
          <Route path="/AdminLoginYear" element={<AdminLoginYear />} />
          <Route path="/Reg50Approval" element={<Reg50Approval />} />
          <Route path="/RoomApproval" element={<RoomApproval />} />
          <Route path="/Rule" element={<AdmissionRuleMaster />} />
          <Route path="/AdminRoomChange/:block_id/:MemberId" element={<AdminRoomChange />} />
          <Route path="/AdminBedChange/:block_id/:room_id/:MemberId" element={<AdminBedChange />} />
          <Route path="/AdminRefundPage" element={<AdminRefundPage />} />
          <Route path="/AdminPaymentChange" element={<AdminPaymentChange />} />
          <Route path="/HomeAdmin" element={<HomeAdmin />} />
          <Route path="/admin-profile" element={<AdminProfile />} />
          <Route path="/event" element={<Event />} />
          <Route path="/roombooking" element={<RoomBookingTable />} />
          <Route path="/RoomChange" element={<RoomChange />} />
          <Route path="/AdminBlock" element={<AdminBlock />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/AluminiView" element={<AluminiView />} />
          <Route path="/StudentView" element={<StudentView />} />
          {/* <Route path="/JobCategoryMaster" element={<JobCategoryMaster />} /> */}
          <Route path="/JobCategoryMaster" element={<JobCategoryMaster />} />
          <Route path="/Block" element={<Block />} />
          <Route path="/room" element={<Room />} />
          <Route path="/Bed" element={<Bed />} />
          <Route path="/complaint" element={<Complaint />} />
          <Route path="/FeeMaster" element={<FeeMaster />} />
          <Route path="/Agalley" element={<AlumniGallery />} />
          <Route path="/BoardingfeeMaster" element={<BoardingFeeMaster />} />
          <Route path="/JobpostAdmin" element={<JobpostAdmin />} />
          <Route path="/JobApproval" element={<JobApproval />} />
          <Route path="/Alljob" element={<Alljob />} />
          <Route path="/MessLeaveApprove" element={<MessLeaveApprove />} />
          <Route path="/RegularAndEventLeaveApprove" element={<RegularAndEventLeaveApprove />} />
          <Route path="/EventLeaveApprove" element={<EventLeaveApprove />} />
          <Route path="/NavLogo" element={<NavLogo />} />
          <Route path="/WebsiteDashboardImgMaster" element={<WebsiteDashboardImgMaster />} />
          <Route path="/GalleryMaster" element={<GalleryMaster />} />
          <Route path="/BoardingDirectorsImgMaster" element={<BoardingDirectorsImgMaster />} />
          <Route path="/SuggestionAll" element={<SuggestionAll />} />
          <Route path="/ToDoMainAdmin" element={<ToDoMainAdmin />} />
          <Route path="/DonationList" element={<DonationList />} />



          {/* ========================ADMIN REPORT=============================== */}
          <Route path="/AdminDashboardReport" element={<AdminDashboardReport />} />
          <Route path="/ReportNavbar" element={<ReportNavbar />} />
          <Route path="/TodayFeeReport" element={<TodayFeeReport />} />
          <Route path="/TotalFeeReport" element={<TotalFeeReport />} />
          <Route path="/PendingFeeReport" element={<PendingFeeReport />} />
          <Route path="/FeeStatusHostelFee" element={<FeeStatusHostelFee />} />
          <Route path="/TodaysApplicationFormFee" element={<TodaysApplicationFormFee />} />
          <Route path="/TotalApplicationFormFee" element={<TotalApplicationFormFee />} />
          <Route path="/TodayDepositReport" element={<TodayDepositReport />} />
          <Route path="/TotalDepositReport" element={<TotalDepositReport />} />
          <Route path="/TodayDepositReturnReport" element={<TodayDepositReturnReport />} />
          <Route path="/TotalDepositReturnReport" element={<TotalDepositReturnReport />} />
          <Route path="/CarryForwardDeposit" element={<CarryForwardDeposit />} />
          <Route path="/TotalRefundHostel" element={<TotalRefundHostel />} />
          <Route path="/TodaysRefundHostel" element={<TodaysRefundHostel />} />
          <Route path="/VacantRoomBed" element={<VacantRoomBed />} />
          <Route path="/AllBookedRoomBed" element={<AllBookedRoomBed />} />
          <Route path="/NewAdmit" element={<NewAdmit />} />
          <Route path="/ReAdmit" element={<ReAdmit />} />
          <Route path="/MessWiseAttendance" element={<MessWiseAttendance />} />
          <Route path="/EventWiseAttendance" element={<EventWiseAttendance />} />
          <Route path="/BlockWiseStudent" element={<BlockWiseStudent />} />
          <Route path="/AlphabeticalWiseStudent" element={<AlphabeticalWiseStudent />} />
          <Route path="/AlumniReport" element={<AlumniReport />} />
          <Route path="/StudentLeaveReport" element={<StudentLeaveReport />} />
          <Route path="/DischargeReport" element={<DischargeReport />} />
          <Route path="/Discharge" element={<Discharge />} />
          <Route path="/PaymentReceipt" element={<PaymentReceipt />} />
          <Route path="/DonorPage" element={<DonationReport />} />


      

        <Route path="/EventAttendance" element={<EventAttendance />} />

        {/* ===================== Room Booking Design Start==================== */}
        <Route path="/StudentLogin" element={<StudentLogin />} />
        <Route path="/Footer" element={<Footer />} />
        <Route path="/BlockRoomBooking" element={<BlockRoomBooking />} />
        <Route path="/passwordChangeStudent" element={<ChangePasswordStudent />} />
        <Route path="/room/:block_id" element={<RoomBooking />} />{" "}
        {/* Route for Room Booking Page */}
        <Route
          path="/bed/:block_id/:room_id"
          element={<BedBookingPage />}
        />{" "}
        {/* Route for Bed Booking Page */}
        <Route path="/PaymentRoomBooking" element={<PaymentRoomBooking />} />{" "}
        {/* ===================== Room Booking Design End==================== */}



        {/* ===================== Student Dashboard Design Start==================== */}
        <Route path="/Homestudent" element={<Homestudent />} />
        <Route path="/complainstudent" element={<StudentComplaintForm />} />
        <Route path="/DownloadReceipt" element={<DownloadReceipt />} />
        <Route path="/InstallmentPay" element={<InstallmentPay />} />
        <Route path="/passwordChangeStudent1" element={<ChangePasswordStudent1 />} />
        <Route path="/Jobsstudent" element={<Jobs />} />
        <Route path="/Eventstudent" element={<Eventstudent />} />
        <Route path="/FooterAll" element={<FooterAll />} />
        <Route path="/EventLeaveApplication" element={<EventLeaveApplication />} />
        <Route path="/MessLeaveApplication" element={<MessLeaveApplication />} />
        <Route path="/RegularLeaveApplication" element={<RegularLeaveApplication />} />
        <Route path="/MyAttendance" element={<MyAttendance />} />
        <Route path="/StudentProfile" element={<StudentProfile />} />
        <Route path="/StudentDischarge" element={<StudentDischarge />} />
        {/* ===================== Student Dashboard Design End==================== */}


        {/* ===================== First Reg Form Design End==================== */}
        {/* <Route path="/Form" element={<Form />} />
         <Route path="/form1" element={<Form1 />} />
        <Route path="/form" element={<Form />} />
        <Route path="/form2" element={<Form2 />} /> */}
        <Route path="/SideNavbarStudentReg" element={<SideNavbarStudentReg />} /> 
        <Route path="/NavBarStudentReg" element={<NavBarStudentReg />} /> 
        <Route path="/termandcondition" element={<TermCondition />} /> 
        <Route path="/privacypolacy" element={<PrivacyPolacyReg />} /> 
        <Route path="/RegForm" element={<RegForm />} /> 
        <Route path="/RegRulePage" element={<RegRulePage />} /> 
        <Route path="/RegInformationPage" element={<RegInformationPage />} /> 
      
  {/* ===================== First Reg Form Design End==================== */}




        {/* ===================== MESS==================== */}

        <Route path="/BreakfastmenuMaster" element={<BreakfastmenuMaster />} /> 
        <Route path="/FeedBack" element={<FeedBack />} /> 
        <Route path="/MessAttendanceAdmin" element={<MessAttendanceAdmin />} /> 
        <Route path="/MessLeaveApproval" element={<MessLeaveApproval />} /> 
        <Route path="/MessMenuMaster" element={<MessMenuMaster />} /> 
        <Route path="/RequestRec" element={<RequestRec />} /> 
        <Route path="/BreakFastReq" element={<BreakFastReq />} /> 
        <Route path="/MessAttendance" element={<MessAttendance />} /> 
        <Route path="/MessFeedback" element={<MessFeedback />} /> 
        <Route path="/StudentTodaysMenu" element={<StudentTodaysMenu />} /> 




        {/* ===================== ADMIN 2==================== */}

        <Route path="/Admin2Discharge" element={<Admin2Discharge />} /> 





      </Routes>
    </>
  );
}

export default TemplateFileImport;






