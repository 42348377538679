import React, { useState, useEffect } from 'react';
import { Button, Box, Select, MenuItem, Grid, Typography, DialogActions, IconButton, Modal, TextField, FormControl, InputLabel } from '@mui/material';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact'; // Import MDBDataTable here
import Jontable from './Jontable';
import { JobPostAddApi, JobPostAdminDelete, JobPostAdminFindAll, JobPostAdminUpdate, JobPostFindAllApi1, JobPostFindAllApi2 } from '../../ApiFile';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { Col, Row } from 'react-bootstrap';

const JobpostAdmin = () => {
  const [showModal, setShowModal] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [jobPosts, setJobPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [jobTitle, setJobTitle] = useState('');
  const [industryName, setIndustryName] = useState('');
  const [jobType, setJobType] = useState('');
  const [location, setLocation] = useState('');
  const [companyContact, setCompanyContact] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [vacancy, setVacancy] = useState('');
  const [experience, setExperience] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [jobCategoryId, setJobCategoryId] = useState('');
  const [jobImage, setJobImage] = useState(null); // New state for image
  const [currentJobId, setCurrentJobId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [qualification, setQualification] = useState('');
  const [otherQualification, setOtherQualification] = useState(''); 

  useEffect(() => {
    fetchJobPosts();
    fetchCategories();
  }, []);

  const fetchJobPosts = async () => {
    try {
      const response = await axios.get(JobPostAdminFindAll);
      setJobPosts(response.data);
    } catch (error) {
      console.error('Error fetching job posts:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(JobPostFindAllApi1);
      console.log("ghkk",response.data);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    resetForm();
  };

  const handleConfirmOpen = () => setConfirmOpen(true);
  const handleConfirmClose = () => setConfirmOpen(false);

  const resetForm = () => {
    setJobTitle('');
    setIndustryName('');
    setLocation('');
    setJobType('');
    setLocation('');
    setCompanyContact('');
    setCompanyEmail('');
    setVacancy('');
    setExperience('');
    setJobDescription('');
    setJobCategoryId('');
    setJobImage(null); // Reset the image state
    setCurrentJobId(null);
    setQualification(''); // Reset the qualification state
    setOtherQualification(''); 
  };

  const handlePostJob = async () => {
    if (!jobType || !jobTitle || !jobDescription || !experience || !industryName || !companyContact || !companyEmail || !location || !vacancy || !jobCategoryId || !qualification ) {
      toast.error("All fields are required", { position: "top-right", autoClose: 3000 });
      return;
    }

    const formData = new FormData();
    formData.append('job_type', jobType);
    formData.append('flag', 1);
    formData.append('job_title', jobTitle);
    formData.append('discription', jobDescription);
    formData.append('experience', experience);
    formData.append('industry_name', industryName);
    formData.append('contact', companyContact);
    formData.append('email_id', companyEmail);
    formData.append('address', location);
    formData.append('vaccancy', vacancy);
    formData.append('job_category', jobCategoryId);
    formData.append('file', jobImage);
    formData.append('qualification', qualification); // Append the qualification
    formData.append('other_qualification', otherQualification); 

    try {
      if (currentJobId) {
        console.log("id....",`${JobPostAdminUpdate}/${currentJobId}`);
        await axios.put(`${JobPostAdminUpdate}/${currentJobId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Job updated successfully!');
      } else {
        // Add new job post
        await axios.post(JobPostAddApi, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Job posted successfully!');
      }
      fetchJobPosts();
      handleClose();
    } catch (error) {
      toast.error('Failed to save job');
    }
  };

  const handleEditJob = (job) => {
    setJobTitle(job.job_title);
    setIndustryName(job.industry_name);
    setJobType(job.job_type);
    setLocation(job.address);
    setCompanyContact(job.contact);
    setCompanyEmail(job.email_id);
    setVacancy(job.vaccancy);
    setJobDescription(job.discription);
    setExperience(job.experience);
    // setJobImage(job.jobpost_image)
    setJobCategoryId(job.jobcategoryid);
    setCurrentJobId(job.id);
    setQualification(job.qualification); // Set the qualification
    setOtherQualification(job.other_qualification); 
    handleShow();
  };

  const handleDeleteJob = async () => {
    try {
      const response = await axios.delete(`${JobPostAdminDelete}/${deleteId}`);
      if (response.data === 'DELETE') {
        toast.success('Job deleted successfully!');
        fetchJobPosts();
      } else {
        toast.error('Failed to delete job');
      }
      handleConfirmClose();
    } catch (error) {
      toast.error('Failed to delete job');
    }
  };

  const columns = [
    { label: 'S.No', field: 'sno', sort: 'asc' },
    { label: 'Job Title', field: 'jobtitle', sort: 'asc', width:150},
    { label: 'Job Category', field: 'jobCategoryId', sort: 'asc',  width:150},
    { label: 'Job Posted Date', field: 'jobpost_date', sort: 'asc',  width:150},
    { label: 'Employer', field: 'industryname', sort: 'asc', width:150 }, 
    { label: 'Job Type', field: 'jobtype', sort: 'asc', width:150},
    { label: 'Location', field: 'location', sort: 'asc' },
    { label: 'Company Contact', field: 'companycontact', sort: 'asc' , width:200},
    { label: 'Company Email', field: 'companyemail', sort: 'asc' , width:150},
    { label: 'Vacancies', field: 'vacancies', sort: 'asc' },
    { label: 'Experience', field: 'experience', sort: 'asc' },
    { label: 'Qualification', field: 'qualification', sort: 'asc' },
    { label: 'Other Qualification', field: 'other_qualification', sort: 'asc' , width:150},
    {
      label: 'Edit',
      field: 'edit',
      sort: 'asc',
      width: 100,
    },
    {
      label: 'Delete',
      field: 'delete',
      sort: 'asc',
      width: 100,
    },
  ];

  const rows = jobPosts.map((job, index) => ({
    sno: index + 1,
    jobtitle: job.job_title,
    jobCategoryId: job.categoryname,
    jobpost_date: job.jobpost_date,
    industryname: job.industry_name,
    jobtype: job.job_type === 1 ? 'Full-Time' : job.job_type === 2 ? 'Part-Time' : job.job_type === 3 ? 'Internship' : job.job_type === 4 ? 'Contract' : '-',
    location: job.address,
    companycontact: job.contact,
    companyemail: job.email_id,
    vacancies: job.vaccancy,
    experience: job.experience,
    qualification: job.qualification,
    other_qualification: job.other_qualification,
    edit: (
      <IconButton onClick={() => handleEditJob(job)}>
        <EditIcon color="primary" />
      </IconButton>
    ),
    delete: (
      <IconButton onClick={() => {
        setDeleteId(job.id);
        handleConfirmOpen();
      }}>
        <DeleteIcon color="secondary" />
      </IconButton>
    ),
  }));

  const data = { columns, rows };

  return (
    <>
      <Sidebar />
      <section className="home-section pb-5">
        <NavLogo />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Job Post
          </h2>
          <Button
            className="ms-md-5 m-0 ms-3 mb-2 mb-md-4 mt-2 mt-md-4"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleShow}
          >
            Add Job Post
          </Button>
        </div>

        <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable striped className="custom-datatable text-center" bordered hover data={data} />
              </div>
            </div>
          </Col>
        </Row>

        <Modal open={showModal} onClose={handleClose} style={{overflow:"scroll"}}>
          <div style={{ padding: 20, backgroundColor: '#fff', borderRadius: 8, maxWidth: 800, margin: 'auto', marginTop:"5%", marginBottom:"5%" }}>
            <Typography variant="h6" component="h2" align="center" style={{ backgroundColor: "#5F9EA0", color: 'black', padding: 10 }}>
              Job Details
            </Typography>
            <Row container spacing={2}>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Job Title"
                  size='small'
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  inputProps={{ maxLength: 40 }} 
                />
              </Col>
              <Col item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" margin="normal" size='small' required>
                  <InputLabel>Job Category</InputLabel>
                  <Select
                    value={jobCategoryId}
                    onChange={(e) => setJobCategoryId(e.target.value)}
                    label="Job Category"
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.categoryname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Employer"
                  size='small'
                  value={industryName}
                  onChange={(e) => setIndustryName(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  inputProps={{ maxLength: 30 }} 
                  required
                />
              </Col>
              <Col item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" margin="normal" required size='small'>
                  <InputLabel>Job Type</InputLabel>
                  <Select
                    value={jobType}
                    onChange={(e) => setJobType(e.target.value)}
                    label="Job Type"
                  >
                    <MenuItem value={1}>Full-Time</MenuItem>
                    <MenuItem value={2}>Part-Time</MenuItem>
                    <MenuItem value={3}>Internship</MenuItem>
                    <MenuItem value={4}>Contract</MenuItem>
                  </Select>
                </FormControl>
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Qualification"
                   size='small'
                   className='mt-2'
                   required
                  inputProps={{ maxLength: 40 }} 
                  variant="outlined"
                  value={qualification}
                  onChange={(e) => setQualification(e.target.value)}
                />
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Other Qualification"
                        className='mt-2'
                   size='small'
                  inputProps={{ maxLength: 40 }} 
                  variant="outlined"
                  value={otherQualification}
                  onChange={(e) => setOtherQualification(e.target.value)}
                />
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Location"
                  size='small'
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  variant="outlined"
                  inputProps={{ maxLength: 60 }} 
                  margin="normal"
                  required
                />
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Company Contact"
                  value={companyContact}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setCompanyContact(value);
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  inputProps={{ 
                    maxLength: 10, 
                    pattern: "[0-9]*",
                    inputMode: 'numeric' 
                  }}
                />
              </Col>

              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company Email"
                  size='small'
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                />
              </Col>
              <Col item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Vacancies"
                size="small"
                value={vacancy}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setVacancy(value);
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                inputProps={{
                  inputMode: 'numeric', // Brings up the numeric keypad on mobile devices
                  pattern: '[0-9]*'     // Ensures only numeric input is allowed
                }}
              />
            </Col>

              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Experience"
                  size='small'
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  variant="outlined"
                  inputProps={{ maxLength: 20 }} 
                  margin="normal"
                  required
                />
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="file"
                   label="Ref Image If"
                  size='small'
                  onChange={(e) => setJobImage(e.target.files[0])}
                  variant="outlined"
                  margin="normal"
                />
              </Col>
              <Col item xs={12}>
                <TextField
                  fullWidth
                  label="Job Description"
                  size='small'
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  inputProps={{ maxLength: 90 }} 
                  multiline
                  rows={3}
                  required
                />
              </Col>
           
            </Row>
            <DialogActions>
              <Button onClick={handleClose} style={{backgroundColor:"rgb(156, 39, 176)" , color:"white"}}>Cancel</Button>
              <Button onClick={handlePostJob} style={{backgroundColor:"rgb(0, 120, 215)",color:"white"}}>
                {currentJobId ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </div>
        </Modal>
        <Modal open={confirmOpen} onClose={handleConfirmClose}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            borderRadius: '5px',
            width: '90%',
            maxWidth: '500px',
          }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Are you sure you want to delete this job post?
            </Typography>
            <DialogActions>
              <Button onClick={handleConfirmClose} color="secondary">Cancel</Button>
              <Button onClick={handleDeleteJob} color="primary">Confirm</Button>
            </DialogActions>
          </Box>
        </Modal>
      </section>
    </>
  );
};

export default JobpostAdmin;
