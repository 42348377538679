import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RoomBookingAddApi, StudentInstallmentPayApi } from '../../ApiFile';
import Sidebar from './sidebarstudent';
import Header from './Headerstudent';
import qrcode from '../../StudentRegristrationDesign/JainBoardingQr.jpeg';
import { Button } from '@mui/material';

const InstallmentPay = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const memberId = sessionStorage.getItem('member_id');
  const [errors, setErrors] = useState({});

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const downloadQRCode = () => {
    const link = document.createElement('a');
    link.href = qrcode;
    link.download = <img src={qrcode} className='w-80 text-center px-md-3 px-1 mb-3 mb-md-0' height={250} />;
    link.click();
  };

  useEffect(() => {
    const fetchInstallmentData = async () => {
      try {
        const response = await fetch(StudentInstallmentPayApi, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ member_id: memberId }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();
        if (jsonData !== 'Already payment done') {
          setData(jsonData[0]);
        } else {
          setData(null);
        }
        console.log('Fetched Data:', jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInstallmentData();
  }, [memberId]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('member_id', memberId);
    formData.append('file', file);
    formData.append('taxid', inputValue);
    formData.append('pay_amount', data?.receive_amount);
    formData.append('pay_mode', paymentMode);
    formData.append('block_id', data?.block_id);
    formData.append('room_id', data?.room_id);
    formData.append('bed_id', data?.bed_id);

    for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }
    try {
      const res = await axios.post(RoomBookingAddApi, formData);

      if (res.data == 'SAVED') {
        toast.success('Payment Done, Wait For Admin Approval !!!', {
          position: 'top-right',
          autoClose: 3000,
        });
        setInputValue('');
        setPaymentMode('');
        setFile(null);
        navigate('/DownloadReceipt');
      } else if (res.data === 'Already Payment done') {
        toast.warn('Already payment done !!!!!', {
          position: 'top-right',
          autoClose: 3000,
        });
      } else {
        toast.error('Please try again', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Error submitting payment:', error);
      toast.error('Error submitting payment, please try again', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <Header />
        <div className="bed-booking px-md-5 pb-5 px-2 mt-md-5 mt-3">
          <h3 className="main-title1">
            <p style={{ borderBottom: '1px solid maroon', color: 'maroon' }}>Pending Payment</p>
          </h3>

          {data ? (
            <Row style={{ display: 'flex' }}>
              <Col md={2}></Col>
              <Col md={8}>
                <Row style={{ display: 'flex' }}>
                  <Col sm={12} md={3}>
                    <p>
                      <b style={{ color: 'gray', borderBottom: '1px solid maroon' }}>Amount</b>: {data?.receive_amount}
                    </p>
                  </Col>
                  <Col sm={12} md={3}>
                    <p>
                      <b style={{ color: 'gray', borderBottom: '1px solid maroon' }}>Block No</b>: {data?.block_id
                      }
                    </p>
                  </Col>
                  <Col sm={12} md={3}>
                    <p>
                      <b style={{ color: 'gray', borderBottom: '1px solid maroon' }}>Room No</b>: {data?.room_id
                      }
                    </p>
                  </Col>
                  <Col sm={12} md={3}>
                    <p>
                      <b style={{ color: 'gray', borderBottom: '1px solid maroon' }}>Bed No</b>: {data?.bed_id}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md={2}></Col>
            </Row>
          ) : (
            <div>No data found</div>
          )}

          <div className="text-center mt-3">
            <img src={qrcode} alt="QR Code" style={{ height: '130px', width: '130px' }} />
          </div>
          <div className="text-center">
                        <Button className='mt-3 mt-md-2' style={{background:"rgb(57, 158, 161)",color:"white",border:"0px"}} onClick= {downloadQRCode}>
                    Download QR Code
                  </Button></div>
          <Row className="mt-3">
            <Col md={6}>
              <div>
              <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        UPI ID :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>getepay.svccblqr432819@icici</p>
                      </Col>
                      </Row>
                <Row className="mt-2">
                  <Col md={4}>
                    <label>Account Name:</label>
                  </Col>
                  <Col md={8}>
                    <p>MS.SHETH R D DAVADA DIGAMBER JAIN BORDING.SANGLI</p>
                  </Col>
                </Row>
                <Row className="mt-md-2 mt-2">
                  <Col md={4}>
                    <label>Account No:</label>
                  </Col>
                  <Col md={8}>
                    <p>1000312010001901</p>
                  </Col>
                </Row>
                <Row className="mt-md-2 mt-2">
                  <Col md={4}>
                    <label>Bank Name/Branch:</label>
                  </Col>
                  <Col md={8}>
                    <p>RBL BANK / Sangli Main</p>
                  </Col>
                </Row>
                <Row className="mt-md-2 mt-2">
                  <Col md={4}>
                    <label>IFSC Code:</label>
                  </Col>
                  <Col md={8}>
                    <p>RATN0000003</p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <Row style={{ display: 'flex' }}>
                <Row className="mt-md-3 mt-2" style={{ display: 'flex' }}>
                  <Col md={5}>
                    <label className="mt-2" htmlFor="transactionId">Pay Amount:</label>
                  </Col>
                  <Col md={7}>
                    <input value={data?.receive_amount} readOnly className="w-100" />
                  </Col>
                </Row>

                <Row className="mt-md-3 mt-2" style={{ display: 'flex' }}>
                  <Col md={5}>
                    <label className="mt-2">Payment Mode:</label>
                  </Col>
                  <Col md={7}>
                    <select
                      id="paymentMode"
                      style={{ height: '39px' }}
                      required
                      className={`w-100 rounded ${errors.paymentMode ? 'error-input' : ''}`}
                      value={paymentMode}
                      onChange={(e) => setPaymentMode(e.target.value)}
                    >
                      <option value="">Select Payment Mode</option>
                      <option value="1">Online</option>
                      <option value="2">Cash</option>
                    </select>
                    {errors.paymentMode && (
                      <div className="error-message">{errors.paymentMode}</div>
                    )}
                  </Col>
                </Row>

                <Row className="mt-md-3 mt-2" style={{ display: 'flex' }}>
                  <Col md={5}>
                    {paymentMode === '1' ? (
                      <label className="mt-2" htmlFor="transactionId">Transaction ID:</label>
                    ) : (
                      <label className="mt-2" htmlFor="transactionId">Cash Receipt No:</label>
                    )}
                  </Col>
                  <Col md={7}>
                    <input
                      type="text"
                      className="w-100"
                      id="transactionId"
                      placeholder={
                        paymentMode === '1' ? 'Enter transaction ID...' : 'Enter Cash Receipt No...'
                      }
                      value={inputValue}
                      name="taxid"
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row className="mt-md-3 mt-2" style={{ display: 'flex' }}>
                  <Col md={5}>
                    <label className="mt-2" htmlFor="fileUpload">Upload Paid Image:</label>
                  </Col>
                  <Col md={7}>
                    <input
                      type="file"
                      className="w-100"
                      id="fileUpload"
                      onChange={handleFileChange}
                    />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
          <div className="mb-5 mt-3 mt-md-5" style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              className="fs-5 ms-2 text-center btn btn-primary nextBtn"
              style={{ backgroundColor: 'rgb(23, 160, 160)', color: 'black', width: '120px', border: '0px' }}
              disabled={!inputValue || !file || !paymentMode}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default InstallmentPay;
