import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select } from '@mui/material';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import { Visibility, Delete } from '@mui/icons-material';
import { DirectorAddApi, DirectorDeleteApi, DirectorFindAllApi, DirectorUpdateApi, ImagePath } from '../../ApiFile';
import { toast } from 'react-toastify';

const BoardingDirectorsImgMaster = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({ fname: '', mname: '', lname: '', designation: '' });
  const [editMode, setEditMode] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [viewImg, setViewImg] = useState(null);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(DirectorFindAllApi);
      const images = response.data.map((img, index) => ({
        serial_no: index + 1,
        id: img.id,
        name: `${img.fname} ${img.mname} ${img.lname}`,
        designation: img.designation || '',
        upload_img: <img src={`${ImagePath}/${img.upload_img}`} alt={`img-${img.id}`} style={{ width: '100px' }} />,
        view: <Button variant="contained" onClick={() => handleView(img.upload_img)}> <Visibility fontSize="small" /></Button>,
        edit: <Button variant="contained" style={{ color: "white", backgroundColor: "orange" }} onClick={() => handleEdit(img)}> <UpdateIcon /></Button>,
        delete: <Button variant="contained" color="secondary" onClick={() => openDeleteDialog(img.id)}> <DeleteIcon /></Button>,
      }));
      setData(images);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleAddRule = () => {
    setSelectedFile(null);
    setFormData({ fname: '', mname: '', lname: '', designation: '' });
    setEditMode(false);
    setOpen(true);
  };

  const handleEdit = (img) => {
    setCurrentId(img.id);
    setFormData({
      fname: img.fname || '',
      mname: img.mname || '',
      lname: img.lname || '',
      designation: img.designation || ''
    });
    setSelectedFile(null); // Clear selected file when editing
    setEditMode(true);
    setOpen(true);
  };

  const openDeleteDialog = (id) => {
    setDeleteId(id);
    setDeleteOpen(true);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${DirectorDeleteApi}/${deleteId}`);
      if (response.data === "DELETE") {
        toast.success('Data deleted successfully');
      } else if (response.data === "NOTFOUND") {
        toast.error('Data not found');
      } else {
        toast.error('Error deleting Data');
      }
      fetchImages();
      setDeleteOpen(false);
    } catch (error) {
      console.error('Error deleting Data:', error);
      toast.error('Error deleting Data');
    }
  };

  const handleView = (imgPath) => {
    setViewImg(`${ImagePath}/${imgPath}`);
    setViewOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setViewOpen(false);
    setDeleteOpen(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {

    if (!formData.fname) {
      toast.error('Please enter First Name');
      return;
    }
    if (!formData.mname) {
      toast.error('Please enter Middle Name');
      return;
    }
    if (!formData.lname) {
      toast.error('Please enter Last Name');
      return;
    }
    if (!formData.designation) {
      toast.error('Please enter Designation');
      return;
    }
    // if (!selectedFile) {
    //   toast.error('Please select Image'); 
    //   return;
    // }
  
    const newFormData = new FormData();
    newFormData.append('file', selectedFile);
    newFormData.append('fname', formData.fname);
    newFormData.append('mname', formData.mname);
    newFormData.append('lname', formData.lname);
    newFormData.append('designation', formData.designation);

    try {
      if (editMode) {
        const response = await axios.put(`${DirectorUpdateApi}/${currentId}`, newFormData);
        if (response.data === "UPDATE") {
          toast.success('Data updated successfully');
        } else {
          toast.error('Error updating Data');
        }
      } else {
        const response = await axios.post(DirectorAddApi, newFormData);
        if (response.data === "SAVED") {
          toast.success('Data saved successfully');
        } else {
          toast.error('Error saving Data');
        }
      }
      fetchImages();
      handleClose();
    } catch (error) {
      console.error('Error saving Data:', error);
      toast.error('Error saving Data');
    }
  };

  const columns = [
    { label: 'Serial No', field: 'serial_no', sort: 'asc' },
    { label: 'Name', field: 'name', sort: 'asc' },
    { label: 'Designation', field: 'designation', sort: 'asc' },
    { label: 'View', field: 'view', sort: 'asc' },
    { label: 'Edit', field: 'edit', sort: 'asc' },
    { label: 'Delete', field: 'delete', sort: 'asc' },
  ];

  const tableData = {
    columns: columns,
    rows: data
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div>
          <div className="block-header">
            <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
              Director Master
            </h2>
            <Button
              className="ms-md-5 m-0 ms-3 mb-2 mb-md-4 mt-2 mt-md-4"
              variant="contained"
              color="primary"
              onClick={handleAddRule}
            >
              Add
            </Button>
          </div>
          <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
            <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
              <div style={{ overflowY: 'auto' }}>
                <div style={{ overflowX: 'auto' }}>
                  <MDBDataTable striped className="custom-datatable" bordered hover data={tableData} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <Dialog open={open} onClose={handleClose} style={{ border: "1px solid maroon" }}>
        <DialogTitle style={{ color: "maroon", borderBottom: "1px solid maroon" }} variant='h5' className='p-0 py-2 mb-4 px-2 bold'>{editMode ? 'Edit Director Master' : 'Add Director Master'}</DialogTitle>
        <DialogContent>
            {/* <DialogContentText className='mb-3 pt-3 text-dark' variant='h6'>
                {editMode ? 'Edit the selected image.' : 'Upload a new image.'}
            </DialogContentText> */}
           
            <TextField
                margin="dense"
                name="fname"
                size='small'
                label="First Name"
                type="text"
                fullWidth
                value={formData.fname}
                onChange={handleInputChange}
            />
            <TextField
                margin="dense"
                name="mname"
                size='small'
                label="Middle Name"
                type="text"
                fullWidth
                value={formData.mname}
                onChange={handleInputChange}
            />
            <TextField
                margin="dense"
                name="lname"
                size='small'
                label="Last Name"
                type="text"
                fullWidth
                value={formData.lname}
                onChange={handleInputChange}
            />
            <TextField
                margin="dense"
                name="designation"
                label="Designation"
                size='small'
                type="text"
                fullWidth
                value={formData.designation}
                onChange={handleInputChange}
            />
  <label className='mt-2'>Select Image :</label>
           <input
                accept="image/*"
                className='w-100 '
                style={{ borderRadius:"3px", height:"38px" }}
                id="contained-button-file"
                type="file"
                // {selectedFile ? selectedFile.name : 'Choose File'}
                onChange={handleFileChange}
            />
            {/* <label htmlFor="contained-button-file">
                <Button variant="contained" component="span">
                {selectedFile ? selectedFile.name : 'Choose File'}
                </Button>
            </label> */}
            </DialogContent>
        <DialogActions style={{ borderTop: "1px solid maroon" }}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {editMode ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={viewOpen} onClose={handleClose}>
        <DialogTitle style={{ color: "maroon", borderBottom: "1px solid maroon" }} variant='h5' className='p-0 py-2 px-2 bold'>View Image</DialogTitle>
        <DialogContent>
          <img src={viewImg} alt="view" style={{ width: '100%' }} />
        </DialogContent>
        <DialogActions style={{ borderTop: "1px solid maroon" }}>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteOpen} onClose={handleClose}>
        <DialogTitle style={{ color: "maroon", borderBottom: "1px solid maroon" }} variant='h5' className='p-0 py-2 px-2 bold'>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText className='pt-3 text-dark'>Are you sure you want to delete this image?</DialogContentText>
        </DialogContent>
        <DialogActions style={{ borderTop: "1px solid maroon" }}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default BoardingDirectorsImgMaster;
