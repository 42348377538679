import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineMeetingRoom } from "react-icons/md";
import Button from "react-bootstrap/Button";
import "../Room Master/room.css";
import Header from './Header';
import Sidebar from './sidebar';
import { BlockFindAllApi, ImagePath, RoomAllocateListApi } from "../../ApiFile";

const RoomBooking = () => {
  const navigate = useNavigate();
  const { block_id } = useParams();
  const [rooms, setRooms] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRooms = async () => {
      setLoading(true);
      try {
        const response = await fetch(RoomAllocateListApi, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ block_id }), // Pass block_id in the request body
        });
        if (!response.ok) {
          throw new Error('Failed to fetch rooms');
        }
        const data = await response.json();
        if (!data[0] || !data[0].hasOwnProperty('room_range')) {
          throw new Error('Invalid response format: room_range is missing');
        }
        const roomNumbers = data[0].room_range.split(',');
        const fetchedRooms = roomNumbers.map((number, index) => ({
          id: index + 1,
          number: number.trim(),
          occupied: false,
          room_img: data[0].room_img, // Add the room image here
          bed_img: data[0].bed_img   // Add the bed image here
        }));
        setRooms(fetchedRooms);
      } catch (error) {
        setError(error);
        setRooms([]); // Reset rooms to empty array on error
      } finally {
        setLoading(false);
      }
    };
    
    fetchRooms(); // Call fetchRooms when component mounts or block_id changes
  }, [block_id]);

  const handleRoomSelect = (room_id) => {
    const selectedRoom = rooms.find(room => room.number === room_id);
    if (!selectedRoom.occupied) {
      navigate(`/bed/${block_id}/${room_id}`);
    }
  };

  const handlePreviousClick = () => {
    navigate(`/?block_id=${block_id}`);
  };
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    fetchBlocks();
  }, []);

  const fetchBlocks = async () => {
    try {
      const response = await fetch(BlockFindAllApi);
      if (!response.ok) {
        throw new Error('Failed to fetch blocks');
      }
      const data = await response.json();
      setBlocks(data);
    } catch (error) {
      console.error('Error fetching blocks:', error);
    }
  };

  return (
    <>
      <Sidebar />
      <section className="home-section pb-5">
        <Header /> 

        <div className="room-booking pb-5">
          <h2 className="main-title1">Select the Room</h2>
       
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="error-message">Error fetching rooms: {error.message}</p>
          ) : (
            <div className="room-container-wrapper d-flex row ">
              <div className="room-container main-cards1 col-md-8">
                {rooms.length === 0 ? (
                  <p>No rooms available</p>
                ) : (
                  rooms.map(room => (
                    <div
                      key={room.id}
                      className={`room-card ${room.occupied ? "occupied" : "unoccupied"}`}
                      onClick={() => handleRoomSelect(room.number)}
                    >
                      <div className="room-card-inner">
                        <MdOutlineMeetingRoom size={28} className="room-icon" />
                        <span className="room-label">Room {room.number}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="col-md-4 d-flex justify-content-center align-items-center">
                {rooms.length > 0 && (
                  <img 
                    src={`${ImagePath}/${rooms[0].bed_img}`} 
                    alt={rooms[0].bed_img}
                    className="rounded shadow" 
                    style={{ mixBlendMode: "darken", height: "200px", width: "250px" }} 
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default RoomBooking;

