// import React, { useState, useEffect } from 'react';
// import { EventFindApi, StudentEventAttendance } from '../../ApiFile';
// import Sidebar from './sidebarstudent';
// import Header from './Headerstudent';
// import { MDBDataTable } from 'mdbreact';
// import { Col, Row } from 'react-bootstrap';
// import { Button } from '@mui/material';

// function Eventstudent() {
//     const [events, setEvents] = useState([]);
//     const memberId = sessionStorage.getItem('member_id');
//     const [totalPresent, setTotalPresent] = useState(0);
//     const [totalAbsent, setTotalAbsent] = useState(0);
//     const [totalEvents, setTotalEvents] = useState(0); // State for total events

//     useEffect(() => {
//         const fetchEvents = async () => {
//             try {
//                 const response = await fetch(`${StudentEventAttendance}/${memberId}`);
//                 if (response.ok) {
//                     const data = await response.json();
//                     setEvents(data);
//                     calculateAttendanceCounts(data);
//                 } else {
//                     console.error('Error fetching events: Response not ok');
//                 }
//             } catch (error) {
//                 console.error('Error fetching events:', error);
//             }
//         };

//         fetchEvents();
//     }, []);

//     const calculateAttendanceCounts = (events) => {
//         let presentCount = 0;
//         let absentCount = 0;

//         events.forEach(event => {
//             if (event.attendance_flag == 1) {
//                 presentCount += 1;
//             } else if (event.attendance_flag == 2) {
//                 absentCount += 1;
//             }
//         });

//         setTotalPresent(presentCount);
//         setTotalAbsent(absentCount);
//         setTotalEvents(events.length); // Update total events count
//     };

//     const data = {
//         columns: [
//             {
//                 label: 'Serial No',
//                 field: 'serialNo',
//                 sort: 'asc',
//                 width: 50
//             },
//             {
//                 label: 'Event Name',
//                 field: 'name',
//                 sort: 'asc',
//                 width: 150
//             },
//             {
//                 label: 'Date',
//                 field: 'event_date',
//                 sort: 'asc',
//                 width: 270
//             },
//             {
//                 label: 'Attendance',
//                 field: 'attendance_flag',
//                 sort: 'asc',
//                 width: 100
//             },
//         ],
//         rows: events.map((event, index) => ({
//             serialNo: index + 1,
//             name: event.discription,
//             event_date: event.event_date,
//             attendance_flag: event.attendance_flag == 1 ? (
//                 <Button style={{ backgroundColor: 'green', color: "white" }}>
//                     Present
//                 </Button>) : event.attendance_flag == 2 ? (
//                     <Button style={{ backgroundColor: 'red', color: "white" }}>
//                         Absent
//                     </Button>) : ""
//         }))
//     };

//     return (
//         <>
//             <Sidebar />
//             <section className="home-section">
//                 <Header />
//                 <div className="container mt-5 mb-5">
//                 <Row className="m-0 pt-1 px-2 px-md-3 mb-2 rounded">
//                         <Col className="m-0 p-0 px-3 py-1 rounded" style={{ background: 'white' }}>
// <h4 style={{color:"maroon"}}>My Event Attendance</h4>
//                         </Col>
//                     </Row>
//                     <Row className="m-0 px-2 px-md-3 mb-5 rounded">
//                         <Col className="m-0 p-0 px-3 py-2 rounded" style={{ background: 'white' }}>
//                             <div style={{ overflowY: 'auto' }}>
//                                 <div style={{ overflowX: 'auto' }}>
//                                     <MDBDataTable
//                                         striped
//                                         bordered
//                                         className='custom-datatable text-center'
//                                         small
//                                         data={data}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="text-center rounded py-2" style={{display:"flex",alignItems:"center",justifyContent:"space-around",border:"1px solid rgb(214, 222, 219)"}}>
//                                 <div style={{color:"maroon"}}>Total Events Conducted: {totalEvents}</div>
//                                 <div style={{color:"green"}}>Total Present: {totalPresent}</div>
//                                 <div style={{color:"red"}}>Total Absent: {totalAbsent}</div>
//                             </div>
//                         </Col>
//                     </Row>
//                 </div>
//             </section>
//         </>
//     );
// }

// export default Eventstudent;






import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Sidebar from './sidebarstudent';
import Header from './Headerstudent';
import { Col, Row } from 'react-bootstrap';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { MDBDataTable } from 'mdbreact';
import { EventDashboardApi, EventDateSelectAttendance } from '../../ApiFile';

const localizer = momentLocalizer(moment);

function Eventstudent() {
  const [events, setEvents] = useState([]);
  const [EventTables, setEventTable] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(EventDateSelectAttendance);
        if (response.ok) {
          const data = await response.json();
          const formattedEvents = data.map(event => ({
            title: event.discription,
            start: moment(`${event.event_date} ${event.starttime}`, 'DD/MM/YYYY HH:mm:ss').toDate(),
            end: moment(`${event.event_date} ${event.starttime}`, 'DD/MM/YYYY HH:mm:ss').add(1, 'hours').toDate(),
            id: event.event_id,
          }));
          setEvents(formattedEvents);
        } else {
          console.error('Error fetching events: Response not ok');
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    fetch(EventDateSelectAttendance)
      .then(response => {
        if (!response.ok) {
          throw new Error('NOTFOUND');
        }
        return response.json();
      })
      .then(data => {
        setEventTable(data);
      })
      .catch(error => {
        if (error.message === 'NOTFOUND') {
          setError('Resource not found');
        } else {
          setError('Add Event');
        }
      });
  }, [EventDateSelectAttendance]);

  const tableData = {
    columns: [
      {
        label: 'Serial No',
        field: 'sn',
        sort: 'asc'
      },
      {
        label: 'Event Description',
        field: 'discription',
        sort: 'asc'
      },
      {
        label: 'Event Date',
        field: 'event_date',
        sort: 'asc'
      },
      {
        label: 'Start Time',
        field: 'starttime',
        sort: 'asc'
      }
    ],
    rows: EventTables.map((EventTable, index) => ({
      sn: index + 1,
      discription: EventTable.discription,
      event_date: EventTable.event_date,
      starttime: EventTable.starttime,
    }))
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <Header />
        <div className='mt-2'>
          <Row className="m-0 pt-1 mb-2 px-2 px-md-3 rounded">
            <Col className="m-0 p-0 px-3 py-1 rounded" style={{ background: 'white' }}>
              <h4 style={{ color: "maroon" }}>Event Calendar</h4>
            </Col>
          </Row>
          <Row className="m-0  px-2 px-md-3 mb-5 rounded">
            <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
              <Calendar
                className='w-100 p-md-5'
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
              />
            </Col>
          </Row>

          <Row className="m-0 px-md-1 py-1 pb-5 mt-3 w-100" style={{ display: 'flex' }}>
            <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
              <h3 className="text-center" style={{ color: "maroon" }}>Events List</h3>
              <MDBDataTable
                striped
                bordered
                className="custom-datatable"
                small
                data={tableData}
              />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default Eventstudent;


