import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import "./boardings.css";
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { BlockFindallApi, RoomDetailsApi } from '../../ApiFile';
import { Col, Row } from 'react-bootstrap';


function Boarding(){
  const [blocks, setBlocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        const response = await axios.get(RoomDetailsApi);
        console.log("console.log..",response.data);
        setBlocks(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchBlocks();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
    return(
       <div>
        <Navbar></Navbar>
        
        {/* Navbar & Hero End */}
        {/* Header Start */}
        <div className="container-fluid bgg-breadcrumbR">
          <div className="container text-center py-5" style={{maxWidth: '900px'}}>
            <h3 className="text-white display-3 mb-4" style={{textShadow: '2px 2px #ee1515'}}>Boarding</h3><br />
            <a className="btn-hover-bg btn btn-primary1 rounded-pill text-white py-3 px-5" href="/#/StudentLogin" style={{backgroundColor: '#ee1515'}}>Room Booking</a>
          </div>
        </div>
        {/* Header End */}
        {/* boarding Start */}
        <div className="container-fluid about py-4" style={{marginTop: '50px'}}>
          <div className="row">
            <div className="col-lg-12"><div className='text-center'>
              <h5 className="section-title text-center">About Boarding</h5></div>
              <br /><br />
              <p  style={{padding: '7px'}}>
                The Valuable efforts of the leading social workers of Dakshin Bharat Jain Sabha and local merchants
                of Sangli Jain Boarding was started in Panjarpol committee’s building in 1911/10 with the auspicious
                hands of Sheth Manikchand Hirachand Javeri of Mumbai.
                This boarding was shifted to another place in as 1912 as Panjarpol Committee demanded its place
                (building) for own use.
                Now this boarding is situated in 37, Mahaveernagar, on Sangli Highschool Road. The construction of
                two strayed boarding house building took place in 1918 and completed in 1922. The land for
                construction was donated by Sheth Govinddas Shethji of Sangli and amount of Rs. 20,000 by sheth
                Ramchandra Dhanaji Dawada of Natepute.
                This boarding was named as sheth Ramchand Dhanaji Dawada Diagambar Jain Boarding, Sangli. Phase wise
                development (construction of hostel buildings) took place as number of students increased year after
                year. Today there are 62 rooms in three different wings with administration wing, meeting hall,
                temple, mess, library hall etc. and 160 Students of different faculty are residing in this boarding
                house.
              </p><p style={{padding: '7px', justifyContent: 'center'}}>
                Two commercial complexes supporting the running of the boarding house.
                The number of generous personalities provided funds to this boarding house for construction and over
                all development.
                Dakshin Bharat Jain Sabha’s Administrative office, Veer Seva Dal &amp; Graduates’ Association’s offices
                are also situated in this premises only. Late Hon’ble Annasaheb Rajmane and other personalities
                administered the boarding house and supported for all round development of the students with their
                auspicious hands.
                Hon’ble Dr. A. N. Upadhye, Hon’ble Justice T.K.Tukol and the well-known personalities were the
                students of this boarding.</p>
              <p  style={{padding: '7px', justifyContent: 'center'}}>
                This boarding was and even today the shelter for the students of this area, as Sangli is an
                educational as well as cultural Centre. The students feel secured in this boarding house.</p>
              <p />
            </div>
          </div>
        </div><br/><br/>
        {/* About End */}
        {/* Services Start */}
        <div className="container-fluid bg-light service py-5" data-aos="fade-up" data-aos-delay={400} data-aos-duration={400}>
          <div className="container py-5 text-center">
            <h5 className="section-title text-center">Our Services</h5>
          </div>
          <div className="row g-4" data-aos-delay={800} data-aos-duration={400}>
            <div className="col-lg-6">
              <div className="row g-4">
                <div className="col-12" data-aos="fade-up" data-aos-delay={1000} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 pe-0">
                    <div className="service-icon p-4">
                      <i className="fa fa-utensil-spoon fa-3x text-primary" />
                    </div>
                    <div className="service-content text-end">
                      <h5 className="mb-4">Annapurna Mess</h5>
                    </div>
                  </div>
                </div>
                <div className="col-12" data-aos="fade-up" data-aos-delay={1300} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center  bg-white border border-primary rounded p-4 pe-0">
                    <div className="service-icon p-4">
                      <i className="fa fa-hotel fa-3x text-primary" />
                    </div>
                    <div className="service-content text-end">
                      <p className="mb-0">
                      </p><h5 className="mb-4">Library</h5>
                      <p />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-4">
                <div className="col-12" data-aos="fade-up" data-aos-delay={1500} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 ps-0">
                    <div className="service-icon p-4">
                      <i className="fa fa-dumbbell fa-3x text-primary" />
                    </div>
                    <div className="service-content">
                      <p className="mb-0">
                      </p><h5 className="mb-4">Gym</h5>
                      <p />
                    </div>
                  </div>
                </div>
                <div className="col-12" data-aos="fade-up" data-aos-delay={1700} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 ps-0">
                    <div className="service-icon p-4">
                      <i className="fas fa-door-open fa-3x text-primary" />
                    </div>
                    <div className="service-content">
                      <p className="mb-0">
                      </p><h5 className="mb-4">Room</h5>
                      <p />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container py-5 text-center">
          <h5 className="section-title text-center">Rooms</h5>
        </div>
       

        <Row className='px-md-4 px-2'>
        {blocks.map(block => (
      <Col sm={12} md={3} className='p-4' key={block.id}>
      <div className='rounded text-center' style={{border:"1px solid #18528b"}}>
      <div className='ms-3 '  style={{zIndex:"-1",marginTop:"-15px",backgroundColor:"rgb(24, 82, 139)",width:"90%",borderRadius:"3px"}}>
      <h3 className='text-center text-light' style={{fontFamily:"cursive"}}>Block  {block.block_id}</h3></div>
      <div className='mt-3'>
      <b style={{ color: 'black' }} >Facilities: </b>  {block.discription}
      </div>
      <div className='mt-3'>
      <b style={{ color: 'black' }} >Deposit: </b>  {block.deposit}
      </div>
      <div className='mt-3'>
      <b style={{ color: 'green' }} >Fees:  </b>{block.total}
      </div>
     

      <p className=" text-center pt-4">
              <Link to="/StudentLogin">
                <button className="btn btn-primary b1 ms-0" style={{ color: 'white' }}>
                  Book Now
                </button>
              </Link>
            </p>
</div>
      </Col>
    ))}
    </Row>

       
        <Footer/>
        {/* Copyright End */}
        {/* Back to Top */}
        {/* <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up" /></a> */}
        {/* JavaScript Libraries */}
        {/* Template Javascript */}
        {/* boarding Javascript */}
      </div>
      

    )
}
export default Boarding;