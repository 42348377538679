import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Sidebar from '../sidebar';
import NavLogo1 from '../../StudentRegristrationDesign/blockImage.jpg'
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'react-toastify';
import { CheckBox } from '@mui/icons-material';
import NavLogo from '../NavLogo';
import { ApproveRegularLeaveFindall, ApproveRegularLeaveFindone, ApproveRegularLeavestatus, ImagePath, MemberAdminApprovalApi, MemberAdminViewApi, MemberApprovalApi } from '../../ApiFile';



export default function RegularAndEventLeaveApprove() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedPayType, setSelectedPayType] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [statusRow, setStatusRow] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);

  const handleCheckBoxChange = () => {
    setCheckBoxChecked(!checkBoxChecked);
  };

  function capitalizeWords(str) {
    return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  }

  const fetchData = () => {
    axios.get(ApproveRegularLeaveFindall)
      .then(response => {
        const mappedRows = response.data.map((row, index) => ({
          ...row,
          Name: row.name,
          PayAmount:row.pay_amount,
        }));
        setRows(mappedRows);
        // console.log("mapped rows: ", mappedRows);
      })
      .catch(error => {
        // console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleApproval = () => {
    if (!checkBoxChecked) {
      alert("Please select the checkbox before proceeding.");
      return;
    }

    axios.put(`${ApproveRegularLeavestatus}/${selectedRow.id}/${1}`)
      .then(res => {
        // console.log(res);
        if (res.data == 'APPROVAL') {
   
          toast.success("Approved Successfully..!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        
          setCheckBoxChecked('')
          fetchData(); 
          setStatusModalOpen(false);
        }
      })
      .catch((err) => {
        // console.error("Failed to save data:", err);
        toast.error("Failed...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  const handleDelete = () => {
    if (!checkBoxChecked) {
      // Display toast or alert when checkbox is not checked
      alert("Please select the checkbox before proceeding.");
      return;
    }

    axios.put(`${ApproveRegularLeavestatus}/${selectedRow.id}/${2}`)
      .then(res => {
        // console.log(res);
        setDialogOpen(false);
        if (res.data === "REJECT") {
          // console.log("Rejected successfully:", res.data);
          toast.success("Rejected Successfully..!", {
            position: "top-right", 
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setStatusModalOpen(false)
          setCheckBoxChecked('')
          fetchData(); // Refresh data after rejection
        }
      })
      .catch((err) => {
        // console.error("Failed to save data:", err);
        toast.error("Failed...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  const handleViewClick = (row) => {
    setSelectedRow(row || {});
    setOpen(true);

    axios.get(`${ApproveRegularLeaveFindone}/${row.id}`)
      .then(response => {
        // console.log("findOne API response: ", response);
        setSelectedRow(response.data[0]);
        // console.log("selected row: ", response.data);
      })
      .catch(error => {
        // console.error(error);
      });
  };

  const handleDeleteConfirmation = (id) => {
    if (!checkBoxChecked) {
      alert("Please select the checkbox before proceeding.");
      return;
    }
    setRowToDelete(id);
    setDialogOpen(true);
  };



  const handleStatusModalOpen = (row) => {
    setStatusRow(row);
    handleViewClick(row);
    setStatusModalOpen(true);
  };

  const data = {
    columns: [
      { label: 'Sr.No', field: 'srNo' },
      { label: 'Name', field: 'name', width: 150 },
      { label: 'From Date', field: 'fromdate', width: 150 },
      { label: 'To Date', field: 'todate', width: 150 },
      { label: 'Reason', field: 'reason', width: 150 },
      {
        label: 'View',
        field: 'View',
        render: (row) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleViewClick(row)}
            style={{ fontSize: '12px' }}
          >
          <span className='me-1'>View </span>    <i class="fa-regular fa-eye"></i>
          </Button>
        )
      },
      {
        label: 'Status',
        field: 'Status',
        render: (row) => {
          if (row.status == 0) {
            return (
              <Button
                variant="contained"
        style={{ backgroundColor: 'orange', color: 'white', fontSize: '12px' }}
                onClick={() => handleStatusModalOpen(row)}
    
              >
               <span className='me-1'>Pending</span>   <i class="fas fa-spinner"></i>
              </Button>
            );
          } else if (row.status == 1) {
            return (
              <Button
                variant="contained"
                style={{ backgroundColor: 'green', color: 'white', fontSize: '12px' }}
                disabled
              >
               <span className='me-1'>Approved</span>   <i class="fas fa-check"></i>
              </Button>
            );
          } else {
            return (
              <Button
                variant="contained"
                style={{ backgroundColor: 'red', color: 'white', fontSize: '12px' }}
                disabled
              >
              <span className='me-1'>Rejected</span>    <i class="fas fa-times"></i>
              </Button>
            );
          }
        }
      },
     
    ],
    rows: rows.map((row,index) => ({
      srNo:index+1,
      ...row,
      View: (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleViewClick(row)}
          style={{ fontSize: '12px' }}
        >
           <span className='me-1'>View</span> <i class="fa-regular fa-eye"></i>
        </Button>
      ),
      Status: row.status == 0 ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleStatusModalOpen(row)}
        style={{ backgroundColor: 'orange', color: 'white', fontSize: '12px' }}
        >
       <span className='me-1'>Pending</span> <i class="fas fa-spinner"></i>
        </Button>
      ) : row.status == 1 ? (
        <Button
          variant="contained"
          style={{ backgroundColor: 'green', color: 'white', fontSize: '12px' }}
          disabled
        >
          <span className='me-1'>Approved</span>  <i class="fas fa-check"></i>
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{ backgroundColor: 'red', color: 'white', fontSize: '12px' }}
          disabled
        >
          <span className='me-1'>Rejected</span>  <i class="fas fa-times"></i>
        </Button>
      )
      
    }))
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Regular Leave Approval
          </h2>
        </div>

        <Row className="m-0 p-0 px-md-3 py-1 pb-5 mb-5 mt-4 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable striped className="custom-datatable text-center" bordered hover small data={data} />
              </div>
            </div>
          </Col>
        </Row>

        <Modal
  open={open}
  onClose={() => setOpen(false)}
  aria-labelledby="status-modal-title"
          aria-describedby="status-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '700px',
              maxHeight: '90vh',
              overflow:"scroll",
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
            }}
  >
    <Box
      sx={{
        overflowY: 'auto',
        overflowY:"scroll",
        p: 4,
        flex: 1,
      }}
    >
      <Typography id="modal-modal-description" style={{lineHeight:"18px"}}>
        <div className="block-header">
        <h2 className="mb-3" style={{ color: 'maroon' ,borderBottom:"2px solid maroon"}}>
          Regular Leave Approval
          </h2>
        </div>
        <Row>
          <Col> <b>Name</b>:</Col>
          <Col> {selectedRow.name} </Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Block No</b>:</Col>
          <Col >{selectedRow.block_id}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Room No</b>:</Col>
          <Col >{selectedRow.room_id}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Address</b>:</Col>
          <Col >{selectedRow.address}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Contact No</b>:</Col>
          <Col >{selectedRow.mob_no}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Parent's Contact No.</b>:</Col>
          <Col >{selectedRow.parents_mobno}  </Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>From Date</b>:</Col>
          <Col >{selectedRow.fromdate}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>To Date</b>:</Col>
          <Col >{selectedRow.todate}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Reason</b>: </Col>
          <Col >{selectedRow.reason} </Col>
        </Row>
        <br/>
        <Row style={{display:"flex"}}>
        <Col ><b>Student Image</b>: </Col>
        <Row>
        <Col md={3}></Col>
        <Col className=" mt-2 text-center"  md={6}>
          <img src={`${ImagePath}/${selectedRow.photo}`} className='acceptable rounded shadow border-0' alt='' style={{ height: "16rem",width:"15rem", border: "1px solid grey" }} />
        </Col>
        <Col md={3}></Col>
        </Row>
        </Row>
        </Typography>

    </Box>
    <Box
      sx={{
        p: 2,
        borderTop: '1px solid #ccc',
        textAlign: 'right',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(false)}
      >
        Close
      </Button>
    </Box>
  </Box>
</Modal>

     
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        >
          <DialogTitle>{"Reject Leave Request"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Do you really want to reject the leave request?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDelete(rowToDelete)} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
          open={statusModalOpen}
          onClose={() => setStatusModalOpen(false)}
          aria-labelledby="status-modal-title"
          aria-describedby="status-modal-description"
          sx={{
            display: 'flex',
            overflow:'scroll',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '700px',
              maxHeight: '90vh',
              overflow:"scroll",
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                p: 3,
                borderTop: '1px solid #ccc',
                // textAlign: 'right',
              }}
            >
            <Row>
            <div className="block-header">
          <h2 className="mb-2  mt-1 " style={{ color: 'maroon' ,borderBottom:"2px solid maroon"}}>
            Regular Leave Approve
          </h2>
        </div>

        <Box
      sx={{
        overflowY: 'auto',
        p: 4,
        flex: 1,
      }}
    >
      <Typography id="modal-modal-description" style={{lineHeight:"18px"}}>
   
      <Row>
          <Col> <b>Name</b>:</Col>
          <Col> {selectedRow.name} </Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Block No</b>:</Col>
          <Col >{selectedRow.block_id}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Room No</b>:</Col>
          <Col >{selectedRow.room_id}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Address</b>:</Col>
          <Col >{selectedRow.address}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Contact No</b>:</Col>
          <Col >{selectedRow.mob_no}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Parent's Contact No.</b>:</Col>
          <Col >{selectedRow.parents_mobno}  </Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>From Date</b>:</Col>
          <Col >{selectedRow.fromdate}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>To Date</b>:</Col>
          <Col >{selectedRow.todate}</Col>
        </Row>
        <br/>
        <Row>
          <Col ><b>Reason</b>: </Col>
          <Col >{selectedRow.reason} </Col>
        </Row>
        <br/>
        <Row style={{display:"flex"}}>
        <Col ><b>Student Image</b>: </Col>
        <Row>
        <Col md={3}></Col>
        <Col className=" mt-2 text-center"  md={6}>
          <img src={`${ImagePath}/${selectedRow.photo}`} className='acceptable rounded shadow border-0' alt='' style={{ height: "16rem",width:"15rem", border: "1px solid grey" }} />
        </Col>
        <Col md={3}></Col>
        </Row>
        </Row>
        </Typography>
</Box>
   

        
      <Box   sx={{
                p: 2,
              }}>
        <Checkbox className='ps-0 m-0' style={{position:"relative"}} checked={checkBoxChecked} onChange={handleCheckBoxChange}/> <b>Check And Proceed..</b>
     <Row className='mt-1' style={{display:"flex"}}>

              <Col>
              <Button
                variant="contained"
                color="primary"
                className='w-100'
                onClick={() => handleApproval(statusRow?.reg_id)}
                style={{ marginRight: '10px' }}
              >
                Approve
              </Button>
              </Col>
              <Col>
              <Button
                variant="contained"
                className='w-100'
                color="secondary"
                onClick={() => handleDeleteConfirmation(statusRow?.reg_id)}
              >
                Reject
              </Button>
              </Col>
              </Row>
              </Box>
            </Row>
            </Box>
            <Box
              sx={{
                p: 2,
                borderTop: '1px solid #ccc',
                textAlign: 'right',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setStatusModalOpen(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </section>
    </>
  );
}
 
