import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import ReportNavbar from './ReportNavbar';
import { MDBDataTable } from 'mdbreact';
import { ReportAlphabeticalList, ReportMemberListApi } from '../../ApiFile';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const AlphabeticalWiseStudent = () => {
 
  
  const [members, setMembers] = useState([]);

  useEffect(() => {
    fetch(ReportAlphabeticalList)
      .then(response => response.json())
      .then(data => setMembers(data))
      .catch(error => console.error('Error fetching member list:', error));
  }, []);

  const rows = members.map((member, index) => ({
    serial: index + 1,
    name: member.name,
    address: member.address,
    mob_no: member.mob_no,
    parents_mobno: member.parents_mobno,
    collage_name: member.collage_name,
    block_id: member.block_id,
    room_id: member.room_id,
    bed_id: member.bed_id,
    std: member.std,
    email_id: member.email_id,
  }));


  const data = {
    columns: [
      {
        label: 'Serial No',
        field: 'serial',
        sort: 'asc',
      },
      {
        label: 'Student Name',
        field: 'name',
        sort: 'asc',
      },
      {
        label: 'Block No',
        field: 'block_id',
        sort: 'asc',
      },
      {
        label: 'Room No',
        field: 'room_id',
        sort: 'asc',
      },
      {
        label: 'Bed No',
        field: 'bed_id',
        sort: 'asc',
      },
      {
        label: 'Mob.No',
        field: 'mob_no',
        sort: 'asc',
      },
      {
        label: 'Parents Mob.No',
        field: 'parents_mobno',
        sort: 'asc',
      },
      {
        label: 'Address',
        field: 'address',
        sort: 'asc',
      },
      {
        label: 'College',
        field: 'collage_name',
        sort: 'asc',
      },
      {
        label: 'Standard',
        field: 'std',
        sort: 'asc',
      },
      {
        label: 'Email Id',
        field: 'email_id',
        sort: 'asc',
      },
     
     
    ],
    rows: rows
  };

  const handleExcelData = () => {
    const ws = XLSX.utils.json_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Alphabetical_Wise_Student_Data.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    toast.success("Excel Sheet Downloaded !!!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handlePdfData = () => {
    const doc = new jsPDF();
    const columns = data.columns.map((col) => col.label);
    const rowsData = rows.map((row) => [row.serial,row.name,row.block_id,row.room_id,row.bed_id,row.mob_no,row.parents_mobno,row.address,row.collage_name,row.std,row.email_id]);

    const title = 'Alphabetical Wise Student Data';

    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const x = (pageWidth - textWidth) / 2;
    doc.text(title, x, 10);

    doc.autoTable({
      head: [columns],
      body: rowsData,
      startY: 20,
    });

    const blob = doc.output('blob');
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Alphabetical_Wise_Student_Data.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    toast.success("PDF Downloaded !!!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <>
      <Sidebar />
      <section className="home-section pb-5">
        <NavLogo />
        <ReportNavbar />

        <div className="member-list border">
          <Row className="m-0 p-0 pt-4 px-3 ">
            <Col
                className="rounded text-center py-2"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    border: "0px",
                    alignItems: "center",
                    alignContent: "center",
                    color: "maroon",
                }}
            >    
                <div><span style={{color:"maroon",fontSize:"18px"}}>Alphabetical Wise Student List</span></div>
                <div>
                  <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#3b9cfd",color:"white"}}  onClick={() => handleExcelData()}>Excel</Button>
                  <Button className='mx-1 p-0' style={{border:"1px solid",backgroundColor:"#136fcb",color:"white"}} onClick={() => handlePdfData()}>Pdf</Button>
                </div>
            </Col>
        </Row>

          <Row className="m-0 p-0 px-md-3 py-1 pb-5  w-100 mb-5" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
          <MDBDataTable
            striped
            bordered
            className="custom-datatable"
            small
            data={data}
          />
          </Col>
          </Row>
        </div>

      </section>
    </>
  );
};

export default AlphabeticalWiseStudent