import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import { AdminMessAttendanceAdd, AdminMessAttendanceMemberList } from '../../ApiFile';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import Sidebar from '../../AluminiAdminDashboardDesign/sidebar';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import NavLogo from '../../AluminiAdminDashboardDesign/NavLogo';
import { notification } from 'antd';

const MessAttendanceAdmin = () => {
  const [studentDataType, setStudentDataType] = useState('');
  const [date, setDate] = useState(dayjs());
  const [attendanceType, setAttendanceType] = useState(1);
  const [memberList, setMemberList] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalPresent, setTotalPresent] = useState(0);
  const [totalAbsent, setTotalAbsent] = useState(0);
  const [selectOption, setSelectOption] = useState(''); // Default to 1 for "Lunch"

  const handleSelectOptionChange = (event) => {
    setSelectOption(event.target.value);
  };

  const handleStudentDataTypeChange = (event) => {
    setStudentDataType(event.target.value);
  };

  const handleAttendanceTypeChange = (event) => {
    setAttendanceType(event.target.value);
  };

  const updateTotals = (members) => {
    const total = members.length;
    const present = members.filter(member => member.statusflag === "1").length;
    const absent = members.filter(member => member.statusflag === "2").length;
    setTotalStudents(total);
    setTotalPresent(present);
    setTotalAbsent(absent);
  };

  const handleAttendanceToggle = (memberId, flag) => {
    setMemberList(prevList => {
      const updatedList = prevList.map(member =>
        member.member_id === memberId ? { ...member, statusflag: flag } : member
      );
      updateTotals(updatedList);
      return updatedList;
    });
  };

  const handleSaveAttendance = () => {

    if (!selectOption ) {
      notification.warning({
        message: 'Warning',
        description: 'Please Select Meal.',
      });
      return;
    }
    if (!studentDataType ) {
      notification.warning({
        message: 'Warning',
        description: 'Please Select Data Type.',
      });
      return;
    }
    const hasPendingAttendance = memberList.some(member => member.statusflag === "0");

    if (hasPendingAttendance) {
      toast.error('Please check all attendance records before saving!');
    } else {
      const attendanceData = {
        flag: attendanceType,
        attendance_date: date.format('DD/MM/YYYY'),
        listflag: selectOption,
        attendanceList: memberList.map(member => ({
          member_id: member.member_id,
          statusflag: member.statusflag
        }))
      };
      console.log("attendanceData..",attendanceData);
      

      axios.post(AdminMessAttendanceAdd, attendanceData)
        .then(response => {
          if (response.data == 'SAVED') {
            toast.success('Attendance data saved successfully!');
          }
          setSelectOption('');
          setStudentDataType('');
          setMemberList([]);
        })
        .catch(error => {
          console.error("There was an error saving the attendance data!", error);
        });
    }
  };

  const fetchMemberList = () => {
    const formattedDate = date.format('DD/MM/YYYY');
    axios.post(AdminMessAttendanceMemberList, {
      list_flag: studentDataType,
      attendance_date: formattedDate
    })
      .then(response => {
        const members = response.data.map(member => ({
          ...member,
          statusflag: selectOption === 1 ? member.lunch_flag.toString() : member.dinner_flag.toString()
        }));
        setMemberList(members);
        updateTotals(members);
      })
      .catch(error => {
        console.error('Error fetching member list:', error);
      });
  };

  useEffect(() => {
    fetchMemberList();
  }, [studentDataType, date, selectOption]);

  const getButtonStyle = (flag, buttonType) => {
    if (flag === "0") {
      return { backgroundColor: "white", color: "black" };
    }
    if (flag === "1" && buttonType === "P") {
      return { backgroundColor: "green", color: "white" };
    }
    if (flag === "2" && buttonType === "A") {
      return { backgroundColor: "red", color: "white" };
    }
    return { backgroundColor: "white", color: "black" };
  };

  const data = {
    columns: [
      { label: 'Sr.No', field: 'sno', sort: 'asc', attributes: { className: 'ssss' } },
      { label: 'Block No', field: 'block_id', sort: 'asc', attributes: { className: 'ssss' } },
      { label: 'Room No', field: 'room_id', sort: 'asc', attributes: { className: 'ssss' } },
      { label: 'Name', field: 'name', sort: 'asc', attributes: { className: 'name-column' } },
      { label: 'Attendance', field: 'statusflag', sort: 'asc', attributes: { style: { textAlign: 'center' } } }
    ],
    rows: memberList.map((member, index) => ({
      sno: index + 1,
      ...member,
      name: (
        <span className="text-start">
          {member.name}
        </span>
      ),
      statusflag: (
        <>
          <Button
            variant="contained"
            className='me-1 '
            style={getButtonStyle(member.statusflag, "P")}
            onClick={() => handleAttendanceToggle(member.member_id, "1")}
          >
            <b>P</b>
          </Button>
          <Button
            variant="contained"
            className='ms-1'
            style={getButtonStyle(member.statusflag, "A")}
            onClick={() => handleAttendanceToggle(member.member_id, "2")}
          >
            <b>A</b>
          </Button>
        </>
      )
    }))
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />

        <div className='pb-5'>
          <div className="block-header">
            <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
              Mess Attendance
            </h2>
          </div>

          <Row className="m-0 pt-1 px-2 px-md-3 rounded">
            <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
              <Row>
                <Col md={3} className="mt-3 mt-md-0">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      label='Select Date'
                      className='w-100'
                      value={date}
                      style={{ width: "100%" }}
                      onChange={(newDate) => {
                        console.log("date res", newDate);
                        setDate(newDate);
                      }}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                </Col>
                <Col md={3} className="mt-3 mt-md-0">
                  <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="select-option-label">Select Meal</InputLabel>
                    <Select
                      labelId="select-option-label"
                      id="select-option-select"
                      value={selectOption}
                      label="Select Option"
                      onChange={handleSelectOptionChange}
                    >
                      <MenuItem value={1}>Lunch</MenuItem>
                      <MenuItem value={2}>Dinner</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col md={3} className="mt-3 mt-md-0">
                  <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="student-data-type-select-label">Student Data Type</InputLabel>
                    <Select
                      labelId="student-data-type-select-label"
                      id="student-data-type-select"
                      value={studentDataType}
                      label="Student Data Type"
                      onChange={handleStudentDataTypeChange}
                    >
                      <MenuItem value={1}>Block Wise</MenuItem>
                      <MenuItem value={2}>Alphabetical Order</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col md={3} className="mt-3 mt-md-0">
                  <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="attendance-type-select-label">Attendance Type</InputLabel>
                    <Select
                      labelId="attendance-type-select-label"
                      id="attendance-type-select"
                      value={attendanceType}
                      label="Attendance Type"
                      onChange={handleAttendanceTypeChange}
                    >
                      <MenuItem value={1}>Manual</MenuItem>
                      <MenuItem value={2}>Biometric</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
            <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
              <div style={{ overflowY: 'auto' }}>
                <div style={{ overflowX: 'auto' }}>
                  <MDBDataTable striped className="custom-datatable text-center" bordered hover data={data} />
                </div>
              </div>
              <div className="text-center pt-2 rounded" style={{display:"flex",alignItems:"center",justifyContent:"space-around",border:"1px solid rgb(214, 222, 219)"}}>
                <p><b style={{color:"maroon"}}>Total Students</b> : <b>{totalStudents}</b></p>
                <p><b style={{color:"green"}}>Total Present</b> : <b>{totalPresent}</b></p>
                <p><b style={{color:"red"}}>Total Absent</b> : <b>{totalAbsent}</b></p>
              </div>
              <div className="text-right text-center mt-3 mb-4">
                <Button style={{ backgroundColor: "rgb(0, 120, 215)" }} onClick={handleSaveAttendance}>
                  <b style={{ color: "white" }}>Save Attendance</b>
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default MessAttendanceAdmin;
