import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
// import '../Room Master/room.css';
import { Col, Row } from 'react-bootstrap';
import { BlockFindall } from '../../ApiFile';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
const AdminBlock = () => {
    const navigate = useNavigate();
    const [blocks, setBlocks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const sessionYears = sessionStorage.getItem('selectedYear');

    const location = useLocation();
  const MemberId = location.state && location.state.MemberId;
//   console.log("memberid....",MemberId)


    useEffect(() => {
    //   console.log("api......", BlockFindall);
      fetch(BlockFindall, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              year: sessionYears, // "2024-25" if you want a fixed year
              member_id: MemberId, // 5 if you want a fixed member_id
          }),
      })
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              return response.json();
          })
          .then(data => {
              setBlocks(data);
            //   console.log("res block findall//....", data);
              setLoading(false);
          })
          .catch(error => {
              setError(error);
              setLoading(false);
          });
  }, [sessionYears, MemberId]);

    const handleBlockSelect = (block_id) => {
        navigate(`/AdminRoomChange/${block_id}/${MemberId}`);
        // console.log("block id././",block_id)
        // console.log("MemberId././",MemberId)
    };
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };
    return (
<>
<Sidebar />
      <section className="home-section">
        <NavLogo />
       

        <main className="main-container">
        <Row className="m-0 p-0 pt-2 mb-1">
              <Col
                className=" rounded pt-2"
                style={{
                  backgroundColor: "white",
                  border: "0px",
                  alignItems: "center",
                  alignContent: "center",
                  color: " rgb(47, 79, 79)",
                }}
              >
                <h3 style={{ color: "maroon" }}>BLOCKS</h3>
              </Col>
            </Row>

            <div className="main-cards1">
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>Error fetching blocks: {error.message}</p>
                ) : blocks.length === 0 ? (
                    <p>No blocks available.</p>
                ) : (
                    blocks.map((block, index) => (
                        <div key={index} className='card5 mt-4' style={{backgroundColor:"pink"}}>
                            <h5>Block {block.block_id}</h5>
                            <h5>Rooms: {block.room_range}</h5>
                            <h5>Bed/Room :{block.bed_count}</h5>
                            <h5>Amount : {block.amount}</h5>
                            {/* <h5>Full Term Fee: {block.yearly_amount}</h5> */}
                            <h5>+Refundable Deposit: {block.deposit_amount}</h5>
                             <h5>Facilities: {block.discription}</h5>
                             <div style={{display:"flex",justifyContent:"center"}}>
                            <button 
                             className="fs-5 nextBtn shadow"
                        style={{backgroundColor:"maroon",color:"white",borderRadius:"10px",width:"100px",border:"0px"}} 
                             onClick={() => handleBlockSelect(block.block_id)}>Select</button>
                             </div>
                        </div>
                    ))
                )}
            </div>
           
        </main>
        </section>
        </>
    );
};

export default AdminBlock; 
