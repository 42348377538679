// HomeAdmin.js
import React, { useEffect, useState } from 'react';
import { AdminDashboard, EventDashboardApi } from '../ApiFile';
import Sidebar from './sidebar';
import NavLogo from './NavLogo';
import { FaUserLarge } from "react-icons/fa6";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { RiPagesLine } from "react-icons/ri";
import { PiStudentFill } from "react-icons/pi";
import { AiFillContainer } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { FaUserCircle } from "react-icons/fa";
import { MdBedroomChild } from "react-icons/md";
import { TbCoinRupeeFilled } from "react-icons/tb";
import { FaHourglassHalf } from "react-icons/fa";
import { MdHourglassFull } from "react-icons/md";
import { MdEvent } from "react-icons/md";

const HomeAdmin = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const sessionYears = sessionStorage.getItem('selectedYear');

  useEffect(() => {

    //---------------------------------Disabled back button-------------------------------
    window.history.pushState(null, null, window.location.href);

    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.forward();
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [ ]);


  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(AdminDashboard, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ year: sessionYears }), // Pass the year in the request body
        });
        if (!response.ok) {
          throw new Error("Failed to fetch dashboard data");
        }
        const data = await response.json();
        setDashboardData(data);
        // console.log("API Response:", data);
      } catch (error) {
        // console.error('Failed to fetch dashboard data', error);
      }
    };
  
    fetchDashboardData();
  }, []);

  useEffect(() => {
    fetch(EventDashboardApi)
      .then(response => {
        if (!response.ok) {
          throw new Error('NOTFOUND');
        }
        return response.json();
      })
      .then(data => {
        setEvents(data);
      })
      .catch(error => {
        if (error.message === 'NOTFOUND') {
          setError('Resource not found');
        } else {
          setError('Add Event');
        }
      });
  }, [EventDashboardApi]);

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div>
        <h2 className="text-center mt-md-4 mt-2 mb-3 m-0 p-0" style={{ color: 'maroon' }}>
              Dashboard
            </h2>
        </div>
        <div className='row mx-1'>
          <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid green' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <FaUserLarge style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Students Admitted</h5>
                </div>
                <div className="col-12 ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <p className='fs-5' style={{color:"gray"}}>{dashboardData ? dashboardData.admitStudentCount : '-'}</p>
                <p className='text-end'><FaUserLarge style={{color:"green"}} className=' fs-3' /></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid orange' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <MdOutlineCurrencyRupee  style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Amount Collected</h5>
                </div>
                <div className="col-12 ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <p  style={{color:"gray"}}>{dashboardData ? `${dashboardData.totalPaymentAmount.month} : ${dashboardData.totalPaymentAmount.amount}` : '-'}
                {/* <span style={{color:"orange"}}>&nbsp;|&nbsp;</span> Cum : {dashboardData ? `${dashboardData.totalPaymentAmount.amount}` : '-'} */}
                </p>

                <p className='text-end'><MdOutlineCurrencyRupee style={{color:"orange"}} className=' fs-3' /></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid rgb(221, 59, 59)' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <RiPagesLine style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Complaints Pending</h5>
                </div>
                <div className="col-12 ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <p className='fs-5' style={{color:"gray"}}>{dashboardData ? dashboardData.complaintpendingCount : '-'}</p>
                <p className='text-end'><RiPagesLine style={{color:"rgb(221, 59, 59)"}} className=' fs-3' /></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid #2bdbd1' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <PiStudentFill  style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Alumni Counts</h5>
                </div>
                <div className="col-12 ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <p className='fs-5' style={{color:"gray"}}> {dashboardData ? dashboardData.recentAluminiaddedCount : '-'}</p>
                <p className='text-end'><PiStudentFill  style={{color:"#2bdbd1"}} className=' fs-2' /></p>
                </div>
              </div>
            </div>
          </div>
        </div>   

        <div className='row mx-1 mt-2'>
        <div className='col'>
        <div>
      <h5 style={{color:"maroon"}}><u>Pending Approval : </u></h5>
      </div>
        </div>
        </div>
                
    <div className='row mx-1 pb-2 mb-2' style={{borderBottom:"2px solid maroon"}} >
         <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid rgb(247, 140, 177)' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <AiFillContainer  style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Application</h5>
                </div>

                <div className="col-12 ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <div className="fs-5" style={{color:"gray"}}>
                 {dashboardData ? dashboardData.approvalPendingCount.regFormApprovalPending : '-'}
                </div>
                <div>
                <Button >
                   <Link to="/Reg50Approval" className='navigateLink'><Button style={{backgroundColor:"rgb(247, 140, 177)" ,color:"black"}}>View</Button></Link>
                </Button>
                </div>
                </div>

                
              </div>
            </div>
          </div>
         <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid rgb(57, 158, 161)' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <FaUserCircle  style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Member</h5>
                </div>
                <div className="ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <div className="fs-5" style={{color:"gray"}}>
                 {dashboardData ? dashboardData.approvalPendingCount.regMemberApprovalPending : '-'}
                </div>
                <div>
                <Button >
                   <Link to="/StudentView" className='navigateLink'><Button style={{backgroundColor:"rgb(57, 158, 161)" ,color:"black"}}>View</Button></Link>
                </Button>
              </div>
              </div>
              </div>
            </div>
          </div>
    
         <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid rgb(89, 212, 143)' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <TbCoinRupeeFilled  style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Payment</h5>
                </div>
                <div className="ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <div className="fs-5" style={{color:"gray"}}>
                 {dashboardData ? dashboardData.approvalPendingCount.paymentApprovalPending : '-'}
                </div>
                <div>
                <Button >
                   <Link to="/payment" className='navigateLink'><Button style={{backgroundColor:"rgb(89, 212, 143)" ,color:"black"}}>View</Button></Link>
                </Button>
              </div>
              </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-3 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', color: '#2bdbd1', borderBottom: '5px solid rgb(240, 176, 44)' }}>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <MdBedroomChild  style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Job</h5>
                </div>
                <div className="ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <div className="fs-5" style={{color:"gray"}}>
                 {dashboardData ? dashboardData.jobApprovalPendingCount : '-'}
                </div>
                <div>
                <Button >
                   <Link className='navigateLink'><Button style={{backgroundColor:"rgb(240, 176, 44)" ,color:"black"}}>View</Button></Link>
                </Button>
              </div>
              </div>
              </div>
            </div>
          </div>
    </div>

    <div className='row mx-1 '>
    <div className="col-12 col-md-4 p-2 ">
            <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none',height:"200px", color: '#2bdbd1', borderBottom: '5px solid rgb(123, 65, 119)' }}>
              <div className="row p-0 m-0" style={{borderBottom:"2px solid gray"}}>
                <div className="col-12 d-flex fs-5 pt-2">
                <FaHourglassHalf style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Half Term Admission</h5>
                </div>
                <div className="col-12 ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <p className='fs-5' style={{color:"gray"}}>{dashboardData ? dashboardData.termYearlyStudentCount.term : '-'}</p>
                <p className='text-end'><FaHourglassHalf style={{color:"rgb(123, 65, 119)"}} className=' fs-4' /></p>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-12 d-flex fs-5 pt-2">
                <MdHourglassFull style={{color:"gray"}} />&nbsp;&nbsp;&nbsp;
                <h5>Yearly Admission</h5>
                </div>
                <div className="col-12 ps-5 " style={{display:"flex",justifyContent:"space-between"}}>
                <p className='fs-5' style={{color:"gray"}}>{dashboardData ? dashboardData.termYearlyStudentCount.yearly : '-'}</p>
                <p className='text-end'><MdHourglassFull style={{color:"rgb(123, 65, 119)"}} className=' fs-3' /></p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 p-2 mb-5 pb-5 mb-md-1 pb-md-1">
      <div className="p-2 rounded" style={{ backgroundColor: '#fff', border: 'none', height: "200px", color: '#2bdbd1', borderBottom: '5px solid rgb(146, 209, 83)' }}>
        <div className="row p-0 m-0 " >
          <div className="col-12 d-flex fs-5 pt-2" style={{ borderBottom: "1px solid gray" }}>
            <MdEvent style={{ color: "gray" }} />&nbsp;&nbsp;&nbsp;
            <h5>Events</h5>
          </div>
          <div className="col-12" style={{ overflowY: 'scroll', maxHeight: '148px' }}>
            {error ? (
              <div>{error}</div>
            ) : (
             <table  className="table">
                <thead>
                  <tr className='p-0 m-0'  style={{ height: '10px' , fontSize:"13px"  }}>
                    <th scope="col">Sr No</th>
                    <th scope="col">Event Date</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((event, index) => (
                    <tr key={index}  style={{ height: '15px' ,fontSize:"13px"  }}>
                      <td>{index + 1}</td>
                      <td>{event.event_date}</td>
                      <td>{event.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>

    </div>


      </section>
    </>
  );
};

export default HomeAdmin;











// import React, { useState ,useEffect} from 'react';
// import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
// import { FaTrash } from 'react-icons/fa';
// import { BsPeopleFill } from 'react-icons/bs';
// import { FaRegLightbulb } from 'react-icons/fa';
// import { GrCompliance } from "react-icons/gr";
// import { IoPerson } from 'react-icons/io5';
// import { FaIndianRupeeSign } from 'react-icons/fa6';
// import { Grid, Paper } from '@mui/material';
// import axios from 'axios';
// import Sidebar from './sidebar';
// import NavLogo from './NavLogo';
// import { DashboardComplaintApi, DashboardPaymentFindAllApi, DashboardRegistrationFindAllApi } from '../ApiFile';


// // Define the StripedRowExample component
// function StripedRowExample({ setStudentCount }) {
//     const [rows, setRows] = useState([
//         { srNo: 1, roomNo: 121, roomType: '2 Sharing', bookingDate: '15/04/2023', status: 'Approved' },
//         { srNo: 2, roomNo: 131, roomType: '3 Sharing', bookingDate: '28/05/23', status: 'Pending' },
//         { srNo: 3, roomNo: 101, roomType: 'Single sharing', bookingDate: '15/04/24', status: 'Rejected' },
//     ]);

//     // Function to handle row deletion
//     const handleDeleteRow = (index) => {
//         const updatedRows = rows.filter((_, idx) => idx !== index);
//         setRows(updatedRows);

//         // Update student count after deleting a row
//         setStudentCount(prevCount => prevCount - 1);
//     };

//     return (
//         <table className="striped-table">
//             <thead>
//                 <tr>
//                     <th>Sr. No</th>
//                     <th>Room No</th>
//                     <th>Room Type</th>
//                     <th>Room Booking Date</th>
//                     <th>Status</th>
//                     <th>Action</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {rows.map((row, index) => (
//                     <tr key={row.srNo}>
//                         <td>{row.srNo}</td>
//                         <td>{row.roomNo}</td>
//                         <td>{row.roomType}</td>
//                         <td>{row.bookingDate}</td>
//                         <td>
//                             {/* Render button with different colors based on status */}
//                             <button
//                                 className={`button-${row.status.toLowerCase()}`}
//                             >
//                                 {row.status}
//                             </button>
//                         </td>
//                         {/* Add delete button to the action column */}
//                         <td>
//                             <button onClick={() => handleDeleteRow(index)}>
//                                 <FaTrash />
//                             </button>
//                         </td>
//                     </tr>
//                 ))}
//             </tbody>
//         </table>
//     );
// }

// // Define the Home component
// function Home() {
//     const [hoveredIndex, setHoveredIndex] = useState(null);
//     const [complaintCount, setComplaintCount] = useState(0);

//     useEffect(() => {

//         //---------------------------------Disabled back button-------------------------------
//         window.history.pushState(null, null, window.location.href);
    
//         const handleBackButton = (event) => {
//           event.preventDefault();
//           window.history.forward();
//         };
    
//         window.addEventListener('popstate', handleBackButton);
    
//         return () => {
//           window.removeEventListener('popstate', handleBackButton);
//         };
//       }, [ ]);
//     useEffect(() => {
//         // Fetch alumni data from the API
//         axios.get(DashboardComplaintApi)
//             .then(response => {
//                 // Get the count of alumni
//                 setComplaintCount(response.data.length);
//             })
//             .catch(error => {
//                 console.error(error);
//             });
//     }, []);

//     const [studentCount, setStudentCount] = useState(0);
//     useEffect(() => {
//         // Fetch student data from the API
//         axios.get(DashboardRegistrationFindAllApi)
//             .then(response => {
//                 // Get the count of students
//                 setStudentCount(response.data.length);
//             })
//             .catch(error => {
//                 console.error(error);
//             });
//     }, []);

//     const [paymentCount, setPaymentCount] = useState(0);
//     useEffect(() => {
//         // Fetch payment data from the API
//         axios.get(DashboardPaymentFindAllApi)
//             .then(response => {
//                 // Get the count of payments
//                 setPaymentCount(response.data.length);
//             })
//             .catch(error => {
//                 console.error(error);
//             });
//     }, []);

//     const data = [
//         { name: 'Complaint', value: complaintCount },
//         { name: 'Student', value: studentCount },
//         { name: 'Random', value: 40 },
//     ];

//     const colors = ['#ff3838', '#05103b', '#2facff'];

//     // Define style for each cell based on hover state
//     const cellStyle = (index) => ({
//         opacity: hoveredIndex === null || hoveredIndex === index ? 1 : 0.3,
//         transition: 'opacity 0.3s',
//     });

//     return (
//         <>
//     <Sidebar/>
//       <section className="home-section">
//         <NavLogo/> 
//         <main className="main-container">
//             <div className="main-title">
//                 <h3>DASHBOARD<br />Welcome, Admin!👋</h3>
//             </div>

//             <div className="main-cards">
//                 {/* Card 1: Rooms Count */}
//                 <div className="card">
//                     <div className="card-inner">
//                         <FaRegLightbulb className="card-icon" />
//                         <h3>Rooms Count</h3>
//                     </div>
//                     <div>
//                         <h1 style={{ display: 'inline' }}>53 | 0</h1>
//                         <h4 style={{ display: 'inline', marginLeft: '5px' }}>Booked</h4>
//                     </div>
//                 </div>
//                 {/* Card 2: Students Count */}
//                 <div className="card">
//                     <div className="card-inner">
//                         <IoPerson className="card-icon" />
//                         <h3>Students Count</h3>
//                     </div>
//                     <div>
//                     <h1 style={{ display: 'inline' }}>144 |{studentCount}</h1><h4 style={{ display: 'inline'}}> Registered</h4>
//                     </div>
//                 </div>
//                 {/* Card 3: Alumni Count */}
//                 <div className="card">
//                     <div className="card-inner">
//                         <GrCompliance className="card-icon" />
//                         <h3>Complaint Count</h3>
//                     </div>
//                     <div>
//                     <h1 style={{ display: 'inline'}}>{complaintCount}</h1>
//                     <h4 style={{ display: 'inline'}}> Registered</h4>
//                     </div>
//                 </div>
//                 {/* Card 4: Payment Count */}
//                 <div className="card">
//                     <div className="card-inner">
//                         <FaIndianRupeeSign className="card-icon" />
//                         <h3>Payment Count</h3>
//                     </div>
//                     <h1>{paymentCount}</h1>
//                 </div>
//             </div>

//             {/* Container for Pie Chart and Table */}
//             <div className="container" style={{ padding: '16px' }}>
//                 <Grid container spacing={2}>
//                     {/* Pie Chart */}
//                     <Grid item xs={12} md={4} lg={4}>
//                         <Paper style={{ padding: '16px', textAlign: 'center' }}>
//                             <h3>Total Site Visits</h3>
//                             <PieChart width={300} height={300}>
//                                 <Pie
//                                     data={data}
//                                     cx={150}
//                                     cy={150}
//                                     outerRadius={100}
//                                     innerRadius={40}
//                                     dataKey="value"
//                                     onMouseEnter={(_, index) => setHoveredIndex(index)}
//                                     onMouseLeave={() => setHoveredIndex(null)}
//                                 >
//                                     {data.map((entry, index) => (
//                                         <Cell
//                                             key={`cell-${index}`}
//                                             fill={colors[index]}
//                                             style={cellStyle(index)}
//                                         />
//                                     ))}
//                                 </Pie>
//                                 <Tooltip />
//                                 <Legend />
//                             </PieChart>
//                         </Paper>
//                     </Grid>

//                     {/* Table Card */}
//                     <Grid item xs={12} md={8} lg={8}>
//                         <Paper style={{ padding: '16px', textAlign: 'center' , height:'360px'}}>
//                             <h3>Table</h3>
//                             <div style={{ display: 'flex', justifyContent: 'center' }}>
//                                 <StripedRowExample setStudentCount={setStudentCount} />
//                             </div>
//                         </Paper>
//                     </Grid>
//                 </Grid>
//             </div>
//         </main>
//         </section>
// </>
// );
// }

// export default Home;




