import React, { useEffect, useState } from "react";
import { Form, Input, notification, Select } from "antd";
import loginImage from "./4707071.jpg"; // Import the image file
import "./Login.css";
import { Navigate, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { BoardingYearApi } from "../../ApiFile";

const { Option } = Select;

const AdminLoginYear = () => {
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // Fetch boarding years from the API
  useEffect(() => {
    const fetchBoardingYears = async () => {
      try {
        const response = await fetch(BoardingYearApi);
        const data = await response.json();
        setYears(data);
        sessionStorage.setItem("boardingYears", JSON.stringify(data.boarding_year));

        // Set the initial value of the form to the first year
        if (data.length > 0) {
          form.setFieldsValue({ year: data[0].boarding_year });
        }
      } catch (error) {
        notification.error({ message: 'Failed to fetch boarding years' });
      }
    };

    fetchBoardingYears();
  }, [form]);

  const customInputStyle = {
    color: '#000000',
    border: '1px solid #d9d9d9',
    height: '40px'
  };

  const handleSubmit = (values) => {
    setLoading(true);
    // Store the selected year in session storage
    sessionStorage.setItem("selectedYear", values.year);
    setTimeout(() => {
      setLoading(false);
      navigate('/HomeAdmin');
    }, 1000); // Mock API call duration
  };

  const logoutFun = () => {
    sessionStorage.clear();
    navigate('/');
  }

  return (
    <div className="login-page">
      <div className="login-box" style={{ overflow: "scroll" }}>
        <div className="illustration-wrapper">
          <img src={loginImage} alt="Login" /> {/* Use the imported image */}
        </div>
        <Form form={form} name="login-form" onFinish={handleSubmit}>
          <p className="form-title fs-4 mb-4">Sheth R D Dawada Digambar Jain Boarding, Sangli</p>
          <Form.Item name="username">
            <Input
              readOnly
              placeholder="Academic Year"
              style={customInputStyle}
              className="custom-input"
            />
          </Form.Item>

          <Form.Item
            name="year"
            rules={[{ required: true, message: 'Please select a year!' }]}
          >
            <Select placeholder="Select year" style={{ height: '40px' }}>
              {years.map((year, index) => (
                <Option key={index} value={year.boarding_year}>
                  {year.boarding_year}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mr: 1 }} disabled={loading}>
              PROCEED
            </Button>
            <Button variant="contained" color="error" fullWidth sx={{ ml: 1 }} onClick={logoutFun}>
              CANCEL
            </Button>
          </Box>
        </Form>
      </div>
    </div>
  );
};

export default AdminLoginYear;
