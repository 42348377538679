import react, { Fragment } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';



function Privacy(){
    return(
        <div>
            <Navbar></Navbar>
        
        {/* Header Start */}
        <div className="container-fluid bg-breadcrumbP">
          <div className="container  py-5" style={{maxWidth: '900px'}}>
            <h3 className=" display-3 mb-4" style={{color: 'white', textShadow: '3px 3px #ee1515', fontSize: '50px', textAlign: 'center'}}>
            </h3>
          </div>
        </div>
        {/* Header End */}
        {/* Packages Start */}
        {/* Packages End */}
        {/* Tour Booking Start */}
        <div className="main">
          <div className="content" style={{textAlign: 'left', margin: '30px 60px', borderRadius: '20px', padding: '30px', backgroundColor: '#ebf2fc'}}>
            <h2 style={{marginLeft: '0px', textAlign: 'center', color: '#ee1515'}}>Privacy Policy</h2>
            <p>We respect the privacy of those who visit our website. In accordance with this, we have created this policy in order to demonstrate our privacy commitment to our users.</p>
            <h3 style={{color: '#ee1515'}}>What this Privacy Policy Covers?</h3>
            <p>This Privacy Policy covers our treatment of personally identifiable information that we collect when you are on our site, and when you use our services. This policy also covers our treatment of any personally identifiable information that third parties share with us.</p>
            <p>This policy does not apply to the practices of organizations that we do not own or control, or to people that we do not employ or manage.</p>
            <h3 style={{color: '#ee1515'}}>Information Collection and Use</h3>
            <p>We collect personally identifiable information when you register on our website, when you use our services, and when you visit our pages. We may also receive personally identifiable information from third parties.</p>
            <p>When you register with us, we ask for your name, email address, zip code, occupation, industry, and personal interests. Once you register with us and sign in to our services, you are not anonymous to us.</p>
            <p>The email or contact number address you submit to receive information from bduedu.in will never be disclosed to any third party. All email that you receive will be from the domain names bduedu.in.</p>
            <p>Bharathidasan University has adopted and adheres to stringent security standards designed to protect non-public personal information at this Website against accidental or unauthorized access or disclosure. Among the safeguards that Bharathidasan University has developed for this Website are administrative, physical, and technical barriers that together form a protective firewall around the information stored at this site.</p>
            <p>We use this information for three general purposes: to customize the content you see, to fulfill your requests for certain services, and to contact you about services.</p>
            <p>We also automatically receive and record information on our server logs from your browser including your IP address, cookie information, and the page you requested. This information is not linked to your account and will not be used to identify you.</p>
            <h3 style={{color: '#ee1515'}}>Information Sharing and Disclosure</h3>
            <p>We will not sell or rent your personally identifiable information to anyone. We will send personally identifiable information about you to other companies or people when:</p>
            <ul className="bullet-list">
              <li>We have your consent to share the information.</li>
              <li>We respond to subpoenas, court orders, or legal process.</li>
              <li>We find that your actions on our websites violate the Terms of Service.</li>
            </ul>
            <h3 style={{color: '#ee1515'}}>Changes to this Privacy Policy</h3>
            <p>The privacy policy is subject to modification from time to time. If we decide to change our privacy policy, we will post those changes here so that you will always know what information we gather, how we might use that information, and whether we will disclose it to anyone. Any significant changes to our privacy policy will be announced on our home page. If you do not agree with the changes in our policy, you can simply discontinue visiting our website.</p>
            <h3 style={{color: '#ee1515'}}>Questions or Suggestions</h3>
            <p>If you have questions or suggestions, send an email to us: support@jainboardingsangli.com</p>
          </div>
          <style dangerouslySetInnerHTML={{__html: "\n    /* Style for the first bullet */\n.bullet-list li:first-child {\n    list-style-type: circle;\n    color: black; /* Change color as desired */\n}\n\n/* Style for the second bullet */\n.bullet-list li:nth-child(2) {\n    list-style-type: circle;\n    color: black; /* Change color as desired */\n}\n\n/* Style for the third bullet */\n.bullet-list li:nth-child(3) {\n    list-style-type: circle;\n    color: black; /* Change color as desired */\n}\n\n " }} />  
         
          <Footer/>
      {/* Copyright End */}
      {/* Back to Top */}
</div>   


          </div>

    )
}
export default Privacy;