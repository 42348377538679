import React, { useEffect, useState } from "react";
import Sidebar from "./sidebarstudent";
import Header from "./Headerstudent";
import { Col, Row } from "react-bootstrap";
import { StudentProfileApi } from "../../ApiFile";

const StudentProfile = () => {
  const [profileData, setProfileData] = useState(null);

  const memberId = sessionStorage.getItem('member_id');

  useEffect(() => {
    fetch(`${StudentProfileApi}/${memberId}`)
      .then(response => response.json())
      .then(data => setProfileData(data))
      .catch(error => console.error("Error fetching data:", error));
  }, []);

  return (
    <>
      <Sidebar />
      <section className="home-section pb-5">
        <Header />

        <Row className="m-0  px-md-2 mt-2 rounded">
          <Col
            className="m-0 p-0 px-3 py-2 rounded"
            style={{ background: "white" }}>
            <h4 style={{ color: "maroon" }}>Profile</h4>
          </Col>
        </Row>

        {profileData ? (
          <Row className="m-0  px-md-2 mt-2 mb-5 rounded">
            <Col
              className="m-0 p-0 px-3 py-2 rounded"
              style={{ background: "white" }}>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Name :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.name}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Father's Name :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.fatherName}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Address :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.address}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Email :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.email}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Father's Occupation :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.father_occupation}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Standard :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.std}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>College Name :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.collage_name}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Sub Category :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.sub_category}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Date of Birth :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.dateofbirth}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Age :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.age}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Mobile No :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.mob_no}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Parent's Mobile No :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.parents_mobno}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Block ID :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.block_id}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Room ID :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.room_id}</p></Col>
                </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                <Row style={{ display: "flex" }}>
                  <Col xs={4} md={3}><label>Bed ID :</label></Col>
                  <Col xs={7} md={9}><p>{profileData.bed_id}</p></Col>
                </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <p>Loading...</p>
        )}
      </section>
    </>
  );
};

export default StudentProfile;
