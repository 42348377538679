import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'; // Import TextField
import { Visibility, Add, Delete, Edit } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { AluminiGalleryMasterAddApi, AluminiGalleryMasterDeleteApi, AluminiGalleryMasterFindAllApi, AluminiGalleryMasterUpdateApi } from '../../ApiFile';


export default function AlumniGallery() {
  const [rows, setRows] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [file, setFile] = useState(null);
  const [year, setYear] = useState(""); // State for year
  const [searchText, setSearchText] = useState(""); // State for search text

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get(AluminiGalleryMasterFindAllApi)
      .then(response => {
        // console.log("res table.......",response.data)
        setRows(response.data);
      })
      .catch(error => {
        // console.error(error);
      });
  };

  const handleAddImage = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('year', year); // Append year to form data

    // console.log("FormData:", formData);
  
    axios.post(AluminiGalleryMasterAddApi, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 30000 // Set timeout to 30 seconds (adjust as needed)
    })
  //   .then(response => {
  //     toast.success('Image added successfully');
  //     setOpenAddModal(false);
  //     setFile(null); // Clear file state
  //     setYear(""); // Clear year state

  //     // Add the newly added image to the rows
  //     setRows(prevRows => [...prevRows, response.data]);
  //   })
  //   .catch(error => {
  //     console.error(error);
  //     toast.error('Failed to add image');
  //   });
  // };
  .then(response => {
    // console.log("response....",response)
    if (response.data === "SAVED") {
      // console.log("response....",response.data)
      toast.success('Image added successfully');
      setOpenAddModal(false);
      setFile(null); // Clear file state
      setYear(""); // Clear year state

      // Fetch the updated data after adding the image
      fetchData(); // Assuming fetchData fetches the updated data
    } else {
      // Handle case where the response indicates an error
      toast.error('Failed to add image');
    }
  })
  .catch(error => {
    // console.error(error);
    toast.error('Failed to add image');
  });
};

  const handleViewClick = (row) => {
    setSelectedRow(row);
    setOpenUpdateModal(true);
  };

  const handleUpdateClick = () => {
    const formData = new FormData();
    formData.append('new_img', file);

    axios.put(`${AluminiGalleryMasterUpdateApi}/${selectedRow.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      toast.success('Image updated successfully');
      setOpenUpdateModal(false);
      fetchData(); // Refresh data after update
    })
    .catch(error => {
      // console.error(error);
      toast.error('Failed to update image');
    });
  };

  const handleDeleteClick = (id) => {
    axios.delete(`${AluminiGalleryMasterDeleteApi}/${id}`)
    .then(response => {
      toast.success('Image deleted successfully');
      fetchData(); // Refresh data after deletion
    })
    .catch(error => {
      // console.error(error);
      toast.error('Failed to delete image');
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'for_year', headerName: 'Year', width: 150 },
    { field: 'upload_img', headerName: 'Image Name', width: 150 },
    // { field: 'upload_img', headerName: 'Image', width: 300,
    //   renderCell: (params) => (
    //     <img src={params.row.upload_img} alt="Gallery" style={{ width: '100px', height: 'auto' }} />
    //   )
    // },
    {
      field: 'view',
      headerName: 'View',
      width: 120,
      renderCell: (params) => (
        <Button variant="contained" color="primary" onClick={() => handleViewClick(params.row)}>
          <Visibility fontSize="small" />
        </Button>
      ),
    },
    {
      field: 'update',
      headerName: 'Update',
      width: 120,
      renderCell: (params) => (
        <Button variant="contained" color="primary" startIcon={<Edit />} onClick={() => handleViewClick(params.row)}>
          Update
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 120,
      renderCell: (params) => (
        <Button variant="contained" color="secondary" startIcon={<Delete />} onClick={() => handleDeleteClick(params.row.id)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
     <Sidebar/>
      <section className="home-section">
        <NavLogo/> 
      <Box className='w-100' sx={{ width: '80%', height:'50%', padding:'20px'}}>
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setOpenAddModal(true)}>
          Add Image
        </Button>
        <br></br>
        <TextField
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          fullWidth
          style={{ marginBottom: '1rem', marginLeft: '1rem' }}
        />
        <DataGrid
          columns={columns}
          rows={rows.filter(row => row.upload_img.toLowerCase().includes(searchText.toLowerCase()))}
          disableColumnResize
          disableDensitySelector
          disableColumnHeaderSorting
          disableColumnFilter
          slots={{ toolbar: () => <GridToolbar /> }}
          className="custom-data-grid"
        />
      </Box>

      <Modal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ width:'50%', height:'50%'}}
      >
        <Box sx={{  position: 'relative',
            width: '500px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            p: 4, }}>
          {/* Modal content for adding image */}
          <Typography variant="h6">Add Image</Typography>
          <TextField
            label="Year"
            variant="outlined"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            fullWidth
            style={{ marginBottom: '1rem' }}
          />
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          <Button variant="contained" color="primary" onClick={handleAddImage}>
            Add Image
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ width:'50%', height:'50%'}}
      >
        <Box sx={{  position: 'relative',
            width: '500px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            p: 4, }}>
          {/* Modal content for updating image */}
          <Typography variant="h6">Update Image</Typography>
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          <Button variant="contained" color="primary" onClick={handleUpdateClick}>
            Update Image
          </Button>
        </Box>
      </Modal>

      <ToastContainer /> {/* Toast container */}
      </section>
    </>
  );
}
