import React, { useState, useEffect } from 'react';

import logo1 from './jain logo.png';
import { Col, Row, Spinner } from 'react-bootstrap';

const NavBarStudentReg = () => {
 
   
    return (
        <Row className=' py-1 shadow m-0 p-0 ' style={{ display: "flex",justifyContent:"center", backgroundColor: "#9acac9" }}>
        <Col md={3}>

</Col>
            <Col>
                <img src={logo1} alt="Logo" style={{ mixBlendMode: "darken"}} className='w-100'  />
            </Col>
            <Col md={3}>

            </Col>
        </Row>
    );
}

export default NavBarStudentReg;