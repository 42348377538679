// import React, { useState, useEffect } from "react";
// import { Form, Button, Container, Row, Col } from "react-bootstrap";
// import {
//   Box,
//   Select,
//   MenuItem,
//   Grid,
//   Typography,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   FilledInput,
// } from "@mui/material";
// import { Modal, TextField } from "@mui/material";
// import { FormControl, InputLabel } from "@mui/material";
// import { toast } from "react-toastify";

// import "./Jobpost.css";
// import axios from "axios";
// import Jontable from "./Jontable";

// import Header from "./Header";
// import Sidebar from "./sidebar";
// const Jobpost = ({ onJobPost }) => {
//   const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

//   const OpenSidebar = () => {
//     setOpenSidebarToggle(!openSidebarToggle);
//   };

//   const [showModal, setShowModal] = useState(false);
//   const handleShow = () => {
//     setShowModal(true);
//   };

//   const [jobTitle, setJobTitle] = useState("");
//   const [industryName, setIndustryName] = useState("");
//   const [jobType, setJobType] = useState("");
//   const [location, setLocation] = useState("");
//   const [companyContact, setCompanyContact] = useState("");
//   const [companyEmail, setCompanyEmail] = useState("");
//   const [vacancy, setVacancy] = useState("");
//   const [experience, setExperience] = useState("");
//   const [jobDescription, setJobDescription] = useState("");
//   const [jobCategoryId, setJobCategoryId] = useState("");

//   const handlePostJob = () => {
//     const postData = {
//       job_type: jobType,
//       job_title: jobTitle,
//       discription: jobDescription,
//       experience: experience,
//       industry_name: industryName,
//       contact: companyContact,
//       email_id: companyEmail,
//       address: location,
//       vaccancy: vacancy,
//       job_category: jobCategoryId,
//     };

//     axios
//       .post("http://192.168.29.112:8081/api/jobpost/add", postData)
//       .then((response) => {
//         toast.success("Job Posted successfully!");

//         console.log(response.data);
//         console.log(postData);
//         handleClose();
//       })
//       .catch((error) => {
//         console.error("Error posting job:", error);
//       });
//   };
//   const handleClose = () => {
//     setShowModal(false);
//     setJobTitle("");
//     setJobCategoryId("");
//     setJobType("");
//     setIndustryName("");
//     setLocation("");
//     setCompanyContact("");
//     setCompanyEmail("");
//     setJobDescription("");
//     setExperience("");
//     setVacancy("");
//   };

//   //category api
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await axios.get(
//           "http://192.168.29.112:8081/api/category/findAll"
//         );
//         setCategories(response.data);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchCategories();
//   }, []);

//   return (
//     <>
//       <Sidebar />
//       <section className="home-section">
//         <Header />
//         <div
//           className="scrollable-content"
//           style={{ overflow: "scroll", margin: "0", padding: "0" }}
//         >
//           <Container>
//             <Row className="m-0 p-0 pt-5 ">
//               <Col
//                 className=" rounded pt-2"
//                 style={{
//                   backgroundColor: "white",
//                   border: "0px",
//                   alignItems: "center",
//                   alignContent: "center",
//                   color: " rgb(47, 79, 79)",
//                 }}
//               >
//                 <h3 style={{ color: "maroon" }}>JOB POST</h3>
//               </Col>
//             </Row>
//             <Row className="m-0 p-0 pt-3 ">
//               <Col
//                 className=" rounded text-center pt-2"
//                 style={{
//                   display: "flex",
//                   justifyContent: "start",
//                   backgroundColor: "white",
//                   border: "0px",
//                   alignItems: "center",
//                   alignContent: "center",
//                   color: " rgb(47, 79, 79)",
//                 }}
//               >
//                 <Button
//                   className="m-0 p-0 text-center mb-2 fs-5"
//                   onClick={handleShow}
//                   style={{
//                     width: "80px",
//                     backgroundColor: "rgb(23, 160, 160)",
//                     color: "black",
//                     borderRadius:"5px",
//                     border: "0px",
//                     height: "35px",
//                   }}
//                   open={showModal}
//                   handleClose={handleClose}
//                 >
//                   ADD+
//                 </Button>
//               </Col>
//             </Row>
//             <Row>
//               <Jontable />
//             </Row>
//           </Container>
//           <Modal open={showModal} onClose={handleClose} style={{overflow:"scroll"}}>
//             <Box
//               sx={{
//                 position: "absolute",
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 bgcolor: "background.paper",
//                 boxShadow: 24,
//                 p: 3,
//                 borderRadius: "5px",
//                 width: "90%", // Adjust width as per your requirement
//                 maxWidth: "500px", // Maximum width for larger screens
//                 marginTop: "30px",
//               }}
//             >
//               <Grid container spacing={0.2}>
//                 <Grid
//                   item
//                   xs={12}
//                   sm={12}
//                   style={{ backgroundColor: "#5F9EA0" }}
//                 >
//                   <h2 style={{ textAlign: "center", color: "white" }}>
//                     JOB DETAILS
//                   </h2>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Job Title"
//                     value={jobTitle}
//                     onChange={(e) => setJobTitle(e.target.value)}
//                     variant="outlined"
//                     margin="normal"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Industry Name"
//                     value={industryName}
//                     onChange={(e) => setIndustryName(e.target.value)}
//                     variant="outlined"
//                     margin="normal"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <FormControl
//                     fullWidth
//                     variant="outlined"
//                     margin="normal"
//                     required
//                   >
//                     <InputLabel>Job Type</InputLabel>
//                     <Select
//                       value={jobType}
//                       onChange={(e) => setJobType(e.target.value)}
//                       label="Job Type"
//                     >
//                       <MenuItem value="1">Full Time</MenuItem>
//                       <MenuItem value="2">Part Time</MenuItem>
//                       <MenuItem value="3">Internship</MenuItem>
//                       <MenuItem value="4">Contract</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <FormControl
//                     fullWidth
//                     variant="outlined"
//                     margin="normal"
//                     required
//                   >
//                     <InputLabel>Job Category</InputLabel>
//                     <Select
//                       value={jobCategoryId}
//                       onChange={(e) => setJobCategoryId(e.target.value)}
//                       label="Job Category"
//                     >
//                       {categories.map((category) => (
//                         <MenuItem key={category.id} value={category.id}>
//                           {category.categoryname}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Location"
//                     value={location}
//                     onChange={(e) => setLocation(e.target.value)}
//                     variant="outlined"
//                     margin="normal"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Company Contact"
//                     value={companyContact}
//                     onChange={(e) => setCompanyContact(e.target.value)}
//                     variant="outlined"
//                     margin="normal"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Company Email"
//                     value={companyEmail}
//                     onChange={(e) => setCompanyEmail(e.target.value)}
//                     variant="outlined"
//                     margin="normal"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Vacancies"
//                     value={vacancy}
//                     onChange={(e) => setVacancy(e.target.value)}
//                     variant="outlined"
//                     margin="normal"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Experience"
//                     value={experience}
//                     onChange={(e) => setExperience(e.target.value)}
//                     variant="outlined"
//                     margin="normal"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     fullWidth
//                     multiline
//                     rows={4}
//                     label="Job Description"
//                     value={jobDescription}
//                     onChange={(e) => setJobDescription(e.target.value)}
//                     variant="outlined"
//                     margin="normal"
//                     required
//                   />
//                 </Grid>
//               </Grid>
//               <DialogActions>
//                 <Button
//                   variant="outlined"
//                   color="secondary"
//                   onClick={handleClose}
//                   style={{ backgroundColor: "grey", color:"darkblue" }}
//                 >
//                   Close
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handlePostJob}
//                   style={{ backgroundColor: "rgb(23, 160, 160)",color:"white" }}
//                 >
//                   Post Job
//                 </Button>
//               </DialogActions>
//             </Box>
//           </Modal>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Jobpost;








// import React, { useState, useEffect } from "react";
// import { Container, Row as BRow, Col as BCol } from "react-bootstrap"; // Aliased as BRow and BCol to avoid conflicts
// import { Modal, Button, Form, Input, Select, Row, Col } from 'antd'; // Using Row and Col from antd
// import { toast } from "react-toastify";
// import axios from "axios";

// import Jontable from "./Jontable";
// import Header from "./Header";
// import Sidebar from "./sidebar";
// import "./Jobpost.css";
// import { AluminiJobpostAddApi } from "../../ApiFile";

// const Jobpost = () => {
//   const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
//   const [showModal, setShowModal] = useState(false);

//   const { Option } = Select;
//   const { TextArea } = Input;

//   const [jobTitle, setJobTitle] = useState("");
//   const [industryName, setIndustryName] = useState("");
//   const [jobType, setJobType] = useState("");
//   const [location, setLocation] = useState("");
//   const [jobImage, setJobImage] = useState(null); 
//   const [companyContact, setCompanyContact] = useState("");
//   const [companyEmail, setCompanyEmail] = useState("");
//   const [vacancy, setVacancy] = useState("");
//   const [experience, setExperience] = useState("");
//   const [jobDescription, setJobDescription] = useState("");
//   const [otherQualification, setOtherQualification] = useState("");
//   const [qualification, setQualification] = useState("");
//   const [jobCategoryId, setJobCategoryId] = useState("");
//   const [categories, setCategories] = useState([]);
//   const userdata = JSON.parse(sessionStorage.getItem("userdata"));
//   const aluminiSignupId = userdata.id;

//   const handleShow = () => setShowModal(true);
//   const handleClose = () => {
//     setShowModal(false);
//     resetForm();
//   };

//   const resetForm = () => {
//     setJobTitle("");
//     setJobImage(null);
//     setIndustryName("");
//     setJobType("");
//     setLocation("");
//     setJobCategoryId("");
//     setJobType("");
//     setIndustryName("");
//     setLocation("");
//     setCompanyContact("");
//     setCompanyEmail("");
//     setJobDescription("");
//     setQualification("");
//     setOtherQualification("");
//     setExperience("");
//     setVacancy("");
//   };

//   const handlePostJob = () => {
//     const formData = new FormData();
  
//     // Append fields to the FormData object
//     formData.append("job_type", jobType);
//     formData.append("file", jobImage); // Assuming jobImage is a File object
//     formData.append("flag", 2);
//     formData.append("job_title", jobTitle);
//     formData.append("discription", jobDescription);
//     formData.append("qualification", qualification);
//     formData.append("other_qualification", otherQualification);
//     formData.append("experience", experience);
//     formData.append("industry_name", industryName);
//     formData.append("contact", companyContact);
//     formData.append("email_id", companyEmail);
//     formData.append("address", location);
//     formData.append("vaccancy", vacancy);
//     formData.append("job_category", jobCategoryId);
//     formData.append("aluminiSignupId", aluminiSignupId);
  
//     axios
//       .post(AluminiJobpostAddApi, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       })
//       .then((response) => {
//         console.log("if");
//         toast.success("Job Posted successfully!");
//         console.log(response.data);
//         handleClose();
//       })
//       .catch((error) => {
//         console.log("else");
//         console.error("Error posting job:", error);
//         toast.error("Failed to post job.");
//       });
//   };
  

//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await axios.get("http://192.168.29.112:8081/api/category/findAll");
//         setCategories(response.data);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };
//     fetchCategories();
//   }, []);

//   return (
//     <>
//       <Sidebar />
//       <section className="home-section">
//         <Header />
//         <div
//           className="scrollable-content"
//           style={{ overflow: "scroll", margin: "0", padding: "0" }}
//         >
//           <Container>
//             <BRow className="m-0 p-0 pt-5 ">
//               <BCol
//                 className="rounded pt-2"
//                 style={{
//                   backgroundColor: "white",
//                   border: "0px",
//                   alignItems: "center",
//                   alignContent: "center",
//                   color: " rgb(47, 79, 79)",
//                 }}
//               >
//                 <h3 style={{ color: "maroon" }}>JOB POST</h3>
//               </BCol>
//             </BRow>
//             <BRow className="m-0 p-0 pt-3 ">
//               <BCol
//                 className="rounded text-center pt-2"
//                 style={{
//                   display: "flex",
//                   justifyContent: "start",
//                   backgroundColor: "white",
//                   border: "0px",
//                   alignItems: "center",
//                   alignContent: "center",
//                   color: " rgb(47, 79, 79)",
//                 }}
//               >
//                 <Button
//                   className="m-0 p-0 text-center mb-2 fs-5"
//                   onClick={handleShow}
//                   style={{
//                     width: "80px",
//                     backgroundColor: "rgb(23, 160, 160)",
//                     color: "black",
//                     borderRadius: "5px",
//                     border: "0px",
//                     height: "35px",
//                   }}
//                 >
//                   ADD+
//                 </Button>
//               </BCol>
//             </BRow>
//             <BRow>
//               <Jontable />
//             </BRow>
//           </Container>

//         <Modal
//   open={showModal}
//   onCancel={handleClose}
//   footer={null}
//   style={{ overflow: "scroll" }}
// >
//   <div
//     style={{
//       backgroundColor: "white",
//       boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
//       padding: "24px",
//       borderRadius: "5px",
//       width: "100%",
//       maxWidth: "500px",
//       marginTop: "30px",
//     }}
//   >
//     <Row gutter={[16, 16]}>
//       <Col span={24} style={{ backgroundColor: "#5F9EA0" }}>
//         <h4 style={{ textAlign: "center", color: "white" }}>JOB DETAILS</h4>
//       </Col>
//       <Col xs={24} md={12}>
//         <Form.Item label="Job Title" className="m-0 p-0" name="job_title" rules={[{ required: true }]}>  </Form.Item>
//           <Input value={jobTitle} className="w-100 " onChange={(e) => setJobTitle(e.target.value)} />
      
//       </Col>
//       <Col xs={24} md={12}>
//         <Form.Item label="Industry Name" className="m-0 p-0" name="industry_name" rules={[{ required: true }]}>     </Form.Item>
//           <Input value={industryName} className="w-100 " onChange={(e) => setIndustryName(e.target.value)} />
   
//       </Col>
//       <Col xs={24} md={12}>
//         <Form.Item label="Job Type" className="m-0 p-0" name="job_type" rules={[{ required: true }]}>    </Form.Item>
//           <Select value={jobType} className="w-100 " onChange={(value) => setJobType(value)}>
//             <Option value="1">Full Time</Option>
//             <Option value="2">Part Time</Option>
//             <Option value="3">Internship</Option>
//             <Option value="4">Contract</Option>
//           </Select>
    
//       </Col>
//       <Col xs={24} md={12}>
//         <Form.Item label="Job Category" className="m-0 p-0" name="job_category" rules={[{ required: true }]}>
//         </Form.Item>
//           <Select className="w-100 "  value={jobCategoryId} onChange={(value) => setJobCategoryId(value)}>
//             {categories.map((category) => (
//               <Option key={category.id} value={category.id}>
//                 {category.categoryname}
//               </Option>
//             ))}
//           </Select>
   
//       </Col>
//       <Col xs={24} md={12}>
//         <Form.Item label="Location" className="m-0 p-0" name="address" rules={[{ required: true }]}></Form.Item>
//           <Input className="w-100 " value={location} onChange={(e) => setLocation(e.target.value)} />
        
//       </Col>
//       <Col xs={24} md={12}>
//         <Form.Item label="Company Contact" className="m-0 p-0" name="contact" rules={[{ required: true }]}> </Form.Item>
//           <Input className="w-100 " value={companyContact} onChange={(e) => setCompanyContact(e.target.value)} />
       
//       </Col>
//       <Col xs={24} md={12}>
//         <Form.Item label="Company Email" className="m-0 p-0" name="email_id" rules={[{ required: true }]}>        </Form.Item>
//           <Input className="w-100" value={companyEmail} onChange={(e) => setCompanyEmail(e.target.value)} />

//       </Col>
//       <Col xs={24} md={12}>
//         <Form.Item label="Vacancies" className="m-0 p-0" name="vaccancy" rules={[{ required: true }]}>        </Form.Item>
//           <Input className="w-100 " value={vacancy} onChange={(e) => setVacancy(e.target.value)} />

//       </Col>
      
//       <Col xs={24} md={12}>
//         <Form.Item label="Qualification" className="m-0 p-0" name="qualification">      </Form.Item>
//           <Input className="w-100"  value={qualification}
//             onChange={(e) => setQualification(e.target.value)} />
  
//       </Col>
//       <Col xs={24} md={12}>
//         <Form.Item label="Other Qualification" className="m-0 p-0" name="other_qualification">      </Form.Item>
//           <Input className="w-100"  value={otherQualification}
//             onChange={(e) => setOtherQualification(e.target.value)} />
  
//       </Col>
//       <Col xs={24} md={12}>
//         <Form.Item label="Experience" className="m-0 p-0" name="experience" rules={[{ required: true }]}>     </Form.Item>
//           <Input className="w-100" value={experience} onChange={(e) => setExperience(e.target.value)} />
   
//       </Col>

//       <Col xs={24} md={12}>
//         <Form.Item label="Ref Image If" className="m-0 p-0" name="file" rules={[{ required: true }]}>     </Form.Item>
//           <Input className="w-100"   type="file"
//                   onChange={(e) => setJobImage(e.target.files[0])}/>
   
//       </Col>
      
//       <Col xs={24} md={24}>
//         <Form.Item label="Job Description" className="m-0 p-0" name="discription" rules={[{ required: true }]}>     </Form.Item>
//           <TextArea
//             value={jobDescription}
//             className="w-100 "
//             onChange={(e) => setJobDescription(e.target.value)}
//             rows={4}
//           />
   
//       </Col>
//     </Row>
//     <div
//       className="text-center"
//       style={{ marginTop: "24px", display: "flex", justifyContent: "center" }}
//     >
//       <Button
//         type="submit"
//         onClick={handlePostJob}
//         style={{ backgroundColor: "#00CED1", color: "black" }}
//       >
//         Post Job
//       </Button>
//     </div>
//   </div>
// </Modal>

//         </div>
//       </section>
//     </>
//   );
// };

// export default Jobpost;




import React, { useState, useEffect } from 'react';
import { Button, Box, Select, MenuItem, Grid, Typography, DialogActions, IconButton, Modal, TextField, FormControl, InputLabel } from '@mui/material';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact'; // Import MDBDataTable here
import Jontable from './Jontable';
import { AluminiJobDelete, AluminiJobpostAddApi, AluminiJobpostFindAllApi, AluminiJobpostFindoneApi, AluminiJobpostUpdateApi, JobPostAddApi, JobPostAdminDelete,  JobPostAdminFindAll,  JobPostAdminUpdate, JobPostFindAllApi1, JobPostFindAllApi2 } from '../../ApiFile';

import { Col, Row } from 'react-bootstrap';
import Sidebar from './sidebar';
import Header from './Header';

const Jobpost = () => {
  const [showModal, setShowModal] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [jobPosts, setJobPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [jobTitle, setJobTitle] = useState('');
  const [industryName, setIndustryName] = useState('');
  const [jobType, setJobType] = useState('');
  const [location, setLocation] = useState('');
  const [companyContact, setCompanyContact] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [vacancy, setVacancy] = useState('');
  const [experience, setExperience] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [jobCategoryId, setJobCategoryId] = useState('');
  const [jobImage, setJobImage] = useState(null); // New state for image
  const [currentJobId, setCurrentJobId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [qualification, setQualification] = useState('');
  const [otherQualification, setOtherQualification] = useState(''); 
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
    const aluminiSignupId = userdata.id;

  useEffect(() => {
    fetchJobPosts();
    fetchCategories();
  }, []);

  const fetchJobPosts = async () => {
    try {
      const response = await axios.get(`${AluminiJobpostFindoneApi}/${aluminiSignupId}`);
      setJobPosts(response.data);
    } catch (error) {
      console.error('Error fetching job posts:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(JobPostFindAllApi1);
      console.log("ghkk",response.data);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    resetForm();
  };

  const handleConfirmOpen = () => setConfirmOpen(true);
  const handleConfirmClose = () => setConfirmOpen(false);

  const resetForm = () => {
    setJobTitle('');
    setIndustryName('');
    setJobType('');
    setLocation('');
    setCompanyContact('');
    setCompanyEmail('');
    setVacancy('');
    setExperience('');
    setJobDescription('');
    setJobCategoryId('');
    setJobImage(null); // Reset the image state
    setCurrentJobId(null);
    setQualification(''); // Reset the qualification state
    setOtherQualification(''); 
  };

  const handlePostJob = async () => {
    if (!jobType || !jobTitle || !jobDescription || !experience || !industryName || !companyContact || !companyEmail || !location || !vacancy || !jobCategoryId || !qualification ) {
      toast.error("All fields are required", { position: "top-right", autoClose: 3000 });
      return;
    }

    const formData = new FormData();
    formData.append('job_type', jobType);
    formData.append('flag', 2);
    formData.append('job_title', jobTitle);
    formData.append('discription', jobDescription);
    formData.append('experience', experience);
    formData.append('industry_name', industryName);
    formData.append('contact', companyContact);
    formData.append('email_id', companyEmail);
    formData.append('address', location);
    formData.append('vaccancy', vacancy);
    formData.append('job_category', jobCategoryId);
    formData.append('file', jobImage);
    formData.append('qualification', qualification); // Append the qualification
    formData.append('other_qualification', otherQualification); 
    formData.append('aluminiSignupId', aluminiSignupId); 

    try {
      if (currentJobId) {
        console.log("id....",`${AluminiJobpostUpdateApi}/${currentJobId}`);
        await axios.put(`${AluminiJobpostUpdateApi}/${currentJobId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Job updated successfully!');
      } else {
        // Add new job post
        await axios.post(AluminiJobpostAddApi, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Job posted successfully!');
      }
      fetchJobPosts();
      handleClose();
    } catch (error) {
      toast.error('Failed to save job');
    }
  };

  const handleEditJob = (job) => {
    setJobTitle(job.job_title);
    setIndustryName(job.industry_name);
    setJobType(job.job_type);
    setLocation(job.address);
    setCompanyContact(job.contact);
    setCompanyEmail(job.email_id);
    setVacancy(job.vaccancy);
    setJobDescription(job.discription);
    setExperience(job.experience);
    // setJobImage(job.jobpost_image)
    setJobCategoryId(job.job_category);
    setCurrentJobId(job.id);
    setQualification(job.qualification); // Set the qualification
    setOtherQualification(job.other_qualification); 
    handleShow();
  };

  const handleDeleteJob = async () => {
    try {
      const response = await axios.delete(`${AluminiJobDelete}/${deleteId}`);
      if (response.data === 'DELETE') {
        toast.success('Job deleted successfully!');
        fetchJobPosts();
      } else {
        toast.error('Failed to delete job');
      }
      handleConfirmClose();
    } catch (error) {
      toast.error('Failed to delete job');
    }
  };

  const columns = [
    { label: 'S.No', field: 'sno', sort: 'asc' },
    { label: 'Job Title', field: 'jobtitle', sort: 'asc', width:150},
    { label: 'Job Category', field: 'jobCategoryId', sort: 'asc',  width:150},
    { label: 'Job Posted Date', field: 'job_postdate', sort: 'asc',  width:150},
    { label: 'Employer', field: 'industryname', sort: 'asc', width:150 }, 
    { label: 'Job Type', field: 'jobtype', sort: 'asc', width:150},
    { label: 'Location', field: 'location', sort: 'asc' },
    { label: 'Company Contact', field: 'companycontact', sort: 'asc' , width:200},
    { label: 'Company Email', field: 'companyemail', sort: 'asc' , width:150},
    { label: 'Vacancies', field: 'vacancies', sort: 'asc' },
    { label: 'Experience', field: 'experience', sort: 'asc' },
    { label: 'Qualification', field: 'qualification', sort: 'asc' },
    { label: 'Other Qualification', field: 'other_qualification', sort: 'asc' , width:150},
    {
      label: 'Edit',
      field: 'edit',
      sort: 'asc',
      width: 100,
    },
    {
      label: 'Delete',
      field: 'delete',
      sort: 'asc',
      width: 100,
    },
  ];

  const rows = jobPosts.map((job, index) => ({
    sno: index + 1,
    jobtitle: job.job_title,
    jobCategoryId: job.categoryname,
    job_postdate: job.job_postdate,
    industryname: job.industry_name,
    jobtype: job.job_type === 1 ? 'Full-Time' : job.job_type === 2 ? 'Part-Time' : job.job_type === 3 ? 'Internship' : job.job_type === 4 ? 'Contract' : '-',
    location: job.address,
    companycontact: job.contact,
    companyemail: job.email_id,
    vacancies: job.vaccancy,
    experience: job.experience,
    qualification: job.qualification,
    other_qualification: job.other_qualification,
    edit: (
      <IconButton onClick={() => handleEditJob(job)}>
        <EditIcon color="primary" />
      </IconButton>
    ),
    delete: (
      <IconButton onClick={() => {
        setDeleteId(job.id);
        handleConfirmOpen();
      }}>
        <DeleteIcon color="secondary" />
      </IconButton>
    ),
  }));

  const data = { columns, rows };

  return (
    <>
       <Sidebar />
     <section className="home-section pb-5">
       <Header />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Job Post
          </h2>
          <Button
            className="ms-md-5 m-0 ms-3 mb-2 mb-md-4 mt-2 mt-md-4"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleShow}
          >
            Add Job Post
          </Button>
        </div>

        <Row className="m-0 pt-1 px-2 px-md-3 mb-5 rounded">
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable striped className="custom-datatable text-center" bordered hover data={data} />
              </div>
            </div>
          </Col>
        </Row>

        <Modal open={showModal} onClose={handleClose} style={{overflow:"scroll"}}>
          <div style={{ padding: 20, backgroundColor: '#fff', borderRadius: 8, maxWidth: 800, margin: 'auto', marginTop:"5%", marginBottom:"5%" }}>
            <Typography variant="h6" component="h2" align="center" style={{ backgroundColor: "#5F9EA0", color: 'black', padding: 10 }}>
              Job Details
            </Typography>
            <Row container spacing={2}>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Job Title"
                  size='small'
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  inputProps={{ maxLength: 40 }} 
                />
              </Col>
              <Col item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" margin="normal" size='small' required>
                  <InputLabel>Job Category</InputLabel>
                  <Select
                    value={jobCategoryId}
                    onChange={(e) => setJobCategoryId(e.target.value)}
                    label="Job Category"
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.categoryname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Employer"
                  size='small'
                  value={industryName}
                  onChange={(e) => setIndustryName(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  inputProps={{ maxLength: 30 }} 
                  required
                />
              </Col>
              <Col item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" margin="normal" required size='small'>
                  <InputLabel>Job Type</InputLabel>
                  <Select
                    value={jobType}
                    onChange={(e) => setJobType(e.target.value)}
                    label="Job Type"
                  >
                    <MenuItem value={1}>Full-Time</MenuItem>
                    <MenuItem value={2}>Part-Time</MenuItem>
                    <MenuItem value={3}>Internship</MenuItem>
                    <MenuItem value={4}>Contract</MenuItem>
                  </Select>
                </FormControl>
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Qualification"
                   size='small'
                   className='mt-2'
                   required
                  inputProps={{ maxLength: 40 }} 
                  variant="outlined"
                  value={qualification}
                  onChange={(e) => setQualification(e.target.value)}
                />
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Other Qualification"
                        className='mt-2'
                   size='small'
                  inputProps={{ maxLength: 40 }} 
                  variant="outlined"
                  value={otherQualification}
                  onChange={(e) => setOtherQualification(e.target.value)}
                />
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Location"
                  size='small'
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  variant="outlined"
                  inputProps={{ maxLength: 60 }} 
                  margin="normal"
                  required
                />
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Company Contact"
                  value={companyContact}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setCompanyContact(value);
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  inputProps={{ 
                    maxLength: 10, 
                    pattern: "[0-9]*",
                    inputMode: 'numeric' 
                  }}
                />
              </Col>

              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company Email"
                  size='small'
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                />
              </Col>
              <Col item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Vacancies"
                size="small"
                value={vacancy}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setVacancy(value);
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                inputProps={{
                  inputMode: 'numeric', // Brings up the numeric keypad on mobile devices
                  pattern: '[0-9]*'     // Ensures only numeric input is allowed
                }}
              />
            </Col>

              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Experience"
                  size='small'
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  variant="outlined"
                  inputProps={{ maxLength: 20 }} 
                  margin="normal"
                  required
                />
              </Col>
              <Col item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="file"
                   label="Ref Image If"
                  size='small'
                  onChange={(e) => setJobImage(e.target.files[0])}
                  variant="outlined"
                  margin="normal"
                />
              </Col>
              <Col item xs={12}>
                <TextField
                  fullWidth
                  label="Job Description"
                  size='small'
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  inputProps={{ maxLength: 90 }} 
                  multiline
                  rows={3}
                  required
                />
              </Col>
           
            </Row>
            <DialogActions>
              <Button onClick={handleClose} style={{backgroundColor:"rgb(156, 39, 176)" , color:"white"}}>Cancel</Button>
              <Button onClick={handlePostJob} style={{backgroundColor:"rgb(0, 120, 215)",color:"white"}}>
                {currentJobId ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </div>
        </Modal>
        <Modal open={confirmOpen} onClose={handleConfirmClose}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            borderRadius: '5px',
            width: '90%',
            maxWidth: '500px',
          }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Are you sure you want to delete this job post?
            </Typography>
            <DialogActions>
              <Button onClick={handleConfirmClose} color="secondary">Cancel</Button>
              <Button onClick={handleDeleteJob} color="primary">Confirm</Button>
            </DialogActions>
          </Box>
        </Modal>
      </section>
    </>
  );
};

export default Jobpost;
