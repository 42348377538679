import react, { Fragment } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';


function TandC(){
    return(
        <div>
        <Navbar></Navbar>
        {/* Navbar & Hero End */}
        {/* Header Start */}
        <div className="container-fluid bg-breadcrumbT">
          <div className="container  py-5" style={{maxWidth: '900px'}}>
            <h3 className=" display-3 mb-4" style={{color: 'white', textShadow: '3px 3px #ee1515', fontSize: '50px', textAlign: 'center'}}>
            </h3>
          </div>
        </div>
        {/* Header End */}
        {/* Packages Start */}
        {/* Packages End */}
        {/* Tour Booking Start */}
        <div className="main">
          <div className="content" style={{textAlign: 'left', margin: '30px 60px', borderRadius: '20px', padding: '30px', backgroundColor: '#ebf2fc'}}>
            <h3 style={{marginLeft: '0px', textAlign: 'center', color: '#ee1515'}}>Terms &amp; Conditions</h3>
            <p>These terms and conditions (“Terms and Conditions”) control your use of this website: www.jainboardingsangli.com (Website).</p>
            <p>You refers to a user or a paying customer. If You are a company or another person who gives access to Company products, you agree to take responsibility in full in case of damages or indemnification that could properly lie against the customer.</p>
            <p>By using or accessing the information on this website, including, but not limited to downloading or accessing courses through this Website or through designated Software of the Company; You agree to abide by the terms and conditions set forth in these “Terms and Conditions”:</p>
            {/* General Terms of Use */}
            <h5 style={{color: '#ee1515'}}>GENERAL TERMS OF USE</h5>
            <ul className="bullet-list">
              <li>Registering at this Website does not entitle You to the right of admission/selection for any course. The Company Selection Committee reserves the right to select or reject a candidate without any notice or assigning any reason whatsoever.</li>
              <li>Selected candidates are required to comply with the rules laid down under the various Policies and jainboardingsangli, reserves the right to expel a student or terminate the candidature at any time if found otherwise.</li>
              <li>As a user, You agree to maintain (i) all content in the account including but not limited to academic content, assessments, email/SMS content, profile information and current billing and contact information, (ii) Email/mobile phone number list and Address Book maintenance including opt-out contact information and immediate removal of opt-outs and bounces from email/mobile phone number lists and the address book, (iii) Payment of all agreed course fees (iv) full responsibility for all activity associated with your use of this Website and services and (v) compliance with all other obligations set forth in these Terms and Conditions.</li>
              <li>You will provide current, complete, accurate information in the registration section of this Website. You will further update and keep that information current as needed. Email login must be a valid email address maintained by You. You are solely responsible for maintaining the confidentiality of your password and account information. You will immediately notify the Company of any unauthorized account activity, or any unauthorized use of your email list(s) or any other breach of security You become aware of by emailing at: enquiry.dtd@ jainboardingsangli.in.</li>
              <li>Excluding third party course materials, jainboardingsangli owns all copyrights, patents, trade secrets, trademarks and other intellectual property rights, title and interest in or pertaining to the courses in the course catalogue and all other services developed by jainboardingsangli for the purpose of these courses on offer. No resale, transfer or re-export of the course(s) and/or content is authorized without the prior authorization of jainboardingsangli.</li>
              <li>jainboardingsangli reserves the right to change course prices at any time. In the event of such change, confirmed registrations will be honored at the original price. Changes to Service Tax and other tax and duty components will be incorporated into the Course Fee as and when such changes occur and the student will become liable to pay the incremental fee.</li>
              <li>Course content for all the courses on offer is provided for the purpose of education and guidance only. The course content is regularly reviewed and is subjected to change without notice. jainboardingsangli reserves the right to modify training content without notice.</li>
              <li>jainboardingsangli may terminate your access at any time for any reason. The provisions regarding a disclaimer of warranty, accuracy of information and indemnification shall survive such termination. jainboardingsangli may also monitor access to this Website.</li>
              <li>To the extent allowed by local law, in no event will jainboardingsangli or its affiliates, subcontractors or suppliers be liable for any incidental, indirect, special consequential damages.</li>
              <li>By registering on this Website, You give your consent to receive, from time to time, text messages from jainboardingsangli which may include offers from Us or our affiliates and partners.</li>
            </ul>
            {/* Online Payment Terms */}
            <h5 style={{color: '#ee1515'}}>ONLINE PAYMENT TERMS</h5>
            <ul className="bullet-list">
              <li>The following section states the Terms and Conditions which will be applicable to the user using the Company’s on-line payment process for various courses/services offered by Us. Please read this page carefully. If these Terms &amp; Conditions are not acceptable to You, please do not use this process. By using Company’s online payment process, You are indicating your acceptance to be bound by these Terms &amp; Conditions. jainboardingsangli reserves the right to revise these Terms &amp; Conditions from time to time.</li>
              <li>User information entered during the online payment process is kept secure and confidential and will not be divulged to any third party, other than the payment gateway service provider.</li>
              <li>The Company will not be held responsible for any damages the user may suffer as a result of using the payment process. The user agrees and shall defend, indemnify, save and hold the Company harmless from any and all demands, liabilities, losses, costs and claims that may arise or result from incorrect use of the online payment process or any other causes attributable to the user.</li>
              {/* Add the rest of the list items here */}
            </ul>
          </div>
          <style dangerouslySetInnerHTML={{__html: "\n    /* Style for the bullet list */\n.bullet-list li {\n    margin-bottom: 10px;\n}\n\n/* Style for the first bullet list */\n.bullet-list:first-child li {\n    list-style-type: circle;\n}\n\n/* Style for the second bullet list */\n.bullet-list:nth-child(2) li {\n    list-style-type: square;\n}\n\n\n " }} />  
          {/* Tour Booking End */}
          {/* Subscribe Start */}
          {/* Subscribe End */}
          {/* Footer Start */}
          <Footer/>
      {/* Copyright End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up" /></a>
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
        </div></div>
    )
}
export default TandC;