// import React, { useState, useEffect } from 'react';
// import './Block.css'; // Import CSS file
// import DeleteIcon from '@mui/icons-material/Delete';
// import UpdateIcon from '@mui/icons-material/Update';
// import AddIcon from '@mui/icons-material/Add'; // Import Add icon
// import Button from '@mui/material/Button'; // Import Button from Material-UI
// import Modal from '@mui/material/Modal'; // Import Modal from Material-UI
// import TextField from '@mui/material/TextField'; // Import TextField from Material-UI
// import Typography from '@mui/material/Typography'; // Import Typography from Material-UI
// import Box from '@mui/material/Box'; // Import Box from Material-UI
// import Sidebar from '../sidebar';
// import NavLogo from '../NavLogo';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { Col, Row } from 'react-bootstrap';
// import { MDBDataTable } from 'mdbreact'; // Import MDBDataTable

// const Block = () => {
//   const [blocks, setBlocks] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [showUpdateModal, setShowUpdateModal] = useState(false); // New state for update modal
//   const [blockName, setBlockName] = useState('');
//   const [year, setYear] = useState('');
//   const [room_range, setRoom_range ] = useState('');
//   const [discription, setDiscription ] = useState('');
//   const [bed_count, setBed_count] = useState('');
//   const [hostel_fee, setHostel_fee] = useState('');
//   const [deposit, setDeposit] = useState('');
//   const [amount, setAmount] = useState('');
//   const [image, setImage] = useState(null);
//   const [image1, setImage1] = useState(null);
//   const [updateBlockData, setUpdateBlockData] = useState({
//     id: '',
//     year: '',
//     block_id: '',
//     room_range: '',
//     discription: '',
//     bed_count: '',
//     hostel_fee: '',
//     deposit: ''
//   });

//   useEffect(() => {
//     fetchBlocks();
//   }, []);

//   const fetchBlocks = async () => {
//     try {
//       const response = await fetch('http://192.168.29.112:8081/api/block/find');
//       if (!response.ok) {
//         throw new Error('Failed to fetch blocks');
//       }
//       const data = await response.json();
//       console.log("Fetched data:", data);
//       setBlocks(data);
//     } catch (error) {
//       console.error('Error fetching blocks:', error);
//     }
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setBlockName('');
//   };

//   const handleShowModal = () => setShowModal(true);

//   const handleAddBlock = async () => {
//     let formData = new FormData();
//     try {
//       if (
//         blockName.trim() !== '' || 
//         year.trim() !== '' || 
//         room_range.trim() !== '' || 
//         bed_count.trim() !== '' || 
//         discription.trim() !== '' || 
//         hostel_fee.trim() !== '' || 
//         deposit.trim() !== ''
//       ) {
//         // Append the form data
//         formData.append('year', year);
//         formData.append('block_id', blockName);
//         formData.append('room_range', room_range);
//         formData.append('bed_count', bed_count);
//         formData.append('discription', discription);
//         formData.append('hostel_fee', hostel_fee);
//         formData.append('deposit', deposit);
//         formData.append('file', image); 
//         formData.append('file1', image1); 
  
//         // Send the form data using axios
//         const response = await axios.post('http://192.168.29.112:8081/api/block/add', formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data'
//           }
//         });
  
//         if (response.data === 'SAVED') {
//           toast.success('Saved Successfully !!!!!', {
//             position: 'top-right',
//             autoClose: 3000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//           });
  
//           fetchBlocks();
//           handleCloseModal();
//         } else {
//           console.error('Failed to save block');
//         }
//       }
//     } catch (error) {
//       console.error('Error adding block:', error);
//     }
//   };
  

//   const handleDeleteBlock = async (blockId) => {
//     try {
//       const response = await fetch(`http://192.168.29.112:8081/api/block/delete/${blockId}`, {
//         method: 'DELETE',
//       });
//       if (!response.ok) {
//         throw new Error('Failed to delete block');
//       }
//       fetchBlocks();
//     } catch (error) {
//       console.error('Error deleting block:', error);
//     }
//   };

//   const handleUpdateModalOpen = (block) => {
//     setUpdateBlockData({
//       id: block.id,
//       year: block.year,
//       block_id: block.block_id,
//       room_range: block.room_range,
//       discription: block.discription,
//       bed_count: block.bed_count,
//       hostel_fee: block.hostel_fee,
//       deposit: block.deposit
//     });
//     setShowUpdateModal(true);
//   };

//   const handleUpdateModalClose = () => {
//     setShowUpdateModal(false);
//   };

//   const handleUpdateBlock = async () => {
//     try {
//       const updateData = {
//         year: updateBlockData.year,
//         block_id: updateBlockData.block_id,
//         room_range: updateBlockData.room_range,
//         discription: updateBlockData.discription,
//         bed_count: updateBlockData.bed_count,
//         hostel_fee: updateBlockData.hostel_fee,
//         deposit: updateBlockData.deposit
//       };
  
//       console.log('Update Data:', updateData);
//       console.log('Update api:', `http://192.168.29.112:8081/api/block/update/${updateBlockData.id}`);
 
//       const response = await axios.put(`http://192.168.29.112:8081/api/block/update/${updateBlockData.id}`, updateData);
  
//       console.log('Response:', response);
  
//       if(response.data === 'NOTFOUND'){
//         alert('Block not found')
//       }
//     else if (response.data.message === "UPDATE") {
//         toast.success('Updated Successfully !!!!!', {
//           position: 'top-right',
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//         });
  
//         fetchBlocks();
//         handleUpdateModalClose();
//       } else {
//         console.error('Failed to update block');
//       }
//     } catch (error) {
//       console.error('Error updating block:', error);
//     }
//   };
  

//   // const columns = [
//   //   { label: 'Sr.no', field: 'srNo', sort: 'asc' },
//   //   { label: 'Block Number', field: 'blockNumber'},
//   //   { label: 'Year', field: 'year'},
//   //   { label: 'Room Range', field: 'roomRange'},
//   //   { label: 'Total Bed Count', field: 'bedCount'},
//   //   { label: 'Hostel Fee', field: 'hostelFee'},
//   //   { label: 'Deposit', field: 'deposit'},
//   //   { label: 'Block Image', field: 'file'},
//   //   { label: 'Room Image', field: 'file1'},
//   //   { label: 'Facilities', field: 'discription'},
//   //   {
//   //     label: 'Update',
//   //     field: 'update',
//   //     width: 100,
//   //   }
//   // ];
  
//   // const rows = blocks.map((block, index) => ({
//   //   srNo: index + 1,
//   //   blockNumber: block.block_id,
//   //   year: block.year,
//   //   roomRange: block.room_range,
//   //   bedCount: block.bed_count,
//   //   hostelFee: block.hostel_fee,
//   //   deposit: block.deposit,
//   //   file: block.room_img,
//   //   file1: block.bed_img,
//   //   discription: block.discription,
//   //   update: (
//   //     <button
//   //       className='m-1'
//   //       style={{ color: "rgb(0, 123, 255)", border: "0px", backgroundColor: "transparent" }}
//   //       onClick={() => handleUpdateModalOpen(block)}
//   //     >
//   //       <i className="fa-solid fa-pen-to-square"></i>
//   //     </button>
//   //   )
//   // }));


//   const handleImageChange = (e) => {
//     setImage(e.target.files[0]);
//   };
//   const handleImageChange1 = (e) => {
//     setImage1(e.target.files[0]);
//   };

//    return (
//     <>
//       <Sidebar/>
//       <section className="home-section">
//         <NavLogo/>
//         <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px', backgroundColor: '#d7eeed' }}>
 
//     <h3>Block Master</h3>
  
//   <Button variant="contained" color="primary" onClick={handleShowModal}>
//     <AddIcon /> Add Block
//   </Button>

  
 
//   <Row className="m-0 pt-1 px-2 mx-md-3 mb-3 rounded" >
//       <Col className="m-0 p-0 px-3 py-3 rounded " style={{ background: "white", width: "100%", overflowX: "auto" }}>
//         <MDBDataTable
//           striped
//           className='custom-datatable'
//           bordered
//           hover
//           data={{
//             columns: [
//               { label: 'Sr.no', field: 'srNo', sort: 'asc' },
//               { label: 'Block Number', field: 'blockNumber'},
//               { label: 'Year', field: 'year'},
//               { label: 'Room Range', field: 'roomRange'},
//               { label: 'Total Bed Count', field: 'bedCount'},
//               { label: 'Hostel Fee', field: 'hostelFee'},
//               { label: 'Deposit', field: 'deposit'},
//               { label: 'Block Image', field: 'file'},
//               { label: 'Room Image', field: 'file1'},
//               { label: 'Facilities', field: 'discription'},
//               { label: 'Update', field: 'update', width: 100 },
//             ],
//             rows: blocks.map((block, index) => ({
//               srNo: index + 1,
//               blockNumber: block.block_id,
//               year: block.year,
//               roomRange: block.room_range,
//               bedCount: block.bed_count,
//               hostelFee: block.hostel_fee,
//               deposit: block.deposit,
//               file: block.room_img,
//               file1: block.bed_img,
//               discription: block.discription,
//               update: (
//                 <button
//                   className='m-1'
//                   style={{ color: "rgb(0, 123, 255)", border: "0px", backgroundColor: "transparent" }}
//                   onClick={() => handleUpdateModalOpen(block)}
//                 >
//                   <i className="fa-solid fa-pen-to-square"></i>
//                 </button>
//               )
//             }))
//           }}
//         />
//       </Col>
//     </Row>

//   <Modal open={showModal} onClose={handleCloseModal}>
//     <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 4, width: '70%', boxShadow: 24 }}>
//       <Typography variant="h4" component="h2" gutterBottom className='mb-md-4 mb-2'>
//         Add Block
//       </Typography>
//       <Row>
//         <Col ms={12} md={6}>
//           <TextField label="Block Name" size='small' value={blockName} onChange={(e) => setBlockName(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//           <TextField label="Year" size='small' value={year} onChange={(e) => setYear(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//           <TextField label="Room Range" size='small' value={room_range} onChange={(e) => setRoom_range(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//           <TextField label="Total Bed Count" size='small' value={bed_count} onChange={(e) => setBed_count(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//           <TextField label="Hostel Fee" size='small' value={hostel_fee} onChange={(e) => setHostel_fee(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//           <TextField label="Deposit" size='small' value={deposit} onChange={(e) => setDeposit(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//             <input type="file" className='w-100' onChange={handleImageChange} style={{ marginBottom: '20px',borderRadius:"0px" }} />
//           </Col><Col ms={12} md={6}>
//             <input type="file" className='w-100' onChange={handleImageChange1} style={{ marginBottom: '20px',borderRadius:"0px" }} />
//           </Col>
//         <Col ms={12} md={12}>
//           <TextField label="Facilities" size='small' value={discription} onChange={(e) => setDiscription(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
        
//       </Row>
//       <Button variant="contained" color="primary" onClick={handleAddBlock} style={{ marginRight: '10px' }}>
//         Add
//       </Button>
//       <Button variant="contained" onClick={handleCloseModal}>
//         Cancel
//       </Button>
//     </Box>
//   </Modal>

//   {/* Update Block Modal */}
//   <Modal open={showUpdateModal} onClose={handleUpdateModalClose}>
//     <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 4, width: '70%', boxShadow: 24 }}>
//       <Typography variant="h4" component="h2" gutterBottom className='mb-md-4 mb-2'>
//         Update Block
//       </Typography>
//       <Row>
//         <Col ms={12} md={6}>
//           <TextField label="Block Name" value={updateBlockData.block_id} onChange={(e) => setUpdateBlockData({ ...updateBlockData, block_id: e.target.value })} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//           <TextField label="Year" value={updateBlockData.year} onChange={(e) => setUpdateBlockData({ ...updateBlockData, year: e.target.value })} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//           <TextField label="Room Range" value={updateBlockData.room_range} onChange={(e) => setUpdateBlockData({ ...updateBlockData, room_range: e.target.value })} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//           <TextField label="Total Bed Count" value={updateBlockData.bed_count} onChange={(e) => setUpdateBlockData({ ...updateBlockData, bed_count: e.target.value })} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//           <TextField label="Hostel Fee" value={updateBlockData.hostel_fee} onChange={(e) => setUpdateBlockData({ ...updateBlockData, hostel_fee: e.target.value })} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//           <TextField label="Deposit" value={updateBlockData.deposit} onChange={(e) => setUpdateBlockData({ ...updateBlockData, deposit: e.target.value })} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//         <Col ms={12} md={6}>
//           <TextField label="Facilities" value={updateBlockData.discription} onChange={(e) => setUpdateBlockData({ ...updateBlockData, discription: e.target.value })} fullWidth style={{ marginBottom: '20px' }} />
//         </Col>
//       </Row>
//       <Button variant="contained" color="primary" onClick={handleUpdateBlock} style={{ marginRight: '10px' }}>
//         Update
//       </Button>
//       <Button variant="contained" onClick={handleUpdateModalClose}>
//         Cancel
//       </Button>
//     </Box>
//   </Modal>
// </Box>
// </section>
// </>
// );
// };

// export default Block;



import React, { useState, useEffect } from 'react';
import './Block.css';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import { MenuItem } from '@mui/material';
import { BlockAddApi, BlockFindallApi, BlockUpdateApi, FeeTypeFindAllApi } from '../../ApiFile';

const Block = () => {
  const [blocks, setBlocks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [blockName, setBlockName] = useState('');
  const [fee, setFee] = useState('');
  const [feeId, setFeeId] = useState([]);
  const [room_range, setRoom_range] = useState('');
  const [discription, setDiscription] = useState('');
  const [bed_count, setBed_count] = useState('');
  const [amount, setAmount] = useState('');
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [updateBlockData, setUpdateBlockData] = useState({
    id: '',
    fee: '',
    block_id: '',
    room_range: '',
    discription: '',
    bed_count: '',
    file: null,
    file1: null
  });

  useEffect(() => {
    fetchBlocks();
    fetchFeeId();
  }, []);

  const fetchFeeId = async () => {
    try {
      const response = await fetch(FeeTypeFindAllApi);
      if (!response.ok) {
        throw new Error('Failed to fetch fee data');
      }
      const data = await response.json();
      if (data === "NOTFOUND") {
        console.log("if ");
        setFeeId([]); // Set rules to an empty array if "NOTFOUND"
    } else {
      console.log("else ");
      setFeeId(data);
    }
     
    } catch (error) {
      // console.error('Error fetching fee data:', error);
    }
  };

  const fetchBlocks = async () => {
    try {
      const response = await fetch(BlockFindallApi);
      if (!response.ok) {
        throw new Error('Failed to fetch blocks');
      }
      const data = await response.json();
      if (data === "NOTFOUND") {
        console.log("if ");
        setBlocks([]); // Set rules to an empty array if "NOTFOUND"
    } else {
      console.log("else ");
      setBlocks(data);
    }
      // console.log("Fetched data:", data);
     
    } catch (error) {
      // console.error('Error fetching blocks:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setBlockName('');
    setRoom_range('');
    setFee('');
    setBed_count('');
    setDiscription('');
    setImage(null);
    setImage1(null);
    
  };

  const handleShowModal = () => setShowModal(true);

  const handleAddBlock = async () => {
    let formData = new FormData();
    try {
      if (
        blockName.trim() !== '' || 
        fee.trim() !== '' || 
        room_range.trim() !== '' || 
        bed_count.trim() !== '' || 
        discription.trim() !== '' 
      ) {
        formData.append('fee_id', fee);
        formData.append('block_id', blockName);
        formData.append('room_range', room_range);
        formData.append('bed_count', bed_count);
        formData.append('discription', discription);
        formData.append('file', image); 
        formData.append('file1', image1); 
  
        const response = await axios.post(BlockAddApi, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        if (response.data === 'SAVED') {
          toast.success('Saved Successfully !!!!!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          // console.log("api..,.,", response.data);
          fetchBlocks();
          handleCloseModal();
        } else {
          // console.error('Failed to save block');
        }
      }
    } catch (error) {
      // console.error('Error adding block:', error);
    }
  };
  

  const handleUpdateModalOpen = (block) => {
    setUpdateBlockData({
      id: block.id,
      fee_name : block.fee_name,
      block_id: block.block_id,
      room_range: block.room_range,
      discription: block.discription,
      bed_count: block.bed_count,
      file: block.room_img,
      file1:block.bed_img
    });
    setShowUpdateModal(true);
  };

  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
    setBlockName('');
    setRoom_range('');
    setFee('');
    setBed_count('');
    setDiscription('');
    setImage(null);
    setImage1(null);
  };

  
  const handleUpdateImageChange = (e) => {
    setUpdateBlockData({ ...updateBlockData, file: e.target.files[0] });
  };
  
  const handleUpdateImageChange1 = (e) => {
    setUpdateBlockData({ ...updateBlockData, file1: e.target.files[0] });
  };

  const handleUpdateBlock = async () => {
    let formData = new FormData();
    formData.append('fee_id', updateBlockData.fee_id);
    formData.append('block_id', updateBlockData.block_id);
    formData.append('room_range', updateBlockData.room_range);
    formData.append('bed_count', updateBlockData.bed_count);
    formData.append('discription', updateBlockData.discription);
    if (updateBlockData.file) {
      formData.append('file', updateBlockData.file);
    }
    if (updateBlockData.file1) {
      formData.append('file1', updateBlockData.file1);
    }

    for (let [key, value] of formData.entries()) {
      // console.log(`${key}:`, value);
    }

    try {
      const response = await axios.put(`${BlockUpdateApi}/${updateBlockData.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
// console.log("res update.....",response)
      if (response.data === 'NOTFOUND') {
        alert('Block not found');
      } else if (response.data === "UPDATE") {
        toast.success('Updated Successfully !!!!!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        fetchBlocks();
        handleUpdateModalClose();
      } else {
        // console.error('Failed to update block');
        toast.error('Failed to update block!!!!!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      // console.error('Error updating block:', error);
    }
  };

  const columns = [
    { label: 'Sr.no', field: 'srNo', sort: 'asc' },
    { label: 'Block Number', field: 'blockNumber' },
    { label: 'Fee Name', field: 'fee_name' },
    { label: 'Room Range', field: 'roomRange' },
    { label: 'Beds Per Room', field: 'bedCount' },
    { label: 'Facilities', field: 'discription' },
    { label: 'Block Image', field: 'file' },
    { label: 'Room Image', field: 'file1' },
    {
      label: 'Update',
      field: 'update',
      width: 100,
    },
  ];

  const rows = blocks.map((block, index) => ({
    srNo: index + 1,
    blockNumber: block.block_id,
    fee_name: block.fee_name,
    roomRange: block.room_range,
    bedCount: block.bed_count,
    file: block.room_img,
    file1: block.bed_img,
    discription: block.discription,
    update: (
      <button
        className='m-1'
        style={{ color: "rgb(0, 123, 255)", border: "0px", backgroundColor: "transparent" }}
        onClick={() => handleUpdateModalOpen(block)}
      >
        <i className="fa-solid fa-pen-to-square"></i>
      </button>
    )
  }));

  const dataTable = { columns, rows };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };
  const handleImageChange1 = (e) => {
    setImage1(e.target.files[0]);
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div className="block-page pb-5">
          <div className="block-header">
            <h2 className='text-center mt-md-4 mt-2' style={{ color: "maroon" }}>Hostel Block</h2>
            <Button className='ms-md-4 ms-2  mb-2 mb-md-4 mt-2' variant="contained" color="primary" onClick={handleShowModal} startIcon={<AddIcon />}>
              Add Block
            </Button>
          </div>

          <Row className="m-0 pt-1 px-2 mx-md-3 mb-3 rounded">
            <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: "white" }}>
              <div style={{ overflowY: 'auto' }}>
                <div style={{ overflowX: 'auto' }}>
                  <MDBDataTable
                    className='custom-datatable'
                    hover
                    striped
                    bordered
                    small
                    data={dataTable}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Modal open={showModal} onClose={handleCloseModal} style={{ overflow: "scroll" }}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 4, width: '70%', boxShadow: 24 }}>
              <Typography variant="h4" component="h2" gutterBottom className='mb-md-4 mb-2'>
                Add Block
              </Typography>
              <Row>
                <Col ms={12} md={6}>
                  <TextField label="Block Name" size='small' value={blockName} onChange={(e) => setBlockName(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
                </Col>
                <Col ms={12} md={6}>
                <TextField       
                    size="small"
                    select
                    label="Select Fee Name"
                    value={fee}
                    onChange={(e) => setFee(e.target.value)}
                    fullWidth
                    style={{ marginBottom: '20px' }}
                    required
                  >
                    {feeId.map((fee) => (
                      <MenuItem key={fee.fee_id} value={fee.fee_id}>
                        {fee.fee_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Col>
                <Col ms={12} md={6}>
                  <TextField label="Room Range" size='small' value={room_range} onChange={(e) => setRoom_range(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
                </Col>
                <Col ms={12} md={6}>
                  <TextField label="Total Bed Count" size='small' value={bed_count} onChange={(e) => setBed_count(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
                </Col>
                <Col ms={12} md={6}>
                  <label>Select Block Image</label>
                  <input type="file" className='w-100' onChange={handleImageChange} style={{ marginBottom: '20px', borderRadius: "0px" }} />
                </Col>
                <Col ms={12} md={6}>
                  <label>Select Room Image</label>
                  <input type="file" className='w-100' onChange={handleImageChange1} style={{ marginBottom: '20px' }} />
                </Col>
                <Col ms={12} md={12}>
                  <TextField label="Facilities" size='small' value={discription} onChange={(e) => setDiscription(e.target.value)} fullWidth style={{ marginBottom: '20px' }} />
                </Col>
              </Row>
              <Button variant="contained" color="primary" onClick={handleAddBlock} style={{ marginRight: '10px' }}>
                Add
              </Button>
              <Button variant="contained" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Box>
          </Modal>

          <Modal open={showUpdateModal} onClose={handleUpdateModalClose} style={{ overflow: "scroll" }}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 4, width: '70%', boxShadow: 24 }}>
              <Typography variant="h4" component="h2" gutterBottom className='mb-md-4 mb-2'>
                Update Block
              </Typography>
              <Row>
                <Col ms={12} md={6}>
                  <TextField label="Block Name" size='small' value={updateBlockData.block_id} onChange={(e) => setUpdateBlockData({ ...updateBlockData, block_id: e.target.value })} fullWidth style={{ marginBottom: '20px' }} />
                </Col>
                <Col ms={12} md={6}>
                  <TextField       
                    size="small"
                    select
                    label="Select Fee Name"
                    value={updateBlockData.fee_name} onChange={(e) => setUpdateBlockData({ ...updateBlockData, fee_name: e.target.value })}
                    fullWidth
                    style={{ marginBottom: '20px' }}
                    required
                  >
                    {feeId.map((fee) => (
                      <MenuItem key={fee.fee_name} value={fee.fee_name}>
                        {fee.fee_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Col>
                <Col ms={12} md={6}>
                  <TextField label="Room Range" size='small' value={updateBlockData.room_range} onChange={(e) => setUpdateBlockData({ ...updateBlockData, room_range: e.target.value })} fullWidth style={{ marginBottom: '20px' }} />
                </Col>
                <Col ms={12} md={6}>
                  <TextField label="Total Bed Count" size='small' value={updateBlockData.bed_count} onChange={(e) => setUpdateBlockData({ ...updateBlockData, bed_count: e.target.value })} fullWidth style={{ marginBottom: '20px' }} />
                </Col>
                <Col ms={12} md={6}>
                  <label>Select Block Image</label>
                  <input type="file" onChange={handleUpdateImageChange} className='w-100' style={{ marginBottom: '20px', borderRadius: "0px" }} />
                </Col>
                <Col ms={12} md={6}>
                  <label>Select Room Image</label>
                  <input type="file" onChange={handleUpdateImageChange1} className='w-100' style={{ marginBottom: '20px', borderRadius: "0px" }} />
                </Col>
                <Col ms={12} md={12}>
                  <TextField label="Facilities" size='small' value={updateBlockData.discription} onChange={(e) => setUpdateBlockData({ ...updateBlockData, discription: e.target.value })} fullWidth style={{ marginBottom: '20px' }} />
                </Col>
              </Row>
              <Button variant="contained" color="primary" onClick={handleUpdateBlock} style={{ marginRight: '10px' }}>
                Update
              </Button>
              <Button variant="contained" onClick={handleUpdateModalClose}>
                Cancel
              </Button>
            </Box>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default Block;
