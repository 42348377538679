import React, { useState, useEffect } from 'react';
import Sidebar from '../../studentDashboard/pages/sidebarstudent';
import Header from '../../studentDashboard/pages/Headerstudent';
import { Row, Col } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StudentMessTodaysMenuFindall } from '../../ApiFile';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const StudentTodaysMenu = () => {
  const [lunchData, setLunchData] = useState(null);
  const [dinnerData, setDinnerData] = useState(null);

  useEffect(() => {
    // Fetch the today's menu data from the API
    fetch(StudentMessTodaysMenuFindall)
      .then((response) => response.json())
      .then((data) => {
        // Update the lunch data
        setLunchData({
          labels: data.lunch.map(item => item.menu),
          datasets: [
            {
              data: data.lunch.map(() => 15), // Example data values, modify as needed
              backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#4BC0C0',
                '#9966FF',
                '#FF9F40',
                '#FFCD56',
              ]
            }
          ]
        });

        // Update the dinner data
        setDinnerData({
          labels: data.dinner.map(item => item.menu),
          datasets: [
            {
              data: data.dinner.map(() => 20), // Example data values, modify as needed
              backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#4BC0C0',
                '#9966FF',
                '#FF9F40'
              ]
            }
          ]
        });
      })
      .catch((error) => {
        console.error('Error fetching the menu data:', error);
      });
  }, []);

  // Chart options with datalabels plugin
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows chart to resize better
    plugins: {
      legend: {
        display: false // Hide the legend since labels will be on the chart
      },
      datalabels: {
        color: '#fff', // Text color for the labels
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        },
        font: {
          weight: 'bold'
        }
      }
    }
  };

  return (
    <>
      <Sidebar />
      <section className="home-section pb-5">
        <Header />
        <div className="block-header pb-4">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Mess Today's Menu
          </h2>

          <Row style={{display:"flex",justifyContent:"space-evenly"}}>
            <Col md={2}></Col>
            <Col md={4} className="text-center mt-5">
              <h4>Lunch</h4>
              <div style={{ height: '300px' }}>
                {lunchData && <Pie data={lunchData} options={options} />}
              </div>
            </Col>
            <Col md={4} className="text-center mt-5">
              <h4>Dinner</h4>
              <div style={{ height: '300px' }}>
                {dinnerData && <Pie data={dinnerData} options={options} />}
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default StudentTodaysMenu;
