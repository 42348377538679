import React, { useEffect, useState } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import ReportNavbar from './ReportNavbar';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { DatePicker } from 'antd';
import axios from 'axios';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo from '../../studentDashboard/img/logo2.png';
import logo2 from '../../studentDashboard/img/logonew.jpg';
import { AdminDownloadAllReceipt } from '../../ApiFile';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const { RangePicker } = DatePicker;

// Utility function to convert image to base64
const toDataURL = url =>
  fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }));

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

const PaymentReceipt = () => {
  const [receiptData, setReceiptData] = useState(null);
  const [feeDetails, setFeeDetails] = useState({});
  const [dateRange, setDateRange] = useState([]); 
  const [logoBase64, setLogoBase64] = useState(null);
  const [logo2Base64, setLogo2Base64] = useState(null);

  useEffect(() => {
    // Convert images to base64
    Promise.all([toDataURL(logo), toDataURL(logo2)])
      .then(([logoBase64, logo2Base64]) => {
        setLogoBase64(logoBase64);
        setLogo2Base64(logo2Base64);
      });
  }, []);

  const handleDateChange = (dates) => {
    setDateRange(dates);
  };

  
  const handleStudentReceiptShow = () => {
    const [dateFrom, dateTo] = dateRange;
  
    const objData = {
      fromdate: dateFrom ? dateFrom.format('DD-MM-YYYY') : null,
      todate: dateTo ? dateTo.format('DD-MM-YYYY') : null,
    };
  
    axios.post(AdminDownloadAllReceipt, objData)
      .then(response => {
        console.log("--------",response.data); 
        const data = response.data;
  
        setReceiptData(data);
        handleDownload(data); // Pass the entire array of receipt data
      })
      .catch(error => console.error('Error fetching receipt data:', error));
  };
  
    //==========single student data in one page==========

  const handleDownload = (receiptDataArray) => {
    
    if (!receiptDataArray || !logoBase64 || !logo2Base64 ) return;
  
    const receiptContents = receiptDataArray.map((receiptData, receiptIndex) => {
      const feeDetails = receiptData.feedetails||[];
      const totalAmount = feeDetails.reduce((sum, fee) => sum + fee.amount, 0);
  
      const tableBody = [
        [
          { text: "S.No", bold: true, alignment: 'center' },
          { text: "Description", bold: true, alignment: 'center' },
          { text: "Amount", bold: true, alignment: 'center' }
        ]
      ];
  
      feeDetails.forEach((fee, index) => {
        tableBody.push([
          { text: index + 1, alignment: 'center' },
          { text: fee.fee_name, alignment: 'left', style: 'description' },
          { text: fee.amount, alignment: 'right', style: 'amount' }
        ]);
      });
  
      tableBody.push([
        {},
        { text: "Total =", bold: true, alignment: 'center' },
        { text: totalAmount, bold: true, alignment: 'right', style: 'amount' }
      ]);
  
      return [
        {
          columns: [
            { style: "logo", width: "15%", image: logoBase64, fit: [50, 50] },
            {
              width: "70%",
              text: [
                { text: "Dakshin Bharat Jain Sabha's \n", style: "header1" },
                { text: "Sheth R D Dawada Digambar Jain Boarding,\n", style: "header" },
                { text: "37, Mahavir Nagar, Sangli 416416, Phone: 0233-262306\n", style: "header2" },
                { text: "Web : www.jainboardingsangli.com  Email : jainboardingsangli@gmail.com \n", style: "header3" },
              ],
            },
            { style: "logo", width: "15%", image: logo2Base64, fit: [50, 50] },
          ],
        },
        {
          margin: [0, 15, 0, 0],
          canvas: [
            {
              type: 'line',
              x1: 0, y1: 0,
              x2: 515, y2: 0,
              lineWidth: 1
            }
          ],
        },
        {
          columns: [
            {
              style: "paymetnReceipt",
              width: "100%",
              text: [
                { text: 'Payment Receipt ', bold: true },
              ],
            },
          ],
        },
        {
          style: "receiptmargin",
          columns: [
            {
              style: "receipt",
              width: "33%",
              text: [
                { text: 'Receipt No: ', bold: true },
                { text: `${receiptData.receipt_no}\n` }
              ],
            },
            {
              style: "receipt",
              width: "33%",
              text: [
                { text: 'Academic Year: ', bold: true },
                { text: `${receiptData.boarding_year}\n` }
              ],
            },
            {
              style: "recdate",
              width: "34%",
              text: [
                { text: 'Receipt Date: ', bold: true },
                { text: `${receiptData.receipt_date}\n` }
              ],
            },
          ],
        },
        {
          columns: [
            {
              width: "66%",
              text: [
                { text: 'Name: ', bold: true },
                { text: `${receiptData.name}\n` }
              ],
              style: "NameMargin"
            },
            {
              width: "34%",
              text: [{ text: 'Payment Date: ', bold: true }, { text: `${receiptData.pay_date}\n` }],
              style: "NameMargin"
            },
          ],
        },
        {
          text: [
            { text: 'Address: ', bold: true },
            { text: `${receiptData.address} ,` },
            { text: ' Taluka: ', bold: true },
            { text: `${receiptData.talukaenname} , ` },
            { text: ' District: ', bold: true },
            { text: `${receiptData.districtenname} , ` },
            { text: ' State: ', bold: true },
            { text: `${receiptData.state_name} , ` },
            { text: ' Pin-Code: ', bold: true },
            { text: `${receiptData.pincode} . ` }
          ],
          style: "NameMargin"
        },
        {
          style: "NameMargin",
          columns: [
            { width: "33%", text: [{ text: 'Term: ', bold: true }, { text: `${receiptData.term}\n` }] },
            { width: "33%", text: [{ text: 'Mobile No: ', bold: true }, { text: `${receiptData.mob_no}\n` }] },
            { width: "34%", text: [{ text: 'Parent\'s Mob No: ', bold: true }, { text: `${receiptData.parents_mobno}\n` }] },
          ],
        },
        {
          style: "NameMargin",
          columns: [
            { width: "33%", text: [{ text: 'Payment Mode: ', bold: true }, { text: `${receiptData.pay_mode}\n` }] },
            { width: "67%", text: [{ text: 'Transaction Id: ', bold: true }, { text: `${receiptData.tax_id}\n` }] },
          ],
        },
        {
          style: "NameMargin",
          columns: [
            { width: "33%", text: [{ text: 'Block No: ', bold: true }, { text: `${receiptData.block_id}\n` }] },
            { width: "33%", text: [{ text: 'Room No: ', bold: true }, { text: `${receiptData.room_id}\n` }] },
            { width: "34%", text: [{ text: 'Bed No: ', bold: true }, { text: `${receiptData.bed_id}\n` }] },
          ],
        },
        {
          text: "",
        },
        {
          style: "NameMargin",
          columns: [
            { width: "15%", text: [{ text: '' }] },
            {
              width: "70%",
              style: "tableExample",
              table: {
                widths: ["12%", "70%", "18%"],
                body: tableBody
              }
            },
            { width: "15%", text: [{ text: '' }] },
          ],
        },
        {
          text: receiptIndex < receiptDataArray.length - 1 ? '' : null,
          pageBreak: receiptIndex < receiptDataArray.length - 1 ? 'after' : ''
        }
      ];
    });
  
    const dd = {
      content: receiptContents.flat(),
      footer: function (currentPage, pageCount) {
        return {
          text: `Design & Developed By TechnoAarv Solution`,
          alignment: 'center',
          margin: [0, 0, 0, 10]
        };
      },
      styles: {
        lineheight: {
          lineHeight: 1.5,
          fontSize: 12,
        },
        header1: {
          fontSize: 15,
          alignment: "center",
        },
        header: {
          fontSize: 18,
          alignment: "center",
          bold: true,
        },
        paymetnReceipt: {
          fontSize: 18,
          paddingTop:10,
          alignment: "center",
          bold: true,
        },
        header2: {
          fontSize: 13,
          alignment: "center",
        },
        header3: {
          fontSize: 9,
          alignment: "center",
        },
        logo: {
          alignment: "center",
        },
        left: {
          alignment: "right",
          lineHeight: 1.5,
          fontSize: 12,
        },
        subheader: {
          fontSize: 15,
          lineHeight: 1.5,
          bold: true,
        },
        start1: {
          alignment: "left",
          bold: true,
          color: 'blue'
        },
        receiptmargin: {
          margin: [0, 25, 0, 0],
        },
        NameMargin: {
          margin: [0, 7, 0, 0],
        },
        description: {
          margin: [19, 0, 0, 0],
        },
        amount: {
          margin: [0, 0, 5, 0],
        },
        tableExample: {
          lineHeight: 1.5,
          alignment: 'center',
          margin: [0, 40, 0, 0],
        }
      },
    };
  
    pdfMake.createPdf(dd).open();
  };
  
    //==========two student data in one page==========

  // const handleDownload = (receiptDataArray) => {
  //   if (!receiptDataArray || !logoBase64 || !logo2Base64) return;
  
  //   const groupedReceiptData = [];
  //   for (let i = 0; i < receiptDataArray.length; i += 2) {
  //     groupedReceiptData.push(receiptDataArray.slice(i, i + 2));
  //   }
  
  //   const receiptContents = groupedReceiptData.map((group) => {
  //     return group.map((receiptData, receiptIndex) => {
  //       const feeDetails = receiptData.feedetails || [];
  //       const totalAmount = feeDetails.reduce((sum, fee) => sum + fee.amount, 0);
  
  //       const tableBody = [
  //         [
  //           { text: "S.No", bold: true, alignment: 'center' },
  //           { text: "Description", bold: true, alignment: 'center' },
  //           { text: "Amount", bold: true, alignment: 'center' }
  //         ]
  //       ];
  
  //       feeDetails.forEach((fee, index) => {
  //         tableBody.push([
  //           { text: index + 1, alignment: 'center' },
  //           { text: fee.fee_name, alignment: 'left', style: 'description' },
  //           { text: fee.amount, alignment: 'right', style: 'amount' }
  //         ]);
  //       });
  
  //       tableBody.push([
  //         {},
  //         { text: "Total =", bold: true, alignment: 'center' },
  //         { text: totalAmount, bold: true, alignment: 'right', style: 'amount' }
  //       ]);
  
  //       return [
  //         {
  //           columns: [
  //             { style: "logo", width: "15%", image: logoBase64, fit: [40, 40] },
  //             {
  //               width: "70%",
  //               text: [
  //                 { text: "Dakshin Bharat Jain Sabha's \n", style: "header1" },
  //                 { text: "Sheth R D Dawada Digambar Jain Boarding,\n", style: "header" },
  //                 { text: "37, Mahavir Nagar, Sangli 416416, Phone: 0233-262306\n", style: "header2" },
  //                 { text: "Web : www.jainboardingsangli.com  Email : jainboardingsangli@gmail.com \n", style: "header3" },
  //               ],
  //             },
  //             { style: "logo", width: "15%", image: logo2Base64, fit: [40, 40] },
  //           ],
  //         },
  //         {
  //           margin: [0, 10, 0, 0],
  //           canvas: [
  //             {
  //               type: 'line',
  //               x1: 0, y1: 0,
  //               x2: 515, y2: 0,
  //               lineWidth: 1
  //             }
  //           ],
  //         },
  //         {
  //           style: "receiptmargin",
  //           columns: [
  //             {
  //               style: "receipt",
  //               width: "33%",
  //               text: [
  //                 { text: 'Receipt No: ', bold: true },
  //                 { text: `${receiptData.receipt_id}\n` }
  //               ],
  //             },
  //             {
  //               style: "receipt",
  //               width: "33%",
  //               text: [
  //                 { text: 'Academic Year: ', bold: true },
  //                 { text: `${receiptData.receipt_year}\n` }
  //               ],
  //             },
  //             {
  //               style: "recdate",
  //               width: "34%",
  //               text: [
  //                 { text: 'Receipt Date: ', bold: true },
  //                 { text: `${receiptData.receipt_date}\n` }
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           columns: [
  //             {
  //               width: "66%",
  //               text: [
  //                 { text: 'Name: ', bold: true },
  //                 { text: `${receiptData.name}\n` }
  //               ],
  //               style: "NameMargin"
  //             },
  //             {
  //               width: "34%",
  //               text: [{ text: 'Payment Date: ', bold: true }, { text: `${receiptData.pay_date}\n` }],
  //               style: "NameMargin"
  //             },
  //           ],
  //         },
  //         {
  //           text: [
  //             { text: 'Address: ', bold: true },
  //             { text: `${receiptData.address} ,` },
  //             { text: ' Taluka: ', bold: true },
  //             { text: `${receiptData.talukaenname} , ` },
  //             { text: ' District: ', bold: true },
  //             { text: `${receiptData.districtenname} , ` },
  //             { text: ' State: ', bold: true },
  //             { text: `${receiptData.state_name} , ` },
  //             { text: ' Pin-Code: ', bold: true },
  //             { text: `${receiptData.pincode} . ` }
  //           ],
  //           style: "NameMargin"
  //         },
  //         {
  //           style: "NameMargin",
  //           columns: [
  //             { width: "33%", text: [{ text: 'Term: ', bold: true }, { text: `${receiptData.term}\n` }] },
  //             { width: "33%", text: [{ text: 'Mobile No: ', bold: true }, { text: `${receiptData.mob_no}\n` }] },
  //             { width: "34%", text: [{ text: 'Parent\'s Mob No: ', bold: true }, { text: `${receiptData.parents_mobno}\n` }] },
  //           ],
  //         },
  //         {
  //           style: "NameMargin",
  //           columns: [
  //             { width: "33%", text: [{ text: 'Payment Mode: ', bold: true }, { text: `${receiptData.pay_mode}\n` }] },
  //             { width: "67%", text: [{ text: 'Transaction Id: ', bold: true }, { text: `${receiptData.tax_id}\n` }] },
  //           ],
  //         },
  //         {
  //           style: "NameMargin",
  //           columns: [
  //             { width: "33%", text: [{ text: 'Block No: ', bold: true }, { text: `${receiptData.block_id}\n` }] },
  //             { width: "33%", text: [{ text: 'Room No: ', bold: true }, { text: `${receiptData.room_id}\n` }] },
  //             { width: "34%", text: [{ text: 'Bed No: ', bold: true }, { text: `${receiptData.bed_id}\n` }] },
  //           ],
  //         },
  //         {
  //           text: "",
  //         },
  //         {
  //           style: "NameMargin",
  //           columns: [
  //             { width: "15%", text: [{ text: '' }] },
  //             {
  //               width: "70%",
  //               style: "tableExample",
  //               table: {
  //                 widths: ["12%", "70%", "18%"],
  //                 body: tableBody
  //               }
  //             },
  //             { width: "15%", text: [{ text: '' }] },
  //           ],
  //         },
  //         {
  //           text: receiptIndex < group.length - 1 ? '' : null,
  //           margin: [0, 15, 0, 0] // Space between receipts
  //         }
  //       ];
  //     }).flat();
  //   });
  
  //   const dd = {
  //     content: receiptContents,
  //     styles: {
  //       lineheight: {
  //         lineHeight: 1.2,
  //         fontSize: 10,
  //       },
  //       header1: {
  //         fontSize: 12,
  //         alignment: "center",
  //       },
  //       header: {
  //         fontSize: 14,
  //         alignment: "center",
  //         bold: true,
  //       },
  //       header2: {
  //         fontSize: 10,
  //         alignment: "center",
  //       },
  //       header3: {
  //         fontSize: 8,
  //         alignment: "center",
  //       },
  //       logo: {
  //         alignment: "center",
  //       },
  //       left: {
  //         alignment: "right",
  //         lineHeight: 1.2,
  //         fontSize: 10,
  //       },
  //       subheader: {
  //         fontSize: 12,
  //         lineHeight: 1.2,
  //         bold: true,
  //       },
  //       start1: {
  //         alignment: "left",
  //         bold: true,
  //         color: 'blue',
  //         fontSize: 10,
  //       },
  //       receiptmargin: {
  //         margin: [0, 15, 0, 0], // Adjusted margin for space between receipts
  //       },
  //       NameMargin: {
  //         margin: [0, 5, 0, 0], // Reduced margin
  //       },
  //       description: {
  //         margin: [10, 0, 0, 0],
  //       },
  //       amount: {
  //         margin: [0, 0, 5, 0],
  //       },
  //       tableExample: {
  //         lineHeight: 1.2,
  //         alignment: 'center',
  //         margin: [0, 10, 0, 0], // Adjusted margin
  //       }
  //     },
  //   };
  
  //   pdfMake.createPdf(dd).open();
  // };
    
  
    return (
        <>
        <Sidebar />
        <section className="home-section pb-5">
          <NavLogo />
          <ReportNavbar />
    
            <Row className="m-0 p-0 pt-4 px-3 ">
                <Col
                    className="rounded text-center py-2"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "white",
                        border: "none",
                        alignItems: "center",
                        alignContent: "center",
                        color: "maroon",
                    }}
                >    
                <div><span style={{color:"maroon",fontSize:"18px"}}>Students Payment Receipt Report</span></div>
                    
                    
                </Col>
            </Row>

          <div className='row m-0 d-flex justify-content-center py-3'>
        
              <div className='col-12 col-md-3'></div>
              <div className='col-12 col-md-6'>
              <label style={{fontWeight:"normal"}}>Select Date :</label>
                <RangePicker style={{height:"40px"}}
                  format={dateFormatList}
                  onChange={handleDateChange}
                /> &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleStudentReceiptShow}
                >
                  Generate Receipt
                </Button>
              </div>
              <div className='col-12 col-md-3'></div>
         
          </div>

        </section>
      </>
    );
  };
  
  export default PaymentReceipt;
  
