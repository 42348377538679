import react, { Fragment, useState } from 'react';
import './alumini.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { useForm } from 'react-hook-form';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { AluminiAdd } from '../../ApiFile';



function Alumini() {
  




  const [formData, setFormData] = useState({
    full_name: '',
    qualification: '',
    address: '',
    alternate_address: '',
    mob_no: '',
    alternate_mobno: '',
    dateofbirth: '',
    email_id: '',
    blood_group: '',
    professional_category: '',
    service_name: '',
    yearof_boarding: '',
    contactdetails: '',
    achievements: '',
    comment: '',
    year: '',
  });

  const {
    full_name,
    qualification,
    address,
    alternate_address,
    mob_no,
    alternate_mobno,
    dateofbirth,
    email_id,
    blood_group,
    professional_category,
    service_name,
    yearof_boarding,
    contactdetails,
    achievements,
    comment,
    year,
  } = formData;

  // Update form data when user input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Add function to submit form data to the API
  const handleSubmit = (e) => {
    e.preventDefault();
    
    console.log('Form data:', formData);
    // Prepare form data
    const data = {
      full_name,
      qualification,
      address,
      alternate_address,
      mob_no,
      alternate_mobno,
      dateofbirth,
      email_id,
      blood_group,
      professional_category,
      service_name,
      yearof_boarding,
      contactdetails,
      achievements,
      comment,
      year,
    };

    // Call the API function to add alumini data
    addAluminiData(data);
  };

  // The addAluminiData function from the previous answer
  const addAluminiData = async (data) => {
    try {
      const response = await axios.post(AluminiAdd, data);
      if (response.status === 200) {
        toast.success("Login successful!");        // Display success message, handle errors and edge cases as needed
      }
    } catch (error) {
      if (error.response) {
        console.error('Error:', error.response.data);
        // Display error message, handle errors and edge cases as needed
      } else if (error.request) {
        console.error('Error: No response received from the server.');
        // Display error message, handle errors and edge cases as needed
      } else {
        console.error('Error: Network error.');
        // Display error message, handle errors and edge cases as needed
      }
    }
  };


  return (



    <div>
      <Navbar></Navbar>
      <div className="container-fluid bg4-breadcrumb">
        <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h3 className="text-white display-3 mb-4" style={{ textShadow: '3px 3px #ee1515' }}>Alumini</h3>
          <a className="btn-hover-bg btn btn-primary1 rounded-pill text-white py-3 px-5" href="/#/AluminiLogin" style={{backgroundColor: '#ee1515'}}>Register Now</a>

        </div>
      </div>

      <div className="container-fluid bg-light service py-5" data-aos="fade-up" data-aos-delay={400} data-aos-duration={400}>
        <Container>
          <Row>
            <Col>
              <div className="f">
                <h5 className="mb-0 t1 section-title px-3" style={{ color: '#ee1515', marginLeft: '400px' }}>Appeal to
                  All Ex-students</h5>
              </div>

              <div className="row g-4 p1" style={{ backgroundColor: 'rgb(247, 238, 198)', borderRadius: '10px', marginTop: '20px' }}>
                <h5 className="section-booking-title pe-3" style={{ color: 'black', marginTop: '30px', fontSize: '20px' }}>धर्मानुरामी धर्मवन्धुनी,
                </h5>
                <div className="para" style={{ textAlign: 'justify', marginTop: '10px', color: 'black' }}>
                  <p className="text mb-4">एका भारावलेल्या - मंतरलेल्या सहजीवनांच्या, वास्तव्याच्या परिमल,
                    स्मृतीगंधात न्हावून निघण्याच्या, जपलेल्या, जोपासलेल्या, जतन केलैल्या " मैत्र
                    जीवाचं " अशा
                    आठवणींना उजाळा देण्याच्या, ऋणानुबंधाच्या, स्नेहबंधाच्या पाऊलखुणा अधोरेखीत
                    करण्याच्या दशादिशांनी
                    विखुरलेल्या स्नेहाकितांच्या उत्कट स्नेहार्द भेटीच्या नुभूती देणाऱ्या रा.ध. दावडा
                    दिगंबर जैन
                    बोर्डिंगच्या माजी विद्यार्थी स्नेह सम्मेलनातील आपली उपस्थिती, सहभाग समर्थनाच्या
                    निमित्ताने
                    आपल्याशी स्नेहसंवाद साधित असताना आम्हास अतिव आनंद होती आहे. आणि यासाठी " या
                    चिमण्याने परत फिरा
                    रे धराकडे अपुल्या... अशी भावनिक स्नेह मयी साद घालीत आहोत.
                  </p>
                  <p className="text mb-4">सर्व सामान्यांच्या दृष्टीने जैन बोर्डिंग म्हणजे दगड विटाच्या
                    भितींची
                    इमारत अशी रुढ अर्थाची माफक संकल्पना! मात्र भाजी विद्यार्थ्यांच्यादृष्टीने या पलिकडची
                    उच्च
                    पराकोटीची भावना म्हणजे आपल्या विद्यार्थी जीवनाचा, शिक्षणाचा, संस्काराचा, चारित्र
                    चारित्र्यांचा,
                    व्यक्ती विकासाचा चैतन्यस्त्रोत " म्हणून या वास्तूचे फार मोठे महत्व आहे. आपल्या
                    जीवनातील अनगौल
                    महत्वपूर्ण क्षणाचा आधारवड ठरणारी, आपल्या विद्यार्थी जीवन वाटचालीमध्ये सावली देणारी,
                    शैक्षणिक
                    विकास, गती, प्रगतीची मूक साक्षीदार ठरणारी, आपल्या ज्ञानादानाची वाट सुकर करणारी ही
                    वास्तू म्हणजे
                    साक्षात "चैतन्यमंदिर" करावी असे वाटते आणि त्यादृष्टीने विचार करता ही वास्तू आणि या
                    शतकाच्या
                    कालावधीतील असंख्य माजी विद्यार्थी
                  </p>
                  <p className="text mb-4" style={{ textAlign: 'center' }}><b>पवित्र मंगल मंदिर अमुचे, मित्य देते
                    ज्ञान
                    संस्कारांची ग्वाही,<br /> असे
                    तळपती अगणित तरि, तेज तयांचे दिशात दाही</b></p>
                  <p className="text mb-4">या काव्य पंक्तिची प्रचिती आणून देत, ज्ञानगुण संपन्न होवून
                    परिवार-परिसर,
                    समाज, धर्म व देशाच्या सेवेत कार्यरत होताना, व्यापार, उदिम, उद्यीम व्यवसाय शासकीय
                    अधिकारी
                    राजकारण, समाजकारणात कार्यमग्र झाल्याचे वास्तव आपण सर्वजण पहाता आहात. आपल्या कार्य
                    कर्तृत्वाच्या
                    कक्षा रुदाविंत, परिघ विस्तारीत सर्वत्र कार्य करताना दिसताहेत. या सर्वा मागची प्रेरणा
                    ही सर्वथा
                    जैन बौर्डिम मधील वास्तव्याची संस्कार शिदोरीची म्हणावी लागेल. कारण अशा सर्व माजी
                    विद्यार्थ्यांच्या पंखात हवा भरुन त्यांना उचंच उंच भरारी घेण्याचं बळ याच वास्तूनं
                    पुरविले
                    असल्याचे आपण जाणता आहातच. आज अनेक माजी विद्यार्थी आपले कर्तृत्व गाजवताहेत.
                    वटवृक्षाच्या फांद्या
                    कितीही उंच आकाशाला भिडल्या असल्यातरी त्याची मुळे मात्र त्या मातीशी घट्ट रुतलेली
                    असतात, तव्दतच या
                    सर्वाची नाळ या वास्तूशी जोडलेली दिसते आहे.</p>
                  <p className="text mb-4"> अशी महती असणाऱ्या जैन बोर्डिंग व त्यातील माजी विद्यार्थ्यांचा
                    स्नेह
                    मेळाव्याचे प्रयोजनच मळी आपल्या अधिक जवळ येणाचा स्नेहसंवाद साधण्याचा, अशा सर्वाच्या
                    एकत्र
                    येण्यानं सुख दुःख अनुभव यशाच्या आदान प्रदानाने, जैन बोर्डिंगचे अमुलाग्र बदलेलं रुप
                    अवलोकनानं आणि
                    विविध संकल्पनांना मूर्त स्वरुप देण्याच्या पदाधिकारी, प्रयत्नांना सहयोग देण्यानं, उया
                    रुम
                    पार्टनराच्या साथीने आणि बोर्डिंगच्या साक्षीने आपल्या उघड्या डोळ्यांना पडणारी स्वप्ने
                    सत्यात
                    उतरविण्यासाठी सहाय्यभूत ठरली अशा सर्व घटकाप्रती कृतज्ञता व्यक्त करण्याची संधी, माजी
                    सहकान्याप्रती नाते संबंधाची विण घट्ट करण्याची प्रक्रिया म्हणून या मेळाव्याकडे पहाणे
                    संयुक्तिक
                    ठरावे असे वाटते.</p>
                  <p className="text mb-4">आपणा सर्वाच्या उपस्थितीने सहभागाने या माजी विद्यार्थी मैळाव्यातून
                    आपल्या
                    स्नेहबंधला घुमारे फुटून, स्नेहपालवी मौहरुन यावी, आपण सर्वांचा आनंद वृध्दीगत व्हावा
                    अशी आमची
                    अपेक्षा आहे आणि असं घडले तर " स्नेह धागा असा जुळावा आपुलकीचा स्पर्श व्हावा, माजी
                    विद्यार्थी
                    मेळाव्यास्तव आपण येता हर्ष आमुचा असिम व्हावा असे घडणार आहे."</p>
                  <p className="text mb-4" style={{ textAlign: 'center' }}>यास्तव पुनश्च एकदा आपण सर्वाच्या
                    उपस्थिती
                    विषयी विनम्र विनंती.</p>
                </div>
              </div>
            </Col>
            
          </Row>

        </Container>
      </div>

      <Footer />

      {/* <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up" /></a> */}

    </div>

  )
}
export default Alumini;