import React from 'react'

const Footer = () => {
  return (
    <>

     <div className="container-fluid footer py-5 " style={{lineHeight:"30px"}}>
        <div className="container">
          <div className="row g-5">
            <div className="col-md-6 col-lg-6 col-xl-4">
              <div className="footer-item d-flex flex-column text-start">
                <h4 className="mb-4 text-white">Get In Touch</h4>
                <a href><i className="fas fa-home me-2" /> 37, Mahaveer Nagar, Vakhar Bhag, Sangli,
                  Sangli Miraj Kupwad, Maharashtra 416416</a>
                <a href><i className="fas fa-envelope me-2" /> jainboardingsangli@.com</a>
                <a href><i className="fas fa-phone me-2" /> (0233) 2623206</a>
                <div className="d-flex align-items-center">
                  <i className="fas fa-share fa-2x text-white me-2" />
                  <a className="btn-square btn btn-primary rounded-circle mx-1" href><i className="fab fa-facebook-f" /></a>
                  <a className="btn-square btn btn-primary rounded-circle mx-1" href><i className="fab fa-instagram" /></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-4">
              <div className="footer-item d-flex flex-column com text-start">
                <h4 className="mb-4 text-white">Company</h4>
                <a href="/#/About"><i className="fas fa-angle-right me-2" /> About</a>
                <a href="/#/Services"><i className="fas fa-angle-right me-2" /> Services</a>
                <a href="/#/Boarding"><i className="fas fa-angle-right me-2" /> Boarding</a>
                <a href="/#/Alumini"><i className="fas fa-angle-right me-2" /> Alumini</a>
                <a href="https://registration.jainboardingsangli.com"><i className="fas fa-angle-right me-2" /> Vadhu-Var</a>
                <a href="/#/Blog"><i className="fas fa-angle-right me-2" /> Career</a>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-4 ">
              <div className="footer-item d-flex flex-column sup text-start">
                <h4 className="mb-4 text-white">Support</h4>
                <a href="/#/Contact"><i className="fas fa-angle-right me-2" /> Contact</a>
                <a href="/#/Privacy"><i className="fas fa-angle-right me-2" /> Privacy Policy</a>
                <a href="/#/TandC"><i className="fas fa-angle-right me-2" /> Terms and Conditions</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
      {/* Copyright Start */}
      <div className="container-fluid copyright text-body py-4 ">
        <div className="container">
          <div className="row g-4 align-items-center text-center">
            <a className="text-white" href="#">
            {/* Copyright © 2024 Sheth R.D Davada Digambar Jain Boarding, Sangli.All Rights Reserved. */}
            2024 Jain Boarding | Managed By: TechnoAarv Solution.
            </a>
          </div>
        </div>
      </div>
      {/* Copyright End */}
      {/* Back to Top */}
      {/* <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up" /></a> */}
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
    
    </>
  )
}

export default Footer